import React from 'react';
import LogoutButtonProperties from "./LogoutButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import LogoutIcon from '@mui/icons-material/Logout';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";


const LogoutButton: React.FC<LogoutButtonProperties> = ({
                                                            onClick = () => {
                                                            },
                                                            onSave = () => {
                                                            },
                                                            showIcon = true,
                                                            showText = true,
                                                            disabled,
                                                            className = '',
                                                            rounded = false,
                                                            size = ButtonSize.DEFAULT,
                                                            type = ButtonType.DEFAULT
                                                        }) => {

    const i18n = useI18nContext();
    const commonContext = useCommonContext();

    const handleOnclick = () => {
        commonContext.logout();
    }

    const text = i18n.translate("LOGOUT");
    const Icon = LogoutIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (<Btn/>)}
    </Observer>
}

export default LogoutButton;
