const values = {
    "MONEY_REMITTANCES": "Entregas de dinero",
    "MONEY_REMITTANCES_CONFIGURATIONS": "Config. de dinero",
    "TOPUPS_CONFIGURATIONS": "Config. de recarga",
    "NEW_MONEY_REMITTANCE": "Nueva entrega de dinero",
    "TOTAL_FEE": "Total de impuestos",
    "APPLIED_RATE": "Tarifa aplicada",
    "QUANTITY_PLACEHOLDER": "Cantidades",
    "AMOUNT_TO_RECEIVE_PLACEHOLDER": "Monto a recibir",
    "AMOUNT_TO_SEND_PLACEHOLDER": "Monto a enviar",
    "RECEIVE_IN": "Recibir en",
    "NO_MONEY_REMITTANCES_BUDGET_TITLE": "No hay datos para mostrar!!!",
    "NO_MONEY_REMITTANCES_BUDGET_SUBTITLE": "Llene el formulario de la izquierda para obetener datos.",
    "MONEY_REMITTANCE": "Remesa",
    "TO_PAY": "A PAGAR",
    "TO_RECEIVE": "A RECIBIR",
    "BANK_CARD_MONEY_REMITTANCE": "Envío de Tarjeta",
    "NO_MONEY_REMITTANCES_TITLE": "No hay entregas de dinero!!!",
    "NO_MONEY_REMITTANCES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "TOTAL_TO_PAY": "Total a pagar",
    "TOTAL_TO_RECEIVE": "Total a recibir",
    "WITHOUT_DATA_TO_SHOW": "Sin datos para mostrar",
    "WITHOUT_DATA_TO_SHOW_DESCRIPTION": "Verifique si existe cargos de envío configurados para el valor a enviar",
    "MONEY_DELIVERY_ORDER_FILTER_PLACEHOLDER": "Cliente, Contacto, Número de orden",
    "MONEY_DELIVERY_ORDER_DETAILS": "Detalles de envío de dinero",
}

export default values;