import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Response from "../../../model/Response";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";
import Geo from "../../../entities/Geo";
import { GeoLevel } from "../../Selects/GeoSelect/GeoSelectProperties";

const ERROR_MAP = {
    email: ["ERROR_00_400_41", "ERROR_00_400_40"],
    name: ["ERROR_00_400_43"],
    phone: ["ERROR_00_400_44", "ERROR_00_400_470"],
    homePhone: ["ERROR_00_400_480"]
}

class NewDeliveryPersonStore {

    public deliveryPersonId?: string;
    public person: DeliveryPerson;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public emailError?: string;
    public nameError?: string;
    public phoneError?: string;
    public homePhoneError?: string;
    public commonStore: CommonStore;

    public selectedProvince?: Geo;


    constructor(commonStore: CommonStore, deliveryPersonId?: string) {
        this.isEditing = !!deliveryPersonId;
        this.deliveryPersonId = deliveryPersonId;
        this.isModalOpen = false;
        this.person = {} as DeliveryPerson;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.person?.name) && !!(this.person?.email) && !!(this.person?.phone) && !!(this.person?.geo);
    }

    @action
    public async loadDeliveryPerson() {
        if (this.deliveryPersonId) {
            this.isEditing = true;
            const response = await DeliveryPersonService.find(this.deliveryPersonId!)
            if (response.success && response.data) {
                this.person = response.data;
                if (this.person && this.person.geo && this.person.geo.parentInfo) {
                    this.selectedProvince = new Geo(this.person.geo.parentId, this.person.geo.parentInfo, '', GeoLevel.STATE, true, 'Cuba');
                }
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.homePhoneError = undefined;
        this.nameError = undefined;
        this.loading = true;
        await this.loadDeliveryPerson();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        let response: Response<any>;
        if (this.isEditing) {
            response = await DeliveryPersonService.update(this.person);
        } else {
            response = await DeliveryPersonService.create(this.person);
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.homePhoneError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.homePhone.includes(error)) {
                this.homePhoneError = error;
            }
        }

    }
}

export default NewDeliveryPersonStore;