import React from "react";
import { useStyles } from "./DatePickerSelect.style";
import DatePickerSelectProperties from "./DatePickerSelectProperties";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from "@mui/material/Stack";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from '@mui/material/TextField';

const DatePickerSelect: React.FC<DatePickerSelectProperties> = ({
  error,
  label,
  selectedValue = 0,
  onChange,
}: DatePickerSelectProperties) => {
  const classes = useStyles();

  const [value, setValue] = React.useState<Date | null>(
    !selectedValue || selectedValue === 0 ? null : new Date(selectedValue + Math.abs((new Date()).getTimezoneOffset() * 60000)),
  );

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
    if (newValue) {
      onChange(newValue.getTime() - (newValue.getTimezoneOffset() * 60 * 1000));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className={classes.full_width}>
      <Stack spacing={3} className={classes.date_select_container}>
        <DesktopDatePicker className={classes.full_width}
          label={label}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField size="small" className={classes.full_width} {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
};
export default DatePickerSelect;
