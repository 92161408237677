import React from "react";
import RemoveAgencyButtonProperties from "./RemoveAgencyButtonProperties";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import {useI18nContext} from "../../../contexts/I18nContext";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import {useCommonContext} from "../../../contexts/CommonContext";
import RemoveAgencyStore from "./RemoveAgencyStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Button from "../../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import Icon from "../../../ui_components/Icon/Icon";
import IconName from "../../../ui_components/Icon/enums/IconName";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";

const RemoveAgencyButton: React.FC<RemoveAgencyButtonProperties> = ({
                                                                        onClick = () => {
                                                                        },
                                                                        onSave = () => {
                                                                        },
                                                                        showIcon = true,
                                                                        showText = true,
                                                                        disabled,
                                                                        className = "",
                                                                        agency,
                                                                        rounded = false,
                                                                        size = ButtonSize.DEFAULT,
                                                                        type = ButtonType.DEFAULT,
                                                                    }) => {
    const i18n = useI18nContext();
    const confirm = useConfirmation();
    const commonContext = useCommonContext();
    const store = new RemoveAgencyStore(commonContext, agency);
    const userStore = useUserContext();

    const handleOnclick = async () => {
        onClick();
        const ok = await confirm(
            agency.name,
            i18n.translate("REMOVE_AGENCY_CONFIRMATION")
        );
        if (ok) {
            await store.remove();
            onSave();
        }
    };

    const text = i18n.translate("REMOVE_AGENCY");

    if (!userStore.hasAccess(Access.CREATE_AGENCY.valueOf())) {
        return <></>;
    }

    const Btn = () => {
        if (rounded) {
            return (
                <RoundedButton
                    onClick={handleOnclick}
                    className={className}
                    disabled={disabled}
                    tooltip={!showText ? text : ""}
                    text={showText ? text : ""}
                    size={size}
                    type={type}
                    icon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
                />
            );
        }
        return (
            <Button
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                startIcon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
            />
        );
    };

    return <Observer>{() => <Btn/>}</Observer>;
};

export default RemoveAgencyButton;
