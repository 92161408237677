import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    dashboard_container: {
        display: 'grid',
        rowGap: '16px'
    },
    tools_container:{
        display: 'flex',
        padding: '8px',
        marginBottom: '16px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '6px',
        boxSizing: 'border-box',
    },
    box_container:{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        borderRadius: '6px'
    },

}));

export {useStyles}