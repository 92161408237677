import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import MoneyExchangeRate from "../../entities/productOrder/moneyDelivery/MoneyExchangeRate";
import { MoneyExchangeService } from "../../service/moneyDelivery/MoneyExchangeRateService";

class MoneyExchangeStore {

    public moneyExchanges: MoneyExchangeRate[];
    public pagination: Pagination;
    public filter?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.moneyExchanges = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            moneyExchanges: observable,
            pagination: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.moneyExchanges.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await MoneyExchangeService.get(page, pageSize, this.filter);
        if (response.success) {
            runInAction(() => {
                this.moneyExchanges = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default MoneyExchangeStore;