import React, {useEffect} from 'react';
import AgencySalesByUserProperties from "./AgencySalesByUserProperties";
import {useStyles} from "./AgencySalesByUser.style";
import AgencySalesByUserStore from "./AgencySalesByUserStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import {useI18nContext} from "../../../contexts/I18nContext";
import InfoIcon from "@mui/icons-material/Info";
import PriceLockup from "../../../ui_components/PriceLockup/PriceLockup";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import SaleByUserResponse from "../../../model/sales/SaleByUserResponse";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import SellerSalesOverviewButton from "../../Buttons/SellerSalesOverviewButton/SellerSalesOverviewButton";

const AgencySalesByUser: React.FC<AgencySalesByUserProperties> = ({
                                                                      from,
                                                                      to,
                                                                      agencyId,
                                                                      title,
                                                                      className = '',
                                                                  }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const store = new AgencySalesByUserStore(commonStore, from, to, agencyId);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    const SellerRow: React.FC<SaleByUserResponse> = (data) => {
        return (
            <div className={classes.seller_row_container}>
                <div className={classes.item_row}>
                    <ShowSellerBasicInfo autofill name={data.userName} email={data.userEmail}/>
                    <PriceLockup value={data.data.breakdown.reduce((t, c) => t + c.total, 0)}
                                 size={20}
                                 theme={"primary"}
                                 orders={data.data.breakdown.reduce((t, c) => t + c.amount, 0)}
                                 title={"Total de ventas"}/>
                    <div className={classes.item_sales_container}>
                        {data.data.breakdown.map((dd, ii) => (
                            <PriceLockup
                                key={ii}
                                value={dd.total}
                                title={i18n.translate(dd.serviceName)}
                                total={data.data.breakdown.reduce((t, c) => t + c.total, 0)}
                                size={15}
                                orders={dd.amount}
                                theme={"success"}/>
                        ))}
                    </div>
                    <ExpandoMenu>
                        <>
                            <SellerSalesOverviewButton
                                sellerId={data.userId}
                                from={from || 0}
                                to={to || 0}
                            />
                        </>
                    </ExpandoMenu>
                </div>
            </div>
        )
    }

    return <Observer>{() => (
        <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            <div className={classes.item_row_container}>
                {store.sales && store.sales.breakdown.map((d, i) => (
                    <SellerRow key={i} {...d}/>
                ))}
            </div>
            {store.sales?.breakdown.length === 0 &&
                <div className={classes.no_content_container} onClick={() => store.getElements()}>
                    <InfoIcon/>
                    {i18n.translate("NO_SALES")}
                </div>
            }
            <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                         text={`${i18n.translate("LOADING")} ${title || ''}`}/>
        </div>
    )}</Observer>


}


export default AgencySalesByUser;