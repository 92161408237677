import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ShopOrderService } from "../../../service/shop/ShopOrderService";


class RemoveShopOrderStore {

    public shopOrderId: string;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, shopOrderId: string) {
        this.shopOrderId = shopOrderId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ShopOrderService.remove(this.shopOrderId);
        if (!response.success && response.status === 400 && response.error === 'ERROR_00_400_44') {
            await this.commonStore.showErrorToast('No se pudo eliminar la venta porque está en un estado que lo imposibilita');
        } else {
            this.commonStore.processErrors(response);
        }        
        return response.success;
    }
}

export default RemoveShopOrderStore;