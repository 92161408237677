import React from 'react';
import {useStyles} from "./ShowEditTopupSalePrice.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import ShowEditTopupSalePriceProperties from './ShowEditTopupSalePriceProperties';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import Button from '../../../ui_components/Button/Button';
import ButtonType from '../../../ui_components/Button/ButtonType';
import { useCommonContext } from '../../../contexts/CommonContext';
import ShowEditTopupSalePriceStore from './ShowEditTopupSalePriceStore';
import { Observer } from 'mobx-react-lite';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreateIcon from '@mui/icons-material/Create';

const ShowEditTopupSalePrice: React.FC<ShowEditTopupSalePriceProperties> = ({
                                                      value,
                                                      salePriceId,
                                                      productId,
                                                      onSave,
                                                      className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();
    const commonContext = useCommonContext();
    const store = new ShowEditTopupSalePriceStore(commonContext, salePriceId, productId, value);

    const handleClickOpen = () => {
        store.openModal();
    };

    const handleClose = () => {
        store.closeModal();
    };

    const handleAccept = async () => {
        const saved = await store.updateTopupSalePrice();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    };

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newVal = event.target.value;
        store.value = +newVal;
    }

    return <Observer>
        {() => (
        <>
            <div className={clsx(classes.show_price_container, className)}>
                <div className={clsx(
                    classes.number,
                    classes.positive
                )}>
                    {CurrencyModule.format(value, 'DOLLAR')}
                    <CreateIcon className={classes.edit_icon} onClick={handleClickOpen}/>
                </div>
                <div className={classes.currency}>{i18n.translate('DOLLAR')}</div>
            </div>
            <Dialog open={store.isModalOpen} onClose={handleClose}>
                <DialogTitle>{i18n.translate('EDIT_VALUE')}</DialogTitle>
                <DialogContent>
                    <TextInput
                        type={TextInputType.NUMBER}
                        value={store.value}
                        onChange={onValueChange}
                        label={'Valor'}
                        className={classes.input}
                        startIcon={AttachMoneyIcon}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.input} onClick={handleClose}
                        type={ButtonType.SECONDARY}
                        text={i18n.translate("CANCEL")}/>
                    <Button
                        className={classes.input} onClick={handleAccept}
                        type={ButtonType.SECONDARY}
                        text={i18n.translate("SAVE")}/>
                </DialogActions>
            </Dialog>
        </>
    )}
    </Observer>
}


export default ShowEditTopupSalePrice;