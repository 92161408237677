const values = {
    "DELIVERY_PERSON": "Repartidores",
    "DELIVERY_PERSON_SINGULAR": "Repartidor",
    "SHIPPING_ASSIGMENT": "Asignación de envío",
    "NO_DELIVERY_PERSON_TITLE": "No hay repartidores!!!",
    "NO_DELIVERY_PERSON_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "DELIVERY_PERSON_FILTER_PLACEHOLDER": "Nombre, número, correo",
    "EDIT_DELIVERY_PERSON": "Editar Repartidor",
    "NEW_DELIVERY_PERSON": "Nuevo Repartidor",
    "ERROR_00_400_43": "Ya existe un repartidor con este nombre",
    "ERROR_00_400_44": "Ya existe un repartidor con este teléfono",
    "ERROR_00_400_41": "Ya existe un repartidor con este correo",
    "ERROR_00_400_40": "Email del repartidor inválido",
    "ERROR_00_400_54": "Hay diferencias entre los repartidores de estos paquetes",
    "DELIVERY_COST": "Costos de entrega",
    "DELIVERY_COST_FILTER_PLACEHOLDER": "Ubicación, moneda",
    "NO_DELIVERY_COST_TITLE": "No hay costos de entrega!!!",
    "NO_DELIVERY_COST_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "REMOVE_DELIVERY_PERSON_CONFIRMATION": "¿Está seguro que desea eliminar el repartidor?",
    "REMOVE_DELIVERY_COST_CONFIRMATION": "¿Está seguro que desea eliminar el costo de entrega?",
    "REMOVE_DELIVERY_PERSON": "Eliminar repartidor",
    "REMOVE_DELIVERY_COST": "Eliminar costo de entrega",
    "NEW_DELIVERY_COST": "Nuevo costo de entrega",
    "COST_OF_THE_DELIVERY": "Costo de la entrega",
    "SELECT_DELIVERY_PERSON": "Asignar repartidor",
    "UPDATE_SHIPMENT": "Actualizar envío",
    "DEASSIGN_SHIPMENT": "Desasignar envío",
    "MANAGE_SHIPMENT": "Gestionar Envío",
    "SELECT_ORGANIZER_PERSON": "Asignar organizador",
    "NO_ASSIGNED_DELIVERY_PERSON_TITLE": "No hay repartidores asignados!!!",
    "NO_ASSIGNED_DELIVERY_PERSON_SUBTITLE": "Escoge uno o más repartidores para esta entrega",
    "CONFIRM_RECEIVED": "Confirmar recibido",
    "CONFIRM_RECEIVED_CONFIRMATION": "¿Está seguro de que está recibido por este repartidor?",
    "CONFIRM_DELIVERED": "Confirmar entrega",
    "CONFIRM_DELIVERED_CONFIRMATION": "¿Está seguro de que está entregado por este repartidor?",
    "REMOVE_DELIVERY_PERSON_INFO": "Eliminar asignación",
    "REMOVE_DELIVERY_PERSON_INFO_CONFIRMATION": "¿Está seguro de que desea eliminar esta asignación?",
    "SELECT_DELIVERY_PERSON_PLACEHOLDER": "Seleccione Repartidor",
    "NO_DELIVERY_PERSONS_TITLE": "No hay repartidores!!!",
    "TRANSFER_BALANCE": "Transferir saldo",
    "FROM_USER": "De",
    "TO_USER": "Para",
    "UPDATE_DELIVERY_PERSON_PASSWORD": "Actualiar clave",
    "NO_ARTICLE_STOCK_TITLE": "No hay combos en el inventario!!!"
}

export default values;
