class CustomRange {
    public text: string;
    public key: string;
    public from: Date | null;
    public to: Date | null;

    constructor(key: string, text: string, from: Date | null, to: Date | null) {
        this.key = key;
        this.text = text;
        this.from = from;
        this.to = to;
    }

    public isCurrentRange(from: Date | null, to: Date | null): boolean {
        return from?.valueOf() === this.from?.valueOf() && to?.valueOf() === this.to?.valueOf();
    }

}

export default CustomRange;