import React from 'react';
import NewPromotionButtonProperties from "./NewPromotionButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewPromotionStore from "./NewPromotionStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewPromotionButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import useOnEnterPress from '../../../hooks/EnterPress';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import DatePickerSelect from '../../../ui_components/DatePickerSelect/DatePickerSelect';
import clsx from 'clsx';
import ArticleDescriptionInput from '../../Inputs/ArticleDescriptionInput/ArticleDescriptionInput';
// import MultiSelectOption from '../../../ui_components/MultiSelect/MultiSelectOption';

const NewPromotionButton: React.FC<NewPromotionButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  promotionId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT,
                                                                  customButton = null,
                                                                  agencyId
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewPromotionStore(commonContext, promotionId, agencyId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const setHourAndMinuteInEpoch = (originalEpoch: number, newHour: number, newMinute: number) => {
        const originalDate = new Date(originalEpoch);      
        originalDate.setHours(newHour);
        originalDate.setMinutes(newMinute);      
        return originalDate.getTime();
    }

    const onNameChange = (newName: string) => {
        store.promotion.name = newName;
    };
    const onDescriptionChange = (newDescription: string) => store.promotion.description = newDescription;
    const onStartDateChange = (newStartDate: number | undefined) => {
        store.promotion.startDate = newStartDate ? setHourAndMinuteInEpoch(newStartDate, 0, 0) : 0;
    }
    const onEndDateChange = (newEndDate: number | undefined) => {
        store.promotion.endDate = newEndDate ? setHourAndMinuteInEpoch(newEndDate, 23, 59) : 0;
    }
    // const onStatusChange = (options: MultiSelectOption[]) => {
    //     // onChange(options.map(t => t.value));
    //     console.log(12333, options)
    // }

    const onPromotionSave = async () => {
        const saved = await store.save();
        if (saved) {
            if (onSave) {
                onSave(store.promotion);
            }
            store.closeModal();
        }
    }
    useOnEnterPress(onPromotionSave, [store]);

    const text = i18n.translate((store.isEditing ? "EDIT_PROMOTION" : "NEW_PROMOTION"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (customButton != null) {
            return <div className={classes.custom_button_container} onClick={handleOnclick}>{customButton}</div>;
        }
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <SellerNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                            required={true}
                            value={store.promotion?.name}
                            onChange={onNameChange}/>
                        <ArticleDescriptionInput className={clsx(classes.input, classes.flex_1)}
                            lines={5}
                            value={store.promotion?.description}
                            onChange={onDescriptionChange}/>
                        <div className={classes.display_flex}>
                            <DatePickerSelect label={'Inicio (dd/mm/yyyy)'} selectedValue={store.promotion?.startDate} onChange={onStartDateChange}/>
                            <DatePickerSelect label={'Fin (dd/mm/yyyy)'} selectedValue={store.promotion?.endDate} onChange={onEndDateChange}/>
                        </div>
                        <div className={classes.btn_container}>
                            <Button
                                loading={store.loading} className={classes.input} onClick={onPromotionSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}/>
                            <Button
                                loading={store.loading} className={classes.cancel_btn} onClick={onCloseModal}
                                type={ButtonType.DEFAULT}
                                text={i18n.translate("CANCEL")}/>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewPromotionButton;
