import React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useStyles} from "./MainLayoutLarge.style";
import FlexContainer from "../../../../ui_components/FlexContainer/FlexContainer";
import Direction from "../../../../ui_components/common/enums/Direction";
import Width from "../../../../ui_components/common/enums/Width";
import Header from "../../Header/Header";
import Alignment from "../../../../ui_components/common/enums/Alignment";
import SideMenu from "../../SideMenu/SideMenu";
import RouteRepository from "../../../../route/RouteRepository";
import {useI18nContext} from "../../../../contexts/I18nContext";
import {useCommonContext} from "../../../../contexts/CommonContext";
import PageLoading from "../../../../ui_components/PageLoading/PageLoading";
import {Observer} from "mobx-react-lite";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import {useUserContext} from "../../../../contexts/UserContext";

const MainLayoutLarge: React.FC = () => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const userStore = useUserContext();
    const location = useLocation();
    const currentPath = location.pathname;
    const currentRoute = RouteRepository.getRoute(currentPath);

    const hasAccess = !currentRoute
        || !currentRoute?.access
        || currentRoute.access.length === 0
        || currentRoute.access.some(a => userStore.hasAccess(a));
    if (!hasAccess) {
        return <Navigate to="/login"/>;
    }

    return (
        <FlexContainer
            direction={Direction.COLUMN}
            width={Width.FULL_WIDTH}
            fullHeight
            justifyContent={Alignment.START}
            alignItems={Alignment.START}
        >
            <Header/>
            {currentRoute &&
            <div className={classes.main_container}>
                <SideMenu/>
                <div className={classes.content_container}>
                    <Breadcrumb/>
                    <div className={classes.outlet}>
                        <Observer>{() => (<PageLoading isLoading={commonStore.loadingPage}
                                                       text={`${i18n.translate("LOADING")} ${i18n.translate(currentRoute.label)}`}/>)}
                        </Observer>
                        <Outlet/>
                    </div>
                </div>
            </div>}
        </FlexContainer>
    );
}

export default MainLayoutLarge;