import React from 'react';
import ContactBankCardButtonProperties from "./ContactBankCardButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ContactBankCardButtonStore from "./ContactBankCardButtonStore";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ContactBankCardButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ShowPagination from "../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
// import CurrencySelect from '../../Selects/CurrencySelect/CurrencySelect';
// import RemoveContactBankCardButton from "../RemoveContactBankCardButton/RemoveContactBankCardButton";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import NewContactBankCardButton from "../NewContactBankCardButton/NewContactBankCardButton";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactBankCardTypeSelect from '../../Selects/ContactBankCardTypeSelect/ContactBankCardTypeSelect';
import RemoveContactBankCardButton from '../RemoveContactBankCardButton/RemoveContactBankCardButton';

const ContactBankCardButton: React.FC<ContactBankCardButtonProperties> = ({
                                                                        onClick = () => {
                                                                        },
                                                                        onSave = () => {
                                                                        },
                                                                        showIcon = true,
                                                                        showText = true,
                                                                        disabled,
                                                                        className = '',
                                                                        clientId,
                                                                        contactId,
                                                                        rounded = false,
                                                                        size = ButtonSize.DEFAULT,
                                                                        type = ButtonType.DEFAULT
                                                                    }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ContactBankCardButtonStore(commonContext, clientId, contactId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onCostSaved = async () => {
        await store.cleanFilter()
    }


    const text = i18n.translate("CARDS");
    const Icon = CreditCardIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                       className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <div className={classes.body}>
                            <Box
                                width={Width.FULL_WIDTH}
                                justifyContent={Alignment.SPACE_BETWEEN}>
                                <ShowSellerBasicInfo name={store.contact?.name}
                                                     email={''}
                                                     phone={store.contact?.mobilePhone} autofill/>
                                <NewContactBankCardButton
                                    type={ButtonType.SECONDARY}
                                    rounded
                                    onSave={onCostSaved}
                                    contactId={store.contactId}/>
                            </Box>
                            <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                                 justifyContent={Alignment.SPACE_BETWEEN}>                                
                                <ContactBankCardTypeSelect onChange={(value) => {
                                    store.contactBankCardTypeFilter = value
                                }}/>
                                <div className={classes.btn_container}>
                                    <Button
                                        onClick={() => {
                                            store.getContactBankCards()
                                        }}
                                        className={classes.search_btn}
                                        text={i18n.translate("SEARCH")}
                                    />
                                    <Button
                                        onClick={() => {
                                            store.cleanFilter()
                                        }}
                                        className={classes.search_btn}
                                        startIcon={<FilterAltOffIcon/>}
                                        tooltip={i18n.translate("CLEAN_FILTER")}
                                    />
                                </div>
                            </Box>
                            {!store.isEmpty &&
                            <div className={classes.contacts_table}>
                                <ShowPagination onChange={onPaginationChange}
                                                isModalPagination={true}
                                                elementLabel={i18n.translate("CARD")}
                                                pagination={store.pagination}/>
                                <div>
                                    {store.contactBankCards.map((contactBankCard, i) => (
                                        <div className={classes.cost_info_container}>
                                            <div>{contactBankCard.bank}</div>
                                            <div>{contactBankCard.cardNumber}</div>
                                            <div className={classes.menu_container}>
                                            <NewContactBankCardButton
                                                type={ButtonType.SECONDARY}
                                                size={ButtonSize.SMALL}
                                                showText={false}
                                                onSave={onCostSaved}
                                                contactBankCard={contactBankCard}
                                                contactId={store.contactId}/>
                                            <RemoveContactBankCardButton
                                                contactBankCard={contactBankCard}
                                                type={ButtonType.SECONDARY}
                                                size={ButtonSize.SMALL}
                                                showText={false}
                                                onSave={() => {
                                                    store.getContactBankCards()
                                                }}
                                            />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    isModalPagination={true}
                                    elementLabel={i18n.translate("CARD")}
                                    pagination={store.pagination}/>
                            </div>
                            }
                            {store.isEmpty &&
                            <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_CONTACT_BANK_CARD_TITLE")}
                                         subtitle={i18n.translate("NO_CONTACT_BANK_CARD_SUBTITLE")}/>}
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ContactBankCardButton;
