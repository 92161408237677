import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./SalePrices.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import SalePriceStore from "./SalePriceStore";
import {useCommonContext} from "../../contexts/CommonContext";
import NewSalePriceButton from '../../shared_components/Buttons/NewSalePriceButton/NewSalePriceButton';
import ButtonType from '../../ui_components/Button/ButtonType';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import SalePriceTableHeader from './SalePriceTableHeader/SalePriceTableHeader';
import SalePriceTableMoneyRow from './SalePriceTableMoneyRow/SalePriceTableMoneyRow';
import MessageCard from '../../ui_components/MessageCard/MessageCard';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SalePriceTableTopupRow from './SalePriceTableTopupRow/SalePriceTableTopupRow';
import SalePriceTableReferenceItemRow from './SalePriceTableReferenceItemRow/SalePriceTableReferenceItemRow';
import SalePriceTableArticleRow from './SalePriceTableArticleRow/SalePriceTableArticleRow';
import SalePriceTableMiscellanyRow from './SalePriceTableMiscellanyRow/SalePriceTableMiscellanyRow';

const SalePrices: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const salePriceStore = new SalePriceStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        salePriceStore.getElements();
        salePriceStore.getTopupProducts();
        salePriceStore.getArticles();
        salePriceStore.getMoneyServiceFees();
        salePriceStore.getReferenceItems()
        salePriceStore.getMiscellanyCategoryItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await salePriceStore.getElements();
    }

    return (
        <Observer>
            {() => (
                <div className={classes.clients_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <NewSalePriceButton type={ButtonType.SECONDARY} onSave={onUpdate} rounded/>
                    </Box>

                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={salePriceStore.filter}
                                     placeholder={i18n.translate("CLIENT_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         salePriceStore.setFilter(value)
                                     }} onEnter={() => salePriceStore.search()}/>
                        <Button
                            onClick={() => {
                                salePriceStore.search()
                            }}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>

                    {salePriceStore.isEmpty &&
                    <MessageCard icon={WidgetsIcon} title={i18n.translate("NO_SALE_PRICES_TITLE")}
                                 subtitle={i18n.translate("NO_SALE_PRICES_SUBTITLE")}/>}

                    {!salePriceStore.isEmpty &&<Paper sx={{ width: 'calc(100vw - 280px)', overflow: 'auto' }}>
                        <TableContainer sx={{ maxHeight: 600 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <SalePriceTableHeader onUpdate={onUpdate} salePrices={salePriceStore.salePrices}/>
                            <TableBody>
                                <SalePriceTableMoneyRow moneyServiceFees={salePriceStore.moneyServiceFees} salePrices={salePriceStore.salePrices} onUpdate={onUpdate}/>
                                <SalePriceTableTopupRow products={salePriceStore.topupProducts} salePrices={salePriceStore.salePrices} onUpdate={onUpdate}/>
                                <SalePriceTableMiscellanyRow miscellanyCategories={salePriceStore.miscellanyCategories} salePrices={salePriceStore.salePrices} onUpdate={onUpdate}/>
                                <SalePriceTableArticleRow articles={salePriceStore.articles} salePrices={salePriceStore.salePrices} onUpdate={onUpdate}/>
                                <SalePriceTableReferenceItemRow referenceItems={salePriceStore.referenceItems} salePrices={salePriceStore.salePrices} onUpdate={onUpdate}/>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Paper>}
                    
                </div>
            )}
        </Observer>
    )
}

export default SalePrices;
