import React from 'react';
import MoneyRemittanceResumeProperties from "./MoneyRemittanceResumeProperties";
import {Observer} from "mobx-react-lite";
import ShowClientToContactInfo from '../../../Info/ShowClientToContactInfo/ShowClientToContactInfo';
import {useStyles} from './MoneyRemittanceResume.style';
import ShowInvoiceAdditionalPrices from '../../../Info/ShowInvoiceAdditionalPrices/ShowInvoiceAdditionalPrices';
import {useI18nContext} from '../../../../contexts/I18nContext';
import ShowContactBankCard from '../../../Info/ShowContactBankCard/ShowContactBankCard';
import ShowMoneyDeliveryBudgetDetails
    from "../../../Info/ShowMoneyDeliveryBudgetDetails/ShowMoneyDeliveryBudgetDetails";
import PaymentsTable from "../../../Info/PaymentsTable/PaymentsTable";
import { Alert } from '@mui/material';
import OrderAdditionalPrice from '../../../../entities/OrderAdditionalPriceRequest';
import Table from '../../../../ui_components/Table/Table';
import TableType from '../../../../ui_components/Table/TableType';
import Currency from '../../../../entities/Currency';
import { CurrencyModule } from '../../../../util/Currency';


const MoneyRemittanceResume: React.FC<MoneyRemittanceResumeProperties> = ({
                                                                              store
                                                                          }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    store.checkMoneyDeliveryPossiblyRepeated();

    return <Observer>
        {() => (
            <div className={classes.container}>
                {store.selectedContact && <ShowClientToContactInfo
                    client={store.selectedClient}
                    contact={store.selectedContact}/>}

                {store.isCardRemittance &&
                <div className={classes.bank_card_container}>
                    {i18n.translate('BANK_CARD_MONEY_REMITTANCE')}:
                    {store.contactBankCard && <ShowContactBankCard bank={store.contactBankCard?.bank}
                                                                   cardNumber={store.contactBankCard?.cardNumber}/>}
                </div>}

                {store.possibleRepeatedDelivery &&
                    <Alert variant="filled" severity="error">
                        <strong>Alerta !!!</strong> Ya creaste en el día de hoy envío{store.possibleRepeatedDeliveriesOrders.length > 1 ? 's ' : ' '} 
                        con los mismos datos que el que están creando (<strong>{store.possibleRepeatedDeliveriesOrders.join(', ')}</strong>).
                        Verifique si no estás creándolo repetido.
                    </Alert>
                }

                {store.budgetDetails &&
                <ShowMoneyDeliveryBudgetDetails
                    budgetDetails={store.budgetDetails} showHeader={false} isLoading={false}/>}
                {store.payments.length > 0 &&
                <PaymentsTable payments={store.payments} totalLabel={'Subtotal'}/>}

                {store.additionalPrices.length > 0 &&
                <ShowInvoiceAdditionalPrices additionalPrices={store.additionalPrices.map((p) => {
                    return new OrderAdditionalPrice(p.amount, p.comment, p.isPercentage)
                })}/>}

                <div className={classes.total_container}>
                    <Table tableType={TableType.SECONDARY} body={[['Total a pagar', CurrencyModule.format(store.getFinalTotalToPay(), Currency.DOLLAR)]]} header={[]}/>
                </div>
            </div>
        )}
    </Observer>
}

export default MoneyRemittanceResume;
