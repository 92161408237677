import React from 'react';
import ShowConfirmationProperties from "./ShowConfirmationProperties";
import {useStyles} from "./ShowConfirmation.style";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";

const ShowConfirmation: React.FC<ShowConfirmationProperties> = ({
                                                      value,
                                                      className = '',
                                                  }) => {

    const classes = useStyles();
    const i18n = useI18nContext();
    if (!value) {
        return <></>;
    }
    return (
        <div className={clsx(classes.show_confirmation_container, className)}>
            <div className={classes.label}>{i18n.translate("CONFIRMATION")}</div>
            <div className={classes.content}>
                <ConfirmationNumberIcon className={classes.icon}/>
                <div className={classes.value_container}>
                    {value}
                </div>
            </div>
        </div>
    )
}


export default ShowConfirmation;