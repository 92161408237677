import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import MoneyExchangeRate from "../../../entities/productOrder/moneyDelivery/MoneyExchangeRate";
import { MoneyExchangeService } from "../../../service/moneyDelivery/MoneyExchangeRateService";
import Agency from "../../../entities/Agency";
import { AgencyService } from "../../../service/AgencyService";

class AgencyMoneyExchangeStore {

    public agencyId: string;
    public agency: Agency;
    public isModalOpen: boolean;
    public loading: boolean;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;
    public pagination: Pagination;
    public moneyExchanges: MoneyExchangeRate[];


    constructor(commonStore: CommonStore, agencyId: string) {
        this.agencyId = agencyId;
        this.isModalOpen = false;
        this.agency = {} as Agency;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.moneyExchanges = [];
        makeAutoObservable(this);
    }

    @action
    public async loadAgency() {
        const response = await AgencyService.find(this.agencyId!)
        if (response.success && response.data) {
            this.agency = response.data;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async getMoneyExchanges(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await MoneyExchangeService.get(page, pageSize, '', this.agencyId);
        if (response.success) {
            runInAction(() => {
                this.moneyExchanges = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        await this.loadAgency();
        await this.search();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.moneyExchanges.length === 0;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getMoneyExchanges(page, pageSize);
    }

    @action
    public async search() {
        await this.getMoneyExchanges(1);
    }

}

export default AgencyMoneyExchangeStore;