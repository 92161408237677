import errors from './error'
import basic from './basic'
import seller from './seller'
import articles from './articles'

export default Object({
    ...errors,
    ...basic,
    ...seller,
    ...articles
})