const colors = [
    "hsl(181, 70%, 50%)",
    "hsl(278, 70%, 50%)",
    "hsl(338, 70%, 50%)",
    "hsl(276, 70%, 50%)",
    "hsl(336, 70%, 50%)",
    "hsl(120, 70%, 50%)",
    "hsl(60, 70%, 50%)",
    "hsl(10, 70%, 50%)",
    "hsl(20, 70%, 50%)",
    "hsl(30, 70%, 50%)",
    "hsl(250, 70%, 50%)",
    "hsl(260, 70%, 50%)",
    "hsl(270, 70%, 50%)",
]

export module Color {
    export function get(index: number): string {
        return colors[index % colors.length]
    }
}