import React from 'react';
import NewClientButtonProperties from "./NewClientButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewClientStore from "./NewClientStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import EmailInput from "../../Inputs/EmailInput/EmailInput";
import {useStyles} from "./NewClientButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PhoneInput from "../../Inputs/PhoneInput/PhoneInput";
import useOnEnterPress from '../../../hooks/EnterPress';
import LastNameInput from '../../Inputs/LastNameInput/LastNameInput';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import Phone from "../../../entities/Phone";
import CountryCode from "../../../entities/CountryCode";
import IdentificationInput from '../../Inputs/IdentificationInput/IdentificationInput';
import AddressInput from '../../Inputs/AddressInput/AddressInput';
import LocationInput from '../../Inputs/LocationInput/LocationInput';
import NationalityInput from '../../Inputs/NationalityInput/NationalityInput';
import DatePickerSelect from '../../../ui_components/DatePickerSelect/DatePickerSelect';
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import DifficultPersonCheckbox from '../../Inputs/DifficultPersonCheckbox/DifficultPersonCheckbox';
import clsx from 'clsx';
import Group from '../../../ui_components/Group/Group';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';


const NewClientButton: React.FC<NewClientButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  clientId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT,
                                                                  customButton = null,
                                                                  agencyId
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewClientStore(commonContext, clientId, agencyId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => {
        store.client.name = newName;
    };
    const onLastNameChange = (newLastName: string) => store.client.lastName = newLastName;
    const onDocumentIdChange = (newDocumentId: string) => store.client.documentId = newDocumentId;
    const onAddressChange = (newAddress: string) => store.client.address = newAddress;
    const onCityChange = (newCity: string) => store.client.city = newCity;
    const onStateChange = (newState: string) => store.client.state = newState;
    const onZipCodeChange = (newZipCode: string) => store.client.zipCode = newZipCode;
    const onNationalityChange = (newNationality: string) => store.client.nacionality = newNationality;
    const onEmailChange = (newEmail: string) => store.client.email = newEmail;
    const onPhoneChange = (newPhone: Phone) => store.client.phone = newPhone;
    const onBirthdayChange = (newBirthday: number | undefined) => store.client.birthday = newBirthday;
    const onObservationChange = (newObservation: string) => store.client.observation = newObservation;
    const onDifficultPersonChange = (newDifficultPerson: boolean) => store.client.difficultPerson = newDifficultPerson;
    const onSendDeliveryNotificationsEmailChange = (newSendDeliveryNotificationsEmail: boolean) => store.client.sendDeliveryNotificationsEmail = newSendDeliveryNotificationsEmail;
    const onSendDeliveryNotificationsSmsChange = (newSendDeliveryNotificationsSms: boolean) => store.client.sendDeliveryNotificationsSms = newSendDeliveryNotificationsSms;
    const onSendPromotionNotificationsEmailChange = (newSendPromotionNotificationsEmail: boolean) => store.client.sendPromotionNotificationsEmail = newSendPromotionNotificationsEmail;
    const onSendPromotionNotificationsSmsChange = (newSendPromotionNotificationsSms: boolean) => store.client.sendPromotionNotificationsSms = newSendPromotionNotificationsSms;

    const onClientSave = async () => {
        const saved = await store.save();
        if (saved) {
            if (onSave) {
                onSave(store.client);
            }
            store.closeModal();
        }
    }
    useOnEnterPress(onClientSave, [store]);

    const text = i18n.translate((store.isEditing ? "EDIT_CLIENT" : "NEW_CLIENT"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (customButton != null) {
            return <div className={classes.custom_button_container} onClick={handleOnclick}>{customButton}</div>;
        }
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <div className={classes.display_flex}>
                            <SellerNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                                             required={true}
                                             value={store.client?.name}
                                             onChange={onNameChange}/>
                            <LastNameInput error={i18n.translate(store.lastNameError)} fullWidth
                                           className={classes.input}
                                           required={true}
                                           value={store.client?.lastName}
                                           onChange={onLastNameChange}/>
                            <IdentificationInput label={'Pasaporte/ID'}
                                                 fullWidth className={classes.input}
                                                 value={store.client?.documentId}
                                                 onChange={onDocumentIdChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <AddressInput
                                onChange={onAddressChange}
                                lines={2}
                                required={true}
                                value={store.client?.address}
                                className={classes.input}
                                error={i18n.translate(store.addressError)}
                                fullWidth/>
                        </div>
                        <div className={classes.display_flex}>
                            <LocationInput label={'Ciudad'} error={i18n.translate(store.cityError)} fullWidth
                                           className={classes.input}
                                           required={true}
                                           value={store.client?.city}
                                           onChange={onCityChange}/>
                            <LocationInput label={'Estado'} error={i18n.translate(store.stateError)} fullWidth
                                           className={classes.input}
                                           required={true}
                                           value={store.client?.state}
                                           onChange={onStateChange}/>
                            <LocationInput label={'ZIP Code'} error={i18n.translate(store.zipCodeError)} fullWidth
                                           className={classes.input}
                                           required={true}
                                           value={store.client?.zipCode}
                                           onChange={onZipCodeChange}/>

                        </div>
                        <div className={classes.display_flex}>
                            <PhoneInput error={i18n.translate(store.phoneError)} fullWidth className={classes.input}
                                        required={true}
                                        value={store.client?.phone}
                                        countryCode={CountryCode.USA}
                                        onChange={onPhoneChange}/>
                            <EmailInput error={i18n.translate(store.emailError)} fullWidth className={classes.input}
                                        value={store.client?.email}
                                        onChange={onEmailChange}/>
                            <NationalityInput fullWidth className={classes.input}
                                              value={store.client?.nacionality}
                                              onChange={onNationalityChange}/>
                            <DatePickerSelect label={'Fecha de nacimiento'} selectedValue={store.client?.birthday} onChange={onBirthdayChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <CommentInput className={clsx(classes.input, classes.flex_1)}
                                          value={store.client?.observation}
                                          onChange={onObservationChange}/>
                            <DifficultPersonCheckbox selected={store.client?.difficultPerson}
                                                     onChange={onDifficultPersonChange}/>
                        </div>
                        <div className={clsx(classes.input, classes.display_flex)}>
                            <Group title={'Notificaciones de remesas'}>
                                <div className={classes.group_content_container}>
                                    <div><Checkbox text={'E-mail'} selected={store.client.sendDeliveryNotificationsEmail}
                                        onChange={onSendDeliveryNotificationsEmailChange}/></div>
                                    <div><Checkbox text={'SMS'} selected={store.client.sendDeliveryNotificationsSms}
                                        onChange={onSendDeliveryNotificationsSmsChange}/></div>
                                </div>
                            </Group>
                            <Group title={'Envio de promosiones'}>
                                <div className={classes.group_content_container}>
                                    <div><Checkbox text={'E-mail'} selected={store.client.sendPromotionNotificationsEmail}
                                        onChange={onSendPromotionNotificationsEmailChange}/></div>
                                    <div><Checkbox text={'SMS'} selected={store.client.sendPromotionNotificationsSms}
                                        onChange={onSendPromotionNotificationsSmsChange}/></div>
                                </div>
                            </Group>
                        </div>
                        <div className={classes.btn_container}>
                            <Button
                                loading={store.loading} className={classes.input} onClick={onClientSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}/>
                            <Button
                                loading={store.loading} className={classes.cancel_btn} onClick={onCloseModal}
                                type={ButtonType.DEFAULT}
                                text={i18n.translate("CANCEL")}/>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewClientButton;
