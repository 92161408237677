import React from 'react';
import clsx from "clsx";
import ShowMoneyDeliveryCostsButtonRowProperties from './ShowMoneyDeliveryCostsButtonRowProperties';
import { useStyles } from '../ShowMoneyDeliveryCostsButton.style';
import ShowInterval from '../../../Info/ShowInterval/ShowInterval';
import ShowText from '../../../Info/ShowText/ShowText';

const ShowMoneyDeliveryCostsButtonRowSmall: React.FC<ShowMoneyDeliveryCostsButtonRowProperties> = ({
                                                                                        moneyPrices,
                                                                                        moneyDeliveryFee
                                                                                    }) => {

    const classes = useStyles();

    const getValue = () => {
        const current = moneyPrices.find(t => t.id === moneyDeliveryFee.id);
        if (!current) {
            return <ShowText text={'-'} title={'Valor de costo'}/>
        }
        return <ShowText text={current.isPercentage ?
            (current.salePrice ? current.salePrice.toString() + '%' : '-') :
            (current.salePrice ? '$' + current.salePrice.toString() : '-')
        } title={'Valor de costo'}/>
    }
    
    return (
        <div className={clsx(classes.show_money_price_info_container_small)}>
            <ShowInterval from={moneyDeliveryFee.fromValue} to={moneyDeliveryFee.toValue}/>
            {getValue()}         
        </div>
    )
}

export default ShowMoneyDeliveryCostsButtonRowSmall;
