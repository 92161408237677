import React from 'react';
import ArticlePriceButtonProperties from "./ArticlePriceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ArticlePriceStore from "./ArticlePriceStore";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ArticlePriceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ShowPagination from "../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import ShowGeo from '../../Info/ShowGeo/ShowGeo';
import ShowPrice from '../../Info/ShowPrice/ShowPrice';
import RemoveArticlePriceButton from '../RemoveArticlePriceButton/RemoveArticlePriceButton';
import SidePanel from '../../../ui_components/SidePanel/SidePanel';
import ShowSellerBasicInfo from '../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo';
import NewArticlePriceButton from '../NewArticlePriceButton/NewArticlePriceButton';

const ArticlePriceButton: React.FC<ArticlePriceButtonProperties> = ({
                                                                          onClick = () => {
                                                                          },
                                                                          onSave = () => {
                                                                          },
                                                                          showIcon = true,
                                                                          showText = true,
                                                                          disabled,
                                                                          className = '',
                                                                          articleId,
                                                                          rounded = false,
                                                                          size = ButtonSize.DEFAULT,
                                                                          type = ButtonType.DEFAULT
                                                                      }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ArticlePriceStore(commonContext, articleId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onPriceCreate = async () => {
        await store.search();
    }

    const onPriceRemove = async () => {
        await store.search();
    }

    const text = i18n.translate("ARTICLE_PRICES")
    const Icon = AttachMoneyIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                    className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <Box
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.SPACE_BETWEEN}>
                            <ShowSellerBasicInfo autofill name={store.article.name}/>
                            <NewArticlePriceButton
                                type={ButtonType.SECONDARY}
                                rounded
                                onSave={onPriceCreate}
                                articleId={store.articleId}/>
                        </Box>
                        <div className={classes.body}>
                            {!store.isEmpty &&
                            <div className={classes.contacts_table}>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={i18n.translate("ARTICLE_PRICES")}
                                                pagination={store.pagination}/>
                                <div className={classes.elements_container}>
                                    {store.articlePrices.map((articlePrice, i) => (
                                        <div className={classes.contact_info_container}>
                                            <ShowGeo geo={articlePrice.geo}/>
                                            <ShowPrice value={articlePrice.price} currency={articlePrice.currency}/>
                                            <div className={classes.menu_container}>
                                                <RemoveArticlePriceButton
                                                    type={ButtonType.SECONDARY}
                                                    size={ButtonSize.SMALL}
                                                    onSave={onPriceRemove}
                                                    showText={false}
                                                    articlePrice={articlePrice}/>
                                                
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={i18n.translate("ARTICLE_PRICES")}
                                    pagination={store.pagination}/>
                            </div>
                            }
                            {store.isEmpty &&
                            <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_ARTICLE_PRICES_TITLE")}
                                         subtitle={i18n.translate("NO_ARTICLE_PRICES_SUBTITLE")}/>}
                        </div>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ArticlePriceButton;
