import CategoryInfo from "./inventoryItem/CategoryInfo";
import PackageItem from "./packageItem/PackageItem";
import WeightInfo from "./WeightInfo";

class ItemsAndCategories {
    public categoryInfo:CategoryInfo
    public packageItem: PackageItem[]
    public subFinalIndividualPrice?: number
    public finalIndividualPrice?:number
    public weightInfo?:WeightInfo
    public subFinalPrice?:number
    public finalPrice?:number

    constructor(categoryInfo: CategoryInfo, packageItem: PackageItem[], subFinalIndividualPrice?: number, finalIndividualPrice?:number, subFinalPrice?: number, finalPrice?:number,weightInfo?: WeightInfo) {
        this.categoryInfo = categoryInfo;
        this.packageItem = packageItem;
        this.subFinalIndividualPrice = (subFinalIndividualPrice==null) ? 0 : subFinalIndividualPrice
        this.finalIndividualPrice = (finalIndividualPrice==null) ? 0 : finalIndividualPrice
        this.weightInfo = (weightInfo==null) ? new WeightInfo() : weightInfo
        this.subFinalPrice = (subFinalPrice==null) ? 0 : subFinalPrice
        this.finalPrice = (finalPrice==null) ? 0 : finalPrice
    }
}
export default ItemsAndCategories