import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../MiscellanyOrder.style";
import clsx from "clsx";
import MiscellanyOrderRowProperties from "./MiscellanyOrderRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ViewInvoiceButton from "../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton";
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import {useUserContext} from '../../../contexts/UserContext';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import DeliveryOrderType from '../../../model/DeliveryOrderType';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import ShowArticleDescription from '../../../shared_components/Info/ShowArticleDescription/ShowArticleDescription';
import { CurrencyModule } from '../../../util/Currency';
import Currency from '../../../entities/Currency';
import RemoveMiscellanyOrderButton from '../../../shared_components/Buttons/RemoveMiscellanyOrderButton/RemoveMiscellanyOrderButton';

const MiscellanyOrderRowMedium: React.FC<MiscellanyOrderRowProperties> = ({
                                                                          miscellanyOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onMiscellanyOrderSelect,
                                                                          onUpdate,
                                                                          filter
                                                                      }) => {


    const classes = useStyles();
    const i18n = useI18nContext();
    const userStore = useUserContext();

    const onInternalMiscellanyOrderSelect = () => {
        onMiscellanyOrderSelect(miscellanyOrder.id);
    }

    return (
        <div
            className={clsx(classes.article_info_container, userStore.isRootAgency() ? classes.article_info_container_grid_template_big : classes.article_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onInternalMiscellanyOrderSelect}
                              selected={selectedDeliveryOrderIds.findIndex(t => t === miscellanyOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={miscellanyOrder.orderNumber}
                                     className={classes.order_number_text} textToHighlight={filter}/>
                </div>
                <ShowDate label={i18n.translate("CREATED")} timestamp={miscellanyOrder.createdAt}
                          className={classes.margin_top_4}/>
            </div>
            <div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={miscellanyOrder.clientName} title={'Cliente'} textToHighlight={filter}/>
                    {miscellanyOrder.clientPhone &&
                        <ShowPhoneNumber value={miscellanyOrder.clientPhone} showFlag={false} textToHighlight={filter}/>}
                </div>
            </div>
            <div>
                <ShowText text={miscellanyOrder.miscellanyCategoryName} title={'Categoria'}/>
            </div>
            <div>
                <div className={classes.title}>Descripción:</div>
                <ShowArticleDescription description={miscellanyOrder.description ?? '-'}/>
            </div>
            <div>
                <div className={classes.title}>{`${i18n.translate("TOTAL_TO_PAY")}:`}</div>
                <div className={classes.value}>{CurrencyModule.format(miscellanyOrder.totalToPay, Currency.DOLLAR)}</div>
            </div>
            <div>
                <ShowText text={miscellanyOrder.agencyName} title={'Agencia'}/>
                <ShowText text={miscellanyOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
            </div>
            <div className={classes.status_container}>
                <ShowOrderStatus status={miscellanyOrder.status}/>
                {miscellanyOrder.status !== ProductOrderStatus.DELIVERED &&
                    miscellanyOrder.status !== ProductOrderStatus.CANCELED &&
                    <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={miscellanyOrder.id} deliveryOrderType={DeliveryOrderType.MISCELLANY_ORDER}/>}
                {miscellanyOrder.status === ProductOrderStatus.DELIVERED &&
                    <ProductOrderStatusHistoryButton
                        detailsLink
                        productOrderId={miscellanyOrder.id}
                    />}
            </div>
            
            <ExpandoMenu>
                <>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderId={miscellanyOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={miscellanyOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    {/* <ObservationsButton
                        size={ButtonSize.SMALL}
                        deliveryOrderId={miscellanyOrder.id}
                        deliveryOrderNumber={miscellanyOrder.orderNumber}
                        deliveryOrderObservations={miscellanyOrder.observations}
                        showText
                        type={ButtonType.SECONDARY}
                    /> */}
                    <AllowAccess isRootAgency={true}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderId={miscellanyOrder.id}
                            deliveryOrderNumber={miscellanyOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    <RemoveMiscellanyOrderButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        miscellanyOrderId={miscellanyOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default MiscellanyOrderRowMedium;
