const values = {
    "MISCELLANY": "Misceláneas",
    "CATEGORIES": "Categorias",
    "MISCELLANY_CONFIGURATIONS": "Config. de Misceláneas",
    "MISCELLANY_CATEGORIES_CONFIGURATIONS": "Config. de Categoria de Misceláneas",
    "MISCELLANY_CATEGORIES": "Categorias de Misceláneas",
    "NEW_MISCELLANY_CATEGORY": "Nueva Categoria de Miscelánea",
    "EDIT_MISCELLANY_CATEGORY": "Editar Categoria de Miscelánea",
    "NO_MISCELLANY_CATEGORIES_TITLE": "No hay Categorias de Misceláneas!!!",
    "NO_MISCELLANY_CATEGORIES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "MISCELLANY_CATEGORY_FILTER_PLACEHOLDER": "Nombre, descripción",
    "REMOVE_MISCELLANY_CATEGORY": "Eliminar categoria de miscelánea",
    "REMOVE_MISCELLANY_CATEGORY_CONFIRMATION": "¿Está seguro que desea eliminar la categoria de isceláneas?",
    "NO_MISCELLANY_ORDER_TITLE": "No hay entregas de misceláneas!!!",
    "NO_MISCELLANY_ORDER_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "MISCELLANY_ORDER_FILTER_PLACEHOLDER": "Cliente, Número de orden",
}

export default values;