import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tab_container: {
        display: 'flex',
        width: "100%",
        marginTop: 10
    },
    row: {
        display: 'grid',
        flexGrow: 1,
        gridTemplateColumns: 'repeat( auto-fit, minmax(max(500px, 49%), 1fr))',
        columnGap: 8,
        rowGap: 8
    },
    box: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        padding: 2,
        minHeight: 250,
    },
    with_scroll:{
        overflowY: "auto"
    },
    with_height: {
        height: "500px"
    },
    span2: {
        gridColumn: "1 / -1",
    }
}));

export {useStyles}