import React from 'react';
import {useStyles} from "../DeliveryPerson.style";
import clsx from "clsx";
import DeliveryPersonRowProperties from "./DeliveryPersonRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";


const DeliveryPersonRowSmall: React.FC<DeliveryPersonRowProperties> = ({person, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.client_info_container_small)}>
            <ShowSellerBasicInfo autofill name={person.name} phone={person.phone}/>
            <div className={classes.menu_container_small}>
                {/*<NewDeliveryCostButton*/}
                {/*    size={ButtonSize.SMALL}*/}
                {/*    onSave={onUpdate}*/}
                {/*    clientId={client.id}*/}
                {/*    showText={false}*/}
                {/*    type={ButtonType.SECONDARY}*/}
                {/*/>*/}
            </div>
        </div>
    )
}

export default DeliveryPersonRowSmall;
