import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "15px",
        backgroundColor: theme.palette.common.white,
    },
    info_container: {
        display: "flex",
    }
}));

export {useStyles};
