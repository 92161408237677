import React, {useEffect, useMemo} from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./PackageDeliveryOverview.style";
import {Observer} from "mobx-react-lite";
import PackageDeliveryOverviewProperties from "./PackageDeliveryOverviewProperties";
import {useCommonContext} from "../../../contexts/CommonContext";
import PackageDeliveryOverviewStore from "./PackageDeliveryOverviewStore";
import PriceLockup from "../../../ui_components/PriceLockup/PriceLockup";
import clsx from "clsx";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";

const PackageDeliveryOverview: React.FC<PackageDeliveryOverviewProperties> = ({
                                                                              from,
                                                                              to,
                                                                              agencyId,
                                                                              filter,
                                                                              deliveryPersonId,
                                                                              status,
                                                                              geoId,
                                                                              shippingId,
                                                                              title,
                                                                              className = '',
                                                                              size = "medium",
                                                                              includeChildren = false
                                                                          }) => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = useMemo(() => {
        return new PackageDeliveryOverviewStore(commonStore, from, to, filter, deliveryPersonId, status, geoId, agencyId, shippingId, includeChildren)
        // eslint-disable-next-line
    }, [from, to, filter, deliveryPersonId, status, geoId, agencyId, shippingId]);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line
    }, [from, to, agencyId, filter, deliveryPersonId, status, geoId, shippingId]);

    return (
        <Observer>
            {() => (
                <div className={clsx(
                    classes.money_overview_container,
                    size === "small" && classes.money_overview_container_small,
                    size === "medium" && classes.money_overview_container_medium,
                    className)}>
                    {store.overview && !store.loading && <>
                        <div className={classes.prices}>
                            <PriceLockup value={store.overview.totalSales}
                                         title={"Total de ventas"}
                                         theme={"secondary"}
                                         size={18}
                                         withBackground
                                         orders={store.overview.ordersAmount}
                            />
                            {store.overview.unpaidOrdersAmount > 0 &&
                                <PriceLockup value={store.overview.unpaidTotal}
                                             title={"Ordenes por pagar"}
                                             theme={"danger"}
                                             size={18}
                                             withBackground
                                             orders={store.overview.unpaidOrdersAmount}
                                />
                            }
                            {store.overview.noShippingIdAmount > 0 &&
                                <PriceLockup 
                                             value={store.overview.noShippingIdTotal}
                                             title={"Sin numero de envio"}
                                             size={18}
                                             theme={"warning"}
                                             withBackground
                                             orders={store.overview.noShippingIdAmount}/>
                            }
                        </div>
                        <div className={clsx(
                            size === "medium" && classes.column,
                            size === "small" && classes.column_small,
                        )}>
                            {store.overview.statusBreakdown.map((c, i) => (
                                <PriceLockup key={i}
                                             value={c.totalSales}
                                             title={i18n.translate(c.status)}
                                             size={13}
                                             theme={"purple"} orders={c.ordersAmount}/>
                            ))}
                        </div>
                    </>}
                    <PageLoading className={classes.loading_container} loadingSize={25} isLoading={store.loading}
                                 text={`${i18n.translate("LOADING")} ${title || ''}`}/>
                </div>
            )}
        </Observer>
    )
}

export default PackageDeliveryOverview;
