import Request from "../model/Request";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import ProductOrderStatusHistory from "../entities/ProductOrderStatusHistory";

export module ProductOrderStatusHistoryService {
    export async function getStatusHistory(isRootAgency: boolean, productOrderId?: string): Promise<ProductOrderStatusHistory[]> {
        const url = Config.get("GET_PRODUCT_ORDER_STATUS_HISTORY_URL", {id: productOrderId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        const data = response.data.map((t: any) => new ProductOrderStatusHistory(
            t.id,
            t.remittanceId,
            t.date,
            t.status,
            t.comment,
            isRootAgency ? t.userId : null,
            isRootAgency ? t.userName : null,
        ));
        return data;
    }

}