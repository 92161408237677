import React from 'react';
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import MainLayoutLarge from "./MainLayoutLarge/MainLayoutLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MainLayoutSmall from "./MainLayoutSmall/MainLayoutSmall";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";

const MainLayout: React.FC = () => {
    return (
        <>
            <ViewSmall fullHeight>
                <MainLayoutSmall/>
            </ViewSmall>
            <ViewMedium fullHeight>
                <MainLayoutSmall/>
            </ViewMedium>
            <ViewLarge fullHeight>
                <MainLayoutLarge/>
            </ViewLarge>

        </>
    );
}

export default MainLayout;