import React from 'react';
import IconProperties from "./IconProperties";
import CountryCode from "../../../entities/CountryCode";
import ReactCountryFlag from "react-country-flag";

interface CountryFlagIconProps extends IconProperties {
    countryCode?: CountryCode
}

const CountryFlagIcon: React.FC<CountryFlagIconProps> = ({
                                                             className,
                                                             fontSize,
                                                             countryCode
                                                         }) => {
    const codeMap = new Map<CountryCode, string>([
        [CountryCode.USA, "US"],
        [CountryCode.CUBA, "CU"],
        [CountryCode.BRAZIL, "BR"],
        [CountryCode.MEXICO, "MX"],
        [CountryCode.URUGUAY, "UR"],
        [CountryCode.BELGIUM, "BL"],
        [CountryCode.UNKNOWN, ""],
    ])
    if (!countryCode) return <></>;
    return <ReactCountryFlag className={className} style={{fontSize: fontSize}} countryCode={codeMap.get(countryCode) || ""}/>
}
export default CountryFlagIcon;