import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    configuration_badge: {
        color: '#ffffff',
        cursor: 'pointer',
        marginRight: '10px'
    },
    configuration_badge_back_color: {
        backgroundColor: '#DE350B'
    },
    configuration_area_header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 24px'
    },
    configuration_area_content: {
        
    },
    configuration_ul: {
        listStyle: 'none',
        margin: '0px',
        padding: '0px',
        position: 'relative'
    },
    header_text: {
        fontSize: '19px',
        fontWeight: 700
    },
    configuration_li:{
        "&:hover":{
            backgroundColor: theme.palette.grey[200]
        },
        "& > div":{
            background: "none"
        }
    }

}));

export {useStyles}
