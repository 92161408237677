import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    checkbox_container: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    icon_container: {
        display: 'flex',
        fill: theme.palette.secondary.light
    },
    checkbox_disabled: {
        color: theme.palette.grey["400"]
    },
    checkbox_enabled: {
        color: theme.palette.secondary.light,
    }
}));

export {useStyles}