
class ReferenceItemPriceResponse {
    public agencyId: string
    public referenceItemPriceId: string
    public referenceItemId: string
    public referenceItemName: string
    public referenceItemDescription: string
    public amount: number
    public cost: number

    constructor(agencyId: string, referenceItemPriceId: string, referenceItemId: string, referenceItemName: string, referenceItemDescription: string, amount: number, cost:number){
        this.agencyId = agencyId
        this.referenceItemPriceId = referenceItemPriceId
        this.referenceItemId = referenceItemId
        this.referenceItemName = referenceItemName
        this.referenceItemDescription = referenceItemDescription
        this.amount = amount
        this.cost = cost
    }
}
export default ReferenceItemPriceResponse