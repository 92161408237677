import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import RemittanceStatusHistoryStore from "./ObservationsStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ObservationsButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ObservationsButtonProperties from './ObservationsButtonProperties';
import Modal from "../../../ui_components/Modal/Modal";
import ThreePIcon from '@mui/icons-material/ThreeP';
import ObservationsInput from '../../Inputs/ObservationsInput/ObservationsInput';

const ObservationsButton: React.FC<ObservationsButtonProperties> = ({
                                                                          showIcon = true,
                                                                          showText = true,
                                                                          disabled,
                                                                          className = '',
                                                                          deliveryOrderId,
                                                                          deliveryOrderNumber,
                                                                          deliveryOrderObservations,
                                                                          rounded = false,
                                                                          size = ButtonSize.DEFAULT,
                                                                          type = ButtonType.DEFAULT
                                                                      }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new RemittanceStatusHistoryStore(commonContext, deliveryOrderObservations, deliveryOrderId);

    const handleOnclick = async () => {
        await store.openModal();
    }

    const onObservationsChange = async (newObservations: string) => {
        store.deliveryOrderObservations = newObservations;
    }

    const onSave = async () => {
        await store.updateObservations();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const text = 'Observaciones'
    const Icon = ThreePIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} title={text} onClose={onCloseModal} open={store.isModalOpen}
                       className={classes.modal_container}>
                    <div className={classes.history_container}>
                        <Box
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.START}>
                            {i18n.translate("REMITTANCE_ORDER_NUMBER")}: <strong className={classes.margin_left_4}>{deliveryOrderNumber}</strong>
                        </Box>
                        <div className={classes.body}>
                            <ObservationsInput
                                value={store.deliveryOrderObservations}
                                lines={10}
                                onChange={onObservationsChange}/>
                            <div className={classes.btn_container}>
                                <Button
                                    loading={store.loading} className={classes.input} onClick={onSave}
                                    type={ButtonType.SECONDARY}
                                    text={i18n.translate("SAVE")}/>
                                <Button
                                    loading={store.loading} className={classes.cancel_btn} onClick={onCloseModal}
                                    type={ButtonType.DEFAULT}
                                    text={i18n.translate("CANCEL")}/>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ObservationsButton;
