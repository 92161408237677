import Currency from "../entities/Currency";

const getSymbol = (currency: string | undefined = undefined): string => {
    switch (currency) {
        case "EURO":
            return "€ {value}";
        case "MN":
            return "$ {value}";
        case "MLC":
            return "$ {value}";
        case "PERCENT":
            return "{value}%";
        default :
            return "$ {value}";
    }
}

export const getEnum = (currency: string | undefined = undefined): Currency => {
    switch (currency) {
        case "EURO":
            return Currency.EURO;
        case "MN":
            return Currency.MN;
        case "MLC":
            return Currency.MLC;
        case "USD":
            return Currency.DOLLAR;
        default :
            return Currency.DOLLAR;
    }
}

export const getCurrencyText = (currency: Currency | undefined = undefined): string => {
    switch (currency) {
        case Currency.EURO:
            return 'EURO';
        case Currency.MN:
            return 'CUP';
        case Currency.MLC:
            return 'MLC';
        case Currency.DOLLAR:
            return 'USD';
        default :
            return 'USD';
    }
}

export module CurrencyModule {
    export function format(amount: number, currency: string | undefined = undefined): string {
        amount = amount || 0;
        let symbol = getSymbol(currency)
        symbol = amount >= 0 ? symbol : `-${symbol}`;
        const value = Math.abs(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        return symbol.replace("{value}", value);
    }
}