import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ProductOrderService } from "../../../service/ProductOrderService";


class RemoveProductOrdersStore {

    public productOrdersIds: string[];
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, productOrdersIds: string[]) {
        this.productOrdersIds = productOrdersIds;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ProductOrderService.removeBatch(this.productOrdersIds);
        if (!response.success && response.status === 400 && response.error === 'ERROR_00_400_0003') {
            await this.commonStore.showErrorToast('No se pudo eliminar uno o más envíos porque están en un estado que lo imposibilita');
        } else {
            this.commonStore.processErrors(response);
        }        
        return response.success;
    }
}

export default RemoveProductOrdersStore;