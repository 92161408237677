import React from "react";
import PackageItemsRowProperties from "./PackageItemsRowProperties";
import clsx from "clsx";
import ShowText from "../../../../shared_components/Info/ShowText/ShowText";
import {useStyles} from "../../CustomCategory.style";
import ButtonType from "../../../../ui_components/Button/ButtonType";
import RemovePackageItemButton from "../../../../shared_components/Buttons/RemovePackageItemButton/RemovePackageItemButton";
import ButtonSize from "../../../../ui_components/Button/ButtonSize";
import EditPackageItemButton from "../../../../shared_components/Buttons/EditPackageItemButton/EditPackageItemButton";
import { useCommonStyles } from "../../../../ui_components/common/CommonStyles.style";

const PackageItemsRowMedium:React.FC<PackageItemsRowProperties> = ({
                                                                       packageItem,
                                                                       onRemovePackageItem,
                                                                       onEditPackageItem,
                                                                       categoryId,
                                                                       onUpdate = ()=>{},
                                                                   }) => {
    const classes = useStyles()
    const commonClasses =useCommonStyles()

    return(
        <div className={clsx(classes.custom_category_with_items_container)}>
            <ShowText  text={packageItem.amount.toString()}/>
            <ShowText className={commonClasses.fullWidth}text={packageItem.name}/>
            <ShowText text={"$"+packageItem.finalItemPrice.toString()}/>
            <div className={classes.custom_row_buttons}>
                <EditPackageItemButton 
                    type={ButtonType.SECONDARY}
                    onSave={onUpdate}
                    packageItem={packageItem}
                    categoryId={categoryId}
                    onEditPackageItem={onEditPackageItem}
                    size={ButtonSize.SMALL}
                    showText={false}/>
                <RemovePackageItemButton
                    type={ButtonType.SECONDARY}
                    onSave={onUpdate}
                    packageItem={packageItem}
                    categoryId={categoryId}
                    onRemovePackageItem={onRemovePackageItem}
                    size={ButtonSize.SMALL}
                    showText={false}/>
            </div>
        </div>
    )
}
export default PackageItemsRowMedium