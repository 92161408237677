import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    all_container: {
        display: 'flex',
        flexDirection: 'column'
    },
    show_balances_container: {
        display: 'flex'
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
    balances_container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        width: '100%',
    },
    balance: {
        fontFamily: 'Alkatra, cursive',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '12px',
        marginTop: '5px'
    },
    hint: {
        fontSize: '10px',
        color: theme.palette.grey[700]
    },
    positive: {
        color: theme.palette.success.light
    },
    negative: {
        color: theme.palette.error.light
    },
    no_cost: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        width: "fit-content",
        padding: "4px 8px",
        boxSizing: "border-box",
        height: "fit-content",
        borderRadius: "4px",
        background: theme.palette.secondary.light,
        color: theme.palette.common.white,
        alignSelf: "center",
    }
}));

export {useStyles}