import Currency from "./Currency";

class Article {
    public id: string;
    public name: string;
    public description: string;
    public defaultImage: string;
    public confectionPrice: number;
    public confectionPriceCurrency: Currency;
    public createdAt: number;

    constructor(id: string, name: string, description: string, defaultImage: string, confectionPrice: number, confectionPriceCurrency: Currency, createdAt: number) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.defaultImage = defaultImage;
        this.confectionPrice = confectionPrice;
        this.confectionPriceCurrency = confectionPriceCurrency;
        this.createdAt = createdAt;
    }

}

export default Article;