import React from 'react';
import {useStyles} from "./ShowOrderNumber.style";
import ShowOrderNumberProperties from "./ShowOrderNumberProperties";
import HighlightText from "../../../ui_components/HighlightText/HighlightText";


const ShowOrderNumber: React.FC<ShowOrderNumberProperties> = ({
                                                                  orderNumber,
                                                                  className = '',
                                                                  textToHighlight
                                                              }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <HighlightText className={classes.text} textToHighlight={[textToHighlight || '']} text={orderNumber}/>
        </div>
    )
}
export default ShowOrderNumber;