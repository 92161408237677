import React from 'react';
import NewAgencyButtonProperties from "./NewAgencyButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewAgencyStore from "./NewAgencyStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewAgencyButton.style";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import AgencyNameInput from "../../Inputs/AgencyNameInput/AgencyNameInput";
import EmailInput from "../../Inputs/EmailInput/EmailInput";
import Group from "../../../ui_components/Group/Group";
import PhoneInput from "../../Inputs/PhoneInput/PhoneInput";
import AddressInput from "../../Inputs/AddressInput/AddressInput";
import useOnEnterPress from '../../../hooks/EnterPress';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import SalePriceSelectSmall from '../../Selects/SalePriceSelectSmall/SalePriceSelectSmall';
import Phone from "../../../entities/Phone";
import CountryCode from "../../../entities/CountryCode";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import AllowAccess from "../../Access/AllowAccess/AllowAccess";


const NewAgencyButton: React.FC<NewAgencyButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  agencyId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);
    const commonClasses = useCommonStyles();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new NewAgencyStore(commonContext, agencyId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.agency.name = newName;
    const onAgencyEmailChange = (newEmail: string) => store.agency.email = newEmail;
    const onAgencyPhoneChange = (newPhone: Phone) => store.agency.phone = newPhone;
    const onAgencyAddressChange = (newAddress: string) => store.agency.address = newAddress;
    const onSalePriceChange = (newSalePriceId: string) => store.agency.salePriceId = newSalePriceId;
    const onSellerNameChange = (newName: string) => {
        if (store.seller) {
            store.seller.name = newName;
        }
    };

    const onSellerEmailChange = (newEmail: string) => {
        if (store.seller) {
            store.seller.email = newEmail;
        }
    };

    const onAgencySave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }
    useOnEnterPress(onAgencySave, [store]);


    if (!userStore.hasAccess(Access.CREATE_AGENCY.valueOf())) {
        return <></>;
    }

    const text = i18n.translate((store.isEditing ? "EDIT_AGENCY" : "NEW_AGENCY"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <AgencyNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                                         value={store.agency.name}
                                         onChange={onNameChange}/>
                        <AllowAccess any={[Access.LIST_SALE_PRICE]}>
                            <SalePriceSelectSmall fullWidth className={classes.input}
                                                  selected={store.agency.salePriceId}
                                                  onChange={onSalePriceChange}/>
                        </AllowAccess>
                        <PhoneInput
                            fullWidth
                            countryCode={CountryCode.USA}
                            error={i18n.translate(store.phoneError)}
                            className={classes.input}
                            value={store.agency.phone}
                            onChange={onAgencyPhoneChange}/>

                        <EmailInput fullWidth
                                    className={classes.input}
                                    value={store.agency.email}
                                    onChange={onAgencyEmailChange}/>
                        <AddressInput onChange={onAgencyAddressChange}
                                      className={classes.input}
                                      fullWidth
                                      value={store.agency.address}
                        />
                        {!store.isEditing &&
                        <Group title={i18n.translate("USER_DATA")} className={commonClasses.marginTop}>
                            <>
                                <SellerNameInput error={i18n.translate(store.userNameError)} fullWidth
                                                 className={classes.input}
                                                 value={store.seller?.name}
                                                 onChange={onSellerNameChange}/>
                                <EmailInput error={i18n.translate(store.userEmailError)} fullWidth
                                            className={classes.input}
                                            value={store.seller?.email}
                                            onChange={onSellerEmailChange}/>
                            </>
                        </Group>}
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onAgencySave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewAgencyButton;
