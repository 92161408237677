import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Invoice from "../../../entities/Invoice";
import {InvoiceService} from "../../../service/InvoiceService";
import PaymentType from "../../../entities/PaymentType";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import Currency from "../../../entities/Currency";
import PaymentTypeResponse from "../../../model/payment/PaymentTypeResponse";

class PayInvoiceButtonStore {

    public productOrderId: string;
    public invoice?: Invoice;
    public isModalOpen: boolean;
    public loading: boolean;
    public error?: string;
    public commonStore: CommonStore;
    public payments: AddPaymentRequest[];
    public selectedPrice: number;
    public selectedPaymentType: PaymentTypeResponse;


    constructor(commonStore: CommonStore, productOrderId: string) {
        this.productOrderId = productOrderId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.payments = [];
        this.selectedPaymentType = new PaymentTypeResponse(0, PaymentType.CASH, false);
        this.selectedPrice = 0;
        makeAutoObservable(this);
    }

    @action
    public async loadInvoice(): Promise<void> {
        this.loading = true;
        const response = await InvoiceService.findByProductOrder(this.productOrderId);
        this.commonStore.processErrors(response);
        if (response.success && response.data) {
            this.invoice = response.data;
            this.selectedPrice = this.invoice.totalService;
        }
        this.loading = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const response = await InvoiceService.payProductOrder(this.productOrderId, this.payments);
        this.commonStore.processErrors(response);
        this.loading = false;
        return response.success;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.loadInvoice();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public addPayment() {
        if (this.getRemaining < this.selectedPrice) {
            this.error = "OVERPAYMENT";
            this.selectedPrice = this.getRemaining;
            return;
        }
        const current = this.payments.find(t => t.paymentTypeResponse.paymentType === this.selectedPaymentType.paymentType);
        if (current) {
            current.amount += this.selectedPrice;
            this.payments = [...this.payments];
        } else {
            const payment = new AddPaymentRequest(this.selectedPrice, Currency.DOLLAR, this.selectedPaymentType);
            this.payments.push(payment);
        }
        this.error = undefined;
        this.selectedPrice = this.getRemaining;
    }

    @action
    public removePayment(payment: AddPaymentRequest) {
        const payments = this.payments.filter(t => t.paymentTypeResponse !== payment.paymentTypeResponse);
        this.payments = [...payments];
        this.error = undefined;
        this.selectedPrice = this.getRemaining;
    }

    get isEmpty(): boolean {
        return this.payments.length === 0;
    }

    get getRemaining(): number {
        return this.invoice?.totalService! - this.getPaymentsSum;
    }

    get getPaymentsSum(): number {
        return this.payments.reduce((a, i) => a + i.amount, 0);
    }

    get getFeesSum(): number {
        return this.payments.reduce((a, i) => a + i.getExtraFee, 0);
    }

    get getTotalToPay(): number {
        return (this.invoice?.totalService || 0) + this.getFeesSum
    }

    get canAddMorePayments(): boolean {
        return this.getPaymentsSum < this.invoice?.totalService!!;
    }
}

export default PayInvoiceButtonStore;