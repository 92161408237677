import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import RemittanceStatusHistoryStore from "./ProductOrderStatusHistoryButtonStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ProductOrderStatusHistoryButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ProductOrderStatusHistoryButtonProperties from './ProductOrderStatusHistoryButtonProperties';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Link from '../../../ui_components/Link/Link';
import { useUserContext } from '../../../contexts/UserContext';
import ShowProductOrderStatusHistory from '../../Info/ShowProductOrderStatusHistory/ShowProductOrderStatusHistory';
// import Access from '../../Access/AllowAccess/Access';
// import AllowAccess from '../../Access/AllowAccess/AllowAccess';

const ProductOrderStatusHistoryButton: React.FC<ProductOrderStatusHistoryButtonProperties> = ({
                                                                                                  showIcon = true,
                                                                                                  showText = true,
                                                                                                  disabled,
                                                                                                  className = '',
                                                                                                  productOrderId,
                                                                                                  rounded = false,
                                                                                                  detailsLink = false,
                                                                                                  size = ButtonSize.DEFAULT,
                                                                                                  type = ButtonType.DEFAULT
                                                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new RemittanceStatusHistoryStore(commonContext, productOrderId, userStore.isRootAgency());

    const handleOnclick = async () => {
        await store.openModal();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const text = i18n.translate("PRODUCT_ORDER_STATUS_HISTORY")
    const Icon = InfoOutlinedIcon;
    const Btn = () => {
        if (detailsLink) {
            return <Link text={'Detalles'} onClick={handleOnclick}></Link>;
        }
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel title={text} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <ShowProductOrderStatusHistory productOrderId={productOrderId}/>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ProductOrderStatusHistoryButton;
