import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import NewMiscellanyCategoryStore from "./NewMiscellanyCategoryStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewMiscellanyCategoryButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ArticleDescriptionInput from '../../Inputs/ArticleDescriptionInput/ArticleDescriptionInput';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import NewMiscellanyCategoryButtonProperties from './NewMiscellanyCategoryButtonProperties';
import PriceInput from '../../Inputs/PriceInput/PricetInput';
import { useUserContext } from '../../../contexts/UserContext';


const NewMiscellanyCategoryButton: React.FC<NewMiscellanyCategoryButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  miscellanyCategoryId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new NewMiscellanyCategoryStore(commonContext, miscellanyCategoryId);

    if (!userStore.isRootAgency()) {
        return <></>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.miscellanyCategory.name = newName;
    const onDescriptionChange = (newDescription: string) => store.miscellanyCategory.description = newDescription;
    const onPriceChange = (newPrice: number) => store.miscellanyCategory.price = newPrice;
    const onMiscellanyCategorySave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_MISCELLANY_CATEGORY" : "NEW_MISCELLANY_CATEGORY"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <SellerNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                                         value={store.miscellanyCategory?.name}
                                         onChange={onNameChange}/>
                        <ArticleDescriptionInput fullWidth className={classes.input}
                                    value={store.miscellanyCategory?.description}
                                    onChange={onDescriptionChange}/>
                        <div className={classes.price_container}>
                            <PriceInput label={'Precio'} fullWidth className={classes.input}
                                value={store.miscellanyCategory?.price}
                                onChange={onPriceChange}/>
                        </div>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onMiscellanyCategorySave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewMiscellanyCategoryButton;
