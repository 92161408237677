import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    progress_container: {
       display: "flex",
        position: "relative"
    },
    backdrop:{
        position:"absolute",
        width: '100%',
        height: 3,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[200],
        top: 0,
        left: 0
    },
    progress:{
        position:"absolute",
        top: 0,
        left: 0,
        height: 3,
        borderRadius: 4,
    },
    info:{
        border: `1px solid ${theme.palette.info.light}`,
        backgroundColor: theme.palette.info.light,
    },
    success:{
        backgroundColor: theme.palette.success.light,
        border: `1px solid ${theme.palette.success.light}`,
    },
    danger:{
        backgroundColor: theme.palette.error.light,
        border: `1px solid ${theme.palette.error.light}`,
    },
    primary:{
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.light}`,
    },
    secondary:{
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.secondary.light}`,
    },
    warning:{
        backgroundColor: theme.palette.warning.light,
        border: `1px solid ${theme.palette.warning.light}`,
    },
    purple:{
        backgroundColor: "#8026D9FF",
        border: `1px solid #6226D9FF`,
    }
}));

export {useStyles}