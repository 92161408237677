import React, {ReactNode } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;



const singletonCacheData = {
  iframe: null as HTMLIFrameElement | null, // Initialize with type annotation
  update(iframe: HTMLIFrameElement | null) {
    this.iframe = iframe;
  },
};

interface PrintProps {
  insertHead?: boolean; // 是否植入本页面的head标签
  ignoreHeadJs?: boolean; // 当insertHead启用时是否屏蔽JS文件
  bodyStyle?: boolean; // 是否植入body标签中的style，插入body底部
  otherStyle?: string; // 附加的样式将直接插入head最底部
  isIframe?: boolean; // 是否使用iframe插入，否则将使用新窗口
  iframeStyle?: string; // 将被应用到iframe或者new window
  winStyle?: string; // 将被应用到iframe或者new window
  title?: string; // iframe或者新窗口的标题，将会在打印页的页眉和新窗口的title
  preventDefault?: boolean; // 是否替换Ctrl+P
  lazyRender?: boolean; // 是否只渲染在iframe或者新窗口上
  clearIframeCache?: boolean; // 是否清理dom缓存。否的情况下，如props为改变将保留并直接使用上次打印留下的dom
  singletonCache?: boolean; // 当clearIframeCache关闭时生效。类单例模式，当界面有多个打印组件时，最多允许保留一个缓存
  onStart?: () => void;
  onEnd?: () => void;
  children: ReactNode;
}

class Print extends React.Component<PrintProps> {
  static propTypes = {
    insertHead: PropTypes.bool,
    ignoreHeadJs: PropTypes.bool, // 当insertHead启用时是否屏蔽JS文
    bodyStyle: PropTypes.bool, // 是否植入body标签中的style，插入body底部
    otherStyle: PropTypes.string, // 附加的样式将直接插入head最底部
    isIframe: PropTypes.bool, // 是否使用iframe插入，否则将使用新窗口
    iframeStyle: PropTypes.string, // 将被应用到iframe或者new window
    winStyle: PropTypes.string, // 将被应用到iframe或者new window
    title: PropTypes.string, // iframe或者新窗口的标题，将会在打印页的页眉和新窗口的title
    preventDefault: PropTypes.bool, // 是否替换Ctrl+P
    lazyRender: PropTypes.bool, // 是否只渲染在iframe或者新窗口上
    clearIframeCache: PropTypes.bool, // 是否清理dom缓存。否的情况下，如props为改变将保留并直接使用上次打印留下的dom
    singletonCache: PropTypes.bool, // 当clearIframeCache关闭时生效。类单例模式，当界面有多个打印组件时，最多允许保留一个缓存
    onStart: PropTypes.func, // 组件开始打印渲染
    onEnd: PropTypes.func, // 组件打印渲染完成
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    insertHead: true,
    ignoreHeadJs: true,
    bodyStyle: false,
    otherStyle: undefined,
    isIframe: true,
    iframeStyle: 'position:absolute;width:0px;height:0px;',
    winStyle: 'toolbar=no,menubar=no',
    title: undefined,
    preventDefault: false,
    lazyRender: false,
    clearIframeCache: false,
    singletonCache: true,
    onStart() {
    },
    onEnd() {
    },
  };

  private changed: boolean = true; // 不触发UI渲染
  private iframe: HTMLIFrameElement | null = null;
  private prevent?: (e: KeyboardEvent) => void;

  constructor(props: PrintProps) {
    super(props);
    this.changed = true; // 不触发UI渲染
  }

  componentDidMount() {
    if (this.props.preventDefault) {
      this.prevent = (e) => {
        if (e.keyCode === 80 && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          this.onPrint();
        }
      };
      document.addEventListener('keydown', this.prevent);
    }
  }

  onPrint = () => {
    const { isIframe, clearIframeCache, singletonCache, onStart } = this.props;
    onStart?.();

    if (isIframe) {
      if (clearIframeCache) { // 清理缓存模式
        this.createIframe(null, (iframe) => {
          // remove dom
          document.body.removeChild(iframe);
        });
      } else if (singletonCache) { // 单例模式缓存模式
        if (this.changed || this.iframe !== singletonCacheData.iframe) { // 发生改变：1、数据改变；2、缓存对应的组件改变。
          this.createIframe(singletonCacheData.iframe, (iframe) => {
            this.iframe = iframe; // 保存本地用作对比
            singletonCacheData.update(iframe);
          });
        } else {
          // Handle the case when this.iframe is null
          this.createIframe(null, (iframe) => {
            this.iframe = iframe;
            singletonCacheData.update(iframe);
          });
        }
      } else if (this.changed) { // 普通缓存模式发生改变
        this.createIframe(this.iframe, (iframe) => {
          this.iframe = iframe;
        });
      } else { // 普通缓存模式未改变
        if (this.iframe !== null) {
          this.iframePrint(this.iframe);
        }
      }
    } else {
      this.winCreateAndPrint();
    }
  };
  componentWillReceiveProps(nextProps: PrintProps) {
    if (nextProps !== this.props) {
      // this.setState({changed: true});
      this.changed = true;
    }
  }

  componentWillUnmount() {
    if (singletonCacheData.iframe === this.iframe) {
      singletonCacheData.update(null); // Pass null directly
    }
    if (this.iframe) {
      document.body.removeChild(this.iframe);
    }
    if (this.prevent) {
      document.removeEventListener('keydown', this.prevent);
    }
  }

  getHead = () => {
    const {insertHead, ignoreHeadJs, title, otherStyle} = this.props;
    const titleTemplate = title ? `<title>${title}</title>` : '';
    const otherStyleTemplate = otherStyle ? `<style>${otherStyle}</style>` : '';
    const headTagsTemplate = (() => {
      if (insertHead) {
        const innerHTML = document.head.innerHTML;
        return ignoreHeadJs ? innerHTML.replace(SCRIPT_REGEX, '') : innerHTML;
      }
      return '';
    })();
    return `${titleTemplate}${headTagsTemplate}${otherStyleTemplate}`;
  };
  getBodyStyle = (): string => {
    let inlineStyle = '';
    const stylesDom = document.body.getElementsByTagName('style');

    for (let i = 0; i < stylesDom.length; i++) {
      inlineStyle += stylesDom[i].innerHTML;
    }

    return inlineStyle;
  };

  writeTemplate = (doc: Document): void => {
    const { bodyStyle, lazyRender, isIframe } = this.props;
    const bodyAttrs = isIframe ? '' : 'onload="window.print()" '; // window时在body上植入事件
    if (lazyRender) {
      doc.write(`<html lang=""><head><title>''</title></head><body ${bodyAttrs}><div></div></body></html>`);
      doc.head.innerHTML = this.getHead();
      ReactDOM.render(this.renderChild(), doc.body.getElementsByTagName('div')[0]); // React的未来版本可能会异步地呈现组件
      if (bodyStyle) {
        const styleTag = doc.createElement('style');
        styleTag.innerHTML = this.getBodyStyle();
        doc.body.appendChild(styleTag);
      }
    } else {
      const domNode = ReactDOM.findDOMNode(this);
      if (domNode instanceof Element) {
        const dom = domNode.innerHTML;
        doc.write(`
        <html lang="">
          <head>${this.getHead()}<title>''</title></head>
          <body ${bodyAttrs}>${dom}${bodyStyle ? `<style>${this.getBodyStyle()}</style>` : ''}</body>
        </html>
      `);
      }
    }
    doc.close();
  };

  createIframe = (iframeCache: HTMLIFrameElement | null, callback?: (iframe: HTMLIFrameElement) => void): void => {
    const { iframeStyle } = this.props;
    let iframe: HTMLIFrameElement;

    if (iframeCache !== null) { // Check if iframeCache is not null
      iframe = iframeCache;
    } else {
      // Create a new iframe element and add it to the page
      iframe = document.createElement('iframe');
      iframe.setAttribute('style', iframeStyle || ''); // Provide a default value for iframeStyle
      document.body.appendChild(iframe);
    }

    iframe.onload = () => {
      this.iframePrint(iframe, callback);
    };

    if (iframe.contentWindow) { // Check if contentWindow is not null
      this.writeTemplate(iframe.contentWindow.document);
    }
  };


  iframePrint = (iframe: HTMLIFrameElement, callback?: (iframe: HTMLIFrameElement) => void): void => {
    if (iframe.contentWindow) { // Check if contentWindow is not null
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      callback && callback(iframe);

      // wait for a new change, iframe is in the current page, so loading should be shared with the page
      this.changed = false;
      if (this.props.onEnd) { // Check if onEnd is defined
        this.props.onEnd();
      }
    }
  };

  winCreateAndPrint = (): void => {
    const win = window.open('', '', this.props.winStyle);

    if (win && win.document) { // Check if both win and win.document are not null
      this.writeTemplate(win.document);

      // wait for a new change
      this.changed = false;

      if (this.props.onEnd) { // Check if onEnd is defined
        this.props.onEnd();
      }
    }
  };

  renderChild = (): React.ReactElement => {
    const { children } = this.props;
    if (React.isValidElement(children)) {
      return React.cloneElement(children);
    }
    throw new Error('Invalid child element');
  };

  render(): React.ReactElement {
    if (!this.props.lazyRender) {
      return this.renderChild() as React.ReactElement;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

export default Print;