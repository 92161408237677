import React from 'react';
import {useStyles} from "./ShowContactBankCard.style";
import ShowContactBankCardProperties from "./ShowContactBankCardProperties";


const ShowContactBankCard: React.FC<ShowContactBankCardProperties> = ({
                                                          bank,
                                                          cardNumber,
                                                          className = '',
                                                          buttons = []
                                                      }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <>
                {<div className={classes.title}>{`${bank}`}</div>}
                <div>{cardNumber}</div>
                <div>
                    {buttons && buttons.length > 0 && buttons.map(t => (
                        <span>{t}</span>
                    ))}
                </div>
            </>
        </div>
    )
}
export default ShowContactBankCard;