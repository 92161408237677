import {useStyles} from './ArticleRow.style';
import React from "react";
import ArticleRowProperties from "./ArticleRowProperties";
import clsx from "clsx";
import ShowImage from "../../../Info/ShowImage/ShowImage";

const ArticleRow: React.FC<ArticleRowProperties> = ({
                                                element,
                                                onSelect,
                                                className,
                                                selected
                                            }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <ShowImage imageName={element.defaultImage} width={70} height={70}/>
            </div>

            <div className={classes.text_container}>
                <div>{element.name}</div>
            </div>

        </div>
    )
}


export default ArticleRow;