import React from "react";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useI18nContext} from "../../../contexts/I18nContext";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import {useCommonContext} from "../../../contexts/CommonContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Icon from "../../../ui_components/Icon/Icon";
import IconName from "../../../ui_components/Icon/enums/IconName";
import Button from "../../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import RemoveInventoryItemButtonProperties from "./RemoveInventoryItemButtonProperties";
import RemoveInventoryItemButtonStore from "./RemoveInventoryItemButtonStore";
import { useUserContext } from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";


const RemoveInventoryItemButton:React.FC<RemoveInventoryItemButtonProperties> = ({
                                                                               onClick = () => {
                                                                               },
                                                                               onSave = () => {
                                                                               },
                                                                               showIcon = true,
                                                                               showText = true,
                                                                               disabled,
                                                                               className = '',
                                                                               inventoryItem,
                                                                               rounded = false,
                                                                               size = ButtonSize.DEFAULT,
                                                                               type = ButtonType.DEFAULT
                                                                           }) =>{
    const i18n = useI18nContext();
    const confirm = useConfirmation();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new RemoveInventoryItemButtonStore(commonContext, inventoryItem);

    const handleOnclick = async () => {
        onClick();
        const ok = await confirm(inventoryItem.name, i18n.translate("REMOVE_INVENTORY_ITEMS_CONFIRMATION"));
        if (ok) {
            await store.remove();
            onSave();
        }
    }

    const text = i18n.translate("REMOVE_INVENTORY_ITEMS_MANAGER");
    if(!userStore.hasAccess(Access.REMOVE_INVENTORY_ITEM)){
        return <></>;
    }
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}
export default RemoveInventoryItemButton