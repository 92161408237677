import React from 'react';
import {useStyles} from "./ShowAdditionalPrice.style";
import ShowAdditionalPriceProperties from "./ShowAdditionalPriceProperties";
import clsx from "clsx";
import { CurrencyModule } from '../../../util/Currency';

const ShowAdditionalPrice: React.FC<ShowAdditionalPriceProperties> = ({
                                                          className = '',
                                                          amount,
                                                          isPercent = false,
                                                          comment = '',
                                                          light = false
                                                      }) => {
    const classes = useStyles(light);

    return (
        <div className={clsx(classes.payment_type_info_container, className)}>
            <div className={classes.info}>
                <div>
                    {!isPercent && CurrencyModule.format(amount)}
                    {isPercent && `${amount} %`}
                </div>
                <div>{comment}</div>
            </div>
        </div>
    )
}
export default ShowAdditionalPrice;