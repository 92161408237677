import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import TopupToDo from "../../../model/topup/TopupToDo";
import {TopupService} from "../../../service/topup/TopupService";
import Product from "../../../entities/productOrder/topup/Product";
import ValidateTopupRequest from "../../../model/topup/ValidateTopupRequest";
import ValidateTopupResponse from "../../../model/topup/ValidateTopupResponse";
import ClientContact from "../../../entities/ClientContact";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import Agency from "../../../entities/Agency";
import Seller from "../../../entities/Seller";
import Phone from "../../../entities/Phone";
import { AppStorage, AppStorageValues } from "../../../util/AppStorage";

const ERROR_MAP = {
    anonymousPhone: ["ERROR_00_400_750"]
}

class NewTopupStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public doingTopup: boolean;
    public commonStore: CommonStore;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;
    public topups: TopupToDo[]
    public errorKey?: string;
    public payments: AddPaymentRequest[];
    public paymentCompleted: boolean;
    public anonymousPhone?: string;

    public activeStep = 0;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.validating = false;
        this.doingTopup = false;
        this.commonStore = commonStore;
        this.topups = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.selectedAgency = AppStorage.get(AppStorageValues.SELECTED_AGENCY, undefined);
        this.selectedSeller = AppStorage.get(AppStorageValues.SELECTED_SELLER, undefined);
        makeAutoObservable(this);
    }

    get isEmpty(): boolean {
        return this.topups.length === 0;
    }

    @action
    public async openModal() {
        this.topups = [];
        this.payments = [];
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async addDoTopup(product: Product, comment?: string, contact?: ClientContact, anonymousPhone?: Phone) {
        this.errorKey = undefined;
        const exist = this.topups.some(t => t.contact.id === contact?.id || t.anonymousNumber === anonymousPhone?.number);
        if (exist) {
            this.errorKey = "DO_TOPUP_ALREADY_IN_LIST";
            return;
        }
        this.validating = true;
        const topupToValidate = new ValidateTopupRequest(contact?.clientId || "ANONYMOUS", contact?.id || "ANONYMOUS", product.id, this.selectedAgency?.id, this.selectedSeller?.id, anonymousPhone?.number)
        const response = await TopupService.validate(topupToValidate);
        if (response.success && response.data) {
            const validationResponse = response.data;
            this.validateSingle(validationResponse, product, comment);
            this.processError(response.error);
        }
        this.validating = false;
    }

    public async doTopups() {
        this.doingTopup = true;
        const response = await TopupService.doTopup(this.topups[0], this.payments);
        if (response.success && response.data) {
            this.closeModal();
            window.location.href = '/topups';
        }
        this.doingTopup = false;
    }


    @action
    public removeDoTopup(doTopup: TopupToDo) {
        this.topups = this.topups.filter(t => t.contact.id !== doTopup.contact.id);
    }

    @action
    public restart() {
        this.topups = [];
    }

    private validateSingle(validationResponse: ValidateTopupResponse, product: Product, comment?: string) {
        if (validationResponse.error) {
            this.errorKey = validationResponse.error;
            return;
        }
        const topupToDo = new TopupToDo(
            validationResponse.client!!,
            validationResponse.contact!!,
            validationResponse.total!!,
            validationResponse.product!!,
            validationResponse.promoCount || 0,
            this.selectedAgency?.id,
            this.selectedSeller?.id,
            comment,
            validationResponse.lastRecharged,
            validationResponse.error,
            validationResponse.anonymousNumber
        );
        this.topups.push(topupToDo);
    }

    public getTotalToPay(): number {
        return this.topups.reduce((acc, t) => acc + t.total, 0);
    }

    @action
    public setPayments(payments: AddPaymentRequest[], paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
    }

    processError(error?: string) {
        this.anonymousPhone = undefined;
        if (error) {
            if (ERROR_MAP.anonymousPhone.includes(error)) {
                this.anonymousPhone = error;
            }
        }

    }

}

export default NewTopupStore;