import React from 'react';
import {useStyles} from "../MoneyServiceFees.style";
import clsx from "clsx";
import MoneyServiceFeeRowProperties from "./MoneyServiceFeeRowProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewMoneyDeliveryFeeButton from '../../../shared_components/Buttons/NewMoneyDeliveryFeeButton/NewMoneyDeliveryFeeButton';
import ShowInterval from '../../../shared_components/Info/ShowInterval/ShowInterval';
import { useI18nContext } from '../../../contexts/I18nContext';
import RemoveMoneyServiceFeeButton from '../../../shared_components/Buttons/RemoveMoneyServiceFeeButton/RemoveMoneyServiceFeeButton';
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";


const MoneyServiceFeeRowMedium: React.FC<MoneyServiceFeeRowProperties> = ({
                                                            moneyServiceFee,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.foodPackage_info_container)}>
            <ShowInterval from={moneyServiceFee.fromValue} to={moneyServiceFee.toValue}/>
            <div>
                <div className={classes.currencies_values_container}>
                    {moneyServiceFee.currenciesValues.map((currenciesValue) => (
                        <div>
                            <div>{i18n.translate(currenciesValue.currency)}</div>
                            <div>{!currenciesValue.isPercentage && <span>$</span>} {currenciesValue.value} {currenciesValue.isPercentage && <span>%</span>}</div>
                        </div>
                    ))}
                </div>
            </div>

            <ExpandoMenu>
               <>
                   <NewMoneyDeliveryFeeButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       moneyServiceFeeId={moneyServiceFee.id}
                       type={ButtonType.SECONDARY}
                   />
                   <RemoveMoneyServiceFeeButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       moneyServiceFee={moneyServiceFee}
                       showText
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default MoneyServiceFeeRowMedium;
