import React from 'react';
import UpdateShippingIdButtonProperties from "./UpdateShippingIdButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ChooseDeliveryPersonStore from "./UpdateShippingIdStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {useStyles} from "./UpdateShippingIdButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Badge from '@mui/icons-material/Badge';
import DeliveryPersonSelectList from '../../Selects/DeliveryPersonSelectList/DeliveryPersonSelectList';
import DeliveryPerson from '../../../entities/DeliveryPerson';
import ShipmentUpdateType from "../../../entities/ShipmentUpdateType";
import {Observer} from "mobx-react-lite";
import Modal from "../../../ui_components/Modal/Modal";

const UpdateShippingIdButton: React.FC<UpdateShippingIdButtonProperties> = ({
                                                                                updateType = ShipmentUpdateType.ADD,
                                                                                deliveryOrderIds,
                                                                                selectedShippingId,
                                                                                onUpdate = () => {
                                                                                },
                                                                                onClick = () => {
                                                                                },
                                                                                onSelect = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT
                                                                            }) => {
    const classes = useStyles(showText);
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ChooseDeliveryPersonStore(commonContext);

    const handleOnclick = async () => {
        await store.updateShippinId(deliveryOrderIds, selectedShippingId, updateType);
        onUpdate();
    }

    const onCloseModal = () => {
        store.closeModal();
    }
    
    const onDeliveryPersonSelect = async (deliveryPerson?: DeliveryPerson) => {
        onSelect(deliveryPerson);
        onCloseModal();
    }

    const getText = () =>{
        switch(updateType){
            case ShipmentUpdateType.ADD:
                return i18n.translate("UPDATE_SHIPMENT")
            case ShipmentUpdateType.TAKE_FROM:
                return i18n.translate("DEASSIGN_SHIPMENT")
        }
    }
    const getIcon = () =>{
        switch(updateType){
            case ShipmentUpdateType.ADD:
                return <DeliveryDiningIcon/>
            case ShipmentUpdateType.TAKE_FROM:
                return <Badge/>
        }
    }

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? getText() : ""}
                text={showText ? getText() : ""}
                type={type}
                icon={showIcon ? getIcon() : <></>}
            />  
        }
        return <Button
            text={showText ? getText() : ""}
            className={className}
            tooltip={!showText ? getText() : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? getIcon() : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={getText()} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <DeliveryPersonSelectList selectedDeliveryPerson={store.selectedDeliveryPerson} onDeliveryPersonSelect={onDeliveryPersonSelect}></DeliveryPersonSelectList>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default UpdateShippingIdButton;
