import React from 'react';
import UpdateDeliveryOrdersStatusButtonProperties from "./UpdateDeliveryOrdersStatusButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import UpdateDeliveryOrdersStatusStore from "./UpdateDeliveryOrdersStatusStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./UpdateDeliveryOrdersStatusButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import EditIcon from '@mui/icons-material/Edit';
import ProductOrderStatusSelect from '../../Selects/ProductOrderStatusSelect/ProductOrderStatusSelect';
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import { useUserContext } from '../../../contexts/UserContext';
import useOnEnterPress from '../../../hooks/EnterPress';
import Access from "../../Access/AllowAccess/Access";

const UpdateDeliveryOrdersStatusButton: React.FC<UpdateDeliveryOrdersStatusButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSave = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT,
                                                                                deliveryOrderType,
                                                                                productOrderIds
                                                                            }) => {
    const classes = useStyles(showText);
    const i18n = useI18nContext();
    const userStore = useUserContext();
    const commonContext = useCommonContext();
    const store = new UpdateDeliveryOrdersStatusStore(commonContext, productOrderIds, deliveryOrderType);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }
    
    const onCommentChange = (newComment: string) => (store.comment = newComment);
    const onStatusChange = (option: string) => {
       store.status = option;
    }

    const onOrderStatusSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }
    useOnEnterPress(onSave,[store]);

    if (!userStore.isRootAgency() || !userStore.hasAccess(Access.REMOVE_MONEY_DELIVERY_ORDER.valueOf())) {
        return <></>;
    }
    
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? 'Editar estado' : ""}
                text={showText ? 'Editar estado' : ""}
                type={type}
                icon={showIcon ? 'Editar estado' : <></>}
            />  
        }
        return <Button
            text={showText ? 'Editar estado' : ""}
            className={className}
            tooltip={!showText ? 'Editar estado' : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <EditIcon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={'Editar estado'} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <ProductOrderStatusSelect fullWidth selected={store.status} onChange={onStatusChange}/>
                        <CommentInput
                            fullWidth
                            lines={3}
                            onChange={onCommentChange}
                            value={store.comment}
                            className={classes.input}/>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onOrderStatusSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default UpdateDeliveryOrdersStatusButton;
