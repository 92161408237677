import {action, makeObservable, observable, runInAction} from "mobx";
import {SalesService} from "../../../service/SalesService";
import CommonStore from "../../../stores/CommonStore";
import PackageDeliveryOrderOverviewResponse from "../../../model/sales/productOrder/PackageDeliveryOrderOverviewResponse";

class PackageDeliveryOverviewStore {

    public overview?: PackageDeliveryOrderOverviewResponse;
    public from?: number;
    public to?: number;
    public filter?: string;
    public deliveryPersonId?: string;
    public status?: string[];
    public geoId?: string;
    public agencyId?: string;
    public shippingId?:string
    public includeChildren?: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(
        commonStore: CommonStore,
        from?: number,
        to?: number,
        filter?: string,
        deliveryPersonId?: string,
        status?: string[],
        geoId?: string,
        agencyId?: string,
        shippingId?:string,
        includeChildren?: boolean
    ) {
        this.commonStore = commonStore;
        this.from = from;
        this.to = to;
        this.filter = filter;
        this.deliveryPersonId = deliveryPersonId;
        this.status = status;
        this.geoId = geoId;
        this.agencyId = agencyId;
        this.shippingId =shippingId
        this.loading = false;
        this.includeChildren = includeChildren;
        makeObservable(this, {
            overview: observable,
            loading: observable,
        });
    }


    @action
    public async getElements(): Promise<void> {
        this.loading = true;
        const response = await SalesService.getPackageDeliveryOrderOverview(
            this.from, this.to, this.filter, this.deliveryPersonId,
            this.status, this.geoId, this.agencyId, this.shippingId, this.includeChildren);
        if (response.success) {
            runInAction(() => {
                this.overview = response.data;
            })
        }
        this.loading = false;
        this.commonStore.processErrors(response);
    }
}

export default PackageDeliveryOverviewStore;