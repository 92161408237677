import React from 'react';
import {useStyles} from "./ShowEditMoneySalePrice.style";
import {CurrencyModule, getCurrencyText} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import ShowEditMoneySalePriceProperties from './ShowEditMoneySalePriceProperties';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import Button from '../../../ui_components/Button/Button';
import ButtonType from '../../../ui_components/Button/ButtonType';
import { useCommonContext } from '../../../contexts/CommonContext';
import ShowEditMoneySalePriceStore from './ShowEditMoneySalePriceStore';
import { Observer } from 'mobx-react-lite';
import ServiceFeeTypeSelect from '../../Selects/ServiceFeeTypeSelect/ServiceFeeTypeSelect';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreateIcon from '@mui/icons-material/Create';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import Tag from '../../../ui_components/Tag/Tag';

const ShowEditMoneySalePrice: React.FC<ShowEditMoneySalePriceProperties> = ({
                                                      value,
                                                      isPercentage,
                                                      currency,
                                                      salePriceId,
                                                      serviceFeeId,
                                                      onSave,
                                                      className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();
    const commonContext = useCommonContext();
    const store = new ShowEditMoneySalePriceStore(commonContext, salePriceId, serviceFeeId, value, isPercentage, currency);

    const handleClickOpen = () => {
        store.openModal();
    };

    const handleClose = () => {
        store.closeModal();
    };

    const handleAccept = async () => {
        const saved = await store.updateMoneySalePrice();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    };

    const onServiceTypeChange = (newType: string) => {
        store.moneyServiceFeeType = newType;
    };
    const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newVal = event.target.value;
        store.value = +newVal;
    }

    return <Observer>
        {() => (
        <>
            <div className={clsx(classes.show_price_container, className)}>
                <div className={clsx(
                    classes.number,
                    classes.positive
                )}>
                    {value && isPercentage && CurrencyModule.format(value, 'PERCENT')}
                    {value && !isPercentage && CurrencyModule.format(value, 'DOLLAR')}
                    <CreateIcon className={classes.edit_icon} onClick={handleClickOpen}/>
                </div>
                <div className={classes.currency}>{i18n.translate(currency)}</div>
            </div>
            <Dialog open={store.isModalOpen} onClose={handleClose}>
                <DialogTitle>{i18n.translate('EDIT_VALUE')}</DialogTitle>
                <DialogContent>
                    <ServiceFeeTypeSelect
                        fullWidth
                        className={classes.input}
                        selected={store.moneyServiceFeeType}
                        onChange={onServiceTypeChange}/>
                    {store.moneyServiceFeeType === 'PERCENT' ? 
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.value}
                            onChange={onValueChange}
                            label={i18n.translate("FEE")}
                            className={classes.input}
                            endIcon={PercentIcon}
                            fullWidth
                        /> : 
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.value}
                            onChange={onValueChange}
                            label={i18n.translate("FEE")}
                            className={classes.input}
                            startIcon={AttachMoneyIcon}
                            fullWidth
                        />}
                    <div className={classes.hint}>
                        <Tag startIcon={GppMaybeIcon}
                            text={`Valor de costo aplicado a envios con entrega en ${getCurrencyText(currency)}`}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.input} onClick={handleClose}
                        type={ButtonType.SECONDARY}
                        text={i18n.translate("CANCEL")}/>
                    <Button
                        className={classes.input} onClick={handleAccept}
                        type={ButtonType.SECONDARY}
                        text={i18n.translate("SAVE")}/>
                </DialogActions>
            </Dialog>
        </>
    )}
    </Observer>
}


export default ShowEditMoneySalePrice;