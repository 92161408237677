import React, {createRef, useEffect} from 'react';
import ShippingStatusSelectProperties from "./ShippingStatusSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./ShippingStatusSelect.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import Loading from "../../../ui_components/PageLoading/Loading";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ShippingStatusSelectStore from "./ShippingStatusSelectStore";
import {Observer} from "mobx-react-lite";
import DeliveryStatusElementRow from "./row/DeliveryStatusElementRow";
import CancelIcon from '@mui/icons-material/Cancel';
import {Tooltip} from "@material-ui/core";


const ShippingStatusSelect: React.FC<ShippingStatusSelectProperties> = ({
                                                                            onChange,
                                                                            disabled,
                                                                            fullWidth = false,
                                                                            className = '',
                                                                            selected,
                                                                            label
                                                                        }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const menuRef = createRef<HTMLDivElement>();
    const inputRef = createRef<HTMLInputElement>();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ShippingStatusSelectStore(commonContext, selected);
    const containerClasses = clsx(classes.select_container, className, fullWidth && commonClasses.fullWidth);

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                store.toggleSearchDialog(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuRef])

    const toggleDialog = () => {
        store.toggleSearchDialog();
        setTimeout(() => inputRef.current?.focus(), 20);
    }

    const selectDeliveryPerson = (shippingAssignment: string) => {
        store.setSelectedShippingAssignment(shippingAssignment);
        onChange(shippingAssignment);
    }

    const removeSelected = (e: React.MouseEvent) => {
        store.setSelectedShippingAssignment(undefined);
        e.stopPropagation();
        onChange(undefined);
    }
    return (
        <Observer>
            {() => (
                <div className={containerClasses} ref={menuRef}>
                    <div className={classes.label}>{label || i18n.translate("SHIPPING_ASSIGMENT")}</div>
                    <div className={classes.input_container} onClick={toggleDialog}>
                        <AccountBoxSharpIcon/>
                        <div className={classes.info_container}>
                            {store.selectedShippingAssignment &&
                            <div className={classes.selected_container}>
                                <div className={classes.selected_text_container}>
                                    <div><b>{store.selectedShippingAssignment}</b></div>
                                    {/*<div><b>{i18n.translate("EMAIL")}:</b>{`${store.selectedDeliveryPerson.email}`}</div>*/}
                                </div>
                                <Tooltip title={i18n.translate("CLEAN")}>
                                    <div onClick={removeSelected} className={classes.remove_icon}><CancelIcon/>
                                    </div>
                                </Tooltip>

                            </div>
                            }
                            {!store.selectedShippingAssignment &&
                                <div>{i18n.translate("STATUS_PLACEHOLDER")}</div>}
                        </div>
                        <div className={classes.expand_icon}>
                            {store.isSearchDialogOpen && <ExpandLessIcon/>}
                            {!store.isSearchDialogOpen && <ExpandMoreIcon/>}
                        </div>
                    </div>
                    {store.isSearchDialogOpen &&
                        <div className={classes.options_container}>

                            <div className={classes.options_body}>
                                {store.loading && <Loading className={classes.loading} fontSize={55}/>}
                                {!store.loading && !store.isEmpty && <div className={classes.elements_container}>
                                    {store.shippingAssignation.map((el, i) => (
                                        <DeliveryStatusElementRow
                                            key={i}
                                            element={el}
                                            selected={el === store.selectedShippingAssignment}
                                            onSelect={selectDeliveryPerson}
                                        />
                                    ))}
                                </div>}
                            </div>
                        </div>
                    }
                </div>
            )}
        </Observer>
    )
}


export default ShippingStatusSelect;
