import React, { useEffect } from 'react';
import DeliveryPersonSelectListProperties from "./DeliveryPersonSelectListProperties";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./DeliveryPersonSelectList.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import DeliveryPersonSelectListStore from "./DeliveryPersonSelectListStore";
import {Observer} from "mobx-react-lite";
import SearchDeliveryPersonPanel from './searchDeliveryPerson/SearchDeliveryPersonPanel';
import DeliveryPerson from '../../../entities/DeliveryPerson';

const DeliveryPersonSelectList: React.FC<DeliveryPersonSelectListProperties> = ({
                                                                          onDeliveryPersonSelect,
                                                                          fullWidth = false,
                                                                          className = '',
                                                                          orderByGeosIdProximity = [],
                                                                          selectedDeliveryPerson,
                                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const commonContext = useCommonContext();
    const store = new DeliveryPersonSelectListStore(commonContext, selectedDeliveryPerson, orderByGeosIdProximity);
    const containerClasses = clsx(
        classes.delivery_person_contact_container,
        className, fullWidth && commonClasses.fullWidth);

    useEffect(() => {
        store.setSelectedDeliveryPerson(selectedDeliveryPerson);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDeliveryPerson])

    const selectDeliveryPerson = (deliveryPerson?: DeliveryPerson) => {
        onDeliveryPersonSelect(deliveryPerson);
    }

    return (
        <Observer>
            {() => (
                <div className={containerClasses}>
                    <SearchDeliveryPersonPanel onDeliveryPersonSelect={selectDeliveryPerson} store={store}/>
                </div>
            )}
        </Observer>
    )
}


export default DeliveryPersonSelectList;