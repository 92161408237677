import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    "fontFamily": "Nunito, sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette:{
    primary:{
      main: '#019D8C',
      light: '#3dc9b4',
      dark: '#007a6d'
    },
    secondary:{
      main: '#3a73a8',
      light: '#01609D',
      dark: '#00467a'
    }
  }
});
 export default theme;
