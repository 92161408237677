import React from 'react';
import {Breakpoint} from 'react-socks';
import MediaViewProperties from "./MediaViewProperties";
import {useStyles} from "./MediaView.style";
import clsx from "clsx";


const ViewSmall: React.FC<MediaViewProperties> = ({
                                                      fullHeight = false,
                                                      fullWidth = false,
                                                      children
                                                  }) => {
    const classes = useStyles();
    const containerClasses = clsx(fullHeight && classes.full_height, fullWidth && classes.full_width);
    return (
        <Breakpoint className={containerClasses} customQuery="(max-width: 800px)">
            {children}
        </Breakpoint>
    )
}
export default ViewSmall;