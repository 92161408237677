import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.grey[200],
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 250px)'
    },
    final_price: {
        fontSize: "xx-large"
    },
    price_resume_container: {
        borderRadius: '4px',
        padding: '16px',
        margin: '16px',
        height: 'fit-content',
        minWidth: '150px',
        backgroundColor: theme.palette.common.white,
    },
}));

export {useStyles}