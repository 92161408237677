import React from 'react';
import PackageDeliveryRowProperties from "./PackageDeliveryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import PackageDeliveryRowMedium from "./PackageDeliveryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import PackageDeliveryRowSmall from "./PackageDeliveryRowSmall";


const PackageDeliveryRow: React.FC<PackageDeliveryRowProperties> = ({
                                                    packageDeliveryOrder,
                                                    selectedDeliveryOrderIds,
                                                    onPackageDeliveryOrderClick,
                                                    onPackageDeliveryOrderSelect,
                                                    onDeliveryPersonSelect,
                                                    onContactSelect,
                                                    onUpdate,
                                                    filter,
                                                                        shippingId
                                                }) => {
    return (
      <>
        <ViewSmall>
          <PackageDeliveryRowSmall
            onUpdate={onUpdate}
            onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect}
            onPackageDeliveryOrderClick={onPackageDeliveryOrderClick}
            onDeliveryPersonSelect={onDeliveryPersonSelect}
            onContactSelect={onContactSelect}
            packageDeliveryOrder={packageDeliveryOrder}
            selectedDeliveryOrderIds={selectedDeliveryOrderIds} 
            filter={filter}
            shippingId={shippingId}/>
        </ViewSmall>
        <ViewMedium>
          <PackageDeliveryRowMedium
            onUpdate={onUpdate}
            onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect}
            onPackageDeliveryOrderClick={onPackageDeliveryOrderClick}
            onDeliveryPersonSelect={onDeliveryPersonSelect}
            onContactSelect={onContactSelect}
            packageDeliveryOrder={packageDeliveryOrder}
            selectedDeliveryOrderIds={selectedDeliveryOrderIds}
            filter={filter}
            shippingId={shippingId}/>
        </ViewMedium>
        <ViewLarge>
          <PackageDeliveryRowMedium
            onUpdate={onUpdate}
            onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect}
            onPackageDeliveryOrderClick={onPackageDeliveryOrderClick}
            onDeliveryPersonSelect={onDeliveryPersonSelect}
            onContactSelect={onContactSelect}
            packageDeliveryOrder={packageDeliveryOrder}
            selectedDeliveryOrderIds={selectedDeliveryOrderIds}
            filter={filter}
            shippingId={shippingId}/>
        </ViewLarge>
      </>
    );
}

export default PackageDeliveryRow;
