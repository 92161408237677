import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '8px',
        border: `1px solid ${theme.palette.grey[200]}`,
        width: 700
    },
    fees_container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
        rowGap: '16px',
        columnGap: '16px',
    },
    payment_fee_row_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        border: `1px solid #cccccc61`,
        borderRadius: 4,
        padding: '4px 8px'
    },
    payment_type: {
        fontSize: 16,
        marginBottom: 8,
        justifyContent: 'end',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        "& svg": {
            fontSize: 20,
            marginRight: 4,
            color: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
        }
    },
    btn_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 16,
    }

}));

export {useStyles}