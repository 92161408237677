import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    buttons_container: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 5
    }
}));

export {useStyles}