import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container:{
        backgroundColor: theme.palette.common.white,
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    title:{
        display: 'flex',
        fontSize: '18px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "4px 8px"
    },
    prices_container: {
        display: "grid",
        gridTemplateColumns: "repeat( auto-fit, minmax(200px, 1fr) )",
        columnGap: "8px",
        padding: "8px",
        rowGap: "8px",
    },
    no_content_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        height: '100%',
        color: theme.palette.grey[400],
        textAlign: 'center',
        cursor: 'pointer',
        "& svg": {
            fontSize: '50px'
        }
    },
    loading_container: {
        minHeight: '80px',
    },
    overview_container:{
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        columnGap: "8px",
        padding: "8px",
        rowGap: "8px",
        backgroundColor: theme.palette.grey[200],
    },
    orders_container:{
        display: "grid",
        maxHeight: "400px",
        overflow:"auto"
    },
    order_container:{
        display: "flex",
    },
    payment_type_container:{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        columnGap: 4
    }
}));

export {useStyles}