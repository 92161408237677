import React from 'react';
import MiscellanyCategorySelectListProperties from "./MiscellanyCategorySelectListProperties";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./MiscellanyCategorySelectList.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import MiscellanyCategorySelectListStore from "./MiscellanyCategorySelectListStore";
import {Observer} from "mobx-react-lite";
import SearchMiscellanyCategoryPanel from './searchMiscellanyCategory/SearchMiscellanyCategoryPanel';
import MiscellanyCategory from '../../../entities/productOrder/miscellany/MiscellanyCategory';

const MiscellanyCategorySelectList: React.FC<MiscellanyCategorySelectListProperties> = ({
                                                                          onMiscellanyCategorySelect,
                                                                          agencyId,
                                                                          fullWidth = false,
                                                                          className = '',
                                                                          selectedMiscellanyCategory,
                                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const commonContext = useCommonContext();
    const store = new MiscellanyCategorySelectListStore(commonContext, selectedMiscellanyCategory, agencyId);
    const containerClasses = clsx(
        classes.delivery_person_contact_container,
        className, fullWidth && commonClasses.fullWidth);

    const selectMiscellanyCategory = (miscellanyCategory?: MiscellanyCategory) => {
        store.selectedMiscellanyCategory = miscellanyCategory;
        onMiscellanyCategorySelect(store.selectedMiscellanyCategory);
    }

    return (
        <Observer>
            {() => (
                <div className={containerClasses}>
                    <SearchMiscellanyCategoryPanel onMiscellanyCategorySelect={selectMiscellanyCategory} store={store}/>
                </div>
            )}
        </Observer>
    )
}


export default MiscellanyCategorySelectList;