import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";

export module ProductOrderService {
    
    export async function addComment(deliveryOrderId: string, comment: string): Promise<Response<any>> {
        const url = Config.get("ADD_DELIVERY_COMMENT_URL", {
            id: deliveryOrderId
        });
        const request = new Request(url, { comment: comment ? comment : '' });
        return await RestClient.post(request);
    }

    export async function updateObservations(deliveryOrderId: string, observations: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_DELIVERY_OBSERVATIONS_URL", {
            id: deliveryOrderId
        });
        const request = new Request(url, { observations: observations ?? '' });
        return await RestClient.put(request);
    }

    export async function removeBatch(productOrdersIds: string[]): Promise<Response<any>> {
        const url = Config.get("REMOVE_PRODUCT_ORDERS_URL", {ids: productOrdersIds});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}