import clsx from "clsx";
import {useCommonStyles} from "../CommonStyles.style";
import Alignment from "../enums/Alignment";

export const useJustifyContentClasses = (aligment: Alignment) => {
    const commonClasses = useCommonStyles();
    return clsx(
        commonClasses.flex,
        aligment === Alignment.CENTER && commonClasses.justifyContentCenter,
        aligment === Alignment.SPACE_AROUND && commonClasses.justifyContentSpaceAround,
        aligment === Alignment.SPACE_BETWEEN && commonClasses.justifyContentSpaceBetween,
        aligment === Alignment.START && commonClasses.justifyContentStart,
        aligment === Alignment.END && commonClasses.justifyContentEnd);
}