import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    all_container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    show_balances_container: {
        display: 'flex',
        flexGrow: 1
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
    balances_container: {
        display: 'flex',
        gap: '5px',
        width: '100%',
    },
    balance: {
        fontFamily: 'Alkatra, cursive',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '12px',
        marginTop: '5px',
        paddingRight: '5px',
        paddingTop: '10px',
        maxHeight: '45px',
        width: '120px'
    },
    border: {
        borderRight: `1px solid ${theme.palette.grey["300"]}`,
    },
    hint: {
        fontSize: '10px',
        color: theme.palette.grey[700]
    },
    positive: {
        color: theme.palette.success.light
    },
    negative: {
        color: theme.palette.error.light
    },
    progress_bar: {
        marginTop: '2px',
        width: '100%',
        height: '30px',
        backgroundColor: theme.palette.grey[300],
        borderRadius: '10px',
        overflow: 'hidden'
    },
    progress: {
        height: '100%',
        transition: 'width 0.3s ease',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        textIndent: '8px'
    },
    active_balance: {
        position: 'absolute'
    }
}));

export {useStyles}