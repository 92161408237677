import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_price_container: {
        display: 'flex',
        flexDirection: "column",
        lineHeight: '16px',
    },
    number: {
        fontSize: '25px',
        marginLeft: '4px'
    },
    currency: {
        color: theme.palette.grey[600],
        fontSize: '11px',
        marginLeft: '8px'
    },
    title: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
    value: {
        fontSize: '18px',
        fontWeight: 600,
        marginTop: '3px'
    },
}));

export {useStyles}