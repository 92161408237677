import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import MoneyDeliveryFee from "../../entities/productOrder/moneyDelivery/MoneyDeliveryFee";

export module MoneyServiceFeeService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string): Promise<Response<PagedResponse<MoneyDeliveryFee>>> {
        const url = Config.get("GET_MONEY_DELIVERY_FEE_URL", {page, pageSize, filter});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const moneyServiceFeeData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (moneyServiceFeeData && paginationData) {
                const data = moneyServiceFeeData.map((t: any) => new MoneyDeliveryFee(t.id, t.agencyId, t.fromValue, t.toValue, t.currenciesValues));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<MoneyDeliveryFee>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<MoneyDeliveryFee>>(false, undefined, response.error, response.status);
    }

    export async function getAll(): Promise<Response<MoneyDeliveryFee[]>> {
        const url = Config.get("GET_MONEY_DELIVERY_FEE_URL", {page: 1, pageSize: 1000, filter: ''});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const moneyServiceFeeData = response.data?.data;
            if (moneyServiceFeeData) {
                const data = moneyServiceFeeData.map((t: any) => new MoneyDeliveryFee(t.id, t.agencyId, t.fromValue, t.toValue, t.currenciesValues));
                return new Response<MoneyDeliveryFee[]>(true, data, response.error)
            }
        }
        return new Response<MoneyDeliveryFee[]>(false, undefined, response.error, response.status);
    }

    export async function getAllFromBaseAgency(): Promise<Response<MoneyDeliveryFee[]>> {
        const url = Config.get("GET_MONEY_DELIVERY_FEE_FROM_BASE_AGENCY_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const moneyServiceFeeData = response.data;
            if (moneyServiceFeeData) {
                const data = moneyServiceFeeData.map((t: any) => new MoneyDeliveryFee(t.id, t.agencyId, t.fromValue, t.toValue, t.currenciesValues));
                return new Response<MoneyDeliveryFee[]>(true, data, response.error)
            }
        }
        return new Response<MoneyDeliveryFee[]>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<MoneyDeliveryFee>> {
        const url = Config.get("FIND_MONEY_DELIVERY_FEE_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const data = response.data;
            if (data) {
                const moneyServiceFee = new MoneyDeliveryFee(data.id, data.agencyId, data.fromValue, data.toValue, data.currenciesValues);
                return new Response<MoneyDeliveryFee>(true, moneyServiceFee, response.error);
            }
        }
        return new Response<MoneyDeliveryFee>(true, undefined, response.error, response.status);
    }

    export async function create(data: MoneyDeliveryFee): Promise<Response<any>> {
        const url = Config.get("CREATE_MONEY_DELIVERY_FEE_URL");
        const request = new Request(url, data);
        return await RestClient.post(request);
    }

    export async function update(data: MoneyDeliveryFee): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_DELIVERY_FEE_URL", {id: data.id});
        const request = new Request(url, data);
        return await RestClient.put(request);
    }

    export async function remove(data: MoneyDeliveryFee): Promise<Response<any>> {
        const url = Config.get("REMOVE_MONEY_DELIVERY_FEE_URL", {id: data.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}