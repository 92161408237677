import React from 'react';
import {useStyles} from "../Promotions.style";
import clsx from "clsx";
import PromotionRowProperties from "./PromotionRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import RemovePromotionButton from "../../../shared_components/Buttons/RemovePromotionButton/RemovePromotionButton";
import NewPromotionButton from '../../../shared_components/Buttons/NewPromotionButton/NewPromotionButton';


const PromotionRowSmall: React.FC<PromotionRowProperties> = ({promotion, onUpdate}) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.promotion_info_container_small)}>
            <ShowSellerBasicInfo autofill name={promotion.name} email={promotion.name}/>
            <div className={classes.menu_container_small}>
                <NewPromotionButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    promotionId={promotion.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <RemovePromotionButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    promotion={promotion}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
            </div>
        </div>
    )
}

export default PromotionRowSmall;
