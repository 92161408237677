import React from "react";
import CustomCategoryRowProperties from "./CustomCategoryRowProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import ShowText from "../../../shared_components/Info/ShowText/ShowText";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import NewCustomCategoryButton
    from "../../../shared_components/Buttons/NewCustomCategoryButton/NewCustomCategoryButton";
import RemoveCustomCategoryButton
    from "../../../shared_components/Buttons/RemoveCustomCategoryButton/RemoveCustomCategoryButton";
import {useStyles} from "../CustomCategory.style";
import NewCustomCategoryWeightRangeButton
    from "../../../shared_components/Buttons/NewCustomCategoryWeightRangeButton/NewCustomCategoryWeightRangeButton";
import CustomCategoryWeightRangeRow from "./WeightRange/CustomCategoryWeightRangeRow/CustomCategoryWeightRangeRow";
import { useUserContext } from "../../../contexts/UserContext";
import Access from "../../../shared_components/Access/AllowAccess/Access";

const CustomCategoryRowMedium:React.FC<CustomCategoryRowProperties> = ({
    customCategory,
    onUpdate = ()=>{},
}) => {
    const classes = useStyles()
    const i18n = useI18nContext()
    const userStore = useUserContext();

    return(
        <div
            className={clsx(classes.custom_category_info_container)}>
            <ShowText title={i18n.translate("NAME")} text={customCategory.name}/>
            <ShowText title={i18n.translate("DESCRIPTION")} text={customCategory.description}/>
            <ShowText title={i18n.translate("CUSTOM_CATEGORY_TYPE")} text={i18n.translate(customCategory.categoryType.valueOf().toString())}/>
            <ShowText title={i18n.translate("WEIGHT_RANGES")} text={customCategory.weightRanges.length.toString()}/>
            <div className={classes.elements_container}>
                {customCategory.weightRanges.map((weightRange,i)=>(
                    <CustomCategoryWeightRangeRow
                        onUpdate = {onUpdate}
                        customCategoryWeightRange={weightRange}
                        customCategoryId={customCategory.id}
                        key={i}
                     />
                ))}
            </div>
            {(userStore.hasAccess(Access.CREATE_EDIT_WEIGHT_RANGE) 
                || userStore.hasAccess(Access.CREATE_EDIT_CUSTOM_CATEGORY) 
                || userStore.hasAccess(Access.REMOVE_CUSTOM_CATEGORY)) &&
                    <ExpandoMenu>
                        <>
                            {userStore.hasAccess(Access.CREATE_EDIT_WEIGHT_RANGE) &&
                                <NewCustomCategoryWeightRangeButton
                                    type={ButtonType.SECONDARY}
                                    onSave={onUpdate}
                                    customCategoryId={customCategory.id}
                                    size={ButtonSize.SMALL}
                                    showText/>
                            }
                            {userStore.hasAccess(Access.CREATE_EDIT_CUSTOM_CATEGORY) &&
                                <NewCustomCategoryButton
                                    type={ButtonType.SECONDARY}
                                    onSave={onUpdate}
                                    customCategoryId={customCategory.id}
                                    size={ButtonSize.SMALL}
                                    showText/>
                            }
                            <RemoveCustomCategoryButton
                                    type={ButtonType.SECONDARY}
                                    onSave={onUpdate}
                                    customCategory={customCategory}
                                    size={ButtonSize.SMALL}/>
                        </>
                    </ExpandoMenu>
            }
        </div>
    )
}
export default CustomCategoryRowMedium