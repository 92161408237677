import Phone from "../../entities/Phone";

class AgencyResponse {
    public id: string;
    public name: string;
    public email?: string;
    public phone?: Phone;
    public address?: string;
    public salePriceId?: string;
    public salePriceName?: string;
    public createdAt: number;

    constructor(id: string, name: string, createdAt: number, email?: string, phone?: Phone, address?: string, salePriceId?: string,
        salePriceName?: string) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.address = address;
        this.salePriceId = salePriceId;
        this.salePriceName = salePriceName;
        this.createdAt = createdAt;
    }
}

export default AgencyResponse;