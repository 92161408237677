import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ProductOrderStatusHistory from "../../../entities/ProductOrderStatusHistory";

class ProductOrderStatusHistoryButtonStore {

    public orderId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public statusHistory: ProductOrderStatusHistory[];
    public isRootAgency: boolean;

    constructor(commonStore: CommonStore, orderId: string, isRootAgency: boolean) {
        this.orderId = orderId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.isRootAgency = isRootAgency;
        this.statusHistory = [];
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.statusHistory.length === 0;
    }
}

export default ProductOrderStatusHistoryButtonStore;