import React from 'react';
import {useStyles} from "./Pie.style";
import PieProperties from "./PieProperties";
import clsx from "clsx";
import {ResponsivePie} from "@nivo/pie";
import {Color} from "../Colors";
import {CurrencyModule} from "../../../util/Currency";


const Pie: React.FC<PieProperties> = ({
                                          className = '',
                                          data
                                      }) => {
    const classes = useStyles();
    const parsedData = data.map((d, i) => ({
        id: d.label,
        label: d.label,
        value: d.value,
        color: Color.get(i)
    }))
    const defs = [
        {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
        },
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
        }
    ];
    const fill: { match: { id: string }; id: string }[] = data.map((d, i) => ({
        match: {id: d.label},
        id: defs[i % defs.length].id
    }));
    return (
        <div className={clsx(classes.graph_container, className)}>
            <ResponsivePie
                data={parsedData}
                margin={{top: 40, right: 80, bottom: 80, left: 80}}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{from: 'color'}}
                arcLabelsSkipAngle={10}
                valueFormat={(v) => `${CurrencyModule.format(v)}`}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                defs={defs}
                fill={fill}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    )
}
export default Pie;