import AddPaymentRequest from "../payment/AddPaymentRequest";

class TopupOrderRequest {
    public clientId: string;
    public contactId: string;
    public agencyId?: string;
    public userId?: string;
    public topupProductId: string;
    public comment?: string;
    public anonymousNumber?: string;
    public payments:AddPaymentRequest[];

    constructor(
        clientId: string,
        contactId: string,
        topupProductId: string,
        payments:AddPaymentRequest[],
        agencyId?: string,
        userId?: string,
        comment?: string,
        anonymousNumber?: string) {
        this.clientId = clientId;
        this.contactId = contactId;
        this.agencyId = agencyId;
        this.userId = userId;
        this.topupProductId = topupProductId;
        this.comment = comment;
        this.payments = payments;
        this.anonymousNumber = anonymousNumber;
    }
}

export default TopupOrderRequest;