import CommonStore from "../../../stores/CommonStore";
import {action, makeObservable, observable} from "mobx";
import {SalesService} from "../../../service/SalesService";
import SalesByAgencyResponse from "../../../model/sales/SalesByAgencyResponse";

class AgencySalesByChildAgenciesStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public from?: number;
    public to?: number;
    public agencyId?: string;
    public sales?: SalesByAgencyResponse

    constructor(
        commonStore: CommonStore,
        from?: number,
        to?: number,
        agencyId?: string,
    ) {
        this.commonStore = commonStore;
        this.loading = true;
        this.from = from;
        this.agencyId = agencyId;
        this.to = to;
        makeObservable(this, {
            loading: observable,
            from: observable,
            to: observable,
            sales: observable,
            agencyId: observable,
        });
    }

    @action
    public async getElements(): Promise<void> {
        this.loading = true;
        const response = await SalesService.getSalesByAgency(this.from, this.to, this.agencyId);
        if (response.success) {
            this.sales = response.data;
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }
}

export default AgencySalesByChildAgenciesStore;