import React from 'react';
import PaymentProperties from "./PaymentProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from './Payment.style';
import AddPayment from "../../../Content/AddPayment/AddPayment";
import AddPaymentRequest from "../../../../model/payment/AddPaymentRequest";
import ShowText from '../../../Info/ShowText/ShowText';
import { CurrencyModule } from '../../../../util/Currency';

const Payment: React.FC<PaymentProperties> = ({
                                                  store
                                              }) => {

    const classes = useStyles();

    const onPaymentChange = (payments: AddPaymentRequest[], paymentCompleted: boolean) => {
        store.setPayments(payments, paymentCompleted);
    }

    return <Observer>
        {() => (
            <div className={classes.container}>
                <div className={classes.price_resume_container}>
                            {store.itemsAndCategories.map((categoryWithItem,i)=>(
                                <ShowText title={categoryWithItem.categoryInfo.name} text={CurrencyModule.format(categoryWithItem.finalPrice || 0)}/>
                            ))}
                            <ShowText className={classes.final_price} title={"Total"} text={CurrencyModule.format(store.finalPackagePrice || 0)}/>
                </div>
                <AddPayment agencyId={store.selectedAgency?.id} currentPayments={store.payments} total={store.getTotalToPay()}
                            onPaymentsChange={onPaymentChange}/>                
            </div>
        )}
    </Observer>
}

export default Payment;
