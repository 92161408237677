import React from 'react';
import clsx from "clsx";
import PageLoadingProperties from "./PageLoadingProperties";
import {useStyles} from "./PageLoading.style";
import Loading from "./Loading";


const PageLoading: React.FC<PageLoadingProperties> = ({
                                                          text,
                                                          isLoading = false,
                                                          className = '',
                                                          loadingSize = 80
                                                      }: PageLoadingProperties) => {
    const classes = useStyles();
    if (!isLoading) return <></>;
    return (
        <div className={clsx(classes.page_loading_container, className)}>
            {<div className={classes.loading_container}>
                <Loading fontSize={loadingSize}/>
                <div>{text}</div>
            </div>}
        </div>
    )
}

export default PageLoading;