import React from 'react';
import ShowPriceProperties from "./ShowPriceProperties";
import {useStyles} from "./ShowPrice.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";

const ShowPrice: React.FC<ShowPriceProperties> = ({
                                                      value,
                                                      type,
                                                      valueBefore,
                                                      currency,
                                                      className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();
    const formattedValue = CurrencyModule.format(value, currency).split(".");
    const intValue = formattedValue[0];
    const decimalValue = formattedValue[1];

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div className={clsx(
                classes.number,
                (value >= 0 || type === 'positive') && classes.positive,
                (value < 0 || type === 'negative') && classes.negative,
            )}>{value &&
            <>
                <span>{intValue}</span>.
                <span>{decimalValue}</span>
            </>
            }</div>
            <div className={classes.currency}>{i18n.translate(currency)}</div>
            <div className={classes.value_before}>{!!valueBefore &&
            <>{i18n.translate("BALANCE_BEFORE")} {CurrencyModule.format(valueBefore, currency)}</>
            }</div>
        </div>
    )
}


export default ShowPrice;