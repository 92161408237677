import React, {useEffect, useState} from "react";
import DownloadAgencyOrdersReportButtonProperties from "./DownloadAgencyOrdersReportButtonProperties";
import {Observer} from "mobx-react-lite";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {Config} from "../../../util/Config";
import {RestClient} from "../../../util/RestClient";
import {Downloader} from "../../../util/Download";
import Button from "../../../ui_components/Button/Button";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";


const DownloadAgencyOrdersReportButton: React.FC<DownloadAgencyOrdersReportButtonProperties> = ({
                                                                                                    from,
                                                                                                    to,
                                                                                                    agencyId,
                                                                                                    className = '',
                                                                                                    text = "Descargar",
                                                                                                    disabled = false,
                                                                                                    rounded = false,
                                                                                                    size = ButtonSize.DEFAULT,
                                                                                                    type = ButtonType.DEFAULT,
                                                                                                    showIcon = true,
                                                                                                    showText = true,
                                                                                                    showPreview = false
                                                                                                }) => {
    const label = showPreview ? "Reporte de ventas" : "Descargar reporte de ventas";

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={onClick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? label : ""}
                text={showText ? label : ""}
                type={type}
                icon={showIcon ? <ReceiptIcon/> : <></>}
            />
        }
        return <Button
            loading={loading}
            text={showText ? label : ""}
            className={className}
            tooltip={!showText ? label : ""}
            disabled={disabled}
            size={size}
            onClick={onClick}
            type={type}
            startIcon={showIcon ? <ReceiptIcon/> : <></>}
        />
    }
    const getUrl = () => {
        return Config.get("DOWNLOAD_AGENCY_ORDER_REPORT_URL", {
            from,
            to,
            id: agencyId
        })
    }
    const [url, setUrl] = useState(getUrl());
    const [loading, setLoading] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);

    useEffect(() => {
        const newUrl = getUrl();
        setUrl(newUrl);
        // eslint-disable-next-line
    }, [from, to, agencyId]);

    const formatDate = (timestamp?: number) => {
        const date = new Date(timestamp || 0);
        const month = date.getMonth();
        const year = date.getFullYear();
        const day = date.getDate();
        return `${day}-${month}-${year}`;
    }
    const onClick = async () => {
        if (!showPreview) {
            setLoading(true);
            const response = await RestClient.download(url);
            const fromParsed = formatDate(from);
            const toParsed = formatDate(to);
            let fileName = `resumen_de-ventas(${fromParsed} a ${toParsed}).pdf`;
            if (fromParsed === toParsed) {
                fileName = `resumen_de-ventas(${fromParsed}).pdf`;
            }
            if (response.success) {
                Downloader.download(response.data?.filename || fileName, response.data?.data);
            }
            setLoading(false);
        } else {
            setOpenPreview(true);
        }
    }
    return (
        <Observer>{() => (
            <>
                <Btn/>
                <SidePanel title={"Detalles de las ventas"} open={openPreview} onClose={() => setOpenPreview(false)}>
                    <embed src={`${url}#toolbar=1&navpanes=0&scrollbar=0`} width="800" type="application/pdf"></embed>
                </SidePanel>
            </>
        )}</Observer>
    );
};

export default DownloadAgencyOrdersReportButton;
