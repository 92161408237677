import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    expando_menu_container: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'end'
    },
    button_container: {
        backgroundColor: theme.palette.secondary.light,
        display: 'flex',
        height: 'fit-content',
        padding: '4px',
        borderRadius: '4px',
        color: theme.palette.common.white,
        alignSelf: 'center'
    },
    menu_items_container: {
        position: "absolute",
        backgroundColor: theme.palette.common.white,
        padding: '10px',
        right: '33px',
        top: '5px',
        minWidth: '200px',
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: '4px',
        display: 'grid',
        rowGap: '4px',
        zIndex: 40,
        "& > div": {
            width: "100%",
            backgroundColor: theme.palette.common.white,
            "& svg": {
                fontSize: 20
            },
            "& *": {
                color: theme.palette.secondary.dark,
                fill: theme.palette.secondary.dark,
                justifyContent: "left"
            },
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
            }

        }
    },
    menu_items_container_right: {
        left: '33px',
        right: 'auto',
    },
    disabled: {
        cursor: 'default',
        backgroundColor: `${theme.palette.grey[200]} !important`,
        color: theme.palette.grey[400],
        "&:hover": {
            backgroundColor: theme.palette.grey[200],
        },
        "& *": {
            color: theme.palette.grey[400],
            fill: theme.palette.grey[400],
        }
    },
}));

export {useStyles}