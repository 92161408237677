import React, {useEffect} from 'react';
import {useStyles} from "./TopupPrices.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import {Observer} from "mobx-react-lite";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import TopupPriceStore from './TopupPricesStore';
import TopupPriceRow from './TopupPricesRow/TopupPricesRow';
import NewTopupPriceButton from '../../shared_components/Buttons/NewTopupPriceButton/NewTopupPriceButton';
import ShowTopupCostsButton from '../../shared_components/Buttons/ShowTopupCostsButton/ShowTopupCostsButton';


const TopupPrices: React.FC = () => {
    const commonStore = useCommonContext();
    const topupPriceStore = new TopupPriceStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        topupPriceStore.getElements();
        topupPriceStore.getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await topupPriceStore.getElements();
    }

    return (
        <Observer>
            {() => (
                <div className={classes.topup_price_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <ShowTopupCostsButton type={ButtonType.SECONDARY}/>
                        <NewTopupPriceButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                    </Box>
                    {!topupPriceStore.isEmpty &&
                    <div className={classes.topup_price_table}>
                        <div className={classes.elements_container}>
                            {topupPriceStore.topupPrices.map((topupPrice, i) => (
                                <TopupPriceRow
                                    onUpdate={onUpdate}
                                    topupPrice={topupPrice} key={i}
                                    products={topupPriceStore.products}
                                />
                            ))}
                        </div>
                    </div>}
                    {topupPriceStore.isEmpty &&
                    <MessageCard icon={CurrencyExchangeIcon} title={'No hay precios de recargas!!!'}
                                 subtitle={'Utiliza la barra superior para crear un nuevo precio de recarga'}/>}
                </div>
            )}
        </Observer>
    )
}

export default TopupPrices;
