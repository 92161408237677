import React, { useRef } from 'react';
import {Observer} from "mobx-react-lite";
import Button from "../../../ui_components/Button/Button";
import {useCommonContext} from "../../../contexts/CommonContext";
import UploadImageButtonProperties from './UploadImageButtonProperties';
import UploadImageStore from './UploadImageStore';
import ButtonType from '../../../ui_components/Button/ButtonType';
import MessageCard from '../../../ui_components/MessageCard/MessageCard';
import HideImageIcon from '@mui/icons-material/HideImage';
import { useStyles } from './UploadImageButton.style';

const UploadImageButton: React.FC<UploadImageButtonProperties> = ({
                                                                  onSelect = () => {
                                                                  },
                                                                  disabled,
                                                                  className = '',
                                                                  type = ButtonType.DEFAULT
                                                              }) => {

    const classes = useStyles();
    const commonContext = useCommonContext();
    const store = new UploadImageStore(commonContext);

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        if (fileInputRef != null && fileInputRef.current != null) {
            (fileInputRef.current as HTMLInputElement | null)?.click();
        }
    };

    // Function to handle file selection
    const handleImageSelect = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target != null && event.target.result != null) {
                    store.selectedImage = event.target.result.toString();
                }
            };

            reader.readAsDataURL(file);

            onSelect(file);
        }
    };

    return <Observer>
        {() => (
            <>
                <input
                    type="file"
                    accept="image/png,image/jpeg"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleImageSelect}
                />
                <Button
                    text={"Seleccionar imagen"}
                    className={className}
                    disabled={disabled}
                    type={type}
                    onClick={handleButtonClick}
                />
                {!store.selectedImage && (
                    <div>
                        <MessageCard icon={HideImageIcon} title={"No hay imagen seleccionada!!!"}
                                 subtitle={"Seleccione una imagen para subir"}/>
                    </div>
                )}
                {store.selectedImage && (
                    <div>
                        <img
                            className={classes.selected_image}
                            src={store.selectedImage}
                            alt="Selected"
                            style={{ maxWidth: '100%' }}
                        />
                    </div>
                )}
            </>
        )}
    </Observer>
}

export default UploadImageButton;
