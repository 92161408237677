import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./Clients.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ClientRow from "./ClientRow/ClientRow";
import ClientStore from "./ClientStore";
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import NewClientButton from "../../shared_components/Buttons/NewClientButton/NewClientButton";
import useOnEnterPress from '../../hooks/EnterPress';
import AllowAccess from '../../shared_components/Access/AllowAccess/AllowAccess';
import Access from '../../shared_components/Access/AllowAccess/Access';
import AgencySelect from '../../shared_components/Selects/AgencySelect/AgencySelect';
import Agency from '../../entities/Agency';


const Clients: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const clientStore = new ClientStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        clientStore.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await clientStore.getElements();
    }

    const onAgencyChange = (newAgency?: Agency) =>
        (clientStore.agencyFilter = newAgency);

    const onPaginationChange = async (page: number, pageSize: number) => {
        await clientStore.goToPage(page, pageSize);
    }

    const onClientSearch = () => {
        clientStore.search()
    }
    useOnEnterPress(onClientSearch,[clientStore]);

    return (
        <Observer>
            {() => (
                <div className={classes.clients_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <NewClientButton type={ButtonType.SECONDARY} onSave={onUpdate} rounded/>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={clientStore.filter}
                                     placeholder={i18n.translate("CLIENT_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         clientStore.setFilter(value)
                                     }} onEnter={() => clientStore.search()}/>
                        <AllowAccess any={[Access.SEND_ORDERS_TO_AGENCIES]}>
                            <AgencySelect selected={clientStore.agencyFilter} onChange={onAgencyChange}/>
                        </AllowAccess>
                        <Button
                            onClick={onClientSearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    {!clientStore.isEmpty &&
                    <div className={classes.clients_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("CLIENTS")}
                                        pagination={clientStore.pagination}/>
                        <div className={classes.elements_container}>
                            {clientStore.clients.map((client, i) => (
                                <ClientRow
                                    onUpdate={onUpdate}
                                    client={client}
                                    key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("CLIENTS")}
                            pagination={clientStore.pagination}/>
                    </div>}
                    {clientStore.isEmpty &&
                    <MessageCard icon={AccountBoxSharpIcon} title={i18n.translate("NO_CLIENTS_TITLE")}
                                 subtitle={i18n.translate("NO_CLIENTS_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default Clients;
