import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    single_topup_container: {
        display: 'flex',
        flexGrow: 1,
        padding: "10px",
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100vh - 250px)'
    },
    form_container: {
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '1024px',
        borderRadius: '4px',
        height: 'fit-content',
        padding: "24px 16px",
        display: 'grid',
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr));",
        columnGap: "16px",
        rowGap: '16px',
        "& > *:first-child":{
            gridColumnStart: 'span 3'
        },
        "@media (max-width: 800px)": {
            display: 'flex',
            flexDirection: 'column',
            padding: '6px 16px 0'
        }
    },
    contact_container:{
        display: "flex",
        flexDirection: 'column',
        height: '100px',
        justifyContent: 'space-between',
    },
    description:{
      padding: 4,
      fontSize: 14,
      color: theme.palette.grey[500]
    },
    comment: {
        gridColumnStart: 'span 4'
    },
    save_btn: {
        height: 'fit-content',
        gridColumn: 4,
        justifySelf: 'flex-end'
    },
    error: {
        gridColumn: 1,
        color: theme.palette.error.light
    },
    rows_container: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '1024px',
        borderRadius: '4px',
        flexGrow: 1,
        marginTop: '16px',
        overflow: 'auto',
        height: '1px'
    },
    empty_list: {
        boxShadow: 'none',
        height: '100%'
    }
}));

export {useStyles}