import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    save_btn: {
        marginTop: '16px'
    },
    input_amount: {
        marginRight: '4px',
        marginTop: '16px'
    },
    input_name: {
        marginTop: '16px',
    },
    amount_and_name_container: {
        display: "flex",
        width: '100%'
    },
    btn_container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        width: '100%'
    },
    cancel_btn: {
        marginTop: '16px',
        marginLeft: '10px'
    },
    container: {
        width: '100%',
        margin: '4px 24px 4px 0'
    },
}));

export {useStyles}