import React from 'react';
import {useStyles} from "../MiscellanyCategories.style";
import clsx from "clsx";
import MiscellanyCategoryRowProperties from "./MiscellanyCategoryRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewMiscellanyCategoryButton from '../../../shared_components/Buttons/NewMiscellanyCategoryButton/NewMiscellanyCategoryButton';


const MiscellanyCategoryRowSmall: React.FC<MiscellanyCategoryRowProperties> = ({miscellanyCategory, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.miscellany_category_info_container_small)}>
            <ShowSellerBasicInfo autofill name={miscellanyCategory.name} email={miscellanyCategory.description}/>
            <div className={classes.menu_container_small}>
                <NewMiscellanyCategoryButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    miscellanyCategoryId={miscellanyCategory.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
            </div>
        </div>
    )
}

export default MiscellanyCategoryRowSmall;
