import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    lockup_container: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '6px',
        padding: '8px',
        boxSizing: 'border-box',
        display: "flex",
        flexDirection: "column",
        lineHeight: '23px'
    },
    lockup_container_with_background_info: {
        backgroundColor: theme.palette.info.light,
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    lockup_container_with_background_success: {
        backgroundColor: theme.palette.success.light,
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    lockup_container_with_background_danger: {
        backgroundColor: theme.palette.error.light,
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    lockup_container_with_background_primary: {
        backgroundColor: theme.palette.primary.light,
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    lockup_container_with_background_secondary: {
        backgroundColor: theme.palette.secondary.light,
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    lockup_container_with_background_warning: {
        backgroundColor: theme.palette.warning.light,
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    lockup_container_with_background_purple: {
        backgroundColor: "#8026D9FF",
        "& $info": {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
            textDecoration: "underline"
        },
        "& $total": {
            color: theme.palette.common.white
        },
        "& $value,$orders": {
            color: theme.palette.common.white
        }
    },
    title: {
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        width: "fit-content",
        padding: '0px 4px',
        marginBottom: 6,
        borderRadius: 4,
    },
    value_container: {
        display: "flex",
        alignItems: 'center'
    },
    value: {
        fontFamily: 'Alkatra, cursive'
    },
    total: {
        color: theme.palette.grey[500],
        fontSize: '12px',
        marginTop: 6,
        textWrap: 'nowrap'
    },
    progress_container: {
        lineHeight: "12px",
        marginTop: 5
    },
    more_info_container: {
        fontSize: '12px',
        color: theme.palette.info.dark,
        marginLeft: 4,
        textDecoration: "underline",
        cursor: "pointer",
        lineHeight: 0
    },
    orders: {
        color: theme.palette.grey[500],
        fontSize: '12px',
        textWrap: 'nowrap'
    },
    info: {
        backgroundColor: theme.palette.info.light,
    },
    success: {
        backgroundColor: theme.palette.success.light,
    },
    danger: {
        backgroundColor: theme.palette.error.light,
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
    },
    warning: {
        backgroundColor: theme.palette.warning.light,
    },
    purple: {
        backgroundColor: "#8026D9FF"
    },
    with_border: {
        border: `1px solid ${theme.palette.grey[200]}`,
    }
}));

export {useStyles}