import React from 'react';
import {useStyles} from "./ShowGeo.style";
import ShowGeoProperties from "./ShowGeoProperties";
import clsx from "clsx";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useI18nContext} from "../../../contexts/I18nContext";

const ShowGeo: React.FC<ShowGeoProperties> = ({
                                                  className = '',
                                                  geo = null,
                                                  showParentInfo = false,
                                                  showIcon = true,
                                                  light = false
                                              }) => {
    const classes = useStyles(light);
    const i18n = useI18nContext();

    return (
        <div className={clsx(classes.geo_info_container, className)}>
            {showIcon && <div className={clsx(classes.geo_icon, !geo && classes.inactive)}><LocationOnIcon/></div>}
            <div className={classes.info_container}>
                <div>{geo?.name}</div>
                <div>{!showParentInfo && i18n.translate(geo?.getLevelText())}</div>
                <div>{showParentInfo && geo?.parentInfo}</div>
                {!geo && <div className={classes.no_geo}>{i18n.translate("NO_GEO")}</div>}
            </div>
        </div>
    )
}
export default ShowGeo;