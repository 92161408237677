import Geo from "./Geo";

class ArticlePrice {
    public id: string;
    public geoId: string;
    public geo?: Geo;
    public currency: string;
    public price: number;
    public articleId: string;

    constructor(id: string, geoId: string, geo: Geo, currency: string, price: number, articleId: string) {
        this.id = id;
        this.geoId = geoId;
        this.geo = geo;
        this.currency = currency;
        this.price = price;
        this.articleId = articleId;
    }
}

export default ArticlePrice;