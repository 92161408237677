import Geo from "../../entities/Geo";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import Phone from "../../entities/Phone";

class TopupOrderResponse {
    public id: string;
    public agencyId: string;
    public agencyName: string;
    public clientId: string;
    public clientName: string;
    public contactId: string;
    public contactName: string;
    public userId: string;
    public userName: string;
    public serviceFee: number;
    public totalToPay: number;
    public serviceCost: number;
    public createdAt: number;
    public status: ProductOrderStatus;
    public geo: Geo;
    public confirmation?: string;
    public error?: string;
    public comment?: string;
    public productId?: string;
    public productName?: string;
    public phone?: Phone;


    constructor(id: string,
                agencyId: string,
                agencyName: string,
                clientId: string,
                clientName: string,
                contactId: string,
                contactName: string,
                userId: string,
                userName: string,
                productId: string,
                productName: string,
                serviceFee: number,
                totalToPay: number,
                serviceCost: number,
                status: ProductOrderStatus,
                createdAt: number,
                geo: Geo,
                confirmation?: string,
                error?: string,
                comment?: string,
                phone?: Phone
                ) {
        this.id = id;
        this.agencyId = agencyId;
        this.agencyName = agencyName;
        this.clientId = clientId;
        this.clientName = clientName;
        this.contactId = contactId;
        this.contactName = contactName;
        this.userId = userId;
        this.userName = userName;
        this.contactName = contactName;
        this.serviceFee = serviceFee;
        this.totalToPay = totalToPay;
        this.serviceFee = serviceFee;
        this.serviceCost = serviceCost;
        this.status = status;
        this.createdAt = createdAt;
        this.error = error;
        this.confirmation = confirmation;
        this.geo = geo;
        this.comment = comment;
        this.productId = productId;
        this.productName = productName;
        this.phone = phone;
    }

}

export default TopupOrderResponse;