import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import ShippingInputProperties from "./ShippingEditInputProperties";

const ShippingEditInput: React.FC<ShippingInputProperties> = ({
                                                          value,
                                                          placeholder,
                                                          onChange,
                                                          disabled,
                                                          fullWidth = false,
                                                          className = '',
                                                          onEnter = () => {
                                                          }
                                                      }) => {

    const [shipping, setShipping] = useState(value);

    useEffect(() => {
        setShipping(value);
    }, [value]);

    const onShippingChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilter = event.target.value;
        setShipping(newFilter);
        onChange(newFilter);
    }

    const onKeyPress = (key: string) => {
        if (key === "Enter") {
            onEnter(shipping);
        }
    }

    return (
        <div>
            <div style={{alignItems: 'center', marginLeft: '50px', marginRight: '50px', marginBottom: '20px'  }}>
                <TextInput
                    label={"Número de envío"}
                    value={shipping}
                    placeholder={placeholder}
                    disabled={disabled}
                    onKeyPress={onKeyPress}
                    onChange={onShippingChange}
                    className={className}
                    fullWidth={fullWidth}
                />

            </div>
        </div>
    )
}


export default ShippingEditInput;
