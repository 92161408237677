import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryOrderType from "../../../model/DeliveryOrderType";
import {PackageDeliveryService} from "../../../service/packageDelivery/PackageDeliveryService";
import {MoneyDeliveryService} from "../../../service/moneyDelivery/MoneyDeliveryService";
import { ArticleDeliveryService } from "../../../service/articleDelivery/ArticleDeliveryService";
import { MiscellanyOrderService } from "../../../service/miscellany/MiscellanyOrderService";

class UpdateDeliveryOrderStatusStore {

    public deliveryOrderType?: DeliveryOrderType
    public productOrderId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public comment?: string;
    public status?: string;


    constructor(commonStore: CommonStore, productOrderId: string, deliveryOrderType?: DeliveryOrderType) {
        this.deliveryOrderType = deliveryOrderType
        this.productOrderId = productOrderId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.status);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.getServiceToCall();        
        const response = await serviceToCall(this.productOrderId, this.status!!, this.comment!!);
        this.commonStore.processErrors(response);
        this.loading = false;
        return response.success;
    }

    getServiceToCall() {
        switch (this.deliveryOrderType) {
            case DeliveryOrderType.PACKAGE_DELIVERY:
                return PackageDeliveryService.updateStatus;
            case DeliveryOrderType.ARTICLE_DELIVERY:
                return ArticleDeliveryService.updateStatus;
            case DeliveryOrderType.MISCELLANY_ORDER:
                return MiscellanyOrderService.updateStatus;
            default:
                return MoneyDeliveryService.updateStatus;
        }
    }
}

export default UpdateDeliveryOrderStatusStore;