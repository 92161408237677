import React, {useEffect} from 'react';
import AgencyTotalSalesProperties from "./AgencyTotalSalesProperties";
import {useStyles} from "./AgencyTotalSales.style";
import AgencyTotalSalesStore from "./AgencyTotalSalesStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import {useI18nContext} from "../../../contexts/I18nContext";
import InfoIcon from "@mui/icons-material/Info";
import PriceLockup from "../../../ui_components/PriceLockup/PriceLockup";
import SaleByServiceResponse from "../../../model/sales/SaleByServiceResponse";
import ServiceName from "../../../entities/ServiceName";
import MoneyDeliveryOverview from "../MoneyDeliveryOverview/MoneyDeliveryOverview";
import clsx from "clsx";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import DownloadSellerSalesReportButton from '../../Buttons/DownloadSellerSalesReportButton/DownloadSellerSalesReportButton';
import ButtonType from '../../../ui_components/Button/ButtonType';

const AgencyTotalSales: React.FC<AgencyTotalSalesProperties> = ({
                                                                    from,
                                                                    to,
                                                                    agencyId,
                                                                    userId,
                                                                    title,
                                                                    includeChildren = false,
                                                                    className = '',
                                                                    inline=false,
                                                                    withBorder=false
                                                                }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const store = new AgencyTotalSalesStore(commonStore, from, to, agencyId, userId, includeChildren);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to, agencyId]);

    const RenderPriceLockup = (service: SaleByServiceResponse) => {
        switch (service.serviceName) {
            case ServiceName.MONEY_DELIVERY:
                return <PriceLockup
                    value={service.total}
                    title={i18n.translate(service.serviceName)}
                    total={store.totalSales}
                    size={20}
                    orders={service.amount}
                    withBorder={withBorder}
                    theme={"info"}>
                    <MoneyDeliveryOverview
                        from={from}
                        to={to}
                        agencyId={agencyId}
                        size="small"
                        includeChildren={includeChildren}
                    />
                </PriceLockup>
            default:
                return <PriceLockup
                    value={service.total}
                    title={i18n.translate(service.serviceName)}
                    withBorder={withBorder}
                    total={store.totalSales}
                    size={20}
                    orders={service.amount}
                    theme={"info"}/>
        }
    }


    return <Observer>{() => (
        <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            {!!store.totalSales && store.totalSales > 0 && <>
                <PriceLockup value={store.totalSales} title={"Total de ventas"} size={30} theme="primary"
                             orders={store.sales?.amount}/>
                {userId && <div className={classes.download_btn_container}>
                    <DownloadSellerSalesReportButton
                        type={ButtonType.SECONDARY}
                        showPreview={false}
                        sellerId={userId}
                        from={from}
                        to={to}
                        size={ButtonSize.SMALL}/>
                </div>}
                <div className={clsx(classes.prices_container, inline && classes.inline)}>
                    {store.sales?.breakdown && store.sales?.breakdown.map((d, i) => (
                        <RenderPriceLockup key={i} {...d}/>
                    ))}
                </div>
            </>}
            {(!store.totalSales || store.totalSales === 0) &&
                <div className={classes.no_content_container} onClick={() => store.getElements()}>
                    <InfoIcon/>
                    {i18n.translate("NO_SALES")}
                </div>
            }
            <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                         text={`${i18n.translate("LOADING")} ${title || ''}`}/>
        </div>
    )}</Observer>

}


export default AgencyTotalSales;