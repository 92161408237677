import React, { useEffect } from 'react';
import { useStyles } from "./Home.style";
import { Observer } from "mobx-react-lite";
import HomeStore from "./HomeStore";
import { useCommonContext } from "../../contexts/CommonContext";
import ShowImage from '../../shared_components/Info/ShowImage/ShowImage';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import ShowTime from '../../shared_components/Info/ShowTime/ShowTime';
import ShowServicesShortcuts from '../../shared_components/Info/ShowServicesShortcuts/ShowServicesShortcuts';


const Home: React.FC = () => {
    const commonStore = useCommonContext();
    const homeStore = new HomeStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        homeStore.getPromotions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function Item(props: any) {
        return (
            <Paper>
                <div className={classes.carousel_content_container}>
                    <div className={classes.carousel_content_container_inside}>
                        <div className={classes.carousel_text}>
                            <h2 className={classes.carousel_title}>{props.item.name}</h2>
                            <span className={classes.carousel_description}>{props.item.description}</span>
                        </div>
                        <ShowImage className={classes.carousel_img} imageName={props.item.defaultImage} height={400}/>
                    </div>
                </div>
            </Paper>
        )
    }

    return (
        <Observer>
            {() => (
                <div className={classes.home_container}>
                    {!homeStore.isEmpty &&
                        <div className={classes.home_table}>
                            <Carousel>{
                                homeStore.activePromotions.map((item, i) => <Item key={i} item={item} />)
                            }</Carousel>
                        </div>}
                    <div className={classes.time_shorcuts_container}>
                        <ShowTime className={classes.full_height}/>
                        <ShowServicesShortcuts className={classes.full_width}/>
                    </div>
                </div>
            )}
        </Observer>
    )
}

export default Home;
