import {action, makeAutoObservable} from "mobx";
import MoneyDeliveryFee from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryFee";
import { MoneyServiceFeeService } from "../../../service/moneyDelivery/MoneyServiceFeeService";
import CommonStore from "../../../stores/CommonStore";


class RemoveMoneyServiceFeeStore {

    public moneyServiceFee: MoneyDeliveryFee;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, moneyServiceFee: MoneyDeliveryFee) {
        this.moneyServiceFee = moneyServiceFee;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await MoneyServiceFeeService.remove(this.moneyServiceFee);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveMoneyServiceFeeStore;