import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    box_text_container: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: `${theme.palette.grey[200]}`,
        border: `2px solid ${theme.palette.grey[700]}`,
        padding: '20px'
    }
}));

export {useStyles}