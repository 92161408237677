import React from "react";
import CustomCategoryRowProperties from "./CustomCategoryRowProperties";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import CustomCategoryRowSmall from "./CustomCategorySmall";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import CustomCategoryRowMedium from "./CustomCategoryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";

const CustomCategoryRow:React.FC<CustomCategoryRowProperties> = ({
                                                                    customCategory,
                                                                    onUpdate,
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <CustomCategoryRowSmall
                    customCategory={customCategory}
                    onUpdate={onUpdate}/>
            </ViewSmall>
            <ViewMedium>
                <CustomCategoryRowMedium
                    customCategory={customCategory}
                    onUpdate={onUpdate}/>
            </ViewMedium>
            <ViewLarge>
                <CustomCategoryRowMedium
                    customCategory={customCategory}
                    onUpdate={onUpdate}/>
            </ViewLarge>
        </>
    )
}
export default CustomCategoryRow