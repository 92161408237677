import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import {TopupService} from "../../service/topup/TopupService";
import Product from "../../entities/productOrder/topup/Product";
import {ProductService} from "../../service/topup/ProductService";
import Client from "../../entities/Client";
import TopupOrderResponse from "../../model/topup/TopupOrderResponse";
import Agency from "../../entities/Agency";

class TopupStore {

    public topups: TopupOrderResponse[];
    public products: Product[];
    public pagination: Pagination;
    public filter?: string;
    public commonStore: CommonStore;
    private clientIdToFilter?: Client;
    dateFromFilter?: number | undefined;
    dateToFilter?: number | undefined;
    public agencyFilter?: Agency;

    constructor(commonStore: CommonStore, dateFromFilter?: number | undefined, dateToFilter?: number | undefined) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.topups = [];
        this.commonStore = commonStore;
        this.clientIdToFilter = undefined;
        this.dateFromFilter = dateFromFilter;
        this.dateToFilter = dateToFilter;
        this.products = [];
        makeObservable(this, {
            topups: observable,
            pagination: observable,
            isEmpty: computed,
            products: observable
        });
    }

    get isEmpty(): boolean {
        return this.topups.length === 0;
    }

    public setFilter(filter: string) {
        this.filter = filter;
    }

    @action
    public async search() {
        await this.getElements(1);
    }

    @action
    public async clearFilter() {
        this.agencyFilter = undefined;
        this.filter = undefined;
        await this.getElements(1);
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        let clientId = undefined;
        let contactId = undefined;
        if (this.clientIdToFilter !== undefined) {
            clientId = this.clientIdToFilter.id;
        }
        this.commonStore.setLoading();

        const response = await TopupService.get(
            page,
            pageSize,
            clientId,
            contactId,
            this.agencyFilter?.id,
            this.filter,
            this.dateFromFilter,
            this.dateToFilter,
            undefined);
        if (response.success) {
            runInAction(() => {
                this.topups = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    public setClienToFilter(selectedClientContact?: Client) {
        this.clientIdToFilter = selectedClientContact;
    }

    public getSelectedClientToFilter(): Client | undefined {
        return this.clientIdToFilter;
    }

    setDateRangeToFilter(from: number | undefined, to: number | undefined): void {
        this.dateFromFilter = from;
        this.dateToFilter = to;
    }

    @action
    public async getProducts() {
        const response = await ProductService.get();
        if (response.success && response.data) {
            this.products = response.data
        }
    }

}

export default TopupStore;