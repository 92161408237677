import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    icon_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export {useStyles}