import {useStyles} from './MiscellanyCategoryElementRow.style';
import React from "react";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import MiscellanyCategoryElementRowProperties from "./MiscellanyCategoryElementRowProperties";
import clsx from "clsx";
import ShowPrice from '../../../../Info/ShowPrice/ShowPrice';

const MiscellanyCategoryElementRow: React.FC<MiscellanyCategoryElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <AccountBoxSharpIcon/>
            </div>
            <div className={classes.name_geo_container}>
                <div className={classes.text_container}>
                    <div>
                        <b>{element.name}</b> 
                    </div>
                    <div className={classes.description_container}>
                        {element.description}
                    </div>
                </div>
                <div className={classes.geo_container}>
                    <ShowPrice value={element.price}/>
                </div>
            </div>
        </div>
    )
}


export default MiscellanyCategoryElementRow;