import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import ClientContact from "../../entities/ClientContact";
import Agency from "../../entities/Agency";
import { MiscellanyOrderService } from "../../service/miscellany/MiscellanyOrderService";
import MiscellanyOrderResponse from "../../model/miscellany/MiscellanyOrderResponse";

class MiscellanyOrderStore {

    public miscellanyOrders: MiscellanyOrderResponse[];
    public selectedMiscellanyOrdersIds: string[];
    public selectedMiscellanyOrderId: string;
    public pagination: Pagination;

    public filter?: string;
    public statusFilter?: string[];
    public fromFilter: number | undefined;
    public toFilter: number | undefined;
    public agencyFilter?: Agency;

    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.miscellanyOrders = [];
        this.selectedMiscellanyOrdersIds = [];
        this.selectedMiscellanyOrderId = '';
        this.commonStore = commonStore;
        makeObservable(this, {
            miscellanyOrders: observable,
            selectedMiscellanyOrdersIds: observable,
            pagination: observable,
            agencyFilter: observable,
            isEmpty: computed
        });

    }

    get isEmpty(): boolean {
        return this.miscellanyOrders.length === 0;
    }

    get haveNotSelected(): boolean {
        return this.selectedMiscellanyOrdersIds.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    public setStatusToFilter(selectedStatus?: string[]) {
        this.statusFilter = selectedStatus;
    }

    public setFromFilter(from?: number) {
        this.fromFilter = from;
    }

    public setToFilter(to?: number) {
        this.toFilter = to;
    }

    @action
    public async search() {
        await this.getElements(1);
    }

    @action
    public async clearFilter() {
        this.statusFilter = undefined;
        this.fromFilter = undefined;
        this.toFilter = undefined;
        this.agencyFilter = undefined;
        this.filter = undefined;
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await MiscellanyOrderService.get(
            page, pageSize, this.fromFilter, this.toFilter, this.filter,
            this.statusFilter, this.agencyFilter?.id);
        if (response.success) {
            runInAction(() => {
                this.miscellanyOrders = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async updateArticleRemittanceContact(contact: ClientContact) {
        this.commonStore.setLoading();
        // const response = await MiscellanyOrderService.updateClientPerson(this.selectedArticleRemittanceId, contact.id);
        // if (response.success) {
        //     runInAction(() => {
        //         const tempArticleRemittance = this.articleRemittances.find(t => t.id === this.selectedArticleRemittanceId);
        //         const tempArticleRemittanceIndex = this.articleRemittances.findIndex(t => t.id === this.selectedArticleRemittanceId);
        //         if (tempArticleRemittance != null) {
        //             this.articleRemittances[tempArticleRemittanceIndex] = {
        //                 ...tempArticleRemittance,
        //                 contactId: contact.id,
        //                 contactName: contact.name
        //             };
        //         }
        //     })
        // }
        // this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default MiscellanyOrderStore;