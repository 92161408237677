import React from 'react';
import PromotionRowProperties from "./PromotionRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import PromotionRowMedium from "./PromotionRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import PromotionRowSmall from "./PromotionRowSmall";


const PromotionRow: React.FC<PromotionRowProperties> = ({
                                                      promotion,
                                                      onUpdate
                                                  }) => {
    return (
        <>
            <ViewSmall>
                <PromotionRowSmall onUpdate={onUpdate} promotion={promotion}/>
            </ViewSmall>
            <ViewMedium>
                <PromotionRowMedium onUpdate={onUpdate} promotion={promotion} />
            </ViewMedium>
            <ViewLarge>
                <PromotionRowMedium onUpdate={onUpdate} promotion={promotion} />
            </ViewLarge>
        </>
    )
}

export default PromotionRow;
