import React from 'react';
import {useStyles} from "./ShowTextHorizontal.style";
import ShowTextProperties from "./ShowTextHorizontalProperties";
import HighlightText from "../../../ui_components/HighlightText/HighlightText";


const ShowTextHorizontal: React.FC<ShowTextProperties> = ({
                                                    text,
                                                    title = '',
                                                    className = '',
                                                    buttons = [],
                                                    textToHighlight
                                                }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <div className={classes.container}>
                {title && 
                    <div className={classes.title}>
                        {`${title}:`}
                    </div>}
                <HighlightText textToHighlight={[textToHighlight || '']} text={text || '-'}/>
                <div>
                    {buttons && buttons.length > 0 && buttons.map(t => (
                        <span>{t}</span>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ShowTextHorizontal;