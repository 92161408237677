import React from "react";

class StepperOption {
    public index: number;
    public label: string;
    public isCompleted: () => boolean;
    public onSelect?: () => void;
    public content: React.ReactNode;

    constructor(index: number, label: string, isCompleted: () => boolean, content: React.ReactNode, onSelect?: () => void) {
        this.index = index;
        this.label = label;
        this.isCompleted = isCompleted;
        this.onSelect = onSelect;
        this.content = content;
    }
}

export default StepperOption;