enum PaymentType {
    CASH = "CASH",
    DEBIT = "DEBIT",
    CREDIT = "CREDIT",
    ZELLE = "ZELLE",
    ZELLE_LOWER = "ZELLE_LOWER",
    ZELLE_EXTRA = "ZELLE_EXTRA",
    CHECK = "CHECK",
    WIRE_TRANSFER = "WIRE_TRANSFER",
    DISTANCE = "DISTANCE",
    DISTANCE_LOWER = "DISTANCE_LOWER",
}

export default PaymentType;