import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_price_container: {
        display: 'flex',
        lineHeight: '16px',
        alignSelf: "center",
        fontSize: '25px',
    },
    number: {
        fontFamily: 'Alkatra, cursive',
        fontSize: '25px',
        marginLeft: '4px',
        "& > span:last-child":{
            fontSize: '25px',
        }
    },
    article_name: {
        fontFamily: 'Alkatra, cursive',
        color: theme.palette.grey[600],
        fontSize: '13px',
        marginLeft: '8px',
        width: 'fit-content'
    },
    positive: {
        color: `${theme.palette.success.light}c9`
    },
    negative: {
        color: theme.palette.error.light
    },
    value_before:{
        color: theme.palette.grey[400],
        fontSize: '11px',
        marginLeft: '8px'
    }
}));

export {useStyles}