import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    input:{
      marginTop: '16px'
    },
    check_list_container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
        fontSize: '16px',
        color: theme.palette.grey['400']
    },
    check_item_container: {
        display: 'flex',
        "& svg":{
            fontSize: '21px'
        }
    },
    valid:{
        color: theme.palette.success.light
    },
}));

export {useStyles}