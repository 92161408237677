import React, {createRef, SyntheticEvent, useEffect} from 'react';
import AutocompleteProperties from "./AutocompleteProperties";
import Autocomplete from '@mui/material/Autocomplete'
import TextField from "@mui/material/TextField";
import {AutocompleteChangeReason, AutocompleteInputChangeReason} from "@mui/material";


const AutocompleteItem: React.FC<AutocompleteProperties> = ({
                                                                onChangeValue,
                                                                onChangeInput,
                                                                itemName,
                                                elements,
                                                error,
                                                label,
                                                selected,
                                                placeholder = '',
                                                helpText,
                                                //disabled = false,
                                                value,
                                                loading = false,
                                                icon: DefaultIcon,
                                                fullWidth = false,
                                                className = '',
                                            }: AutocompleteProperties) => {
    const menuRef = createRef<HTMLDivElement>();
    /* const [open, setOpen] = useState(false); */
    /* const [selectedOption, setSelectedOption] = useState(selected); */


    useEffect(() => {
        //setSelectedOption(value);
    }, [value]);

    useEffect(() => {
        //setSelectedOption(selected);
    }, [selected]);

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                //setOpen(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [menuRef])

    // const onElementClick = (element: SelectOption) => {
    //     setSelectedOption(element);
    //     setOpen(false);
    //     onChange(element);
    // }

    
    const onInputChange = (event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        onChangeInput(value)
    }
    const onValueChange = (event: SyntheticEvent<Element, Event>, value: string | { id: string; label: string; } | null, reason: AutocompleteChangeReason) => {
        if(value!=null){
            onChangeValue((value as {id:string;label:string}).id)
        }
    }

    return (
        <Autocomplete
            fullWidth={fullWidth}
            className={className}
            inputValue={itemName}
            onInputChange={onInputChange}
            onChange={onValueChange}
            freeSolo
            options = {elements.map(option => ({ id: option.value, label: option.primaryText }))}
            renderInput={(params) => <TextField {...params} label={label} />}/>
    )
}
export default AutocompleteItem;