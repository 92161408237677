import React from 'react';
import ProductOrderStatusSelectProperties from "./ProductOrderStatusSelectProperties";
import SelectOption from "../../../ui_components/Select/SelectOption";
import Select from "../../../ui_components/Select/Select";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';

const ProductOrderStatusSelect: React.FC<ProductOrderStatusSelectProperties> = ({
                                                                        selected,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {


    const opt = [
        new SelectOption("Pendiente", "PENDING", "Entrega pendiente de pago", HourglassTopIcon),
        new SelectOption("Pagada", "PAYED", "Entrega pagada", PaidIcon),
        new SelectOption("Asignada", "ASSIGNED", "Entrega asignada a un repartidor", PersonIcon),
        new SelectOption("Procesando", "PROCESSING", "Entrega procesada por el repartidor", DirectionsBikeIcon),
        new SelectOption("Entregada", "DELIVERED", "Entregada al cliente", CheckIcon),
        new SelectOption("No entregada", "NOT_DELIVERED", "Entrega no entregada", DoNotDisturbIcon),
        new SelectOption("Cancelada", "CANCELED", "Entrega cancelada", CancelIcon)
    ]

    const selectedOption = opt.find(t => t.value === selected);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value)
    }

    return (
        <Select
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : "Estado"}
            placeholder={"Seleccione el estado"}
            selected={selectedOption}
            icon={AttachMoneyIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default ProductOrderStatusSelect;