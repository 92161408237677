import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import Agency from "../../../entities/Agency";
import {AgencyService} from "../../../service/AgencyService";

class AgencySelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public elements: Agency[];
    public pagination: Pagination;
    public selectedAgency?: Agency;
    public isSearchDialogOpen: boolean;
    public initialLoadAmount: number;

    constructor(commonStore: CommonStore, initialLoadAmount: number, selectedAgency?: Agency) {
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, initialLoadAmount, 0, 0);
        this.elements = [];
        this.selectedAgency = selectedAgency;
        this.isSearchDialogOpen = false;
        this.initialLoadAmount = initialLoadAmount;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await AgencyService.get(1, this.initialLoadAmount, this.filter, true, true);
        if (response.success) {
            runInAction(() => {
                this.elements = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        if (value) {
            this.search();
        }
        this.isSearchDialogOpen = value;
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public setSelectedClient(agency?: Agency) {
        this.selectedAgency = agency;
        this.isSearchDialogOpen = false;
    }

}

export default AgencySelectStore;