enum CountryCode {
    UNKNOWN = "UNKNOWN",
    CUBA = "CUBA",
    USA = "USA",
    BRAZIL = "BRAZIL",
    URUGUAY = "URUGUAY",
    MEXICO = "MEXICO",
    BELGIUM = "BELGIUM"
}

const codeMap = {
    "UNKNOWN": 0,
    "CUBA": 53,
    "USA": 1,
    "BRAZIL": 55,
    "URUGUAY": 598,
    "MEXICO": 52,
    "BELGIUM": 32,
}

const getPhoneCode = (countryCode: CountryCode): number => {
    return codeMap[countryCode] || 0;
}

export default CountryCode;
export {getPhoneCode}