import React from 'react';
import ShowIntervalProperties from "./ShowIntervalProperties";


const ShowInterval: React.FC<ShowIntervalProperties> = ({
                                                        from,
                                                        to
                                                      }) => {

    return (
        <div>
            <>
                <div>{from} - {to}</div>
            </>
        </div>
    )
}
export default ShowInterval;