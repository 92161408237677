import { useEffect } from "react";

export default function useOnEnterPress(callback: () => void, ...dependencies: any[]) {

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter" && callback) {
                event.stopPropagation();
                callback();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [callback, dependencies]);
}