import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import ShopProduct from "../../entities/productOrder/shop/ShopProduct";
import { ShopProductService } from "../../service/shop/ShopProductService";

class ShopProductStore {

    public shopProducts: ShopProduct[];
    public pagination: Pagination;
    public filter?: string;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.shopProducts = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            shopProducts: observable,
            pagination: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.shopProducts.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await ShopProductService.get(page, pageSize, this.filter);
        if (response.success) {
            runInAction(() => {
                this.shopProducts = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default ShopProductStore;