import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    page_loading_container: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 999
    },
    loading_container: {
        width: "100%",
        height: "100%",
        backdropFilter: 'blur(4px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& svg': {
            color: theme.palette.primary.dark
        },
        '& div': {
            color: theme.palette.primary.dark
        }
    }
}));

export {useStyles}
