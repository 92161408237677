import React from 'react';
import clsx from "clsx";
import ShowMoneyDeliveryCostsButtonRowProperties from './ShowMoneyDeliveryCostsButtonRowProperties';
import { useStyles } from '../ShowMoneyDeliveryCostsButton.style';
import ShowText from '../../../Info/ShowText/ShowText';
import ShowInterval from '../../../Info/ShowInterval/ShowInterval';
import { useI18nContext } from '../../../../contexts/I18nContext';

const ShowMoneyDeliveryCostsButtonRowMedium: React.FC<ShowMoneyDeliveryCostsButtonRowProperties> = ({
                                                            moneyPrices,
                                                            moneyDeliveryFee
                                                        }) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    const getValue = () => {
        const current = moneyPrices.find(t => t.serviceFeeId === moneyDeliveryFee.id);
        if (!current) {
            return <ShowText text={'-'} title={'Valor de costo'}/>
        }
        return current.salePriceByCurrency.map((price) => (
            <ShowText text={price.isPercentage ?
                (price.salePrice ? price.salePrice.toString() + '%' : '-') :
                (price.salePrice ? '$' + price.salePrice.toString() : '-')
            } title={i18n.translate(price.currency)}/>
        ));
    }

    return (
        <div
            className={clsx(classes.show_money_price_info_container)}>
            <ShowInterval from={moneyDeliveryFee.fromValue} to={moneyDeliveryFee.toValue}/>
            <div className={classes.values_container}>{getValue()}</div>
        </div>
    )
}

export default ShowMoneyDeliveryCostsButtonRowMedium;
