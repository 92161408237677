import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    table_container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: '18px',
        fontWeight: 800,
        position:"sticky",
        top:0,
        backgroundColor: theme.palette.common.white,
    },
    table: {
        "& > thead": {
            position:"sticky",
            top:20,
            "& > tr": {
                "& > td": {
                    padding: '4px',
                }
            }
        },
        "& > tbody": {
            "& > tr": {
                "& > td": {
                    padding: '4px',
                    color: theme.palette.secondary.light,
                    border: `1px solid ${theme.palette.secondary.light}`
                }
            }
        }
    },
    default: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.grey[200],
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
    }
}));

export {useStyles}