import React from 'react';
import ShowPhoneNumberProperties from "./ShowPhoneNumberProperties";
import {useStyles} from "./ShowPhoneNumber.style";
import {format} from "../../../util/PhoneFormatter";
import CountryFlagIcon from "../../../ui_components/Icon/icons/CountryFlagIcon";
import HighlightText from "../../../ui_components/HighlightText/HighlightText";
import PhoneIcon from "../../../ui_components/Icon/icons/PhoneIcon";

const ShowPhoneNumber: React.FC<ShowPhoneNumberProperties> = ({
                                                                  value,
                                                                  label = '',
                                                                  showFlag = true,
                                                                  showIcon = false,
                                                                  className = '',
                                                                  textToHighlight
                                                              }) => {

    // const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={classes.phone_container}>
            {label && <div className={classes.label}>{label}</div>}
            <div className={classes.show_phone_number_container}>
                {showIcon && <PhoneIcon className={classes.icon}/>}
                <HighlightText className={classes.number} textToHighlight={[textToHighlight || '']} text={format(value, true)|| ''}/>
                {showFlag && <CountryFlagIcon fontSize={15} countryCode={value?.countryCode}/>}
            </div>
        </div>
    )
}


export default ShowPhoneNumber;