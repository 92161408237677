import {useStyles} from './SearchMiscellanyCategoryPanel.style';
import React, {createRef, useEffect, useRef, useState} from "react";
import {Observer} from "mobx-react-lite";
import Loading from "../../../../ui_components/PageLoading/Loading";
import MiscellanyCategoryElementRow from "./row/MiscellanyCategoryElementRow";
import SearchMiscellanyCategoryPanelProperties from "./SearchMiscellanyCategoryPanelProperties";
import MiscellanyCategory from '../../../../entities/productOrder/miscellany/MiscellanyCategory';


const SearchMiscellanyCategoryPanel: React.FC<SearchMiscellanyCategoryPanelProperties> = ({
                                                                      store,
                                                                      onMiscellanyCategorySelect
                                                                  }) => {
    const classes = useStyles();

    const inputRef = createRef<HTMLInputElement>();
    const [filter, setFilter] = useState("");

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFilter(value);
    }

    const selectMiscellanyCategory = (deliveryPerson: MiscellanyCategory) => {
        store.setSelectedMiscellanyCategory(deliveryPerson);
        onMiscellanyCategorySelect(deliveryPerson);
    }

    let timeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        const changeFilter = () => {
            store.setMiscellanyCategoryFilter(filter);
        };
        timeout.current = setTimeout(changeFilter, 800);
    }, [filter, store])

    return <Observer>
                {() => (
                    <div className={classes.search_container}>
                        <div className={classes.search_input_container}>
                            <div className={classes.search_input}>
                                <input
                                    value={filter}
                                    placeholder="Buscar"
                                    ref={inputRef}
                                    onChange={onFilterChange}/>
                            </div>
                        </div>
                        {store.loadingMiscellanyCategories &&
                        <div className={classes.options_body}>
                            <Loading className={classes.loading} fontSize={45}/>
                        </div>}

                        {!store.loadingMiscellanyCategories && <div className={classes.elements_container}>
                            {store.miscellanyCategories.map((e, i) => (
                                <MiscellanyCategoryElementRow
                                    key={i}
                                    element={e}
                                    selected={e.id === store.selectedMiscellanyCategory?.id}
                                    onSelect={selectMiscellanyCategory}
                                />
                            ))}
                        </div>}

                        {!store.loadingMiscellanyCategories && store.isMiscellanyCategoriesEmpty &&
                        <div className={classes.empty_container}>
                            <div>No hay categorias!!!</div>
                            <div>Verifica si estás aplicando algún filtro</div>
                        </div>}
                    </div>
                )}
            </Observer>
}

export default SearchMiscellanyCategoryPanel;