import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import Role from "../entities/Role";
import GetAccessResponse from "../model/access/GetAccessResponse";
import GetAccessNewValueResponse from "../model/access/GetAccessNewValueResponse";

export module RoleService {
    export async function get(): Promise<Response<Role[]>> {
        const url = Config.get("GET_ROLE_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const roleData = response.data;
            if (roleData) {
                const data = roleData.map((t: any) => new Role(t.id, t.name, t.value, t.description));
                return new Response<Role[]>(true, data, response.error)
            }
        }
        return new Response<Role[]>(false, undefined, response.error, response.status);
    }

    export async function getAllAccess(): Promise<GetAccessResponse[]> {
        const url = Config.get("GET_ALL_ACCESS_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        return response.data
    }

    export async function getNewAccessValue(): Promise<GetAccessNewValueResponse> {
        const url = Config.get("GET_NEW_ACCESS_VALUE_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        return response.data
    }

    export async function getRoleAccess(roleId: String): Promise<GetAccessResponse[]> {
        const url = Config.get("GET_ROLE_ACCESS_URL", {id: roleId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        return response.data
    }

    export async function toggleAccess(roleId: String, accessName: string) {
        const url = Config.get("TOGGLE_ROLE_ACCESS_URL", {id: roleId, access: accessName});
        const request = new Request(url)
        await RestClient.get(request)
    }
}