import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    title: {
        display: 'flex',
        fontSize: '18px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "4px 8px"
    },
    agency_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    item_row_container: {
        display: "grid",
        rowGap: 8,
        padding: 8
    },
    item_row: {
        display: "grid",
        gridTemplateColumns: "200px 160px auto 60px",
        padding: 8,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        backgroundColor: theme.palette.grey[100],
        columnGap: 8,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.grey[200],
        }
    },
    current_row: {
        backgroundColor: `${theme.palette.info.light}5e`,
        position: "relative",
        border: `1px solid ${theme.palette.info.light}`,
        "&:hover": {
            backgroundColor: theme.palette.grey[300],
        }
    },
    current_agency: {
        position: "absolute",
        fontSize: 10,
        right: 5,
        top: -6
    },
    item_sales_container: {
        display: "grid",
        gridTemplateColumns: 'repeat( auto-fit, minmax(130px, 1fr))',
        columnGap: 8
    },
    no_content_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        height: '100%',
        color: theme.palette.grey[400],
        textAlign: 'center',
        cursor: 'pointer',
        "& svg": {
            fontSize: '50px'
        }
    },
    loading_container: {
        minHeight: '80px',
    },
}));

export {useStyles}