import React from 'react';
import NewClientContactButtonProperties from "./NewClientContactButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewClientContactStore from "./NewClientContactStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewClientContactButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PhoneInput from "../../Inputs/PhoneInput/PhoneInput";
import GeoSelect from "../../Selects/GeoSelect/GeoSelect";
import Geo from "../../../entities/Geo";
import AddressInput from "../../Inputs/AddressInput/AddressInput";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import CountryCode from "../../../entities/CountryCode";
import Phone from "../../../entities/Phone";
import {GeoLevel} from "../../Selects/GeoSelect/GeoSelectProperties";
import IdentificationInput from '../../Inputs/IdentificationInput/IdentificationInput';
import LastNameInput from '../../Inputs/LastNameInput/LastNameInput';
import LocationInput from '../../Inputs/LocationInput/LocationInput';
import EmailInput from '../../Inputs/EmailInput/EmailInput';
import clsx from 'clsx';
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import DifficultPersonCheckbox from '../../Inputs/DifficultPersonCheckbox/DifficultPersonCheckbox';


const NewClientContactButton: React.FC<NewClientContactButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSave = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                clientId,
                                                                                contactId,
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT,
                                                                                customButton = null,
                                                                                agencyId
                                                                            }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewClientContactStore(commonContext, clientId, contactId, agencyId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.clientContact.name = newName;
    const onLastNameChange = (newLastName: string) => store.clientContact.lastName = newLastName;
    const onDocumentIdChange = (newDocumentId: string) => store.clientContact.documentId = newDocumentId;
    const onAddressChange = (newAddress: string) => store.clientContact.address = newAddress;
    const onNeighborhoodChange = (newNeighborhood: string) => store.clientContact.neighborhood = newNeighborhood;
    const onPhoneChange = (newPhone: Phone) => store.clientContact.homePhone = newPhone;
    const onMobilePhoneChange = (newPhone: Phone) => store.clientContact.mobilePhone = newPhone;
    const onSecondMobilePhoneChange = (newSecondPhone: Phone) => store.clientContact.secondMobilePhone = newSecondPhone;
    const onGeoChange = (geo?: Geo) => store.clientContact.geo = geo;
    const onEmailChange = (newEmail: string) => store.clientContact.email = newEmail;
    const onObservationChange = (newObservation: string) => store.clientContact.observation = newObservation;
    const onDifficultPersonChange = (newDifficultPerson: boolean) => store.clientContact.difficultPerson = newDifficultPerson;

    const onProvinceChange = (geo?: Geo) => {
        store.selectedProvince = geo;
        if (geo && store.clientContact.geo && store.clientContact.geo.parentId !== geo.id) {
            store.clientContact.geo = undefined;
        }
    }

    const onClientSave = async () => {
        const saved = await store.save();
        if (saved) {
            if (onSave) {
                onSave(store.clientContact);
            }
            store.closeModal();
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_CONTACT" : "NEW_CONTACT"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (customButton != null) {
            return <div className={classes.custom_button_container} onClick={handleOnclick}>{customButton}</div>;
        }
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <div className={classes.display_flex}>
                            <SellerNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                                required={true}
                                value={store.clientContact?.name}
                                onChange={onNameChange}/>
                            <LastNameInput error={i18n.translate(store.lastNameError)} fullWidth className={classes.input}
                                required={true}
                                value={store.clientContact?.lastName}
                                onChange={onLastNameChange}/>
                            <IdentificationInput label={'Carnet de Identidad'} fullWidth className={classes.input}
                                value={store.clientContact?.documentId}
                                onChange={onDocumentIdChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <AddressInput
                                lines={2}
                                onChange={onAddressChange}
                                required={true}
                                error={i18n.translate(store.addressError)}
                                value={store.clientContact?.address}
                                className={classes.input}
                                fullWidth/>
                        </div>
                        <div className={classes.display_flex}>
                            <GeoSelect
                                label={'Provincia'}
                                onChange={onProvinceChange}
                                className={classes.input}
                                levels={[GeoLevel.STATE]}
                                selected={store.selectedProvince}
                                fullWidth/>
                            <GeoSelect
                                label={'Municipio'}
                                onChange={onGeoChange}
                                required={true}
                                className={classes.input}
                                levels={[GeoLevel.CITY]}
                                selected={store.clientContact?.geo}
                                parentIdFilter={store.selectedProvince?.id}
                                error={i18n.translate(store.geoError)}
                                fullWidth/>
                            <LocationInput label={'Reparto o Pueblo'} fullWidth className={classes.input}
                                required={false}
                                value={store.clientContact?.neighborhood}
                                onChange={onNeighborhoodChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <PhoneInput error={i18n.translate(store.phoneError)} fullWidth className={classes.input}
                                value={store.clientContact?.homePhone}
                                label={i18n.translate("LINE_PHONE")}
                                countryCode={CountryCode.CUBA}
                                onChange={onPhoneChange}/>
                            <PhoneInput error={i18n.translate(store.mobilePhoneError)} fullWidth className={classes.input}
                                value={store.clientContact?.mobilePhone}
                                label={i18n.translate("MOBILE_PHONE")}
                                countryCode={CountryCode.CUBA}
                                onChange={onMobilePhoneChange}/>
                            <PhoneInput error={i18n.translate(store.secondMobilePhoneError)} fullWidth className={classes.input}
                                value={store.clientContact?.secondMobilePhone}
                                label={i18n.translate("SECOND_MOBILE_PHONE")}
                                countryCode={CountryCode.CUBA}
                                onChange={onSecondMobilePhoneChange}/>
                            <EmailInput error={i18n.translate(store.emailError)} fullWidth className={classes.input}
                                value={store.clientContact?.email}
                                onChange={onEmailChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <CommentInput className={clsx(classes.input,classes.flex_1)}
                                value={store.clientContact?.observation}
                                onChange={onObservationChange}/>
                            <DifficultPersonCheckbox selected={store.clientContact?.difficultPerson} onChange={onDifficultPersonChange}/>
                        </div>
                        <div className={classes.btn_container}>
                            <Button
                                loading={store.loading} className={classes.input} onClick={onClientSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}/>
                            <Button
                                loading={store.loading} className={classes.cancel_btn} onClick={onCloseModal}
                                type={ButtonType.DEFAULT}
                                text={i18n.translate("CANCEL")}/>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewClientContactButton;
