import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./MoneyExchanges.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import MoneyExchangeStore from './MoneyExchangeStore';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import MoneyExchangeRow from './MoneyExchangesRow/MoneyExchangeRow';
import NewMoneyExchangeRateButton
    from '../../shared_components/Buttons/NewMoneyExchangeRateButton/NewMoneyExchangeRateButton';
import Tag from "../../ui_components/Tag/Tag";
import AllowAccess from "../../shared_components/Access/AllowAccess/AllowAccess";


const MoneyExchanges: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const foodPackageStore = new MoneyExchangeStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        foodPackageStore.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await foodPackageStore.getElements();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await foodPackageStore.goToPage(page, pageSize);
    }


    return (
        <Observer>
            {() => (
                <div className={classes.money_exchange_container}>
                    <AllowAccess isRootAgency>
                        <Box className={classes.tools_container}
                             width={Width.FULL_WIDTH}
                             justifyContent={Alignment.END}
                        >

                            <NewMoneyExchangeRateButton type={ButtonType.SECONDARY} onSave={onUpdate} rounded/>
                        </Box>
                    </AllowAccess>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={foodPackageStore.filter}
                                     placeholder={i18n.translate("MONEY_EXCHANGES_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         foodPackageStore.setFilter(value)
                                     }} onEnter={() => foodPackageStore.search()}/>
                        <Button
                            onClick={() => {
                                foodPackageStore.search()
                            }}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    {!foodPackageStore.isEmpty &&
                        <div className={classes.money_exchange_table}>
                            <ShowPagination onChange={onPaginationChange}
                                            elementLabel={i18n.translate("MONEY_EXCHANGES")}
                                            pagination={foodPackageStore.pagination}/>
                            <div className={classes.hint}><Tag startIcon={GppMaybeIcon}
                                                               text={`${i18n.translate("MONEY_EXCHANGE_HINT")}:`}/>
                            </div>
                            <div className={classes.elements_container}>
                                {foodPackageStore.moneyExchanges.map((moneyExchange, i) => (
                                    <MoneyExchangeRow
                                        onUpdate={onUpdate}
                                        moneyExchange={moneyExchange} key={i}
                                    />
                                ))}
                            </div>
                            <ShowPagination
                                onChange={onPaginationChange}
                                elementLabel={i18n.translate("MONEY_EXCHANGES")}
                                pagination={foodPackageStore.pagination}/>
                        </div>}
                    {foodPackageStore.isEmpty &&
                        <MessageCard icon={CurrencyExchangeIcon} title={i18n.translate("NO_MONEY_EXCHANGES_TITLE")}
                                     subtitle={i18n.translate("NO_MONEY_EXCHANGES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default MoneyExchanges;
