import React from 'react';
import MoneyServiceFeeRowProperties from "./MoneyServiceFeeRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import MoneyServiceFeeRowMedium from "./MoneyServiceFeeRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MoneyServiceFeeRowSmall from "./MoneyServiceFeeRowSmall";


const MoneyServiceFeeRow: React.FC<MoneyServiceFeeRowProperties> = ({
                                                    moneyServiceFee,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <MoneyServiceFeeRowSmall onUpdate={onUpdate} moneyServiceFee={moneyServiceFee}/>
            </ViewSmall>
            <ViewMedium>
                <MoneyServiceFeeRowMedium onUpdate={onUpdate} moneyServiceFee={moneyServiceFee} />
            </ViewMedium>
            <ViewLarge>
                <MoneyServiceFeeRowMedium onUpdate={onUpdate} moneyServiceFee={moneyServiceFee} />
            </ViewLarge>
        </>
    )
}

export default MoneyServiceFeeRow;
