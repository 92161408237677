import Currency from "../../entities/Currency";
import DeliveryOrderComment from "../../entities/productOrder/DeliveryOrderComment";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import PackageDeliveryOrderContactResponse from "../packageDelivery/PackageDeliveryOrderContactResponse";
import ItemsAndCategories from "../packageDelivery/ItemsAndCategories";
import DeliveryOrderType from "../DeliveryOrderType";
import ArticleDeliveryOrderArticleResponse from "../articleDelivery/ArticleDeliveryOrderArticleResponse";
import ShopOrderProductResponse from "../shop/ShopOrderProductResponse";

class OrderToPrint {
    id: string;
    agencyId: string;
    agencyName: string;
    agencyAddress: string;
    agencyEmail: string;
    agencyPhone: string;
    clientId: string;
    contactId: string;
    userId: string;
    createdAt: number;
    totalToPay: number;
    status: ProductOrderStatus;
    orderNumber: string;
    geoId: string;
    deliveryPersonId: string | null;
    deliveredAt: number | null;
    comments: DeliveryOrderComment[];
    itemsAndCategories: ItemsAndCategories[];
    serviceFee: number;
    serviceCost: number;
    totalToReceive: number;
    conversionRate: number;
    currencyToReceive: Currency;
    isBankCard: boolean;
    contactBankCardId: string | null;
    articles: ArticleDeliveryOrderArticleResponse[];
    products: ShopOrderProductResponse[];
    orderType: DeliveryOrderType;
    difficultDelivery: boolean;
    bankCardType: string;
    bankCardNumber: string;
    clientName: string;
    contact: PackageDeliveryOrderContactResponse;
    amountToReceive: number;
    shippingId: string

    constructor(
        id: string = "",
        agencyId: string = "",
        agencyName: string = "",
        agencyAddress: string = "",
        agencyEmail: string = "",
        agencyPhone: string = "",
        clientId: string = "",
        contactId: string = "",
        userId: string = "",
        createdAt: number = 0,
        totalToPay: number = 0,
        status: ProductOrderStatus = ProductOrderStatus.PENDING,
        orderNumber: string = "",
        geoId: string = "",
        deliveryPersonId: string | null = null,
        deliveredAt: number | null = 0,
        comments: DeliveryOrderComment[] = [],
        itemsAndCategories: ItemsAndCategories[] = [],
        serviceFee: number = 0,
        serviceCost: number = 0,
        totalToReceive: number = 0,
        conversionRate: number = 0,
        currencyToReceive: Currency = Currency.DOLLAR,
        isBankCard: boolean = false,
        contactBankCardId: string | null = null,
        articles: ArticleDeliveryOrderArticleResponse[] = [],
        products: ShopOrderProductResponse[],
        orderType: DeliveryOrderType,
        difficultDelivery: boolean = false,
        bankCardType : string,
        bankCardNumber : string,
        clientName: string = "",
        contact: PackageDeliveryOrderContactResponse,
        amountToReceive: number = 0,
        shippingId: string = ""
    ) {
        this.id = id;
        this.agencyId = agencyId;
        this.agencyName = agencyName;
        this.agencyAddress = agencyAddress;
        this.agencyEmail = agencyEmail;
        this.agencyPhone = agencyPhone;
        this.clientId = clientId;
        this.contactId = contactId;
        this.userId = userId;
        this.createdAt = createdAt;
        this.totalToPay = totalToPay;
        this.status = status;
        this.orderNumber = orderNumber;
        this.geoId = geoId;
        this.deliveryPersonId = deliveryPersonId;
        this.deliveredAt = deliveredAt;
        this.comments = comments;
        this.itemsAndCategories = itemsAndCategories;
        this.serviceFee = serviceFee;
        this.serviceCost = serviceCost;
        this.totalToReceive = totalToReceive;
        this.conversionRate = conversionRate;
        this.currencyToReceive = currencyToReceive;
        this.isBankCard = isBankCard;
        this.contactBankCardId = contactBankCardId;
        this.articles = articles;
        this.products = products;
        this.orderType = orderType;
        this.difficultDelivery = difficultDelivery;
        this.bankCardType = bankCardType;
        this.bankCardNumber = bankCardNumber;
        this.clientName = clientName;
        this.contact = contact;
        this.amountToReceive = amountToReceive;
        this.shippingId = shippingId
    }
}

export default OrderToPrint;
