import React, {useEffect} from 'react';
import {useStyles} from "./PasswordAndConfirmPasswordInput.style";
import PasswordAndConfirmPasswordInputProperties from "./PasswordAndConfirmPasswordInputProperties";
import PasswordInput from "../PasswordInput/PasswordInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";


const PasswordAndConfirmPasswordInput: React.FC<PasswordAndConfirmPasswordInputProperties> = ({
                                                                                                  value,
                                                                                                  onChange,
                                                                                                  disabled,
                                                                                                  error,
                                                                                                  fullWidth = false,
                                                                                                  className = ''
                                                                                              }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const [password, setPassword] = React.useState<string>();
    const [confirmPassword, setConfirmPassword] = React.useState<string>();

    useEffect(() => {
        onChange(password ?? "", isPasswordValid());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, confirmPassword]);
    const validate = (key: string): boolean => {
        if (!password) return false;
        switch (key) {
            case "UPPERCASE":
                return /[A-Z]+/.test(password);
            case "LOWERCASE":
                return /[a-z]+/.test(password);
            case "NUMBER":
                return /[0-9]+/.test(password);
            case "LENGTH":
                return password.length >= 8;
            case "EQUALS":
                return password === confirmPassword;
        }
        return true;
    }
    const getClass = (key: string) => {
        return clsx(
            classes.check_item_container,
            validate(key) && classes.valid)
    }

    const onPasswordChange = (password: string) => {
        setPassword(password);
    }

    const onConfirmPasswordChange = (password: string) => {
        setConfirmPassword(password);
    }

    const isPasswordValid = () => {
        return validate("UPPERCASE")
            && validate("LOWERCASE")
            && validate("NUMBER")
            && validate("LENGTH")
            && validate("EQUALS");
    }


    return (
        <div className={classes.container}>
            <PasswordInput className={classes.input} fullWidth onChange={onPasswordChange}/>
            <PasswordInput className={classes.input} fullWidth label={i18n.translate("CONFIRM_PASSWORD")}
                           onChange={onConfirmPasswordChange}/>
            <div className={classes.check_list_container}>
                <div className={getClass("LENGTH")}>
                    <CheckCircleIcon/>
                    <div>{i18n.translate("PASSWORD_LENGTH_DESCRIPTION")}</div>
                </div>
                <div className={getClass("UPPERCASE")}>
                    <CheckCircleIcon/>
                    <div>{i18n.translate("PASSWORD_UPPERCASE_DESCRIPTION")}</div>
                </div>
                <div className={getClass("LOWERCASE")}>
                    <CheckCircleIcon/>
                    <div>{i18n.translate("PASSWORD_LOWERCASE_DESCRIPTION")}</div>
                </div>
                <div className={getClass("NUMBER")}>
                    <CheckCircleIcon/>
                    <div>{i18n.translate("PASSWORD_NUMBER_DESCRIPTION")}</div>
                </div>
                <div className={getClass("EQUALS")}>
                    <CheckCircleIcon/>
                    <div>{i18n.translate("PASSWORD_EQUALS_DESCRIPTION")}</div>
                </div>
            </div>
        </div>
    )
}

export default PasswordAndConfirmPasswordInput;
