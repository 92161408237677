import React from "react";
import ShowPaginationProperties from "./ShowPaginationProperties";
import ViewLarge from "../MediaView/ViewLarge";
import ShowPaginationLarge from "./ShowPaginationLarge/ShowPaginationLarge";
import ViewMedium from "../MediaView/ViewMedium";
import ShowPaginationSmall from "./ShowPaginationSmall/ShowPaginationSmall";
import ViewSmall from "../MediaView/ViewSmall";
import ShowPaginationInModal from "./ShowPaginationInModal/ShowPaginationInModal";
import Box from "@material-ui/core/Box";

const ShowPagination: React.FC<ShowPaginationProperties> = ({
  className = "",
  pagination,
  elementLabel,
  isModalPagination = false,
  onChange,
}) => {
  return (
    <>
      {isModalPagination && (
        <ShowPaginationInModal
          className={className}
          pagination={pagination}
          elementLabel={elementLabel}
          onChange={onChange}
        />
      )}

      {!isModalPagination && (
        <Box>
          <ViewSmall>
            <ShowPaginationSmall
              className={className}
              pagination={pagination}
              elementLabel={elementLabel}
              onChange={onChange}
            />
          </ViewSmall>
          <ViewMedium>
            <ShowPaginationSmall
              className={className}
              pagination={pagination}
              elementLabel={elementLabel}
              onChange={onChange}
            />
          </ViewMedium>
          <ViewLarge>
            <ShowPaginationLarge
              className={className}
              pagination={pagination}
              elementLabel={elementLabel}
              onChange={onChange}
            />
          </ViewLarge>
        </Box>
      )}
    </>
  );
};
export default ShowPagination;
