import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ClientContact from "../../../entities/ClientContact";
import { ClientContactService } from "../../../service/ClientContactService";


class RemoveContactStore {

    public clientId: string;
    public contact: ClientContact;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, contact: ClientContact, clientId: string) {
        this.clientId = clientId;
        this.contact = contact;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ClientContactService.remove(this.contact, this.clientId);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveContactStore;