import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import TopupPrice from "../../entities/productOrder/topup/TopupPrice";
import TopupPriceRequest from "../../model/topup/TopupPriceRequest";

export module TopupPriceService {
    export async function get(): Promise<Response<TopupPrice[]>> {
        const url = Config.get("GET_TOPUP_PRICES_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const productsData = response.data;
            if (productsData) {
                const data = productsData.map((t: any) => new TopupPrice(t.id, t.providerName, t.agencyId, t.topupProductId, t.amount));
                return new Response<TopupPrice[]>(true, data, response.error)
            }
        }
        return new Response<TopupPrice[]>(false, undefined, response.error, response.status);
    }

    export async function create(data: TopupPriceRequest): Promise<Response<any>> {
        const url = Config.get("CREATE_TOPUP_PRICE_URL");
        const request = new Request(url, data);
        return await RestClient.post(request);
    }

    export async function update(data: TopupPriceRequest): Promise<Response<any>> {
        const url = Config.get("UPDATE_TOPUP_PRICE_URL", {id: data.id});
        const request = new Request(url, data);
        return await RestClient.put(request);
    }

    export async function remove(data: TopupPrice): Promise<Response<any>> {
        const url = Config.get("REMOVE_TOPUP_PRICE_URL", {id: data.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }
}