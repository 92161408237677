import React, {useEffect, useState} from 'react';
import ShowRolesProperties from "./ShowRolesProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowRoles.style";
import clsx from "clsx";
import {useCommonContext} from "../../../contexts/CommonContext";
import Role from "../../../entities/Role";
import Tag from "../../../ui_components/Tag/Tag";
import LockSharpIcon from '@material-ui/icons/LockSharp';

const ShowRoles: React.FC<ShowRolesProperties> = ({
                                                      rolesId,
                                                      className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();

    const [roles, setRoles] = useState<Role[]>([]);

    useEffect(() => {
        const selectedRoles = commonStore.roles.filter(r => rolesId?.some(i => r.id === i));
        setRoles(selectedRoles);
    }, [rolesId, commonStore.roles]);

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_roles_container, className)}>
            <div className={classes.label}>{`${i18n.translate("ROLES")}:`}</div>
            {roles?.map((r, i) => (
                <Tag startIcon={LockSharpIcon} key={i} text={r.name}/>
            ))}
        </div>
    )
}


export default ShowRoles;