import React from 'react';
import GeneralTabProperties from "./GeneralTabProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./GeneralTab.style";
import clsx from "clsx";
import AgencyTotalSales from "../../../../shared_components/Widget/AgencyTotalSales/AgencyTotalSales";
import AgencyTotalSalesGraph from "../../../../shared_components/Widget/AgencyTotalSalesGraph/AgencyTotalSalesGraph";
import AgencySalesByChildAgencies
    from "../../../../shared_components/Widget/AgencySalesByChildAgencies/AgencySalesByChildAgencies";


const GeneralTab: React.FC<GeneralTabProperties> = ({
                                                        from ,
                                                        to
                                                    }) => {

    // const i18n = useI18nContext();
    // const commonContext = useCommonContext();
    const classes = useStyles();

    return <Observer>
        {() => (<div className={classes.tab_container}>
            <div className={classes.row}>
                <div className={clsx(classes.box, classes.with_height) }>
                    <AgencyTotalSalesGraph from={from}  to={to} includeChildren title={"Total de ventas"}/>
                </div>
                <div className={clsx(classes.box, classes.with_height, classes.with_scroll) }>
                    <AgencyTotalSales from={from} to={to} includeChildren title={"Total de ventas"}/>
                </div>
                <div className={clsx(classes.box, classes.span2, classes.with_scroll)}>
                    <AgencySalesByChildAgencies from={from} to={to} title={"Total de ventas por agencias"}/>
                </div>
            </div>
        </div>)}
    </Observer>
}

export default GeneralTab;
