import React, {useEffect, useState} from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceInputShortProperties from "./PriceInputShortProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import TextInputShort from "../../../ui_components/TextInputShort/TextInputShort";
import TextInputShortType from "../../../ui_components/TextInputShort/TextInputShortType";

const PriceInputShort: React.FC<PriceInputShortProperties> = ({
                                                                  value,
                                                                  label,
                                                                  placeholder,
                                                                  onChange,
                                                                  disabled,
                                                                  fullWidth = false,
                                                                  className = '',
                                                                  error
                                                              }) => {

    const [amount, setAmount] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setAmount(value);
    }, [value]);

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newAmount = +event.target.value;
        setAmount(newAmount);
        onChange(newAmount);
    }

    return (
        <TextInputShort
            type={TextInputShortType.NUMBER}
            error={error}
            label={label?label:i18n.translate("PRICE")}
            value={amount}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onAmountChange}
            startIcon={AttachMoneyIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default PriceInputShort;