import React from 'react';
import {useStyles} from "./Header.style";
import TopupAgenciesText from "../../../ui_components/Icon/icons/TopupAgenciesText";
import LogoutButton from "../../../shared_components/Buttons/LogoutButton/LogoutButton";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import NotificationsButton from '../../../shared_components/Buttons/NotificationsButton/NotificationsButton';
import ConfigurationButton from "../../../shared_components/Buttons/ConfigurationButton/ConfigurationButton";

const Header: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.header_container}>
            <div>
                <TopupAgenciesText fontSize={65}/>
            </div>
            <div className={classes.rigth_btn_container}>
                <ConfigurationButton/>
                <NotificationsButton/>
                <LogoutButton size={ButtonSize.SMALL} showText={false}/>
            </div>
        </div>
    );
}

export default Header;