import Geo from "../../entities/Geo";
import Phone from "../../entities/Phone";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import ArticleDeliveryOrderArticleResponse from "./ArticleDeliveryOrderArticleResponse";

class ArticleDeliveryOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public agencyName: string;
    public clientName: string;
    public clientPhone: Phone | null;
    public contactId: string;
    public contactName: string;
    public contactPhone: Phone | null;
    public userId: string;
    public userName: string;
    public deliveryPersonId: string | null;
    public deliveryPersonName: string | null;
    public totalToPay: number;
    public createdAt: number;
    public status: ProductOrderStatus;
    public address: string;
    public geo: Geo;
    public articles: ArticleDeliveryOrderArticleResponse[];
    public observations: string;

    constructor(id: string, orderNumber: string, agencyId: string, agencyName: string, clientId: string,
            clientName: string, clientPhone: Phone | null, contactId: string, contactName: string, contactPhone: Phone | null,
            userId: string, userName: string, deliveryPersonId: string, deliveryPersonName: string,
            totalToPay: number, status: ProductOrderStatus, createdAt: number,
            address: string,
            geo: Geo,
            articles: ArticleDeliveryOrderArticleResponse[],
            observations: string) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.agencyName = agencyName;
        this.clientId = clientId;
        this.clientName = clientName;
        this.clientPhone = clientPhone;
        this.contactId = contactId;
        this.contactName = contactName;
        this.contactPhone = contactPhone;
        this.userId = userId;
        this.userName = userName;
        this.deliveryPersonId = deliveryPersonId;
        this.contactName = contactName;
        this.deliveryPersonId = deliveryPersonId;
        this.deliveryPersonName = deliveryPersonName;
        this.totalToPay = totalToPay;
        this.status = status;
        this.createdAt = createdAt;
        this.address = address;
        this.geo = geo;
        this.articles = articles;
        this.observations = observations;
    }

}

export default ArticleDeliveryOrderResponse;