import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    container_classes: {
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '4px',
    }
}));

export {useStyles}