import React, {useEffect, useState} from "react";
import WeightInputProperties from "./WeightInputProperties";
import BalanceIcon from '@mui/icons-material/Balance';
import TextInputShort from "../../../ui_components/TextInputShort/TextInputShort";
import TextInputShortType from "../../../ui_components/TextInputShort/TextInputShortType";


const WeightInput:React.FC<WeightInputProperties> = ({
                                                                                 value,
                                                                                 required = false,
                                                                                 placeholder,
                                                                                 onChange,
                                                                                 disabled,
                                                                                 fullWidth = false,
                                                                                 className = '',
                                                                                 error
                                                                             }) => {

    const [name,setName] = useState(value)

    useEffect(() => {
        setName(value)
    }, [value]);

    const onNameChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newName = event.target.value
        setName(+newName)
        onChange(+newName)
    }

    return (
        <div>
            <TextInputShort
                type={TextInputShortType.NUMBER}
                error={error}
                label={"Peso grupal"}
                value={name}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onNameChange}
                startIcon={BalanceIcon}
                className={className}
                fullWidth={fullWidth}/>
        </div>
    )
}
export default WeightInput