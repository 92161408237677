import React from 'react';
import ToggleSellerStatusButtonProperties from "./ToggleSellerStatusButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ToggleSellerStatusStore from "./ToggleSellerStatusStore";
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import RadioButtonUncheckedSharpIcon from '@material-ui/icons/RadioButtonUncheckedSharp';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useCommonContext} from "../../../contexts/CommonContext";
import SellerStatus from "../../../entities/SellerStatus";
import Button from "../../../ui_components/Button/Button";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";

const VALID_STATUS = [SellerStatus.ACTIVE, SellerStatus.INACTIVE];

const ToggleSellerStatusButton: React.FC<ToggleSellerStatusButtonProperties> = ({
                                                                                    onClick = () => {
                                                                                    },
                                                                                    onSave = () => {
                                                                                    },
                                                                                    showIcon = true,
                                                                                    showText = true,
                                                                                    disabled,
                                                                                    className = '',
                                                                                    seller,
                                                                                    rounded = false,
                                                                                    size = ButtonSize.DEFAULT,
                                                                                    type = ButtonType.DEFAULT
                                                                                }) => {

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ToggleSellerStatusStore(commonContext, seller);

    const handleOnclick = async () => {
        onClick();
        await store.toggle();
        onSave();
    }

    const text = i18n.translate((store.isSellerActive ? "DEACTIVATE_SELLER" : "ACTIVATE_SELLER"));
    const Icon = store.isSellerActive ? RadioButtonUncheckedSharpIcon : CheckCircleSharpIcon;

    if (!VALID_STATUS.includes(seller.status)) {
        return <></>;
    }

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default ToggleSellerStatusButton;
