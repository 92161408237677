import React, {memo, PropsWithChildren, useEffect, useMemo} from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./MoneyDeliveryOverview.style";
import {Observer} from "mobx-react-lite";
import MoneyDeliveryOverviewProperties from "./MoneyDeliveryOverviewProperties";
import {useCommonContext} from "../../../contexts/CommonContext";
import MoneyDeliveryOverviewStore from "./MoneyDeliveryOverviewStore";
import PriceLockup from "../../../ui_components/PriceLockup/PriceLockup";
import clsx from "clsx";
import Currency from "../../../entities/Currency";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";

const MoneyDeliveryOverview: React.FC<MoneyDeliveryOverviewProperties> = ({
                                                                              from,
                                                                              to,
                                                                              agencyId,
                                                                              filter,
                                                                              deliveryPersonId,
                                                                              status,
                                                                              currency,
                                                                              type,
                                                                              geoId,
                                                                              title,
                                                                              className = '',
                                                                              userId,
                                                                              size = "medium",
                                                                              includeChildren = false,
                                                                              forceRefresh = false
                                                                          }) => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = useMemo(() => {
        return new MoneyDeliveryOverviewStore(commonStore, from, to, filter, deliveryPersonId, status, currency, type, geoId, agencyId,userId, includeChildren)
        // eslint-disable-next-line
    }, [from, to, filter, deliveryPersonId, status, currency, type, geoId, agencyId]);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line
    }, [from, to, agencyId, filter, deliveryPersonId, status, currency, type, geoId, userId]);

    const getCurrencyTitle = (currency: Currency, isTransfer: boolean) => {
        if (isTransfer) return `Transferencia de ${i18n.translate(`${currency}_SHORT`)}`;
        return `Envío de ${i18n.translate(`${currency}_SHORT`)}`;
    }

    return (
        <Observer>
            {() => (
                <div className={clsx(
                    classes.money_overview_container,
                    size === "small" && classes.money_overview_container_small,
                    size === "medium" && classes.money_overview_container_medium,
                    className)}>
                    {store.overview && !store.loading && <>
                        <div className={classes.prices}>
                            <PriceLockup value={store.overview.totalSales}
                                         title={"Total de ventas"}
                                         theme={"secondary"}
                                         size={18}
                                         withBackground
                                         orders={store.overview.ordersAmount}
                            />
                            {store.overview.unpaidOrdersAmount > 0 &&
                                <PriceLockup value={store.overview.unpaidTotal}
                                             title={"Ordenes por pagar"}
                                             theme={"danger"}
                                             size={18}
                                             withBackground
                                             orders={store.overview.unpaidOrdersAmount}
                                />
                            }
                        </div>
                        <div className={clsx(
                            size === "medium" && classes.column,
                            size === "small" && classes.column_small,
                        )}>
                            {store.overview.currencyBreakdown.map((c, i) => (
                                <PriceLockup key={i}
                                             value={c.totalSales}
                                             currency={c.currency}
                                             title={getCurrencyTitle(c.currency, c.isTransfer)}
                                             size={13}
                                             theme={"success"}
                                             orders={c.ordersAmount}/>
                            ))}
                        </div>
                        <div className={clsx(
                            size === "medium" && classes.column,
                            size === "small" && classes.column_small,
                        )}>
                            {store.overview.statusBreakdown.map((c, i) => (
                                <PriceLockup key={i}
                                             value={c.totalSales}
                                             title={i18n.translate(c.status)}
                                             size={13}
                                             theme={"purple"} orders={c.ordersAmount}/>
                            ))}
                        </div>
                    </>}
                    <PageLoading className={classes.loading_container} loadingSize={25} isLoading={store.loading}
                                 text={`${i18n.translate("LOADING")} ${title || ''}`}/>
                </div>
            )}
        </Observer>
    )
}

const arePropsEqual = (prevProps: Readonly<PropsWithChildren<MoneyDeliveryOverviewProperties>>,
                       nextProps: Readonly<PropsWithChildren<MoneyDeliveryOverviewProperties>>) => {
    return (
        prevProps.from === nextProps.from
        && prevProps.to === nextProps.to
        && prevProps.agencyId === nextProps.agencyId
        && prevProps.userId === nextProps.userId
        && prevProps.filter === nextProps.filter
        && prevProps.deliveryPersonId === nextProps.deliveryPersonId
        && prevProps.currency === nextProps.currency
        && prevProps.type === nextProps.type
        && prevProps.geoId === nextProps.geoId
        && JSON.stringify(prevProps.status) === JSON.stringify(nextProps.status)
        && prevProps.includeChildren === nextProps.includeChildren
        && prevProps.forceRefresh === nextProps.forceRefresh
    );
}

export default memo(MoneyDeliveryOverview, arePropsEqual);
