import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import IdentificationInputProperties from "./IdentificationInputProperties";

const IdentificationInput: React.FC<IdentificationInputProperties> = ({
                                                                  value,
                                                                  label,
                                                                  placeholder,
                                                                  required,
                                                                  onChange,
                                                                  disabled,
                                                                  fullWidth = false,
                                                                  className = '',
                                                                  error
                                                              }) => {

    const [name, setName] = useState(value);

    useEffect(() => {
        setName(value);
    }, [value]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newName = event.target.value;
        setName(newName);
        onChange(newName);
    }

    return (
        <TextInput
            error={error}
            label={label + `${required ? '*' : ''}`}
            value={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onNameChange}
            startIcon={ContactPageIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default IdentificationInput;