import DeliveryPersonInfoRequest from "./DeliveryPersonInfoRequest"

class AddDeliveryPersonInfoRequestMultiOrders {
    public packageDeliveryIds: string[]
    public deliveryPersonInfoRequest: DeliveryPersonInfoRequest

    constructor(packageDeliveryIds: string[], deliveryPersonInfoRequest: DeliveryPersonInfoRequest){
        this.packageDeliveryIds = packageDeliveryIds
        this.deliveryPersonInfoRequest = deliveryPersonInfoRequest
    }
}
export default AddDeliveryPersonInfoRequestMultiOrders
