import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import {PackageDeliveryService} from "../../../service/packageDelivery/PackageDeliveryService";

class AssignShippingIdStore {

    public deliveryOrderIds: string[];
    public suggestedId?: string;
    public selectedShippingId: string;
    public selectedDeliveryPerson?: DeliveryPerson;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, deliveryOrderIds: string[]) {
        this.deliveryOrderIds = deliveryOrderIds;
        this.selectedShippingId = "";
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public setDeliveryPerson(deliveryPerson?: DeliveryPerson) {
        this.selectedDeliveryPerson = deliveryPerson;
    }

    @action
    public async openModal() {
        await this.getShippinId();
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    updateID() {

    }

    @action
    public async getShippinId() {
        const response = await PackageDeliveryService.getShippinId();
        this.suggestedId = response.data;
        this.setShippingId(this.suggestedId||"");
        this.commonStore.processErrors(response);
        return response.success;
    }


    setShippingId(selectedShippingId: string) {
        this.selectedShippingId = selectedShippingId;
    }
}

export default AssignShippingIdStore;
