import React from 'react';
import {useStyles} from "../MiscellanyCategories.style";
import clsx from "clsx";
import MiscellanyCategoryRowProperties from "./MiscellanyCategoryRowProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import { CurrencyModule } from '../../../util/Currency';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import NewMiscellanyCategoryButton from '../../../shared_components/Buttons/NewMiscellanyCategoryButton/NewMiscellanyCategoryButton';
import ShowArticleDescription from '../../../shared_components/Info/ShowArticleDescription/ShowArticleDescription';
import Currency from '../../../entities/Currency';
import RemoveMiscellanyCategoryButton from '../../../shared_components/Buttons/RemoveMiscellanyCategoryArticleButton/RemoveMiscellanyCategoryButton';
import EditMiscellanyCategoryPriceButton from '../../../shared_components/Buttons/EditMiscellanyCategoryPriceButton/EditMiscellanyCategoryPriceButton';


const MiscellanyCategoryRowMedium: React.FC<MiscellanyCategoryRowProperties> = ({
                                                            miscellanyCategory,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const classes = useStyles();

    return (
        <div
            className={clsx(classes.miscellany_category_info_container)}>
            <ShowText text={miscellanyCategory.name} className={classes.miscellany_category_name_container}/>
            <ShowArticleDescription description={miscellanyCategory.description ?? '-'}/>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Precio:`}</div>
                        {miscellanyCategory.price ?
                            <div className={classes.value}>{CurrencyModule.format(miscellanyCategory.price, Currency.DOLLAR)}</div> :
                            <div className={classes.without_value}>Sin precio definido</div>}
                </div>
            </div>
            <ExpandoMenu>
                <>
                    <EditMiscellanyCategoryPriceButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        miscellanyCategoryId={miscellanyCategory.id}
                        miscellanyCategoryName={miscellanyCategory.name}
                        price={miscellanyCategory.price}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <NewMiscellanyCategoryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        miscellanyCategoryId={miscellanyCategory.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <RemoveMiscellanyCategoryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        miscellanyCategory={miscellanyCategory}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default MiscellanyCategoryRowMedium;
