import TransactionStatus from "./TransactionStatus";

class ArticleTransaction {
    public id: string;
    public amount: number;
    public direction: string;
    public error?: string;
    public status: TransactionStatus;
    public articleId: string;
    public articleName: string;
    public createdAt: number;
    public balanceToBefore?: number;
    public comment?: string;

    constructor(
        id: string,
        amount: number,
        articleId: string,
        articleName: string,
        direction: string,
        status: TransactionStatus,
        createdAt: number,
        balanceToBefore?: number,
        comment?: string,
        error?: string
    ) {
        this.id = id;
        this.createdAt = createdAt;
        this.amount = amount;
        this.direction = direction;
        this.error = error;
        this.articleId = articleId;
        this.articleName = articleName;
        this.status = status;
        this.balanceToBefore = balanceToBefore;
        this.comment = comment;
    }

}

export default ArticleTransaction;