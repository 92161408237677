class ValidateTopupRequest {
    public clientId: string;
    public contactId: string;
    public productId: string;
    public agencyId?: string;
    public userId?: string;
    public anonymousNumber?: string;

    constructor(clientId: string, contactId: string, productId: string, agencyId?: string, userId?: string, anonymousNumber?: string) {
        this.contactId = contactId;
        this.clientId = clientId;
        this.productId = productId;
        this.agencyId = agencyId;
        this.userId = userId;
        this.anonymousNumber = anonymousNumber;
    }

}

export default ValidateTopupRequest;