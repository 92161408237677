import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        padding: '12px',
        backgroundColor: theme.palette.grey[200],
        height: 'calc( 100vh - 250px)',
        "@media (max-width: 800px)": {
            flexDirection: 'column',
            overflow: 'auto'
        }
    },
    amounts_container: {
        display: 'flex',
        flexDirection: 'row'
    },
    amounts_icon: {
        paddingTop: '24px',
        margin: '0 2px'
    },
    input: {
        marginTop: '16px'
    },
    filler: {
        flexGrow: 1,
        textAlign: 'center'
    },
    form_container: {
        borderRadius: '8px',
        display: "flex",
        padding: '10px',
        flexDirection: "column",
        backgroundColor: '#ffffff'
    },
    details_container: {
        width: '100%',
        padding: '0 12px',
        display: 'flex',
        "@media (max-width: 800px)": {
            padding: '0',
            marginTop: '4px',
            with: '100%'
        }
    },
    full_width: {
        width: '100%'
    },
    checkbox: {
        alignSelf: 'flex-start',
        marginTop: '16px'
    },
    contact_bank_card_group: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px'
    },
    padding_top_12: {
        paddingTop: '12px'
    }
}));

export {useStyles}