import React from "react";
import InventoryItemRowProperties from "./InventoryItemRowProperties";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import InventoryItemRowSmall from "./InventoryItemRowSmall";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import InventoryItemRowMedium from "./InventoryItemRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";

const InventoryItemRow:React.FC<InventoryItemRowProperties> = ({
                                                                    inventoryItem,
                                                                    onUpdate,
                                                                    filter
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <InventoryItemRowSmall
                    inventoryItem={inventoryItem}
                    onUpdate={onUpdate}
                    filter={filter}/>
            </ViewSmall>
            <ViewMedium>
                <InventoryItemRowMedium
                    inventoryItem={inventoryItem}
                    onUpdate={onUpdate}
                    filter={filter}/>
            </ViewMedium>
            <ViewLarge>
                <InventoryItemRowMedium
                    inventoryItem={inventoryItem}
                    onUpdate={onUpdate}
                    filter={filter}/>
            </ViewLarge>
        </>
    )
}
export default InventoryItemRow