import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";

const ERROR_MAP = {
    name: ["ERROR_00_400_33"],
}

class EditMiscellanyCategoryPriceStore {

    public miscellanyCategoryId: string;
    public miscellanyCategoryName: string;
    public price: number;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public nameError?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, miscellanyCategoryId: string, miscellanyCategoryName: string, price: number) {
        this.isEditing = !!miscellanyCategoryId;
        this.miscellanyCategoryId = miscellanyCategoryId;
        this.miscellanyCategoryName = miscellanyCategoryName;
        this.price = price;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.price);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const response = await MiscellanyCategoryService.updatePrice(this.miscellanyCategoryId, this.price);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default EditMiscellanyCategoryPriceStore;