import CommonStore from "../../../stores/CommonStore";
import { action, makeAutoObservable } from "mobx";
import Response from "../../../model/Response";
import { ReferenceItemPriceService } from "../../../service/packageDelivery/ReferenceItemPrice/ReferenceItemPriceService";
import ReferenceItemPriceResponse from "../../../model/packageDelivery/referenceItemPrice/ReferenceItemPriceResponse";

const ERROR_MAP = {
  unauthorized: ["ERROR_00_401_00"],
  name: ["ERROR_00_600_01"],
};

class NewReferenceItemPriceButtonStore {
  public referenceItemId?: string;
  public referenceItemPriceResponse: ReferenceItemPriceResponse;
  public isEditing: boolean;
  public isModalOpen: boolean;
  public loading: boolean;
  public nameError?: string;
  public commonStore: CommonStore;

  // @ts-ignore
  constructor(commonStore: CommonStore, referenceItemId?: string | undefined) {
    this.isEditing = !!referenceItemId;
    this.referenceItemId = referenceItemId;
    this.isModalOpen = false;
    this.referenceItemPriceResponse = {} as ReferenceItemPriceResponse;
    this.loading = false;
    this.commonStore = commonStore;
    makeAutoObservable(this);
  }

  get enableButton(): boolean {
    return !!this.referenceItemPriceResponse?.referenceItemName;
  }

  @action
  public setNewName(newName: string) {
    this.referenceItemPriceResponse.referenceItemName = newName.toUpperCase();
  }

  @action
  public setNewDescription(newDescription: string) {
    this.referenceItemPriceResponse.referenceItemDescription =
      newDescription.toUpperCase();
  }

  @action
  public setNewAmount(newAmount: number) {
    this.referenceItemPriceResponse.amount = newAmount;
  }

  @action
  public async loadReferenceItemPriceResponse() {
    if (this.referenceItemPriceResponse) {
      this.isEditing = true;
      this.loading = true;
      const response = await ReferenceItemPriceService.find(
        this.referenceItemId!
      );
      if (response.success && response.data) {
        this.referenceItemPriceResponse = response.data;
      }
      this.loading = false;
      this.commonStore.processErrors(response);
      this.processError(response.error);
    }
  }

  @action
  public async openModal() {
    this.loading = true;
    if (this.isEditing) {
      await this.loadReferenceItemPriceResponse();
    }
    this.isModalOpen = true;
    this.loading = false;
  }

  @action
  public closeModal() {
    this.isModalOpen = false;
    this.referenceItemPriceResponse = {} as ReferenceItemPriceResponse;
  }

  @action
  public async save(): Promise<boolean> {
    this.loading = true;
    let response: Response<any>;
    if (this.isEditing) {
      response = await ReferenceItemPriceService.update(
        this.referenceItemPriceResponse
      );
    } else {
      response = await ReferenceItemPriceService.create(
        this.referenceItemPriceResponse
      );
    }
    this.commonStore.processErrors(response);
    this.processError(response.error);
    this.loading = false;
    return response.success;
  }

  processError(error?: string) {
    this.nameError = undefined;
    if (error) {
      if (ERROR_MAP.unauthorized.includes(error)) {
        this.commonStore.showErrorToast(error);
      }
      if (ERROR_MAP.name.includes(error)) {
        this.nameError = error;
      }
    }
  }
}
export default NewReferenceItemPriceButtonStore;
