import React from 'react';
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AdditionalPriceRow.style";
import {useI18nContext} from "../../../../../contexts/I18nContext";
import AdditionalPriceRowProperties from "./AdditionalPriceRowProperties";
import Button from "../../../../../ui_components/Button/Button";
import ButtonSize from "../../../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../../../ui_components/Button/ButtonType";
import ShowPrice from '../../../../Info/ShowPrice/ShowPrice';
import Icon from '../../../../../ui_components/Icon/Icon';
import IconName from '../../../../../ui_components/Icon/enums/IconName';
import ShowGeneralComment from '../../../../Info/ShowGeneralComment/ShowGeneralComment';


const AdditionalPriceRow: React.FC<AdditionalPriceRowProperties> = ({
                                                        additionalPrice,
                                                        onDelete
                                                    }) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    return <Observer>
        {() => (
            <div className={classes.additional_price_row_container}>
                <ShowPrice value={additionalPrice.amount} currency={additionalPrice.currency}/>
                <ShowGeneralComment value={additionalPrice.comment}/>
                <div className={classes.tools_container}>
                    <Button
                        onClick={() => onDelete(additionalPrice)}
                        size={ButtonSize.SMALL}
                        type={ButtonType.SECONDARY}
                        tooltip={i18n.translate("DELETE")}
                        startIcon={<Icon name={IconName.DELETE_ICON}/>}
                    />
                </div>
            </div>
        )}
    </Observer>
}

export default AdditionalPriceRow;
