import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ArticlePrice from "../../../entities/ArticlePrice";
import {ArticlePriceService} from "../../../service/articleDelivery/ArticlePriceService";
import Geo from "../../../entities/Geo";
import { GeoLevel } from "../../Selects/GeoSelect/GeoSelectProperties";

const ERROR_MAP = {
    amountError: ["ERROR_00_400_81"]
}

class NewArticlePriceStore {

    public articleId: string;
    public articlePriceId?: string;
    public articlePrice: ArticlePrice;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;

    public selectedProvince?: Geo;

    // Validation erros
    public amountError?: string;

    public commonStore: CommonStore;
    public agencyId?: string;


    constructor(commonStore: CommonStore, clientId: string, contactId?: string, agencyId?: string) {
        this.isEditing = !!contactId;
        this.articleId = clientId;
        this.articlePriceId = contactId;
        this.isModalOpen = false;
        this.articlePrice = {} as ArticlePrice;
        this.loading = false;
        this.commonStore = commonStore;
        this.agencyId = agencyId;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.articlePrice?.price) && !!(this.articlePrice?.geoId);
    }

    @action
    public async loadArticlePrice() {
        if (this.articlePriceId) {
            this.isEditing = true;
            const response = await ArticlePriceService.find(this.articleId, this.articlePriceId!)
            if (response.success && response.data) {
                this.articlePrice = response.data;
                if (this.articlePrice && this.articlePrice.geo && this.articlePrice.geo.parentInfo) {
                    this.selectedProvince = new Geo(this.articlePrice.geo.parentId, this.articlePrice.geo.parentInfo, '', GeoLevel.STATE, true, 'Cuba');
                }
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadArticlePrice();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        this.articlePrice = {} as ArticlePrice;
    }

    @action
    public async save(): Promise<boolean> {
        const serviceToCall = this.isEditing ? ArticlePriceService.update : ArticlePriceService.create;
        const response = await serviceToCall(this.articlePrice, this.articleId);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        return response.success;
    }

    processError(error?: string) {
        this.amountError = undefined;
        if (error) {
            if (ERROR_MAP.amountError.includes(error)) {
                this.amountError = error;
            }
        }

    }
}

export default NewArticlePriceStore;