import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {Observer} from "mobx-react-lite";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ConfirmReceivedDeliveryButtonProperties from './ConfirmReceivedDeliveryButtonProperties';
import Link from '../../../ui_components/Link/Link';

const ConfirmReceivedDeliveryButton: React.FC<ConfirmReceivedDeliveryButtonProperties> = ({
                                                                                  onClick = () => {},
                                                                                  onSave = () => {},
                                                                                  showIcon = true,
                                                                                  showText = true,
                                                                                  disabled,
                                                                                  className = '',
                                                                                  deliveryPersonInfo,
                                                                                  onReceivedDelivery,
                                                                                  rounded = false,
                                                                                  size = ButtonSize.DEFAULT,
                                                                                  type = ButtonType.DEFAULT
}) => {

    const i18n = useI18nContext();
    const confirm = useConfirmation();

    const handleOnclick = async () => {
        const success = await confirm(i18n.translate("CONFIRM_RECEIVED_CONFIRMATION"), '');
        if(success){
            onReceivedDelivery(deliveryPersonInfo)
        }       
    }

    const text = i18n.translate("CONFIRM_RECEIVED");


    return <Observer>
        {() => (
            <>
                {/* <Btn/> */}
                <div><Link text={text} onClick={handleOnclick}></Link></div>
            </>
        )}
    </Observer>
}

export default ConfirmReceivedDeliveryButton;
