import Currency from "./Currency";
import TransactionStatus from "./TransactionStatus";

class Transaction {
    public id: string;
    public amount: number;
    public direction: string;
    public error?: string;
    public status: TransactionStatus;
    public currency: Currency;
    public createdAt: number;
    public balanceToBefore?: number;
    public comment?: string;

    constructor(
        id: string,
        amount: number,
        currency: Currency,
        direction: string,
        status: TransactionStatus,
        createdAt: number,
        balanceToBefore?: number,
        comment?: string,
        error?: string
    ) {
        this.id = id;
        this.createdAt = createdAt;
        this.amount = amount;
        this.direction = direction;
        this.error = error;
        this.currency = currency;
        this.status = status;
        this.balanceToBefore = balanceToBefore;
        this.comment = comment;
    }

}

export default Transaction;