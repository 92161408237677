class ArticleStockResponse {
    public id: string;
    public agencyId: string;
    public articleId: string;
    public articleName: string;
    public articleDefaultImage: string;
    public entityId: string;
    public unit: string;
    public amount: number;
    public blockedAmount: number;
    public availableAmount: number;

    constructor(
        id: string,
        agencyId: string,
        articleId: string,
        articleName: string,
        articleDefaultImage: string,
        entityId: string,
        unit: string,
        amount: number,
        blockedAmount: number,
        availableAmount: number
    ) {
        this.id = id;
        this.agencyId = agencyId;
        this.articleId = articleId;
        this.articleName = articleName;
        this.articleDefaultImage = articleDefaultImage;
        this.entityId = entityId;
        this.unit = unit;
        this.amount = amount;
        this.blockedAmount = blockedAmount;
        this.availableAmount = availableAmount;
    }
}

export default ArticleStockResponse;