import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import CustomCategory from "../model/packageDelivery/customCategory/CustomCategory";

export module CustomCategoryService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string, includeCurrent: boolean = false, agencyId?:string): Promise<Response<PagedResponse<CustomCategory>>> {
        const url = Config.get("GET_CUSTOM_CATEGORY_URL", {page, pageSize, filter, includeCurrent, agencyId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const customCategoriesData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (customCategoriesData && paginationData) {
                const data = customCategoriesData.map((t: any) => new CustomCategory(t.id, t.name, t.description, t.createdAt, t.weightRanges, t.categoryType));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<CustomCategory>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<CustomCategory>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<CustomCategory>> {
        const url = Config.get("FIND_SELLER_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const customCategoryData = response.data;
            if (customCategoryData) {
                const customCategory = new CustomCategory(customCategoryData.id, customCategoryData.name, customCategoryData.email, customCategoryData.createdAt, customCategoryData.status, customCategoryData.roleIds);
                return new Response<CustomCategory>(true, customCategory, response.error);
            }
        }
        return new Response<CustomCategory>(true, undefined, response.error, response.status);
    }

    export async function create(customCategory: CustomCategory): Promise<Response<any>> {
        const url = Config.get("CREATE_SELLER_URL");
        const request = new Request(url, customCategory);
        return await RestClient.post(request);
    }

    export async function update(customCategory: CustomCategory): Promise<Response<any>> {
        const url = Config.get("UPDATE_SELLER_URL", {id: customCategory.id});
        const request = new Request(url, customCategory);
        return await RestClient.put(request);
    }

    export async function setActive(customCategory: CustomCategory): Promise<Response<any>> {
        const url = Config.get("SET_SELLER_ACTIVE_URL", {id: customCategory.id});
        const request = new Request(url);
        return await RestClient.get(request);
    }

    export async function setInactive(customCategory: CustomCategory): Promise<Response<any>> {
        const url = Config.get("SET_SELLER_INACTIVE_URL", {id: customCategory.id});
        const request = new Request(url);
        return await RestClient.get(request);
    }

    export async function remove(customCategory: CustomCategory): Promise<Response<any>> {
        const url = Config.get("REMOVE_SELLER_URL", {id: customCategory.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}