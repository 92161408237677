import React, { useEffect, useState } from 'react';
import ShowTimeProperties from "./ShowTimeProperties";
import { Card, CardContent, Typography } from '@mui/material';
import { format, utcToZonedTime } from 'date-fns-tz';
import es from 'date-fns/locale/es'

const ShowTime: React.FC<ShowTimeProperties> = ({
    className = ''
}) => {
    const timeZone = 'America/Havana';
    const [time, setTime] = useState(utcToZonedTime(new Date(), timeZone));    

    useEffect(() => {
        let update = setInterval(() => {
          setTime(utcToZonedTime(new Date(), timeZone));
        }, 1000);
        return () => clearInterval(update);
    }, []);    
    
    return (
        <div>
            <Card sx={{ minWidth: 275 }} className={className}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Hora en Cuba
                    </Typography>
                    <Typography variant="h5" component="div">
                        {format(time, 'hh')}:{format(time, 'mm')}:{format(time, 'ss')}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {format(time, 'd \'de\' MMMM \'de\' yyyy', { locale: es })}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

export default ShowTime;