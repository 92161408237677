import {action, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { MoneyDeliveryService } from "../../../service/moneyDelivery/MoneyDeliveryService";
import DeliveryOrderCommentResponse from "../../../model/moneyDelivery/DeliveryOrderCommentResponse";
import { ProductOrderService } from "../../../service/ProductOrderService";
import MoneyDeliveryOrderResponse from "../../../model/moneyDelivery/MoneyDeliveryOrderResponse";
import { DeliveryPersonService } from "../../../service/DeliveryPersonService";
import DeliveryPerson from "../../../entities/DeliveryPerson";

class MoneyDeliveryOrderDetailsStore {

    public moneyDeliveryOrderId: string;
    public moneyDeliveryOrder?: MoneyDeliveryOrderResponse = undefined;
    public moneyDeliveryOrderComments: DeliveryOrderCommentResponse[];
    public deliveryPerson?: DeliveryPerson;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, moneyDeliveryOrderId: string) {
        this.commonStore = commonStore;
        this.moneyDeliveryOrderId = moneyDeliveryOrderId;
        this.moneyDeliveryOrderComments = [];
        makeObservable(this, {
            moneyDeliveryOrder: observable,
            moneyDeliveryOrderComments: observable,
            deliveryPerson: observable
        });
    }

    @action
    public async getElement(): Promise<void> {
        this.commonStore.setLoading();
        const response = await MoneyDeliveryService.find(this.moneyDeliveryOrderId);
        runInAction(() => {
            this.moneyDeliveryOrder = response;
            if (this.moneyDeliveryOrder?.deliveryPersonId && this.moneyDeliveryOrder.deliveryPersonName) {
                this.findDeliveryPersonDetails();
            }
        })
        this.commonStore.removeLoading();
    }

    @action
    public async loadMoneyDeliveryOrderComments() {
        const response = await MoneyDeliveryService.getMoneyDeliveryComments(this.moneyDeliveryOrderId)
        if (response.success && response.data) {
            this.moneyDeliveryOrderComments = response.data;
        }
    }

    @action
    public async findDeliveryPersonDetails() {
        const response = await DeliveryPersonService.find(this.moneyDeliveryOrder!!.deliveryPersonId!!)
        if (response.success && response.data) {
            this.deliveryPerson = response.data;
        }
    }

    @action
    public async addComment(comment: string) {
        const response = await ProductOrderService.addComment(this.moneyDeliveryOrderId, comment);
        if (response.success && response.data) {
            const newComments = response.data.comments.filter((t: any) => this.moneyDeliveryOrderComments.findIndex(x => x.id === t.id) < 0);
            if (newComments && newComments.length > 0) {
                const newComment = newComments[0];
                this.moneyDeliveryOrderComments = [ ...this.moneyDeliveryOrderComments, new DeliveryOrderCommentResponse(
                    newComment.id, newComment.userId, 'Yo', newComment.comment, newComment.createdAt
                ) ];
            }
            return true;
        }
        return false;
    }

}

export default MoneyDeliveryOrderDetailsStore;