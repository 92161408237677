import React from 'react';
import {useStyles} from "../Clients.style";
import clsx from "clsx";
import ClientRowProperties from "./ClientRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewClientButton from "../../../shared_components/Buttons/NewClientButton/NewClientButton";
import RemoveClientButton from "../../../shared_components/Buttons/RemoveClientButton/RemoveClientButton";


const ClientRowSmall: React.FC<ClientRowProperties> = ({client, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.client_info_container_small)}>
            <ShowSellerBasicInfo autofill name={client.name} email={client.email}/>
            <div className={classes.menu_container_small}>
                <NewClientButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    clientId={client.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <RemoveClientButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    client={client}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
            </div>
        </div>
    )
}

export default ClientRowSmall;
