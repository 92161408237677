import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import Agency from "../../entities/Agency";
import Promotion from "../../entities/Promotion";
import { PromotionService } from "../../service/PromotionService";

class HomeStore {

    public activePromotions: Promotion[];
    public agencyFilter?: Agency;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore) {
        this.activePromotions = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            activePromotions: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.activePromotions.length === 0;
    }

    @action
    public async getPromotions(): Promise<void> {
        this.commonStore.setLoading();
        const response = await PromotionService.getActivePromotions();
        if (response.success) {
            runInAction(() => {
                this.activePromotions = response.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default HomeStore;