import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        padding: '12px',
        backgroundColor: theme.palette.grey[200],
        height: 'calc(100vh - 250px)'
    },
    final_price: {
        fontSize: "xx-large"
    },
    form_container: {
        width:'100%',
        height:'auto',
        borderRadius: '16px',
        display: "flex",
        margin:'8px auto',
        flexDirection: "column",
        backgroundColor: '#ffffff'
    },
    form_container2: {
        width:'35%',
        height:'auto',
        borderRadius: '16px',
        display: "flex",
        margin:'8px auto',
        marginLeft:'10px',
        flexDirection: "column",
        backgroundColor: '#ffffff'
    },
    form_container3: {
        width:'32px',
        writingMode: 'vertical-lr',
        textOrientation: 'upright',
        height:'auto',
        borderRadius: '16px',
        display: "flex",
        margin:'8px auto',
        marginLeft:'10px',
        flexDirection: "column",
        backgroundColor: '#ffffff',
        padding: 'auto auto'
    },
    resume_and_new_item_container:{
        margin: 'auto 16px 16px 16px',
        display:'flex',
    },
    price_resume_container: {
        borderRadius: '8px',
        padding: '16px',
        margin: '16px',
        marginTop: '20px',
        height: 'fit-content',
        minWidth: '150px',
        border: `1px solid ${theme.palette.grey["400"]}`
    },
}));

export {useStyles}