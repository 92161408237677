import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        agency_seller_container: {
            display: 'grid',
            rowGap: '20px',
            minWidth: '300px',
            backgroundColor: theme.palette.common.white,
            padding: '28px',
            borderRadius: '4px'
        },
        agency_select_container: {
            maxHeight: '300px',
            overflow: 'auto'
        }
    }))
;

export {useStyles}