import React, {useEffect, useState} from 'react';
import CustomCategorySelectProperties from "./CustomCategorySelectProperties";
import Select from "../../../ui_components/Select/Select";
import SelectOption from "../../../ui_components/Select/SelectOption";
import {useI18nContext} from "../../../contexts/I18nContext";
import CategoryIcon from "@mui/icons-material/Category";

const CustomCategorySelect: React.FC<CustomCategorySelectProperties> = ({
                                                                            className = '',
                                                                            disabled,
                                                                            fullWidth = false,
                                                                            selectedCustomCategory,
                                                                            categories,
                                                                            onCustomCategoryChange,
                                                                    }) => {
    const i18n = useI18nContext();

    const [loading] = useState(false)
    const [options,setOptions] = useState<SelectOption[]>([])
    const [selectedOption,setSelectedOption]= useState<SelectOption>()

    useEffect(()=>{
        const opts = categories.map(p =>
            new SelectOption(
                p.name,
                p.id,
                i18n.translate(p.categoryType)
            )
        )
        setOptions(opts)
    }, [categories,i18n])

    useEffect(()=>{
        if(selectedCustomCategory){
            const selectedOpt = options.find(t => t.value===selectedCustomCategory.id )
            setSelectedOption(selectedOpt)
        }else{
            setSelectedOption(undefined)
        }
    },[selectedCustomCategory,options])


    const onCustomCategorySelectChange = async (option: SelectOption) => {
        onCustomCategoryChange(option.value);
    }

    return (
        <Select
            loading={loading}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("INVENTORY_ITEMS_CATEGORY")}
            placeholder={i18n.translate("INVENTORY_ITEMS_CATEGORY_PLACEHOLDER")}
            icon={CategoryIcon}
            onChange={onCustomCategorySelectChange}
            selected={selectedOption}
            elements={options}/>
    )
}

export default CustomCategorySelect;