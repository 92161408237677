import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./Sellers.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SellerRow from "./SellerRow/SellerRow";
import NewSellerButton from "../../shared_components/Buttons/NewSellerButton/NewSellerButton";
import SellerStore from "./SellerStore";
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import useOnEnterPress from '../../hooks/EnterPress';


const Sellers: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const sellerStore = new SellerStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        sellerStore.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await sellerStore.getElements();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await sellerStore.goToPage(page, pageSize);
    }

    const onSellerSearch = () => {
        sellerStore.search()
    }
    useOnEnterPress(onSellerSearch,[sellerStore]);

    return (
        <Observer>
            {() => (
                <div className={classes.sellers_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <NewSellerButton type={ButtonType.SECONDARY} onSave={onUpdate} rounded/>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={sellerStore.filter}
                                     placeholder={i18n.translate("SELLER_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         sellerStore.setFilter(value)
                                     }} onEnter={() => sellerStore.search()}/>
                        {/*<GeoSelect onChange={(status) => {*/}

                        {/*}}/>*/}
                        <Button
                            onClick={onSellerSearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    {!sellerStore.isEmpty &&
                    <div className={classes.sellers_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("SELLERS")}
                                        pagination={sellerStore.pagination}/>
                        <div className={classes.elements_container}>
                            {sellerStore.sellers.map((seller, i) => (
                                <SellerRow
                                    onUpdate={onUpdate}
                                    seller={seller} key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("SELLERS")}
                            pagination={sellerStore.pagination}/>
                    </div>}
                    {sellerStore.isEmpty &&
                    <MessageCard icon={PeopleAltIcon} title={i18n.translate("NO_SELLERS_TITLE")}
                                 subtitle={i18n.translate("NO_SELLERS_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default Sellers;
