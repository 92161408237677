import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";


class RemoveDeliveryPersonStore {

    public deliveryPerson: DeliveryPerson;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, deliveryPerson: DeliveryPerson) {
        this.deliveryPerson = deliveryPerson;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await DeliveryPersonService.remove(this.deliveryPerson);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveDeliveryPersonStore;