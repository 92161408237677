import React from 'react';
import ShowProductProperties from "./ShowProductProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowProduct.style";
import ProductIcon from "../../../ui_components/Icon/icons/ProductIcon";

const ShowProduct: React.FC<ShowProductProperties> = ({
                                                          value,
                                                          className = '',
                                                      }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={classes.show_product_container}>
            <ProductIcon className={classes.icon}/>
            <div className={classes.name_container}>
                <div className={classes.label}>{i18n.translate("PRODUCT")}</div>
                {value && <div className={classes.name}>{value?.name}</div>}
                {!value && <div className={classes.not_available}>{i18n.translate("NOT_AVAILABLE")}</div>}
            </div>
        </div>
    )
}


export default ShowProduct;