class Pagination {
    public currentPage: number;
    public pageSize: number;
    public elementsCount: number;
    public pageCount: number;

    constructor(currentPage: number, pageSize: number, elementsCount: number, pageCount: number) {
        this.currentPage = currentPage;
        this.pageCount = pageCount;
        this.pageSize = pageSize;
        this.elementsCount = elementsCount;
    }

}

export default Pagination;