import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import DeliveryPerson from "../../entities/DeliveryPerson";
import Geo from "../../entities/Geo";
import ClientContact from "../../entities/ClientContact";
import Agency from "../../entities/Agency";
import PackageDeliveryOrderResponse from "../../model/packageDelivery/PackageDeliveryOrderResponse";
import {PackageDeliveryService} from "../../service/packageDelivery/PackageDeliveryService";
import CustomRange from "../../ui_components/DateTimeRageSelect/CustomRange";
import {Filter} from "../../util/Filter";
import CustomRangeRepository, { RangeName } from "../../ui_components/DateTimeRageSelect/CustomRangeRepository";
import I18nStore from "../../stores/I18nStore";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"]
}
class PackageDeliveryStore {

    public packageDeliverys: PackageDeliveryOrderResponse[];
    public selectedPackageDeliveryIds: string[];
    public selectedPackageDeliveryOrderNumbers: string[];
    public selectedPackageDeliveryId: string;
    public pagination: Pagination;


    //FILTERS
    public filter?: string;
    public shippingIdFilter?: string;
    public shipmentAssignedFilter? :string;
    public statusFilter?: string[];
    public deliveryPersonFilter?: DeliveryPerson;
    public fromFilter: number | undefined;
    public toFilter: number | undefined;
    public agencyFilter?: Agency;
    public geoFilter?: Geo;
    public range: CustomRange;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, i18n: I18nStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.packageDeliverys = [];
        
        const customRanges = CustomRangeRepository.getRanges(i18n);
        const range = customRanges.find(t => t.key === RangeName.LAST_FIFTEEN_DAYS);
        this.range = range || customRanges[0];
        this.setFromFilter(range?.from?.valueOf() || undefined);
        this.setToFilter(range?.to?.valueOf() || undefined);
        this.selectedPackageDeliveryIds = [];
        this.selectedPackageDeliveryOrderNumbers = [];
        this.selectedPackageDeliveryId = '';
        this.commonStore = commonStore;
        makeObservable(this, {
            filter:observable,
            packageDeliverys:observable,
            selectedPackageDeliveryIds: observable,
            pagination: observable,
            isEmpty: computed
        });

    }

      // MANAGE FILTERS
    public setFilter(filter?: string) {
        this.filter = filter;
        Filter.save("filter", filter);
    }
    public setShippingIdFilter(shippingId?: string) {
        this.shippingIdFilter = shippingId;
        Filter.save("shippingIdFilter", shippingId);
    }
    public setShipmentAssignedFilter(shipmentAssigned?: string) {
        this.shipmentAssignedFilter = shipmentAssigned;
        Filter.save("shipmentAssignedFilter", shipmentAssigned);
    }
    public setStatusFilter(selectedStatus?: string[]) {
        this.statusFilter = selectedStatus;
        Filter.save("statusFilter", selectedStatus);
    }
    public setDeliveryPersonFilter(deliveryPersonFilter?: DeliveryPerson) {
        this.deliveryPersonFilter = deliveryPersonFilter;
        Filter.save("deliveryPersonFilter", deliveryPersonFilter);
    }
    public setFromFilter(from?: number) {
        this.fromFilter = from;
        Filter.save("fromFilter", from);
    }
    public setToFilter(to?: number) {
        this.toFilter = to;
        Filter.save("toFilter", to);
    }
    public setAgencyFilter(agency?:Agency){
        this.agencyFilter = agency
        Filter.save("agencyFilter", agency);
    }
    public setGeoFilter(geo?:Geo){
        this.geoFilter = geo
        Filter.save("geoFilter", geo);
    }
    @action
    public async clearFilter() {
        this.setFilter(undefined)
        this.setDeliveryPersonFilter(undefined)
        this.setStatusFilter(undefined)
        this.setGeoFilter(undefined)
        this.setFromFilter(Filter.get("from", this.range.from?.valueOf() || undefined))
        this.setToFilter(Filter.get("to", this.range?.to?.valueOf() || undefined))
        this.setShippingIdFilter(undefined)
        this.setShipmentAssignedFilter(undefined)
        this.setAgencyFilter(undefined)
        await this.getElements(1)
    }

    get isEmpty(): boolean {
        return this.packageDeliverys.length === 0;
    }

    get haveNotSelected(): boolean {
        return this.selectedPackageDeliveryIds.length === 0;
    }

  

    @action
    public async search() {
        await this.getElements(1);
    }

    

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {

        this.commonStore.setLoading();
        const response = await PackageDeliveryService.get(
            page, pageSize, this.fromFilter, this.toFilter, this.filter, this.deliveryPersonFilter?.id,
            this.statusFilter, this.geoFilter?.id, this.agencyFilter?.id, this.shippingIdFilter, this.shipmentAssignedFilter);
        if (response.success) {
            runInAction(() => {
                this.pagination = response.data?.pagination || this.pagination;
                this.packageDeliverys = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response)
        this.processError(response.error)
        this.commonStore.removeLoading();
    }

    @action
    public async updatePackageDeliveryContact(contact: ClientContact) {
        this.commonStore.setLoading();
        const response = await PackageDeliveryService.updateClientPerson(this.selectedPackageDeliveryId, contact.id);
        if (response.success) {
            runInAction(() => {
                const tempPackageDelivery = this.packageDeliverys.find(t => t.id === this.selectedPackageDeliveryId);
                const tempPackageDeliveryIndex = this.packageDeliverys.findIndex(t => t.id === this.selectedPackageDeliveryId);
                if (tempPackageDelivery != null) {
                    this.packageDeliverys[tempPackageDeliveryIndex] = {
                        ...tempPackageDelivery,
                        contactId: contact.id,
                        contactName: contact.name
                    };
                }
            })
        }
        this.commonStore.processErrors(response);
        this.processError(response.error)
        this.commonStore.removeLoading();
    }

    @action
    public async setPackageDeliveryDeliveryPerson(packageDeliveryIds: string[], deliveryPerson: DeliveryPerson) {
        /* this.commonStore.setLoading();
        const response = await PackageDeliveryService.setDeliveryPerson(packageDeliveryIds, deliveryPerson.id);
        if (response.success) {
            runInAction(() => {
                for (const packageDeliveryId of packageDeliveryIds) {
                    const tempPackageDelivery = this.packageDeliverys.find(t => t.id === packageDeliveryId);
                    const PackageDeliveryIndex = this.packageDeliverys.findIndex(t => t.id === packageDeliveryId);
                    if (tempPackageDelivery != null) {
                        this.packageDeliverys[PackageDeliveryIndex] = {
                            ...tempPackageDelivery,
                            status: ProductOrderStatus.ASSIGNED,
                            deliveryPersonId: deliveryPerson.id,
                            deliveryPersonName: deliveryPerson.name
                        };
                    }
                }
            })
        }
        this.commonStore.processErrors(response);
        this.processError(response.error)
        this.commonStore.removeLoading(); */
    }

    @action
    public async unsetPackageDeliveryDeliveryPerson() {
       /*  this.commonStore.setLoading();
        const response = await PackageDeliveryService.unsetDeliveryPerson(this.selectedPackageDeliveryId);
        if (response.success) {
            runInAction(() => {
                const tempPackageDelivery = this.packageDeliverys.find(t => t.id === this.selectedPackageDeliveryId);
                const tempPackageDeliveryIndex = this.packageDeliverys.findIndex(t => t.id === this.selectedPackageDeliveryId);
                if (tempPackageDelivery != null) {
                    this.packageDeliverys[tempPackageDeliveryIndex] = {
                        ...tempPackageDelivery,
                        status: ProductOrderStatus.PENDING,
                        deliveryPersonId: null,
                        deliveryPersonName: null
                    };
                }
            })
        }
        this.commonStore.processErrors(response);
        this.processError(response.error)
        this.commonStore.removeLoading(); */
    }
    processError(error?: string) {
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                //this.commonStore.showErrorToast(error) //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
        }
    }
}

export default PackageDeliveryStore;
