import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";


class MiscellanyCategorySelectListStore {

    public loadingMiscellanyCategories: boolean;
    public commonStore: CommonStore;
    public miscellanyCategoryFilter?: string;
    public miscellanyCategories: MiscellanyCategory[];
    public miscellanyCategorysPagination: Pagination;
    public selectedMiscellanyCategory?: MiscellanyCategory;
    public agencyId?: string;

    constructor(commonStore: CommonStore, selectedMiscellanyCategory?: MiscellanyCategory, agencyId?: string) {
        this.loadingMiscellanyCategories = true;
        this.commonStore = commonStore;
        this.miscellanyCategorysPagination = new Pagination(1, 15, 0, 0);
        this.miscellanyCategories = [];
        this.selectedMiscellanyCategory = selectedMiscellanyCategory;
        this.agencyId = agencyId;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.selectedMiscellanyCategory);
    }

    @action
    public async searchMiscellanyCategories(): Promise<void> {
        this.loadingMiscellanyCategories = true;
        const response = await MiscellanyCategoryService.get(1, 1000, this.miscellanyCategoryFilter, this.agencyId);
        if (response.success) {
            runInAction(() => {
                this.miscellanyCategories = response.data?.data || [];
                this.miscellanyCategorysPagination = response.data?.pagination || this.miscellanyCategorysPagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loadingMiscellanyCategories = false;
    }

    get isMiscellanyCategoriesEmpty(): boolean {
        return this.miscellanyCategories.length === 0;
    }

    @action
    public async setMiscellanyCategoryFilter(filter: string) {
        runInAction(() => {
            this.miscellanyCategoryFilter = filter;
            this.searchMiscellanyCategories();
        })
    }

    @action
    public setSelectedMiscellanyCategory(miscellanyCategory?: MiscellanyCategory) {
        runInAction(() => {
            this.selectedMiscellanyCategory = miscellanyCategory;
        });
    }

}

export default MiscellanyCategorySelectListStore;