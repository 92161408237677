import React from 'react';
import TopupPriceRowProperties from "./TopupPricesRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import TopupPriceRowMedium from "./TopupPricesRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import TopupPriceRowSmall from "./TopupPricesRowSmall";


const TopupPriceRow: React.FC<TopupPriceRowProperties> = ({
                                                    topupPrice,
                                                    products,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <TopupPriceRowSmall onUpdate={onUpdate} topupPrice={topupPrice} products={products}/>
            </ViewSmall>
            <ViewMedium>
                <TopupPriceRowMedium onUpdate={onUpdate} topupPrice={topupPrice} products={products} />
            </ViewMedium>
            <ViewLarge>
                <TopupPriceRowMedium onUpdate={onUpdate} topupPrice={topupPrice} products={products} />
            </ViewLarge>
        </>
    )
}

export default TopupPriceRow;
