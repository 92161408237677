import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { MiscellanyOrderService } from "../../../service/miscellany/MiscellanyOrderService";


class RemoveMiscellanyOrderStore {

    public miscellanyOrderId: string;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, miscellanyOrderId: string) {
        this.miscellanyOrderId = miscellanyOrderId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await MiscellanyOrderService.remove(this.miscellanyOrderId);
        if (!response.success && response.status === 400 && response.error === 'ERROR_00_400_44') {
            await this.commonStore.showErrorToast('No se pudo eliminar el envío porque está en un estado que lo imposibilita');
        } else {
            this.commonStore.processErrors(response);
        }        
        return response.success;
    }
}

export default RemoveMiscellanyOrderStore;