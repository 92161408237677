import React from 'react';
import IconProperties from "./IconProperties";


const ExcelIcon: React.FC<IconProperties> = ({
                                                 className,
                                                 fontSize
                                             }) => {
    return (
        <svg className={className} width="1em" height="1em" x="0px" y="0px" viewBox="0 0 20 20"
             style={{fontSize: fontSize}}>
            <path
                d="m15.5336935 1.36078656-1.2245001-1.36078656h-9.64754479c-.69577602 0-.96419839.51624065-.96419839.91894088v3.63096407h1.35277125v-2.89759304c0-.15466882.12950495-.28399685.2799043-.28399685h6.90257343c.1521674 0 .2280283.02704571.2280283.15119677v4.82023752h4.9136085c.1930933 0 .267854.09935624.267854.24674856v11.76863999c0 .2466993-.0994251.2839969-.2498244.2839969h-12.06223983c-.15171156 0-.2799043-.1320294-.2799043-.2839969v-1.075385h-1.34436952v1.6948983c-.01754838.599425.30244277 1.0253488.95579666 1.0253488h13.39914779c.699952 0 .9392036-.507105.9392036-.968947v-12.58708742-1.2572951l-.3495043-.37946981zm-1.6975669.15872527.3864907.43401545 2.5961264 2.85367339.1430237.17283806h-2.652574c-.2003171 0-.3270546-.03306996-.3802125-.09920987-.0531578-.06613992-.0841093-.170658-.0928543-.31355425zm-1.0909716 9.14787687h4.5777173v1.333414h-4.5777173zm0-2.66685359h4.5777173v1.33341395h-4.5777173zm0 5.33370719h4.5777173v1.333414h-4.5777173zm-11.745155-7.7085115v10.6673887h10.4647417v-10.6673887zm5.23301435 6.2044011-.64091322.9773823h.64091322v1.1921231h-3.21748634l2.33595938-3.5082705-2.0691267-3.15753866h1.72777568l1.22421543 1.83613905 1.22290321-1.83613905h1.72643824l-2.07175112 3.15622656 2.33727159 3.5095826h-1.79315902z"
                fill="currentColor"/>
        </svg>
    )
}
export default ExcelIcon;