import React from "react";
import ShowMoneyDeliveryBudgetDetailsProperties from "./ShowMoneyDeliveryBudgetDetailsProperties";
import {useStyles} from "./ShowMoneyDeliveryBudgetDetails.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import Currency from "../../../entities/Currency";
import {CurrencyModule} from "../../../util/Currency";
import Table from "../../../ui_components/Table/Table";
import TableType from "../../../ui_components/Table/TableType";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";

const ShowMoneyDeliveryBudgetDetails: React.FC<ShowMoneyDeliveryBudgetDetailsProperties> = ({
                                                                                                budgetDetails,
                                                                                                isLoading,
                                                                                                showHeader = true
                                                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const tableHeaders = [i18n.translate("DESCRIPTION"), i18n.translate("VALUE")]
    const tableBody = [
        [i18n.translate("AMOUNT_TO_SEND_PLACEHOLDER"), CurrencyModule.format(budgetDetails?.amountToSend || 0, Currency.DOLLAR)],
        [i18n.translate("APPLIED_RATE"), `1 USD = ${CurrencyModule.format(budgetDetails?.conversionRate || 0, budgetDetails?.currencyToReceive)}`],
        [i18n.translate("AMOUNT_TO_RECEIVE_PLACEHOLDER"), CurrencyModule.format(budgetDetails?.amountToReceive || 0, budgetDetails?.currencyToReceive)],
        [i18n.translate("TOTAL_FEE"), CurrencyModule.format(budgetDetails?.totalFees || 0, Currency.DOLLAR)],
        [i18n.translate("SUBTOTAL"), CurrencyModule.format(budgetDetails?.totalToPay || 0, Currency.DOLLAR)],
    ]

    const Info = () => (<div className={classes.budget_container}>
        {showHeader && <div className={classes.box_container}>
            <div className={classes.box}>
                <span>{i18n.translate("AMOUNT_TO_RECEIVE_PLACEHOLDER")}</span>
                <div>{CurrencyModule.format(budgetDetails?.amountToReceive || 0, budgetDetails?.currencyToReceive)}</div>
            </div>
            <div className={classes.box}>
                <span>{i18n.translate("TOTAL_TO_PAY")}</span>
                <div>{CurrencyModule.format(budgetDetails?.totalToPay || 0, Currency.DOLLAR)}</div>
            </div>
        </div>}
        <div className={classes.overview_container}>
            <Table tableType={TableType.SECONDARY} title={i18n.translate("BREAKDOWN")} header={tableHeaders}
                   body={tableBody}/>
        </div>
    </div>);

    const NoData = () => (
        <div className={classes.no_content}>
            <MessageCard
                title={i18n.translate("NO_MONEY_REMITTANCES_BUDGET_TITLE")}
                subtitle={i18n.translate("WITHOUT_DATA_TO_SHOW_DESCRIPTION")}
                icon={CurrencyExchangeIcon}/>
        </div>
    )

    const Loading = () => (
        <div className={classes.no_content}>
            <PageLoading isLoading/>
        </div>
    )
    if (isLoading) {
        return <Loading/>
    }
    if (budgetDetails) {
        return <Info/>
    }

    return <NoData/>
};

export default ShowMoneyDeliveryBudgetDetails;