import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { PromotionService } from "../../../service/PromotionService";

class UploadPromotionImageStore {

    public promotionId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public selectedImageFile?: File;

    constructor(commonStore: CommonStore, promotionId: string) {
        this.isModalOpen = false;
        this.loading = false;
        this.promotionId = promotionId;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        if (!this.selectedImageFile) {
            return false;
        }
        this.loading = true;
        await PromotionService.uploadImage(this.promotionId, this.selectedImageFile);
        this.loading = false;
        return true;
    }
}

export default UploadPromotionImageStore;