import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Invoice from "../../../entities/Invoice";
import {InvoiceService} from "../../../service/InvoiceService";

class ViewInvoiceStore {

    public productOrderId: string;
    public invoice?: Invoice;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, productOrderId: string) {
        this.productOrderId = productOrderId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async loadInvoice() {
        this.loading = true;
        const response = await InvoiceService.findByProductOrder(this.productOrderId);
        this.commonStore.processErrors(response);
        if (response.success && response.data) {
            this.invoice = response.data;
        }
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.loadInvoice();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default ViewInvoiceStore;