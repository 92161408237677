import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";
import Pagination from "../../../entities/Pagination";


class DeliveryPersonSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public elements: DeliveryPerson[];
    public pagination: Pagination;
    public selectedDeliveryPerson?: DeliveryPerson;
    public isSearchDialogOpen: boolean;

    public orderByGeosIdProximity?: string[];

    constructor(commonStore: CommonStore, selectedDeliveryPerson?: DeliveryPerson, orderByGeosIdProximity?: string[]) {
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.elements = [];
        this.selectedDeliveryPerson = selectedDeliveryPerson;
        this.isSearchDialogOpen = false;
        this.orderByGeosIdProximity = orderByGeosIdProximity;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await DeliveryPersonService.get(1, 20, this.filter, this.orderByGeosIdProximity);
        if (response.success) {
            runInAction(() => {
                this.elements = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        this.isSearchDialogOpen = value;
        if (this.isSearchDialogOpen) {
            this.search();
        }
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public setSelectedDeliveryPerson(deliveryPerson?: DeliveryPerson) {
        this.selectedDeliveryPerson = deliveryPerson;
        this.isSearchDialogOpen = false;
    }

}

export default DeliveryPersonSelectStore;