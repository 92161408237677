import React from "react";
import NewCustomCategoryButtonProperties from "./NewCustomCategoryButtonProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useStyles} from "./NewCustomCategoryButton.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import NewCustomCategoryButtonStore from "./NewCustomCategoryButtonStore";
import useOnEnterPress from "../../../hooks/EnterPress";
import Icon from "../../../ui_components/Icon/Icon";
import IconName from "../../../ui_components/Icon/enums/IconName";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Button from "../../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import Modal from "../../../ui_components/Modal/Modal";
import CustomCategoryNameInput from "../../Inputs/CustomCategory/CustomCategoryNameInput/CustomCategoryNameInput";
import CustomCategoryDescriptionInput
    from "../../Inputs/CustomCategory/CustomCategoryDescriptionInput/CustomCategoryDescriptionInput";
import CustomCategoryType from "../../../entities/CustomCategoryType";
import CustomCategoryTypeSelect from "../../Selects/CustomCategoryTypeSelect/CustomCategoryTypeSelect";

const NewCustomCategoryButton:React.FC<NewCustomCategoryButtonProperties> = ({
                                                                                 onClick = () => {},
                                                                                 onSave = () => {},
                                                                                 showIcon = true,
                                                                                 showText = true,
                                                                                 disabled,
                                                                                 className = '',
                                                                                 rounded = false,
                                                                                 size = ButtonSize.DEFAULT,
                                                                                 type = ButtonType.DEFAULT,
                                                                                 customButton = null,
                                                                                 customCategoryId
}) => {

    const classes = useStyles(showText);
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewCustomCategoryButtonStore(commonContext,customCategoryId)

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => {
        store.customCategory.name = newName;
    };

    const onDescriptionChange = (newDescription: string) => {
        store.customCategory.description = newDescription;
    };

    const onTypeChange = (newType:CustomCategoryType) => {
        store.customCategory.categoryType = newType
    }

    const onCustomCategorySave = async () => {
        const saved = await store.save()
        if(saved) {
            store.closeModal()
            if (onSave){
                onSave(store.customCategory)
            }
        }
    }
    useOnEnterPress(onCustomCategorySave,[store])

    const text = i18n.translate((store.isEditing ? "EDIT_CUSTOM_CATEGORY" : "NEW_CUSTOM_CATEGORY"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;

    const Btn = () => {
        if (customButton !=null){
            return <div className={classes.custom_button_container} onClick={handleOnclick}>{customButton}</div>;
        }
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }

    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <CustomCategoryNameInput
                            error={i18n.translate(store.nameError)}
                            fullWidth
                            className={classes.input}
                            required = {true}
                            value={store.customCategory?.name}
                            onChange={onNameChange}/>
                        <CustomCategoryDescriptionInput
                            className={classes.input}
                            fullWidth
                            value={store.customCategory?.description}
                            onChange={onDescriptionChange}/>
                        <CustomCategoryTypeSelect
                            className={classes.input}
                            fullWidth
                            selected={store.customCategory.categoryType}
                            onChange={onTypeChange}/>
                        <div className={classes.btn_container}>
                            <Button
                                disabled={!store.enableButton}
                                loading={store.loading} className={classes.input} onClick={onCustomCategorySave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}/>
                            <Button
                                loading={store.loading} className={classes.cancel_btn} onClick={onCloseModal}
                                type={ButtonType.DEFAULT}
                                text={i18n.translate("CANCEL")}/>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}
export default NewCustomCategoryButton