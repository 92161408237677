import React from 'react';
import RemittanceTypeSelectProperties from "./RemittanceTypeSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import SelectOption from "../../../ui_components/Select/SelectOption";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Select from "../../../ui_components/Select/Select";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const RemittanceTypeSelect: React.FC<RemittanceTypeSelectProperties> = ({
                                                                        selected,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {

    const i18n = useI18nContext();

    const opt = [
        new SelectOption(i18n.translate("ALL_MALE"), "ALL", 'Todas los tipos', CheckBoxIcon),
        new SelectOption("Entrega", "DELIVERY", "Entrega de dinero en manos", DeliveryDiningIcon),
        new SelectOption("Tarjeta", "CARD", "Envío a tarjeta", CreditCardIcon)
    ]

    const selectedOption = opt.find(t => t.value === selected);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value)
    }

    return (
        <Select
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : "Tipo"}
            placeholder={"Seleccione el tipo de envío"}
            selected={selectedOption}
            icon={AttachMoneyIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default RemittanceTypeSelect;