import InventoryItemNameInputProperties from "./InventoryItemNameInputProperties";
import React, {useEffect, useState} from "react";
import {useI18nContext} from "../../../../contexts/I18nContext";
import TextInput from "../../../../ui_components/TextInput/TextInput";
import CategoryIcon from "@mui/icons-material/Category";


const InventoryItemNameInput:React.FC<InventoryItemNameInputProperties> = ({
                                                                                 value,
                                                                                 required = false,
                                                                                 placeholder,
                                                                                 onChange,
                                                                                 disabled,
                                                                                 fullWidth = false,
                                                                                 className = '',
                                                                                 error
                                                                             }) => {

    const [name,setName] = useState(value)
    const i18n = useI18nContext()

    useEffect(() => {
        setName(value)
    }, [value]);

    const onNameChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newName = event.target.value
        setName(newName)
        onChange(newName)
    }

    return (
        <TextInput
            error={error}
            label={i18n.translate("INVENTORY_ITEM_NAME") + `${required ? '*' : ''}`}
            value={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onNameChange}
            startIcon={CategoryIcon}
            className={className}
            fullWidth={fullWidth}/>
    )
}
export default InventoryItemNameInput