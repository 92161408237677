import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import TransactionStatus from "../entities/TransactionStatus";
import ArticleTransaction from "../entities/ArticleTransaction";

export module ArticleTransactionService {
    export async function get(
        entityId: string,
        page: number = 1,
        pageSize: number = 15,
        from?: number,
        to?: number,
        status?: TransactionStatus,
        articleId?: string
    ): Promise<Response<PagedResponse<ArticleTransaction>>> {
        const url = Config.get("GET_ARTICLE_TRANSACTION_URL", {page, pageSize, id: entityId, from, to, status, articleId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const transactionData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (transactionData && paginationData) {
                const data = transactionData.map((t: any) =>
                    new ArticleTransaction(
                        t.id,
                        t.amount,
                        t.articleId,
                        t.articleName,
                        t.direction,
                        t.status,
                        t.createdAt,
                        t.balanceToBefore,
                        t.comment,
                        t.error
                    ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ArticleTransaction>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ArticleTransaction>>(false, undefined, response.error, response.status);
    }

}