import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
        weight_section_container: {
            width: 'fit-content',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 16,
            marginLeft: 'auto',
            marginTop: '8px',
            borderRadius: 4,
            padding: '10px 10px',
            border: `1px solid ${theme.palette.grey[400]}`,
            background: '#F3DEBA'
            },         
    }))
export {useStyles}