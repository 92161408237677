import React from 'react';
import ShowDifficultPersonIndicatorProperties from "./ShowDifficultPersonIndicatorProperties";
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import clsx from 'clsx';
import { useStyles } from './ShowDifficultPersonIndicator.style';

const ShowDifficultPersonIndicator: React.FC<ShowDifficultPersonIndicatorProperties> = ({
                                                          difficultPerson,
                                                          className = ''
                                                      }) => {
                                                        
    const classes = useStyles();
    
    return (
        <div className={clsx(className,classes.icon_container)}>
            {difficultPerson && <WbTwilightIcon/>}
        </div>
    )
}
export default ShowDifficultPersonIndicator;