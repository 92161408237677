import {action, makeAutoObservable} from "mobx";
import Article from "../../../entities/Article";
import CommonStore from "../../../stores/CommonStore";
import { ArticleService } from "../../../service/articleDelivery/ArticleService";


class RemoveArticleStore {

    public article: Article;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, article: Article) {
        this.article = article;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ArticleService.remove(this.article);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveArticleStore;