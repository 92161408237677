import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    full_width: {
        width: '100%'
    },
    date_select_container: {
        width: '100%',
        marginTop: '16px'
    }
}));

export {useStyles}