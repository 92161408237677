import React from 'react';
import {useStyles} from "../ShopProducts.style";
import clsx from "clsx";
import ShopProductRowProperties from "./ShopProductRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";


const ShopProductRowSmall: React.FC<ShopProductRowProperties> = ({shopProduct, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.shop_product_info_container_small)}>
            <ShowSellerBasicInfo autofill name={shopProduct.name} email={shopProduct.description}/>
            <div className={classes.menu_container_small}>
                {/* <NewShopProductButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    articleId={article.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                /> */}
            </div>
        </div>
    )
}

export default ShopProductRowSmall;
