import {makeStyles} from "@material-ui/core/styles";

const useStyles: any = makeStyles((theme) => ({
    show_price_container: {
        display: 'flex',
        flexDirection: "column",
        lineHeight: '16px',
        alignSelf: "center"
    },
    number: {
        fontSize: '18px',
        marginLeft: '4px'
    },
    currency: {
        color: theme.palette.grey[600],
        fontSize: '11px',
        marginLeft: '8px'
    },
    positive: {
        color: theme.palette.success.light
    },
    text_input: {
        marginTop: '5px'
    },
    input: {
        marginTop: '16px'
    },
    edit_icon: {
        fontSize: '16px !important',
        color: theme.palette.grey[900],
        marginLeft: '4px',
        cursor: 'pointer'
    },
    hint: {
        marginTop: '10px'
    }
}));

export {useStyles}