const values = {
    "ARTICLE_PRICES": "Precios del artículo",
    "ARTICLE_PRICE": "Precio del artículo",
    "ARTICLE_PRICE_FILTER_PLACEHOLDER": "Geo, moneda",
    "NO_ARTICLE_PRICES_TITLE": "No hay precios!!!",
    "NO_ARTICLE_PRICES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "EDIT_ARTICLE_PRICE": "Editar precio",
    "NEW_ARTICLE_PRICE": "Nuevo precio",
    "REMOVE_ARTICLE_PRICE": "Eliminar precio del artículo",
    "REMOVE_ARTICLE_PRICE_CONFIRMATION": "¿Está seguro que desea eliminar el precio del artículo?",
    "REMOVE_ARTICLE_PRICE_CONFIRMATION_TITLE": "Confirmación"
}

export default values;