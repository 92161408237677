import React from 'react';
import {useStyles} from "./Group.style";
import GroupProperties from "./GroupProperties";
import Direction from "../common/enums/Direction";
import Width from "../common/enums/Width";
import FlexContainer from "../FlexContainer/FlexContainer";
import Alignment from "../common/enums/Alignment";
import clsx from "clsx";


const Group: React.FC<GroupProperties> = ({
                                              title = '',
                                              className = '',
                                              direction = Direction.ROW,
                                              width = Width.FIT_CONTENT,
                                              alignItems = Alignment.CENTER,
                                              justifyContent = Alignment.CENTER,
                                              disabled = false,
                                              children
                                          }) => {
    const classes = useStyles();
    return (
        <FlexContainer
            className={clsx(classes.group_container, className)}
            width={width}
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}>
            {disabled && <div className={classes.disabled}/>}
            <div className={classes.title}>{title}</div>
            {children}
        </FlexContainer>
    )
}
export default Group;