import React from 'react';
import MoneyExchangeRowProperties from "./MoneyExchangeRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import MoneyExchangeRowMedium from "./MoneyExchangeRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MoneyExchangeRowSmall from "./MoneyExchangeRowSmall";


const MoneyExchangeRow: React.FC<MoneyExchangeRowProperties> = ({
                                                    moneyExchange,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <MoneyExchangeRowSmall onUpdate={onUpdate} moneyExchange={moneyExchange}/>
            </ViewSmall>
            <ViewMedium>
                <MoneyExchangeRowMedium onUpdate={onUpdate} moneyExchange={moneyExchange} />
            </ViewMedium>
            <ViewLarge>
                <MoneyExchangeRowMedium onUpdate={onUpdate} moneyExchange={moneyExchange} />
            </ViewLarge>
        </>
    )
}

export default MoneyExchangeRow;
