import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    home_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    home_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    carousel_content_container: {
        height: '400px',
    },
    carousel_content_container_inside: {
        display: 'flex',
        justifyContent: 'center'
    },
    carousel_img: {
        position: 'absolute'
    },
    carousel_text: {
        position: 'absolute',
        zIndex: 99999,
        color: theme.palette.common.white,
        textAlign: 'center',
        bottom: '25px'
    },
    carousel_title: {
        background: 'rgba(0, 0, 0, 0.7)',
        padding: '5px',
        margin: '8px'
    },
    carousel_description: {
        background: 'rgba(0, 0, 0, 0.7)',
        padding: '5px'
    },
    time_shorcuts_container: {
        display: 'flex',
        gap: 10,
        marginTop: '15px'
    },
    full_height: {
        height: '100%'
    },
    full_width: {
        width: '100%'
    }
}));

export {useStyles}