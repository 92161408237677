import React from 'react';
import {useStyles} from "./ShowBalances.style";
import ShowBalancesProperties from "./ShowBalancesProperties";
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";
import {CurrencyModule} from "../../../util/Currency";
import Currency from '../../../entities/Currency';
import { Tooltip } from '@material-ui/core';

const ShowBalances: React.FC<ShowBalancesProperties> = ({
                                                            className = '',
                                                            balances,
                                                            activeMoneyDeliveryBalances = [],
                                                            label
                                                        }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const currencies = [Currency.MN, Currency.DOLLAR, Currency.MLC];
    
    const getBalances = () => {
        return (
            <div className={classes.balances_container}>
                {currencies.map((currency: Currency, i) => {
                    const balance = balances.find(b => b.currency === currency);
                    const activeMoneyDeliveryBalance = activeMoneyDeliveryBalances.find(b => b.currency === currency);

                    const progress = activeMoneyDeliveryBalance?.amount;
                    const total = balance ? balance.amount : 0;

                    let progressBarStyle;
                    let alertText = '';

                    if (progress) {
                        const percentage = (progress / total) * 100;

                        let backgroundColor;
                        if (percentage >= 100) {
                            backgroundColor = '#EF5350';
                            alertText = `. Saldo insuficiente para entregar los envios asignados. Faltante: $ ${progress - total}`
                        } else if (percentage >= 80) {
                            backgroundColor = '#FFA726';
                            alertText = '. Saldo con valor cercano a la suma a entrear de los envios activos.'
                        } else {
                            backgroundColor = '#4caf50';
                        }
    
                        progressBarStyle = {
                            width: `calc((${progress} / ${total}) * 100%)`,
                            backgroundColor
                        };
                    }
                    

                    return (
                        <div key={i} className={clsx(classes.balance,i < currencies.length - 1 && classes.border)}>
                            <div className={clsx(
                                !balance && classes.positive,
                                balance && balance.amount >= 0 && classes.positive,
                                balance && balance.amount < 0 && classes.negative
                            )}>{CurrencyModule.format(balance ? balance.amount : 0, currency)}</div>
                            <div className={classes.hint}>{i18n.translate(currency)}</div>
                            {activeMoneyDeliveryBalance &&
                            <Tooltip title={"Saldo por entregar" + alertText}>
                                <div className={classes.progress_bar}>
                                    <div className={classes.progress} style={progressBarStyle && progressBarStyle}>
                                        <span className={classes.active_balance}>
                                            {CurrencyModule.format(progress ?? 0, Currency.DOLLAR)}
                                        </span>
                                    </div>
                                </div>
                            </Tooltip>
                            }
                        </div>
                    );
                })}
            </div>);
    }

    return (
        <div className={classes.all_container}>
            {label && <div className={classes.label}>{`${label}:`}</div>}
            <div className={clsx(classes.show_balances_container, className)}>
                {getBalances()}
            </div>
        </div>
    )
}
export default ShowBalances;