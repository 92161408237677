import Currency from "../../entities/Currency";

class AddBalanceRequest {

    public to: string;
    public amount: number;
    public currency: Currency;
    public comment?: string;

    constructor(to: string, amount: number, currency: Currency, comment?: string) {
        this.to = to;
        this.comment = comment;
        this.amount = amount;
        this.currency = currency;
    }

}

export default AddBalanceRequest;