import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import {DeliveryCostService} from "../../../service/DeliveryCostService";
import DeliveryCost from "../../../entities/DeliveryCost";
import Currency from "../../../entities/Currency";
import Response from "../../../model/Response";

class NewDeliveryCostStore {

    public deliveryCostId?: string;
    public deliveryPersonId: string;
    public cost: DeliveryCost;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public withoutCost: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, deliveryPersonId: string, deliveryCostId?: string) {
        this.isEditing = !!deliveryCostId;
        this.deliveryCostId = deliveryCostId;
        this.deliveryPersonId = deliveryPersonId;
        this.isModalOpen = false;
        this.cost = { currency: Currency.DOLLAR } as DeliveryCost;
        this.loading = false;
        this.withoutCost = true;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        if (this.withoutCost) return !!this.cost?.geo;
        return !!(this.cost?.currency && this.cost?.geo && this.cost?.cost);
    }

    @action
    public async loadDeliveryCost() {
        if (this.deliveryCostId) {
            this.isEditing = true;
            const response = await DeliveryCostService.find(this.deliveryCostId)
            if (response.success && response.data) {
                this.cost = response.data;
                this.withoutCost = this.cost.cost === 0;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        await this.loadDeliveryCost();
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        if (this.withoutCost) {
            this.cost.cost = 0;
            this.cost.currency = Currency.DOLLAR;
        }
        let response: Response<any>;
        if (this.isEditing) {
            response = await DeliveryCostService.update(this.cost);
        } else {
            response = await DeliveryCostService.create(this.cost, this.deliveryPersonId);
        }
        this.loading = false;
        return response.success;
    }
}

export default NewDeliveryCostStore;