import React, {useState} from 'react';
import {Observer} from "mobx-react-lite";
import {useStyles} from "./SingleTopup.style";
import DoTopupProperties from "../DoTopupProperties";
import ProductSelect from "../../../Selects/ProductSelect/ProductSelect";
import CommentInput from "../../../Inputs/CommentInput/CommentInput";
import Button from "../../../../ui_components/Button/Button";
import ButtonType from "../../../../ui_components/Button/ButtonType";
import {useI18nContext} from "../../../../contexts/I18nContext";
import TopupToDo from "../../../../model/topup/TopupToDo";
import Product from "../../../../entities/productOrder/topup/Product";
import DoTopupRow from "../DoTopupRow/DoTopupRow";
import MessageCard from "../../../../ui_components/MessageCard/MessageCard";
import BallotIcon from '@material-ui/icons/Ballot';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ClientContact from '../../../../entities/ClientContact';
import ClientContactSelect from "../../../Selects/ClientContactSelect/ClientContactSelect";
import Alert from "../../../../ui_components/Alert/Alert";
import AlertType from "../../../../ui_components/Alert/AlertType";
import PhoneInput from "../../../Inputs/PhoneInput/PhoneInput";
import CountryCode from "../../../../entities/CountryCode";
import Phone from "../../../../entities/Phone";


const SingleTopup: React.FC<DoTopupProperties> = ({
                                                      store
                                                  }) => {

    const classes = useStyles();
    const i18n = useI18nContext();
    const [clientContact, setClientContact] = useState<ClientContact>();
    const [comment, setComment] = useState("");
    const [product, setProduct] = useState<Product>();
    const [anonymousPhone, setAnonymousPhone] = useState<Phone>();

    const onAnonymousPhoneChange = (phone: Phone) => {
        setAnonymousPhone(phone);
        setClientContact(undefined);
    }

    const onContactSelected = (contact?: ClientContact) => {
        setClientContact(contact);
        setAnonymousPhone(undefined);
    }
    const addTopup = async () => {
        if (product && (clientContact || anonymousPhone?.isValid)) {
            await store.addDoTopup(product, comment, clientContact, anonymousPhone);
            setClientContact(undefined);
            setAnonymousPhone(undefined);
        }
    }

    const onDeleteDoTopup = async (topup: TopupToDo) => {
        await store.removeDoTopup(topup);
    }

    return <Observer>
        {() => (
            <div className={classes.single_topup_container}>
                <div className={classes.form_container}>
                    <div className={classes.contact_container}>
                        <PhoneInput
                            error={store.anonymousPhone}
                            value={anonymousPhone}
                            label={"Número a recargar"}
                            onChange={onAnonymousPhoneChange}
                            countryCode={CountryCode.CUBA}>
                        </PhoneInput>
                        <div className={classes.description}>o seleccione un contacto</div>
                        <ClientContactSelect selectedContact={clientContact} modal agencyId={store.selectedAgency?.id} onClientChange={() => {
                        }} onContactChange={onContactSelected}/>
                    </div>
                    <ProductSelect onChange={(p) => setProduct(p)} selected={product}/>
                    <CommentInput className={classes.comment} onChange={(c) => setComment(c)}/>
                    <Button
                        startIcon={<ArrowDownwardIcon/>}
                        disabled={(!product?.id  || (!clientContact && !anonymousPhone?.isValid))}
                        type={ButtonType.SECONDARY}
                        className={classes.save_btn}
                        loading={store.validating}
                        onClick={addTopup}
                        text={i18n.translate("ADD")}/>
                </div>
                <div className={classes.rows_container}>
                    {store.topups.map((t, i) => (
                        <DoTopupRow onDelete={onDeleteDoTopup} doTopup={t} key={i} index={i + 1}/>
                    ))}
                    {store.errorKey && <Alert text={i18n.translate(store.errorKey)} type={AlertType.DANGER}/>}
                    {store.isEmpty &&
                        <MessageCard
                            className={classes.empty_list}
                            icon={BallotIcon}
                            title={i18n.translate("NO_DO_TOPUP_TITLE")}
                            subtitle={i18n.translate("NO_DO_TOPUP_SUBTITLE")}
                        />
                    }
                </div>
            </div>
        )}
    </Observer>
}

export default SingleTopup;
