import React from 'react';
import {useStyles} from "../MiscellanyOrder.style";
import clsx from "clsx";
import MiscellanyOrderRowProperties from "./MiscellanyOrderRowProperties";
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import { motion } from 'framer-motion';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';


const MiscellanyOrderRowSmall: React.FC<MiscellanyOrderRowProperties> = ({
                                                                                    miscellanyOrder,
                                                                                    onMiscellanyOrderSelect,
                                                                                    onUpdate
                                                                                }) => {

    const classes = useStyles();

    const onInternalMiscellanyOrderSelect = () => {
        onMiscellanyOrderSelect(miscellanyOrder.id);
    }

    return (
        <div className={clsx(classes.article_info_container_small)}>
            <div className={classes.first_row_small}>
                <div>
                    <div className={classes.order_number_container}>
                        <Checkbox text={''} onChange={onInternalMiscellanyOrderSelect}/>
                        <ShowOrderNumber orderNumber={miscellanyOrder.orderNumber}/>
                    </div>
                    <ShowDate label={'Fecha'} timestamp={miscellanyOrder.createdAt} className={classes.margin_top_4}/>
                </div>
            </div>
            <div className={classes.second_row_small}>
                <div>
                    <ShowText text={miscellanyOrder.agencyName} title={'Agencia'}/>
                    <ShowText text={miscellanyOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
                </div>
                <div className={classes.status_container}>
                    <ShowOrderStatus status={miscellanyOrder.status}/>
                    {miscellanyOrder.status !== ProductOrderStatus.DELIVERED &&
                        miscellanyOrder.status !== ProductOrderStatus.CANCELED &&
                        <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={miscellanyOrder.id}/>}
                    {miscellanyOrder.status === ProductOrderStatus.DELIVERED &&
                        <ProductOrderStatusHistoryButton
                            detailsLink
                            productOrderId={miscellanyOrder.id}
                        />}
                </div>
            </div>
            <div className={classes.menu_container_small}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={miscellanyOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
            </div>
            <div className={clsx(classes.menu_container_small,classes.margin_top_n10px)}>
                {/* <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ObservationsButton
                        size={ButtonSize.SMALL}
                        deliveryOrderId={miscellanyOrder.id}
                        deliveryOrderNumber={miscellanyOrder.orderNumber}
                        deliveryOrderObservations={miscellanyOrder.observations}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div> */}
                <AllowAccess isRootAgency={true}>
                    <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderId={miscellanyOrder.id}
                            deliveryOrderNumber={miscellanyOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </motion.div>
                </AllowAccess>
            </div>
            <div className={clsx(classes.menu_container_small,classes.margin_top_n10px)}>
                {/* <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <RemoveMiscellanyOrderButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        miscellanyOrderId={miscellanyOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div> */}
            </div>
        </div>
    )
}

export default MiscellanyOrderRowSmall;
