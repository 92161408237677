import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Agency from "../../../entities/Agency";
import {AgencyService} from "../../../service/AgencyService";
import {SellerService} from "../../../service/SellerService";
import Seller from "../../../entities/Seller";


class AgencySellerSelectStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;
    public agencies: Agency[];
    public sellers: Seller[];
    public currentAgencyId: String;
    public currentSellerId: String;


    constructor(commonStore: CommonStore, currentAgencyId: String, currentSellerId: String, selectedAgency?: Agency, selectedSeller?: Seller) {
        this.loading = false;
        this.agencies = [];
        this.sellers = [];
        this.commonStore = commonStore;
        this.currentAgencyId = currentAgencyId;
        this.currentSellerId = currentSellerId;
        this.selectedAgency = selectedAgency;
        this.selectedSeller = selectedSeller;
        makeAutoObservable(this);
        this.searchAgencies();
    }

    @action
    public async searchAgencies(): Promise<void> {
        this.loading = true;
        const response = await AgencyService.get(1, 1000, '', true, true);
        if (response.success) {
            this.agencies = response.data?.data || [];
        }
        this.commonStore.processErrors(response);
        if (!this.selectedAgency) {
            this.selectedAgency = this.agencies.find(t => t.id === this.currentAgencyId)
        }
        await this.searchSellers();
        this.loading = false;
    }

    @action
    public async searchSellers(): Promise<void> {
        this.loading = true;
        const response = await SellerService.get(1, 20, '', true, this.selectedAgency?.id);
        if (response.success) {
            this.sellers = response.data?.data || [];
            if (this.sellers.length === 1) {
                this.selectedSeller = this.sellers[0];
            }
        }
        this.commonStore.processErrors(response);
        if (!this.selectedSeller) {
            this.selectedSeller = this.sellers.find(t => t.id === this.currentSellerId)
        }
        this.loading = false;
    }

    public async setSelectedAgency(agencyId?: String) {
        this.selectedAgency = this.agencies.find(a => a.id === agencyId);
        this.selectedSeller = undefined;
        await this.searchSellers();
    }

    public async setSelectedSeller(sellerId?: String) {
        this.selectedSeller = this.sellers.find(a => a.id === sellerId);
    }
}

export default AgencySellerSelectStore;