import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    body_container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '80vw'
    },
    header_container: {
        backgroundColor: theme.palette.grey[300]
    }

}));

export {useStyles}