import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    phone_container: {
        display: 'flex',
        flexDirection: 'column'
    },
    show_phone_number_container: {
        display: 'flex'
    },
    icon:{
        color: theme.palette.secondary.dark,
        fill: theme.palette.secondary.dark
    },
    number:{
        fontSize: '14px',
        marginLeft: '4px',
        color: theme.palette.secondary.dark
    },
    label: {
        fontSize: '13px',
        fontWeight: 600,
        color: theme.palette.secondary.light
    }
}));

export {useStyles}