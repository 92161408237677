import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        minWidth: '300px',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    input: {
        marginTop: '16px'
    },
    margin_right_5: {
        marginRight: "5px"
    },
    payment_fee_row_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        border: `1px solid #cccccc61`,
        borderRadius: 4,
        padding: '4px 8px'
    },
    payment_type: {
        fontSize: 16,
        marginBottom: 8,
        justifyContent: 'end',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        "& svg": {
            fontSize: 20,
            marginRight: 4,
            color: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
        }
    },
    currency_value_container: {
        display: 'flex',
        marginTop: '16px',
        gap: '10px'
    }
}));

export {useStyles}