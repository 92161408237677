import React from 'react';
import RemoveDeliveryPersonButtonProperties from "./RemoveDeliveryPersonButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import RemoveDeliveryPersonStore from "./RemoveDeliveryPersonStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useCommonContext} from "../../../contexts/CommonContext";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";


const RemoveDeliveryPersonButton: React.FC<RemoveDeliveryPersonButtonProperties> = ({
                                                                                        onClick = () => {
                                                                                        },
                                                                                        onSave = () => {
                                                                                        },
                                                                                        showIcon = true,
                                                                                        showText = true,
                                                                                        disabled,
                                                                                        className = '',
                                                                                        deliveryPerson,
                                                                                        rounded = false,
                                                                                        size = ButtonSize.DEFAULT,
                                                                                        type = ButtonType.DEFAULT
                                                                                    }) => {

    const i18n = useI18nContext();
    const confirm = useConfirmation();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new RemoveDeliveryPersonStore(commonContext, deliveryPerson);

    const handleOnclick = async () => {
        onClick();
        const ok = await confirm(`${deliveryPerson.name}`, i18n.translate("REMOVE_DELIVERY_PERSON_CONFIRMATION"));
        if (ok) {
            await store.remove();
            onSave();
        }
    }

    const text = i18n.translate("REMOVE_DELIVERY_PERSON");

    if (!userStore.hasAccess(Access.CREATE_DELIVERY_PERSON.valueOf())) {
        return <></>;
    }

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default RemoveDeliveryPersonButton;
