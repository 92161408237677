import React from 'react';
import {Breakpoint} from 'react-socks';
import {useStyles} from "./MediaView.style";
import clsx from "clsx";
import MediaViewProperties from "./MediaViewProperties";


const ViewMedium: React.FC<MediaViewProperties> = ({
                                                       fullHeight = false,
                                                       fullWidth = false,
                                                       children
                                                   }) => {
    const classes = useStyles();
    const containerClasses = clsx(fullHeight && classes.full_height, fullWidth && classes.full_width);
    return (
        <Breakpoint className={containerClasses} customQuery="(max-width: 1060px) and (min-width: 801px)">
            {children}
        </Breakpoint>
    )
}
export default ViewMedium;