import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_client_contact_container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
    },
    card: {
        display: 'flex',
        padding: '10px',
        borderRadius: '8px'
    },
    data_container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4px'
    },
    name:{
        fontSize: '18px',
        // color: theme.palette.secondary.light,
        borderBottom: '1px solid #ccc'
    },
    value: {
        display: 'flex',
        // color: theme.palette.grey[600],
        alignItems: 'center',
        "& > svg": {
            fontSize: '18px',
            marginRight: '4px'
        }
    },
    contact_container: {},
    client_container: {},
    hide_small: {
        "@media (max-width: 800px)": {
            display: 'none'
        }
    }
}));

export {useStyles};
