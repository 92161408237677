import React from 'react';
import ContactSelectProperties from "./ContactSelectProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ContactSelect.style";
import ClientContactSelect from "../../../Selects/ClientContactSelect/ClientContactSelect";
import ClientContact from "../../../../entities/ClientContact";
import Client from "../../../../entities/Client";

const ContactSelect: React.FC<ContactSelectProperties> = ({
                                                              store
                                                          }) => {
    const classes = useStyles();

    const onContactChange = (contact?: ClientContact) => {
        store.setContact(contact);
    }

    const onClientChange = (client?: Client) => {
        store.setClient(client);
    }

    const onObservationsChange = (observations?: string) => {
        store.setObservations(observations);
    }
    
    return <Observer>
        {() => (
            <div className={classes.container}>
                <ClientContactSelect
                    fullWidth
                    agencyId={store.selectedAgency?.id}
                    showObservationsInput={true}
                    selectedClient={store.selectedClient}
                    selectedContact={store.selectedContact}
                    selectedObservations={store.observations}
                    onClientChange={onClientChange}
                    onContactChange={onContactChange}
                    onObservationsChange={onObservationsChange}/>
            </div>
        )}
    </Observer>
}

export default ContactSelect;
