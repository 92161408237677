import CustomCategoryType from "../../../entities/CustomCategoryType";

class CategoryInfo {

    public id?:string
    public name?: string
    public categoryType? : CustomCategoryType = CustomCategoryType.GROUP


    constructor(id?:string,name?: string,customCategoryType? : CustomCategoryType) {
        this.id = id
        this.name = name
        this.categoryType = customCategoryType
    }
}
export default CategoryInfo