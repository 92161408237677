class DeliveryOrderCommentResponse {
    public id: string;
    public userId: string;
    public userName: string;
    public comment: string;
    public createdAt: number;
    
    constructor(
        id: string,
        userId: string,
        userName: string,
        comment: string,
        createdAt: number) {
        this.id = id;
        this.userId = userId;
        this.userName = userName;
        this.comment = comment;
        this.createdAt = createdAt;
    }

}

export default DeliveryOrderCommentResponse;