import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    do_topup_row_container: {
        display: 'grid',
        gridTemplateColumns: "30px 150px 150px 130px 130px auto 80px",
        columnGap: '32px',
        border: `1px dotted ${theme.palette.secondary.light}`,
        padding: '16px',
        borderRadius: '4px',
        alignItems: 'start',
        backgroundColor: theme.palette.grey[200],
        margin: '8px',
        "&:hover": {
            backgroundColor: theme.palette.grey[300],
        },
        "@media (max-width: 800px)": {
            display: 'flex'
        }
    },
    index_container: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center'
    },
    check_btn: {
        marginTop: '4px',
        cursor: 'pointer',
        "& svg": {
            fontSize: '30px'
        }
    },
    checked: {
        color: theme.palette.success.light
    },
    index: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '25px',
        height: '25px'
    },
    tools_container: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    },
    tool: {
        position: 'absolute',
        right: '-10px',
        top: '-10px',
        color: theme.palette.common.white,
        borderRadius: '4px',
        padding: '4px',
        fontSize: '14px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.light,
        "& svg": {
            fontSize: '15px',
        },
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    hide_small: {
        "@media (max-width: 800px)": {
            display: 'none'
        }
    }
}));

export {useStyles}