import React from 'react';
import ArticleDeliveryResumeProperties from "./ArticleDeliveryResumeProperties";
import {Observer} from "mobx-react-lite";
import { useStyles } from './ArticleDeliveryResume.style';
import ShowInvoiceAdditionalPrices from '../../../Info/ShowInvoiceAdditionalPrices/ShowInvoiceAdditionalPrices';
import ShowClientToContactInfo from '../../../Info/ShowClientToContactInfo/ShowClientToContactInfo';
import PaymentsTable from '../../../Info/PaymentsTable/PaymentsTable';
import ShowArticleDeliveryDetails from '../../../Info/ShowArticleDeliveryDetails/ShowArticleDeliveryDetails';
import OrderAdditionalPrice from '../../../../entities/OrderAdditionalPriceRequest';

const ArticleDeliveryResume: React.FC<ArticleDeliveryResumeProperties> = ({
                                                                    store
                                                                }) => {

    const classes = useStyles();

    

    return <Observer>
        {() => (
            <div className={classes.container}>
                {store.selectedContact && <ShowClientToContactInfo
                    client={store.selectedClient}
                    contact={store.selectedContact}/>}
                
                {store.selectedArticles.length > 0 &&
                    <ShowArticleDeliveryDetails articles={store.selectedArticles} selectedArticlesPrices={store.selectedArticlesPrices}/>    
                }

                {store.additionalPrices.length > 0 &&
                    <ShowInvoiceAdditionalPrices additionalPrices={store.additionalPrices.map((p) => {
                        return new OrderAdditionalPrice(p.amount, p.comment, p.isPercentage)
                    })}></ShowInvoiceAdditionalPrices>}

                {store.payments.length > 0 &&
                    <PaymentsTable payments={store.payments}/>}
                
            </div>
        )}
    </Observer>
}

export default ArticleDeliveryResume;
