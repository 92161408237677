import React from 'react';
import {useStyles} from "./ShowField.style";
import DisplayDateProperties from "./ShowFieldProperties";
import clsx from "clsx";
import {Tooltip} from "@material-ui/core";


const ShowField: React.FC<DisplayDateProperties> = ({
                                                        value,
                                                        className = '',
                                                        icon: Icon,
                                                        tooltip,
                                                        label,
                                                        children
                                                    }) => {
    const classes = useStyles();
    if (!value) return <></>

    return (
        <Tooltip title={tooltip || ""}>
            <div className={clsx(classes.display_field_container, className)}>
                {label && <div className={classes.label}>{`${label}:`}</div>}
                <div className={classes.value}>{Icon && <Icon/>}{value}</div>
                {children}
            </div>
        </Tooltip>
    )
}
export default ShowField;