import {useStyles} from './ElementRow.style';
import React from "react";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ElementRowProperties from "./ElementRowProperties";
import clsx from "clsx";

const DeliveryStatusElementRow: React.FC<ElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();
    
    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <AccountBoxSharpIcon/>
            </div>

            <div className={classes.text_container}>
                <div><b>{element}</b></div>
            </div>

        </div>
    )
}


export default DeliveryStatusElementRow;
