import React from 'react';
import ShowHouseMarkerProperties from './ShowHouseMarkerProperties';
import HouseIcon from '../../../../ui_components/Icon/icons/HouseIcon';

const ShowHouseMarker: React.FC<ShowHouseMarkerProperties> = ({ className }) => {
   
  return (
    <div style={{
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute'
    }} className={className}>
      <HouseIcon/>
    </div>
  );
};

export default ShowHouseMarker;