import React from 'react';
import ShowDeliveryPersonMarkerProperties from './ShowDeliveryPersonMarkerProperties';
import DeliveryPersonIcon from '../../../../ui_components/Icon/icons/DeliveryPersonIcon';

const ShowDeliveryPersonMarker: React.FC<ShowDeliveryPersonMarkerProperties> = () => {
   
  return (
    <div style={{
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute'
    }}>
      <DeliveryPersonIcon/>
    </div>
  );
};

export default ShowDeliveryPersonMarker;