import clsx from "clsx";
import { useI18nContext } from "../../../contexts/I18nContext";
import { useUserContext } from "../../../contexts/UserContext";
import { useStyles } from "../ReferenceItemPrice.style";
import ShowText from "../../../shared_components/Info/ShowText/ShowText";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import Access from "../../../shared_components/Access/AllowAccess/Access";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ReferenceItemPriceRowProperties from "./ReferenceItemPriceRowProperties";
import NewReferenceItemPriceButton from "../../../shared_components/Buttons/NewReferenceItemPriceButton/NewReferenceItemPriceButton";
import { CurrencyModule } from "../../../util/Currency";

const ReferenceItemPriceRowMedium: React.FC<ReferenceItemPriceRowProperties> = ({ 
                                                                                  referenceItemPriceResponse, 
                                                                                  className, 
                                                                                  filter, 
                                                                                  onUpdate 
}) => {
  const classes = useStyles();
  const i18n = useI18nContext();
  const userStore = useUserContext();

  return (
    <div className={clsx(classes.reference_items_info_container)}>
            <ShowText
                title={i18n.translate("NAME")}
                text={referenceItemPriceResponse.referenceItemName}
                textToHighlight={filter}/>
            <ShowText
                title={i18n.translate("DESCRIPTION")}
                text={referenceItemPriceResponse.referenceItemDescription}
                textToHighlight={filter}/>
            <ShowText
                title={"Costo"}
                text={CurrencyModule.format(referenceItemPriceResponse.cost || 0)}/>
            <ShowText
                title={"Precio"}
                text={CurrencyModule.format(referenceItemPriceResponse.amount || 0)}/>
      {userStore.hasAccess(Access.CREATE_EDIT_REFERENCE_ITEM_PRICE) && (
        <ExpandoMenu>
          <>
            {userStore.hasAccess(Access.CREATE_EDIT_REFERENCE_ITEM_PRICE) && (
              <NewReferenceItemPriceButton
                type={ButtonType.SECONDARY}
                onSave={onUpdate}
                referenceItemId={referenceItemPriceResponse.referenceItemId}
                size={ButtonSize.SMALL}
                showText
              />
            )}
            {/*             <RemoveReferenceItemPriceButton
              type={ButtonType.SECONDARY}
              onSave={onUpdate}
              referenceItemPrice={referenceItemPrice}
              size={ButtonSize.SMALL}
            /> */}
          </>
        </ExpandoMenu>
      )}
    </div>
  );
};
export default ReferenceItemPriceRowMedium;
