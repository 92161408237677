import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../../../stores/CommonStore";
import ClientContact from "../../../../../entities/ClientContact";
import {ClientContactService} from "../../../../../service/ClientContactService";


class ContactSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public clientId?: string;
    public filter?: string;
    public contacts: ClientContact[];
    public selectedContact?: ClientContact;
    public showSearchPanel: boolean;
    public agencyId?: string;
    public observations?: string;

    constructor(commonStore: CommonStore, clientId?: string, selectedContact?: ClientContact, selectedObservations?: string, agencyId?: string) {
        this.loading = false;
        this.commonStore = commonStore;
        this.contacts = [];
        this.clientId = clientId;
        this.showSearchPanel = !selectedContact;
        this.selectedContact = selectedContact;
        this.observations = selectedObservations;
        this.agencyId = agencyId;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        if (!this.clientId) return;
        this.loading = true;
        const response = await ClientContactService.get(1, 20, this.clientId, this.filter, this.agencyId);
        if (response.success) {
            runInAction(() => {
                this.contacts = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    get isEmpty(): boolean {
        return this.contacts.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public setSelectedContact(contact?: ClientContact) {
        runInAction(() => {
            this.selectedContact = contact;
            this.closeSearchPanel();
        });
    }

    @action
    public setObservationsChange(observations?: string) {
        runInAction(() => {
            this.observations = observations;
        });
    }

    @action
    public openSearchPanel() {
        this.search()
        this.showSearchPanel = true;
        this.selectedContact = undefined;
    }

    @action
    public closeSearchPanel() {
        this.showSearchPanel = false;
    }

}

export default ContactSelectStore;