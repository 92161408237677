import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_error_container: {
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        display: 'flex',
    },
    label_container:{
        display: 'flex',
    },
    icon: {
        color: theme.palette.error.light,
        fontSize: '18px'
    },
    value_container: {
        color: theme.palette.error.light,
        fontSize: '14px',
        marginLeft: '4px'
    },
    label: {
        fontSize: '12px',
        color: theme.palette.error.light,
        fontWeight: 800,
        marginLeft: '4px'
    }
}));

export {useStyles}