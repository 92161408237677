import {useStyles} from './SearchClientPanel.style';
import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";
import {format} from "../../../../../util/PhoneFormatter";
import Client from "../../../../../entities/Client";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from "@mui/icons-material/Email";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {Observer} from "mobx-react-lite";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Loading from "../../../../../ui_components/PageLoading/Loading";
import ClientElementRow from "./row/ClientElementRow";
import SearchClientPanelProperties from "./SearchClientPanelProperties";
import {useI18nContext} from "../../../../../contexts/I18nContext";
import NewClientButton from "../../../../Buttons/NewClientButton/NewClientButton";
import ProfileImagePlaceholder from "../../../../../ui_components/Icon/ProfileImagePlaceholder";
import ClientSelectStore from "./ClientSelectStore";
import {useCommonContext} from "../../../../../contexts/CommonContext";
import ShowDifficultPersonIndicator from '../../../../Info/ShowDifficultPersonIndicator/ShowDifficultPersonIndicator';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from '@mui/icons-material/Home';

const SelectClientPanel: React.FC<SearchClientPanelProperties> = ({
                                                                      selectedClient,
                                                                      onlyClientSelection = false,
                                                                      onClientChange,
                                                                      agencyId
                                                                  }) => {
    const i18n = useI18nContext();
    const classes = useStyles();
    const commonContext = useCommonContext();
    const store = useMemo<ClientSelectStore>(() => new ClientSelectStore(commonContext, selectedClient, agencyId), [commonContext, selectedClient, agencyId])

    const inputRef = createRef<HTMLInputElement>();
    const [filter, setFilter] = useState("");

    const openClientSearch = async () => {
        await store.openSearchPanel();
        setFilter("");
        store.setFilter("");
    }

    const closeClientSearch = () => {
        store.closeSearchPanel();
        setFilter("");
    }

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFilter(value);
    }

    const selectClient = (client: Client) => {
        store.setSelectedClient(client);
        onClientChange(client);
    }

    const onNewClient = async (client: Client) => {
        store.setSelectedClient(client);
        onClientChange(client);
    }

    const onUpdateClient = async (client: Client) => {
        store.setSelectedClient(client);
        onClientChange(client);
    }

    useEffect(() => {
        if (store.showSearchPanel) {
            inputRef.current?.focus();
        }
    }, [store.showSearchPanel, inputRef])

    let timeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        const changeFilter = () => {
            store.setFilter(filter);
        };
        timeout.current = setTimeout(changeFilter, 800);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])


    const ClientInfo = () => {
        if (store.selectedClient) {
            return (
                <Observer>
                    {() => (
                        <div className={clsx(classes.btn_selected_container, classes.selected_client_btn, !onlyClientSelection && classes.maxWidth50Percent)}>
                            <div className={classes.buttons_container}>
                                <NewClientButton clientId={store.selectedClient?.id}
                                                 agencyId={store.agencyId}
                                                 customButton={<div className={clsx(classes.tool_btn)}>
                                                     <EditIcon/>
                                                     <span>{i18n.translate("EDIT_CLIENT")}</span>
                                                 </div>}
                                                 onSave={onUpdateClient}
                                />
                                <div className={clsx(classes.tool_btn)} onClick={openClientSearch}>
                                    <ManageSearchIcon/>
                                    <span>Buscar otro cliente</span>
                                </div>
                            </div>
                            <div className={classes.info_container}>
                                <div className={classes.header_container}>
                                    <h2>Remitente</h2>
                                </div>
                                <div className={clsx(classes.name_icon_container,classes.hide_small)}>
                                    <ProfileImagePlaceholder
                                        fontSize={40}
                                        name={store.selectedClient?.name}/>
                                </div>
                                <div className={classes.row_container}>
                                    <div>
                                        <strong>Nombre:</strong>
                                    </div>
                                    <div>
                                        <strong><span>{store.selectedClient?.name + ' ' + store.selectedClient?.lastName}</span></strong>
                                    </div>
                                </div>

                                {store.selectedClient?.email &&
                                    <div className={clsx(classes.row_container,classes.hide_small)}>
                                        <div className={classes.row_container_left}>
                                            <strong>Email:</strong>
                                        </div>
                                        <div className={classes.row_container_right}>
                                            <EmailIcon/>
                                            <div><strong><span>{store.selectedClient.email}</span></strong></div>
                                        </div>
                                    </div>}
                                {store.selectedClient?.phone &&
                                    <div className={classes.row_container}>
                                        <div className={classes.row_container_left}>
                                            <strong>Teléfono:</strong>
                                        </div>
                                        <div className={classes.row_container_right}>
                                            <ContactPhoneIcon/>
                                            <div><strong><span>{format(store.selectedClient?.phone)}</span></strong>
                                            </div>
                                        </div>
                                    </div>}

                                <div className={clsx(classes.row_container,classes.hide_small)}>
                                    <div className={classes.row_container_left}>
                                        <strong>Pasaporte/ID:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <div><strong><span>{store.selectedClient?.documentId}</span></strong></div>
                                    </div>
                                </div>

                                <div className={clsx(classes.row_container,classes.hide_small)}>
                                    <div className={classes.row_container_left}>
                                        <strong>Dirección:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <HomeIcon/>
                                        <div><strong><span>{store.selectedClient?.address}</span></strong></div>
                                    </div>
                                </div>

                                <div className={clsx(classes.row_container,classes.hide_small)}>
                                    <div className={classes.row_container_left}>
                                        <strong>Localización:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <LocationOnIcon/>
                                        <div>
                                            <strong><span>{store.selectedClient?.city}</span>. <span>{store.selectedClient?.state}</span>.
                                                ZIP Code: <span>{store.selectedClient?.zipCode}</span></strong></div>
                                    </div>
                                </div>

                                {store.selectedClient?.nacionality &&
                                    <div className={clsx(classes.row_container,classes.hide_small)}>
                                        <div className={classes.row_container_left}>
                                            <strong>Nacionalidad:</strong>
                                        </div>
                                        <div className={classes.row_container_right}>
                                            <div><strong><span>{store.selectedClient?.nacionality}</span></strong></div>
                                        </div>
                                    </div>}

                                {store.selectedClient?.observation &&
                                    <div className={classes.row_container}>
                                        <div>
                                            <strong>Observación:</strong>
                                        </div>
                                        <div className={classes.row_container_right}>
                                            <div><strong><span>{store.selectedClient?.observation}</span></strong></div>
                                        </div>
                                    </div>}

                                {store.selectedClient?.difficultPerson &&
                                    <div className={classes.difficult_person_indicator_container}>
                                        <ShowDifficultPersonIndicator
                                            difficultPerson={store.selectedClient?.difficultPerson}/>
                                    </div>}
                            </div>
                        </div>
                    )}
                </Observer>
            )
        }

        return (
            <div onClick={openClientSearch}
                 className={clsx(classes.btn_container, classes.select_client_btn)}>
                <div>{i18n.translate("SELECT_CLIENT_PLACEHOLDER")}</div>
                <div><AccountBoxIcon/></div>
            </div>
        )
    }

    const SearchClient = () => (
        <Observer>
            {() => (
                <div className={clsx(classes.search_container, classes.search_client_container, !onlyClientSelection && classes.maxWidth50Percent)}>
                    <div className={classes.search_input_container}>
                        <NewClientButton
                            agencyId={store.agencyId}
                            customButton={<div className={clsx(classes.btn, classes.add_btn)}>
                                <AddBoxIcon/>
                                <span>{i18n.translate("NEW")}</span>
                            </div>}
                            onSave={onNewClient}
                        />
                        <div className={classes.search_input}>
                            <input
                                value={filter}
                                placeholder={i18n.translate("CLIENT_FILTER_PLACEHOLDER")}
                                ref={inputRef}
                                onChange={onFilterChange}/>
                        </div>
                        <div onClick={closeClientSearch}
                             className={clsx(classes.btn, classes.remove_btn)}>
                            <HighlightOffIcon/>
                            <span>{i18n.translate("CLOSE")}</span>
                        </div>
                    </div>
                    {store.loading &&
                        <div className={classes.options_body}>
                            <Loading className={classes.loading} fontSize={45}/>
                        </div>}

                    {!store.loading && <div className={classes.elements_container}>
                        {store.clients.map((e, i) => (
                            <ClientElementRow
                                key={i}
                                element={e}
                                selected={e.id === store.selectedClient?.id}
                                onSelect={selectClient}
                            />
                        ))}
                    </div>}

                    {!store.loading && store.isEmpty &&
                        <div className={classes.empty_container}>
                            <div>{i18n.translate("NO_CLIENTS_TITLE")}</div>
                            <div>{i18n.translate("NO_CLIENTS_SUBTITLE")}</div>
                        </div>}
                </div>
            )}
        </Observer>
    )


    return <Observer>
        {() => (
            <>
                {!store.showSearchPanel && <ClientInfo/>}
                {store.showSearchPanel && <SearchClient/>}
            </>
        )}
    </Observer>
}


export default SelectClientPanel;