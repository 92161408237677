import React, {} from 'react';
import PrintToDeliverButtonProperties from "./PrintToDeliverButtonProperties";
import PrintToDeliverStore from "./PrintToDeliverStore";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./PrintToDeliverButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import InvoiceToPrint from "../../Content/InvoiceToPrint/InvoiceToPrint";
import {Receipt} from "@mui/icons-material";

const PrintToDeliverButton: React.FC<PrintToDeliverButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderIds,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT,
                                                                            invoice
                                                                  }) => {
    const classes = useStyles(showText);

    const commonContext = useCommonContext();
    const store = new PrintToDeliverStore(commonContext, productOrderIds);


    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
        if (onSave) {
            onSave();
        }
    }

    const text = invoice ? "Factura" : "Imprimir para entrega";
    const Icon = invoice ? Receipt: LocalPrintshopIcon ;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }


    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={`Orden de envío`}
                           onClose={onCloseModal} open={store.isModalOpen} className={classes.modal_container_deliver}>
                    <div>
                        <InvoiceToPrint productOrderIds={productOrderIds} isInvoice={invoice}/>
                    </div>
                </SidePanel>
                {showButton && <Btn/>}
                {!showButton && <div onClick={handleOnclick}>{children}</div>}
            </>
        )}
    </Observer>
}

export default PrintToDeliverButton;
