import React from 'react';
import {useStyles} from "../PackageDelivery.style";
import clsx from "clsx";
import {motion} from "framer-motion";
import PackageDeliveryRowProperties from "./PackageDeliveryRowProperties";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import RemovePackageDeliveryButton
    from "../../../shared_components/Buttons/RemovePackageDeliveryButton/RemovePackageDeliveryButton";


const PackageDeliveryRowSmall: React.FC<PackageDeliveryRowProperties> = ({
                                                                                    packageDeliveryOrder,
                                                                                    onPackageDeliveryOrderClick,
                                                                                    // onDeliveryPersonSelect,
                                                                                    onUpdate
                                                                                }) => {

    const classes = useStyles();

    // const onRowMoneyRemittanceClick = () => {
    //     onMoneyDeliveryOrderClick(moneyDeliveryOrder.id);
    // }

    return (
        <div className={clsx(classes.money_info_container_small)}>
            {/*<ShowSellerBasicInfo autofill name={MoneyDeliveryOrder.orderNumber} email={MoneyDeliveryOrder.orderNumber}/>*/}
            <div className={classes.menu_container_small}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <RemovePackageDeliveryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        packageDeliveryOrderId={packageDeliveryOrder.id}
                        showText={false}
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
                {/* <NewMoneyDeliveryButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    moneyId={money.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <MoneyRemittancePriceButton
                    size={ButtonSize.SMALL}
                    moneyId={money.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <MoneyRemittanceProdCostButton
                    size={ButtonSize.SMALL}
                    moneyId={money.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <RemoveMoneyRemittanceButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    money={money}
                    showText={false}
                    type={ButtonType.SECONDARY}
                /> */}
            </div>
        </div>
    )
}

export default PackageDeliveryRowSmall;
