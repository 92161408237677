import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    seller_info_container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        width: 'auto'
    },
    seller_logo: {
        fontSize: '20px',
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    name:{
        fontWeight: 500
    },
    info_container: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        lineHeight: '14px',
        marginLeft: '8px',
        maxWidth: '75%',
        overflow: 'hidden',
    },
    location_icon: {
        color: theme.palette.grey["700"]
    }
}));

export {useStyles}