import React from 'react';
import {useStyles} from "../Articles.style";
import clsx from "clsx";
import ArticleRowProperties from "./ArticleRowProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewArticleButton from "../../../shared_components/Buttons/NewArticleButton/NewArticleButton";
import ArticlePriceButton from '../../../shared_components/Buttons/ArticlePriceButton/ArticlePriceButton';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowArticleDescription from '../../../shared_components/Info/ShowArticleDescription/ShowArticleDescription';
import { useI18nContext } from '../../../contexts/I18nContext';
import { CurrencyModule } from '../../../util/Currency';
import UploadArticleImageButton from '../../../shared_components/Buttons/UploadArticleImageButton/UploadArticleImageButton';
import ShowImage from '../../../shared_components/Info/ShowImage/ShowImage';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import RemoveArticleButton from '../../../shared_components/Buttons/RemoveArticleButton/RemoveArticleButton';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';


const ArticleRowMedium: React.FC<ArticleRowProperties> = ({
                                                            article,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.article_info_container)}>
            <ShowImage imageName={article.defaultImage} width={70} height={70}/>
            <ShowText text={article.name} className={classes.article_name_container}/>
            <ShowArticleDescription description={article.description}/>
            <AllowAccess isRootAgency>
                <div>
                    <div className={classes.number}>
                        <div className={classes.title}>{`Costo de confección:`}</div>
                        <div className={classes.value}>{article.confectionPrice && CurrencyModule.format(article.confectionPrice, article.confectionPriceCurrency)}</div>
                    </div>
                    <div className={classes.currency}>{i18n.translate(article.confectionPriceCurrency)}</div>
                </div>
            </AllowAccess>
            <ExpandoMenu>
                <>
                    <NewArticleButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        articleId={article.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <UploadArticleImageButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        articleId={article.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ArticlePriceButton
                        size={ButtonSize.SMALL}
                        articleId={article.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <RemoveArticleButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        article={article}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default ArticleRowMedium;
