import React from "react";
import PackageItemsRowProperties from "./PackageItemsRowProperties";
import PackageItemsRowMedium from "./PackageItemsRowMedium";
import ViewSmall from "../../../../ui_components/MediaView/ViewSmall";
import ViewMedium from "../../../../ui_components/MediaView/ViewMedium";
import ViewLarge from "../../../../ui_components/MediaView/ViewLarge";

const PackageItemsRow:React.FC<PackageItemsRowProperties> = ({
                                                                 packageItem,
                                                                 onRemovePackageItem, 
                                                                 onEditPackageItem,
                                                                 categoryId,
                                                                 onUpdate,
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <PackageItemsRowMedium
                    packageItem={packageItem}
                    categoryId={categoryId}
                    onRemovePackageItem={onRemovePackageItem}
                    onEditPackageItem={onEditPackageItem}
                    onUpdate={onUpdate}/>
            </ViewSmall>
            <ViewMedium>
                <PackageItemsRowMedium
                    packageItem={packageItem}
                    categoryId={categoryId}
                    onRemovePackageItem={onRemovePackageItem}
                    onEditPackageItem={onEditPackageItem}
                    onUpdate={onUpdate}/>
            </ViewMedium>
            <ViewLarge>
                <PackageItemsRowMedium
                    packageItem={packageItem}
                    categoryId={categoryId}
                    onRemovePackageItem={onRemovePackageItem}
                    onEditPackageItem={onEditPackageItem}
                    onUpdate={onUpdate}/>
            </ViewLarge>
        </>
    )
}
export default PackageItemsRow