import React from 'react';
import ShowMoneyDeliveryAmountsProperties from "./ShowMoneyDeliveryAmountsProperties";
import {useStyles} from "./ShowMoneyDeliveryAmounts.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
// import UpdateMoneyDeliveryValuesLink from '../../Buttons/UpdateMoneyDeliveryValuesLink/UpdateMoneyDeliveryValuesLink';
// import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';

const ShowMoneyDeliveryAmounts: React.FC<ShowMoneyDeliveryAmountsProperties> = ({
                                                        moneyDeliveryOrder,
                                                        valueToPay,
                                                        currencyToPay,
                                                        valueToReceive,
                                                        currencyReceive,
                                                        className = '',
                                                        onValuesChange = () => {
                                                        }
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    // const onSave = async () => {
    //     onValuesChange && onValuesChange();
    // }

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`${i18n.translate("TOTAL_TO_RECEIVE")}:`}</div>
                    <div className={classes.value}>{valueToReceive && CurrencyModule.format(valueToReceive, currencyReceive)}</div>
                </div>
                <div className={classes.currency}>{i18n.translate(currencyReceive)}</div>
            </div>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`${i18n.translate("TOTAL_TO_PAY")}:`}</div>
                    <div className={classes.value}>{valueToPay && CurrencyModule.format(valueToPay, currencyToPay)}</div>
                </div>
                <div className={classes.currency}>{i18n.translate(currencyToPay)}</div>
            </div>
            {/* TODO: En caso de necesitar para Yusnay (admin)... adicionar esto aqui bajo un permiso */}
            {/* {(moneyDeliveryOrder.status === ProductOrderStatus.ASSIGNED ||
                moneyDeliveryOrder.status === ProductOrderStatus.PAYED ||
                moneyDeliveryOrder.status === ProductOrderStatus.PENDING) && <div className={classes.edit_link_container}>
                <UpdateMoneyDeliveryValuesLink onSave={onSave} moneyDelivery={moneyDeliveryOrder}/>
            </div>} */}
        </div>
    )
}


export default ShowMoneyDeliveryAmounts;