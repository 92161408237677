import React from 'react';
import ShowShopOrderProductsProperties from "./ShowShopOrderProductsProperties";
import {useStyles} from "./ShowShopOrderProducts.style";
import clsx from "clsx";

const ShowShopOrderProducts: React.FC<ShowShopOrderProductsProperties> = ({
                                                        products,
                                                        className = '',
                                                  }) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Productos (${products.length}):`}</div>
                    <div className={classes.value}>
                        {products.map((product, index) => (
                            <span>{product.shopProductName} ({product.amount}){index < products.length - 1 && <span>,</span>} </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ShowShopOrderProducts;