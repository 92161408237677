import SignInPage from "./pages/SignInPage/SignInPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import RouteRepository from "./route/RouteRepository";
import MainLayout from "./pages/Layout/MainLayout/MainLayout";
import ProtectedRoute from "./pages/Layout/ProtectedRoute/ProtectedRoute";
import ChangePassword from './pages/ChangePassword/ChangePassword';
import ChangePasswordDeliveryPerson from './pages/ChangePasswordDeliveryPerson/ChangePasswordDeliveryPerson';
import Providers from "./Providers";
import PasswordRecoveryPage from "./pages/PasswordRecovery/PasswordRecoveryPage";
import AllowAccess from "./shared_components/Access/AllowAccess/AllowAccess";
import MoneyDeliveryOrderDetails from './pages/ProductOrdersDetails/MoneyDeliveryOrderDetails/MoneyDeliveryOrderDetails';


function App() {
    const routes = RouteRepository.getRoutes();
    const adminRoutes = RouteRepository.getAdminRoutes();
    const allRoutes = routes.flatMap(t => [t, ...t.children || []]);
    const allAdminRoutes = adminRoutes.flatMap(t => [t, ...t.children || []]);
    return (
        <Providers>
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<SignInPage/>}/>
                    <Route path='/recover-password' element={<PasswordRecoveryPage/>}/>
                    <Route path='/change-password' element={<ChangePassword/>}/>
                    <Route path='/delivery-person/change-password' element={<ChangePasswordDeliveryPerson/>}/>
                    {/* <Route path='/money-delivery-order/:id' element={<MoneyDeliveryOrderDetails/>}/> */}
                    <Route path='/' element={<Navigate to='/login'/>}/>
                    <Route path='/' element={<MainLayout/>}>
                        {allRoutes.map((r, i) => (
                            <AllowAccess key={i} any={r.access}>
                                <Route path={r.path} element={<ProtectedRoute route={r}/>}/>
                            </AllowAccess>
                        ))}
                        <Route path={'/money-delivery-order-details/:id'}>
                            <MoneyDeliveryOrderDetails/>
                        </Route>
                    </Route>
                    <Route path='/' element={<MainLayout/>}>
                        {allAdminRoutes.map((r, i) => (
                            <AllowAccess key={i} any={r.access}>
                                <Route path={r.path} element={<ProtectedRoute route={r}/>}/>
                            </AllowAccess>
                        ))}
                    </Route>
                </Routes>
            </BrowserRouter>
        </Providers>

    );
}

export default App;
