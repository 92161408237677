import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        // parent_box: {
        //     display: 'flex',
        //     flexDirection: 'column'
        // },
        // step_completed_message: {
        //     'marginTop': '2px',
        //     'marginBottom': '1px'
        // },
        // steps_button_container: {
        //     'display': 'flex',
        //     'flexDirection': 'row',
        //     'paddingTop': '2px',
        //     justifyContent: 'center'
        // }
        container_classes: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gridColumnGap: '10px',
            gridRowGap:' 10px',
            backgroundColor: '#ffffff'
        },
    }))
;

export {useStyles}