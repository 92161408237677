import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import TopupPrice from "../../../entities/productOrder/topup/TopupPrice";
import { TopupPriceService } from "../../../service/topup/TopupPriceService";


class RemoveTopupPriceStore {

    public topupPrice: TopupPrice;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, topupPrice: TopupPrice) {
        this.topupPrice = topupPrice;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await TopupPriceService.remove(this.topupPrice);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveTopupPriceStore;