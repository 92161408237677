import Phone from "../../entities/Phone";

class SearchContactClientElementResponse {
    public id: string;
    public name: string;
    public homePhone?: Phone;
    public mobilePhone?: Phone;
    public email: string;
    public clientId: string;
    public clientName: string;
    public clientLastName: string;
    public clientEmail: string;
    public clientPhone: Phone;
    public agencyId?: string;

    constructor(
        id: string,
        name: string,
        email: string,
        clientId: string,
        clientName: string,
        clientLastName: string,
        clientEmail: string,
        clientPhone: Phone,
        homePhone?: Phone,
        mobilePhone?: Phone,
        agencyId?: string
        ) {
        this.id = id;
        this.name = name;
        this.homePhone = homePhone;
        this.mobilePhone = mobilePhone;
        this.clientId = clientId;
        this.email = email;
        this.clientName = clientName;
        this.clientLastName = clientLastName;
        this.clientEmail = clientEmail;
        this.clientPhone = clientPhone;
        this.agencyId = agencyId;
    }
}

export default SearchContactClientElementResponse;