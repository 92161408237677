import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    individual_category_price_container: {
        width: 'fit-content',
        display: 'flex',
        gap: 16,
        marginLeft: 'auto',
        marginTop: '8px',
        borderRadius: 4,
        padding: '10px 10px',
        border: `1px solid ${theme.palette.grey[400]}`,
        background: '#F3DEBA'
    }, 
    final_category_price_container: {
        width: 'fit-content',
        display: 'flex',
        gap: 16,
        marginLeft: 'auto',
        marginTop: '8px',
        borderRadius: 4,
        padding: '10px 10px',
        border: `1px solid ${theme.palette.grey[400]}`,
        background: '#B4E4FF'
    }, 
    individual_item_price_container: {
        display: 'flex',
        gap: 16,
        marginTop: '4px',
        borderRadius: 4,
        padding: '10px 0',
    }, 
    individual_item__optional_prices_container:{
        display:"flex",
        gridColumnGap:'16px',
    },   
    }))
;

export {useStyles}