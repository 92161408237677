import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
        element_row_container: {
            borderRadius: '4px',
            display: 'flex',
            padding: '4px 8px',
            boxSizing: 'border-box',
            "& svg": {
                verticalAlign: 'middle',
                color: theme.palette.secondary.light,
                fill: theme.palette.secondary.light,
                fontSize: '30px',
                padding: '0 8px',
            },
            "&:hover": {
                cursor: 'pointer',
                backgroundColor: `${theme.palette.primary.light}38`,
                "& svg": {
                    color: theme.palette.primary.dark,
                    fill: theme.palette.primary.dark,
                },
                "& $text_container, $icon_info span": {
                    color: theme.palette.primary.dark,
                    "& > div:first-child": {
                        color: theme.palette.primary.dark,
                    }
                }
            }
        },
        element_row_container_non_selectable: {
            borderRadius: '4px',
            display: 'flex',
            padding: '8px 16px',
            boxSizing: 'border-box',
            "& svg": {
                verticalAlign: 'middle',
                color: theme.palette.secondary.light,
                fill: theme.palette.secondary.light,
                fontSize: '30px',
                padding: '0 8px',
            },
            "&:hover": {
                cursor: 'pointer',
                backgroundColor: `${theme.palette.grey[200]}`,
                "& svg": {
                    color: theme.palette.secondary.light,
                    fill: theme.palette.secondary.light,
                },
                "& $text_container": {
                    color: theme.palette.secondary.light,
                    "& > div:first-child": {
                        color: theme.palette.secondary.light,
                    }
                }
            }
        },
        active: {
            backgroundColor: `${theme.palette.secondary.light}18`,
        },
        text_container: {
            display: 'flex',
            flexDirection: 'column',
            lineHeight: ' 12px',
            justifyContent: 'center',
            color: theme.palette.grey[400],
            fontSize: '12px',
            "& > div:first-child": {
                color: theme.palette.grey[600],
                fontSize: '15px',
            }

        },
        icon_info: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            lineHeight: '10px',
            '& span': {
                fontSize: '10px',
                color: theme.palette.grey[400]
            }
        }
    }))
;

export {useStyles}
