import ProductOrderStatus from "./productOrder/ProductOrderStatus";

class ProductOrderStatusHistory {
    public readonly id: string;
    public readonly remittanceId: string;
    public readonly date: number;
    public readonly status: ProductOrderStatus;
    public readonly comment?: string;
    public readonly userId?: string;
    public readonly userName?: string;

    constructor(
        id: string,
        remittanceId: string,
        date: number,
        status: ProductOrderStatus,
        comment?: string,
        userId?: string,
        userName?: string
    ) {
        this.id = id;
        this.remittanceId = remittanceId;
        this.date = date;
        this.status = status;
        this.comment = comment;
        this.userId = userId;
        this.userName = userName;
    }
}

export default ProductOrderStatusHistory;