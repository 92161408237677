
class Product {
    public readonly id: string;
    public readonly name: string;
    public readonly amount: number;

    constructor(id: string, name: string, amount: number) {
        this.id = id;
        this.name = name;
        this.amount = amount;
    }
}

export default Product;