import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";


export module PasswordService {

    export async function validateCodeForDeliveryPerson(code?: string): Promise<Response<boolean>> {
        const url = Config.get('PASSWORD_VALIDATE_CODE_URL')
        const request = new Request(url, {code: code})
        const response = await RestClient.post(request)
        if (response.success) {
            return new Response<boolean>(true, true)
        }
        return new Response<boolean>(false, false, response.error, response.status);
    }

    export async function validateCode(code?: string): Promise<Response<boolean>> {
        const url = Config.get('PASSWORD_VALIDATE_CODE_URL')
        const request = new Request(url, {code: code})
        const response = await RestClient.post(request)
        if (response.success) {
            return new Response<boolean>(true, true)
        }
        return new Response<boolean>(false, false, response.error, response.status);
    }

    export async function changePassword(code: string, newPassword: string): Promise<Response<boolean>> {
        const url = Config.get('CHANGE_PASSWORD_URL')
        const request = new Request(url, {code, password: newPassword})
        const response = await RestClient.post(request)
        if (response.success) {
            return new Response<boolean>(true, true)
        }
        return new Response<boolean>(false, false, response.error, response.status);
    }

    export async function changePasswordForDeliveryPerson(code: string, newPassword: string): Promise<Response<boolean>> {
        const url = Config.get('CHANGE_PASSWORD_DELIVERY_PERSON_URL')
        const request = new Request(url, {code, password: newPassword})
        const response = await RestClient.post(request)
        if (response.success) {
            return new Response<boolean>(true, true)
        }
        return new Response<boolean>(false, false, response.error, response.status);
    }

    export async function recover(email: string): Promise<Response<boolean>> {
        const url = Config.get("RECOVER_PASSWORD_URL");
        const request = new Request(url, {email})
        const response = await RestClient.post(request)
        if (response.success) {
            return new Response<boolean>(true, true)
        }
        return new Response<boolean>(false, false, response.error, response.status);
    }
}
