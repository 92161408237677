
class TopupPrice {
    public readonly id: string;
    public providerName: string;
    public agencyId: string;
    public topupProductId: string;
    public amount: number;

    constructor(id: string, providerName: string, agencyId: string, topupProductId: string, amount: number) {
        this.id = id;
        this.providerName = providerName;
        this.agencyId = agencyId;
        this.topupProductId = topupProductId;
        this.amount = amount;
    }
}

export default TopupPrice;