import React from 'react';
import RemittanceStatusSelectProperties from "./RemittanceStatusSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import MultiSelect from "../../../ui_components/MultiSelect/MultiSelect";
import MultiSelectOption from "../../../ui_components/MultiSelect/MultiSelectOption";
import HistoryIcon from '@mui/icons-material/History';

const RemittanceStatusSelect: React.FC<RemittanceStatusSelectProperties> = ({
                                                                    selectedStatus,
                                                                    onChange,
                                                                    disabled,
                                                                    fullWidth = false,
                                                                    className = '',

                                                                }) => {
    const i18n = useI18nContext();

    const opt = [
        new MultiSelectOption(i18n.translate("PENDING"), "PENDING", "Pendientes de pago", selectedStatus?.some(r => r === "PENDING")),
        new MultiSelectOption(i18n.translate("PAYED"), "PAYED", "Pendientes de asignar un repartidor", selectedStatus?.some(r => r === "PAYED")),
        new MultiSelectOption("Asignada", "ASSIGNED", "Asignada a un repartidor", selectedStatus?.some(r => r === "ASSIGNED")),
        new MultiSelectOption("Procesando", "PROCESSING", "Procesando por el repartidor", selectedStatus?.some(r => r === "PROCESSING")),
        new MultiSelectOption("Entregada", "DELIVERED", "Entregada al destinatario", selectedStatus?.some(r => r === "DELIVERED")),
        new MultiSelectOption("No entregada", "NOT_DELIVERED", "No entregada", selectedStatus?.some(r => r === "NOT_DELIVERED")),
        new MultiSelectOption("Cancelada", "CANCELED", "Cancelada", selectedStatus?.some(r => r === "CANCELED")),
    ]

    const onStatusChange = (options: MultiSelectOption[]) => {
        onChange(options.map(t => t.value));
    }

    return (
        <MultiSelect
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("REMITTANCE_STATUS")}
            placeholder={i18n.translate("STATUS_PLACEHOLDER")}
            icon={HistoryIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default RemittanceStatusSelect;