import DeliveryCostServiceName from "./DeliveryCostServiceName";
import Geo from "./Geo";

class DeliveryCost {
    public id: string;
    public geo?: Geo;
    public currency: string;
    public deliveryPersonId: string;
    public cost: number;
    public service: DeliveryCostServiceName;

    constructor(id: string, geo: Geo, currency: string, cost: number, deliveryPersonId: string, service: DeliveryCostServiceName) {
        this.id = id;
        this.geo = geo;
        this.currency = currency;
        this.cost = cost;
        this.deliveryPersonId = deliveryPersonId;
        this.service = service;
    }
}

export default DeliveryCost;