import clsx from "clsx";
import {useCommonStyles} from "../CommonStyles.style";
import Alignment from "../enums/Alignment";

export const useAlignItemsClasses = (alignment: Alignment) => {
    const commonClasses = useCommonStyles();
    return clsx(
        commonClasses.flex,
        alignment === Alignment.CENTER && commonClasses.alignItemsCenter,
        alignment === Alignment.START && commonClasses.alignItemsStart,
        alignment === Alignment.END && commonClasses.alignItemsEnd);
}