import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    money_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    money_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container: {},
    money_info_container_grid_template_big: {
        gridTemplateColumns: '150px 170px 270px 150px 130px 150px auto 50px',
    },
    money_info_container_grid_template_small: {
        gridTemplateColumns: '150px 170px 170px 270px 150px 130px auto 100px',
    },
    money_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    money_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    inactive: {
        borderLeft: `8px solid ${theme.palette.grey["300"]}`,
        backgroundColor: `${theme.palette.grey["300"]}8c`,
        opacity: '0.8'
    },
    menu_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    menu_container_small: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        "& > *": {
            marginLeft: '4px'
        }
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr))",
        rowGap: 16,
        columnGap: 8,
        "& button": {
            justifySelf: "end"
        }
    },
    tools_container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px'
    },
    options_container: {
        display: 'flex'
    },
    filter_buttons_container: {
        display: "flex",
        justifyContent: "end",
        gridColumn: '1 / -1'
    },
    search_btn: {
        justifySelf: 'flex-end',
        marginLeft: '5px'
    },
    status_container: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    margin_top_8: {
        marginTop: '8px'
    },
    margin_top_4: {
        marginTop: '4px'
    },
    order_number_container: {
        display: 'flex',
    },
    order_number_text: {
        paddingTop: '2px'
    },
    option_buttons_container: {
        display: 'flex',
        padding: '22px 0 6px'
    },
    border_button: {
        border: '1px solid #c5c5c5'
    },
    print_to_deliver_btn: {
        border: '1px solid #c5c5c5',
        marginLeft: '8px'
    },
    padding_right_5px: {
        paddingRight: '5px'
    },
    select_all_checkbox: {
        marginLeft: '15px',
        marginRight: '10px'
    },
    first_row_small: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    second_row_small: {
        display: 'flex',
        gap: '20px'
    },
    margin_top_n10px: {
        marginTop: '-10px'
    },
    hide_small: {
        "@media (max-width: 800px)": {
            display: 'none'
        }
    },
    margin_left_download_btn: {
        marginLeft: '20px'
    },
    details_icon: {
        marginLeft: '3px',
        color: theme.palette.grey['600'],
        "&:hover": {
            color: theme.palette.grey['800']
        }
    }
}));

export {useStyles}