import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        select_container: {
            position: "relative",
        },
        label: {
            position: 'absolute',
            fontSize: '12px',
            color: theme.palette.grey[600],
            backgroundColor: theme.palette.common.white,
            top: -8,
            left: 10,
            padding: " 0 4px"
        },
        input_container: {
            display: 'flex',
            borderRadius: '4px',
            padding: '8px 14px',
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
            height: '40px',
            boxSizing: 'border-box',
            "&:hover": {
                border: `1px solid ${theme.palette.secondary.main}`,
                cursor: 'pointer',
                "& $icon_container": {
                    "& svg": {
                        color: theme.palette.secondary.main,
                        fill: theme.palette.secondary.main,
                    }
                },
                "& $expand_icon": {
                    color: theme.palette.secondary.main,
                }
            }
        },
        icon_container: {
            "& svg": {
                verticalAlign: 'middle',
                color: theme.palette.secondary.light,
                fill: theme.palette.secondary.light,
                fontSize: '1.5rem'
            }
        },
        info_container: {
            display: 'flex',
            flexGrow: 1,
            color: theme.palette.grey[500],
            paddingLeft: '8px'
        },
        expand_icon: {
            color: theme.palette.grey[500],
            "& svg": {
                verticalAlign: 'middle'
            }
        },
        options_container: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: theme.palette.common.white,
            padding: '16px 8px',
            zIndex: 1,
            boxSizing: 'border-box',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '0 0 4px 4px'
        },
        option: {
            borderRadius: '4px',
            display: 'flex',
            padding: '8px 4px',
            boxSizing: 'border-box',
            "& svg": {
                verticalAlign: 'middle',
                color: theme.palette.secondary.light,
                fill: theme.palette.secondary.light,
                fontSize: '1.5rem',
                padding: '8px',
            },
            "&:hover": {
                cursor: 'pointer',
                backgroundColor: `${theme.palette.primary.light}38`,
                "& svg": {
                    color: theme.palette.primary.dark,
                    fill: theme.palette.primary.dark,
                },
                "& $text_container": {
                    color: theme.palette.primary.dark,
                    "& :first-child": {
                        color: theme.palette.primary.dark,
                        fontWeight: '800',
                        fontSize: '14px',
                    }
                }
            }
        },
        text_container: {
            display: 'flex',
            flexDirection: 'column',
            lineHeight: ' 13px',
            justifyContent: 'center',
            color: theme.palette.grey[400],
            fontSize: '12px',
            "& :first-child": {
                color: theme.palette.grey[600],
                fontSize: '14px',
            }

        },
        selected_container: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: '12px',
        },
        selected: {
            backgroundColor: `${theme.palette.primary.main}`,
            color: theme.palette.common.white,
            padding: '4px',
            lineHeight: '12px',
            borderRadius: '4px',
            marginLeft: '4px'
        },
        active: {
            backgroundColor: `${theme.palette.primary.light}38`,
            "& svg": {
                color: theme.palette.primary.dark,
                fill: theme.palette.primary.dark,
            },
            "& $text_container": {
                color: theme.palette.primary.dark,
                "& :first-child": {
                    color: theme.palette.primary.dark,
                    fontWeight: '800',
                    fontSize: '14px',
                }
            }
        }
    }))
;

export {useStyles}