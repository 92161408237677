import ProductOrderDeliveryPersonStatus from "../../entities/productOrder/ProductOrderDeliveryPersonStatus"


class DeliveryPersonInfoRequest {
    public deliveryPersonId: string
    public deliveryPersonStatus: ProductOrderDeliveryPersonStatus
    public commission: number
    public receivedAt?: number
    public movingAt?: number
    public deliveredAt?: number

    constructor(
        deliveryPersonId: string, 
        commision:number,
        deliveryPersonStatus: ProductOrderDeliveryPersonStatus,
        receivedAt?: number, 
        movingAt?: number,
        deliveredAt?: number,){
        this.deliveryPersonId=deliveryPersonId
        this.commission = commision
        this.deliveryPersonStatus = deliveryPersonStatus || ProductOrderDeliveryPersonStatus.PENDING
        this.receivedAt=receivedAt
        this.movingAt = movingAt
        this.deliveredAt=deliveredAt
    }
}
export default DeliveryPersonInfoRequest  