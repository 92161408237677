import SellerStatus from "./SellerStatus";

class Seller {
    public id: string;
    public name: string;
    public email: string;
    public createdAt: number;
    public status: SellerStatus;
    public isActive: boolean;
    public roles: string[];


    constructor(id: string, name: string, email: string, createdAt: number, status: SellerStatus, roles: string[]) {
        this.name = name;
        this.email = email;
        this.id = id;
        this.roles = roles;
        this.createdAt = createdAt;
        this.status = status;
        this.isActive = (this.status === SellerStatus.ACTIVE);
    }

}

export default Seller;