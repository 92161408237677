import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Geo from "../../../entities/Geo";
import {GeoService} from "../../../service/GeoService";


class GeoMultiSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public parentIdFilter?: string;
    public elements: Geo[];
    public levels?: number[];
    public selectedGeos: Geo[];
    public isSearchDialogOpen: boolean;

    constructor(commonStore: CommonStore, levels?: number[], selectedGeos?: Geo[], parentIdFilter?: string) {
        this.loading = false;
        this.commonStore = commonStore;
        this.elements = [];
        this.levels = levels;
        this.selectedGeos = selectedGeos ?
            selectedGeos.map(geo => new Geo(geo.id, geo.name, geo?.parentId, geo?.level, geo?.hasChildren, geo?.parentInfo)) : [];
        this.parentIdFilter = parentIdFilter;
        this.isSearchDialogOpen = false;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await GeoService.search(1, 30, this.filter, this.levels, this.parentIdFilter);
        if (response.success) {
            runInAction(() => {
                this.elements = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        this.isSearchDialogOpen = value;
        if (this.isSearchDialogOpen) {
            this.search();
        }
    }

    @action
    setSelectedGeos(geos: Geo[]): void {
        this.selectedGeos = geos.map(geo => new Geo(geo.id, geo.name, geo?.parentId, geo?.level, geo?.hasChildren, geo?.parentInfo));
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public async setSelectedGeo(geo: Geo) {
        if (this.selectedGeos?.findIndex(t => t.id === geo?.id) >= 0) {
            this.selectedGeos = this.selectedGeos.filter(t => t.id !== geo?.id);
        } else {
            this.selectedGeos.push(new Geo(geo.id, geo.name, geo?.parentId, geo?.level, geo?.hasChildren, geo?.parentInfo));
        }
        // this.selectedGeos = geo ? new Geo(geo.id, geo.name, geo?.parentId, geo?.level, geo?.hasChildren, geo?.parentInfo) : undefined;
        // this.isSearchDialogOpen = false;
    }

    @action
    public async clearSelection() {
        this.selectedGeos = [];
    }

}

export default GeoMultiSelectStore;