class PieData {
    public label: string;
    public value: number;

    constructor(label: string, value: number) {
        this.label = label;
        this.value = value;
    }
}

export default PieData;