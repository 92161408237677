import PaymentType from "../../entities/PaymentType";

class PaymentTypeResponse {
    public value: number;
    public paymentType: PaymentType;
    public isPercentage: boolean

    constructor(value: number, paymentType: PaymentType, isPercentage:boolean) {
        this.paymentType = paymentType;
        this.value = value;
        this.isPercentage = isPercentage;
    }

}

export default PaymentTypeResponse;