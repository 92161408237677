import {action, makeAutoObservable, observable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Agency from "../../../entities/Agency";

class PrintToDeliverStore {

    public productOrderIds: string[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    @observable agency: Agency;

    @observable agencies : Agency[] = [];

    constructor(commonStore: CommonStore, productOrderIds: string[]) {
        this.productOrderIds = [...productOrderIds];
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
        this.agency = {} as Agency;
    }


    @action
    public async openModal() {
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default PrintToDeliverStore;