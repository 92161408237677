import React from "react";
import { useStyles } from "../ShowDeliveryPersonMulti.style";
import DeliveryPersonInfoRowProperties from "./DeliveryPersonInfoRowProperties";
import clsx from "clsx";
import ProductOrderDeliveryPersonStatus from "../../../../entities/productOrder/ProductOrderDeliveryPersonStatus";
import {Tooltip} from "@material-ui/core";
import ShowText from "../../ShowText/ShowText";
import ShowGeo from "../../ShowGeo/ShowGeo";
import { format } from "../../../../util/PhoneFormatter";
import ConfirmReceivedDeliveryButton from "../../../Buttons/ConfirmReceivedDeliveryButton/ConfirmReceivedDeliveryButton";
import ButtonSize from "../../../../ui_components/Button/ButtonSize";
import { CheckCircle, DoDisturbOn, LocalShipping, Place } from "@mui/icons-material";
import ConfirmDeliveredDeliveryButton from "../../../Buttons/ConfirmDeliveredDeliveryButton/ConfirmDeliveredDeliveryButton";
import ShowField from "../../ShowField/ShowField";
import { useI18nContext } from "../../../../contexts/I18nContext";
import RemoveDeliveryPersonInfoButton from "../../../Buttons/RemoveDeliveryPersonInfoButton/RemoveDeliveryPersonInfoButton";
import ButtonType from "../../../../ui_components/Button/ButtonType";

const DeliveryPersonInfoRowMedium: React.FC<DeliveryPersonInfoRowProperties> = ({
                                                                                    deliveryPersonsInfo, 
                                                                                    onReceivedDelivery, 
                                                                                    onDeliveredDelivery, 
                                                                                    onRemoveDeliveryPersonInfo, 
                                                                                    onCommissionSave,
                                                                                    onUpdate = () => {},
                                                                                    className = '',
                                                                                    filter,
}) => {
    const i18n = useI18nContext();
    const classes = useStyles();

    // const onNewCommission = (newCommission?:number)=>{
    //     onCommissionSave(newCommission,deliveryPersonsInfo)
    // }

    const getIcon = () => {
        switch (deliveryPersonsInfo?.deliveryPersonOrderStatus) {
            case ProductOrderDeliveryPersonStatus.ASSIGNED:
                return <DoDisturbOn/>;
            case ProductOrderDeliveryPersonStatus.PENDING:
                return <LocalShipping/>;
            case ProductOrderDeliveryPersonStatus.RECEIVED:
                return <Place/>;
            case ProductOrderDeliveryPersonStatus.DELIVERED:
                return <CheckCircle/>
        }
    }
    const getClass = () => {
        switch (deliveryPersonsInfo?.deliveryPersonOrderStatus) {
            case ProductOrderDeliveryPersonStatus.ASSIGNED:
                return classes.pending;
            case ProductOrderDeliveryPersonStatus.PENDING:
                return classes.warning;
            case ProductOrderDeliveryPersonStatus.RECEIVED:
                return classes.warning;
            case ProductOrderDeliveryPersonStatus.DELIVERED:
                return classes.success;
            
        }
    }
    const formatTime = (timestamp: number) => {
        const date: Date = new Date(timestamp);
        const hour = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        return `${hour}:${min}`;
    };
    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const month = date.getMonth();
        const year = date.getFullYear();
        const day = date.getDate();
        return `${i18n.translate(`MONTH_${month + 1}`)} ${day}, ${year}`;
    };

    const getFullDate = (timestamp?: number) => {
        if(timestamp){
            if(timestamp!==0){
                return formatDate(timestamp)+ " "+formatTime(timestamp)
            }
        }
        return "-"
    }
    return (
        <div className={clsx(classes.items_inventory_info_container)}>
        <Tooltip title={""}>
                <div className={clsx(classes.display_field_container, className)}>
                    <div className={classes.value}>
                        <div className={getClass()}>
                            {getIcon()}
                        </div>
                        <div className={classes.value}>
                            <div>
                                <ShowText text={deliveryPersonsInfo?.deliveryPersonName || "-"}/>
                                {deliveryPersonsInfo?.deliveryPersonPhone && <span className={classes.phone_number_container}><b>{` (${format(deliveryPersonsInfo?.deliveryPersonPhone)})`}</b></span>}
                            </div>                       
                            <div className={classes.geo_container}>
                                <ShowGeo className={classes.geo_container} geo={deliveryPersonsInfo?.deliveryPersonGeo} showParentInfo={true} showIcon={false} />
                            </div>
                        </div>
                        
                        <div className={classes.full_width}>
                            <ShowField className={classes.text_nowrap} icon={LocalShipping} value={getFullDate(deliveryPersonsInfo?.movingAt)}/>
                            <ShowField className={classes.text_nowrap} icon={Place} value={getFullDate(deliveryPersonsInfo?.receivedAt)}/>
                            <ShowField className={classes.text_nowrap} icon={CheckCircle} value={getFullDate(deliveryPersonsInfo?.deliveredAt)}/>
                            {(deliveryPersonsInfo?.deliveryPersonOrderStatus===ProductOrderDeliveryPersonStatus.ASSIGNED 
                            || deliveryPersonsInfo?.deliveryPersonOrderStatus===ProductOrderDeliveryPersonStatus.PENDING) &&
                                <ConfirmReceivedDeliveryButton
                                    showText={false}
                                    deliveryPersonInfo={deliveryPersonsInfo}
                                    onReceivedDelivery={onReceivedDelivery} 
                                    size={ButtonSize.SMALL}
                                    type={ButtonType.SECONDARY}/>
                            }
                            {(deliveryPersonsInfo?.deliveryPersonOrderStatus===ProductOrderDeliveryPersonStatus.RECEIVED) &&
                                <ConfirmDeliveredDeliveryButton
                                    showText={false}
                                    deliveryPersonInfo={deliveryPersonsInfo}
                                    onDeliveredDelivery={onDeliveredDelivery} 
                                    size={ButtonSize.SMALL}
                                    type={ButtonType.SECONDARY}/>
                            }
                        </div>
                        {/* <ShowEditDeliveryPersonCommission value={deliveryPersonsInfo?.commission!} onNewCommission={onNewCommission}/> */}
                        <RemoveDeliveryPersonInfoButton
                                deliveryPersonInfo={deliveryPersonsInfo}
                                onRemoveDeliveryPersonInfo={onRemoveDeliveryPersonInfo} 
                                size={ButtonSize.SMALL}
                                showText={false}
                                type={ButtonType.SECONDARY}/>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};
export default DeliveryPersonInfoRowMedium;
