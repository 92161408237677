import {useStyles} from './ElementRow.style';
import React from "react";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ElementRowProperties from "./ElementRowProperties";
import clsx from "clsx";

const ContactElementRow: React.FC<ElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();

    // let fullAddress = "";
    // if (element.address) {
    //     fullAddress += element.address;
    // }
    // if (element.geo) {
    //     fullAddress += ` (${element.geo.name})`
    // }

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <ContactPageIcon/>
            </div>

            <div className={classes.text_container}>
                <div>Nombre: <b>{element.name} {element.lastName}</b></div>
                {/*{element.number && <div className={classes.phone_number_container}>Teléfono: {`${format(element.number)}`}</div>}*/}
            </div>

        </div>
    )
}


export default ContactElementRow;