import React, { useState } from 'react';
import DownloadOrdersToDeliverReportButtonProperties from "./DownloadOrdersToDeliverReportButtonProperties";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import {Receipt} from "@mui/icons-material";
import { RestClient } from '../../../util/RestClient';
import { Config } from '../../../util/Config';
import { Downloader } from '../../../util/Download';

const DownloadOrdersToDeliverReportButton: React.FC<DownloadOrdersToDeliverReportButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderIds,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT,
                                                                            invoice
                                                                  }) => {

    const getUrl = () => {
        return Config.get("GET_MONEY_INVOICE_TO_DELIVER")
    }
    const [url] = useState(getUrl());
    const [loading, setLoading] = useState(false);

    const handleOnclick = async () => {
        setLoading(true);
        const response = await RestClient.postDownload({ url, data: productOrderIds });

        let fileName = `invoices-para-entregar.pdf`;
        if (response.success) {
            Downloader.download(response.data?.filename || fileName, response.data?.data);
            onSave && onSave();
        }
        setLoading(false);
    }

    const text = invoice ? "Factura" : "Imprimir para entrega";
    const Icon = invoice ? Receipt: LocalPrintshopIcon ;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            loading={loading}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }


    return <Observer>
        {() => (
            <>
                {showButton && <Btn/>}
                {!showButton && <div onClick={handleOnclick}>{children}</div>}
            </>
        )}
    </Observer>
}

export default DownloadOrdersToDeliverReportButton;
