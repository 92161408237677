import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    modal_container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    input: {
        marginTop: '16px'
    },
    total_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    big_modal_container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-end',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
    },
    btn_container: {
        display: 'flex',
        columnGap: 8,
        marginBottom: '8px'
    },
    full_width:{
        width: '100%',
    },
    selected_order: {
        backgroundColor: `${theme.palette.primary.main}`,
        color: theme.palette.common.white,
        padding: '4px',
        lineHeight: '12px',
        borderRadius: '4px',
        marginLeft:'4px',
        marginTop:'4px',
    },
    selected_container: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto auto auto auto auto auto',
        alignItems: 'end',
        fontSize: '12px',
        marginBottom: '8px',
    },
}));

export {useStyles}
