import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    title: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
    items_inventory_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
    },
    display_field_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        columnGap: '16px'
    },
    delivery_person_container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    value: {
        width: '100%',
        columnGap: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    text_nowrap:{
        whiteSpace: 'nowrap',
    },
    full_width:{
        width: '100%',
    },
    
    geo_container: {
        display: 'flex',
    },
    phone_number_container: {
        fontSize: '13px'
    },
    error: {
        color: theme.palette.error.main
    },
    success: {
        color: theme.palette.success.main
    },
    warning: {
        color: theme.palette.warning.main
    },
    pending: {
        color: theme.palette.grey["700"],
    },
}));

export {useStyles}