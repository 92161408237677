import React from "react";
import ShowClientContactProperties from "./ShowClientContactProperties";
import {useStyles} from "./ShowClientContact.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import ContactsIcon from "@mui/icons-material/Contacts";
import PersonIcon from "@mui/icons-material/Person";
import clsx from "clsx";

const ShowClientContact: React.FC<ShowClientContactProperties> = ({
                                                                      clientName,
                                                                      contactName,
                                                                      className = "",
                                                                  }) => {
    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_client_contact_container, className)}>
            {clientName && <div className={classes.element_container}>
                <PersonIcon className={classes.icon}/>
                <div className={classes.name_container}>
                    <div className={classes.label}>{i18n.translate("CLIENT")}</div>
                    <div className={classes.name}>{clientName}</div>
                    {/*{!clientName && <div className={classes.not_available}>{i18n.translate("NOT_AVAILABLE")}</div>}*/}
                </div>
            </div>}

            {contactName &&
            <div className={classes.element_container}>
                <ContactsIcon className={classes.icon}/>
                <div className={classes.name_container}>
                    <div className={classes.label}>{i18n.translate("CONTACT")}</div>
                    <div className={classes.name}>{contactName}</div>
                    {/*{!contactName && <div className={classes.not_available}>{i18n.translate("NOT_AVAILABLE")}</div>}*/}
                </div>
            </div>
            }
        </div>
    );
};

export default ShowClientContact;
