import {action, makeAutoObservable} from "mobx";
import ArticlePrice from "../../../entities/ArticlePrice";
import { ArticlePriceService } from "../../../service/articleDelivery/ArticlePriceService";
import CommonStore from "../../../stores/CommonStore";


class RemoveArticlePriceStore {

    public articlePrice: ArticlePrice;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, articlePrice: ArticlePrice) {
        this.articlePrice = articlePrice;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await ArticlePriceService.remove(this.articlePrice);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveArticlePriceStore;