import CustomCategoryWeightRange from "../../../model/packageDelivery/customCategory/CustomCategoryWeightRange";
import CommonStore from "../../../stores/CommonStore";
import {action, makeAutoObservable} from "mobx";
import {CustomCategoryWeightRangeService} from "../../../service/packageDelivery/customCategory/CustomCategoryWeightRangeService";
import Response from "../../../model/Response";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"],
    fromValue: ["ERROR_00_601_01","ERROR_00_601_05","ERROR_00_601_06"],
    toValue: ["ERROR_00_601_02","ERROR_00_601_05","ERROR_00_601_06"],
    costError:["ERROR_00_601_03"],
    priceError:["ERROR_00_601_04"],
}
class NewCustomCategoryWeightRangeButtonStore{

    public weightRangeId?:string
    public weightRange:CustomCategoryWeightRange
    public customCategoryId:string
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore

    //Errors
    public fromError?:string
    public toError?:string
    public costError?:string
    public priceError?:string

    constructor(commonStore:CommonStore, customCategoryId:string, weightRangeId?:string) {
        this.isEditing = !!weightRangeId
        this.weightRangeId = weightRangeId
        this.customCategoryId = customCategoryId
        this.isModalOpen = false
        this.weightRange = {} as CustomCategoryWeightRange
        this.weightRange.from=0
        this.weightRange.to=0
        this.weightRange.cost=0
        this.weightRange.price=0
        this.loading = false
        this.commonStore = commonStore
        makeAutoObservable(this)
    }

    get enableButton(): boolean {
        return true
    }

    @action
    public async loadWeightRange(){
        if(this.weightRangeId){
            this.isEditing = true
            const response = await CustomCategoryWeightRangeService.find(this.weightRangeId, this.customCategoryId)
            if(response.success && response.data){
                this.weightRange = response.data
            }
            this.commonStore.processErrors(response)
            this.processError(response.error)
        }
    }

    @action
    public async openModal(){
        this.processError()
        this.loading = true
        await this.loadWeightRange()
        this.isModalOpen = true
        this.loading = false
    }

    @action
    public closeModal(){
        this.isModalOpen = false
    }

    @action
    public async save():Promise<boolean>{
        this.loading = true
        let response: Response<any>;
        if(this.isEditing){
            response = await CustomCategoryWeightRangeService.update(this.weightRange,this.customCategoryId)
        }else {
            response = await CustomCategoryWeightRangeService.create(this.weightRange,this.customCategoryId)
        }
        this.commonStore.processErrors(response)
        this.processError(response.error)
        this.loading = false
        return response.success
    }

    processError(error?:string){
        this.fromError = undefined
        this.toError = undefined
        this.costError = undefined
        this.priceError = undefined

        if(error){
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error)
            }
            if (ERROR_MAP.fromValue.includes(error)) {
                this.fromError = error;
            }
            if (ERROR_MAP.toValue.includes(error)) {
                this.toError = error;
            }
            if (ERROR_MAP.costError.includes(error)) {
                this.costError = error;
            }
            if (ERROR_MAP.priceError.includes(error)) {
                this.priceError = error;
            }
        }
    }
}
export default NewCustomCategoryWeightRangeButtonStore