import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    pagination_container: {
        color: theme.palette.secondary.light,
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    row: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between'
    },
    box: {
        marginTop: '8px',
        width: '100%',
        backgroundColor: theme.palette.grey["100"],
    },
    elements_count: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        '& :first-child': {
            fontSize: '40px',
            marginRight: '4px',
            fontWeight: '600'
        }
    },
    items_per_page: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.grey["600"],
        "& select": {
            backgroundColor: "transparent",
            border: `1px solid ${theme.palette.grey["600"]}`,
            width: "fit-content",
            color: theme.palette.grey["600"],
            padding: "4px",
            borderRadius: '4px',
            "&::-ms-expand": {
                display: 'none'
            },
            "&:focus": {
                outline: 'none'
            }
        }
    },
    page_elements: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    page_elements_small: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            marginLeft: '16px'
        }
    },
    current_page: {
        marginLeft: '4px',
        padding: '4px 8px',
        backgroundColor: `${theme.palette.grey["300"]}`,
        color: theme.palette.grey["500"],
        borderRadius: '8px',
        height: 'fit-content'
    },
    current_page_small:{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',
        padding: '4px 12px',
        fontSize: '20px',
        height: 'fit-content',
        color: theme.palette.common.white
    },
    page_button: {
        marginLeft: '4px',
        padding: '6px 8px',
        color: theme.palette.secondary.light,
        borderRadius: '8px',
        height: 'fit-content',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '10px',
        "& svg": {
            fontSize: '18px',
        },
        "&:hover": {
            backgroundColor: `${theme.palette.grey["400"]}`
        }
    },
    page_button_small: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '50%',
        padding: '6px 8px',
        fontSize: '12px',
        height: 'fit-content',
        cursor: 'pointer',
        color: theme.palette.common.white
    },
    disabled: {
        cursor: 'default',
        backgroundColor: theme.palette.grey["200"],
        color: theme.palette.grey["400"],
    }
}));

export {useStyles}