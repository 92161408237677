import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    comp_container: {
        width: '303px',
        position: 'relative'
    },
    card_img: {
        border: `1px solid ${theme.palette.grey['300']}`
    },
    card_number: {
        width: '100%',
        textAlign: 'right',
        position: 'absolute',
        top: '122px',
        fontSize: '20px',
        '& div': {
            paddingRight: '10px',
        }
    },
    text_white: {
        color: theme.palette.grey['100']
    }
}));

export {useStyles}