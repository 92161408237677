import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import ArticlePrice from "../../entities/ArticlePrice";
import Geo from "../../entities/Geo";

export module ArticlePriceService {
    export async function get(page: number = 1, pageSize: number = 15, articleId?: string, filter?: string): Promise<Response<PagedResponse<ArticlePrice>>> {
        const url = Config.get("GET_ARTICLE_PRICE_URL", {page, pageSize, filter, id: articleId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const contactData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (contactData && paginationData) {
                const data = contactData.map((t: any) => new ArticlePrice(t.id, t.geoId,
                    new Geo(t.geo.id, t.geo.name, t.geo.parentId, t.geo.level, t.geo.hasChildren),
                    t.currency, t.price, t.articleId));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ArticlePrice>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ArticlePrice>>(false, undefined, response.error, response.status);
    }


    export async function find(articleId: string, articlePriceId: string): Promise<Response<ArticlePrice>> {
        const url = Config.get("FIND_ARTICLE_PRICE_URL", {id: articleId, articlePriceId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const articleData = response.data;
            if (articleData) {
                const article = new ArticlePrice(articleData.id, articleData.geoId,
                    new Geo(articleData.geo.id, articleData.geo.name, articleData.geo.parentId, articleData.geo.level, articleData.geo.hasChildren),
                    articleData.currency, articleData.price, articleData.articleId);
                return new Response<ArticlePrice>(true, article, response.error);
            }
        }
        return new Response<ArticlePrice>(true, undefined, response.error, response.status);
    }

    export async function create(price: ArticlePrice, articleId: string): Promise<Response<any>> {
        const url = Config.get("CREATE_ARTICLE_PRICE_URL", {id: articleId});
        const request = new Request(url, price);
        return await RestClient.post(request);
    }

    export async function update(data: ArticlePrice, articleId: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_ARTICLE_PRICE_URL", {id: articleId, contactId: data.id});
        const request = new Request(url, data);
        return await RestClient.put(request);
    }

    export async function remove(articlePrice: ArticlePrice): Promise<Response<any>> {
        const url = Config.get("REMOVE_ARTICLE_PRICE_URL", {id: articlePrice.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function findByArticlesIds(articleIds: string[]): Promise<Response<ArticlePrice[]>> {
        const url = Config.get("GET_BULK_ARTICLE_PRICE_URL");
        const request = new Request(url, articleIds)
        const response = await RestClient.post(request);
        if (response.success) {
            if (response.data) {
                const articlePrices = response.data.map((t: any) => new ArticlePrice(t.id, t.geoId,
                    new Geo(t.id, t.id, t.id, t.id, false),
                    t.currency, t.price, t.articleId) );
                return new Response<ArticlePrice[]>(true, articlePrices, response.error);
            }
        }
        return new Response<ArticlePrice[]>(true, undefined, response.error, response.status);
    }

}