const values = {
    "SELLER_FILTER_PLACEHOLDER": "Nombre, Correo",
    "NO_SELLERS_TITLE": "No hay vendedores!!!",
    "NO_SELLERS_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "PENDING": "Pendiente",
    "INACTIVE": "Inactivo",
    "ACTIVE": "Activo",
    "CREATED": "Creado",
    "SHIPPING_NUMBER": "Envío: ",
    "NO_SHIPPING_ASSIGNED": "Sin envío asignado",
    "ROLES": "Roles",
    "NEW_SELLER": "Nuevo vendedor",
    "EDIT_SELLER": "Editar vendedor",
    "PENDING_DESCRIPTION": "Validación pendiente, no puede entrar",
    "INACTIVE_DESCRIPTION": "Inactivo, no puede entrar al sitio",
    "ACTIVE_DESCRIPTION": "Puede entrar al sitio",
    "STATUS_PLACEHOLDER": "Seleccione estado",
    "ROLE_PLACEHOLDER": "Seleccione rol",
    "DEACTIVATE_SELLER": "Desactivar vendedor",
    "ACTIVATE_SELLER": "Activar vendedor",
    "REMOVE_SELLER": "Eliminar vendedor",
    "REMOVE_SELLER_CONFIRMATION": "¿Está seguro que desea eliminar el vendedor?",
    "SELECT_SELLER": "Seleccione Vendedor",
    "UPDATE_USER_PASSWORD": "Actualizar clave",
    "SELECT_SELLER_PLACEHOLDER": "Seleccione vendedor",
}

export default values;
