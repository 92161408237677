import React from 'react';
import clsx from "clsx";
import FlexContainerProperties from "./FlexContainerProperties";
import Direction from "../common/enums/Direction";
import Width from "../common/enums/Width";
import {useDirectionClasses} from "../common/hooks/useDirectionClasses";
import {useWidthClasses} from "../common/hooks/useWidthClasses";
import Alignment from "../common/enums/Alignment";
import {useAlignItemsClasses} from "../common/hooks/useAlignItemsClasses";
import {useJustifyContentClasses} from "../common/hooks/useJustifyContentClasses";
import {useCommonStyles} from "../common/CommonStyles.style";


const FlexContainer: React.FC<FlexContainerProperties> = ({
                                                              className = '',
                                                              direction = Direction.ROW,
                                                              width = Width.FULL_WIDTH,
                                                              alignItems = Alignment.CENTER,
                                                              justifyContent = Alignment.CENTER,
                                                              fullHeight = false,
                                                              children
                                                          }) => {
    const commonClasses = useCommonStyles();
    const containerClasses = clsx(
        className,
        fullHeight && commonClasses.fullHeight,
        useAlignItemsClasses(alignItems),
        useJustifyContentClasses(justifyContent),
        useWidthClasses(width),
        useDirectionClasses(direction));
    return (
        <div className={containerClasses}>
            {children}
        </div>
    )
}
export default FlexContainer;