import React from 'react';
import TopupRowProperties from "./TopupRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import TopupRowMedium from "./TopupRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import TopupRowSmall from "./TopupRowSmall";


const TopupRow: React.FC<TopupRowProperties> = ({
                                                    topup,
                                                    products = [],
                                                    filter,
                                                    onLoad
                                                }) => {
    return (
        <>
            <ViewSmall>
                <TopupRowSmall topup={topup} products={products} filter={filter} onLoad={onLoad}/>
            </ViewSmall>
            <ViewMedium>
                <TopupRowMedium topup={topup} products={products} filter={filter} onLoad={onLoad}/>
            </ViewMedium>
            <ViewLarge>
                <TopupRowMedium topup={topup} products={products} filter={filter} onLoad={onLoad}/>
            </ViewLarge>
        </>
    )
}

export default TopupRow;
