import { useEffect } from "react";
import { useCommonContext } from "../../contexts/CommonContext";
import { useI18nContext } from "../../contexts/I18nContext";
import { useStyles } from "./ReferenceItemPrice.style";
import useOnEnterPress from "../../hooks/EnterPress";
import { Observer } from "mobx-react-lite";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import Button from "../../ui_components/Button/Button";
import ButtonType from "../../ui_components/Button/ButtonType";
import Box from "../../ui_components/Box/Box";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import NewReferenceItemButton from "../../shared_components/Buttons/NewReferenceItemPriceButton/NewReferenceItemPriceButton";
import ReferenceItemPriceStore from "./ReferenceItemPriceStore";
import ReferenceItemPriceRow from "./ReferenceItemPriceRow/ReferenceItemPriceRow";

const ReferenceItemPrice: React.FC = () => {
  const commonStore = useCommonContext();
  const i18n = useI18nContext();
  const store = new ReferenceItemPriceStore(commonStore);
  const classes = useStyles();

  useEffect(() => {
    store.getElements();
  });

  const onUpdate = async () => {
    store.search();
  };
  const onPaginationChange = async (page: number, pageSize: number) => {
    await store.goToPage(page, pageSize);
  };
  const onSearch = () => {
    store.search();
  };
  const onCleanSearch = () => {
    store.cleanSearch();
  };
  const onValueChange = (value: string) => {
    store.setFilter(value);
  };
  useOnEnterPress(onSearch, [store]);

  return (
      <Observer>
          {() => (
              <div className={classes.foodPackages_container}>
                <Box className={classes.tools_container}
                  width={Width.FULL_WIDTH}
                  justifyContent={Alignment.START}
                  >
                    <NewReferenceItemButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                </Box>
                <Box
                  className={classes.filter_container}
                  width={Width.FULL_WIDTH}
                  justifyContent={Alignment.SPACE_BETWEEN}
                >
                  <FilterInput
                    value={store.filter}
                    placeholder={i18n.translate("INVENTORY_ITEMS_PLACEHOLDER")}
                    onChange={onValueChange}
                    onEnter={onSearch}
                  />
                  <div className={classes.filter_buttons_container}>
                    <Button
                      onClick={onSearch}
                      type={ButtonType.PRIMARY}
                      className={classes.search_btn}
                      text={i18n.translate("SEARCH")}
                    />
                    <Button
                      onClick={onCleanSearch}
                      className={classes.search_btn}
                      text={i18n.translate("CLEAN")}
                    />
                  </div>
                </Box>
                {!store.isEmpty && (
                  <div className={classes.items_inventory_table}>
                    <ShowPagination
                      onChange={onPaginationChange}
                      elementLabel={i18n.translate("ITEMS")}
                      pagination={store.pagination}
                    />
                    <div className={classes.elements_container}>
                      {store.referenceItemsPrice.map((referenceItemPriceResponse, i) => (
                        <ReferenceItemPriceRow
                          onUpdate={onUpdate}
                          referenceItemPriceResponse={referenceItemPriceResponse}
                          key={i}
                          filter={store.filter}
                        />
                      ))}
                    </div>
                    <ShowPagination
                      pagination={store.pagination}
                      onChange={onPaginationChange}
                      elementLabel={i18n.translate("ITEMS")}
                    />
                  </div>
                )}
                {store.isEmpty && (
                  <MessageCard
                    title={i18n.translate("NO_REFERENCE_ITEMS_TITLE")}
                    icon={StorefrontIcon}
                    subtitle={i18n.translate("NO_REFERENCE_ITEMS_SUBTITLE")}
                  />
                )}
              </div>
          )}
      </Observer>
  );
};
export default ReferenceItemPrice;
