import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    service_name_container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 1
    },
    description_icon: {
        color: `${theme.palette.grey["600"]}`,
        cursor: 'pointer'
    },
    font_bold: {
        fontWeight: 'bold'
    },
    header_cell: {
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0
    }
}));

export {useStyles}