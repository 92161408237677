import React from 'react';
import {useStyles} from "./Breadcrumb.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import RouteRepository from "../../../route/RouteRepository";
import {useLocation} from "react-router-dom";

const Breadcrumb: React.FC = () => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const location = useLocation();
    const currentPath = location.pathname;
    const currentRoute = RouteRepository.getRoute(currentPath);
    return (
        <div className={classes.breadcrumb}>
            <div className={classes.page_info_container}>
                {currentRoute && <>
                    <currentRoute.icon/>
                    <div>{i18n.translate(currentRoute.label)}</div>
                </>}
            </div>
        </div>
    );
}

export default Breadcrumb;