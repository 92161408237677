import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import {MoneyDeliveryService} from "../../service/moneyDelivery/MoneyDeliveryService";
import MoneyDeliveryOrderResponse from "../../model/moneyDelivery/MoneyDeliveryOrderResponse";
import DeliveryPerson from "../../entities/DeliveryPerson";
import Currency from "../../entities/Currency";
import Geo from "../../entities/Geo";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import ClientContact from "../../entities/ClientContact";
import Agency from "../../entities/Agency";
import {Filter} from "../../util/Filter";
import CustomRange from "../../ui_components/DateTimeRageSelect/CustomRange";
import Seller from "../../entities/Seller";
import Token from "../../model/Token";

class MoneyDeliveryStore {

    public moneyRemittances: MoneyDeliveryOrderResponse[];
    public selectedMoneyRemittanceIds: string[];
    public selectedMoneyRemittanceId: string;
    public pagination: Pagination;

    public filter?: string;
    public statusFilter?: string[];
    public deliveryPersonFilter?: DeliveryPerson;
    public fromFilter: number | undefined;
    public toFilter: number | undefined;
    public currencyFilter?: Currency;
    public agencyFilter?: Agency;
    public sellerFilter?: Seller;
    public typeFilter?: string;
    public geoFilter?: Geo[];
    public range: CustomRange;
    public ref: boolean = false;

    public sellerAgencyIdFilter?: string;

    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, range: CustomRange) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.moneyRemittances = [];
        this.range = range;
        this.selectedMoneyRemittanceIds = [];
        this.selectedMoneyRemittanceId = '';
        this.commonStore = commonStore;
        this.fromFilter = Filter.get("from", range.from?.valueOf() || undefined);
        this.toFilter = Filter.get("to", range?.to?.valueOf() || undefined);
        this.filter = Filter.get("filter", "");
        this.typeFilter = Filter.get("type", "");
        this.statusFilter = Filter.get("status", []);
        this.agencyFilter = Filter.get("agency", undefined);
        if (this.agencyFilter) {
            this.sellerAgencyIdFilter = (this.agencyFilter as Agency).id;
        }
        this.sellerFilter = Filter.get("seller", undefined);
        this.deliveryPersonFilter = Filter.get("deliveryPerson", undefined);
        this.geoFilter = Filter.get("geo", []);
        this.currencyFilter = Filter.get("currency", undefined);
        makeObservable(this, {
            moneyRemittances: observable,
            selectedMoneyRemittanceIds: observable,
            pagination: observable,
            ref: observable,
            isEmpty: computed,
        });
    }

    get isEmpty(): boolean {
        return this.moneyRemittances.length === 0;
    }

    get haveNotSelected(): boolean {
        return this.selectedMoneyRemittanceIds.length === 0;
    }

    get disableUpdateBulkStatus(): boolean {
        if (this.selectedMoneyRemittanceIds.length === 0) {
            return true;
        }
        const tempOrders = this.moneyRemittances.filter(t => this.selectedMoneyRemittanceIds.indexOf(t.id) >= 0);
        return tempOrders.findIndex(t => t.status === ProductOrderStatus.DELIVERED ||  t.status === ProductOrderStatus.CANCELED) >= 0;
    }

    public setFilter(filter: string) {
        this.filter = filter;
        Filter.save("filter", filter);
    }

    public setStatusToFilter(selectedStatus?: string[]) {
        this.statusFilter = selectedStatus;
        Filter.save("status", selectedStatus);
    }

    public setDeliveryPersonToFilter(deliveryPersonFilter?: DeliveryPerson) {
        this.deliveryPersonFilter = deliveryPersonFilter;
        Filter.save("deliveryPerson", deliveryPersonFilter);
    }

    public setFromFilter(from?: number) {
        this.fromFilter = from;
        Filter.save("from", from);
    }

    public setToFilter(to?: number) {
        this.toFilter = to;
        Filter.save("to", to);
    }

    public setAgencyFilter(newAgency?: Agency) {
        this.agencyFilter = newAgency;
        this.setAgendyIdSellerFilter(newAgency?.id);
        Filter.save("agency", newAgency);
    }

    public setAgendyIdSellerFilter(agencyId?: string) {
        
        if (agencyId) {
            this.sellerAgencyIdFilter = agencyId;
        } else {
            const token = new Token();
            const user = token.parse();
            this.sellerAgencyIdFilter = user?.agencyId;
        }
    }

    public setSellerFilter(newSeller?: Seller) {
        this.sellerFilter = newSeller;
        Filter.save("seller", newSeller);
    }

    public setCurrencyFilter(newCurrency: Currency) {
        this.currencyFilter = newCurrency;
        Filter.save("currency", newCurrency);
    }

    public setTypeFilter(newType: string) {
        this.typeFilter = newType;
        Filter.save("type", newType);
    }

    public setGeoFilter(newGeo: Geo[]) {
        this.geoFilter = newGeo;
        Filter.save("geo", newGeo);
    }

    @action
    public async search() {
        await this.getElements(1);
    }

    @action
    public async clearFilter() {
        this.deliveryPersonFilter = undefined;
        Filter.save("deliveryPerson", this.deliveryPersonFilter);
        this.statusFilter = undefined;
        Filter.save("status", this.statusFilter);
        this.typeFilter = undefined;
        Filter.save("type", this.typeFilter);
        this.currencyFilter = undefined;
        Filter.save("currency", this.currencyFilter);
        this.geoFilter = undefined;
        Filter.save("geo", this.geoFilter);
        this.fromFilter = Filter.get("from", this.range.from?.valueOf() || undefined);
        Filter.save("from", this.fromFilter);
        this.toFilter = Filter.get("to", this.range?.to?.valueOf() || undefined);
        Filter.save("to", this.toFilter);
        this.agencyFilter = undefined;
        Filter.save("agency", this.agencyFilter);
        this.filter = undefined;
        Filter.save("filter", this.filter);
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await MoneyDeliveryService.get(
            page, pageSize, this.fromFilter, this.toFilter, this.filter, this.deliveryPersonFilter?.id,
            this.statusFilter, this.currencyFilter, this.typeFilter,
            this.geoFilter && this.geoFilter.length > 0 ? this.geoFilter.map(t => t.id) : undefined,
            this.agencyFilter?.id);
        if (response.success) {
            runInAction(() => {
                this.moneyRemittances = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.ref = !this.ref;
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async updateMoneyRemittanceContact(contact: ClientContact) {
        this.commonStore.setLoading();
        const response = await MoneyDeliveryService.updateClientPerson(this.selectedMoneyRemittanceId, contact.id);
        if (response.success) {
            runInAction(() => {
                const tempMoneyRemittance = this.moneyRemittances.find(t => t.id === this.selectedMoneyRemittanceId);
                const tempMoneyRemittanceIndex = this.moneyRemittances.findIndex(t => t.id === this.selectedMoneyRemittanceId);
                if (tempMoneyRemittance != null) {
                    this.moneyRemittances[tempMoneyRemittanceIndex] = {
                        ...tempMoneyRemittance,
                        contactId: contact.id,
                        contactName: contact.name
                    };
                }
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async setMoneyRemittanceDeliveryPerson(moneyDeliveryIds: string[], deliveryPerson: DeliveryPerson) {
        this.commonStore.setLoading();
        const response = await MoneyDeliveryService.setDeliveryPerson(moneyDeliveryIds, deliveryPerson.id);
        if (response.success) {
            runInAction(() => {
                for (const moneyDeliveryId of moneyDeliveryIds) {
                    const tempMoneyRemittance = this.moneyRemittances.find(t => t.id === moneyDeliveryId);
                    const tempMoneyRemittanceIndex = this.moneyRemittances.findIndex(t => t.id === moneyDeliveryId);
                    if (tempMoneyRemittance != null) {
                        this.moneyRemittances[tempMoneyRemittanceIndex] = {
                            ...tempMoneyRemittance,
                            status: ProductOrderStatus.ASSIGNED,
                            deliveryPersonId: deliveryPerson.id,
                            deliveryPersonName: deliveryPerson.name
                        };
                    }
                }
                this.selectedMoneyRemittanceIds = [];
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async unsetMoneyRemittanceDeliveryPerson() {
        this.commonStore.setLoading();
        const response = await MoneyDeliveryService.unsetDeliveryPerson(this.selectedMoneyRemittanceId);
        if (response.success) {
            runInAction(() => {
                const tempMoneyRemittance = this.moneyRemittances.find(t => t.id === this.selectedMoneyRemittanceId);
                const tempMoneyRemittanceIndex = this.moneyRemittances.findIndex(t => t.id === this.selectedMoneyRemittanceId);
                if (tempMoneyRemittance != null) {
                    this.moneyRemittances[tempMoneyRemittanceIndex] = {
                        ...tempMoneyRemittance,
                        status: ProductOrderStatus.PENDING,
                        deliveryPersonId: null,
                        deliveryPersonName: null
                    };
                }
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }
}

export default MoneyDeliveryStore;