import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import Transaction from "../../../entities/Transaction";
import {TransactionService} from "../../../service/TransactionService";
import TransactionStatus from "../../../entities/TransactionStatus";
import Currency from "../../../entities/Currency";
import { ArticleTransactionService } from "../../../service/ArticleTransactionService";
import ArticleTransaction from "../../../entities/ArticleTransaction";
import Article from "../../../entities/Article";

class ViewTransactionsStore {

    public entityId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public articleLoading: boolean;
    public commonStore: CommonStore;

    public pagination: Pagination;
    public transactions: Transaction[];
    public from?: number;
    public to?: number;
    public status?: TransactionStatus;
    public currency?: Currency;

    public articlePagination: Pagination;
    public articleTransactions: ArticleTransaction[];
    public articleFrom?: number;
    public articleTo?: number;
    public articleStatus?: TransactionStatus;
    public articleFilter?: Article;


    constructor(commonStore: CommonStore, entityId: string) {
        this.entityId = entityId;
        this.isModalOpen = false;
        this.loading = false;
        this.articleLoading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 10, 0, 0);
        this.articlePagination = new Pagination(1, 10, 0, 0);
        this.transactions = [];
        this.articleTransactions = [];
        makeAutoObservable(this);
    }

    @action
    public async searchBalanceTransations(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await TransactionService.get(
            this.entityId,
            page,
            pageSize,
            this.from,
            this.to,
            this.status,
            this.currency
        );
        if (response.success) {
            runInAction(() => {
                this.transactions = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async searchArticleTransactions(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.articleLoading = true;
        const response = await ArticleTransactionService.get(
            this.entityId,
            page,
            pageSize,
            this.articleFrom,
            this.articleTo,
            this.articleStatus,
            this.articleFilter?.id
        );
        if (response.success) {
            runInAction(() => {
                this.articleTransactions = response.data?.data || [];
                this.articlePagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.articleLoading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.searchBalanceTransations();
        await this.searchArticleTransactions();
    }

    @action
    public async cleanFilter() {
        this.currency = undefined;
        this.from = undefined;
        this.to = undefined;
        await this.searchBalanceTransations();
    }

    @action
    public async cleanArticleFilter() {
        this.articleFrom = undefined;
        this.articleTo = undefined;
        this.articleFilter = undefined;
        await this.searchArticleTransactions();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.transactions.length === 0;
    }

    get isArticleEmpty(): boolean {
        return this.articleTransactions.length === 0;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.searchBalanceTransations(page, pageSize);
    }

    @action
    public async goToArticlePage(page: number = this.articlePagination.currentPage, pageSize: number = this.articlePagination.pageSize): Promise<void> {
        await this.searchArticleTransactions(page, pageSize);
    }

}

export default ViewTransactionsStore;