class Geo {
    public id: string;
    public name: string;
    public parentId: string;
    public level: number;
    public hasChildren: boolean;
    public parentInfo?: string;


    constructor(id: string, name: string, parentId: string, level: number, hasChildren: boolean,parentInfo?: string) {
        this.name = name;
        this.id = id;
        this.parentId = parentId;
        this.level = level;
        this.hasChildren = hasChildren;
        this.parentInfo = parentInfo;
    }

    getLevelText(level?:number): string {
        switch (level || this.level) {
            case 1:
                return "COUNTRY";
            case 2:
                return "STATE";
            case 3:
                return "CITY";
            case 4:
                return "TOWN";
            default: return ""
        }
    }

}

export default Geo;