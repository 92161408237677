import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Client from "../../../entities/Client";
import {ClientService} from "../../../service/ClientService";
import {ClientContactService} from "../../../service/ClientContactService";
import Pagination from "../../../entities/Pagination";
import ClientContact from "../../../entities/ClientContact";

const ERROR_MAP = {
    phone: ["CLIENT_PHONE_ALREADY_EXIST", "INVALID_PHONE_NUMBER"],
    name: ["CLIENT_NAME_REQUIRED", "CLIENT_NAME_ALREADY_EXIST"],
}

class ClientContactStore {

    public clientId?: string;
    public agencyId?: string;
    public client: Client;
    public isModalOpen: boolean;
    public loading: boolean;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;
    public pagination: Pagination;
    public filter?: string;
    public contacts: ClientContact[];


    constructor(commonStore: CommonStore, clientId?: string, agencyId?: string) {
        this.clientId = clientId;
        this.agencyId = agencyId;
        this.isModalOpen = false;
        this.client = {} as Client;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.contacts = [];
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.client?.name);
    }

    @action
    public async loadClient() {
        const response = await ClientService.find(this.clientId!)
        if (response.success && response.data) {
            this.client = response.data;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async getContacts(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await ClientContactService.get(page, pageSize, this.clientId, this.filter, this.agencyId);
        if (response.success) {
            runInAction(() => {
                this.contacts = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        await this.loadClient();
        await this.search();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.contacts.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getContacts(page, pageSize);
    }

    @action
    public async search() {
        await this.getContacts(1);
    }

    // @action
    // public async save(): Promise<boolean> {
    //     const serviceToCall = this.isEditing ? ClientService.update : ClientService.create;
    //     const response = await serviceToCall(this.client);
    //     this.commonStore.processErrors(response);
    //     this.processError(response.error);
    //     return response.success;
    // }

    processError(error?: string) {
        this.phoneError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }
    }
}

export default ClientContactStore;