import {useStyles} from './ContactElementRow.style';
import React from "react";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ContactElementRowProperties from "./ContactElementRowProperties";
import clsx from "clsx";
import {format} from "../../../../../util/PhoneFormatter";

const ContactElementRow: React.FC<ContactElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <AccountBoxSharpIcon/>
            </div>

            <div className={classes.text_container}>
                <div>Nombre: <b>{element.name}</b> {element.mobilePhone && <span className={classes.phone_number_container}>Teléfono: <b>{`${format(element.mobilePhone)}`}</b></span>}</div>

            </div>

        </div>
    )
}


export default ContactElementRow;