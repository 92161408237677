import React, {useEffect} from "react";
import {useStyles} from "./InventoryItem.style"
import {useCommonContext} from "../../contexts/CommonContext";
import {useI18nContext} from "../../contexts/I18nContext";
import InventoryItemStore from "./InventoryItemStore";
import useOnEnterPress from "../../hooks/EnterPress";
import {Observer} from "mobx-react-lite";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import ButtonType from "../../ui_components/Button/ButtonType";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InventoryItemRow from "./InventoryItemRow/InventoryItemRow";
import NewInventoryItemButton from "../../shared_components/Buttons/NewInventoryItemButton/NewInventoryItemButton";
import { useUserContext } from "../../contexts/UserContext";
import Access from "../../shared_components/Access/AllowAccess/Access";
import CustomCategorySelect from "../../shared_components/Selects/CustomCategorySelect/CustomCategorySelect";


const InventoryItem:React.FC = () => {
    const commonStore = useCommonContext()
    const i18n = useI18nContext()
    const store = new InventoryItemStore(commonStore)
    const classes = useStyles()
    const userStore = useUserContext()
    
    useEffect(() => { 
        store.getElements() 
        store.getCategories()
    })

    const onUpdate = async () => { await store.getElements() }

    const onPaginationChange = async (page: number, pageSize: number) => { await store.goToPage(page, pageSize) }

    const onSearch = () => { store.search() }
    const onCleanSearch = () => { store.cleanSearch() }

    const onValueChange = (value:string) => { store.setFilter(value) }

    const onCustomCategorySelectChange = (customCategoryId?:string) => {store.setCategoryFilter(customCategoryId)}

    useOnEnterPress(onSearch,[store])

    return (
        <Observer>
            {() => (
                <div className={classes.items_inventory_container}>
                    <Box className={classes.filter_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("INVENTORY_ITEMS_PLACEHOLDER")}
                                     onChange={onValueChange}
                                     onEnter={onSearch}/>
                        <CustomCategorySelect 
                            selectedCustomCategory={store.selectedCustomCategory}
                            categories={store.customCategories}
                            onCustomCategoryChange={onCustomCategorySelectChange}/>
                        <div className={classes.filter_buttons_container}>
                            <Button
                                onClick={onSearch}
                                type={ButtonType.PRIMARY}
                                className={classes.search_btn}
                                text={i18n.translate("SEARCH")}/>
                            <Button
                                onClick={onCleanSearch}
                                className={classes.search_btn}
                                text={i18n.translate("CLEAN")}/>
                        </div>
                    </Box>
                    {userStore.hasAccess(Access.CREATE_EDIT_INVENTORY_ITEM) &&
                        <Box
                        className={classes.tools_container}
                        width={Width.FULL_WIDTH}
                        justifyContent={Alignment.END}
                      >
                            <NewInventoryItemButton
                                type={ButtonType.SECONDARY}
                                onSave={onUpdate}/>
                        </Box>
                    }
                    {!store.isEmpty &&
                    <div className={classes.items_inventory_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("ITEMS")}
                                        pagination={store.pagination}/>
                        <div className={classes.elements_container}>
                            {store.inventoryItems.map((inventoryItem,i)=>(
                                <InventoryItemRow
                                    onUpdate = {onUpdate}
                                    inventoryItem={inventoryItem}
                                    key={i}
                                    filter={store.filter}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            pagination={store.pagination}
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("ITEMS")}/>
                    </div>}
                    {store.isEmpty &&
                    <MessageCard
                        title={i18n.translate("NO_INVENTORY_ITEMS_TITLE")}
                        icon={StorefrontIcon}
                        subtitle={i18n.translate("NO_INVENTORY_ITEMS_SUBTITLE")}/> }
                </div>
            )}
        </Observer>
    )
}
export default InventoryItem
