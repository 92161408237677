import React from "react";
import ShowInvoiceAdditionalPricesProperties from "./ShowInvoiceAdditionalPricesProperties";
import {useStyles} from "./ShowInvoiceAdditionalPrices.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import {CurrencyModule} from "../../../util/Currency";
import Table from "../../../ui_components/Table/Table";
import TableType from "../../../ui_components/Table/TableType";

const ShowInvoiceAdditionalPrices: React.FC<ShowInvoiceAdditionalPricesProperties> = ({
                                                                                          additionalPrices
                                                                                      }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const tableHeader = ['Valor', i18n.translate("COMMENT")]
    const tableBody = additionalPrices.map(p => [CurrencyModule.format(p.amount), p.comment]);

    return (
        <div className={classes.container}>
            <div className={classes.info_container}>
                <Table title={'Adicionales'} tableType={TableType.SECONDARY} body={tableBody} header={tableHeader}/>
            </div>
        </div>
    );
};

export default ShowInvoiceAdditionalPrices;
