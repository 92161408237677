import React from 'react';
import ShowCodeProperties from "./ShowCodeProperties";
import {useStyles} from "./ShowCode.style";
import QrCodeIcon from '@mui/icons-material/QrCode';
import clsx from "clsx";

const ShowCode: React.FC<ShowCodeProperties> = ({
                                                          value,
                                                          className = '',
                                                      }) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_code_container, className)}>
            <QrCodeIcon className={classes.icon}/>
            <div className={classes.value_container}>
                {value}
            </div>
        </div>
    )
}


export default ShowCode;