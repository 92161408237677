import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_roles_container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        "& > div:not(:first-child)":{
            margin: '4px'
        }
    },
    icon: {
        color: theme.palette.grey[700],
        fontSize: '18px',
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800,
        width: '100%',
        margin: 0
    },
    empty: {
        "& $value_container,$icon": {
            fontStyle: 'italic',
            color: theme.palette.grey[400],
        }
    }
}));

export {useStyles}