const values = {
    "ERROR_00_401_00": "Error inesperado",
    "ERROR_00_401_01": "Correo o clave inválido",
    "ERROR_00_401_02": "Error de autenticación inesperado",
    "ERROR_00_401_03": "Dispositivo no autorizado",
    "ERROR_00_401_04": "No tiene acceso al servicio solicitado",
    "ERROR_00_401_05": "Error de autenticación de agencia inesperado",
    "ERROR_00_400_06": "Nombre de usuario requerido",
    "ERROR_00_400_07": "Email requerido",
    "ERROR_00_400_08": "El correo no es válido",
    "ERROR_00_400_09": "Ya existe un vendedor con este correo",
    "ERROR_00_400_10": "Ya existe un vendedor con este número de teléfono",
    "ERROR_00_400_20": "El correo no es válido",
    "ERROR_00_400_21": "Ya existe un cliente con este correo",
    "ERROR_00_400_213": "Email es requerido si desea recibir notificaciones por email",
    "ERROR_00_400_22": "Nombre de cliente requerido",
    "ERROR_00_400_23": "Ya existe un cliente con este nombre",
    "ERROR_00_400_24": "Ya existe un cliente con este número de teléfono",
    "ERROR_00_400_212": "Teléfono del cliente es obligatorio",
    "ERROR_00_400_02": "Ya existe una agencia con este nombre",
    "ERROR_00_400_25": "Apellidos de cliente requerido",
    "ERROR_00_400_27": "Dirección de cliente requerido",
    "ERROR_00_400_28": "Ciudad de cliente requerido",
    "ERROR_00_400_29": "Estado de cliente requerido",
    "ERROR_00_400_211": "ZIP Code de cliente requerido",
    "ERROR_00_400_30": "Nombre del contacto requerido",
    "ERROR_00_400_31": "Ya existe un contacto con ese nombre",
    "ERROR_00_400_32": "Ya existe un contacto con ese teléfono",
    "ERROR_00_400_33": "Apellido del contacto requerido",
    "ERROR_00_400_34": "Carnet de identidad del contacto requerido",
    "ERROR_00_400_35": "Email del contacto inválido",
    "ERROR_00_400_36": "Dirección del contacto requerido",
    "ERROR_00_400_53": "Municipio del contacto requerido",
    "ERROR_00_500_52": "Ya existe una tasa de cambio con esta modena",
    "ERROR_00_500_57": "Ya existe un cargo en el mismo rango de valores",

    //Custom category
    "ERROR_00_600_01":"Nombre de categoría requerida",
    "ERROR_00_600_02":"Ya existe una categoría con ese nombre",
    //Weight ranges
    "ERROR_00_601_01":"Valor de inicio requerido",
    "ERROR_00_601_02":"Valor final requerido",
    "ERROR_00_601_03":"Costo requerido",
    "ERROR_00_601_04":"Precio requerido",
    "ERROR_00_601_05":"Rango incorrecto, el valor inical no puede ser mayor que el valor final",
    "ERROR_00_601_06":"Interferencia con otro rango dentro de esta categoría",


    "AMOUNT_REQUIRED": "Saldo requerido",
    "CURRENCY_REQUIRED": "Tipo de moneda requerido",
    "ERROR": "Error inesperado",
    "SUCCESS": "Saldo actualizado con éxito",

    // Phone lengths
    "ERROR_00_400_03": "Teléfono de USA debe tener 10 dígitos",
    "ERROR_00_400_214": "Teléfono de USA debe tener 10 dígitos",
    "ERROR_00_400_37": "Teléfono de CUBA debe tener 8 dígitos",
    "ERROR_00_400_38": "Teléfono de CUBA debe tener 8 dígitos",
    "ERROR_00_400_39": "Teléfono de CUBA debe tener 8 dígitos",
    "ERROR_00_400_470": "Teléfono de CUBA debe tener 8 dígitos",
    "ERROR_00_400_480": "Teléfono de CUBA debe tener 8 dígitos",
}

export default values;