import React from 'react';
import {useStyles} from "../MoneyExchanges.style";
import clsx from "clsx";
import MoneyExchangeRowProperties from "./MoneyExchangeRowProperties";
import ShowPrice from '../../../shared_components/Info/ShowPrice/ShowPrice';


const MoneyExchangeRowSmall: React.FC<MoneyExchangeRowProperties> = ({moneyExchange, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.money_exchange_info_container_small)}>
            <ShowPrice value={moneyExchange.value} currency={moneyExchange.currency}/>
            <div className={classes.menu_container_small}>
                {}
            </div>
        </div>
    )
}

export default MoneyExchangeRowSmall;
