import React, {useEffect} from 'react';
import MoneyDeliverySalesProperties from "./MoneyDeliverySalesProperties";
import {useStyles} from "./MoneyDeliverySales.style";
import MoneyDeliverySalesStore from "./MoneyDeliverySalesStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import {useI18nContext} from "../../../contexts/I18nContext";
import PriceLockup from "../../../ui_components/PriceLockup/PriceLockup";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import Table from "../../../ui_components/Table/Table";
import {CurrencyModule} from "../../../util/Currency";
import TableType from "../../../ui_components/Table/TableType";
import {format} from "../../../util/PhoneFormatter"
import Tag from "../../../ui_components/Tag/Tag";
import ShowDate from "../../Info/ShowDate/ShowDate";
import ShowOrderStatus from "../../Info/ShowOrderStatus/ShowOrderStatus";
import ShowOrderNumber from "../../Info/ShowOrderNumber/ShowOrderNumber";
import clsx from "clsx";
import InfoIcon from "@material-ui/icons/Info";
import TagType from "../../../ui_components/Tag/TagType";

const MoneyDeliverySales: React.FC<MoneyDeliverySalesProperties> = ({
                                                                        from,
                                                                        to,
                                                                        sellerId,
                                                                        agencyId,
                                                                        title,
                                                                        className = '',
                                                                    }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const store = new MoneyDeliverySalesStore(commonStore, from, to, sellerId, agencyId);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    const Overview = () => {
        if (!store.sales) {
            return <></>
        }
        return (
            <>
                <div className={classes.overview_container}>
                    <PriceLockup value={store.sales.overview.totalSales}
                                 title={"Total de ventas"}
                                 size={30} theme="info"
                                 orders={store.sales.overview.orders}
                                 withBackground={true}
                    />
                    <PriceLockup value={store.sales.overview.payed.totalSales}
                                 title={"Pagadas"}
                                 size={30} theme="success"
                                 orders={store.sales.overview.payed.orders}
                                 withBackground={false}
                                 withBorder={false}
                    />
                    <PriceLockup value={store.sales.overview.pendingPayment.totalSales}
                                 title={"Pendientes de pago"}
                                 size={30} theme="danger"
                                 orders={store.sales.overview.pendingPayment.orders}
                                 withBackground={false}
                                 withBorder={false}
                    />
                </div>
                <div className={classes.prices_container}>
                    <Table
                        tableType={TableType.SECONDARY}
                        title={"Desglose por tipo de pago"}
                        header={["Tipo de pago", "Cantidad de ordenes", "Valor"]}
                        body={store.sales.salesByPaymentType.map(
                            (d, i) =>
                                ([
                                    i18n.translate(`PAYMENT_TYPE_${d.paymentType}`),
                                    d.overview.orders.toString(), CurrencyModule.format(d.overview.totalSales)
                                ]))
                        }
                    />
                    <Table
                        tableType={TableType.SECONDARY}
                        title={"Desglose por moneda"}
                        header={["Moneda", "Cantidad de ordenes", "Valor"]}
                        body={store.sales.salesByCurrency.map(
                            (d, i) =>
                                ([
                                    i18n.translate(`${d.currency}`),
                                    d.overview.orders.toString(), CurrencyModule.format(d.overview.totalSales)
                                ]))
                        }
                    />
                </div>
                <div className={classes.orders_container}>
                    <Table
                        title={`Ordenes (${store.sales.orders.length})`}
                        header={["", "Remitente", "Destinatario", "Venta", "Vendedor", "Estado"]}
                        body={store.sales.orders.map(d => (
                            [
                                <div>
                                    <ShowOrderNumber orderNumber={d.orderNumber}/>
                                    <ShowDate timestamp={d.createdAt}/>
                                </div>,
                                <div>
                                    <b>{d.client.name}</b>
                                    <br/>
                                    <i>{format(d.client.phone, true)}</i>
                                </div>,
                                <div style={{maxWidth: "200px"}}>
                                    <b>{d.contact.name}</b>
                                    <br/>
                                    <i>{format(d.contact.phone, true)}</i>
                                    <br/>
                                </div>,
                                <div>
                                    <b>Total a
                                        enviar: </b><i>{CurrencyModule.format(d.totalToReceive, d.currencyToReceive)}</i>
                                    <br/>
                                    <b>Total a pagar: </b><i>{CurrencyModule.format(d.totalToPay)}</i>
                                    <br/>
                                    <div className={classes.payment_type_container}>
                                        {d.paymentType.map((p, i) => (
                                            <Tag fitContent key={i} text={i18n.translate(`PAYMENT_TYPE_${p}`)}/>))}
                                        {!d.payed && <Tag fitContent text={"SIN PAGAR"} tagType={TagType.DANGER}/>}
                                    </div>

                                </div>,
                                <div>{d.seller.name}</div>,
                                <ShowOrderStatus status={d.status}/>
                            ]))}
                    />
                </div>
            </>
        );
    }

    return <Observer>{() => (
        <div className={clsx(classes.container, className)}>
            {title && <div className={classes.title}>{title}</div>}
            {!store.isEmpty() && <Overview/>}
            {(store.isEmpty() && !store.loading) &&
                <div className={classes.no_content_container} onClick={() => store.getElements()}>
                    <InfoIcon/>
                    {i18n.translate("NO_SALES")}
                </div>
            }
            <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                         text={`${i18n.translate("LOADING")} ${title || ''}`}/>
        </div>
    )}</Observer>

}


export default MoneyDeliverySales;