import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Client from "../../../entities/Client";
import {ClientService} from "../../../service/ClientService";

const ERROR_MAP = {
    email: ["ERROR_00_400_20", "ERROR_00_400_21", "ERROR_00_400_213"],
    phone: ["ERROR_00_400_24", "ERROR_00_400_212", "ERROR_00_400_214"],
    name: ["ERROR_00_400_22", "ERROR_00_400_23"],
    lastName: ["ERROR_00_400_25"],
    address: ["ERROR_00_400_27"],
    city: ["ERROR_00_400_28"],
    state: ["ERROR_00_400_29"],
    zipCode: ["ERROR_00_400_211"],
}

class NewClientStore {

    public clientId?: string;
    public client: Client;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;

    // Errors validators
    public emailError?: string;
    public phoneError?: string;
    public nameError?: string;
    public lastNameError?: string;
    public addressError?: string;
    public cityError?: string;
    public stateError?: string;
    public zipCodeError?: string;

    public commonStore: CommonStore;
    public agencyId?: string;


    constructor(commonStore: CommonStore, clientId?: string, agencyId?: string) {
        this.isEditing = !!clientId;
        this.clientId = clientId;
        this.isModalOpen = false;
        this.client = {} as Client;
        this.loading = false;
        this.commonStore = commonStore;
        this.agencyId = agencyId;
        makeAutoObservable(this);
    }

    @action
    public async loadClient() {
        if (this.clientId) {
            this.isEditing = true;
            const response = await ClientService.find(this.clientId!)
            if (response.success && response.data) {
                this.client = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadClient();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        this.client = {} as Client;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        this.client.agencyId = this.agencyId;
        const serviceToCall = this.isEditing ? ClientService.update : ClientService.create;
        const response = await serviceToCall(this.client);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        if (response.success) {
            this.client = response.data;
        }
        return response.success;
    }

    processError(error?: string) {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.nameError = undefined;
        this.lastNameError = undefined;
        this.addressError = undefined;
        this.cityError = undefined;
        this.stateError = undefined;
        this.zipCodeError = undefined;
        if (error) {
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.lastName.includes(error)) {
                this.lastNameError = error;
            }
            if (ERROR_MAP.address.includes(error)) {
                this.addressError = error;
            }
            if (ERROR_MAP.city.includes(error)) {
                this.cityError = error;
            }
            if (ERROR_MAP.state.includes(error)) {
                this.stateError = error;
            }
            if (ERROR_MAP.zipCode.includes(error)) {
                this.zipCodeError = error;
            }
        }

    }
}

export default NewClientStore;