import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";
import ArticleSalePrice from "../../../entities/ArticleSalePrice";
import Article from "../../../entities/Article";
import { ArticleService } from "../../../service/articleDelivery/ArticleService";

class ShowArticleCostsStore {
    public articlePrices: ArticleSalePrice[];
    public articles: Article[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.articlePrices = [];
        this.articles = [];
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get isEmpty(): boolean {
        return this.articlePrices.length === 0;
    }

    @action
    public async getArticles() {
        const response = await ArticleService.getAll();
        if (response.success && response.data) {
            this.articles = response.data;
        }
    }

    @action
    public async loadArticleSalePrices() {
        const response = await SalePriceService.getSalePriceFromAgency();
        if (response.success && response.data) {
            this.articlePrices = response.data.articleSalePrices;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadArticleSalePrices();
        await this.getArticles();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

}

export default ShowArticleCostsStore;