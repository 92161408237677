import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    page_container: {
        backgroundImage: "url(/webb.png)",
        backgroundPosition: 'bottom',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form_container: {
        boxSizing: 'border-box',
        position: 'relative',
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '6px 6px 0 0',
        padding: '8px 8px',
        textAlign: 'center',
    },
    body: {
        border: `1px solid ${theme.palette.grey["200"]}`,
        borderRadius: '0 0 6px 6px',
        padding: '32px 32px 32px 32px',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        "& button": {
            marginTop: '32px'
        }
    },
    check_list_container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
        fontSize: '16px',
        color: theme.palette.grey['400']
    },
    check_item_container: {
        display: 'flex',
        "& svg":{
            fontSize: '21px'
        }
    },
    valid:{
        color: theme.palette.success.light
    },
    details: {}
}));

export {useStyles}