import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";

class SellerSalesOverviewButtonStore {

    public sellerId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    constructor(commonStore: CommonStore, sellerId: string) {
        this.isModalOpen = false;
        this.sellerId = sellerId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async loadData(): Promise<void> {
        this.loading = true;
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.loadData();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

}

export default SellerSalesOverviewButtonStore;