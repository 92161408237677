import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    card_container: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    box_container: {
        display: 'flex',
        flexDirection: 'column'
    },
    card_content: {
        flex: '1 0 auto'
    },
    image: {
        height: '100px',
        width: '100px'
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px'
    },
    title: {
        fontWeight: 800
    },
    checkbox_container: {
        justifyContent: 'right'
    },
    filler: {
        flexGrow: 1,
        textAlign: 'center'
    },
    description_container: {
        height: '90px',
        paddingBottom: '20px',
        overflow: 'hidden'
    },
    expand_button_container: {
        textAlign: 'right',
        paddingBottom: '10px'
    },
    prices_container: {
        color: theme.palette.secondary.dark,
        fontSize: '19px',
        fontWeight: 600,
        margin: '5px 0'
    },
    prices_select_container: {
        display: 'flex',
        padding: '10px 0'
    },
    amount_input: {
        marginLeft: '5px'
    },
    amount_input_contaier: {
        width: '100px'
    }
}));

export {useStyles}