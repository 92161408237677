const values = {
    "PROMOTIONS": "Promociones",
    "PROMOTION": "Promoción",
    "NO_PROMOTIONS_TITLE": "No hay promociones!!!",
    "NO_PROMOTIONS_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "PROMOTION_FILTER_PLACEHOLDER": "Nombre",
    "EDIT_PROMOTION": "Editar promoción",
    "REMOVE_PROMOTION": "Eliminar promoción",
    "NEW_PROMOTION": "Nueva promoción",
    "PROMOTION_NAME": "Nombre de la promoción",
    "REMOVE_PROMOTION_CONFIRMATION": "¿Está seguro que desea eliminar la promoción?",
}

export default values;