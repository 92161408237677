import React from "react";
import InventoryItemRowProperties from "./DeliveryPersonInfoRowProperties";
import { useStyles } from "../ShowDeliveryPersonMulti.style";
import clsx from "clsx";

const InventoryItemRowSmall: React.FC<InventoryItemRowProperties> = ({
  onUpdate,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.items_inventory_info_container)}>
      {/* <div className={classes.menu_container_small}>
        <RemoveInventoryItemButton
          size={ButtonSize.SMALL}
          onSave={onUpdate}
          inventoryItem={inventoryItem}
          showText={false}
          type={ButtonType.SECONDARY}
        />
      </div> */}
    </div>
  );
};
export default InventoryItemRowSmall;
