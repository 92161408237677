import React, {useState} from 'react';
import {useStyles} from "./PriceLockup.style";
import PriceLockupProperties from "./PriceLockupProperties";
import clsx from "clsx";
import {CurrencyModule} from "../../util/Currency";
import Progress from "../Graph/Progress/Progress";
import Currency from "../../entities/Currency";


const PriceLockup: React.FC<PriceLockupProperties> = ({
                                                          className = '',
                                                          title,
                                                          value,
                                                          currency = Currency.DOLLAR,
                                                          total,
                                                          size = 30,
                                                          theme = "info",
                                                          orders,
                                                          children,
                                                          withBackground = false,
                                                          withBorder = false
                                                      }) => {
    const classes = useStyles();
    const [displayChildren, setDisplayChildren] = useState(false);
    const getTitleClass = () => {
        switch (theme) {
            case "info":
                return classes.info;
            case "success":
                return classes.success;
            case "danger":
                return classes.danger;
            case "primary":
                return classes.primary;
            case "secondary":
                return classes.secondary;
            case "warning":
                return classes.warning;
            case "purple":
                return classes.purple;
        }

    }

    const getTitleFontSize = () => {
        if (size > 20) return 11;
        if (size > 10) return 10;
        return 7;
    }
    const getTitleLineHeight = () => {
        if (size > 20) return 15;
        return 12;
    }

    const getOrdersText = (orders: number) => {
        if (orders === 1) return `1 orden`;
        return `${orders} ordenes`
    }
    return (
        <div
            className={
                clsx(
                    classes.lockup_container,
                    withBackground && theme === "info" && classes[`lockup_container_with_background_info`],
                    withBackground && theme === "primary" && classes[`lockup_container_with_background_primary`],
                    withBackground && theme === "secondary" && classes[`lockup_container_with_background_secondary`],
                    withBackground && theme === "danger" && classes[`lockup_container_with_background_danger`],
                    withBackground && theme === "warning" && classes[`lockup_container_with_background_warning`],
                    withBackground && theme === "purple" && classes[`lockup_container_with_background_purple`],
                    withBackground && theme === "success" && classes[`lockup_container_with_background_success`],
                    withBorder && classes.with_border,
                    className
                )}>
            <div className={clsx(classes.title, getTitleClass())}
                 style={{fontSize: getTitleFontSize(), lineHeight: `${getTitleLineHeight()}px`}}>{title}</div>
            <div className={classes.value_container}>
                <div className={classes.value}
                     style={{
                         fontSize: size,
                         lineHeight: `${size / 2}px`
                     }}>{CurrencyModule.format(value, currency)}</div>
                {children &&
                    <div className={classes.more_info_container} onClick={() => setDisplayChildren(!displayChildren)}>
                        {`${displayChildren ? "ocultar detalles" : "ver más"}`}
                    </div>}
            </div>
            <div className={classes.progress_container}>
                {!!total && <Progress total={total} progress={value} theme={theme}/>}
                {!!total && <div
                    className={classes.total}>{`~${Math.ceil(value * 100.0 / total)}% del total (${CurrencyModule.format(total)})`}</div>}
                {!!orders && <div className={classes.orders}>{getOrdersText(orders)}</div>}
            </div>
            {displayChildren && <div>
                {children}
            </div>}
        </div>
    )
}
export default PriceLockup;