import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";
import TopupSalePrice from "../../../entities/productOrder/topup/TopupSalePrice";
import { ProductService } from "../../../service/topup/ProductService";
import Product from "../../../entities/productOrder/topup/Product";

class ShowTopupCostsStore {
    public topupPrices: TopupSalePrice[];
    public products: Product[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.topupPrices = [];
        this.products = [];
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get isEmpty(): boolean {
        return this.topupPrices.length === 0;
    }

    @action
    public async getProducts() {
        const response = await ProductService.get();
        if (response.success && response.data) {
            this.products = response.data;
        }
    }

    @action
    public async loadTopupSalePrices() {
        const response = await SalePriceService.getSalePriceFromAgency();
        if (response.success && response.data) {
            this.topupPrices = response.data.topupSalePrices;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadTopupSalePrices();
        await this.getProducts();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

}

export default ShowTopupCostsStore;