import React from "react";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useI18nContext} from "../../../contexts/I18nContext";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import {useCommonContext} from "../../../contexts/CommonContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Icon from "../../../ui_components/Icon/Icon";
import IconName from "../../../ui_components/Icon/enums/IconName";
import Button from "../../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import RemoveCustomCategoryButtonProperties from "./RemoveCustomCategoryButtonProperties";
import RemoveCustomCategoryButtonStore from "./RemoveCustomCategoryButtonStore";
import { useUserContext } from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";


const RemoveCustomCategoryButton:React.FC<RemoveCustomCategoryButtonProperties> = ({
                                                                               onClick = () => {
                                                                               },
                                                                               onSave = () => {
                                                                               },
                                                                               showIcon = true,
                                                                               showText = true,
                                                                               disabled,
                                                                               className = '',
                                                                               customCategory,
                                                                               rounded = false,
                                                                               size = ButtonSize.DEFAULT,
                                                                               type = ButtonType.DEFAULT
                                                                           }) =>{
    const i18n = useI18nContext();
    const confirm = useConfirmation();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new RemoveCustomCategoryButtonStore(commonContext, customCategory);

    const handleOnclick = async () => {
        onClick();
        const ok = await confirm(customCategory.name, i18n.translate("REMOVE_CUSTOM_CATEGORY_CONFIRMATION"));
        if (ok) {
            if(await store.remove()){
                onSave();
            }
        }
    }

    const text = i18n.translate("REMOVE_CUSTOM_CATEGORY");
    if (!userStore.hasAccess(Access.REMOVE_CUSTOM_CATEGORY.valueOf())) {
        return <></>;
    }

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}
export default RemoveCustomCategoryButton