import React from 'react';
import CustomCategoryWeightRangeRowProperties from "./CustomCategoryWeightRangeRowProperties";
import ViewMedium from "../../../../../ui_components/MediaView/ViewMedium";
import CustomCategoryWeightRangeRowMedium from "./CustomCategoryWeightRangeRowMedium";
import ViewLarge from "../../../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../../../ui_components/MediaView/ViewSmall";
import CustomCategoryWeightRangeRowSmall from "./CustomCategoryWeightRangeRowSmall";


const CustomCategoryWeightRangeRow: React.FC<CustomCategoryWeightRangeRowProperties> = ({
                                                    customCategoryWeightRange,
                                                    customCategoryId,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <CustomCategoryWeightRangeRowSmall onUpdate={onUpdate} customCategoryWeightRange={customCategoryWeightRange} customCategoryId={customCategoryId}/>
            </ViewSmall>
            <ViewMedium>
                <CustomCategoryWeightRangeRowMedium onUpdate={onUpdate} customCategoryWeightRange={customCategoryWeightRange} customCategoryId={customCategoryId} />
            </ViewMedium>
            <ViewLarge>
                <CustomCategoryWeightRangeRowMedium onUpdate={onUpdate} customCategoryWeightRange={customCategoryWeightRange} customCategoryId={customCategoryId}/>
            </ViewLarge>
        </>
    )
}

export default CustomCategoryWeightRangeRow;
