import React from 'react';
import ShowCommentProperties from "./ShowCommentProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowComment.style";
import CommentIcon from '@material-ui/icons/Comment';
import clsx from "clsx";

const ShowComment: React.FC<ShowCommentProperties> = ({
                                                          value,
                                                          createdAt,
                                                          isOwnComment,
                                                          userName,
                                                          className = '',
                                                      }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    };

    return (
        <div className={clsx(classes.show_comment_container, className, !value && classes.empty, isOwnComment && classes.comment_right, !isOwnComment && classes.comment_left)}>
            <CommentIcon className={classes.icon}/>
            <div>
                <div className={classes.date_container}>
                    {createdAt && formatDate(createdAt)}
                </div>
                <div className={classes.value_container}>
                    {isOwnComment && <strong>Yo: </strong>} {!isOwnComment && <strong>{userName}: </strong>} {value || i18n.translate("NO_COMMENT")}
                </div>
            </div>
        </div>
    )
}


export default ShowComment;