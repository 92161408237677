import React from 'react';
import {useStyles} from "./ShowDeliveryCosts.style";
import ShowDeliveryCostsProperties from "./ShowDeliveryCostsProperties";
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";
import { CurrencyModule } from '../../../util/Currency';


const ShowDeliveryCosts: React.FC<ShowDeliveryCostsProperties> = ({
                                                            className = '',
                                                            deliveryCosts,
                                                            label
                                                        }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    return (
        <div className={classes.all_container}>
            {label && <div className={classes.label}>{`${label}:`}</div>}
            <div className={clsx(classes.show_balances_container, className)}>
                <div className={classes.balances_container}>
                    {deliveryCosts.map((deliveryCost, i) => (
                        <div key={i} className={classes.balance}>
                            {deliveryCost.cost > 0 && <span>
                                    {CurrencyModule.format(deliveryCost.cost, deliveryCost.currency)}
                                    <div className={classes.hint}>{i18n.translate(deliveryCost.currency)}</div>
                                </span>}
                            {deliveryCost.cost === 0 &&
                            <div className={classes.no_cost}>{i18n.translate("WITHOUT_COST")}</div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ShowDeliveryCosts;