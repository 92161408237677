import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ClientContact from "../../../entities/ClientContact";
import {ClientContactService} from "../../../service/ClientContactService";
import Geo from "../../../entities/Geo";
import { GeoLevel } from "../../Selects/GeoSelect/GeoSelectProperties";

const ERROR_MAP = {
    phone: ["ERROR_00_400_32", "INVALID_PHONE_NUMBER", "ERROR_00_400_37"],
    mobilePhone: ["ERROR_00_400_51", "INVALID_PHONE_NUMBER", "ERROR_00_400_38"],
    secondMobilePhone: ["ERROR_00_400_39"],
    name: ["ERROR_00_400_30", "ERROR_00_400_31"],
    lastName: ["ERROR_00_400_33"],
    documentId: ["ERROR_00_400_34"],
    email: ["ERROR_00_400_35"],
    address: ["ERROR_00_400_36"],
    geo: ["ERROR_00_400_53"],
}

class NewClientContactStore {

    public clientId: string;
    public contactId?: string;
    public clientContact: ClientContact;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;

    public selectedProvince?: Geo;

    // Validation erros
    public emailError?: string;
    public phoneError?: string;
    public mobilePhoneError?: string;
    public secondMobilePhoneError?: string;
    public nameError?: string;
    public lastNameError?: string;
    public addressError?: string;
    public geoError?: string;

    public commonStore: CommonStore;
    public agencyId?: string;


    constructor(commonStore: CommonStore, clientId: string, contactId?: string, agencyId?: string) {
        this.isEditing = !!contactId;
        this.clientId = clientId;
        this.contactId = contactId;
        this.isModalOpen = false;
        this.clientContact = {} as ClientContact;
        this.loading = false;
        this.commonStore = commonStore;
        this.agencyId = agencyId;
        makeAutoObservable(this);
    }

    @action
    public async loadContact() {
        if (this.contactId) {
            this.isEditing = true;
            const response = await ClientContactService.find(this.clientId, this.contactId!)
            if (response.success && response.data) {
                this.clientContact = response.data;
                if (this.clientContact && this.clientContact.geo && this.clientContact.geo.parentInfo) {
                    this.selectedProvince = new Geo(this.clientContact.geo.parentId, this.clientContact.geo.parentInfo, '', GeoLevel.STATE, true, 'Cuba');
                }
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadContact();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        this.clientContact = {} as ClientContact;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? ClientContactService.update : ClientContactService.create;
        this.clientContact.agencyId = this.agencyId;
        const response = await serviceToCall(this.clientContact, this.clientId);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        if (response.success) {
            this.clientContact = response.data;
        }
        return response.success;
    }

    processError(error?: string) {
        this.phoneError = undefined;
        this.mobilePhoneError = undefined;
        this.secondMobilePhoneError = undefined;
        this.nameError = undefined;
        this.emailError = undefined;
        this.lastNameError = undefined;
        this.addressError = undefined;
        this.geoError = undefined;
        if (error) {
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.mobilePhone.includes(error)) {
                this.mobilePhoneError = error;
            }
            if (ERROR_MAP.secondMobilePhone.includes(error)) {
                this.secondMobilePhoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.lastName.includes(error)) {
                this.lastNameError = error;
            }
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.address.includes(error)) {
                this.addressError = error;
            }
            if (ERROR_MAP.geo.includes(error)) {
                this.geoError = error;
            }
        }

    }
}

export default NewClientContactStore;