
class NewCommissionDeliveryPersonInfoRequestMultiOrders {
    public packageDeliveryIds: string[]
    public newCommission: number

    constructor(packageDeliveryIds: string[], newCommission: number){
        this.packageDeliveryIds = packageDeliveryIds
        this.newCommission = newCommission
    }
}
export default NewCommissionDeliveryPersonInfoRequestMultiOrders
