import {useStyles} from './ElementRow.style';
import React from "react";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ElementRowProperties from "./ElementRowProperties";
import clsx from "clsx";
import {format} from "../../../../../../util/PhoneFormatter";

const ClientElementRow: React.FC<ElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <AccountBoxSharpIcon/>
            </div>

            <div className={classes.text_container}>
                <div>Nombre: <b>{element.name} {element.lastName}</b></div>
                {element.phone && <div className={classes.phone_number_container}>Teléfono: <b>{`${format(element.phone)}`}</b></div>}
            </div>

        </div>
    )
}


export default ClientElementRow;