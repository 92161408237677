import React, {useEffect, useState} from 'react';
import ContactBankCardSelectProperties from "./ContactBankCardSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Select from '../../../ui_components/Select/Select';
import SelectOption from '../../../ui_components/Select/SelectOption';

const ContactBankCardSelect: React.FC<ContactBankCardSelectProperties> = ({
                                                                    contactId,
                                                                    onChange,
                                                                    disabled,
                                                                    fullWidth = false,
                                                                    className = '',
                                                                    contactBankCards,
                                                                    selected
                                                                }) => {
    const i18n = useI18nContext();

    const [loading] = useState(false);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<SelectOption>();

    const onContactBankCardChange = (option: SelectOption) => {
        onChange(option.value)
    }

    useEffect(() => {
        const opts = contactBankCards.map(p =>
            new SelectOption(
                p.cardNumber,
                p.id,
                p.bank,
                CreditCardIcon
            ));
        setOptions(opts);
    }, [contactBankCards, contactId]);

    useEffect(() => {
        if (selected) {
            const selectedOpt = options.find(t => t.value === selected?.id) || options[0];
            setSelectedOption(selectedOpt);
        } else {
            setSelectedOption(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, options])

    return (
        <Select
            loading={loading}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("CARD")}
            placeholder={i18n.translate("CARD_PLACEHOLDER")}
            icon={CreditCardIcon}
            onChange={onContactBankCardChange}
            selected={selectedOption}
            elements={options}
        />
    )
}


export default ContactBankCardSelect;