import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
        modal_container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: 0,
            padding: 0,
            "@media print": {
                zoom: '80%',
                width: '100%',
                height: "100%"
            }
        },
        view_invoice_container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
        },
        invoice_container: {
            display: 'flex',
            flexDirection: 'column',
            width: '800px',
            marginTop: '16px',
            height: 'calc(100vh - 185px)',
            overflow: 'auto',
            overflowX: 'hidden',
            backgroundColor: theme.palette.common.white,
            borderRadius: '4px',
            padding: '16px',
            border: `1px solid ${theme.palette.grey[200]}`,
            "@media print": {
                width: '100%',
                boxShadow: 'none',
                padding: '0',
                border: `none`,
                height: "100%",
                overflow: 'hidden',
                overflowX: 'hidden',
            }
        },
        toolbar_container: {
            display: "flex",
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.common.white,
            padding: '16px',
            "@media print": {
                display: 'none'
            },
            "& > *:not(:last-child)": {
                marginRight: '16px'
            }
        },
        separator: {
            width: '100%',
            height: '1px',
            borderTop: `2px dotted ${theme.palette.grey[400]}`,
            margin: '40px 0'
        },
        invoice_data_container: {
            border: `1px solid ${theme.palette.grey[900]}`,
            display: 'flex',
            padding: '8px',
            flexDirection: 'column',
            borderRadius: '8px',
            position: 'relative',
        },
        water_mark: {
            position: 'absolute',
            width: '100%',
            height: "100%",
            transform: "rotate(-30deg)",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '80px',
            textTransform: "uppercase",
            color: '#00968824'
        },
        canceled: {
            color: '#ff000024'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxWidth: '50%',
            "& svg": {
                maxWidth: '300px',
                maxHeight: '100px'
            }
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        last_column: {
            alignItems: 'flex-end'
        },
        agency_name: {
            fontSize: '24px',
            textTransform: 'uppercase',
            fontWeight: 900
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontSize: "20px",
            textAlign: 'center',
            textTransform: 'uppercase'
        },
        subtitle: {
            fontSize: "15px",
            fontWeight: 800
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '4px',
            "& td": {
                border: `1px solid ${theme.palette.grey[400]}`,
                padding: '0 4px'
            }
        },
        table_header: {
            backgroundColor: theme.palette.grey[200],
            textAlign: 'center',
            fontWeight: 800
        },
        table_footer: {
            backgroundColor: theme.palette.grey[200],
            fontWeight: 800,
            "& td:first-child": {
                textAlign: 'end'
            }
        },
        sign_container: {
            width: '100%',
            height: '1px',
            borderTop: `2px solid ${theme.palette.grey[800]}`,
            marginTop: '80px'
        },
        payment_row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            "& > *:first-child": {
                marginRight: '4px',
            }
        },
        payments_container: {},
        qr_container: {
            border: `1px solid #ccc`,
            width: 'fit-content',
            flexGrow: 0,
            height: 'fit-content',
            padding: '8px',
            borderRadius: '4px'
        },
        fee: {
            fontSize: '13px',
            color: theme.palette.grey[400],
            marginLeft: '4px'
        },
        text_nowrap: {
            whiteSpace: 'nowrap'
        }
    }))
;

export {useStyles}