import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme/default';
import {I18nContextProvider} from "./contexts/I18nContext";
import {UserContextProvider} from "./contexts/UserContext";
import {CommonContextProvider} from "./contexts/CommonContext";
import I18nStore from "./stores/I18nStore";
import Language from "./i18n/Language";
import {BreakpointProvider} from 'react-socks';
import UserStore from "./stores/UserStore";
import CommonStore from "./stores/CommonStore";
import {ConfirmProvider} from "material-ui-confirm";
import {ToastProvider} from 'react-toast-notifications';
import {useErrorToast, useSuccessToast} from "./hooks/Toast";


const Providers: React.FC = ({
                                 children
                             }
) => {
    const i18NStore = new I18nStore(Language.ES);
    const userStore = new UserStore();

    const OtherProviders = () =>{
        const errorToast = useErrorToast();
        const successToast = useSuccessToast();
        const commonStore = new CommonStore(userStore, errorToast, successToast);
        return (
            <CommonContextProvider value={commonStore}>
                <ThemeProvider theme={theme}>
                    <ConfirmProvider>
                        <BreakpointProvider>
                            {children}
                        </BreakpointProvider>
                    </ConfirmProvider>
                </ThemeProvider>
            </CommonContextProvider>
        )
    }


    return (
        <I18nContextProvider value={i18NStore}>
            <UserContextProvider value={userStore}>
                <ToastProvider>
                   <OtherProviders/>
                </ToastProvider>
            </UserContextProvider>
        </I18nContextProvider>

    );
}

export default Providers;
