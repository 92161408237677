import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    stepper_container: {
        width: '80vw',
        "@media (max-width: 800px)": {
            width: '100vw',
        }
    }
}));

export {useStyles}