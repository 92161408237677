import React from 'react';
import {useStyles} from "./Table.style";
import TableProperties from "./TableProperties";
import clsx, {ClassValue} from "clsx";
import TableType from "./TableType";


const Table: React.FC<TableProperties> = ({
                                              title,
                                              body,
                                              header,
                                              tableType = TableType.DEFAULT,
                                              className = '',
                                          }: TableProperties) => {
    const classes = useStyles();

    const render = (element: any) => {
        return element;
    }

    const getTypedClass = (): ClassValue => {
        switch (tableType) {
            case TableType.DEFAULT:
                return classes.default;
            default:
            case TableType.PRIMARY:
                return classes.primary;
            case TableType.SECONDARY:
                return classes.secondary;
        }
    }

    const containerClasses = clsx(
        className,
        classes.table_container);

    return (
        <div className={containerClasses}>
            <div className={classes.title}>{title}</div>
            <table className={classes.table}>
                <thead>
                <tr>
                    {header.map((e, i) => (
                        <td className={clsx(getTypedClass())} key={i}>{render(e)}</td>
                    ))}
                </tr>
                </thead>
                <tbody>
                {body.map((b, i1) => (
                    <tr key={i1}>
                        {b.map((c, i2) => (
                            <td key={i2}>{render(c)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
export default Table;