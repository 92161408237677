import React from 'react';
import {useStyles} from "../Articles.style";
import clsx from "clsx";
import ArticleRowProperties from "./ArticleRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewArticleButton from "../../../shared_components/Buttons/NewArticleButton/NewArticleButton";


const ArticleRowSmall: React.FC<ArticleRowProperties> = ({article, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.article_info_container_small)}>
            <ShowSellerBasicInfo autofill name={article.name} email={article.description}/>
            <div className={classes.menu_container_small}>
                <NewArticleButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    articleId={article.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
            </div>
        </div>
    )
}

export default ArticleRowSmall;
