const values= {
    "REFERENCE_ITEMS": "Artículos de referencia",
    "REFERENCE_ITEMS_PLACEHOLDER": "Nombre de artículo",
    "ITEMS": "Artículos de referencia",
    "NO_REFERENCE_ITEMS_TITLE": "No hay artículo de referencia!!!",
    "NO_REFERENCE_ITEMS_SUBTITLE": "Verifica si estás aplicando algún filtro.",
    "NEW_REFERENCE_ITEM": "Nuevo artículo de referencia",
    "EDIT_REFERENCE_ITEM": "Editar artículo de referencia",
    "REFERENCE_ITEM_NAME": "Nombre de artículo de referencia",
    "REMOVE_REFERENCE_ITEMS_MANAGER": "Eliminar artículo de referencia",
    "REMOVE_REFERENCE_ITEMS_CONFIRMATION": "¿Esta seguro que desea eliminar el artículo de referencia?",
}
export default values;