import React from 'react';
import {useStyles} from "./ShowPaginationSmall.style";
import ShowPaginationProperties from "../ShowPaginationProperties";
import clsx from "clsx";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';


const PageComponentsSmall: React.FC<ShowPaginationProperties> = ({
                                                                      pagination,
                                                                      onChange
                                                                  }) => {
    const classes = useStyles();

    const goToPage = (page: number) => {
        if (page > pagination.pageCount || page <= 0) return;
        if (page === pagination.currentPage) return;
        onChange(page, pagination.pageSize);
    }

    return (
        <div className={classes.page_elements_small}>
            <div onClick={() => goToPage(1)}
                 className={clsx(classes.page_button_small, pagination.currentPage === 1 && classes.disabled)}>
                <FirstPageIcon/>
            </div>
            <div onClick={() => goToPage(pagination.currentPage - 1)}
                 className={clsx(classes.page_button_small, pagination.currentPage === 1 && classes.disabled)}>
                <ChevronLeftIcon/>
            </div>
            <div className={classes.current_page_small}>{`${pagination.currentPage}`}</div>
            <div
                onClick={() => goToPage(pagination.currentPage + 1)}
                className={clsx(classes.page_button_small, pagination.currentPage === pagination.pageCount && classes.disabled)}>
                <ChevronRightIcon/>
            </div>
            <div
                onClick={() => goToPage(pagination.pageCount)}
                className={clsx(classes.page_button_small, pagination.currentPage === pagination.pageCount && classes.disabled)}>
                <LastPageIcon/>
            </div>
        </div>
    )
}
export default PageComponentsSmall;