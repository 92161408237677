import React from 'react';
import {useStyles} from "./ShowText.style";
import ShowTextProperties from "./ShowTextProperties";
import HighlightText from "../../../ui_components/HighlightText/HighlightText";


const ShowText: React.FC<ShowTextProperties> = ({
                                                    text,
                                                    title = '',
                                                    className = '',
                                                    buttons = [],
                                                    textToHighlight
                                                }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <>
                {title && <div className={classes.title}>{`${title}:`}</div>}
                <HighlightText textToHighlight={[textToHighlight || '']} text={text || '-'}/>
                <div>
                    {buttons && buttons.length > 0 && buttons.map(t => (
                        <span>{t}</span>
                    ))}
                </div>
            </>
        </div>
    )
}
export default ShowText;