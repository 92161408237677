import AddPaymentRequest from "../payment/AddPaymentRequest";
import ItemsAndCategories from "./ItemsAndCategories";

class UpdateItemsAndPaymentsRequest {
    public itemsAndCategories: ItemsAndCategories[]
    public payments: AddPaymentRequest[]
    public totalToPay?: number
    public paymentFee?: number
    public serviceCost?: number

    constructor(itemsAndCategories:ItemsAndCategories[], payments: AddPaymentRequest[], totalToPay?: number, paymentFee?: number, serviceCost?: number){
        this.itemsAndCategories = itemsAndCategories
        this.payments = payments
        this.totalToPay = totalToPay
        this.paymentFee = paymentFee
        this.serviceCost = serviceCost
    }
}
export default UpdateItemsAndPaymentsRequest