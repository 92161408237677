import React, { useState } from 'react';
import NewMoneyDeliveryFeeButtonProperties from "./NewMoneyDeliveryFeeButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewMoneyDeliveryFeeStore from "./NewMoneyDeliveryFeeStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewMoneyDeliveryFeeButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import Access from "../../Access/AllowAccess/Access";
import {useUserContext} from "../../../contexts/UserContext";
import PriceInput from '../../Inputs/PriceInput/PricetInput';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import {Percent} from "@mui/icons-material";
import MoneyDeliveryFeeCurrencyValue from '../../../entities/productOrder/moneyDelivery/MoneyDeliveryFeeCurrencyValue';

const NewMoneyDeliveryFeeButton: React.FC<NewMoneyDeliveryFeeButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  moneyServiceFeeId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewMoneyDeliveryFeeStore(commonContext, moneyServiceFeeId);

    const userStore = useUserContext();
    if (!userStore.hasAccess(Access.CREATE_MONEY_DELIVERY_FEE.valueOf())) {
        return <></>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onFromValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFromValue = event.target.value;
        store.moneyServiceFee.fromValue = +newFromValue;
    }
    
    const onToValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newToValue = event.target.value;
        store.moneyServiceFee.toValue = +newToValue;
    }

    const CurrencyValueRow: React.FC<{ currencyValue: MoneyDeliveryFeeCurrencyValue }> = ({currencyValue}) => {
        const [isPercent, setIsPercent] = useState<boolean>(currencyValue.isPercentage)
        const onValueChange = (newValue: number) => {
            currencyValue.value = newValue;
        }

        const onIsPercentChange = (newValue: boolean) => {
            currencyValue.isPercentage = newValue;
            setIsPercent(newValue);
        }

        return (
            <div className={classes.payment_fee_row_container}>
                <div className={classes.payment_type}>
                    {i18n.translate(currencyValue.currency)}
                </div>
                <PriceInput onChange={onValueChange} value={currencyValue.value} icon={isPercent ? Percent : undefined}
                            label={isPercent ? "Porciento" : "Valor fijo"}/>
                <Checkbox text={"Valor en %"} onChange={onIsPercentChange} selected={isPercent}/>
            </div>
        )
    }

    const onServiceFeeSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_MONEY_SERVICE_FEE" : "NEW_MONEY_SERVICE_FEE"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={store.moneyServiceFee?.fromValue}
                                onChange={onFromValueChange}
                                error={i18n.translate(store.fromError)}
                                label={i18n.translate("FROM")}
                                className={classes.input}
                                fullWidth
                            />
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={store.moneyServiceFee?.toValue}
                                onChange={onToValueChange}
                                error={i18n.translate(store.toError)}
                                label={i18n.translate("TO")}
                                className={classes.input}
                                fullWidth
                            />
                            
                            <div className={classes.currency_value_container}>
                                {store.moneyServiceFee && 
                                    store.moneyServiceFee?.currenciesValues &&
                                        store.moneyServiceFee?.currenciesValues.map((currencyValue, i) => (
                                            <CurrencyValueRow currencyValue={currencyValue} key={i}/>
                                ))}
                            </div>

                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onServiceFeeSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewMoneyDeliveryFeeButton;
