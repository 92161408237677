import React from 'react';
import {useStyles} from "./ShowDeliveryPersonMulti.style";
import ShowDeliveryPersonProperties from "./ShowDeliveryPersonMultiProperties";
import AllowAccess from "../../Access/AllowAccess/AllowAccess";
import Access from "../../Access/AllowAccess/Access";
import { useUserContext } from '../../../contexts/UserContext';
import ChooseDeliveryPersonLinksMulti from '../../Buttons/ChooseDeliveryPersonLinksMulti/ChooseDeliveryPersonLinksMulti';
import ProductOrderDeliveryPersonStatus from '../../../entities/productOrder/ProductOrderDeliveryPersonStatus';
import { CheckCircle, DoDisturbOn, LocalShipping, Place } from '@mui/icons-material';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';

const ShowDeliveryPersonMulti: React.FC<ShowDeliveryPersonProperties> = ({ 
                                                          multiOrders = false,
                                                          deliveryOrderIds, 
                                                          deliveryOrderNumbers,
                                                          currentDeliveryPersonInfo,
                                                          text,
                                                          showActionLinks = true,
                                                          orderByGeosIdProximity = [],
                                                          onClick = () => {
                                                          },
                                                          onSelect = () => {
                                                          },
                                                          onUpdate,
                                                          className = '',
                                                          showIcon = true,
                                                          showText = true,
                                                          disabled,
                                                          rounded = false,
                                                          size = ButtonSize.DEFAULT,
                                                          type = ButtonType.DEFAULT
                                                      }) => {
    const classes = useStyles();
    const userStore = useUserContext();

    const getIcon = () => {
        switch (currentDeliveryPersonInfo?.deliveryPersonOrderStatus) {
            case ProductOrderDeliveryPersonStatus.ASSIGNED:
                return <DoDisturbOn/>;
            case ProductOrderDeliveryPersonStatus.PENDING:
                return <LocalShipping/>;
            case ProductOrderDeliveryPersonStatus.RECEIVED:
                return <Place/>;
            case ProductOrderDeliveryPersonStatus.DELIVERED:
                return <CheckCircle/>
        }
    }
    const getClass = () => {
        switch (currentDeliveryPersonInfo?.deliveryPersonOrderStatus) {
            case ProductOrderDeliveryPersonStatus.ASSIGNED:
                return classes.pending;
            case ProductOrderDeliveryPersonStatus.PENDING:
                return classes.warning;
            case ProductOrderDeliveryPersonStatus.RECEIVED:
                return classes.warning;
            case ProductOrderDeliveryPersonStatus.DELIVERED:
                return classes.success;
            
        }
    }

    if (!userStore.isRootAgency()) {
        return <></>;
    }

    return (
        <div className={className}>
            <>
                {multiOrders===false && <div className={classes.title}>{`Repartidor:`}</div>}
                <div  className={getClass()}>
                    {multiOrders===false && 
                        <div className={classes.delivery_person_container}>
                            {getIcon()}
                            <div>{currentDeliveryPersonInfo ? currentDeliveryPersonInfo.deliveryPersonName : '-'}</div>
                        </div>
                    }                    
                    {showActionLinks && <AllowAccess any={[Access.SET_MONEY_DELIVERY_DELIVERY_PERSON]}>
                        <ChooseDeliveryPersonLinksMulti 
                            multiOrders = {multiOrders}
                            deliveryOrderIds={deliveryOrderIds} 
                            deliveryOrderNumbers={deliveryOrderNumbers}
                            haveDeliveryPersonSelected={currentDeliveryPersonInfo!=null}
                            orderByGeosIdProximity={orderByGeosIdProximity}
                            onClick={onClick}
                            onSelect={onSelect}
                            onUpdate={onUpdate} 
                            disabled={disabled}
                            showText={showText}
                            showIcon={showIcon}
                            rounded={rounded}
                            size={size}
                            type={type}/>
                    </AllowAccess>}
                </div>
            </>
        </div>
    )
}
export default ShowDeliveryPersonMulti;
