import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        minWidth: '300px',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    input: {
        marginTop: '16px'
    },
    margin_right_5: {
        marginRight: "5px"
    },
    full_width: {
        width: '100%'
    },
    show_article_price_table: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        width: '100%'
    },
    show_article_price_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: "repeat( auto-fit, minmax(125px, 1fr))",
        gridColumnGap: '8px',
        gridRowGap: '8px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        width: '100%',
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    show_article_price_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        width: '100%',
    },
}));

export {useStyles}