import CustomCategory from "../../../model/packageDelivery/customCategory/CustomCategory";
import {Config} from "../../../util/Config";
import {RestClient} from "../../../util/RestClient";
import Request from "../../../model/Request";
import Response from "../../../model/Response";
import PagedResponse from "../../../entities/PagedResponse";
import Pagination from "../../../entities/Pagination";

export module CustomCategoryService{

    export async function get(page:number=1,pageSize:number=15, filter?:string):Promise<Response<PagedResponse<CustomCategory>>>{
        const url = Config.get("GET_CUSTOM_CATEGORY_URL",{
            page,
            pageSize,
            filter,
        })

        const request = new Request(url)
        const response = await RestClient.get(request)

        if(response.success){
            const resultData = response.data?.data
            const paginationData = response.data?.pagination

            if(resultData && paginationData){
                const data = resultData.map((t:any)=> new CustomCategory(
                    t.id,
                    t.name,
                    t.description,
                    t.createdAt,
                    t.weightRanges,
                    t.categoryType
                ))
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount,paginationData.pageCount)
                return new Response<PagedResponse<CustomCategory>>(true,{data,pagination}, response.error)
            }
        }
        return new Response<PagedResponse<CustomCategory>>(false,undefined,response.error,response.status)
    }

    export async function create(customCategory: CustomCategory):Promise<Response<any>>{
        const url = Config.get("CREATE_CUSTOM_CATEGORY_URL")
        const request = new Request(url, customCategory);
        return await RestClient.post(request);
    }

    export async function update(customCategory: CustomCategory):Promise<Response<any>>{
        const url = Config.get("UPDATE_CUSTOM_CATEGORY_URL",{id:customCategory.id})
        const request = new Request(url,{
            name: customCategory.name,
            description:customCategory.description,
            categoryType:customCategory.categoryType
        })
        return await RestClient.put(request)
    }

    export async function find(id:string):Promise<Response<CustomCategory>>{
        const url = Config.get("FIND_CUSTOM_CATEGORY_URL",{id})
        const request = new Request(url)
        const response = await RestClient.get(request)

        if(response.success){
            const customCategoryData = response.data
            if (customCategoryData){
                const customCategory = new CustomCategory(
                    customCategoryData.id,
                    customCategoryData.name,
                    customCategoryData.description,
                    customCategoryData.createdAt,
                    customCategoryData.weightRanges,
                    customCategoryData.categoryType)
                return new Response<CustomCategory>(true,customCategory,response.error)
            }
        }
        return new Response<CustomCategory>(true,undefined,response.error,response.status)
    }

    export async function remove(customCategory:CustomCategory):Promise<Response<any>>{
        const url = Config.get("REMOVE_CUSTOM_CATEGORY_URL", {id: customCategory.id})
        const request = new Request(url)
        return await RestClient.remove(request)
    }
}