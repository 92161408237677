import React from 'react';
import {useStyles} from "../MoneyDelivery.style";
import clsx from "clsx";
import {motion} from "framer-motion";
import MoneyDeliveryRowProperties from "./MoneyDeliveryRowProperties";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import RemoveMoneyDeliveryButton from '../../../shared_components/Buttons/RemoveMoneyDeliveryButton/RemoveMoneyDeliveryButton';
import ShowMoneyDeliveryAmounts from '../../../shared_components/Info/ShowMoneyDeliveryAmounts/ShowMoneyDeliveryAmounts';
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import Currency from '../../../entities/Currency';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowContactBankCard from '../../../shared_components/Info/ShowContactBankCard/ShowContactBankCard';
import ShowDeliveryPerson from '../../../shared_components/Info/ShowDeliveryPerson/ShowDeliveryPerson';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import Tag from '../../../ui_components/Tag/Tag';
import TagType from '../../../ui_components/Tag/TagType';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import ObservationsButton from '../../../shared_components/Buttons/ObservationsButton/ObservationsButton';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';


const MoneyDeliveryRowSmall: React.FC<MoneyDeliveryRowProperties> = ({
                                                                                    moneyDeliveryOrder,
                                                                                    selected,
                                                                                    onMoneyDeliveryOrderClick,
                                                                                    onMoneyDeliveryOrderSelect,
                                                                                    onDeliveryPersonSelect,
                                                                                    onUpdate
                                                                                }) => {

    const classes = useStyles();

    const onMoneyDeliverySelect = () => {
        onMoneyDeliveryOrderSelect(moneyDeliveryOrder.id);
    }

    const onRowMoneyRemittanceClick = () => {
        onMoneyDeliveryOrderClick(moneyDeliveryOrder.id);
    }

    return (
        <div className={clsx(classes.money_info_container_small)}>
            <div className={classes.first_row_small}>
                <div>
                    <div className={classes.order_number_container}>
                        <Checkbox text={''} onChange={onMoneyDeliverySelect}
                              selected={selected}/>
                        <ShowOrderNumber orderNumber={moneyDeliveryOrder.orderNumber}/>
                    </div>
                    <ShowDate label={'Fecha'} timestamp={moneyDeliveryOrder.createdAt} className={classes.margin_top_4}/>
                </div>
                <ShowMoneyDeliveryAmounts
                    moneyDeliveryOrder={moneyDeliveryOrder}
                    valueToPay={moneyDeliveryOrder.totalToPay}
                    currencyToPay={Currency.DOLLAR}
                    valueToReceive={moneyDeliveryOrder.totalToReceive}
                    currencyReceive={moneyDeliveryOrder.currencyToReceive}/>
            </div>
            <div className={classes.second_row_small}>
                <ShowText text={moneyDeliveryOrder.clientName} title={'Remitente'}/>
                <div>
                    <ShowText text={moneyDeliveryOrder.contactName} title={'Destinatario'}/>
                    {moneyDeliveryOrder.contactPhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.contactPhone} showFlag={false}/>}
                </div>
            </div>
            <div>
                {moneyDeliveryOrder && moneyDeliveryOrder.isBankCard &&
                    <div><small>Envío de tarjeta</small>
                        <div><ShowContactBankCard bank={moneyDeliveryOrder.contactBankCardBank}
                                                  cardNumber={moneyDeliveryOrder.contactBankCardNumber}></ShowContactBankCard>
                        </div>
                    </div>}

                {moneyDeliveryOrder && !moneyDeliveryOrder.isBankCard &&
                    <div><small>Entrega</small>
                        <div><ShowText text={moneyDeliveryOrder.address}
                                       title={moneyDeliveryOrder.geo.name + '. ' + moneyDeliveryOrder.geo.parentInfo}/>
                        </div>
                    </div>}
            </div>
            <div className={classes.second_row_small}>
                <div>
                    <ShowDeliveryPerson
                        text={moneyDeliveryOrder.deliveryPersonName}
                        showActionLinks={moneyDeliveryOrder.status !== 'DELIVERED' && moneyDeliveryOrder.status !== 'CANCELED' && moneyDeliveryOrder.status !== 'NOT_DELIVERED'}
                        orderByGeosIdProximity={[moneyDeliveryOrder.geo.id]}
                        onClick={onRowMoneyRemittanceClick}
                        onSelect={onDeliveryPersonSelect}
                    />
                </div>
                <div>
                    <ShowText text={moneyDeliveryOrder.agencyName} title={'Agencia'}/>
                    <ShowText text={moneyDeliveryOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
                </div>
                <div className={classes.status_container}>
                    {!moneyDeliveryOrder.payed && <Tag text={"SIN PAGAR"} tagType={TagType.DANGER}/>}
                    <ShowOrderStatus status={moneyDeliveryOrder.status}/>
                    {moneyDeliveryOrder.status !== ProductOrderStatus.DELIVERED &&
                        moneyDeliveryOrder.status !== ProductOrderStatus.CANCELED &&
                        <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={moneyDeliveryOrder.id}/>}
                    {moneyDeliveryOrder.status === ProductOrderStatus.DELIVERED &&
                        <ProductOrderStatusHistoryButton
                            detailsLink
                            productOrderId={moneyDeliveryOrder.id}
                        />}
                </div>
            </div>
            <div className={classes.menu_container_small}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
            </div>
            <div className={clsx(classes.menu_container_small,classes.margin_top_n10px)}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <ObservationsButton
                        size={ButtonSize.SMALL}
                        deliveryOrderId={moneyDeliveryOrder.id}
                        deliveryOrderNumber={moneyDeliveryOrder.orderNumber}
                        deliveryOrderObservations={moneyDeliveryOrder.observations}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
                <AllowAccess isRootAgency={true}>
                    <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderId={moneyDeliveryOrder.id}
                            deliveryOrderNumber={moneyDeliveryOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </motion.div>
                </AllowAccess>
            </div>
            <div className={clsx(classes.menu_container_small,classes.margin_top_n10px)}>
                <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}>
                    <RemoveMoneyDeliveryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        moneyDeliveryOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </motion.div>
            </div>
        </div>
    )
}

export default MoneyDeliveryRowSmall;
