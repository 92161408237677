import React, { useState } from 'react';
import AddPaymentProperties from "./AddPaymentProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import AddPaymentStore from "./AddPaymentStore";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AddPayment.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PriceInput from "../../Inputs/PriceInput/PricetInput";
import PaymentTypeSelect from "../../Selects/PaymentTypeSelect/PaymentTypeSelect";
import ShowPayment from "../../Info/ShowPayment/ShowPayment";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import Alert from "../../../ui_components/Alert/Alert";
import AlertType from "../../../ui_components/Alert/AlertType";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import PaymentTypeResponse from "../../../model/payment/PaymentTypeResponse";
import PaymentType from "../../../entities/PaymentType";
import ShowField from "../../Info/ShowField/ShowField";
import {CurrencyModule} from '../../../util/Currency';
import clsx from "clsx";
import CommentInput from '../../Inputs/CommentInput/CommentInput';
import OrderAdditionalPrice from '../../../entities/OrderAdditionalPriceRequest';
import ShowAdditionalPrice from '../../Info/ShowAdditionalPrice/ShowAdditionalPrice';
import { useUserContext } from '../../../contexts/UserContext';


const AddPayment: React.FC<AddPaymentProperties> = ({
                                                        total,
                                                        onPaymentsChange = () => {
                                                        },
                                                        currentPayments = [],
                                                        disabled,
                                                        className = '',
                                                        agencyId,
                                                        showAdditionalPrices = false,
                                                        additionalPrices = [],
                                                        onAdditionalPriceChange = () => {
                                                        },
                                                    }) => {
    const classes = useStyles();

    const i18n = useI18nContext();
    const userStore = useUserContext();
    
    const store = new AddPaymentStore(total, additionalPrices, userStore.currentUser()?.id, currentPayments);

    // Additional payment type

    const [additionalAmount, setAdditionalAmount] = useState(0);
    const [additionalComment, setAdditionalComment] = useState("");
    const [isAdditionalPercent, setIsAdditionalPercent] = useState<boolean>(false);

    const addAdditionalPrice = () => {
        store.addAdditionalPrice(additionalAmount, additionalComment, isAdditionalPercent);
        onAdditionalPriceChange(store.additionalPrices);
        setAdditionalAmount(0);
        setAdditionalComment('');
        setIsAdditionalPercent(false);
    }

    const removeAdditionalPrice = (additionalPrice: OrderAdditionalPrice) => {
        store.removeAdditionalPrice(additionalPrice.amount, additionalPrice.comment, additionalPrice.isPercentage);
        onAdditionalPriceChange(store.additionalPrices);
    }

    // *****

    const addPayment = () => {
        store.addPayment();
        onPaymentsChange(store.payments, !store.canAddMorePayments);
    }

    const removePayment = (payment: AddPaymentRequest) => {
        store.removePayment(payment);
        onPaymentsChange(store.payments, !store.canAddMorePayments);
    }

    const onPaymentTypeChange = (paymentTypeResponse?: PaymentTypeResponse) => {
        store.selectedPaymentType = paymentTypeResponse || new PaymentTypeResponse(0, PaymentType.CASH, false);
    }

    return <Observer>
        {() => (
            <div className={clsx(classes.payment_container, className)}>
                <div className={classes.body}>
                    <div className={classes.inputs_container}>
                        <PriceInput error={i18n.translate(store.error)} value={store.selectedPrice}
                                    onChange={(price) => {
                                        store.selectedPrice = price;
                                    }}/>
                        <PaymentTypeSelect selected={store.selectedPaymentType.paymentType}
                                           agencyId={agencyId}
                                           onChange={onPaymentTypeChange}/>
                        <Button disabled={!store.canAddMorePayments} onClick={addPayment}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("ADD_PAYMENT")}/>
                    </div>
                    
                    {!store.isEmpty && <div className={clsx(classes.elements_container, classes.margin_top_2px)}>
                        {store.payments.map((payment, i) => (
                            <div key={i} className={classes.element_row}>
                                {payment.isEditable && payment.userId === userStore.currentUser()?.id && <div className={classes.remove_button}>
                                    <Button type={ButtonType.SECONDARY} size={ButtonSize.SMALL}
                                            startIcon={<DeleteForeverSharpIcon/>} onClick={() => {
                                        removePayment(payment)
                                    }}/>
                                </div>}
                                <ShowPayment 
                                    amount={payment.amount}
                                    paymentType={payment.paymentTypeResponse.paymentType}
                                    extraFee={payment.getExtraFee}/>
                            </div>
                        ))}
                    </div>}

                    {store.isEmpty &&
                    <MessageCard className={classes.empty_card}
                                 title={i18n.translate("NO_PAYMENTS_TITLE")}
                                 subtitle={i18n.translate("NO_PAYMENT_SUBTITLE")}/>}

                    {store.canAddMorePayments && !store.error && <Alert
                        text={`${i18n.translate("REMAINING_PAYMENT")}: ${CurrencyModule.format(store.getRemaining)}`}
                        type={AlertType.INFO}/>}
                    {!store.canAddMorePayments && !store.error && <Alert
                        text={`${i18n.translate("ALL_PAYMENTS_ADDED")}`}
                        type={AlertType.SUCCESS}/>}

                    {showAdditionalPrices && <div className={clsx(classes.additional_container, classes.margin_top_16px)}>
                        <div className={classes.additional_inputs_container}>
                            <div>
                                <PriceInput fullWidth
                                    value={additionalAmount}
                                    onChange={(c) => setAdditionalAmount(c)}/>
                                {/* <Checkbox text={"Valor en %"} onChange={(c) => setIsAdditionalPercent(c)} selected={isAdditionalPercent}/> */}
                            </div>
                            <CommentInput onChange={(c) => setAdditionalComment(c)} fullWidth/>
                        </div>
                        <div className={classes.addition_add_btn_container}>
                            <Button disabled={!additionalAmount || additionalAmount === 0} onClick={addAdditionalPrice}
                                type={ButtonType.SECONDARY}
                                text={'ADICIONAR'}/>
                        </div>
                    </div>}
                    {showAdditionalPrices && store.additionalPrices.length > 0 && <div className={clsx(classes.elements_container, classes.margin_top_2px)}>
                        {store.additionalPrices.map((additionalPrice, i) => (
                            <div key={i} className={classes.element_row}>
                                <div className={classes.remove_button}>
                                    <Button type={ButtonType.SECONDARY} size={ButtonSize.SMALL}
                                            startIcon={<DeleteForeverSharpIcon/>} onClick={() => {
                                            removeAdditionalPrice(additionalPrice)
                                    }}/>
                                </div>
                                <ShowAdditionalPrice 
                                    amount={additionalPrice.amount}
                                    isPercent={additionalPrice.isPercentage}
                                    comment={additionalPrice.comment}/>
                            </div>
                        ))}
                    </div>}

                    {showAdditionalPrices && store.additionalPrices?.length === 0 &&
                            <MessageCard className={classes.empty_card}
                                        title={'No hay valores adicionales aún!!!'}/>}

                    <div className={clsx(classes.total_container, classes.elements_container, classes.margin_top_16px)}>
                        <ShowField label={i18n.translate("INVOICE_AMOUNT")}
                                   value={CurrencyModule.format(store.total)}/>
                        <ShowField label={i18n.translate("TOTAL_TAXES")}
                                   value={CurrencyModule.format(store.getFeesSum)}/>
                        {showAdditionalPrices && <ShowField label={'Adicionales'}
                                   value={CurrencyModule.format(store.getAdditionalPriceSum)}/>}
                        <ShowField label={i18n.translate("TOTAL_TO_PAY")}
                                   value={CurrencyModule.format(store.getTotalToPay)}/>
                    </div>
                </div>
            </div>
        )}
    </Observer>
}

export default AddPayment;
