import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import { ArticleStockService } from "../../../service/articleDelivery/ArticleStockService";
import ArticleStockResponse from "../../../model/articleDelivery/ArticleStockResponse";

class ShowDeliveryPersonArticleStockStore {

    public deliveryPerson: DeliveryPerson;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public pagination: Pagination;
    public articlesStock: ArticleStockResponse[];

    public amountError?: string;


    constructor(commonStore: CommonStore, deliveryPerson: DeliveryPerson) {
        this.isModalOpen = false;
        this.deliveryPerson = deliveryPerson;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.articlesStock = [];
        makeAutoObservable(this);
    }

    @action
    public async getArticlesStock(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await ArticleStockService.get(page, pageSize, this.deliveryPerson.id);
        if (response.success) {
            runInAction(() => {
                this.articlesStock = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.getArticlesStock();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.articlesStock.length === 0;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getArticlesStock(page, pageSize);
    }

}

export default ShowDeliveryPersonArticleStockStore;