import React from 'react';
import ArticleDeliveryRowProperties from "./ArticleDeliveryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ArticleDeliveryRowMedium from "./ArticleDeliveryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ArticleDeliveryRowSmall from "./ArticleDeliveryRowSmall";


const ArticleDeliveryRow: React.FC<ArticleDeliveryRowProperties> = ({
                                                                    articleDeliveryOrder,
                                                                    selectedDeliveryOrderIds,
                                                                    onArticleDeliveryOrderClick,
                                                                    onArticleDeliveryOrderSelect,
                                                                    onDeliveryPersonSelect,
                                                                    onContactSelect,
                                                                    onUpdate,
                                                                    filter
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <ArticleDeliveryRowSmall onUpdate={onUpdate} onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect}
                                       onArticleDeliveryOrderClick={onArticleDeliveryOrderClick}
                                       onDeliveryPersonSelect={onDeliveryPersonSelect} onContactSelect={onContactSelect}
                                       articleDeliveryOrder={articleDeliveryOrder}
                                       selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                       filter={filter}/>
            </ViewSmall>
            <ViewMedium>
                <ArticleDeliveryRowMedium onUpdate={onUpdate} onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect}
                                        onArticleDeliveryOrderClick={onArticleDeliveryOrderClick}
                                        onDeliveryPersonSelect={onDeliveryPersonSelect}
                                        onContactSelect={onContactSelect} articleDeliveryOrder={articleDeliveryOrder}
                                        selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                        filter={filter}/>
            </ViewMedium>
            <ViewLarge>
                <ArticleDeliveryRowMedium onUpdate={onUpdate} onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect}
                                        onArticleDeliveryOrderClick={onArticleDeliveryOrderClick}
                                        onDeliveryPersonSelect={onDeliveryPersonSelect}
                                        onContactSelect={onContactSelect} articleDeliveryOrder={articleDeliveryOrder}
                                        selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                        filter={filter}/>
            </ViewLarge>
        </>
    )
}

export default ArticleDeliveryRow;
