import React, {useCallback, useEffect, useState} from 'react';
import ProductSelectProperties from "./ProductSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import SelectOption from "../../../ui_components/Select/SelectOption";
import Select from "../../../ui_components/Select/Select";
import ProductIcon from "../../../ui_components/Icon/icons/ProductIcon";
import Product from "../../../entities/productOrder/topup/Product";
import {ProductService} from "../../../service/topup/ProductService";
import {CurrencyModule} from "../../../util/Currency";
import {useCommonContext} from "../../../contexts/CommonContext";
import Currency from "../../../entities/Currency";

const ProductSelect: React.FC<ProductSelectProperties> = ({
                                                              selected,
                                                              onChange,
                                                              disabled,
                                                              error,
                                                              fullWidth = false,
                                                              className = '',

                                                          }) => {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [selectedOption] = useState<SelectOption>();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();

    const onProductChange = useCallback((option: SelectOption) => {
        const product = products.find(p => p.id === option?.value);
        if (product) {
            onChange(product);
        }
    }, [onChange, products]);

    useEffect(() => {
        setLoading(true);
        ProductService.get().then(response => {
            if (response.success && response.data) {
                setProducts(response.data);
            }
            commonContext.processErrors(response);
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const opts = products.map(p =>
            new SelectOption(
                p.name,
                p.id,
                `${i18n.translate("AMOUNT")}: ${CurrencyModule.format(p.amount, Currency.MN)}`,
                ProductIcon
            ));
        setOptions(opts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    // useEffect(() => {
    //     const selectedOpt = options.find(t => t.value === selected?.id) || options[options.length - 1];
    //     setSelectedOption(selectedOpt);
    //     onProductChange(selectedOpt);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selected, options])


    return (
        <Select
            className={className}
            loading={loading}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("PRODUCT")}
            placeholder={i18n.translate("PRODUCT_PLACEHOLDER")}
            selected={selectedOption}
            icon={ProductIcon}
            onChange={onProductChange}
            elements={options}
            error={error}
        />
    )
}


export default ProductSelect;