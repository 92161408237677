import React from 'react';
import ChooseDeliveryPersonLinksProperties from "./ChooseDeliveryPersonLinksProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ChooseDeliveryPersonStore from "./ChooseDeliveryPersonStore";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ChooseDeliveryPersonLinks.style";
import {useCommonContext} from "../../../contexts/CommonContext";
import DeliveryPersonSelectList from '../../Selects/DeliveryPersonSelectList/DeliveryPersonSelectList';
import DeliveryPerson from '../../../entities/DeliveryPerson';
import Link from '../../../ui_components/Link/Link';
import useConfirmation from '../../../hooks/ConfirmationDialog';
import { useUserContext } from '../../../contexts/UserContext';

const ChooseDeliveryPersonLinks: React.FC<ChooseDeliveryPersonLinksProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSelect = () => {
                                                                                },
                                                                                haveDeliveryPersonSelected,
                                                                                orderByGeosIdProximity = []
                                                                            }) => {
    const classes = useStyles();
    const confirm = useConfirmation();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new ChooseDeliveryPersonStore(commonContext);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const handleRemoveOnclick = async () => {
        onClick();
        const ok = await confirm("Confirmación", "¿Está seguro que desea quitar el repartidor del envío?");
        if (ok) {
            onSelect(undefined);
        }
    }

    const onCloseModal = () => {
        store.closeModal();
    }
    
    const onDeliveryPersonSelect = async (deliveryPerson?: DeliveryPerson) => {
        onSelect(deliveryPerson);
        onCloseModal();
    }

    const text = i18n.translate("SELECT_DELIVERY_PERSON");

    if (!userStore.isRootAgency()) {
        return <></>;
    }

    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <DeliveryPersonSelectList
                            selectedDeliveryPerson={store.selectedDeliveryPerson}
                            orderByGeosIdProximity={orderByGeosIdProximity}
                            onDeliveryPersonSelect={onDeliveryPersonSelect}>
                        </DeliveryPersonSelectList>
                    </div>
                </Modal>
                <div className={classes.btn_container}>
                    <div><Link text={haveDeliveryPersonSelected ? 'Cambiar' : 'Asignar'} onClick={handleOnclick}></Link></div>
                    {haveDeliveryPersonSelected && <div className={classes.btn_container}>| <Link text={'Quitar'} onClick={handleRemoveOnclick}></Link></div>}
                </div>
            </>
        )}
    </Observer>
}

export default ChooseDeliveryPersonLinks;
