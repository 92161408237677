import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { NotificationService } from "../../../service/NotificationService";
import UserNotification from "../../../entities/Notification";
import NotificationStatus from "../../../entities/NotificationStatus";

class NotificationsStore {

    public isModalOpen: boolean;
    public loading: boolean;
    public notifications: UserNotification[];
    public newNotificationsIds: string[];
    public newNotifications: number;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.loading = false;
        this.notifications = [];
        this.newNotificationsIds = [];
        this.newNotifications = 0;
        this.commonStore = commonStore;
        this.loadNotifications();
        makeAutoObservable(this);
    }

    @action
    public async loadNotifications() {
        this.loading = true;
        const response = await NotificationService.get()
        if (response.success && response.data) {
            this.notifications = response.data;
            const newNotifications = this.notifications.filter(t => t.status === NotificationStatus.NEW);
            if (this.newNotificationsIds.length === 0) {
                this.newNotificationsIds = newNotifications.map(t => t.id)
            }
            this.newNotifications = newNotifications.length;
            this.loading = false;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async markAsVisualized() {
        setTimeout(() => {
            NotificationService.markAsVisualized();
        }, 1000);
    }

    @action
    public async remove(notification: UserNotification) {
        const response = await NotificationService.remove(notification);
        this.commonStore.processErrors(response);
        this.loadNotifications();
        return response.success;
    }

}

export default NotificationsStore;