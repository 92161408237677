import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import {Observer} from "mobx-react-lite";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ShowPagination from "../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import ShowDeliveryPersonArticleStockProperties from './ShowDeliveryPersonArticleStockProperties';
import clsx from "clsx";
import { useStyles } from './ShowDeliveryPersonArticleStock.style';
import ShowDeliveryPersonArticleStockStore from './ShowDeliveryPersonArticleStockStore';
import ShowImage from '../ShowImage/ShowImage';
import ShowText from '../ShowText/ShowText';
import AddArticleStockButton from '../../Buttons/AddArticleStockButton/AddArticleStockButton';
import Link from '../../../ui_components/Link/Link';


const ShowDeliveryPersonArticleStock: React.FC<ShowDeliveryPersonArticleStockProperties> = ({
                                                                        className = '',
                                                                        deliveryPerson,
                                                                        onClose = () => {
                                                                        },
                                                                    }) => {
    const classes = useStyles();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new ShowDeliveryPersonArticleStockStore(commonContext, deliveryPerson);

    const handleOnclick = async () => {
        await store.openModal();
    }

    const onCloseModal = () => {
        store.closeModal();
        onClose();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onCostSaved = async () => {
        await store.goToPage()
    }

    const text = 'Inventario de Artículos';
    const title = `${text}: ${deliveryPerson.name}`;
    if (!userStore.hasAccess(Access.ADD_DELIVERY_COST.valueOf())) {
        return <></>;
    }
    const Info = () => {
        return <div className={clsx(classes.display_field_container, className)}>
                    <div className={classes.label}>{`Artículos:`}</div>
                    <div className={classes.value}>{deliveryPerson.articleStockTotalAmount}</div>
                    <Link text={'Detalles'} onClick={handleOnclick}></Link>
                </div>;
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={title} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <div className={classes.body}>
                            <Box
                                width={Width.FULL_WIDTH}
                                justifyContent={Alignment.SPACE_BETWEEN}>
                                <ShowSellerBasicInfo name={store.deliveryPerson.name}
                                                     email={store.deliveryPerson.email}
                                                     phone={store.deliveryPerson?.phone} autofill/>
                                <AddArticleStockButton
                                    type={ButtonType.SECONDARY}
                                    rounded
                                    onSave={onCostSaved}
                                    deliveryPersonId={store.deliveryPerson.id}/>
                            </Box>

                            {!store.isEmpty &&
                            <div className={classes.contacts_table}>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={'Artículos'}
                                                pagination={store.pagination}/>
                                <div>
                                    {store.articlesStock.map((articleStock, i) => (
                                        <div className={classes.cost_info_container}>
                                            <ShowImage imageName={articleStock.articleDefaultImage} width={70} height={70}/>
                                            <ShowText text={articleStock.articleName} className={classes.article_name_container}/>
                                            <ShowText text={articleStock.amount.toString()} title={'Cantidad'} className={classes.amount_container}/>
                                            



                                        </div>
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={'Artículos'}
                                    pagination={store.pagination}/>
                            </div>
                            }
                            {store.isEmpty &&
                            <MessageCard icon={FastfoodIcon} title={i18n.translate("NO_ARTICLE_STOCK_TITLE")}/>}
                        </div>
                    </div>
                </SidePanel>
                <Info/>
            </>
        )}
    </Observer>
}

export default ShowDeliveryPersonArticleStock;
