import React, {useEffect} from 'react';
import AgencyTotalSalesGraphProperties from "./AgencyTotalSalesGraphProperties";
import {useStyles} from "./AgencyTotalSalesGraph.style";
import AgencyTotalSalesGraphStore from "./AgencyTotalSalesGraphStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import {useI18nContext} from "../../../contexts/I18nContext";
import InfoIcon from "@mui/icons-material/Info";
import Pie from "../../../ui_components/Graph/Pie/Pie";
import PieData from "../../../ui_components/Graph/Pie/PieData";

const AgencyTotalSalesGraph: React.FC<AgencyTotalSalesGraphProperties> = ({
                                                                              from,
                                                                              to,
                                                                              agencyId,
                                                                              userId,
                                                                              title,
                                                                              includeChildren,
                                                                              className = '',
                                                                          }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const store = new AgencyTotalSalesGraphStore(commonStore, from, to, agencyId,userId, includeChildren);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    return <Observer>{() => (
        <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            {store.sales && store.sales.amount > 0 &&
                <Pie data={store.sales?.breakdown.map(d => new PieData(i18n.translate(d.serviceName), d.total))}/>}
            {(!store.sales || store.sales.amount === 0) &&
                <div className={classes.no_content_container} onClick={() => store.getElements()}>
                    <InfoIcon/>
                    {i18n.translate("NO_SALES")}
                </div>
            }
            <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                         text={`${i18n.translate("LOADING")} ${title || ''}`}/>
        </div>
    )}</Observer>

}


export default AgencyTotalSalesGraph;