import React, { useEffect, useState } from 'react';
import SalePriceSelectSmallProperties from "./SalePriceSelectSmallProperties";
import SelectOption from "../../../ui_components/Select/SelectOption";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Select from "../../../ui_components/Select/Select";
import Currency from "../../../entities/Currency";
import { SalePriceService } from '../../../service/SalePriceService';
import SalePrice from '../../../entities/SalePrice';
import { useCommonContext } from '../../../contexts/CommonContext';

const SalePriceSelectSmall: React.FC<SalePriceSelectSmallProperties> = ({
                                                                        selected,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {

    const commonContext = useCommonContext();

    const [loading, setLoading] = useState(false);
    const [salePrices, setSalePrices] = useState<SalePrice[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<SelectOption>();

    useEffect(() => {
        setLoading(true);
        SalePriceService.get(1, 1000).then(response => {
            if (response.success && response.data) {
                setSalePrices(response.data.data);
            }
            commonContext.processErrors(response);
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const opts = salePrices.map(p =>
            new SelectOption(
                p.name,
                p.id,
                p.description ? p.description : '-',
                LocalAtmIcon
            ));
        setOptions(opts);
    }, [salePrices, selected]);

    useEffect(() => {
        const opt = options.find(t => t.value === selected);
        setSelectedOption(opt);
    }, [options, selected]);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value as Currency)
    }

    return (
        <Select
            loading={loading}
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : 'Precios de venta'}
            placeholder={'Seleccione la lista de precios de venta'}
            selected={selectedOption}
            icon={LocalAtmIcon}
            onChange={onStatusChange}
            elements={options}
        />
    )
}


export default SalePriceSelectSmall;

