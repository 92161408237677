import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    additional_price_row_container: {
        marginTop: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.secondary.light}5c`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    tools_container: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    }
}));

export {useStyles}