import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    box_container: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '6px',
        padding: '8px',
        boxSizing: 'border-box',
    }
}));

export {useStyles}