import {action, computed, makeObservable, observable} from "mobx";
import CommonStore from "../../stores/CommonStore";
import {PasswordService} from "../../service/PasswordService";

class ChangePasswordDeliveryPersonStore {

    public password: string;
    public confirmPassword: string;
    public code?: string;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, code?: string) {
        this.password = '';
        this.confirmPassword = '';
        this.code = code;
        this.loading = false;
        this.commonStore = commonStore;
        makeObservable(this, {
            password: observable,
            confirmPassword: observable,
            code: observable,
            loading: observable,
            isEnable: computed,
        });
        this.validatePasswordRecoveryCode()
    }

    get isEnable(): boolean {
        return this.validate("UPPERCASE")
            && this.validate("LOWERCASE")
            && this.validate("NUMBER")
            && this.validate("LENGTH")
            && this.validate("EQUALS");
    }

    public validate(key: string): boolean {
        switch (key) {
            case "UPPERCASE":
                return /[A-Z]+/.test(this.password);
            case "LOWERCASE":
                return /[a-z]+/.test(this.password);
            case "NUMBER":
                return /[0-9]+/.test(this.password);
            case "LENGTH":
                return this.password.length >= 8;
            case "EQUALS":
                return this.password === this.confirmPassword;
        }
        return true;
    }

    public validatePasswordRecoveryCode() {
        this.loading = true;
        PasswordService.validateCodeForDeliveryPerson(this.code).then(response => {
            if (!response.success || !response.data) {
                window.location.href = '/login?label=PASSWORD_RECOVERY_CODE_ERROR';
            }
        });
        this.loading = false;
    }

    @action
    public async changePassword() {
        this.loading = true;
        const response = await PasswordService.changePasswordForDeliveryPerson(this.code!!, this.password)
        if (response.success) {
            window.location.href = '/login?label=PASSWORD_RECOVERY_SUCCESS';
        }
        this.loading = false;
    }

}

export default ChangePasswordDeliveryPersonStore;