class User {
    public readonly id: string;
    public readonly agencyId: string;
    public readonly name: string;
    public readonly email: string;
    public readonly roles: number;
    public readonly isRootAgency: boolean;

    constructor(id: string, agencyId: string, name: string, email: string, roles: number, isRootAgency: boolean) {
        this.id = id;
        this.agencyId = agencyId;
        this.name = name;
        this.email = email;
        this.roles = roles;
        this.isRootAgency = isRootAgency;
    }
}

export default User;