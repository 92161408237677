import {action, makeAutoObservable} from "mobx";
import { ArticleDeliveryService } from "../../../service/articleDelivery/ArticleDeliveryService";
import CommonStore from "../../../stores/CommonStore";


class RemoveArticleDeliveryStore {

    public articleDeliveryOrderId: string;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, articleDeliveryOrderId: string) {
        this.articleDeliveryOrderId = articleDeliveryOrderId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ArticleDeliveryService.remove(this.articleDeliveryOrderId);
        if (!response.success && response.status === 400 && response.error === 'ERROR_00_400_44') {
            await this.commonStore.showErrorToast('No se pudo eliminar el envío porque está en un estado que lo imposibilita');
        } else {
            this.commonStore.processErrors(response);
        }        
        return response.success;
    }
}

export default RemoveArticleDeliveryStore;