import {action, makeObservable, observable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Agency from "../../../entities/Agency";

class AgencySalesOverviewButtonStore {

    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public agency: Agency;
    public from: number;
    public to: number;

    constructor(commonStore: CommonStore, agency: Agency, from: number, to: number) {
        this.agency = agency;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.from = from;
        this.to = to;
        makeObservable(this, {
            loading: observable,
            from: observable,
            to: observable,
            isModalOpen: observable,
            agency: observable,
        });
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default AgencySalesOverviewButtonStore;