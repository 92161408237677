import React from 'react';
import ShowServiceProperties from "./ShowServiceProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowService.style";
import ServiceName from '../../../entities/ServiceName';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GradingIcon from '@mui/icons-material/Grading';
import DeliveryCostServiceName from '../../../entities/DeliveryCostServiceName';
import {Inventory} from "@mui/icons-material";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const ShowService: React.FC<ShowServiceProperties> = ({
    service,
                                                          className = '',
                                                      }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={classes.show_product_container}>
            {service === DeliveryCostServiceName.ALL &&
                <div className={classes.service_icon_name_container}>
                    <GradingIcon className={classes.icon}/>
                    <div>Todos</div>
                </div>}
            {service === ServiceName.TOPUP &&
                <div className={classes.service_icon_name_container}>
                    <SendToMobileIcon className={classes.icon}/>
                    <div>{i18n.translate('TOPUP')}</div>
                </div>}
            {(service === ServiceName.ARTICLE_DELIVERY || service === DeliveryCostServiceName.ARTICLE_DELIVERY) &&
                <div className={classes.service_icon_name_container}>
                    <FastfoodIcon className={classes.icon}/>
                    <div>Artículo</div>
                </div>}
            {(service === ServiceName.MONEY_DELIVERY || service === DeliveryCostServiceName.MONEY_DELIVERY) &&
                <div className={classes.service_icon_name_container}>
                    <AttachMoneyIcon className={classes.icon}/>
                    <div>{i18n.translate('MONEY')}</div>
                </div>}
            {(service === ServiceName.PACKAGE_DELIVERY || service === DeliveryCostServiceName.PACKAGE_DELIVERY) &&
                <div className={classes.service_icon_name_container}>
                    <Inventory className={classes.icon}/>
                    <div>Paquete</div>
                </div>}
            {(service === ServiceName.MISCELLANY || service === DeliveryCostServiceName.MISCELLANY) &&
                <div className={classes.service_icon_name_container}>
                    <DashboardCustomizeIcon className={classes.icon}/>
                    <div>Miscelánea</div>
                </div>}
        </div>
    )
}


export default ShowService;