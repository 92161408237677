import React from 'react';
import MiscellanyOrderResumeProperties from "./MiscellanyOrderResumeProperties";
import {Observer} from "mobx-react-lite";
import ShowInvoiceAdditionalPrices from '../../../Info/ShowInvoiceAdditionalPrices/ShowInvoiceAdditionalPrices';
import ShowClientToContactInfo from '../../../Info/ShowClientToContactInfo/ShowClientToContactInfo';
import PaymentsTable from '../../../Info/PaymentsTable/PaymentsTable';
import { useStyles } from './MiscellanyOrderResume.style';
import ShowShowMiscellanyOrderDetails from '../../../Info/ShowMiscellanyOrderDetails/ShowMiscellanyOrderDetails';
import OrderAdditionalPrice from '../../../../entities/OrderAdditionalPriceRequest';

const MiscellanyOrderResume: React.FC<MiscellanyOrderResumeProperties> = ({
                                                                    store
                                                                }) => {

    const classes = useStyles();

    return <Observer>
        {() => (
            <div className={classes.container}>
                {store.selectedClient && <ShowClientToContactInfo
                    client={store.selectedClient}/>}
                
                {store.selectedMiscellanyCategory &&
                    <ShowShowMiscellanyOrderDetails
                        miscellanyCategory={store.selectedMiscellanyCategory}
                        showHeader={false}
                        isLoading={false}/>
                }

                {store.additionalPrices.length > 0 &&
                    <ShowInvoiceAdditionalPrices additionalPrices={store.additionalPrices.map((p) => {
                        return new OrderAdditionalPrice(p.amount, p.comment, p.isPercentage)
                    })}></ShowInvoiceAdditionalPrices>}

                {store.payments.length > 0 &&
                    <PaymentsTable payments={store.payments}/>}
                
            </div>
        )}
    </Observer>
}

export default MiscellanyOrderResume;
