import React from 'react';
import {useStyles} from "../Topups.style";
import clsx from "clsx";
import TopupRowProperties from "./TopupRowProperties";
import ShowOrderStatus from "../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus";
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import ShowProduct from '../../../shared_components/Info/ShowProduct/ShowProduct';
import ShowGeneralComment from '../../../shared_components/Info/ShowGeneralComment/ShowGeneralComment';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowConfirmation from '../../../shared_components/Info/ShowConfirmation/ShowConfirmation';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import ShowError from '../../../shared_components/Info/ShowError/ShowError';
import ViewInvoiceButton from '../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';


const TopupRowSmall: React.FC<TopupRowProperties> = ({topup, products, filter}) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.topups_info_container_small)}>
            <div className={classes.first_row_small}>
                <div>
                    <ShowDate label={'Fecha'} timestamp={topup.createdAt} className={classes.margin_top_4}/>
                </div>
                <div>
                    <ShowProduct value={products.find((t) => t.id === topup.productId)}/>
                    <ShowGeneralComment value={topup.comment}/>
                </div>
            </div>
            <div className={classes.first_row_small}>
                <div>
                    <ShowText text={topup.clientName} title={'Remitente'} textToHighlight={filter}/>
                    <ShowText text={topup.contactName} title={'Destinatario'} textToHighlight={filter}/>
                    <ShowPhoneNumber showIcon showFlag={false} value={topup.phone} textToHighlight={filter}/>
                </div>
                <div>
                    {topup.status!== ProductOrderStatus.COMPLETED && <ShowError error={topup.error}/>}
                    <ShowConfirmation value={topup.confirmation}/>
                </div>
            </div>
            <div className={classes.first_row_small}>
                <div>
                    <ShowText text={topup.agencyName} title={'Agencia'}/>
                    <ShowText text={topup.userName} title={'Vendedor'} className={classes.margin_top_8}/>
                </div>
                <div className={classes.margin_top_8}>
                    <ShowOrderStatus status={topup.status}/>
                </div>
            </div>
            <div className={clsx(classes.menu_container_small)}>
                <ViewInvoiceButton
                    size={ButtonSize.SMALL}
                    productOrderId={topup.id}
                    type={ButtonType.SECONDARY}
                />
                <ProductOrderStatusHistoryButton
                    size={ButtonSize.SMALL}
                    productOrderId={topup.id}
                    type={ButtonType.SECONDARY}
                />
            </div>
        </div>
    )
}

export default TopupRowSmall;
