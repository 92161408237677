import OrderAdditionalPrice from "../../entities/OrderAdditionalPriceRequest";
import AddPaymentRequest from "../payment/AddPaymentRequest";
import ArticleDeliveryOrderArticleRequest from "./ArticleDeliveryOrderArticleReguest";

class ArticleDeliveryOrderRequest {
    public clientId: string;
    public contactId: string;
    public agencyId?: string;
    public userId?: string;
    public total: number;
    public articles: ArticleDeliveryOrderArticleRequest[];
    public payments: AddPaymentRequest[];
    public additionalPrices?: OrderAdditionalPrice[];
    public observations?: string;

    constructor(
        clientId: string,
        contactId: string,
        total: number,
        articles: ArticleDeliveryOrderArticleRequest[],
        payments: AddPaymentRequest[],
        additionalPrices?: OrderAdditionalPrice[],
        agencyId?: string,
        userId?: string,
        observations?: string
    ) {
        this.clientId = clientId;
        this.contactId = contactId;
        this.total = total;
        this.articles = articles;
        this.payments = payments;
        this.additionalPrices = additionalPrices;
        this.agencyId = agencyId;
        this.userId = userId;
        this.observations = observations;
    }
}

export default ArticleDeliveryOrderRequest;