import React from 'react';
import RemoveDeliveryCostButtonProperties from "./RemoveDeliveryCostButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import RemoveDeliveryCostStore from "./RemoveDeliveryCostStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useCommonContext} from "../../../contexts/CommonContext";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import {CurrencyModule} from "../../../util/Currency";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';


const RemoveDeliveryCostButton: React.FC<RemoveDeliveryCostButtonProperties> = ({
                                                                                    onClick = () => {
                                                                                    },
                                                                                    onSave = () => {
                                                                                    },
                                                                                    showIcon = true,
                                                                                    showText = true,
                                                                                    disabled,
                                                                                    className = '',
                                                                                    deliveryCost,
                                                                                    rounded = false,
                                                                                    size = ButtonSize.DEFAULT,
                                                                                    type = ButtonType.DEFAULT
                                                                                }) => {

    const i18n = useI18nContext();
    const confirm = useConfirmation();
    const commonContext = useCommonContext();
    const store = new RemoveDeliveryCostStore(commonContext, deliveryCost);

    const handleOnclick = async () => {
        onClick();
        const ok = await confirm(`${deliveryCost.geo?.name} - ${CurrencyModule.format(deliveryCost.cost, deliveryCost.currency)}`, i18n.translate("REMOVE_DELIVERY_COST_CONFIRMATION"));
        if (ok) {
            await store.remove();
            onSave();
        }
    }

    const text = 'Eliminar comisión';

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default RemoveDeliveryCostButton;
