import Currency from "../../entities/Currency";
import PaymentType from "../../entities/PaymentType";

class InvoicePaymentResponse {
    public amount: number;
    public currency: Currency;
    public paymentType: PaymentType;
    public fee: number;

    constructor(amount: number, currency: Currency, paymentType: PaymentType,fee: number) {
        this.paymentType = paymentType;
        this.amount = amount;
        this.currency = currency;
        this.fee = fee;
    }

}

export default InvoicePaymentResponse;