import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import NewShopProductStore from "./NewShopProductStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewShopProductButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import NewShopProductButtonProperties from './NewShopProductButtonProperties';
import PriceInput from '../../Inputs/PriceInput/PricetInput';
import ArticleDescriptionInput from '../../Inputs/ArticleDescriptionInput/ArticleDescriptionInput';
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';


const NewShopProductButton: React.FC<NewShopProductButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  shopProductId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewShopProductStore(commonContext, shopProductId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.shopProduct.name = newName;
    const onDescriptionChange = (newDescription: string) => store.shopProduct.description = newDescription;
    const onPriceChange = (newPrice: number) => store.shopProduct.price = newPrice;
    
    const onStockAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        store.shopProduct.stockAmount = +newValue;
    }

    const onMinStockWarningChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        store.shopProduct.minStockWarning = +newValue;
    }

    const onShopProductSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_SHOP_PRODUCT" : "NEW_SHOP_PRODUCT"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <SellerNameInput 
                            error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                            value={store.shopProduct?.name}
                            onChange={onNameChange}/>
                        <ArticleDescriptionInput fullWidth className={classes.input}
                            error={i18n.translate(store.descriptionError)}
                            value={store.shopProduct?.description}
                            onChange={onDescriptionChange}/>
                        <div className={classes.price_container}>
                            <PriceInput label={'Precio'} fullWidth className={classes.input}
                                value={store.shopProduct?.price}
                                onChange={onPriceChange}/>
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={store.shopProduct?.stockAmount}
                                onChange={onStockAmountChange}
                                label={'Inventario'}
                                className={classes.input}
                                fullWidth
                            />
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={store.shopProduct?.minStockWarning}
                                onChange={onMinStockWarningChange}
                                label={'Notificar mínimo inventario'}
                                className={classes.input}
                                fullWidth
                            />
                        </div>

                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onShopProductSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewShopProductButton;
