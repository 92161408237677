import React, {createRef} from 'react';
import ViewInvoiceButtonProperties from "./ViewInvoiceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ViewInvoiceStore from "./ViewInvoiceStore";
import ArticleIcon from '@mui/icons-material/Article';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ViewInvoiceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ShowField from "../../Info/ShowField/ShowField";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import ShowDate from "../../Info/ShowDate/ShowDate";
import ShowCode from "../../Info/ShowCode/ShowCode";
import QRCode from "react-qr-code";
import clsx from "clsx";
import PrintButton from "../PrintButton/PrintButton";
import InvoiceStatus from "../../../entities/InvoiceStatus";
import PayInvoiceButton from "../PayInvoiceButton/PayInvoiceButton";
import {CurrencyModule} from '../../../util/Currency';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import { Config } from '../../../util/Config';


const ViewInvoiceButton: React.FC<ViewInvoiceButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderId,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT
                                                                  }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ViewInvoiceStore(commonContext, productOrderId);
    const containerRef = createRef<HTMLDivElement>()


    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
        if (onSave) {
            onSave();
        }
    }

    const onPaymentDone = async () => {
        await store.loadInvoice();
    }

    const text = i18n.translate("PAYMENT_CONFIRMATION");
    const Icon = ArticleIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    const InvoiceData: React.FC<{ signerName: string, signerRole: string }> = ({signerName, signerRole}) => {
        const getTitle = () => {
            switch (store.invoice?.status) {
                case InvoiceStatus.PENDING:
                    return i18n.translate("PAYMENT_PENDING_INVOICE_TITLE");
                case InvoiceStatus.PAYED:
                    return i18n.translate("PAYMENT_DONE_INVOICE_TITLE");
                case InvoiceStatus.CANCELED:
                    return i18n.translate("INVOICE_CANCELLED_TITLE");
            }
        }
        const getWaterMarkTitle = () => {
            switch (store.invoice?.status) {
                case InvoiceStatus.PENDING:
                    return "";
                case InvoiceStatus.PAYED:
                    return i18n.translate("PAYMENT_DONE_INVOICE_WATERMARK_TITLE");
                case InvoiceStatus.CANCELED:
                    return i18n.translate("INVOICE_CANCELLED_WATERMARK_TITLE");
            }
        }
        const getQRCodeValue = Config.get('CLIENT_ORDER_TRACKER_UI_URL', {
            id: store.productOrderId
        });

        return <div className={classes.invoice_data_container}>
            <div
                className={clsx(classes.water_mark, store.invoice?.status === InvoiceStatus.CANCELED && classes.canceled)}>{getWaterMarkTitle()}</div>
            <div className={classes.header}>
                <div className={classes.column}>
                    <div className={classes.agency_name}>AloCuba Travel</div>
                    <ShowField value={store.invoice?.agencyAddress} icon={HomeIcon}/>
                    <ShowField value={store.invoice?.agencyPhone} icon={ContactPhoneIcon}/>
                    <ShowField value={store.invoice?.agencyEmail} icon={MailIcon}/>
                    {store.invoice && <ShowCode value={store.invoice.number}/>}
                    <ShowDate timestamp={store.invoice?.createdAt}/>
                </div>
                <div className={clsx(classes.column, classes.last_column, classes.qr_container)}>
                    {store.invoice && <QRCode size={100} title={store.productOrderId} value={getQRCodeValue}/>}
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.title}>{getTitle()}</div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <div className={classes.subtitle}>{`${i18n.translate("CLIENT")}:`} </div>
                            <div>{store.invoice?.clientName}</div>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <ShowField value={store.invoice?.clientEmail} icon={MailIcon}/>
                        <ShowField value={store.invoice?.clientPhone} icon={ContactPhoneIcon}/>
                    </div>
                </div>
                <div className={classes.row}>
                    <table className={classes.table}>
                        <tr className={classes.table_header}>
                            <td colSpan={store.invoice?.contactName ? 1 : 2}>{i18n.translate("DESCRIPTION")}</td>
                            {store.invoice?.contactName && <td>{i18n.translate("ADDRESSEE")}</td>}
                            <td>{i18n.translate("PRICE")}</td>
                        </tr>
                        <tr>
                            <td colSpan={store.invoice?.contactName ? 1 : 2}>
                                <div>{store.invoice?.description}</div>
                                {store.invoice?.additionalData?.map((v,i)=>(
                                    <div key={i}><strong>{v.name}: </strong>{v.value}</div>
                                ))}
                            </td>
                            {store.invoice?.contactName && <td>
                                <div><strong>Nombre: </strong>{`${store.invoice?.contactName}`}</div>
                                <div><strong>Teléfono: </strong>{store.invoice?.contactPhone ?? '-'}</div>
                                <div><strong>Dirección: </strong>{store.invoice?.contactAddress}</div>
                            </td>}
                            <td className={classes.text_nowrap}>{CurrencyModule.format(store.invoice?.totalService || 0, store.invoice?.currency)}</td>
                        </tr>
                        {store.invoice && store.invoice.payments && store.invoice.status === InvoiceStatus.PAYED && store.invoice.payments.length > 0 &&
                            <>
                                <tr className={classes.table_header}>
                                    <td colSpan={3}>{i18n.translate("PAYMENT")}</td>
                                </tr>
                            </>
                        }
                        {store.invoice?.status === InvoiceStatus.PAYED && store.invoice?.payments?.map((p, i) => (
                            <tr key={i} className={classes.payments_container}>
                                <td colSpan={2}>
                                    <div className={classes.payment_row}>
                                        <div>{i18n.translate(`PAYMENT_TYPE_${p.paymentType}`)}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className={classes.text_nowrap}>
                                        {CurrencyModule.format(p.amount || 0, p.currency)}
                                        {p.fee != null && p.fee > 0 && <span
                                            className={classes.fee}>(+{CurrencyModule.format(p.fee || 0, p.currency)})</span>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr className={classes.table_footer}>
                            <td colSpan={2}>{i18n.translate("TOTAL_TAXES")}</td>
                            <td className={classes.text_nowrap}>{CurrencyModule.format(store.invoice?.paymentFee || 0)}</td>
                        </tr>
                        <tr className={classes.table_footer}>
                            <td colSpan={2}>{i18n.translate("TOTAL_TO_PAY")}</td>
                            <td className={classes.text_nowrap}>{CurrencyModule.format(store.invoice?.totalToPay || 0, store.invoice?.currency)}</td>
                        </tr>

                    </table>
                </div>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <div className={classes.sign_container}/>
                        <div className={classes.row}>
                            <div className={classes.subtitle}>{`${signerRole}: `} </div>
                            &nbsp;
                            <div>{signerName}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={`${i18n.translate("INVOICE_NO")}: ${store.invoice?.number}`}
                           onClose={onCloseModal} open={store.isModalOpen} className={classes.modal_container}>
                    <div className={classes.view_invoice_container}>
                        <div className={classes.toolbar_container}>
                            <PrintButton type={ButtonType.DEFAULT} onClick={() => {
                                containerRef.current?.scroll(0, 0);
                            }}/>
                            {store.invoice?.status === InvoiceStatus.PENDING &&
                                <PayInvoiceButton type={ButtonType.SECONDARY} productOrderId={store.productOrderId}
                                         onSave={onPaymentDone}/>}
                        </div>
                        <div className={classes.invoice_container} ref={containerRef}>
                            <div>
                                <InvoiceData signerName={store.invoice?.userName || "-"}
                                             signerRole={i18n.translate("SELLER")}/>
                            </div>
                            <div className={classes.separator}/>
                            <div>
                                <InvoiceData signerName={store.invoice?.clientName || "-"}
                                             signerRole={i18n.translate("CLIENT")}/>
                            </div>
                        </div>
                    </div>
                </SidePanel>
                {showButton && <Btn/>}
                {!showButton && <div onClick={handleOnclick}>{children}</div>}
            </>
        )}
    </Observer>
}

export default ViewInvoiceButton;
