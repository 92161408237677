import React from 'react';
import PaymentProperties from "./PaymentProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from './Payment.style';
import AddPayment from "../../../Content/AddPayment/AddPayment";
import AddPaymentRequest from "../../../../model/payment/AddPaymentRequest";
import OrderAdditionalPrice from '../../../../entities/OrderAdditionalPriceRequest';

const Payment: React.FC<PaymentProperties> = ({
                                                  store
                                              }) => {

    const classes = useStyles();

    const onPaymentChange = (payments: AddPaymentRequest[], paymentCompleted: boolean) => {
        store.setPayments(payments, paymentCompleted);
    }

    const onAdditionalPriceChange = (additionalPrices: OrderAdditionalPrice[]) => {
        store.setAdditionalPrices(additionalPrices);
    }

    return <Observer>
        {() => (
            <div className={classes.container}>
                <AddPayment agencyId={store.selectedAgency?.id}
                    currentPayments={store.payments}
                    total={store.getTotalToPay()}
                    onPaymentsChange={onPaymentChange}
                    showAdditionalPrices={true}
                    additionalPrices={store.additionalPrices}
                    onAdditionalPriceChange={onAdditionalPriceChange}/>
            </div>
        )}
    </Observer>
}

export default Payment;
