import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_comment_container: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    value_container: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        marginLeft: '4px'
    },
}));

export {useStyles}