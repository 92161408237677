import React from 'react';
import clsx from "clsx";
import ShowMiscellanyCostsButtonRowProperties from './ShowMiscellanyCostsButtonRowProperties';
import { useStyles } from '../ShowMiscellanyCostsButton.style';
import ShowText from '../../../Info/ShowText/ShowText';
import MiscellanyCategory from '../../../../entities/productOrder/miscellany/MiscellanyCategory';

const ShowMiscellanyCostsButtonRowMedium: React.FC<ShowMiscellanyCostsButtonRowProperties> = ({
                                                            miscellanyPrice,
                                                            miscellanyCategories
                                                        }) => {

    const classes = useStyles();

    return (
        <div
            className={clsx(classes.show_article_price_info_container)}>
            <ShowText text={miscellanyCategories.find((t: MiscellanyCategory) => t.id === miscellanyPrice.miscellanyCategoryId)?.name ?? ''}/>
            <ShowText text={miscellanyPrice.salePrice ? '$' + miscellanyPrice.salePrice.toString() : '-'} title={'Valor de costo'}/>
        </div>
    )
}

export default ShowMiscellanyCostsButtonRowMedium;
