import {useConfirm} from "material-ui-confirm";
import {useI18nContext} from "../contexts/I18nContext";
import {useCommonContext} from "../contexts/CommonContext";

export default function useConfirmation(): (title: string, description: string) => Promise<boolean> {
    const confirm = useConfirm();
    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    return async (title: string, description: string) => {
        try {
            await confirm({
                title,
                description,
                cancellationText: i18n.translate("NO"),
                confirmationText: i18n.translate("YES")
            });
            return true;
        } catch {
            return false;
        } finally {
            commonStore.emitEvent('dialog_closed');
        }
    }
}