import React from 'react';
import {useStyles} from "../TopupPrices.style";
import clsx from "clsx";
import TopupPriceRowProperties from "./TopupPricesRowProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewTopupPriceButton from '../../../shared_components/Buttons/NewTopupPriceButton/NewTopupPriceButton';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ShowProduct from '../../../shared_components/Info/ShowProduct/ShowProduct';
import RemoveTopupPriceButton from '../../../shared_components/Buttons/RemoveTopupPriceButton/RemoveTopupPriceButton';

const TopupPriceRowMedium: React.FC<TopupPriceRowProperties> = ({
                                                            topupPrice,
                                                            products,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {

    const classes = useStyles();

    return (
        <div
            className={clsx(classes.topup_price_info_container)}>
            <ShowProduct value={products.find((t) => t.id === topupPrice.topupProductId)}/>
            <ShowText text={topupPrice.amount ? '$' + topupPrice.amount.toString() : '-'} title={'Valor de venta'}/>
            <ExpandoMenu>
               <>
                   <NewTopupPriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText
                       topupPriceId={topupPrice.id}
                       type={ButtonType.SECONDARY}
                   />
                   <RemoveTopupPriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       topupPrice={topupPrice}
                       showText
                       type={ButtonType.SECONDARY}
                   />
               </>
            </ExpandoMenu>
        </div>
    )
}

export default TopupPriceRowMedium;
