import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    display_field_container: {
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content'
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
    value: {
        color: theme.palette.grey["600"],
        display: 'flex',
        alignItems: 'center',
        "& svg": {
            fontSize: '18px',
            color: theme.palette.grey["700"],
            marginRight: '4px',
        }
    },
}));

export {useStyles}