import React from 'react';
import MiscellanyCategoryRowProperties from "./MiscellanyCategoryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import MiscellanyCategoryRowMedium from "./MiscellanyCategoryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MiscellanyCategoryRowSmall from "./MiscellanyCategoryRowSmall";


const MiscellanyCategoryRow: React.FC<MiscellanyCategoryRowProperties> = ({
                                                    miscellanyCategory,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <MiscellanyCategoryRowSmall onUpdate={onUpdate} miscellanyCategory={miscellanyCategory}/>
            </ViewSmall>
            <ViewMedium>
                <MiscellanyCategoryRowMedium onUpdate={onUpdate} miscellanyCategory={miscellanyCategory} />
            </ViewMedium>
            <ViewLarge>
                <MiscellanyCategoryRowMedium onUpdate={onUpdate} miscellanyCategory={miscellanyCategory} />
            </ViewLarge>
        </>
    )
}

export default MiscellanyCategoryRow;
