import React from 'react';
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import SideMenuLarge from "./SideMenuLarge/SideMenuLarge";
import SideMenuSmall from "./SideMenuSmall/SideMenuSmall";

const SideMenu: React.FC = () => {

    return (
        <>
            <ViewSmall>
                <SideMenuSmall/>
            </ViewSmall>
            <ViewMedium>
                <SideMenuSmall/>
            </ViewMedium>
            <ViewLarge>
                <SideMenuLarge/>
            </ViewLarge>
        </>

    );
}

export default SideMenu;