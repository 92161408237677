import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_product_container: {
        display: 'flex',
        alignItems: 'center'
    },
    icon:{
        fontSize: '25px',
        color: theme.palette.secondary.light
    },
    name_container:{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '14px',
        marginLeft: '4px'
    },
    label:{
        fontSize: '12px',
        color: theme.palette.grey[500],
    },
    name:{
        color: theme.palette.grey[600],
        fontSize: '18px',
    },
    not_available:{
        color: theme.palette.error.light,
        fontSize: '18px',
    },
}));

export {useStyles}