import Phone from "../entities/Phone";

function isCubanOrTestNumber(number: string | undefined): boolean {
    if (!number) return true;
    const value = number.replace(/\s/g, '');
    let cubanNumberAndTestNumbersRegex = /((^(\+53)([\d]){8}$)|(^(\+63)([\d]){8}$)|(^(\+|\+5|\+53|\+6|\+63))$|(^5\d{7})$|(^6\d{7})$|(^0{8})$|(^1{8})$|(^2{8})$)/g;
    return cubanNumberAndTestNumbersRegex.test(value) || value === '';
}

function isUSANumber(number: string | undefined): boolean {
    if (!number) return true;
    const value = number.replace(/\s/g, '');
    let usaNumberRegex = /^([0-9]){1,10}$/g;
    return usaNumberRegex.test(value) || value === '';
}

export module PhoneValidation {
    export function validate(phone?: Phone): boolean {
        if (!phone) return true;
        switch (phone.countryCode.toString()) {
            default:
            case "CUBA":
                return isCubanOrTestNumber(phone.number);
            case "USA":
                return isUSANumber(phone.number);
        }
    }
}