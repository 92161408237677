import User from "../entities/User";

const LOCAL_STORAGE_TOKEN_NAME = 'AUTH';

class Token {
    public value?: string;

    constructor() {
        this.value = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME) || undefined;
    }

    save() {
        localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, this.value || '');
        document.cookie = `session=${this.value}; domain=3irq0dy6nd.execute-api.us-east-1.amazonaws.com; path=/`;
        document.cookie = `session=${this.value}; path=/`;
        document.cookie = `hash=1234; domain=3irq0dy6nd.execute-api.us-east-1.amazonaws.com; path=/`;
        document.cookie = `hash=1234; path=/`;
    }

    replace(value: string) {
        this.value = value;
        this.save();
    }

    remove() {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME)
        document.cookie = `session=${this.value}; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=3irq0dy6nd.execute-api.us-east-1.amazonaws.com; path=/`;
        document.cookie = `session=${this.value}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        document.cookie = `hash=1234; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=3irq0dy6nd.execute-api.us-east-1.amazonaws.com; path=/`;
        document.cookie = `hash=1234; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }

    parse(): User | undefined {
        try {
            const base64Url = this.value?.split('.')[1];
            const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64 || '').split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            const parsed = JSON.parse(jsonPayload);
            const ids = parsed.sub.split("~")
            return new User(ids[1],ids[0], parsed.name, parsed.email, parsed.roles, parsed.isRoot);
        } catch (e) {
            return undefined;
        }
    }
}

export default Token;