import Product from "../../entities/productOrder/topup/Product";
import Client from "../../entities/Client";
import ClientContact from "../../entities/ClientContact";
import Phone from "../../entities/Phone";
import CountryCode from "../../entities/CountryCode";
import PhoneType from "../../entities/PhoneType";

class TopupToDo {
    public comment?: string;
    public readonly client: Client;
    public readonly contact: ClientContact;
    public readonly agencyId?: string;
    public readonly userId?: string;
    public readonly total: number;
    public readonly product: Product;
    public readonly lastRecharge?: number;
    public readonly promoCount: number;
    public readonly error?: string;
    public readonly anonymousNumber?: string;

    constructor(
        client: Client,
        contact: ClientContact,
        total: number,
        product: Product,
        promoCount: number,
        agencyId?: string,
        userId?: string,
        comment?: string,
        lastRecharge?: number,
        error?: string,
        anonymousNumber?: string) {

        this.client = client;
        this.contact = contact;
        this.agencyId = agencyId;
        this.userId = userId;
        this.total = total;
        this.comment = comment;
        this.product = product;
        this.lastRecharge = lastRecharge;
        this.promoCount = promoCount;
        this.error = error;
        this.anonymousNumber = anonymousNumber;
    }

    get number(): Phone | undefined {
        if(this.anonymousNumber){
            return new Phone(this.anonymousNumber, CountryCode.CUBA, PhoneType.MOBILE);
        }
        return this.contact.mobilePhone;
    }
}

export default TopupToDo;