import PagedResponse from "../../../entities/PagedResponse";
import ReferenceItem from "../../../model/packageDelivery/referenceItem/ReferenceItem";
import { Config } from "../../../util/Config";
import { RestClient } from "../../../util/RestClient";
import Response from "../../../model/Response";
import Pagination from "../../../entities/Pagination";
import Request from "../../../model/Request";

export module ReferenceItemService{

    export async function get(page: number = 1, pageSize: number=15, filter?: string):Promise<Response<PagedResponse<ReferenceItem>>> {
        const url = Config.get("GET_REFERENCE_ITEM_URL",{
            page,
            pageSize, 
            filter
        })

        const request = new Request(url)
        const response = await RestClient.get(request)

        if(response.success){
            const resultData = response.data?.data
            const paginationData = response.data?.pagination

            if(resultData && paginationData){
                const data = resultData.map((t:any)=> new ReferenceItem(
                    t.id,
                    t.name
                ))
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount)
                return new Response<PagedResponse<ReferenceItem>>(true,{data,pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ReferenceItem>>(false,undefined,response.error,response.status)
    }

    export async function getAll(): Promise<Response<ReferenceItem[]>> {
        const url = Config.get("GET_REFERENCE_ITEM_URL", {page: 1, pageSize: 1000, filter: ''})
        const request = new Request(url)
        const response = await RestClient.get(request)
        if(response.success){
            const referenceItemsData = response.data?.data
            if(referenceItemsData){
                const data = referenceItemsData.map((t:any)=> new ReferenceItem(
                    t.id,
                    t.name
                ))
                return new Response<ReferenceItem[]>(true,data,response.error)
            }
        }
        return new Response<ReferenceItem[]>(false,undefined,response.error,response.status)
    }

    export async function create(referenceItem: ReferenceItem):Promise<Response<any>> {
        const url = Config.get("CREATE_REFERENCE_ITEM_URL")
        const request = new Request(url,referenceItem)
        return await RestClient.post(request)
    }

    export async function update(referenceItem: ReferenceItem):Promise<Response<any>> {
        const url = Config.get("UPDATE_REFERENCE_ITEM_URL",{id:referenceItem.id})
        const request = new Request(url,{
            name: referenceItem.name
        })
        return await RestClient.put(request)
    }

    export async function find(id:string):Promise<Response<ReferenceItem>> {
        const url = Config.get("FIND_REFERENCE_ITEM_URL",{id})
        const request = new Request(url)
        const response = await RestClient.get(request)  
        
        if(response.success){
            const referenceItemData = response.data
            if(referenceItemData){
                const referenceItem = new ReferenceItem(
                    referenceItemData.id, 
                    referenceItemData.name)
                return new Response<ReferenceItem>(true,referenceItem,response.error)
            }
        }
        return new Response<ReferenceItem>(false, undefined, response.error, response.status)
    }

    export async function remove(referenceItem: ReferenceItem):Promise<Response<any>> {
        const url = Config.get("REMOVE_REFERENCE_ITEM_URL",{id:referenceItem.id})
        const request = new Request(url)
        return await RestClient.remove(request)
    }
}