import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../ArticleDelivery.style";
import clsx from "clsx";
import ArticleDeliveryRowProperties from "./ArticleDeliveryRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ViewInvoiceButton from "../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton";
import ShowDeliveryPerson from '../../../shared_components/Info/ShowDeliveryPerson/ShowDeliveryPerson';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import {useUserContext} from '../../../contexts/UserContext';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import PrintToDeliverButton from '../../../shared_components/Buttons/PrintToDeliverButton/PrintToDeliverButton';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import ShowArticleDeliveryArticles from '../../../shared_components/Info/ShowArticleDeliveryArticles/ShowArticleDeliveryArticles';
import DeliveryOrderType from '../../../model/DeliveryOrderType';
import RemoveArticleDeliveryButton from '../../../shared_components/Buttons/RemoveArticleDeliveryButton/RemoveArticleDeliveryButton';
import ObservationsButton from '../../../shared_components/Buttons/ObservationsButton/ObservationsButton';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';

const ArticleDeliveryRowMedium: React.FC<ArticleDeliveryRowProperties> = ({
                                                                          articleDeliveryOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onArticleDeliveryOrderClick,
                                                                          onArticleDeliveryOrderSelect,
                                                                          onDeliveryPersonSelect,
                                                                          onContactSelect,
                                                                          onUpdate,
                                                                          filter
                                                                      }) => {


    const classes = useStyles();
    const i18n = useI18nContext();
    const userStore = useUserContext();

    const onRowArticleRemittanceClick = () => {
        onArticleDeliveryOrderClick(articleDeliveryOrder.id);
    }

    const onArticleDeliverySelect = () => {
        onArticleDeliveryOrderSelect(articleDeliveryOrder.id);
    }

    return (
        <div
            className={clsx(classes.article_info_container, userStore.isRootAgency() ? classes.article_info_container_grid_template_big : classes.article_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onArticleDeliverySelect}
                              selected={selectedDeliveryOrderIds.findIndex(t => t === articleDeliveryOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={articleDeliveryOrder.orderNumber}
                                     className={classes.order_number_text} textToHighlight={filter}/>
                </div>
                <ShowDate label={i18n.translate("CREATED")} timestamp={articleDeliveryOrder.createdAt}
                          className={classes.margin_top_4}/>
            </div>
            <div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={articleDeliveryOrder.clientName} title={'Remitente'} textToHighlight={filter}/>
                    {articleDeliveryOrder.clientPhone &&
                        <ShowPhoneNumber value={articleDeliveryOrder.clientPhone} showFlag={false} textToHighlight={filter}/>}
                </div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={articleDeliveryOrder.contactName} title={'Destinatario'} textToHighlight={filter}/>
                    {articleDeliveryOrder.contactPhone &&
                        <ShowPhoneNumber value={articleDeliveryOrder.contactPhone} showFlag={false} textToHighlight={filter}/>}
                </div>
            </div>
            <div>
                {articleDeliveryOrder &&
                    <div><small>Entrega</small>
                        <div><ShowText text={articleDeliveryOrder.address}
                                       title={articleDeliveryOrder.geo.name + '. ' + articleDeliveryOrder.geo.parentInfo}/>
                        </div>
                    </div>}
            </div>
            <ShowArticleDeliveryArticles valueToPay={articleDeliveryOrder.totalToPay} articles={articleDeliveryOrder.articles}/>
            <ShowDeliveryPerson
                text={articleDeliveryOrder.deliveryPersonName}
                showActionLinks={articleDeliveryOrder.status !== 'DELIVERED' && articleDeliveryOrder.status !== 'CANCELED' && articleDeliveryOrder.status !== 'NOT_DELIVERED'}
                orderByGeosIdProximity={[articleDeliveryOrder.geo.id]}
                onClick={onRowArticleRemittanceClick}
                onSelect={onDeliveryPersonSelect}
            />
            <div>
                <ShowText text={articleDeliveryOrder.agencyName} title={'Agencia'}/>
                <ShowText text={articleDeliveryOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
            </div>
            <div className={classes.status_container}>
                <ShowOrderStatus status={articleDeliveryOrder.status}/>
                {articleDeliveryOrder.status !== ProductOrderStatus.DELIVERED &&
                    articleDeliveryOrder.status !== ProductOrderStatus.CANCELED &&
                    <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={articleDeliveryOrder.id} deliveryOrderType={DeliveryOrderType.ARTICLE_DELIVERY}/>}
                {articleDeliveryOrder.status === ProductOrderStatus.DELIVERED &&
                    <ProductOrderStatusHistoryButton
                        detailsLink
                        productOrderId={articleDeliveryOrder.id}
                    />}
            </div>
            
            <ExpandoMenu>
                <>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderId={articleDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <PrintToDeliverButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[articleDeliveryOrder.id]}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={articleDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ObservationsButton
                        size={ButtonSize.SMALL}
                        deliveryOrderId={articleDeliveryOrder.id}
                        deliveryOrderNumber={articleDeliveryOrder.orderNumber}
                        deliveryOrderObservations={articleDeliveryOrder.observations}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <AllowAccess isRootAgency={true}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderId={articleDeliveryOrder.id}
                            deliveryOrderNumber={articleDeliveryOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    <RemoveArticleDeliveryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        articleDeliveryOrderId={articleDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default ArticleDeliveryRowMedium;
