import React, { useState } from 'react';
import {Box, CardContent, Typography} from "@material-ui/core";
import { useStyles } from './ShowShopProductDeliveryItem.style';
import ShowShopProductDeliveryItemProperties from './ShowShopProductDeliveryItemProperties';
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import ShowImage from '../ShowImage/ShowImage';
import Button from '../../../ui_components/Button/Button';
import ShowPrice from '../ShowPrice/ShowPrice';

const ShowShopProductDeliveryItem: React.FC<ShowShopProductDeliveryItemProperties> = ({
                                                id,
                                                title,
                                                text,
                                                imageName,
                                                price,
                                                stockAmount,
                                                imageWidth = 80,
                                                imageHeight = 80,
                                                isSelectable,
                                                selectedAmount,
                                                onAmountSelect
                                            }: ShowShopProductDeliveryItemProperties) => {

    const classes = useStyles();
    
    const [amount, setAmount] = useState<number>(selectedAmount ?? 0);

    const increaseAmount = () => {
        const newValue = amount + 1;
        setAmount(newValue);
        if (onAmountSelect) {
            onAmountSelect(newValue);
        }
    };
    
      const decreaseAmount = () => {
        if (amount > 0) {
            const newValue = amount - 1;
          setAmount(newValue);
          if (onAmountSelect) {
            onAmountSelect(newValue);
        }
        }
      };

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        if (onAmountSelect) {
            onAmountSelect(+newValue);
        }
    }

    return (
        <Box className={classes.card_container}>
            <Box>

                <CardContent className={classes.box_container}>
                    <Typography variant="subtitle1" className={classes.title} component="div">
                        {title}
                    </Typography>
                    <div className={classes.price_container}>
                        <ShowPrice value={price} className={classes.price}/>
                        {!isSelectable && <span>x {selectedAmount} = <strong>$ {(selectedAmount ?? 1) * price}</strong></span>}
                    </div>
                    <ShowImage imageName={imageName} width={imageWidth} height={imageHeight}/>
                    <Typography variant="body2" className={classes.description}>
                        {text}
                    </Typography>
                    {isSelectable && <Typography variant="caption" className={classes.stock_info}>
                        Em estoque: {stockAmount}
                    </Typography>}
                    {isSelectable && <div className={classes.amount_input_container}>
                        <Button text={'-'} onClick={decreaseAmount} disabled={amount <= 0}/>
                        <TextInput
                            type={TextInputType.TEXT}
                            value={amount}
                            onChange={onAmountChange}
                            label={''}
                            className={classes.amount_input}
                            fullWidth={false}
                        />
                        <Button text={'+'} onClick={increaseAmount}/>
                    </div>}
                </CardContent>

            </Box>
        </Box>
    )
}

export default ShowShopProductDeliveryItem;