import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ShopProduct from "../../../entities/productOrder/shop/ShopProduct";
import { ShopProductService } from "../../../service/shop/ShopProductService";


class RemoveShopProductStore {

    public shopProduct: ShopProduct;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, shopProduct: ShopProduct) {
        this.shopProduct = shopProduct;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ShopProductService.remove(this.shopProduct);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveShopProductStore;