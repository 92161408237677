import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../Sellers.style";
import clsx from "clsx";
import NewSellerButton from "../../../shared_components/Buttons/NewSellerButton/NewSellerButton";
import SellerRowProperties from "./SellerRowProperties";
import ToggleSellerStatusButton
    from "../../../shared_components/Buttons/ToggleSellerStatusButton/ToggleSellerStatusButton";
import RemoveSellerButton from "../../../shared_components/Buttons/RemoveSellerButton/RemoveSellerButton";
import ShowField from "../../../shared_components/Info/ShowField/ShowField";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";


const SellerRowSmall: React.FC<SellerRowProperties> = ({seller, onUpdate}) => {

    const classes = useStyles();
    const i18n = useI18nContext();


    return (
        <div className={clsx(classes.seller_info_container_small, !seller.isActive && classes.inactive)}>
            <ShowSellerBasicInfo autofill name={seller.name} email={seller.email}/>
            <ShowField
                value={i18n.translate(seller.status)}
                label={i18n.translate("STATUS")}/>
            <ShowField
                value={"-"}
                label={i18n.translate("ROLE")}/>
            <div className={classes.menu_container_small}>
                <NewSellerButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    sellerId={seller.id}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <ToggleSellerStatusButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    seller={seller}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
                <RemoveSellerButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    seller={seller}
                    showText={false}
                    type={ButtonType.SECONDARY}
                />
            </div>
        </div>
    )
}

export default SellerRowSmall;
