import IndividualItemPriceSectionProperties from "./IndividualItemPriceSectionProperties";
import React from "react";
import {useStyles} from "./IndividualItemPriceSection.style";
import {Observer} from "mobx-react-lite";
import ShowText from "../../Info/ShowText/ShowText";
import PriceInputShort from "../../Inputs/PriceInputShort/PricetInputShort";
import { CurrencyModule } from "../../../util/Currency";

const IndividualItemPriceSection:React.FC<IndividualItemPriceSectionProperties> =({
                                                                                      itemPrice,
                                                                                      finalItemPrice,
                                                                                      onFinalItemPriceChange,
                                                                                      disable,
                                                                                      fullWidth=false,
                                                                                      className='',
                                                                                  })=>{

    const classes = useStyles()


    return (
        <Observer>
            {() => (
                <div>
                    <div className={classes.individual_item_price_container}>
                        <ShowText
                            title={"Sugerencia"}
                            text={CurrencyModule.format(itemPrice)}/>
                        <PriceInputShort
                            label={"Precio individual"}
                            value={finalItemPrice}
                            onChange={onFinalItemPriceChange}/>
                    </div>
                </div>
            )}
        </Observer>
    )
}
export default IndividualItemPriceSection