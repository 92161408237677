import React, {createRef, useEffect} from 'react';
import AgencySelectProperties from "./AgencySelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./AgencySelect.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import Loading from "../../../ui_components/PageLoading/Loading";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import AgencySelectStore from "./AgencySelectStore";
import {Observer} from "mobx-react-lite";
import AgencyElementRow from "./row/AgencyElementRow";
import CancelIcon from '@mui/icons-material/Cancel';
import {Tooltip} from "@material-ui/core";
import Agency from "../../../entities/Agency";

const AgencySelect: React.FC<AgencySelectProperties> = ({
                                                                          onChange,
                                                                          disabled,
                                                                          fullWidth = false,
                                                                          className = '',
                                                                          selected,
                                                                          showCleanButton = true,
                                                                          initialLoadAmount = 15,
                                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const menuRef = createRef<HTMLDivElement>();
    const inputRef = createRef<HTMLInputElement>();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new AgencySelectStore(commonContext, initialLoadAmount, selected);
    const containerClasses = clsx(classes.select_container, className, fullWidth && commonClasses.fullWidth);

    useEffect(() => {
        if (selected) {
            store.setSelectedClient(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    // useEffect(() => {
    //     store.search();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[store.isSearchDialogOpen])

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                store.toggleSearchDialog(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuRef])

    const toggleDialog = () => {
        store.toggleSearchDialog();
        setTimeout(() => inputRef.current?.focus(), 20);
    }

    let timeout: NodeJS.Timeout;
    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        clearTimeout(timeout);
        const value = event.target.value;
        const changeFilter = () => {
            store.setFilter(value);
        };
        timeout = setTimeout(changeFilter, 800);
    }

    const selectAgency= (agency: Agency) => {
        store.setSelectedClient(agency);
        onChange(agency);
    }

    const removeSelected = (e:React.MouseEvent) => {
        store.setSelectedClient(undefined);
        e.stopPropagation();
        onChange(undefined);
    }
    return (
        <Observer>
            {() => (
                <div className={containerClasses} ref={menuRef}>
                    <div className={classes.label}>{i18n.translate("AGENCY")}</div>
                    <div className={classes.input_container} onClick={toggleDialog}>
                        <AccountBoxSharpIcon/>
                        {/*{store.loading && <Loading fontSize={22}/>}*/}
                        <div className={classes.info_container}>
                            {store.selectedAgency &&
                            <div className={classes.selected_container}>
                                <div className={classes.selected_text_container}>
                                    <div><b>{store.selectedAgency.name}</b></div>
                                    <div><b>{i18n.translate("EMAIL")}:</b>{`${store.selectedAgency.email}`}</div>
                                </div>
                                {showCleanButton && <Tooltip title={i18n.translate("CLEAN")}>
                                    <div onClick={removeSelected} className={classes.remove_icon}><CancelIcon/></div>
                                </Tooltip>}

                            </div>
                            }
                            {!store.selectedAgency && <div>{i18n.translate("SELECT_AGENCY_PLACEHOLDER")}</div>}
                        </div>
                        <div className={classes.expand_icon}>
                            {store.isSearchDialogOpen && <ExpandLessIcon/>}
                            {!store.isSearchDialogOpen && <ExpandMoreIcon/>}
                        </div>
                    </div>
                    {store.isSearchDialogOpen &&
                    <div className={classes.options_container}>
                        <div className={classes.options_header}>
                            <input placeholder={i18n.translate("AGENCY_FILTER_PLACEHOLDER")} ref={inputRef}
                                   onChange={(value) => onFilterChange(value)}/>
                        </div>
                        <div className={classes.header}>
                            <div className={classes.header_option}>
                                <b>{`${i18n.translate("TOTAL")}:`} </b>{store.pagination.elementsCount}
                            </div>
                        </div>
                        <div className={classes.options_body}>
                            {store.loading && <Loading className={classes.loading} fontSize={55}/>}
                            {!store.loading && !store.isEmpty && <div className={classes.elements_container}>
                                {store.elements.map((el, i) => (
                                    <AgencyElementRow
                                        key={i}
                                        className={className}
                                        element={el}
                                        selected={el.id === store.selectedAgency?.id}
                                        onSelect={selectAgency}
                                    />
                                ))}
                            </div>}
                            {!store.loading && store.isEmpty &&
                            <div className={classes.empty_container}>
                                <div>{i18n.translate("NO_AGENCIES_TITLE")}</div>
                            </div>}
                        </div>
                    </div>
                    }
                </div>
            )}
        </Observer>
    )
}


export default AgencySelect;