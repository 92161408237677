import React from 'react';

interface ProfileImagePlaceholderProps {
    className?: string,
    fontSize: number,
    name?: string
}

const ProfileImagePlaceholder: React.FC<ProfileImagePlaceholderProps> = ({
                                                                             className,
                                                                             fontSize = 20,
                                                                             name
                                                                         }) => {
    const firstLetter = name ? name[0].toUpperCase() : '';
    const colors = ["#5ac18e", "#008080", "#daa520", "#f6546a", "#cbcba9",
        "#0e2f44", "#0a75ad", "#fa8072", "#c39797", "#468499"];

    const getBackgroundColor = () => {
        const index = firstLetter.charCodeAt(0);
        if (index) return colors[index % colors.length];
        return '';
    }
    return (
        <div style={{
            fontSize: `${fontSize}px`,
            border: `2px solid #fff`,
            color: "#fff",
            borderRadius: '50%',
            minHeight: `${fontSize * 2}px`,
            minWidth: `${fontSize * 2}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: getBackgroundColor()
        }}>
            <span> {firstLetter}</span>
        </div>
    )
}
export default ProfileImagePlaceholder;