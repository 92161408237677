import Direction from "../enums/Direction";
import clsx from "clsx";
import {useCommonStyles} from "../CommonStyles.style";

export const useDirectionClasses = (direction: Direction) => {
    const commonClasses = useCommonStyles();
    return clsx(
        commonClasses.flex,
        direction === Direction.ROW && commonClasses.flexRow,
        direction === Direction.COLUMN && commonClasses.flexColumn);
}