import PromotionStatus from "./PromotionStatus";

class Promotion {
    public id: string;
    public name: string;
    public description: string;
    public defaultImage?: string;
    public agenciesId: string[];
    public startDate: number;
    public endDate: number;
    public status: PromotionStatus;
    public createdAt: number;
    public agencyId?: string;

    constructor(id: string, name: string, description: string, defaultImage: string, agenciesId: string[],
        startDate: number, endDate: number, status: PromotionStatus, createdAt: number, agencyId: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.defaultImage = defaultImage;
        this.agenciesId = agenciesId;
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
        this.createdAt = createdAt;
        this.agencyId = agencyId;
    }

}

export default Promotion;