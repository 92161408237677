import OrderAdditionalPrice from "../../entities/OrderAdditionalPriceRequest";
import AddPaymentRequest from "../payment/AddPaymentRequest";
import Currency from "../../entities/Currency";

class MoneyDeliveryOrderRequest {
    public clientId: string;
    public contactId: string;
    public agencyId?: string;
    public userId?: string;
    public amountToSend: number;
    public amountToReceive: number;
    public amountToPay: number;
    public contactBankCardId?: string;
    public payments:AddPaymentRequest[];
    public additionalPrices: OrderAdditionalPrice[];
    public currencyToReceive: Currency;
    public observations?: string;

    constructor(
        clientId: string,
        contactId: string,
        amountToSend: number,
        amountToReceive: number,
        amountToPay: number,
        currencyToReceive: Currency,
        payments:AddPaymentRequest[],
        additionalPrices: OrderAdditionalPrice[],
        contactBankCardId?: string,
        agencyId?: string,
        userId?: string,
        observations?: string,
        ) {
        this.clientId = clientId;
        this.contactId = contactId;
        this.additionalPrices = additionalPrices;
        this.amountToSend = amountToSend;
        this.amountToReceive = amountToReceive;
        this.amountToPay = amountToPay;
        this.contactBankCardId = contactBankCardId;
        this.currencyToReceive = currencyToReceive;
        this.payments = payments;
        this.agencyId = agencyId;
        this.userId = userId;
        this.observations = observations;
    }
}

export default MoneyDeliveryOrderRequest;