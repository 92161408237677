import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useStyles} from "./SideMenuLarge.style";
import RouteRepository from "../../../../route/RouteRepository";
import clsx from "clsx";
import {useI18nContext} from "../../../../contexts/I18nContext";
import {useUserContext} from "../../../../contexts/UserContext";
import ShowSellerBasicInfo from "../../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonType from "../../../../ui_components/Button/ButtonType";
import AllowAccess from "../../../../shared_components/Access/AllowAccess/AllowAccess";
import NewMoneyDeliveryButton
    from "../../../../shared_components/Buttons/NewMoneyDeliveryButton/NewMoneyDeliveryButton";
import Route from "../../../../route/Route";
import NewTopupButton from "../../../../shared_components/Buttons/NewTopupButton/NewTopupButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NewArticleDeliveryButton from "../../../../shared_components/Buttons/NewArticleDeliveryButton/NewArticleDeliveryButton";
import NewPackageDeliveryButton from "../../../../shared_components/Buttons/NewPackageDeliveryButton/NewPackageDeliveryButton";
import NewMiscellanyOrderButton from "../../../../shared_components/Buttons/NewMiscellanyOrderButton/NewMiscellanyOrderButton";
import NewShopOrderButton from "../../../../shared_components/Buttons/NewShopOrderButton/NewShopOrderButton";

const SingleMenuItem: React.FC<{ route: Route }> = ({route}) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const location = useLocation();
    const currentPath = location.pathname;

    const onClick = (path: string) => {
        if (path === currentPath) {
            document.location.href = currentPath;
        }
    };
    return (
        <AllowAccess key={route.path} any={route.access}>
            <Link to={route.path} className={clsx(classes.menu_item_container)}>
                <div
                    onClick={() => {
                        onClick(route.path);
                    }}
                    className={clsx(
                        classes.menu_item_title,
                        route.isActive(currentPath) && classes.active
                    )}
                >
                    <route.icon/>
                    <div>{i18n.translate(route.label)}</div>
                </div>
            </Link>
        </AllowAccess>
    );
};

const MenuItemWithChildren: React.FC<{ route: Route }> = ({route}) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const location = useLocation();
    const currentPath = location.pathname;
    const isSectionActive = route.children?.some((t) => t.isActive(currentPath));
    const [isOpen, setIsOpen] = useState(true);

    const onClick = (path: string) => {
        if (path === currentPath) {
            document.location.href = currentPath;
        }
    };

    return (
        <AllowAccess any={route.children?.flatMap((t) => t.access || [])}>
            <div
                className={clsx(
                    classes.menu_item_title,
                    classes.title_with_children,
                    isSectionActive && classes.title_with_children_active
                )}
                onClick={() => !isSectionActive && setIsOpen(!isOpen)}
            >
                <route.icon/>
                <div className={classes.title_label_with_children}>
                    <div>{i18n.translate(route.label)}</div>
                    {!isOpen && <KeyboardArrowRightIcon/>}
                    {isOpen && <KeyboardArrowDownIcon/>}
                </div>
                
            </div>
            {isOpen && (
                <div className={classes.menu_item_children}>
                    {route.children?.map((r, i) => (
                        <AllowAccess key={r.path} any={r.access}>
                            <Link to={r.path} className={clsx(classes.menu_item_container)}>
                                <div
                                    onClick={() => {
                                        onClick(r.path);
                                    }}
                                    className={clsx(
                                        classes.menu_item_title,
                                        r.isActive(currentPath) && classes.active
                                    )}
                                >
                                    {/* <r.icon/> */}
                                    <div className={classes.label_container}>{i18n.translate(r.label)}</div>
                                </div>
                            </Link>
                        </AllowAccess>
                    ))}
                </div>
            )}
        </AllowAccess>
    );
};

const MenuItem: React.FC<{ route: Route }> = ({route}) => {
    if (route.children && route.children.length > 0) {
        return <MenuItemWithChildren route={route}/>;
    }
    return <SingleMenuItem route={route}/>;
};

const SideMenuLarge: React.FC = () => {

    const classes = useStyles();
    const userStore = useUserContext();
    const routes = RouteRepository.getRoutes();
    const user = userStore.currentUser();

    return (
        <div className={classes.side_menu_container}>
            <ShowSellerBasicInfo email={user?.email} name={user?.name} className={classes.user_info_container}/>
            <div className={classes.main_tools_container}>
                <div className={classes.main_action_container}>
                    <NewMoneyDeliveryButton rounded type={ButtonType.PRIMARY} showText={false}/>
                </div>
                <div className={classes.main_action_container}>
                   <NewArticleDeliveryButton rounded type={ButtonType.PRIMARY} showText={false}/>
                </div>
                <div className={classes.main_action_container}>
                    <NewPackageDeliveryButton rounded type={ButtonType.PRIMARY} showText={false}/>
                </div>
                <div className={classes.main_action_container}>
                    <NewTopupButton rounded type={ButtonType.PRIMARY} showText={false}/>
                </div>
                <div className={classes.main_action_container}>
                    <NewMiscellanyOrderButton rounded type={ButtonType.PRIMARY} showText={false}/>
                </div>
                <div className={classes.main_action_container}>
                    <NewShopOrderButton rounded type={ButtonType.PRIMARY} showText={false}/>
                </div>
            </div>
            <div className={classes.menu_items_container}>
                {routes.map((r, i) => (r.showInMenu && <MenuItem key={i} route={r}/>))}
            </div>
        </div>
    );
};

export default SideMenuLarge;
