import {useI18nContext} from "../../../../contexts/I18nContext";
import {useStyles} from './GeoRow.style';
import React from "react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GeoRowProperties from "./GeoRowProperties";
import clsx from "clsx";

const GeoRow: React.FC<GeoRowProperties> = ({
                                                element,
                                                onSelect,
                                                className,
                                                selected
                                            }) => {
    const classes = useStyles();

    const i18n = useI18nContext();

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                {selected && <CheckBoxIcon/>}
                {!selected && <CheckBoxOutlineBlankIcon/>}
            </div>

            <div className={classes.text_container}>
                <div>{i18n.translate(element.getLevelText())}</div>
                <div>{element.name}</div>
                <div>{`${i18n.translate(element.getLevelText(element.level - 1))}: ${element.parentInfo}`}</div>
            </div>

        </div>
    )
}


export default GeoRow;