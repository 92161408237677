import CountryCode, {getPhoneCode} from "./CountryCode";
import PhoneType from "./PhoneType";
import {PhoneValidation} from "../util/PhoneValidation";

class Phone {
    public number: string;
    public countryCode: CountryCode;
    public phoneType: PhoneType;


    constructor(number: string, countryCode: CountryCode, phoneType: PhoneType) {
        this.number = number;
        this.countryCode = countryCode;
        this.phoneType = phoneType;
    }

    get phoneCode(): number {
        return getPhoneCode(this.countryCode);
    }

    get toString(): string {
        return `+${this.phoneCode} ${this.number}`;
    }

    get isValid(): boolean {
        if (!this.number) return false;
        return PhoneValidation.validate(this);
    }

}

export default Phone;