import React from 'react';
import NewTopupButtonProperties from "./NewTopupButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {useCommonContext} from "../../../contexts/CommonContext";
import NewTopupStore from "./NewTopupStore";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewTopupButton.style";
import SingleTopup from "./SingleTopup/SingleTopup";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import StepperOption from "../../../ui_components/Stepper/StepperOption";
import Steppers from "../../../ui_components/Stepper/Steppers";
import Payment from "./Payment/Payment";
import Resume from "./Resume/Resume";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import Access from '../../Access/AllowAccess/Access';
import { useUserContext } from '../../../contexts/UserContext';
import AgencySelect from './AgencySelect/AgencySelect';

const NewTopupButton: React.FC<NewTopupButtonProperties> = ({
                                                                onClick = () => {
                                                                },
                                                                showIcon = true,
                                                                showText = true,
                                                                disabled,
                                                                className = '',
                                                                rounded = false,
                                                                size = ButtonSize.DEFAULT,
                                                                type = ButtonType.DEFAULT
                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewTopupStore(commonContext);
    const userStore = useUserContext();

    const handleOnclick = async () => {
        onClick();
        await store.openModal();
    }
    const onCloseModal = () => {
        store.closeModal();
    }

    const isContactStepCompleted = (): boolean => {
        return store.topups.length > 0;
    }

    const isPaymentCompleted = (): boolean => {
        return store.paymentCompleted;
    }

    const isAgencySelected = (): boolean => {
        if (!store.selectedAgency) return true;
        return !!store.selectedSeller?.id
    }

    let index = 0;
    let steps: StepperOption[] = [];    
    if (userStore.hasAccess(Access.SEND_ORDERS_TO_AGENCIES) && userStore.hasAccess(Access.LIST_AGENCY)) {
        steps.push(new StepperOption(index++, i18n.translate("SELECT_AGENCY"), isAgencySelected, <AgencySelect
            store={store}/>))
    }
    steps.push(new StepperOption(0, i18n.translate("SELECT_CONTACT_PLACEHOLDER"), isContactStepCompleted, <SingleTopup store={store}/>));
    steps.push(new StepperOption(1, i18n.translate("PAYMENT"), isPaymentCompleted, <Payment store={store}/>));
    steps.push(new StepperOption(1, i18n.translate("RESUME"), () => false, <Resume store={store}/>));

    const handleFinish = () => {
        store.doTopups();
    };

    const getIcon = (): React.ReactNode => {
        return showText ? <Icon name={IconName.CREATE_ICON}/> : <SendToMobileIcon/>;
    }

    const text = showText ? i18n.translate("NEW_TOPUP") : "";
    const tooltip = !showText ? i18n.translate("NEW_TOPUP") : "";
    const btnIcon = showIcon ? getIcon() : React.Fragment;


    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                className={className}
                onClick={handleOnclick}
                tooltip={tooltip}
                disabled={disabled}
                size={size}
                type={type}
                text={text}
                icon={btnIcon}
            />
        }
        return <Button
            className={className}
            onClick={handleOnclick}
            tooltip={tooltip}
            disabled={disabled}
            size={size}
            type={type}
            text={text}
            startIcon={btnIcon}
        />
    }

    return <Observer>
        {() => (
            <>
                <SidePanel title={i18n.translate("NEW_TOPUP")} onClose={onCloseModal}
                           open={store.isModalOpen} className={classes.modal_container}>
                    <Steppers 
                        activeStep={store.activeStep}
                        onActiveStepChange={(value) => {
                            store.activeStep = value;
                        }}
                        steps={steps} containerClassName={classes.stepper_container}
                        handleFinish={handleFinish}
                        finishButtonLoading={store.doingTopup}/>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewTopupButton;
