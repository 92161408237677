import React from 'react';
import AssignShippingIdButtonProperties from "./AssignShippingIdButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import AssignShippingIdStore from "./AssignShippingIdStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AssignShippingIdButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Badge from '@mui/icons-material/Badge';
import DeliveryPersonType from '../../../entities/DeliveryPersonType';
import Box from "../../../ui_components/Box/Box";
import ShowText from "../../Info/ShowText/ShowText";
import ShippingEditInput from "../../Inputs/ShippingEditInput/ShippingEditInput";
import UpdateShippingIdButton from "../UpdateShippingIdButton/UpdateShippingIdButton";
import ShipmentUpdateType from "../../../entities/ShipmentUpdateType";

const AssignShippingIdButton: React.FC<AssignShippingIdButtonProperties> = ({
                                                                                multiOrders = false,
                                                                                deliveryOrderIds,
                                                                                deliveryOrderNumbers,
                                                                                deliveryPersonType = DeliveryPersonType.DELIVERY_PERSON,
                                                                                onUpdate = () => {},
                                                                                onClick = () => {
                                                                                },
                                                                                onSelect = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT
                                                                            }) => {
    const classes = useStyles(showText);
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new AssignShippingIdStore(commonContext, deliveryOrderIds);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }
    
/*     const onDeliveryPersonSelect = async (deliveryPerson?: DeliveryPerson) => {
        onSelect(deliveryPerson);
        onCloseModal();
    } */

    const getText = () =>{
        return i18n.translate("ASSIGN_SHIPPING_NUMBER")
    }
    const getIcon = () =>{
        switch(deliveryPersonType){
            case DeliveryPersonType.DELIVERY_PERSON:
                return <DeliveryDiningIcon/>
            case DeliveryPersonType.ORGANIZER:
                return <Badge/>
        }
    }

    
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? getText() : ""}
                text={showText ? getText() : ""}
                type={type}
                icon={showIcon ? getIcon() : <></>}
            />  
        }
        return <Button
            text={showText ? getText() : ""}
            className={className}
            tooltip={!showText ? getText() : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? getIcon() : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={getText()} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.total_container}>
                        <Box className={classes.big_modal_container}>
                            <ShowText title={"Ordenes"}
                                      text={store.deliveryOrderIds?.length.toString() + " órdenes"}></ShowText>
                            <div className={classes.selected_container}>
                                {deliveryOrderNumbers?.map((deliveryOrderId, i) => (
                                    <div className={classes.selected_order} key={i}>{deliveryOrderId}</div>
                                ))}
                            </div>
                            <div className={classes.full_width}>
                                <ShippingEditInput value={store.suggestedId}
                                                   placeholder={i18n.translate("PACKAGE_DELIVERY_SHIPPING_FILTER_PLACEHOLDER")}
                                                   onChange={(value) => {
                                                       store.setShippingId(value)
                                                   }} onEnter={() => store.updateID()
                                }/>
                            </div>
                            <div className={classes.btn_container}>
                                <div className={classes.btn_container}>
                                    <UpdateShippingIdButton
                                        updateType={ShipmentUpdateType.TAKE_FROM}
                                        deliveryOrderIds = {store.deliveryOrderIds}
                                        //onSelect={onDeliverSelected}
                                        type={ButtonType.SECONDARY}
                                        onUpdate={onUpdate}
                                        selectedShippingId={store.selectedShippingId}/>
                                    <UpdateShippingIdButton
                                        updateType={ShipmentUpdateType.ADD}
                                        deliveryOrderIds = {store.deliveryOrderIds}
                                        //onSelect={onOrganizerSelected}
                                       // onClick={onClick}
                                        onUpdate={onUpdate}
                                        type={ButtonType.SECONDARY}
                                        selectedShippingId={store.selectedShippingId}/>

                                </div>
                            </div>

                        </Box>
                    </div>
                    {/*<div className={classes.modal_container}>*/}
                    {/*    <ChooseManageShippingID*/}
                    {/*        multiOrders = {multiOrders}*/}
                    {/*        deliveryOrderIds={deliveryOrderIds}*/}
                    {/*        deliveryOrderNumbers={deliveryOrderNumbers}*/}
                    {/*        // haveDeliveryPersonSelected={currentDeliveryPersonInfo!=null}*/}
                    {/*        // orderByGeoIdProximity={orderByGeoIdProximity}*/}
                    {/*        onClick={onClick}*/}
                    {/*        //onSelect={onSelect}*/}
                    {/*        // onUpdate={onUpdate}*/}
                    {/*        disabled={disabled}*/}
                    {/*        showText={showText}*/}
                    {/*        showIcon={showIcon}*/}
                    {/*        rounded={rounded}*/}
                    {/*        size={size}*/}
                    {/*        type={type}/>*/}
                    {/*</div>*/}
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default AssignShippingIdButton;
