import InventoryItem from "../../../model/packageDelivery/inventoryItem/InventoryItem";
import {Config} from "../../../util/Config";
import {RestClient} from "../../../util/RestClient";
import Request from "../../../model/Request";
import Response from "../../../model/Response";
import PagedResponse from "../../../entities/PagedResponse";
import Pagination from "../../../entities/Pagination";

export module InventoryItemService{

    export async function get(page:number=1,pageSize:number=15, filter?:string, customCategoryId?:string):Promise<Response<PagedResponse<InventoryItem>>>{
        const url = Config.get("GET_INVENTORY_ITEM_URL",{
            page,
            pageSize,
            filter,
            customCategoryId,
        })

        const request = new Request(url)
        const response = await RestClient.get(request)

        if(response.success){
            const resultData = response.data?.data
            const paginationData = response.data?.pagination

            if(resultData && paginationData){
                const data = resultData.map((t:any)=> new InventoryItem(
                    t.id,
                    t.agencyId,
                    t.name,
                    t.categoryInfo,
                    t.price,
                    t.comments,
                    t.status,
                    t.createdAt
                ))
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount,paginationData.pageCount)
                return new Response<PagedResponse<InventoryItem>>(true,{data,pagination}, response.error)
            }
        }
        return new Response<PagedResponse<InventoryItem>>(false,undefined,response.error,response.status)
    }

    export async function create(inventoryItem: InventoryItem):Promise<Response<any>>{
        const url = Config.get("CREATE_INVENTORY_ITEM_URL")
        const request = new Request(url, inventoryItem);
        return await RestClient.post(request);
    }

    export async function update(inventoryItem: InventoryItem):Promise<Response<any>>{
        const url = Config.get("UPDATE_INVENTORY_ITEM_URL",{id:inventoryItem.id})
        const request = new Request(url,{
            name: inventoryItem.name,
            categoryInfo:inventoryItem.categoryInfo,
            price: inventoryItem.price,
            comments:inventoryItem.comments
        })
        return await RestClient.put(request)
    }

    export async function find(id:string):Promise<Response<InventoryItem>>{
        const url = Config.get("FIND_INVENTORY_ITEM_URL",{id})
        const request = new Request(url)
        const response = await RestClient.get(request)

        if(response.success){
            const inventoryItemData = response.data
            if (inventoryItemData){
                const inventoryItem = new InventoryItem(
                    inventoryItemData.id,
                    inventoryItemData.agencyId,
                    inventoryItemData.name,
                    inventoryItemData.categoryInfo,
                    inventoryItemData.price,
                    inventoryItemData.comments,
                    inventoryItemData.status,
                    inventoryItemData.createdAt)
                return new Response<InventoryItem>(true,inventoryItem,response.error)
            }
        }
        return new Response<InventoryItem>(true,undefined,response.error,response.status)
    }


    export async function remove(inventoryItem:InventoryItem):Promise<Response<any>>{
        const url = Config.get("REMOVE_INVENTORY_ITEM_URL", {id: inventoryItem.id})
        const request = new Request(url)
        return await RestClient.remove(request)
    }
}
