import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PaymentFee from "../entities/PaymentFee";

export module PaymentService {
    export async function getPaymentFees(agencyId?: string): Promise<Response<PaymentFee[]>> {
        const url = Config.get("GET_PAYMENT_FEE_URL", {agencyId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function updatePaymentFees(paymentFees: PaymentFee[], agencyId?: string,): Promise<Response<PaymentFee[]>> {
        const url = Config.get("UPDATE_PAYMENT_FEE_URL", {agencyId});
        const request = new Request(url, paymentFees)
        return await RestClient.post(request)
    }
}