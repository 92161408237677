import React from "react";
import InventoryItemRowProperties from "./InventoryItemRowProperties";
import {useStyles} from "../InventoryItem.style";
import clsx from "clsx";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import RemoveInventoryItemButton
    from "../../../shared_components/Buttons/RemoveInventoryItemButton/RemoveInventoryItemButton";


const InventoryItemRowSmall:React.FC<InventoryItemRowProperties> = ({
                                                                                inventoryItem,
                                                                                onUpdate,
                                                                            }) => {
    const classes = useStyles()

    return (
        <div className={clsx(classes.items_inventory_info_container_small)}>
            <div className={classes.menu_container_small}>
                <RemoveInventoryItemButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    inventoryItem={inventoryItem}
                    showText={false}
                    type={ButtonType.SECONDARY}/>
            </div>
        </div>
    )
}
export default InventoryItemRowSmall