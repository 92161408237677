import {action, makeAutoObservable} from "mobx";
import { PackageDeliveryService } from "../../../service/packageDelivery/PackageDeliveryService";
import CommonStore from "../../../stores/CommonStore";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"]
}
class RemovePackageDeliveryStore {

    public moneyDeliveryOrderId: string;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, moneyDeliveryOrderId: string) {
        this.moneyDeliveryOrderId = moneyDeliveryOrderId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await PackageDeliveryService.remove(this.moneyDeliveryOrderId);
        if (!response.success && response.status === 400 && response.error === 'ERROR_00_400_44') {
            await this.commonStore.showErrorToast('No se pudo eliminar el envío porque está en un estado que lo imposibilita');
        } else {
            this.commonStore.processErrors(response);
        }  
        this.processError(response.error)      
        return response.success;
    }

    processError(error?: string) {
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error) //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
        }
    }
}

export default RemovePackageDeliveryStore;