import { action, makeAutoObservable, runInAction } from "mobx";
import CommonStore from "../../../stores/CommonStore";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import Client from "../../../entities/Client";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import ShopOrderResponse from "../../../model/shop/ShopOrderResponse";
import ShopProduct from "../../../entities/productOrder/shop/ShopProduct";
import { ShopOrderService } from "../../../service/shop/ShopOrderService";
import ShopOrderRequest from "../../../model/shop/ShopOrderRequest";
import { ShopProductService } from "../../../service/shop/ShopProductService";

class NewShopOrderStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public commonStore: CommonStore;
    public response?: ShopOrderResponse[]
    public errorKey?: string;

    public finishButtonLoading = false;

    public activeStep = 0;

    // Remittances data

    // Products
    public loadingShopProducts: boolean;
    public products?: ShopProduct[];
    public selectedProducts: ShopProduct[] = [];
    public productFilter?: string;

    // Client
    public selectedClient?: Client;
    
    public observations?: string;

    // Additional prices
    public additionalPrices: OrderAdditionalPrice[];

    // Payments
    public payments: AddPaymentRequest[];
    public paymentCompleted: boolean;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.validating = false;
        this.loadingShopProducts = false;
        this.selectedProducts = [];
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    public reset() {
        this.loadingShopProducts = false;
        this.selectedProducts = [];
        this.selectedClient = undefined;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.activeStep = 0;
        this.observations = undefined;
    }

    @action
    public async openModal() {
        this.reset();
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public restart() {
        this.response = undefined;
    }

    // Client area

    @action
    public setClient(client?: Client) {
        this.selectedClient = client;
    }

    @action
    public setObservations(observations?: string) {
        this.observations = observations;
    }

    // Products area

    get isEmpty(): boolean {
        return !this.products || this.products.length === 0;
    }

    @action
    public async getProducts(): Promise<void> {
        this.loadingShopProducts = true;
        const response = await ShopProductService.get(1, 100, this.productFilter);
        if (response) {
            runInAction(() => {
                this.products = response.data?.data || [];
            })
        }
        this.loadingShopProducts = false;
    }

    @action
    public async productAmountChange(productId: string, amount: number) {
        if (amount === 0) {
            this.selectedProducts = this.selectedProducts.filter(t => t.id !== productId);
            return;
        }
        const product = this.products!.find(t => t.id === productId);
        if (!product) {
            return;
        }
        const selectedProductIndex = this.selectedProducts.findIndex(t => t.id === productId);
        if (selectedProductIndex >= 0) {
            this.selectedProducts[selectedProductIndex] = { ...product, amount };
        } else {
            this.selectedProducts.push({ ...product, amount });
        }
    }

    // Additional prices area

    @action
    public addAdditionalPrice(currency: string, amount: number, comment: string) {
        this.additionalPrices.push(new OrderAdditionalPrice(amount, comment, false));
    }

    @action
    public removeAdditionalPrice(additionalPrice: OrderAdditionalPrice) {
        this.additionalPrices = this.additionalPrices.filter(t => t.amount !== additionalPrice.amount && t.comment !== additionalPrice.comment);
    }

    public getAdditionalPriceTotal(): number {
        return this.additionalPrices.reduce((sum, current) => sum + current.amount, 0);
    }

    // Payment area

    public getServicePrice(): number {
        return this.selectedProducts?.reduce((total, obj) =>
            total + (obj.price * (obj.amount ?? 1)), 0) || 0;
    }

    public getTotalToPay(): number {
        return this.getServicePrice() + this.getAdditionalPriceTotal();
    }

    @action
    public setPayments(payments: AddPaymentRequest[], paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
    }

    @action
    public async createShopOrder() {
        if (!this.selectedClient) {
            await this.commonStore.showErrorToast('Debe seleccionar un cliente.');
            return;
        }
        if (!this.selectedProducts || this.selectedProducts.length === 0) {
            await this.commonStore.showErrorToast('Debe seleccionar algún producto.');
            return;
        }
        if (this.finishButtonLoading) {
            return;
        }
        this.finishButtonLoading = true;
        const response = await ShopOrderService.create(
            new ShopOrderRequest(
                this.selectedClient.id,
                this.getTotalToPay(),
                this.selectedProducts.map(product => {
                    return {
                        shopProductId: product.id,
                        amount: product.amount ?? 1,
                    }
                }),
                this.payments,
                this.additionalPrices,
                this.observations));
        if (response.success && response.data) {
            this.closeModal();
            window.location.href = '/shop-orders';
        }
        this.finishButtonLoading = false;
    }

}

export default NewShopOrderStore;