import React from 'react';
import {useStyles} from "./Tag.style";
import TagProperties from "./TagProperties";
import clsx, {ClassValue} from "clsx";
import TagType from "./TagType";


const Tag: React.FC<TagProperties> = ({
                                          text,
                                          startIcon: StartIcon,
                                          endIcon: EndIcon,
                                          tagType = TagType.PRIMARY,
                                          className = '',
                                          fitContent = false
                                      }: TagProperties) => {
    const classes = useStyles();

    const getTypedClass = (): ClassValue => {
        switch (tagType) {
            case TagType.DEFAULT:
                return classes.default;
            default:
            case TagType.PRIMARY:
                return classes.primary;
            case TagType.SECONDARY:
                return classes.secondary;
            case TagType.DANGER:
                return classes.danger;
        }
    }

    const containerClasses = clsx(
        className,
        classes.tag_container,
        fitContent && classes.fit_content,
        getTypedClass());

    return (
        <div className={containerClasses}>
            {StartIcon &&
                <div className={classes.start_icon_container}>
                    <StartIcon/>
                </div>}
            <div className={classes.text}>{text}</div>
            {EndIcon &&
                <div className={classes.end_icon_container}>
                    <EndIcon/>
                </div>}

        </div>
    )
}
export default Tag;