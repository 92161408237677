const values= {
    "INVENTORY_ITEMS": "Inventario de artículos",
    "INVENTORY_ITEMS_PLACEHOLDER": "Nombre de artículo",
    "ITEMS": "Artículos",
    "NO_INVENTORY_ITEMS_TITLE": "No hay artículo en el inventario!!!",
    "NO_INVENTORY_ITEMS_SUBTITLE": "Verifica si estás aplicando algún filtro.",
    "NEW_INVENTORY_ITEM": "Nuevo artículo en inventario",
    "EDIT_INVENTORY_ITEM": "Editar artículo",
    "INVENTORY_ITEM_NAME": "Nombre de artículo",
    "INVENTORY_ITEM_DESCRIPTION": "Descripción",
    "INVENTORY_ITEM_DEFAULT_CUSTOM_PRICE":"Precio aduanal",
    "INVENTORY_ITEM_DEFAULT_TO_CONSIDER_PRICE":"Precio a consideración",
    "INVENTORY_ITEMS_COMMENTS": "Comentarios",
    "INVENTORY_ITEMS_CATEGORY": "Categoría",
    "INVENTORY_ITEMS_CATEGORY_PLACEHOLDER":"Seleccione la categoría aduanal",
    "REMOVE_INVENTORY_ITEMS_MANAGER": "Eliminar artículo",
    "REMOVE_INVENTORY_ITEMS_CONFIRMATION": "¿Esta seguro que desea eliminar el artículo?",
}
export default values;