import React, {useRef} from 'react';
import MoneySelectProperties from "./MoneySelectProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./MoneySelect.style";
import TextInput from '../../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../../ui_components/TextInput/TextInputType';
import {useI18nContext} from '../../../../contexts/I18nContext';
import CurrencySelect from '../../../Selects/CurrencySelect/CurrencySelect';
import Currency from '../../../../entities/Currency';
import {Box} from '@material-ui/core';
import ShowMoneyDeliveryBudgetDetails
    from '../../../Info/ShowMoneyDeliveryBudgetDetails/ShowMoneyDeliveryBudgetDetails';
import MessageCard from '../../../../ui_components/MessageCard/MessageCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Group from "../../../../ui_components/Group/Group";
import Checkbox from "../../../../ui_components/Checkbox/Checkbox";
import {useCommonStyles} from '../../../../ui_components/common/CommonStyles.style';
import ContactBankCardSelect from '../../../Selects/ContactBankCardSelect/ContactBankCardSelect';
import NewContactBankCardButton from '../../NewContactBankCardButton/NewContactBankCardButton';
import ButtonType from '../../../../ui_components/Button/ButtonType';
import ButtonSize from '../../../../ui_components/Button/ButtonSize';
import {motion} from 'framer-motion';
import clsx from 'clsx';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ContactBankCard from '../../../../entities/ContactBankCard';


const MoneySelect: React.FC<MoneySelectProperties> = ({
                                                          store
                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const i18n = useI18nContext();

    let timeout1 = useRef<NodeJS.Timeout>();
    const onAmountToSendChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newAmountToSend = event.target.value;
        store.isByAmountToSend = true;
        store.amountToSend = +newAmountToSend;
        store.totalToPay = 0;
        store.amountToReceive = 0
        if (timeout1.current != null) {
            clearTimeout(timeout1.current);
        }
        const changeFilter = () => {
            store.getBudgetDetailsFromAmountToSend();
        };
        timeout1.current = setTimeout(changeFilter, 800);
    }

    let timeout2 = useRef<NodeJS.Timeout>();
    const onAmountToPayChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        store.isByAmountToSend = true;
        const newAmountToPay = event.target.value;
        store.totalToPay = +newAmountToPay;
        store.amountToSend = 0;
        store.amountToReceive = 0
        if (timeout2.current != null) {
            clearTimeout(timeout2.current);
        }
        const changeFilter = () => {
            store.getBudgetDetailsFromAmountToPay();
        };
        timeout2.current = setTimeout(changeFilter, 800);
    }

    let timeout3 = useRef<NodeJS.Timeout>();
    const onAmountToReceiveChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        store.isByAmountToSend = false;
        const newAmountToReceive = event.target.value;
        store.amountToReceive = +newAmountToReceive;
        store.totalToPay = 0;
        store.amountToSend = 0
        if (timeout3.current != null) {
            clearTimeout(timeout3.current);
        }
        const changeFilter = () => {
            store.getBudgetDetailsFromAmountToReceive();
        };
        timeout3.current = setTimeout(changeFilter, 800);
    }

    const onCurrencyChange = (newCurrency: Currency) => {
        store.currency = newCurrency;
        if (newCurrency === Currency.MLC) {
            store.isCardRemittance = true;
        }
        if (newCurrency === Currency.DOLLAR) {
            store.isCardRemittance = false;
            store.contactBankCard = undefined;
        }
        store.getBudgetDetailsFromAmountToSend();
    }

    const onContactBankCardChange = (contactBankCardId: string) => {
        store.contactBankCard = store.selectedContactBankCards ?
            store.selectedContactBankCards.find(t => t.id === contactBankCardId) :
            undefined;
    }

    const onNewContactBankCardSaved = async (savedContactBankCard: ContactBankCard) => {
        store.loadSelectedContactBankCards();
        store.contactBankCard = { ...savedContactBankCard };
    }

    const noDataContainer =
        <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_MONEY_REMITTANCES_BUDGET_TITLE")}
                     subtitle={i18n.translate("NO_MONEY_REMITTANCES_BUDGET_SUBTITLE")}/>

    return <Observer>
        {() => (
            <Box className={classes.container}>
                <Box className={classes.form_container}>
                    <div className={classes.amounts_container}>
                        <div>
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={store.amountToSend}
                                onChange={onAmountToSendChange}
                                error={i18n.translate(store.amountToSendError)}
                                label={i18n.translate("AMOUNT_TO_SEND_PLACEHOLDER")}
                                className={classes.input}
                                fullWidth
                            />
                            <TextInput
                                type={TextInputType.NUMBER}
                                value={store.totalToPay}
                                onChange={onAmountToPayChange}
                                error={i18n.translate(store.amountToSendError)}
                                label={i18n.translate("TOTAL_TO_PAY")}
                                className={classes.input}
                                fullWidth
                            />
                        </div>
                        <div className={classes.amounts_icon}><CompareArrowsIcon/></div>
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.amountToReceive}
                            onChange={onAmountToReceiveChange}
                            error={i18n.translate(store.amountToReceiveError)}
                            label={i18n.translate("AMOUNT_TO_RECEIVE_PLACEHOLDER")}
                            className={classes.input}
                            fullWidth
                        />
                    </div>

                    <CurrencySelect fullWidth className={classes.input}
                                    label={i18n.translate("RECEIVE_IN")}
                                    selected={store.currency}
                                    onChange={onCurrencyChange}/>
                    <Box>
                        <Checkbox selected={store.isCardRemittance} disabled={store.currency === Currency.MLC || store.currency === Currency.DOLLAR} text={i18n.translate("BANK_CARD_MONEY_REMITTANCE")}
                                  className={classes.checkbox} onChange={(value) => {
                            store.isCardRemittance = value;
                        }}/>
                        <Group disabled={!store.isCardRemittance}
                               className={clsx(commonClasses.marginTop, classes.contact_bank_card_group)}>
                            <ContactBankCardSelect fullWidth className={classes.input}
                                                   selected={store.contactBankCard}
                                                   contactBankCards={store.selectedContactBankCards ?? []}
                                                   onChange={onContactBankCardChange}
                                                   contactId={store.selectedContact?.id}/>
                            <motion.div animate={{scale: [0, 1]}} transition={{delay: 0.2, duration: 0.2}}
                                        className={classes.padding_top_12}>
                                <NewContactBankCardButton
                                    size={ButtonSize.SMALL}
                                    type={ButtonType.SECONDARY}
                                    showText={false}
                                    disabled={store.selectedContact == null}
                                    onSave={onNewContactBankCardSaved}
                                    contactId={store.selectedContact?.id!!}/>
                            </motion.div>
                        </Group>
                    </Box>
                </Box>
                <div className={classes.filler}/>
                <div className={classes.details_container}>
                    {store.noBudgetData ? noDataContainer : <ShowMoneyDeliveryBudgetDetails
                        budgetDetails={store.budgetDetails} isLoading={store.budgetDetailsLoading}/>}
                </div>
            </Box>
        )}
    </Observer>
}

export default MoneySelect;
