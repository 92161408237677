import React, {useEffect} from 'react';
import AgencySalesByChildAgenciesProperties from "./AgencySalesByChildAgenciesProperties";
import {useStyles} from "./AgencySalesByChildAgencies.style";
import AgencySalesByChildAgenciesStore from "./AgencySalesByChildAgenciesStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import {Observer} from "mobx-react-lite";
import {useI18nContext} from "../../../contexts/I18nContext";
import PriceLockup from "../../../ui_components/PriceLockup/PriceLockup";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import InfoIcon from "@mui/icons-material/Info";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import clsx from "clsx";
import {useUserContext} from "../../../contexts/UserContext";
import Tag from "../../../ui_components/Tag/Tag";
import TagType from "../../../ui_components/Tag/TagType";
import SaleByServiceResponse from "../../../model/sales/SaleByServiceResponse";
import ServiceName from "../../../entities/ServiceName";
import MoneyDeliveryOverview from "../MoneyDeliveryOverview/MoneyDeliveryOverview";
import SaleByAgencyResponse from "../../../model/sales/SaleByAgencyResponse";
import DownloadAgencyOrdersReportButton
    from "../../Buttons/DownloadAgencyOrdersReportButton/DownloadAgencyOrdersReportButton";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";

const AgencySalesByChildAgencies: React.FC<AgencySalesByChildAgenciesProperties> = ({
                                                                                        from,
                                                                                        to,
                                                                                        agencyId,
                                                                                        title,
                                                                                        className = '',
                                                                                    }) => {

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const userStore = useUserContext();
    const store = new AgencySalesByChildAgenciesStore(commonStore, from, to, agencyId);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);
    const RenderPriceLockup: React.FC<{
        agencySale: SaleByAgencyResponse,
        service: SaleByServiceResponse
    }> = ({agencySale, service}) => {
        switch (service.serviceName) {
            case ServiceName.MONEY_DELIVERY:
                return <PriceLockup
                    value={service.total}
                    title={i18n.translate(service.serviceName)}
                    total={agencySale.data.breakdown.reduce((t, c) => t + c.total, 0)}
                    size={15}
                    orders={service.amount}
                    theme={"purple"}>
                    <MoneyDeliveryOverview
                        from={from}
                        to={to}
                        agencyId={agencySale.agencyId}
                        size="small"
                    />
                </PriceLockup>
            default:
                return <PriceLockup
                    value={service.total}
                    title={i18n.translate(service.serviceName)}
                    total={agencySale.data.breakdown.reduce((t, c) => t + c.total, 0)}
                    size={15}
                    orders={service.amount}
                    theme={"purple"}/>
        }
    }

    return <Observer>{() => (
        <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            <div className={classes.item_row_container}>
                {store.sales && store.sales.breakdown.map((d, i) => (
                    <div key={i}
                         className={clsx(classes.item_row, d.agencyId === userStore.currentUser()?.agencyId && classes.current_row)}
                    >
                        <div className={classes.agency_container}>
                            {d.agencyId === userStore.currentUser()?.agencyId &&
                                <Tag className={classes.current_agency} text={"Mi Agencia"}
                                     tagType={TagType.SECONDARY}/>
                            }
                            <ShowSellerBasicInfo autofill name={d.agencyName} email={d.agencyEmail}
                                                 phone={d.agencyPhone}/>

                        </div>
                        <PriceLockup value={d.data.breakdown.reduce((t, c) => t + c.total, 0)}
                                     size={20}
                                     theme={"primary"}
                                     withBackground
                                     orders={d.data.breakdown.reduce((t, c) => t + c.amount, 0)}
                                     title={"Total de ventas"}/>
                        <div className={classes.item_sales_container}>
                            {d.data.breakdown.map((dd, ii) => (
                                <RenderPriceLockup service={dd} agencySale={d} key={ii}/>
                            ))}
                        </div>
                        <ExpandoMenu>
                            <>
                                <DownloadAgencyOrdersReportButton
                                    type={ButtonType.SECONDARY}
                                    showPreview={false}
                                    agencyId={d.agencyId}
                                    from={from}
                                    to={to}
                                    size={ButtonSize.SMALL}
                                />
                            </>
                        </ExpandoMenu>
                    </div>
                ))}
            </div>
            {store.sales?.breakdown.length === 0 &&
                <div className={classes.no_content_container} onClick={() => store.getElements()}>
                    <InfoIcon/>
                    {i18n.translate("NO_SALES")}
                </div>
            }
            <PageLoading className={classes.loading_container} loadingSize={40} isLoading={store.loading}
                         text={`${i18n.translate("LOADING")} ${title || ''}`}/>
        </div>
    )}</Observer>

}


export default AgencySalesByChildAgencies;