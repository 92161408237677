import React from 'react';
import ShowServiceNameProperties from "./ShowServiceNameProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowServiceName.style";
import clsx from "clsx";
import ServiceName from "../../../entities/ServiceName";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Tag from "../../../ui_components/Tag/Tag";
import TagType from "../../../ui_components/Tag/TagType";

const ShowServiceName: React.FC<ShowServiceNameProperties> = ({
                                                                  service,
                                                                  showIcon = true,
                                                                  className = '',
                                                              }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    const getIcon = () => {
        switch (service) {
            case ServiceName.ARTICLE_DELIVERY:
                return FastfoodIcon;
            case ServiceName.MONEY_DELIVERY:
                return AttachMoneyIcon;
            case ServiceName.TOPUP:
                return SendToMobileIcon;
        }
    }
    return (
        <div className={clsx(classes.show_comment_container, className)}>
            <Tag tagType={TagType.PRIMARY} text={i18n.translate(service)} startIcon={getIcon()}/>
        </div>
    );

    // return (
    //     <div className={clsx(classes.show_comment_container, className)}>
    //         {showIcon && service === ServiceName.TOPUP && <SendToMobileIcon className={classes.icon}/>}
    //         {showIcon && service === ServiceName.ARTICLE_DELIVERY && <FastfoodIcon className={classes.icon}/>}
    //         {showIcon && service === ServiceName.MONEY_DELIVERY && <AttachMoneyIcon className={classes.icon}/>}
    //         <div className={classes.value_container}>{i18n.translate(service)}</div>
    //     </div>
    // )
}


export default ShowServiceName;