import React, {useEffect, useState} from 'react';
import SuggestedItemSelectProperties from "./SuggestedItemSelectProperties";
import SelectOption from "../../../ui_components/Select/SelectOption";
import {useI18nContext} from "../../../contexts/I18nContext";
import AutocompleteItem from "../../../ui_components/Autocomplete/Autocomplete";

const SuggestedItemSelect: React.FC<SuggestedItemSelectProperties> = ({
                                                                          required = false,
                                                                          inventoryItems,
                                                                          selectedInventoryItem,
                                                                          onInventoryItemChange,
                                                                          itemName,
                                                                          onItemNameChange,
                                                                          className = '',
                                                                          disabled,
                                                                          fullWidth = false
}) => {
    const i18n = useI18nContext();

    const [options,setOptions] = useState<SelectOption[]>([])
   /*  const [selectedOption,setSelectedOption]= useState<SelectOption>() */

    useEffect(()=>{
        const opts = inventoryItems.map(p =>
            new SelectOption(
                p.name,
                p.id
            )
        )
        setOptions(opts)
    }, [inventoryItems])

    useEffect(()=>{
        if(selectedInventoryItem){
            //const selectedOpt = options.find(t => t.value===selectedInventoryItem.id )
            //setSelectedOption(selectedOpt)
        }else{
            //setSelectedOption(undefined)
        }
    },[selectedInventoryItem,options])

    return (
        <AutocompleteItem
            fullWidth={fullWidth}
            className={className}
            label={i18n.translate("INVENTORY_ITEM_NAME") + `${required ? '*' : ''}`}
            elements={options}
            itemName={itemName}
            onChangeInput={onItemNameChange}
            onChangeValue={onInventoryItemChange}  />
    )
}

export default SuggestedItemSelect;