import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    date_container: {},
    normal: {
        color: theme.palette.grey["500"],
    },
    warning: {
        color: theme.palette.warning.light
    },
    date: {
        verticalAlign: 'middle',
        fontSize: '15px',
        color: theme.palette.grey["600"],
        '& svg': {
            verticalAlign: 'middle',
            color: theme.palette.grey["600"],
            fontSize: 18
        }
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    }
}));

export {useStyles}