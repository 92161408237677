import React from "react";
import AddBalanceButtonProperties from "./AddBalanceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import AddBalanceStore from "./AddBalanceStore";
import PaidIcon from "@mui/icons-material/Paid";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AddBalanceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Currency from "../../../entities/Currency";
import CurrencySelect from "../../Selects/CurrencySelect/CurrencySelect";
import CommentInput from "../../Inputs/CommentInput/CommentInput";
import Alert from "../../../ui_components/Alert/Alert";
import AlertType from "../../../ui_components/Alert/AlertType";
import PriceInput from "../../Inputs/PriceInput/PricetInput";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import Tag from "../../../ui_components/Tag/Tag";

const AddBalanceButton: React.FC<AddBalanceButtonProperties> = ({
                                                                    onClick = () => {
                                                                    },
                                                                    onSave = () => {
                                                                    },
                                                                    showIcon = true,
                                                                    showText = true,
                                                                    disabled,
                                                                    className = "",
                                                                    entityId,
                                                                    entityName,
                                                                    rounded = false,
                                                                    size = ButtonSize.DEFAULT,
                                                                    type = ButtonType.DEFAULT,
                                                                }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const userStore = useUserContext();
    const commonContext = useCommonContext();
    const store = new AddBalanceStore(commonContext, entityId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    };

    const onCloseModal = () => {
        store.error = undefined;
        store.success = undefined;
        store.currencyError = undefined;
        store.amountError = undefined;
        store.closeModal();
        if (onSave) {
            onSave();
        }
    };

    const onCurrencyChange = (newCurrency: Currency) =>
        (store.currency = newCurrency);
    const onAmountChange = (newAmount: number) => (store.amount = newAmount);
    const onCommentChange = (newComment: string) => (store.comment = newComment);

    const onBalanceSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.cleanModal();
            store.error = undefined;
            store.currencyError = undefined;
            store.amountError = undefined;
            store.success = "OK";
        } else {
            store.success = undefined;
            if (!store.amount || !store.currency) {
                store.error = undefined;
            } else {
                store.error = "INTERNAL_ERROR";
            }
        }
    };

    if (!userStore.hasAccess(Access.ADD_BALANCE_TO_DELIVERY_PERSON.valueOf())) {
        return <></>;
    }

    const basicText = i18n.translate("ADD_OR_REMOVE_BALANCE");
    const text = entityName ? entityName : basicText;
    const Icon = PaidIcon;
    const Btn = () => {
        if (rounded) {
            return (
                <RoundedButton
                    onClick={handleOnclick}
                    className={className}
                    disabled={disabled}
                    tooltip={!showText ? basicText : ""}
                    text={showText ? basicText : ""}
                    type={type}
                    icon={showIcon ? <Icon/> : <></>}
                />
            );
        }
        return (
            <Button
                text={showText ? basicText : ""}
                className={className}
                tooltip={!showText ? basicText : ""}
                disabled={disabled}
                size={size}
                onClick={handleOnclick}
                type={type}
                startIcon={showIcon ? <Icon/> : <></>}
            />
        );
    };
    return (
        <Observer>
            {() => (
                <>
                    <Modal
                        fullScreen={false}
                        loading={store.loading}
                        title={text}
                        onClose={onCloseModal}
                        open={store.isModalOpen}
                    >
                        <div className={classes.modal_container}>
                            <div className={classes.hint}>
                                <Tag startIcon={GppMaybeIcon} text={`Para restar saldo al repartidor, basta colocar un valor negativo en el campo Saldo.`}/>
                            </div>
                            <PriceInput
                                fullWidth
                                label={i18n.translate("BALANCE")}
                                className={classes.input}
                                value={store.amount}
                                error={i18n.translate(store.amountError)}
                                onChange={onAmountChange}
                            />
                            <CurrencySelect
                                fullWidth
                                selected={store.currency}
                                error={i18n.translate(store.currencyError)}
                                className={classes.input}
                                onChange={onCurrencyChange}
                            />
                            <CommentInput
                                lines={3}
                                onChange={onCommentChange}
                                fullWidth
                                value={store.comment}
                                className={classes.input}
                            />
                            {store.error && (
                                <Alert
                                    className={classes.alert}
                                    text={`${i18n.translate("ERROR")}`}
                                    type={AlertType.DANGER}
                                />
                            )}
                            {store.success && (
                                <Alert
                                    className={classes.alert}
                                    text={`${i18n.translate("SUCCESS")}`}
                                    type={AlertType.SUCCESS}
                                />
                            )}
                            <Button
                                loading={store.loading}
                                className={classes.input}
                                onClick={onBalanceSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}
                            />
                        </div>
                    </Modal>
                    <Btn/>
                </>
            )}
        </Observer>
    );
};

export default AddBalanceButton;
