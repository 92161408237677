import React, { useState } from 'react';
import {useI18nContext} from "../../../../../contexts/I18nContext";
import {Observer} from "mobx-react-lite";
import Button from "../../../../../ui_components/Button/Button";
import ButtonType from "../../../../../ui_components/Button/ButtonType";
import PriceInput from '../../../../Inputs/PriceInput/PricetInput';
import CommentInput from '../../../../Inputs/CommentInput/CommentInput';
import AdditionalPricesProperties from '../AdditionalPrices/AdditionalPricesProperties';
import { useStyles } from './AddAdditionalPrice.style';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Currency from '../../../../../entities/Currency';

const AddAdditionalPrices: React.FC<AdditionalPricesProperties> = ({
                                                                    store
                                                                }) => {
    const classes = useStyles();

    const i18n = useI18nContext();

    const [currency] = useState(Currency.DOLLAR);
    const [amount, setAmount] = useState(0);
    const [comment, setComment] = useState("");

    const addAdditionalPrice = async () => {
        await store.addAdditionalPrice(currency, amount, comment);
    }

    return <Observer>
        {() => (
            <>
                <div className={classes.add_additional_price_container}>
                    <div className={classes.form_container}>
                        <PriceInput fullWidth
                            onChange={(c) => setAmount(c)}/>
                        <CommentInput onChange={(c) => setComment(c)} fullWidth/>
                        <Button
                            startIcon={<ArrowDownwardIcon/>}
                            disabled={(!amount)}
                            loading={store.validating} 
                            className={classes.save_btn}
                            onClick={addAdditionalPrice}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("ADD")}/>
                    </div>
                </div>
            </>
        )}
    </Observer>
}

export default AddAdditionalPrices;
