import React from "react";
import PaymentsTableProperties from "./PaymentsTableProperties";
import {useStyles} from "./PaymentsTable.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import {CurrencyModule} from "../../../util/Currency";
import Table from "../../../ui_components/Table/Table";
import TableType from "../../../ui_components/Table/TableType";

const PaymentsTable: React.FC<PaymentsTableProperties> = ({
                                                              payments,
                                                              totalLabel = null
                                                          }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const tableHeader = [
        i18n.translate("PAYMENT_TYPE"),
        i18n.translate("AMOUNT"),
        i18n.translate("FEE"),
    ]

    const tableBody = payments.map(p => [
        i18n.translate(`PAYMENT_TYPE_${p.paymentTypeResponse.paymentType}`),
        CurrencyModule.format(p.amount, p.currency),
        CurrencyModule.format(p.getExtraFee, p.currency)]);
    const total = payments.reduce((partialSum, a) => partialSum + a.amount, 0);
    const totalFees = payments.reduce((partialSum, a) => partialSum + a.getExtraFee, 0);
    tableBody.push([
        i18n.translate("TOTAL"),
        CurrencyModule.format(total, payments[0].currency),
        CurrencyModule.format(totalFees, payments[0].currency)])
    tableBody.push([totalLabel ?? i18n.translate("TOTAL_TO_PAY"), CurrencyModule.format(total + totalFees, payments[0].currency)])

    return (
        <div className={classes.container}>
            <div className={classes.info_container}>
                <Table title={i18n.translate("PAYMENT")} tableType={TableType.SECONDARY} body={tableBody}
                       header={tableHeader}/>
            </div>
        </div>
    );
};

export default PaymentsTable;
