import React from 'react';
import {useStyles} from "./ShowPayment.style";
import ShowPaymentProperties from "./ShowPaymentProperties";
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";
import PaymentType from "../../../entities/PaymentType";
import CashPaymentIcon from "../../../ui_components/Icon/icons/CashPaymentIcon";
import ZelleIcon from "../../../ui_components/Icon/icons/ZelleIcon";
import WireTransferIcon from "../../../ui_components/Icon/icons/WireTransferIcon";
import CreditCardSharpIcon from "@mui/icons-material/CreditCardSharp";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckPaymentIcon from "../../../ui_components/Icon/icons/CheckPaymentIcon";
import { CurrencyModule } from '../../../util/Currency';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';

const ShowPayment: React.FC<ShowPaymentProperties> = ({
                                                          className = '',
                                                          amount,
                                                          extraFee = null,
                                                          paymentType,
                                                          light = false
                                                      }) => {
    const classes = useStyles(light);
    const i18n = useI18nContext();

    const getIcon = () => {
        switch (paymentType) {
            case PaymentType.CASH:
                return <CashPaymentIcon/>;
            case PaymentType.ZELLE:
                return <ZelleIcon/>;
            case PaymentType.ZELLE_LOWER:
                return <ZelleIcon/>;
            case PaymentType.ZELLE_EXTRA:
                return <ZelleIcon/>;
            case PaymentType.WIRE_TRANSFER:
                return <WireTransferIcon/>;
            case PaymentType.DEBIT:
                return <CreditCardSharpIcon/>;
            case PaymentType.CREDIT:
                return <CreditCardIcon/>;
            case PaymentType.CHECK:
                return <CheckPaymentIcon/>;
            case PaymentType.DISTANCE:
                return <PermPhoneMsgIcon/>;
            case PaymentType.DISTANCE_LOWER:
                return <PermPhoneMsgIcon/>;
        }
    }

    return (
        <div className={clsx(classes.payment_type_info_container, className)}>
            <div className={clsx(classes.icon)}>{getIcon()}</div>
            <div className={classes.info}>
                <div>{CurrencyModule.format(amount)}</div>
                <div >{i18n.translate(`PAYMENT_TYPE_${paymentType}`)}</div>
                {!!extraFee && <div className={classes.fee}>{`+ ${CurrencyModule.format(extraFee)}`}</div>}
            </div>
        </div>
    )
}
export default ShowPayment;