import User from "../entities/User";
import Token from "../model/Token";
import {AuthenticateService} from "../service/AuthenticateService";
import {action, makeAutoObservable} from "mobx";
import {PasswordService} from "../service/PasswordService";

class UserStore {

    private readonly user?: User;
    public error?: string;
    public loading?: boolean;

    constructor() {
        const token = new Token();
        this.user = token.parse();
        this.loading = false;
        makeAutoObservable(this);
    }

    @action
    public async login(email: string, password: string) {
        this.error = '';
        this.loading = true;
        const response = await AuthenticateService.login(email, password);
        if (response.success) {
            window.location.href = '/home';
        }
        this.error = response.error;
        this.loading = false;
    }

    @action
    public async recoverPassword(email: string) {
        this.error = '';
        this.loading = true;
        const response = await PasswordService.recover(email);
        if (response.success) {
            window.location.href = '/login?label=PASSWORD_RECOVERY_CONFIRMATION';
        }
        this.error = response.error;
        this.loading = false;
    }

    public isAuthenticated(): boolean {
        return !!this.user;
    }

    public currentUser(): User | undefined {
        return this.user;
    }

    public logout = () => {
        const token = new Token();
        token.remove();
        window.location.href = '/login';
    }

    public hasAccess = (value: number): boolean => {
        const rolValue = BigInt(this.user?.roles || 0);
        const bigValue = BigInt(value);
        return (rolValue & bigValue) === bigValue;
    }

    public isRootAgency(): boolean {
        return this.user?.isRootAgency || false;
    }
}

export default UserStore;