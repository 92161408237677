import React from "react";
import CustomCategoryRowProperties from "./CustomCategoryRowProperties";
import {useStyles} from "../CustomCategory.style";
import clsx from "clsx";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import RemoveCustomCategoryButton
    from "../../../shared_components/Buttons/RemoveCustomCategoryButton/RemoveCustomCategoryButton";


const CustomCategoryRowSmall:React.FC<CustomCategoryRowProperties> = ({
    customCategory,
    onUpdate,
}) => {
    const classes = useStyles()

    return (
        <div className={clsx(classes.custom_category_info_container_small)}>
            <div className={classes.menu_container_small}>
                <RemoveCustomCategoryButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    customCategory={customCategory}
                    showText={false}
                    type={ButtonType.SECONDARY}/>
            </div>
        </div>
    )
}
export default CustomCategoryRowSmall