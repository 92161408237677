import React, {useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import CreditCardNumberProperties from "./CreditCardNumberProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import CreditCardIcon from '@mui/icons-material/CreditCard';

const CreditCardNumber: React.FC<CreditCardNumberProperties> = ({
                                                        value,
                                                        onChange,
                                                        disabled,
                                                        error,
                                                        fullWidth = false,
                                                        className = ''
                                                    }) => {

    const [cardNumber, setCreditCardNumber] = useState(value);
    const i18n = useI18nContext();

    const onCreditCardNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newCreditCardNumber = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        setCreditCardNumber(newCreditCardNumber);
        onChange(newCreditCardNumber);
    }

    const Icon = CreditCardIcon;

    return (
        <TextInput
            label={i18n.translate("CREDIT_CARD_NUMBER")}
            error={error}
            value={cardNumber}
            disabled={disabled}
            onChange={onCreditCardNumberChange}
            startIcon={Icon}
            className={className}
            fullWidth={fullWidth}
            maxLength={19}
        />
    )
}


export default CreditCardNumber;