import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    breadcrumb: {
        height: '60px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.grey["100"],
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        borderLeft: `1px solid ${theme.palette.grey["400"]}`
    },
    page_info_container: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.light,
        "& svg": {
            fontSize: "40px"
        },
        "& div": {
            fontSize: "20px",
            marginLeft: '8px',
            selfAlign: 'baseline'
        }
    },
}));

export {useStyles}