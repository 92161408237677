
import ViewLarge from "../../../../ui_components/MediaView/ViewLarge";
import ViewMedium from "../../../../ui_components/MediaView/ViewMedium";
import ViewSmall from "../../../../ui_components/MediaView/ViewSmall";
import ReferenceItemPriceSelectRowMedium from "./ReferenceItemPriceSelectRowMedium";
import ReferenceItemPriceSelectRowProperties from "./ReferenceItemPriceSelectRowProperties";
import ReferenceItemPriceSelectRowSmall from "./ReferenceItemPriceSelectRowSmall";

const ReferenceItemPriceSelectRow: React.FC<ReferenceItemPriceSelectRowProperties> = ({
  referenceItemPriceResponse,
  className,
  filter,
}) => {
  return (
    <>
      <ViewSmall>
        <ReferenceItemPriceSelectRowSmall
          referenceItemPriceResponse={referenceItemPriceResponse}
          className={className}
          filter={filter}
        />
      </ViewSmall>
      <ViewMedium>
        <ReferenceItemPriceSelectRowMedium
          referenceItemPriceResponse={referenceItemPriceResponse}
          className={className}
          filter={filter}
        />
      </ViewMedium>
      <ViewLarge>
        <ReferenceItemPriceSelectRowMedium
          referenceItemPriceResponse={referenceItemPriceResponse}
          className={className}
          filter={filter}
        />
      </ViewLarge>
    </>
  );
};
export default ReferenceItemPriceSelectRow;
