import ContactBankCardType from "../entities/ContactBankCardType";

export module ContactBankCardUtils {
    export function getBankFromCardNumber(cardNumber: string): ContactBankCardType | undefined {
        if (cardNumber.length < 6) {
            return undefined;
        }
        const spl = cardNumber.split(' ').join('').substring(4, 6);
        if(spl === '12') {
            return ContactBankCardType.BPA;
        } else if (spl === '95') {
            return ContactBankCardType.METROPOLITANO;
        } else if (spl === '06') {
            return ContactBankCardType.BANDEC;
        }
        return undefined;
    }
}