import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    columnData :{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginRight: '0',
        marginTop:'0',
        marginBottom:'auto'
    },
    columnName : {
        height:'100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginLeft: 'auto',
        marginRight: '10px',
        marginTop:'0',
        marginBottom:'auto'
    },
    column : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    name : {
        fontWeight: 'bold',
        marginRight: '10px', // Adjust the margin as needed
    },

    data : {
        marginLeft: '10px', // Adjust the margin as needed
        alignItems:'flex-end',
        textOverflow:'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },

    hr : {
        marginTop: '10px', // Adjust the margin as needed
    },

    tableRows : {
        padding:'0px',
    },
    columnOne: {
        width: '5%',
    },
    columnTwo: {
        width: '85%',
    },
    columnThree: {
        width: '10%',
    },
    table_details: {
        marginTop: '10px',
        width:'100%',
        maxWidth:'100%',
        borderWidth:'1px',
        backgroundColor:'grey'
    },
    modal_container_deliver: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0,
        "@media print": {
            zoom: '80%',
            width: '100%',
            height: "100%"
        }
    },
    view_invoice_container_deliver: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },



    resume_container : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        alignSelf:'end',
        alignContent:'end'
    },
    resume : {
        width: '100%',
        height: '100%',
        alignItems: 'end',
        alignContent:'end'
    },
    header_text : {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        alignContent:'center'
    },
    header2_text : {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop:'10px',
        alignItems: 'center',
        textDecoration: "underline",
    },
        invoice_container_deliver: {
            display: 'flex',
            flexDirection: 'column',
            width: '800px',
            marginTop: '16px',
            height: 'calc(100vh - 185px)',
            overflow: 'auto',
            overflowX: 'hidden',
            backgroundColor: theme.palette.common.white,
            borderRadius: '4px',
            padding: '16px',
            border: `1px solid ${theme.palette.grey[200]}`,
            "@media print": {
                width: '880px',
                boxShadow: 'none',
                padding: '0',
                border: `none`,
                height: "100%",
                overflow: 'hidden',
                overflowX: 'hidden',
                margin: '0cm'
            }
        },
        toolbar_container_deliver: {
            display: "flex",
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.common.white,
            padding: '16px',
            "@media print": {
                display: 'none'
            },
            "& > *:not(:last-child)": {
                marginRight: '16px'
            }
        },
        invoice_data_container_deliver: {
            border: `1px solid ${theme.palette.grey[900]}`,
            display: 'flex',
            padding: '8px 0',
            flexDirection: 'column',
            borderRadius: '8px',
            position: 'relative',
        },
        column_deliver: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            // maxWidth: '50%',
            "& svg": {
                maxWidth: '300px',
                maxHeight: '100px'
            }
        },
        column_container_deliver: {
            display: 'grid',
            gridTemplateColumns: '250px auto 150px',
            "@media print": {
                gridTemplateColumns: '280px 400px 150px',
            }
        },
        row_deliver: {
            display: 'flex',
            flexDirection: 'row',
        },
    top_header: {
        width:'100%',
        display: 'flex',
        flexDirection: 'row'
    },
        body_deliver: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 8px'
        },
        subtitle_deliver: {
            fontSize: "15px",
            fontWeight: 800
        },
        sign_container_deliver: {
            width: '100%',
            height: '1px',
            borderTop: `2px solid ${theme.palette.grey[800]}`,
            marginTop: '20px'
        },
        order_item_container_deliver: {
            marginTop: '10px',
            borderTop: '2px solid #4a4a4a',
            paddingTop: '10px'
        },
        margin_top_4_deliver: {
            marginTop: '4px'
        },
        margin_top_8_deliver: {
            marginTop: '8px'
        },
    margin_top_20_deliver: {
        marginTop: '20px'
    },

        width_90_deliver: {
            width: '90%'
        },

    }))
;

export {useStyles}