import React from "react";
import UpdateSellerPasswordButtonProperties from "./UpdateSellerPasswordButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import UpdateSellerPasswordButtonStore from "./UpdateSellerPasswordButtonStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./UpdateSellerPasswordButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import PasswordIcon from '@mui/icons-material/Password';
import PasswordAndConfirmPasswordInput
    from "../../Inputs/PasswordAndConfirmPasswordInput/PasswordAndConfirmPasswordInput";
import Alert from "../../../ui_components/Alert/Alert";
import AlertType from "../../../ui_components/Alert/AlertType";

const UpdateSellerPasswordButton: React.FC<UpdateSellerPasswordButtonProperties> = ({
                                                                                                        onClick = () => {
                                                                                                        },
                                                                                                        onSave = () => {
                                                                                                        },
                                                                                                        showIcon = true,
                                                                                                        showText = true,
                                                                                                        disabled,
                                                                                                        className = "",
                                                                                                        sellerId,
                                                                                                        sellerName,
                                                                                                        rounded = false,
                                                                                                        size = ButtonSize.DEFAULT,
                                                                                                        type = ButtonType.DEFAULT,
                                                                                                    }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const userStore = useUserContext();
    const commonContext = useCommonContext();
    const store = new UpdateSellerPasswordButtonStore(commonContext, sellerId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    };

    const onCloseModal = () => {
        store.closeModal();
        if (onSave) {
            onSave();
        }
    };

    const onPasswordSave = async () => {
        const success = await store.save();
        if (success) {
            store.closeModal();
        }
    }

    if (!userStore.hasAccess(Access.UPDATE_USER_PASSWORD.valueOf())) {
        return <></>;
    }

    const basicText = i18n.translate("UPDATE_USER_PASSWORD");
    const Icon = PasswordIcon;

    const onPasswordChange = (password: string, isValid: boolean) => {
        store.isValid = isValid;
        store.password = password;
    }
    const Btn = () => {
        if (rounded) {
            return (
                <RoundedButton
                    onClick={handleOnclick}
                    className={className}
                    disabled={disabled}
                    tooltip={!showText ? basicText : ""}
                    text={showText ? basicText : ""}
                    type={type}
                    icon={showIcon ? <Icon/> : <></>}
                />
            );
        }
        return (
            <Button
                text={showText ? basicText : ""}
                className={className}
                tooltip={!showText ? basicText : ""}
                disabled={disabled}
                size={size}
                onClick={handleOnclick}
                type={type}
                startIcon={showIcon ? <Icon/> : <></>}
            />
        );
    };
    return (
        <Observer>
            {() => (
                <>
                    <Modal
                        fullScreen={false}
                        loading={store.loading}
                        title={basicText}
                        subtitle={sellerName}
                        onClose={onCloseModal}
                        open={store.isModalOpen}
                    >
                        <div className={classes.modal_container}>
                            {store.error && <Alert text={store.error} type={AlertType.DANGER}/>}
                            <PasswordAndConfirmPasswordInput onChange={onPasswordChange} value={store.password}/>
                            <Button
                                loading={store.loading}
                                disabled={!store.isValid}
                                className={classes.input}
                                onClick={onPasswordSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}
                            />
                        </div>
                    </Modal>
                    <Btn/>
                </>
            )}
        </Observer>
    );
};

export default UpdateSellerPasswordButton;
