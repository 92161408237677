import React, {useState} from 'react';
import EmailInput from "../../shared_components/Inputs/EmailInput/EmailInput";
import Button from "../../ui_components/Button/Button";
import ButtonType from "../../ui_components/Button/ButtonType";
import {useStyles} from "./PasswordRecoveryPage.style";
import Direction from "../../ui_components/common/enums/Direction";
import FlexContainer from "../../ui_components/FlexContainer/FlexContainer";
import {useI18nContext} from "../../contexts/I18nContext";
import {useUserContext} from "../../contexts/UserContext";
import TopupAgenciesText from "../../ui_components/Icon/icons/TopupAgenciesText";
import {Observer} from "mobx-react-lite";
import Link from "../../ui_components/Link/Link";
import LoginIcon from '@mui/icons-material/Login';
import {useCommonStyles} from "../../ui_components/common/CommonStyles.style";


const PasswordRecoveryPage: React.FC = () => {

    const [email, setEmail] = useState("");
    const i18n = useI18nContext();
    const userStore = useUserContext();
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    if (userStore.isAuthenticated()) {
        window.location.href = "/home";
    }

    const onclick = async () => {
        await userStore.recoverPassword(email);
    }

    return (
        <Observer>
            {() => (
                <FlexContainer direction={Direction.COLUMN} fullHeight
                               className={classes.password_recover_page_container}>
                    <div className={classes.form_container}>
                        <div className={classes.header}>
                            <TopupAgenciesText fontSize={120}/>
                        </div>
                        <div className={classes.body}>
                            <p className={classes.description_container}>{i18n.translate("PASSWORD_RECOVERY_MESSAGE")}</p>
                            <EmailInput value={email} onChange={setEmail}/>
                            <Button
                                loading={userStore.loading}
                                className={classes.recover_btn}
                                fullWidth
                                type={ButtonType.SECONDARY}
                                onClick={onclick}
                                text={i18n.translate("RECOVER")}
                            />
                            <Link startIcon={LoginIcon} className={commonClasses.marginTop}
                                  text={i18n.translate("GO_TO_LOGIN")} to={'/login'}/>
                        </div>
                    </div>
                </FlexContainer>
            )}
        </Observer>
    )
}

export default PasswordRecoveryPage;
