import SearchContactClientElementResponse from "./SearchContactClientElementResponse";

class SearchContactClientResponse {
    public elements: SearchContactClientElementResponse[];
    public totalElements: number;
    public allElementsIncluded: boolean;

    constructor(elements: SearchContactClientElementResponse[],
                totalElements: number,
                allElementsIncluded: boolean) {
        this.elements = elements;
        this.totalElements = totalElements;
        this.allElementsIncluded = allElementsIncluded;
    }
}

export default SearchContactClientResponse;