import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import ShopProduct from "../../entities/productOrder/shop/ShopProduct";

export module ShopProductService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string): Promise<Response<PagedResponse<ShopProduct>>> {
        const url = Config.get("GET_SHOP_PRODUCT_URL", {page, pageSize, filter});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const articleData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (articleData && paginationData) {
                const data = articleData.map((t: any) => new ShopProduct(
                    t.id,
                    t.name,
                    t.description,
                    t.defaultImage,
                    t.price,
                    t.stockAmount,
                    t.minStockWarning,
                    t.status,
                    t.createdAt
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ShopProduct>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ShopProduct>>(false, undefined, response.error, response.status);
    }

    export async function getAll(): Promise<Response<ShopProduct[]>> {
        const url = Config.get("GET_SHOP_PRODUCT_URL", {page: 1, pageSize: 1000, filter: ''});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const moneyServiceFeeData = response.data?.data;
            if (moneyServiceFeeData) {
                const data = moneyServiceFeeData.map((t: any) => new ShopProduct(
                    t.id,
                    t.name,
                    t.description,
                    t.defaultImage,
                    t.price,
                    t.stockAmount,
                    t.minStockWarning,
                    t.status,
                    t.createdAt
                ));
                return new Response<ShopProduct[]>(true, data, response.error)
            }
        }
        return new Response<ShopProduct[]>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<ShopProduct>> {
        const url = Config.get("FIND_SHOP_PRODUCT_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const client = new ShopProduct(
                    t.id,
                    t.name,
                    t.description,
                    t.defaultImage,
                    t.price,
                    t.stockAmount,
                    t.minStockWarning,
                    t.status,
                    t.createdAt
                );
                return new Response<ShopProduct>(true, client, response.error);
            }
        }
        return new Response<ShopProduct>(true, undefined, response.error, response.status);
    }

    export async function create(client: ShopProduct): Promise<Response<any>> {
        const url = Config.get("CREATE_SHOP_PRODUCT_URL");
        const request = new Request(url, client);
        return await RestClient.post(request);
    }

    export async function update(client: ShopProduct): Promise<Response<any>> {
        const url = Config.get("UPDATE_SHOP_PRODUCT_URL", {id: client.id});
        const request = new Request(url, client);
        return await RestClient.put(request);
    }

    export async function remove(client: ShopProduct): Promise<Response<any>> {
        const url = Config.get("REMOVE_SHOP_PRODUCT_URL", {id: client.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function uploadImage(articleId: string, file: File): Promise<void> {
        const reader = new FileReader();
        reader.onloadend = async () => {
            const url = Config.get("UPLOAD_SHOP_PRODUCT_IMAGE_URL", {id: articleId});
            const request = new Request(url, { base64File: reader.result });
            await RestClient.post(request);
        };
        reader.readAsDataURL(file);
    }

}