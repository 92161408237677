import React from 'react';
import {useStyles} from "./ShowArticleDescription.style";
import ShowArticleDescriptionProperties from "./ShowArticleDescriptionProperties";
import clsx from "clsx";
import { useI18nContext } from '../../../contexts/I18nContext';
import Link from '../../../ui_components/Link/Link';

const ShowArticleDescription: React.FC<ShowArticleDescriptionProperties> = ({
                                                                          className = '',
                                                                          description = ''
                                                                      }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const [linesToShow, setlinesToShow] = React.useState(3);

    const textLines = description ? description.split('\n') : [];

    const handleToggleExpand = () => {
        if (linesToShow === 3) {
            setlinesToShow(textLines.length);
        } else {
            setlinesToShow(3);
        }
    }

    return (
        <div className={clsx(classes.seller_info_container, className)}>
            <div className={classes.info_container}>
                    {textLines.slice(0, linesToShow).map(t => <div>{t}</div>)}
                    {textLines.length > 3 && linesToShow !== textLines.length && <div><Link className={classes.link} text={i18n.translate("SEE_MORE")} onClick={handleToggleExpand}></Link></div>}
                    {textLines.length > 3 && linesToShow === textLines.length && <div><Link className={classes.link} text={i18n.translate("SEE_LESS")} onClick={handleToggleExpand}></Link></div>}
            </div>
        </div>
    )
}
export default ShowArticleDescription;