class AddArticleDeliveryStockRequest {
    public to: string;
    public amount: number;
    public articleId: string;
    public comment?: string;

    constructor(to: string, amount: number, articleId: string, comment?: string) {
        this.to = to;
        this.comment = comment;
        this.amount = amount;
        this.articleId = articleId;
    }
}

export default AddArticleDeliveryStockRequest;