import clsx from "clsx";

import { useStyles } from "../ReferenceItemPriceSelect.style";
import ShowText from "../../../Info/ShowText/ShowText";
import { CurrencyModule } from "../../../../util/Currency";
import ReferenceItemPriceSelectRowProperties from "./ReferenceItemPriceSelectRowProperties";
import {Tooltip} from '@material-ui/core';

const ReferenceItemPriceSelectRowMedium: React.FC<ReferenceItemPriceSelectRowProperties> = ({ 
                                                                                              referenceItemPriceResponse, 
                                                                                              className, 
                                                                                              filter, 
}) => {
  const classes = useStyles();

  const getText = () =>{
    if(referenceItemPriceResponse.referenceItemDescription==="")
    return "Sin descripcion"
    return referenceItemPriceResponse.referenceItemDescription
  }

  return (
    <div className={clsx(classes.reference_items_info_container)}>
      <ShowText text={referenceItemPriceResponse.referenceItemName} textToHighlight={filter}/>
      <Tooltip title={<h3>{getText()}</h3>}>
        <div key={referenceItemPriceResponse.referenceItemId}> 
          {"..."}
        </div>
      </Tooltip>
      <ShowText text={CurrencyModule.format(referenceItemPriceResponse.amount || 0)}/>
    </div>
  );
};
export default ReferenceItemPriceSelectRowMedium;
