import ReferenceItemSalePrice from "../model/packageDelivery/referenceItemSalePrice/ReferenceItemSalePrice";
import EntityStatus from "./EntityStatus";
import ArticleSalePrice from "./ArticleSalePrice";
import MoneySalePrice from "./productOrder/moneyDelivery/MoneySalePrice";
import TopupSalePrice from "./productOrder/topup/TopupSalePrice";
import MiscellanySalePrice from "./MiscellanySalePrice";

class SalePrice {
    public id: string;
    public name: string;
    public description: string;
    public articleSalePrices: ArticleSalePrice[];
    public moneySalePrices: MoneySalePrice[];
    public topupSalePrices: TopupSalePrice[];
    public packageReferenceItemSalePrices: ReferenceItemSalePrice[]
    public miscellanySalePrices: MiscellanySalePrice[]
    public status: EntityStatus;

    constructor(
        id: string,
        name: string,
        description: string,
        articleSalePrices: ArticleSalePrice[],
        moneySalePrices: MoneySalePrice[],
        topupSalePrices: TopupSalePrice[],
        packageReferenceItemSalePrices: ReferenceItemSalePrice[],
        miscellanySalePrices: MiscellanySalePrice[],
        status: EntityStatus
    ) {
        this.name = name;
        this.description = description;
        this.articleSalePrices = articleSalePrices;
        this.moneySalePrices = moneySalePrices;
        this.topupSalePrices = topupSalePrices;
        this.packageReferenceItemSalePrices = packageReferenceItemSalePrices;
        this.miscellanySalePrices = miscellanySalePrices;
        this.id = id;
        this.status = status;
    }

}

export default SalePrice;