import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    '@global': {
        '@page': {
            size: 'auto',
            height: '90vh',
            margin: 0,
            marginTop: 'top-right-corner', // Use 'none' to remove header/footer
            marginBottom: '20mm', // Adjust as needed
        },
        '@page :first': {
            marginTop: 0,
            // Add any additional styles for the first page
        },
    },

    agencyInfo:{
        width:'40%'
    },

    additionalText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '40px', // Optional: Add spacing above the line
        marginBottom: '-15px', // Optional: Add spacing below the line
    },

    phoneText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    spacedText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    line: {
        borderTop: '1px solid #ddd', // Use a lighter color (e.g., #ccc for light gray)
        width: '50%',
        marginTop: '1px', // Optional: Add spacing above the line
        marginBottom: '4px', // Optional: Add spacing below the line
    },

    margin_left: {
        marginLeft: '40px',
    },


    horizontal_line: {
        borderTop: '1px solid #ddd', // Use a lighter color (e.g., #ccc for light gray)
        width: '100%',
        marginTop: '1px', // Optional: Add spacing above the line
        marginBottom: '4px', // Optional: Add spacing below the line
    },
    page : {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    horizontal : {
        display: 'flex',
        justifyContent: 'center',
    },
    export_container : {
        width: '612',
        height: '792',
        border: 'none',
        padding:'none',
        backgroundColor: 'white',
        boxShadow: '5px 5px 5px black',
        margin: 'auto',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    columnData :{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginRight: '0',
        marginTop:'0',
        marginBottom:'auto'
    },
    columnName : {
        height:'100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginLeft: 'auto',
        marginRight: '0px',
        marginTop:'0',
        marginBottom:'auto'
    },
    column : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    name : {
        fontWeight: 'bold',
        marginRight: '0px', // Adjust the margin as needed
    },
    data : {
        marginLeft: '0px', // Adjust the margin as needed
        alignItems:'flex-end',
        textOverflow:'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    hr : {
        marginTop: '10px', // Adjust the margin as needed
    },
    tableRows : {
        padding:'0px',
        borderBottom: "none"
    },
    columnOne: {
        width: '5%',
    },
    columnTwo: {
        width: '85%',
    },
    columnThree: {
        width: '10%',
    },
    table_details: {
        marginTop: '10px',
        width:'100%',
        maxWidth:'100%',
        borderWidth:'1px',
        backgroundColor:'grey'
    },
    modal_container_deliver: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0,
        "@media print": {
            width: '100%',
            height: "100%"
        }
    },
    view_invoice_container_deliver: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },
    articles_container: {
        marginTop: '10px'
    },
    articles_name: {
        fontSize: '16px',
        fontWeight: 800
    },
    resume_container : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        alignSelf:'end',
        alignContent:'end'
    },
    shop_resume_container: {

    },
    invoice_item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 0',
        borderBottom: '1px solid #ddd',
      },
      product_name: {
        flex: 2,
        fontWeight: 'bold',
      },
      product_details: {
        flex: 1,
        textAlign: 'center',
      },
      product_total: {
        flex: 1,
        textAlign: 'right',
        fontWeight: 'bold',
      },
    resume : {
        width: '100%',
        height: '100%',
        alignItems: 'end',
        alignContent:'end'
    },
    page_break: {
        pageBreakBefore: "always"
    },
    header_text : {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        alignContent:'center'
    },
    header2_text : {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop:'10px',
        alignItems: 'center',
        textDecoration: "underline",
    },

    //height: 'calc(100vh - 185px)',
    invoice_container_deliver: {
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
        marginTop: '16px',
        height: '100%',
        overflow: 'auto', // This allows content to be scrollable if it exceeds the available height
        overflowX: 'visible', // Hide horizontal scrollbar
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
        border: `1px solid ${theme.palette.grey[200]}`,
        "@media print": {
            width: '100%', // Make sure the content fills the entire width of the page
            height: '100%', // Make sure the content fills the entire height of the page
            overflowY: 'hidden', // Hide vertical scrollbar for printed version
            margin: '0', // Remove page margins
            padding: '0', // Remove padding
            border: 'none', // Remove border
            boxShadow: 'none', // Remove shadows
            overflow: 'visible', // Set overflow to visible for printed version
            overflowX: 'visible', // Set overflowX to visible for printed version
            display: 'block', // Set display to block for page-break
            pageBreakBefore: 'auto', // Reset page-break property to auto
            '&::after': {
                content: `"URL: " attr(href) "\\A" "Date: " attr(datetime) "\\A" "Time: " attr(datetime)`,
                display: 'none',
            },
        },
    },
        toolbar_container_deliver: {
            display: "flex",
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.common.white,
            padding: '16px',
            "@media print": {
                display: 'none'
            },
            "& > *:not(:last-child)": {
                marginRight: '16px'
            }
        },
        invoice_data_container_deliver: {
            display: 'flex',
            padding: '8px 0',
            flexDirection: 'column',
            position: 'relative',
        },
    package_invoice_data_container_deliver: {
        display: 'flex',
        padding: '8px 0',
        flexDirection: 'column',
        borderRadius: '8px',
        position: 'relative',
    },
    column_deliver: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // maxWidth: '50%',
        "& svg": {
            maxWidth: '300px',
            maxHeight: '100px'
        }
    },
    column_container_deliver: {
        display: 'grid',
        gridTemplateColumns: '250px auto 150px',
        "@media print": {
            gridTemplateColumns: '280px 400px 150px',
        }
    },
    row_deliver: {
        display: 'flex',
        flexDirection: 'row',
    },
    top_header: {
        width:'100%',
        display: 'flex',
        flexDirection: 'row'
    },
        body_deliver: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 8px'
        },
        subtitle_deliver: {
            fontSize: "15px",
            fontWeight: 800
        },
        sign_container_deliver: {
            width: '100%',
            height: '1px',
            borderTop: `2px solid ${theme.palette.grey[800]}`,
            marginTop: '20px'
        },
        order_item_container_deliver: {
            marginTop: '10px',
            borderTop: '2px solid #4a4a4a',
            paddingTop: '10px',
            "@media print": {
                borderTop: "none",
            },
        },
        margin_top_4_deliver: {
            marginTop: '4px'
        },
        margin_top_8_deliver: {
            marginTop: '8px'
        },
    margin_top_20_deliver: {
        marginTop: '20px'
    },

        width_90_deliver: {
            width: '90%'
        },

    }))

;

export {useStyles}
