import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.grey[300],
        flexGrow: 1,
        justifyContent: 'center',
        height: 'calc( 100vh - 250px)'
    },
    articles_resume_container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        padding: '12px',
        borderRadius: '5px',
        backgroundColor: theme.palette.common.white,
        margin: '8px',
        width: '62%',
        overflowY: 'auto'
    },
    resume_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '35%',
    },
    additional_price_resume_container: {
        height: '70%',
        backgroundColor: theme.palette.common.white,
        margin: '8px',
        overflowY: 'auto',
        padding: '12px',
    },
    resume_all_container: {
        height: '25%',
        backgroundColor: theme.palette.common.white,
        margin: '8px',
        padding: '12px',
    },
    list_container: {
        padding: '10px'
    },
    title: {
        fontSize: '17px',
        fontWeight: 800
    },
    no_data_container: {
        textAlign: 'center',
        marginTop: '-32px'
    },
    pre_resume_item_container: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        padding: '5px 0px'
    },
    text_bold: {
        fontWeight: 800
    }
}));

export {useStyles}