import React from 'react';
import SkeletonGridProperties from "./SkeletonGridProperties";
import { useStyles } from './SkeletonGrid.style';
import { Box } from '@material-ui/core';


const SkeletonGrid: React.FC<SkeletonGridProperties> = ({
                                            skeletons
                                        }: SkeletonGridProperties) => {

    const classes = useStyles();

    return (
        <Box className={classes.container_classes}>
            {skeletons.map((el) => (
                el
            ))}
        </Box>
    )
}
export default SkeletonGrid;