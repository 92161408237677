import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import Request from "../../model/Request";
import Response from "../../model/Response";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import ArticleStockResponse from "../../model/articleDelivery/ArticleStockResponse";
import AddArticleDeliveryStockRequest from "../../model/articleDelivery/AddArticleDeliveryStockRequest";


export module ArticleStockService {
    export async function get(page: number = 1, pageSize: number = 15, deliveryPersonId?: string): Promise<Response<PagedResponse<ArticleStockResponse>>> {
        const url = Config.get("GET_ARTICLE_STOCK_URL", {page, pageSize, personId: deliveryPersonId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const costData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (costData && paginationData) {
                const data = costData.map((t: any) => new ArticleStockResponse(
                    t.id,
                    t.agencyId,
                    t.articleId,
                    t.articleName,
                    t.articleDefaultImage,
                    t.entityId,
                    t.unit,
                    t.amount,
                    t.blockedAmount,
                    t.availableAmount
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ArticleStockResponse>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ArticleStockResponse>>(false, undefined, response.error, response.status);
    }

    export async function addArticleToDeliveryPersonStock(request: AddArticleDeliveryStockRequest): Promise<Response<any>> {
        const url = Config.get("ADD_ARTICLE_TO_DELIVERY_PERSON_STOCK_URL", {id: request.to});
        const requestToSend = new Request(url, request);
        return await RestClient.post(requestToSend);
    }

    // export async function find(id: string): Promise<Response<DeliveryCost>> {
    //     const url = Config.get("FIND_DELIVERY_COST_URL", { id });
    //     const request = new Request(url)
    //     const response = await RestClient.get(request);
    //     if (response.success) {
    //         const t = response.data;
    //         if (t) {
    //             const client = new DeliveryCost(
    //                 t.id,
                        // t.agencyId,
                        // t.articleId,
                        // t.entityId,
                        // t.unit,
                        // t.amount,
                        // t.blockedAmount,
                        // t.availableAmount
    //             );
    //             return new Response<DeliveryCost>(true, client, response.error);
    //         }
    //     }
    //     return new Response<DeliveryCost>(true, undefined, response.error, response.status);
    // }

    // export async function create(deliveryCost: DeliveryCost, deliveryPersonId: string): Promise<Response<any>> {
    //     const url = Config.get("CREATE_DELIVERY_COST_URL", {personId: deliveryPersonId});
    //     const {geo, ...rest} = deliveryCost;
    //     const request = new Request(url, {geoId: geo?.id, ...rest});
    //     return await RestClient.post(request);
    // }

    // export async function update(deliveryCost: DeliveryCost): Promise<Response<any>> {
    //     const url = Config.get("UPDATE_DELIVERY_COST_URL", {id: deliveryCost.id});
    //     const {geo, ...rest} = deliveryCost;
    //     const request = new Request(url, {geoId: geo?.id, ...rest});
    //     return await RestClient.put(request);
    // }

    // export async function remove(deliveryCostId: String, deliveryPersonId: string): Promise<Response<any>> {
    //     const url = Config.get("REMOVE_DELIVERY_COST_URL", {personId: deliveryPersonId, id: deliveryCostId});
    //     const request = new Request(url);
    //     return await RestClient.remove(request);
    // }

}