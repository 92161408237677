import React from 'react';
import ChooseDeliveryPersonLinksProperties from "./ChooseDeliveryPersonLinksMultiProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ChooseDeliveryPersonLinksMulti.style";
import {useCommonContext} from "../../../contexts/CommonContext";
import DeliveryPerson from '../../../entities/DeliveryPerson';
import Link from '../../../ui_components/Link/Link';
import { useUserContext } from '../../../contexts/UserContext';
import Box from '../../../ui_components/Box/Box';
import DeliveryPersonInfoRow from '../../Info/ShowDeliveryPersonMulti/DeliveryPersonInfoRow/DeliveryPersonInfoRow';
import SidePanel from '../../../ui_components/SidePanel/SidePanel';
import ChooseDeliveryPersonMultiStore from './ChooseDeliveryPersonMultiStore';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ChooseDeliveryPersonButton from '../ChooseDeliveryPersonButton/ChooseDeliveryPersonButton';
import DeliveryPersonType from '../../../entities/DeliveryPersonType';
import MessageCard from '../../../ui_components/MessageCard/MessageCard';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DeliveryPersonInfoResponse from '../../../model/packageDelivery/DeliveryPersonInfoResponse';
import ShowText from '../../Info/ShowText/ShowText';
import RoundedButton from '../../../ui_components/RoundedButton/RoundedButton';
import Button from '../../../ui_components/Button/Button';
import ButtonSize from '../../../ui_components/Button/ButtonSize';

const ChooseDeliveryPersonLinksMulti: React.FC<ChooseDeliveryPersonLinksProperties> = ({ 
                                                                                multiOrders = false,
                                                                                deliveryOrderIds, 
                                                                                deliveryOrderNumbers,
                                                                                onClick = () => {
                                                                                },
                                                                                onSelect = () => {
                                                                                },
                                                                                haveDeliveryPersonSelected,
                                                                                orderByGeosIdProximity = [], 
                                                                                onUpdate,
                                                                                className = '',
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT
                                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new ChooseDeliveryPersonMultiStore(commonContext, i18n, deliveryOrderIds);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
        if(onUpdate) onUpdate()
    }
    
    const onDeliverSelected = async (deliveryPerson?: DeliveryPerson) => {
        onSelect(deliveryPerson);
        store.selectDeliveryPerson(deliveryPerson)
    }
    const onOrganizerSelected = async (deliveryPerson?: DeliveryPerson) => {
        onSelect(deliveryPerson);
        store.selectOrganizer(deliveryPerson)
    }
    const onReceivedDelivery = async (deliveryPersonInfo?:DeliveryPersonInfoResponse) => {
        store.receivedDelivery(deliveryPersonInfo)
    }
    const onDeliveredDelivery = async (deliveryPersonInfo?:DeliveryPersonInfoResponse) => {
        store.deliveredDelivery(deliveryPersonInfo)
    }
    const onRemoveDeliveryPersonInfo = async (deliveryPersonInfo?:DeliveryPersonInfoResponse) => {
        store.removeDeliveryPersonInfo(deliveryPersonInfo)
    }
    const onCommissionSave = async (newCommission?: number, deliveryPersonInfo?:DeliveryPersonInfoResponse) => {
        store.saveNewCommission(newCommission, deliveryPersonInfo)
    } 
    
    

    const text = i18n.translate("SELECT_DELIVERY_PERSON");

    if (!userStore.isRootAgency()) {
        return <></>;
    }

    const Starter = () => {
        if(multiOrders){
            return <Btn/>
        }else{
            return <div className={classes.btn_container}>
                <div><Link text={haveDeliveryPersonSelected ? 'Cambiar' : 'Asignar'} onClick={handleOnclick}></Link></div>
                {haveDeliveryPersonSelected && <div className={classes.btn_container}></div>}
            </div>
        }
    }

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <DeliveryDiningIcon/> : <></>}
            />  
        }
         return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <DeliveryDiningIcon/> : <></>}
        />
    }
    
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.total_container}>
                        <Box className={classes.big_modal_container}>
                            <ShowText title={"Ordenes"} text={store.deliveryOrderIds?.length.toString()+" ordenes"}></ShowText>
                            <div className={classes.selected_container}>
                                {deliveryOrderNumbers?.map((deliveryOrderId, i)=> (
                                    <div className={classes.selected_order} key={i}>{deliveryOrderId}</div>
                                ))}
                            </div>
                            <div className={classes.btn_container}>
                                    <ChooseDeliveryPersonButton 
                                        deliveryPersonType={DeliveryPersonType.ORGANIZER}
                                        onSelect={onOrganizerSelected}
                                        type={ButtonType.SECONDARY}
                                        orderByGeosIdProximity={orderByGeosIdProximity}/>
                                    <ChooseDeliveryPersonButton
                                        onSelect={onDeliverSelected}
                                        type={ButtonType.SECONDARY}
                                        orderByGeosIdProximity={orderByGeosIdProximity}/>
                                    
                            </div>
                            <div className={classes.full_width}>
                                {store.deliveryPersonsInfo.map((deliveryPersonInfo,i)=>(
                                    <DeliveryPersonInfoRow 
                                        deliveryPersonsInfo={deliveryPersonInfo}
                                        onReceivedDelivery={onReceivedDelivery}
                                        onDeliveredDelivery={onDeliveredDelivery}
                                        onRemoveDeliveryPersonInfo={onRemoveDeliveryPersonInfo} 
                                        onCommissionSave={onCommissionSave}/>
                                ))
                                }
                                {store.deliveryPersonsInfo.length ===0 &&
                                    <MessageCard icon={DeliveryDiningIcon} title={i18n.translate("NO_ASSIGNED_DELIVERY_PERSON_TITLE")}
                                    subtitle={i18n.translate("NO_ASSIGNED_DELIVERY_PERSON_SUBTITLE")}/>
                                }
                            </div>
                        </Box>
                    </div>
                </SidePanel>
                <Starter/>
            </>
        )}
    </Observer>
}

export default ChooseDeliveryPersonLinksMulti;
