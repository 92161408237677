import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  show_client_contact_container: {
    display: "flex",
    flexDirection: "column"
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.secondary.light
  },
  label:{
    fontSize: '12px',
    color: theme.palette.grey[500],
  },
  name_container:{
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '14px',
    marginLeft: '4px'
  },
  element_container:{
    display: 'flex',
    alignItems: 'center'
  },
  name:{
    color: theme.palette.grey[600],
    fontSize: '14px',
  },
  not_available:{
    color: theme.palette.error.light,
    fontSize: '18px',
  },
}));

export { useStyles };
