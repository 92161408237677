import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import ShopProductStockTransaction from "../entities/productOrder/shop/ShopProductStockTransaction";

export module ShopProductStockTransactionService {
    export async function get(
        shopProductId: string,
        page: number = 1,
        pageSize: number = 15,
        from?: number,
        to?: number,
    ): Promise<Response<PagedResponse<ShopProductStockTransaction>>> {
        const url = Config.get("GET_SHOP_PRODUCT_STOCK_TRANSACTION_URL", { page, pageSize, id: shopProductId, from, to });
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const transactionData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (transactionData && paginationData) {
                const data = transactionData.map((t: any) =>
                    new ShopProductStockTransaction(
                        t.id,
                        t.agencyId,
                        t.shopProductId,
                        t.shopProductName,
                        t.amount,
                        t.status,
                        t.createdAt,
                        t.amountBefore,
                        t.comment,
                        t.error
                    ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ShopProductStockTransaction>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ShopProductStockTransaction>>(false, undefined, response.error, response.status);
    }

}