import React, {useCallback, useEffect, useState} from 'react';
import SellerRoleSelectProperties from "./SellerRoleSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import LockSharpIcon from '@material-ui/icons/LockSharp';
import Role from "../../../entities/Role";
import {useCommonContext} from "../../../contexts/CommonContext";
import {RoleService} from "../../../service/RoleService";
import MultiSelect from "../../../ui_components/MultiSelect/MultiSelect";
import MultiSelectOption from "../../../ui_components/MultiSelect/MultiSelectOption";

const SellerRoleSelect: React.FC<SellerRoleSelectProperties> = ({
                                                                    selectedRolesId,
                                                                    onChange,
                                                                    disabled,
                                                                    fullWidth = false,
                                                                    className = '',

                                                                }) => {
    const i18n = useI18nContext();
    const commonContext = useCommonContext();

    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [options, setOptions] = useState<MultiSelectOption[]>([]);


    const onRoleChange = useCallback((selected: MultiSelectOption[]) => {
        const products = roles.filter(p => selected.some(s => s.value === p.id));
        onChange(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles]);

    useEffect(() => {
        setLoading(true);
        RoleService.get().then(response => {
            if (response.success && response.data) {
                setRoles(response.data);
            }
            commonContext.processErrors(response);
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const opts = roles.map(p =>
            new MultiSelectOption(
                p.name,
                p.id,
                p.description,
                selectedRolesId?.some(r => r === p.id)
            ));
        setOptions(opts);
    }, [roles, selectedRolesId]);

    return (
        <MultiSelect
            loading={loading}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("ROLES")}
            placeholder={i18n.translate("ROLE_PLACEHOLDER")}
            icon={LockSharpIcon}
            onChange={onRoleChange}
            elements={options}
        />
    )
}


export default SellerRoleSelect;