import React from 'react';
import ShowBankCardImgProperties from "./ShowBankCardImgProperties";
import { useStyles } from './ShowBankCardImg.style';
import clsx from 'clsx';


const ShowBankCardImg: React.FC<ShowBankCardImgProperties> = ({
                                                        bankCardBank,
                                                        bankCardNumber
                                                      }) => {

    const classes = useStyles();

    return (
        <div className={classes.comp_container}>
            <img className={classes.card_img} src={`${process.env.PUBLIC_URL}/${bankCardBank}.png`} alt='Card'/>
            <div className={clsx(classes.card_number, bankCardBank === 'BANDEC' && classes.text_white)}><div>{bankCardNumber}</div></div>
        </div>
    )
}
export default ShowBankCardImg;