const values = {
    "MONEY_SERVICE_FEES": "Cargos",
    "MONEY_SERVICE_FEE": "Cargo de envío",
    "NEW_MONEY_SERVICE_FEE": "Nuevo cargo de envío",
    "EDIT_MONEY_SERVICE_FEE": "Editar cargo de envío",
    "MONEY": "Dinero",
    "FROM": "De",
    "TO": "Hasta",
    "FEE": "Cargo",
    "NO_MONEY_SERVICE_FEE_TITLE": "No hay cargos de envío!!!",
    "NO_MONEY_SERVICE_FEE_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "MONEY_SERVICE_FEES_FILTER_PLACEHOLDER": "Valor",
    "REMOVE_MONEY_SERVICE_FEE_CONFIRMATION": "¿Está seguro que desea eliminar cargo de envío?",
    "REMOVE_MONEY_SERVICE_FEE": "Eliminar cargo de envío",
    "SERVICE_FEE_TYPE": "Tipo de cargo",
    "SERVICE_FEE_TYPE_PLACEHOLDER": "Seleccione un tipo de cargo",
    "PERCENT": "Porsentaje",
    "UNITARY": "Unitario",
    "PERCENT_DESCRIPTION": "Cargo será cobrado en valor porcentual",
    "UNITARY_DESCRIPTION": "Cargo será cobrado en valor unitario",
}

export default values;