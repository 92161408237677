import React, {ChangeEvent} from 'react';
import {useStyles} from "./ShowPaginationSmall.style";
import ShowPaginationProperties from "../ShowPaginationProperties";
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";
import Width from "../../common/enums/Width";
import Alignment from "../../common/enums/Alignment";
import Box from "../../Box/Box";
import PageComponentsMedium from "./PageComponentsMedium";
import ViewMedium from "../../MediaView/ViewMedium";
import ViewSmall from "../../MediaView/ViewSmall";
import PageComponentsSmall from "./PageComponentsSmall";


const ShowPaginationSmall: React.FC<ShowPaginationProperties> = ({
                                                                           className = '',
                                                                           pagination,
                                                                           elementLabel,
                                                                           onChange
                                                                       }) => {
    const itemsPerPageValues = [5, 10, 15, 20, 30, 50, 100];
    const classes = useStyles();
    const i18n = useI18nContext();

    const onItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const pageSize = parseInt(event.target.value);
        onChange(1, pageSize);
    }

    return (
        <Box className={classes.box} width={Width.FULL_WIDTH}
             justifyContent={Alignment.SPACE_BETWEEN}>
            <div className={clsx(classes.pagination_container, className)}>
                <div className={classes.row}>
                    <div className={classes.elements_count}>
                        <div>{pagination.elementsCount}</div>
                        <div>{elementLabel}</div>
                    </div>
                    <div className={classes.items_per_page}>
                        <select onChange={onItemsPerPageChange} value={pagination.pageSize}>
                            {itemsPerPageValues.map((value, i) =>
                                <option key={i} value={value}>{value}</option>
                            )}
                        </select>
                        <div>{i18n.translate("ITEMS_PER_PAGE")}</div>
                    </div>
                </div>
                <div className={classes.row}>
                    <ViewSmall fullWidth>
                        <PageComponentsSmall onChange={onChange} pagination={pagination}/>
                    </ViewSmall>
                    <ViewMedium fullWidth>
                        <PageComponentsMedium onChange={onChange} pagination={pagination}/>
                    </ViewMedium>
                </div>
            </div>
        </Box>
    )
}
export default ShowPaginationSmall;