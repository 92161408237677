import React, { useEffect } from 'react';
import ArticleDeliverySelectProperties from "./ArticleSelectProperties";
import {useStyles} from "./ArticleSelect.style";
import {Observer} from "mobx-react-lite";
import ImageCardSkeletonSkeleton from '../../../../ui_components/Skeletons/ImageCardSkeleton/ImageCardSkeleton';
import SkeletonGrid from '../../../../ui_components/SkeletonGrid/SkeletonGrid';
import { Box } from '@material-ui/core';
import Width from '../../../../ui_components/common/enums/Width';
import Alignment from '../../../../ui_components/common/enums/Alignment';
import GeoSelect from '../../../Selects/GeoSelect/GeoSelect';
import { useI18nContext } from '../../../../contexts/I18nContext';
import Button from "../../../../ui_components/Button/Button";
import Geo from '../../../../entities/Geo';
import MessageCard from '../../../../ui_components/MessageCard/MessageCard';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShowArticleDeliveryItem from '../../../Info/ShowArticleDeliveryItem/ShowArticleDeliveryItem';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Link from '../../../../ui_components/Link/Link';

const ArticleSelect: React.FC<ArticleDeliverySelectProperties> = ({
                                                                          store
                                                                      }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    useEffect(() => {
        store.filterByGeo();
    }, [store]);

    const select = (foodPackageId: string) => {
        store.selectArticle(foodPackageId);
    }

    const deSelect = (foodPackageId: string) => {
        store.deselectArticle(foodPackageId);
    }

    const onContactGeoFilter = () => {
        store.geoFilter = store.selectedContact?.geo;
        store.filterByGeo();
    }

    const onGeoChange = (newGeo: Geo | undefined) => store.geoFilter = newGeo;

    const skeletons = [
        <ImageCardSkeletonSkeleton></ImageCardSkeletonSkeleton>,
        <ImageCardSkeletonSkeleton></ImageCardSkeletonSkeleton>,
        <ImageCardSkeletonSkeleton></ImageCardSkeletonSkeleton>
    ];

    return (
        <Observer>
            {() => (
                <>
                    <Box className={classes.container}>
                        <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                            justifyContent={Alignment.SPACE_BETWEEN}>
                            <div>
                                {store.selectedContact && <div className={classes.contact_geo_select_link_container}>
                                    <Link text={'Filtrar por ubicación del Destinatario'} onClick={onContactGeoFilter}/>
                                </div>}
                                <GeoSelect selected={store.geoFilter} onChange={onGeoChange}/>
                                <Button
                                    onClick={() => {
                                        store.filterByGeo()
                                    }}
                                    className={classes.search_btn}
                                    text={i18n.translate("SEARCH")}
                                />
                            </div>
                            <div className={classes.shopping_cart_container}>
                                <div className={classes.shopping_cart_icon_container}><ShoppingCartIcon/></div>
                                {store.selectedArticles && store.selectedArticles.length > 0 && <ul>
                                    {store.selectedArticles.map((selectedArticle, i) => (
                                        <li>
                                            {selectedArticle.name}
                                        </li>
                                    ))}
                                </ul>}
                                {(!store.selectedArticles || store.selectedArticles.length === 0) &&
                                <div className={classes.with_cart_items_text}>Sin artículos en el carrito. Seleccione artículos en el área a la derecha para adicionarlos al carrito.</div>}
                            </div>
                        </Box>
                        <div className={classes.article_container}>
                            {(!store.articles || store.articles.length === 0) && !store.loadingArticles &&
                                <MessageCard icon={FastfoodIcon} title={i18n.translate("SELECT_A_FILTER_TO_SHOW_ARTICLES")}
                                            subtitle={''}/>}
                            <div>{store.loadingArticles && <SkeletonGrid skeletons={skeletons}></SkeletonGrid>}</div>
                            {!store.loadingArticles && store.articles &&
                            store.articles.map((el, i) => (
                                <ShowArticleDeliveryItem
                                    key={i}
                                    id={el.id}
                                    title={el.name}
                                    text={el.description}
                                    imageName={el.defaultImage}
                                    articlePrices={el.prices}
                                    isSelectable={true}
                                    isSelected={store.selectedArticles ? store.selectedArticles.findIndex(t => t.id === el.id) >= 0 : false}
                                    onSelect={select}
                                    onDeselect={deSelect}
                                />
                            ))}
                        </div>
                    </Box>
                </>
            )}
        </Observer>
    )
}

export default ArticleSelect;