import React from 'react';
import NewDeliveryPersonButtonProperties from "./NewDeliveryPersonButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewDeliveryPersonStore from "./NewDeliveryPersonStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import {useStyles} from "./NewDeliveryPersonButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import EmailInput from "../../Inputs/EmailInput/EmailInput";
import PhoneInput from '../../Inputs/PhoneInput/PhoneInput';
import useOnEnterPress from '../../../hooks/EnterPress';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import Phone from "../../../entities/Phone";
import CountryCode from "../../../entities/CountryCode";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import { GeoLevel } from '../../Selects/GeoSelect/GeoSelectProperties';
import GeoSelect from '../../Selects/GeoSelect/GeoSelect';
import Geo from '../../../entities/Geo';


const NewDeliveryPersonButton: React.FC<NewDeliveryPersonButtonProperties> = ({
                                                                                  onClick = () => {
                                                                                  },
                                                                                  onSave = () => {
                                                                                  },
                                                                                  showIcon = true,
                                                                                  showText = true,
                                                                                  disabled,
                                                                                  className = '',
                                                                                  deliveryPersonId,
                                                                                  rounded = false,
                                                                                  size = ButtonSize.DEFAULT,
                                                                                  type = ButtonType.DEFAULT
                                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new NewDeliveryPersonStore(commonContext, deliveryPersonId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.person.name = newName;
    const onEmailChange = (newEmail: string) => store.person.email = newEmail;
    const onPhoneChange = (newPhone: Phone) => store.person.homePhone = newPhone;
    const onGeoChange = (geo?: Geo) => store.person.geo = geo;
    const onNumberChange = (newNumber: Phone) => store.person.phone = newNumber;

    const onProvinceChange = (geo?: Geo) => {
        store.selectedProvince = geo;
        if (geo && store.person.geo && store.person.geo.parentId !== geo.id) {
            store.person.geo = undefined;
        }
    }

    const onPersonSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }
    useOnEnterPress(onPersonSave,[store]);

    if (!userStore.hasAccess(Access.CREATE_DELIVERY_PERSON.valueOf())) {
        return <></>;
    }

    const text = i18n.translate((store.isEditing ? "EDIT_DELIVERY_PERSON" : "NEW_DELIVERY_PERSON"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <div className={classes.display_flex}>
                            <SellerNameInput error={i18n.translate(store.nameError)} fullWidth
                                required={true}
                                className={classes.input}
                                value={store.person?.name}
                                onChange={onNameChange}/>
                            <EmailInput error={i18n.translate(store.emailError)} fullWidth className={classes.input}
                                required={true}
                                value={store.person?.email}
                                onChange={onEmailChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <PhoneInput
                                error={i18n.translate(store.homePhoneError)}
                                fullWidth className={classes.input}
                                value={store.person?.homePhone}
                                label={i18n.translate("LINE_PHONE")}
                                countryCode={CountryCode.CUBA}
                                onChange={onPhoneChange}/>
                            <PhoneInput 
                                error={i18n.translate(store.phoneError)}
                                required={true}
                                fullWidth className={classes.input}
                                value={store.person?.phone}
                                countryCode={CountryCode.CUBA}
                                onChange={onNumberChange}/>
                        </div>
                        <div className={classes.display_flex}>
                            <GeoSelect
                                label={'Provincia'}
                                onChange={onProvinceChange}
                                className={classes.input}
                                levels={[GeoLevel.STATE]}
                                selected={store.selectedProvince}
                                fullWidth/>
                            <GeoSelect
                                label={'Municipio'}
                                onChange={onGeoChange}
                                required={true}
                                className={classes.input}
                                levels={[GeoLevel.CITY]}
                                selected={store.person?.geo}
                                parentIdFilter={store.selectedProvince?.id}
                                fullWidth/>
                        </div>
                        <div className={classes.btn_container}>
                            <Button
                                disabled={!store.enableButton}
                                loading={store.loading} className={classes.input} onClick={onPersonSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}/>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewDeliveryPersonButton;
