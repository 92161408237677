import {action, computed, makeObservable, observable, runInAction,} from "mobx";
import ReferenceItemPriceResponse from "../../../model/packageDelivery/referenceItemPrice/ReferenceItemPriceResponse";
import Pagination from "../../../entities/Pagination";
import CommonStore from "../../../stores/CommonStore";
import { ReferenceItemPriceService } from "../../../service/packageDelivery/ReferenceItemPrice/ReferenceItemPriceService";

class ReferenceItemPriceSelectStore {
  public referenceItemsPrice: ReferenceItemPriceResponse[];
  public pagination: Pagination;
  public filter?: string;
  public commonStore: CommonStore;

  constructor(commonStore: CommonStore) {
    this.pagination = new Pagination(1, 10, 0, 0);
    this.referenceItemsPrice = [];
    this.commonStore = commonStore;
    makeObservable(this, {
      referenceItemsPrice: observable,
      pagination: observable,
      filter: observable,
      isEmpty: computed,
    });
  }

  get isEmpty(): boolean {
    return this.referenceItemsPrice.length === 0;
  }

  public async setFilter(filter: string) {
    runInAction(() => {
      this.filter = filter.toUpperCase();
    });
    await this.getElements(1);
  }

  @action
  public async search() {
    await this.getElements(1);
  }

  @action
  public async cleanSearch() {
    runInAction(() => {
      this.filter = "";
    });
    await this.getElements(1);
  }

  @action
  public async goToPage(
    page: number = this.pagination.currentPage,
    pageSize: number = this.pagination.pageSize
  ): Promise<void> {
    await this.getElements(page, pageSize);
  }

  @action
  public async getElements(
    page: number = this.pagination.currentPage,
    pageSize: number = this.pagination.pageSize
  ): Promise<void> {
    this.commonStore.setLoading();
    const response = await ReferenceItemPriceService.get(
      page,
      pageSize,
      this.filter
    );
    if (response.success) {
      runInAction(() => {
        this.referenceItemsPrice = response.data?.data || [];
        this.pagination = response.data?.pagination || this.pagination;
      });
    }
    this.commonStore.processErrors(response);
    this.commonStore.removeLoading();
  }
}
export default ReferenceItemPriceSelectStore;
