const values = {
    "ARTICLE_REMITTANCES": "Entregas de artículos",
    "SELECT_ARTICLE": "Selección de artículo",
    "ARTICLE_DELIVERY": "Entregas de artículos",
    "NO_ARTICLE_DELIVERY_TITLE": "No hay entregas de artículo!!!",
    "NO_ARTICLE_DELIVERY_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "SELECT_A_FILTER_TO_SHOW_ARTICLES": "Seleccione un filtro para mostrar los artículos",
    "NEW_ARTICLE_DELIVERY": "Nueva entrega de artículo",
    "RESUME": "Resumen",
    "NO_ADDITIONAL_PRICE_TITLE": "No hay precios adicionales!!!",
    "NO_ADDITIONAL_PRICE_SUBTITLE": "Adicionelos usando el formulario encima.",
}

export default values;