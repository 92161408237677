import React from 'react';
import ShowPackageDeliveryAmountProperties from "./ShowPackageDeliveryAmountProperties";
import {useStyles} from "./ShowPackageDeliveryAmount.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";

const ShowPackageDeliveryAmount: React.FC<ShowPackageDeliveryAmountProperties> = ({
                                                        valueToPay,
                                                        currencyToPay,
                                                        className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div className={classes.number}>
                <div className={classes.title}>{`${i18n.translate("TOTAL_TO_PAY")}:`}</div>
                <div className={classes.value}>{valueToPay && CurrencyModule.format(valueToPay, currencyToPay)}</div>
            </div>
            <div className={classes.currency}>{i18n.translate(currencyToPay)}</div>
        </div>
    )
}


export default ShowPackageDeliveryAmount;