import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { MoneyDeliveryService } from "../../../service/moneyDelivery/MoneyDeliveryService";
import DeliveryOrderCommentResponse from "../../../model/moneyDelivery/DeliveryOrderCommentResponse";
import DeliveryOrderType from "../../../model/DeliveryOrderType";
import {PackageDeliveryService} from "../../../service/packageDelivery/PackageDeliveryService";
import { ProductOrderService } from "../../../service/ProductOrderService";

class CommentsStore {

    public deliveryOrderType?:DeliveryOrderType
    public deliveryOrderId?: string;
    public moneyDeliveryOrderComments: DeliveryOrderCommentResponse[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, deliveryOrderType?: DeliveryOrderType,remittanceId?: string) {
        this.deliveryOrderType= deliveryOrderType
        this.deliveryOrderId = remittanceId;
        this.isModalOpen = false;
        this.moneyDeliveryOrderComments = [];
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async loadMoneyDeliveryOrderComments() {
        const response =
            (this.deliveryOrderType===DeliveryOrderType.PACKAGE_DELIVERY)?
                await PackageDeliveryService.getPackageDeliveryComments(this.deliveryOrderId!):
                await MoneyDeliveryService.getMoneyDeliveryComments(this.deliveryOrderId!)
        if (response.success && response.data) {
            this.moneyDeliveryOrderComments = response.data;
        }
    }

    @action
    public async addComment(comment: string) {
        this.loading = true;
        const response = await ProductOrderService.addComment(this.deliveryOrderId!, comment);
        if (response.success && response.data) {
            const newComments = response.data.comments.filter((t: any) => this.moneyDeliveryOrderComments.findIndex(x => x.id === t.id) < 0);
            if (newComments && newComments.length > 0) {
                const newComment = newComments[0];
                this.moneyDeliveryOrderComments = [ ...this.moneyDeliveryOrderComments, new DeliveryOrderCommentResponse(
                    newComment.id, newComment.userId, 'Yo', newComment.comment, newComment.createdAt
                ) ];
            }
            return true;
        }
        this.loading = false;
        return false;
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        await this.loadMoneyDeliveryOrderComments();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default CommentsStore;