import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    access_page_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar_container: {},
    filter_container: {
        marginTop: 8
    },
    body_container: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    roles_container: {
        width: '100%',
    },
    role_container: {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 4,
        padding: 4
    },
    role_title: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        padding: 4,
        justifyContent: "center"
    },
    access_container: {
        display: "flex",
        flexWrap: 'wrap',
        width: '100%',
    },
    access: {
        backgroundColor: theme.palette.primary.dark,
        padding: 4,
        borderRadius: 4,
        margin: 4,
        color: theme.palette.common.white,
        display: 'flex'
    },
    access_neg: {
        backgroundColor: theme.palette.error.dark,
        padding: 4,
        borderRadius: 4,
        margin: 4,
        color: theme.palette.common.white,
        display: 'flex'
    },
    tools: {
        display: 'flex',
        cursor: "pointer"
    }
}));

export {useStyles}