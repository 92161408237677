import React, {useEffect, useState} from 'react';
import {useStyles} from "./TextInputShort.style";
import {useCommonStyles} from "../common/CommonStyles.style";
import TextInputShortType from "./TextInputShortType";
import TextInputShortProperties from "./TextInputShortProperties";
import clsx from "clsx";


const TextInputShort: React.FC<TextInputShortProperties> = ({
                                                      type = TextInputShortType.TEXT,
                                                      error,
                                                      label,
                                                      value = '',
                                                      maxLength,
                                                      placeholder = '',
                                                      helpText,
                                                      onChange,
                                                      disabled = false,
                                                      startIcon: StartIcon,
                                                      endIcon: EndIcon,
                                                      fullWidth = false,
                                                      removeSpaces = false,
                                                      className = '',
                                                      onFocus = () => {
                                                      },
                                                      onBlur = () => {
                                                      },
                                                      onKeyPress = () => {
                                                      }
                                                  }: TextInputShortProperties) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [currentValue, setCurrentValue] = useState(value);

    const containerClasses = clsx(
        classes.text_input_container,
        className,
        fullWidth && commonClasses.fullWidth,
        error && classes.input_container_error);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newValue = e.target.value;
        if (removeSpaces) {
            newValue = newValue.replaceAll(' ', '');
        }
        if (maxLength && newValue.length > maxLength) return;
        setCurrentValue(newValue);
        e.target.value = newValue;
        onChange(e);
    }
    return (
        <div className={containerClasses}>
            <div className={classes.label}>{label}</div>
            <div className={classes.input_container}>
                {StartIcon &&
                    <div className={classes.start_icon_container}>
                        <StartIcon/>
                    </div>}
                <input
                    type={type}
                    value={currentValue}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    className={classes.input}
                    onKeyPress={(event => onKeyPress(event.key))}
                    onChange={onValueChange}
                    disabled={disabled}
                    // maxLength={maxLength}
                />
                {EndIcon &&
                    <div className={classes.end_icon_container}>
                        <EndIcon/>
                    </div>}
            </div>
            <div className={clsx(classes.text_hint, error && classes.error)}>{error || helpText}</div>
        </div>
    )
}
export default TextInputShort