import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    add_additional_price_container: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form_container: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        padding: '15px 0',
        flexDirection: 'column',
        boxSizing: 'border-box',
        borderRadius: '4px',
        gap: '12px',
        alignItems: 'end'
    },
    save_btn: {
        height: 'fit-content',
        gridColumn: 2,
        justifySelf: 'flex-end'
    },
}));

export {useStyles}