import Request from "../../model/Request";
import Response from "../../model/Response";
import { RestClient } from "../../util/RestClient";
import { Config } from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import DeliveryOrderCommentResponse from "../../model/moneyDelivery/DeliveryOrderCommentResponse";
import Phone from "../../entities/Phone";
import PackageDeliveryOrderResponse from "../../model/packageDelivery/PackageDeliveryOrderResponse";
import PackageDeliveryOrderWithDetailsResponse from "../../model/packageDelivery/PackageDeliveryOrderWithDetailsResponse";
import PackageDeliveryOrderRequest from "../../model/packageDelivery/PackageDeliveryOrderRequest";
import AddDeliveryPersonInfoRequestMultiOrders from "../../model/packageDelivery/AddDeliveryPersonInfoRequestMultiOrders";
import NewCommissionDeliveryPersonInfoRequestMultiOrders from "../../model/packageDelivery/NewCommissionDeliveryPersonInfoRequestMultiOrders";
import UpdateItemsAndPaymentsRequest from "../../model/packageDelivery/UpdateItemsAndPaymentsRequest";
import ShipmentUpdateType from "../../entities/ShipmentUpdateType";

export module PackageDeliveryService {
  export async function get(
      page: number = 1,
      pageSize: number = 15,
      from?: number | undefined,
      to?: number | undefined,
      filter?: string | undefined,
      deliveryPersonId?: string | undefined,
      status?: string[] | undefined,
      geoId?: string | undefined,
      agencyId?: string | undefined,
      shippingId?: string | undefined,
      shipmentAssigned?: string | undefined,
  ): Promise<Response<PagedResponse<PackageDeliveryOrderResponse>>> {
    const url = Config.get("GET_PACKAGE_DELIVERY_URL", {
      page,
      pageSize,
      from,
      to,
      filter,
      deliveryPersonId,
      status,
      geoId,
      agencyId,
      shippingId,
      shipmentAssigned
    });

    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const resultData = response.data?.data;
      const paginationData = response.data?.pagination;
      if (resultData && paginationData) {
        const data = resultData.map(
          (t: any) =>
            new PackageDeliveryOrderResponse(
              t.id,
              t.shippingId,
              t.orderNumber,
              t.agencyId,
              t.agencyName,
              t.clientId,
              t.clientName,
              t.clientPhone
                ? new Phone(
                    t.clientPhone.number,
                    t.clientPhone.countryCode,
                    t.clientPhone.phoneType
                  )
                : null,
              t.contactId,
              t.contactName,
              t.contactPhone
                ? new Phone(
                    t.contactPhone.number,
                    t.contactPhone.countryCode,
                    t.contactPhone.phoneType
                  )
                : null,
              t.userId,
              t.userName,
              t.deliveryPersonsInfo,
              t.currentDeliveryPersonInfo,
              t.totalToPay,
              t.status,
              t.createdAt,
              t.address,
              t.geo,
              t.itemsAndCategories || [],
              t.totalWeight,
              t.payed,
              t.observations
            )
        );
        const pagination = new Pagination(
          paginationData.currentPage,
          paginationData.pageSize,
          paginationData.elementsCount,
          paginationData.pageCount
        );
        return new Response<PagedResponse<PackageDeliveryOrderResponse>>(
          true,
          { data, pagination },
          response.error
        );
      }
    }
    return new Response<PagedResponse<PackageDeliveryOrderResponse>>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function find(
    id: string
  ): Promise<Response<PackageDeliveryOrderWithDetailsResponse>> {
    const url = Config.get("FIND_PACKAGE_DELIVERY_URL", { id });
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const t = response.data;
      if (t) {
        const packageDelivery = new PackageDeliveryOrderWithDetailsResponse(
          t.id,
          t.orderNumber,
          t.agencyId,
          t.agency,
          t.clientId,
          t.clientName,
          t.contact,
          t.totalToPay,
          t.createdAt,
          t.amountToReceive,
          t.currencyToReceive,
          t.currentStatus,
          t.comments,
          t.difficultDelivery,
          t.itemsAndCategories,
          t.payments
        );
        return new Response<PackageDeliveryOrderWithDetailsResponse>(
          true,
          packageDelivery,
          response.error
        );
      }
    }
    return new Response<PackageDeliveryOrderWithDetailsResponse>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function create(
    packageDelivery: PackageDeliveryOrderRequest
  ): Promise<Response<PackageDeliveryOrderResponse>> {
    const url = Config.get("CREATE_PACKAGE_DELIVERY_URL");
    const request = new Request(url, packageDelivery);
    const response = await RestClient.post(request);
    if(response.success){
      const t = response.data
      if(t){
        const packageDeliveryOrder = new PackageDeliveryOrderResponse(
          t.id,
          t.shippingId,
          t.orderNumber,
          t.agencyId,
          t.agencyName,
          t.clientId,
          t.clientName,
          t.clientPhone, 
          t.contactId, 
          t.contactName, 
          t.contactPhone, 
          t.userId, 
          t.userName, 
          t.deliveryPersonsInfo, 
          t.currentDeliveryPersonInfo, 
          t.totalToPay, 
          t.status, 
          t.createdAt, 
          t.address, 
          t.geo, 
          t.itemsAndCategories, 
          t.totalWeight, 
          t.payed,
          t.observations,
        )
        return new Response<PackageDeliveryOrderResponse>(
          true,
          packageDeliveryOrder,
          response.error
        );
      }
    }
    return new Response<PackageDeliveryOrderResponse>(
      false,
      undefined,
      response.error,
      response.status
    );
  }
  export async function updateItemsAndPayments(
    packageDeliveryId:string, 
    updateItemsAndPaymentsRequest:UpdateItemsAndPaymentsRequest
    ): Promise<Response<any>> {
    const url = Config.get("UPDATE_ITEMS_AND_PAYMENTS_PACKAGE_DELIVERY_URL", {
      packageDeliveryId: packageDeliveryId
    })

    const request = new Request(url, updateItemsAndPaymentsRequest)
    const response = await RestClient.post(request)
    if(response.success){
      const t = response.data
      if(t){
        const packageDeliveryOrder = new PackageDeliveryOrderResponse(
          t.id,
          t.shippingId,
          t.orderNumber,
          t.agencyId,
          t.agencyName,
          t.clientId,
          t.clientName,
          t.clientPhone, 
          t.contactId, 
          t.contactName, 
          t.contactPhone, 
          t.userId, 
          t.userName, 
          t.deliveryPersonsInfo, 
          t.currentDeliveryPersonInfo, 
          t.totalToPay, 
          t.status, 
          t.createdAt, 
          t.address, 
          t.geo, 
          t.itemsAndCategories, 
          t.totalWeight, 
          t.payed,
          t.observations,
        )
        return new Response<PackageDeliveryOrderResponse>(
          true,
          packageDeliveryOrder,
          response.error
        );
      }
    }
    return new Response<PackageDeliveryOrderResponse>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function getPackageDeliveryComments(
    id: string
  ): Promise<Response<DeliveryOrderCommentResponse[]>> {
    const url = Config.get("GET_PRODUCT_ORDER_COMMENTS_URL", { id });
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const comments = response.data;
      if (comments) {
        const commentsREsult = comments.map(
          (t: DeliveryOrderCommentResponse) =>
            new DeliveryOrderCommentResponse(
              t.id,
              t.userId,
              t.userName,
              t.comment,
              t.createdAt
            )
        );
        return new Response<DeliveryOrderCommentResponse[]>(
          true,
          commentsREsult,
          response.error
        );
      }
    }
    return new Response<DeliveryOrderCommentResponse[]>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function getPackageDeliveryDeliveryPersonsInfo(
    packageDeliveryIds: string[]
  ) {
    const url = Config.get("GET_PRODUCT_ORDER_DELIVERY_PERSONS_INFO");
    const request = new Request(url, packageDeliveryIds);
    return RestClient.put(request);
  }

  export async function addDeliveryPersonInfo(
    addDeliveryPersonInfoRequestMultiOrders: AddDeliveryPersonInfoRequestMultiOrders
  ) {
    const url = Config.get("ADD_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO", {});
    const request = new Request(url, addDeliveryPersonInfoRequestMultiOrders);
    return await RestClient.post(request);
  }

  export async function removeDeliveryPersonInfo(
    packageDeliveryIds: string[], deliveryPersonId: string
  ) {
    const url = Config.get("REMOVE_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO", {deliveryPersonId});
    const request = new Request(url,packageDeliveryIds);
    return await RestClient.post(request);
  }

  export async function setCommissionDeliveryPersonInfo(
    newCommissionDeliveryPersonInfoRequestMultiOrders:NewCommissionDeliveryPersonInfoRequestMultiOrders,
    deliveryPersonId: string,
  ) {
    const url = Config.get(
      "SET_COMMISSION_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO",
      {
        deliveryPersonId: deliveryPersonId,
      }
    );
    const request = new Request(url, newCommissionDeliveryPersonInfoRequestMultiOrders);
    return await RestClient.put(request);
  }

  export async function receivedDelivery(
    packageDeliveryIds: string[],
    deliveryPersonId: string
  ) {
    const url = Config.get(
      "CONFIRM_RECEIVED_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO",
      {
        deliveryPersonId: deliveryPersonId,
      }
    );
    const request = new Request(url,packageDeliveryIds);
    return await RestClient.put(request);
  }
  export async function deliveredDelivery(
    packageDeliveryIds: string[],
    deliveryPersonId: string
  ) {
    const url = Config.get(
      "CONFIRM_DELIVERED_PACKAGE_DELIVERY_DELIVERY_PERSON_INFO",
      {
        deliveryPersonId: deliveryPersonId,
      }
    );
    const request = new Request(url,packageDeliveryIds);
    return await RestClient.put(request);
  }

  export async function updateClientPerson(
    packageDeliveryId: string,
    contactId: string
  ): Promise<Response<any>> {
    const url = Config.get("UPDATE_PACKAGE_DELIVERY_CONTACT_URL", {
      packageDeliveryId: packageDeliveryId,
      contactId: contactId,
    });
    const request = new Request(url, {});
    return await RestClient.put(request);
  }

  export async function setDeliveryPerson(
    packageDeliveryIds: string[],
    deliveryPersonId: string
  ): Promise<Response<any>> {
    const url = Config.get("SET_PACKAGE_DELIVERY_DELIVERY_PERSON_URL", {
      deliveryPersonId: deliveryPersonId,
    });
    const request = new Request(url, packageDeliveryIds);
    return await RestClient.put(request);
  }

  export async function unsetDeliveryPerson(
    packageDeliveryId: string
  ): Promise<Response<any>> {
    const url = Config.get("UNSET_PACKAGE_DELIVERY_DELIVERY_PERSON_URL", {
      packageDeliveryId: packageDeliveryId,
    });
    const request = new Request(url, {});
    return await RestClient.put(request);
  }
  
  
  export async function updateStatus(
    packageDeliveryId: string,
    status: string,
    comment: string
  ): Promise<Response<any>> {
    const url = Config.get("UPDATE_PACKAGE_DELIVERY_ORDER_STATUS_URL", {
      packageDeliveryId: packageDeliveryId,
      status: status,
    });
    const request = new Request(url, { comment: comment ? comment : "" });
    return await RestClient.put(request);
  }

  export async function updateBulkStatus(
    ordersIds: string[],
    status: string,
    comment: string
  ): Promise<Response<any>> {
    const url = Config.get("UPDATE_PACKAGE_DELIVERY_ORDERS_STATUS_URL", {
      status: status,
    });
    const request = new Request(url, { ordersIds, comment: comment ? comment : "" });
    return await RestClient.put(request);
  }

  export async function remove(
    packageDeliveryId: string
  ): Promise<Response<any>> {
    const url = Config.get("REMOVE_PACKAGE_DELIVERY_ORDER_URL", {
      id: packageDeliveryId,
    });
    const request = new Request(url);
    return await RestClient.remove(request);
  }

  export async function getShippinId(): Promise<Response<any>> {
    const url = Config.get("GET_PACKAGE_DELIVERY_SHIPPING_ID_URL");
    const request = new Request(url);
    return await RestClient.get(request);
  }

  export async function updateShippingId(
      packageDeliveryIds: string[],
      selectedShippingId: string | null,
      type: ShipmentUpdateType
  ): Promise<Response<any>> {
    let url = Config.get("UPDATE_PACKAGE_DELIVERY_SHIPPING_ID_URL", {
      shippingId: type === ShipmentUpdateType.ADD ? selectedShippingId : null,
    });

    const request = new Request(url, {
      deliveryIds: packageDeliveryIds,
    });

    return await RestClient.put(request);
  }




}
