import React from "react";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../Topups.style";
import clsx from "clsx";
import TopupRowProperties from "./TopupRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowProduct from "../../../shared_components/Info/ShowProduct/ShowProduct";
import ShowConfirmation from "../../../shared_components/Info/ShowConfirmation/ShowConfirmation";
import ProductOrderStatus from "../../../entities/productOrder/ProductOrderStatus";
import ShowGeneralComment from "../../../shared_components/Info/ShowGeneralComment/ShowGeneralComment";
import ShowOrderStatus from "../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus";
import ShowError from "../../../shared_components/Info/ShowError/ShowError";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ViewInvoiceButton from "../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ProductOrderStatusHistoryButton
    from "../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton";
import ShowText from "../../../shared_components/Info/ShowText/ShowText";
import ShowPhoneNumber from "../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber";
import ConfirmTopupButton from "../../../shared_components/Buttons/ConfirmTopupButton/ConfirmTopupButton";

const TopupRowMedium: React.FC<TopupRowProperties> = ({topup, products, filter, onLoad}) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const containerClasses = clsx(
        classes.topups_info_container,
        [
            ProductOrderStatus.PENDING,
            ProductOrderStatus.PAYED,
            ProductOrderStatus.PROCESSING
        ].includes(topup.status) && classes.pending,
        [ProductOrderStatus.COMPLETED].includes(topup.status) && classes.done,
        [
            ProductOrderStatus.CANCELED,
            ProductOrderStatus.REMOVED,
        ].includes(topup.status) && classes.error
    );
    const statusForConfirmation = [
        ProductOrderStatus.PROCESSING,
        ProductOrderStatus.RETRYING,
        ProductOrderStatus.PENDING,
    ];
    return (
        <div className={containerClasses}>
            <div>
                <ShowDate label={i18n.translate("DATE")} timestamp={topup.createdAt}/>
            </div>
            <div>
                <ShowText text={topup.clientName} title={'Remitente'} textToHighlight={filter}/>
                <ShowText text={topup.contactName} title={'Destinatario'} textToHighlight={filter}/>
                <ShowPhoneNumber showIcon showFlag={false} value={topup.phone} textToHighlight={filter}/>
            </div>
            <div>
                <ShowProduct value={products.find((t) => t.id === topup.productId)}/>
                <ShowGeneralComment value={topup.comment}/>
            </div>
            <div>
                <ShowText text={topup.agencyName} title={'Agencia'}/>
                <ShowText text={topup.userName} title={'Vendedor'} className={classes.margin_top_8}/>
            </div>
            <div>
                {topup.status !== ProductOrderStatus.COMPLETED && <ShowError error={topup.error}/>}
                <ShowConfirmation value={topup.confirmation}/>
            </div>
            <ShowOrderStatus status={topup.status}/>
            <ExpandoMenu>
                <>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderId={topup.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={topup.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    {statusForConfirmation.includes(topup.status) && <ConfirmTopupButton
                        size={ButtonSize.SMALL}
                        orderId={topup.id}
                        showText
                        type={ButtonType.SECONDARY}
                        onSuccess={onLoad}
                    />}
                </>
            </ExpandoMenu>
        </div>
    );
};

export default TopupRowMedium;
