class MultiSelectOption {
    public primaryText: string;
    public secondaryText?: string;
    public value: string;
    public selected: boolean;

    constructor(primaryText: string, value: string, secondaryText?: string, selected: boolean = false) {
        this.primaryText = primaryText;
        this.value = value;
        this.secondaryText = secondaryText;
        this.selected = selected;
    }
}

export default MultiSelectOption;