import {action, makeAutoObservable, observable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";

class ShowEditMiscellanySalePriceStore {

    public salePriceId: string;
    public miscellanyCategoryId: string;
    public value: number;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, salePriceId: string, miscellanyCategoryId: string, value: number) {
        this.loading = false;
        this.commonStore = commonStore;
        this.salePriceId = salePriceId;
        this.miscellanyCategoryId = miscellanyCategoryId;
        this.value = value;
        this.isModalOpen = false;
        makeAutoObservable(this, {
            isModalOpen: observable
        });
    }

    @action
    public openModal(): void {
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async updateMiscellanySalePrice() {
        const response = await SalePriceService.updateMiscellanySalePrice(this.salePriceId, this.miscellanyCategoryId, this.value);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default ShowEditMiscellanySalePriceStore;