import React from 'react';
import IconProperties from "./IconProperties";


const PhoneIcon: React.FC<IconProperties> = ({
                                                       className,
                                                       fontSize
                                                   }) => {
    return (
        <svg className={className}
             width="1em" height="1em"
             style={{fontSize: fontSize}}
             viewBox="0 0 1024 1024" >
            <path
                d="M883.5072 627.3536c-4.9152-34.1504-31.2832-60.7744-65.2288-68.7104-131.9424-30.9248-165.0176-106.9056-170.5984-191.1808-23.3984-4.352-65.024-9.0624-135.68-9.0624s-112.2816 4.7104-135.68 9.0624c-5.5808 84.2752-38.656 160.256-170.5984 191.1808-33.9456 7.9872-60.3136 34.56-65.2288 68.7104L115.0464 803.584C106.0864 865.6384 151.6544 921.6 215.04 921.6h593.92c63.3344 0 108.9536-55.9616 99.9936-118.016l-25.4464-176.2304zM512 793.1904c-71.424 0-129.3312-57.344-129.3312-128s57.9072-128 129.3312-128 129.3312 57.344 129.3312 128-57.9584 128-129.3312 128zM1021.44 307.2C1020.2112 230.4 824.7296 102.4512 512 102.4 199.2192 102.4512 3.7376 230.4 2.56 307.2s1.0752 176.7424 129.792 160.1024c150.5792-19.5072 141.312-72.0896 141.312-147.2512 0-52.4288 122.4704-65.0752 238.336-65.0752s238.2848 12.6464 238.336 65.0752c0 75.1616-9.2672 127.744 141.312 147.2512C1020.3136 483.9424 1022.6176 384 1021.44 307.2z"/>
        </svg>
    )
}
export default PhoneIcon;