import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import SalePrice from "../../../entities/SalePrice";
import {SalePriceService} from "../../../service/SalePriceService";

const ERROR_MAP = {
    email: ["ERROR_00_400_20", "ERROR_00_400_21"],
    phone: ["ERROR_00_400_24"],
    name: ["ERROR_00_400_22", "ERROR_00_400_23"],
}

class NewSalePriceStore {

    public salePriceId?: string;
    public salePrice: SalePrice;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public nameError?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, salePriceId?: string) {
        this.isEditing = !!salePriceId;
        this.salePriceId = salePriceId;
        this.isModalOpen = false;
        this.salePrice = {} as SalePrice;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.salePrice?.name);
    }

    @action
    public async loadSalePrice() {
        if (this.salePriceId) {
            this.isEditing = true;
            const response = await SalePriceService.find(this.salePriceId!)
            if (response.success && response.data) {
                this.salePrice = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadSalePrice();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? SalePriceService.update : SalePriceService.create;
        const response = await serviceToCall(this.salePrice);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default NewSalePriceStore;