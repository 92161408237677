import React from 'react';
import ShopOrderRowProperties from "./ShopOrderRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ShopOrderRowMedium from "./ShopOrderRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ShopOrderRowSmall from "./ShopOrderRowSmall";


const ShopOrderRow: React.FC<ShopOrderRowProperties> = ({
                                                                    shopOrder,
                                                                    selectedDeliveryOrderIds,
                                                                    onShopOrderSelect,
                                                                    onUpdate,
                                                                    filter
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <ShopOrderRowSmall onUpdate={onUpdate} onShopOrderSelect={onShopOrderSelect}
                                       shopOrder={shopOrder}
                                       selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                       filter={filter}/>
            </ViewSmall>
            <ViewMedium>
                <ShopOrderRowMedium onUpdate={onUpdate} onShopOrderSelect={onShopOrderSelect}
                                        shopOrder={shopOrder}
                                        selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                        filter={filter}/>
            </ViewMedium>
            <ViewLarge>
                <ShopOrderRowMedium onUpdate={onUpdate} onShopOrderSelect={onShopOrderSelect}
                                        shopOrder={shopOrder}
                                        selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                        filter={filter}/>
            </ViewLarge>
        </>
    )
}

export default ShopOrderRow;
