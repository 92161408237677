import {action, makeAutoObservable, observable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import ClientContact from "../../../entities/ClientContact";
import MoneyDeliveryOrder from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryOrder";
import Client from "../../../entities/Client";
import Agency from "../../../entities/Agency";
import Seller from "../../../entities/Seller";
import {CustomCategoryService} from "../../../service/CustomCategoryService";
import ItemsAndCategories from "../../../model/packageDelivery/ItemsAndCategories";
import CustomCategory from "../../../model/packageDelivery/customCategory/CustomCategory";
import PackageItem from "../../../model/packageDelivery/packageItem/PackageItem";
import CategoryInfo from "../../../model/packageDelivery/inventoryItem/CategoryInfo";
import CustomCategoryWeightRange from "../../../model/packageDelivery/customCategory/CustomCategoryWeightRange";
import WeightInfo from "../../../model/packageDelivery/WeightInfo";
import {
    CustomCategoryWeightRangeService
} from "../../../service/packageDelivery/customCategory/CustomCategoryWeightRangeService";
import {InventoryItemService} from "../../../service/packageDelivery/InventoryItem/InventoryItemService";
import InventoryItem from "../../../model/packageDelivery/inventoryItem/InventoryItem";
import PackageDeliveryOrderRequest from "../../../model/packageDelivery/PackageDeliveryOrderRequest";
import {PackageDeliveryService} from "../../../service/packageDelivery/PackageDeliveryService";
import { ClientContactService } from "../../../service/ClientContactService";
import { ClientService } from "../../../service/ClientService";
import UpdateItemsAndPaymentsRequest from "../../../model/packageDelivery/UpdateItemsAndPaymentsRequest";
import { AppStorage, AppStorageValues } from "../../../util/AppStorage";
import OrderPaymentResponse from "../../../model/payment/OrderPaymentResponse";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"],
    invalidPayment: ["ERROR_00_400_51"],
}
class NewPackageDeliveryStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public commonStore: CommonStore;
    public response?: MoneyDeliveryOrder[]
    public loading: boolean
    public finishButtonLoading = false;
    public editingItemsAndPaymnts = false

    public activeStep = 0;

    // ERRORS
    public invalidPaymentError?: string

    //IDENTIFIERS
    public id: string | undefined
    public orderNumber?: string
    public createdAt?: number

    // CLIENT AND CONTACT
    public selectedClient?: Client;
    public selectedContact?: ClientContact;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;

    public observations?: string;

    //CATEGORIES AND ITEMS
    @observable
    public itemsAndCategories: ItemsAndCategories[]
    public customCategories: CustomCategory[];
    public selectedCustomCategory: CustomCategory | undefined;
    public totalToPay?: number;
    public serviceCost?: number;
    public paymentFee?: number;
    public finalPackagePrice?:number
    public tempPackageItem?:PackageItem

    // ADDITIONAL PRICES
    public isAdditionalPricesStepCompleted = false;
    public additionalPrices: OrderAdditionalPrice[];

    // PAYMENTS
    public payments: OrderPaymentResponse[];
    public paymentCompleted: boolean;


    constructor(commonStore: CommonStore, onSuccessCreate?: () => void, packageDeliveryId?:string) {
        if(packageDeliveryId!=null){
            this.editingItemsAndPaymnts = true
        }
        this.id = packageDeliveryId
        this.isModalOpen = false;
        this.validating = false;
        this.additionalPrices = [];
        this.commonStore = commonStore;
        this.payments = [];
        this.loading = false
        this.customCategories = [];
        this.paymentCompleted = false;
        this.selectedAgency = AppStorage.get(AppStorageValues.SELECTED_AGENCY, undefined);
        this.selectedSeller = AppStorage.get(AppStorageValues.SELECTED_SELLER, undefined);
        this.onSuccessCreate = onSuccessCreate;
        this.itemsAndCategories = []
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.reset();
        if(this.id!==undefined){
            await this.getPackageDeliveryOrder(this.id!)
        }else{
            this.updateGeneralPrice()
        }
        this.isModalOpen = true;
    }
    @action
    public closeModal() {
        this.isModalOpen = false;
    }
    @action
    public restart() {
        this.response = undefined;
    }
    public reset() {
        this.orderNumber = undefined
        this.selectedClient = undefined;
        this.selectedContact = undefined;
        this.itemsAndCategories = []
        this.customCategories = []
        this.selectedCustomCategory = undefined
        this.totalToPay = 0;
        this.serviceCost = 0
        this.paymentFee = 0
        this.finalPackagePrice = 0
        this.tempPackageItem = undefined
        this.isAdditionalPricesStepCompleted = false
        this.additionalPrices = []
        this.payments = [];
        this.paymentCompleted = false;
        this.observations = undefined;
    }

    @action
    public async createPackageDelivery() {
        if (!this.selectedContact) {
            await this.commonStore.showErrorToast('Debe seleccionar un contacto.');
            return;
        }
        if (this.finishButtonLoading) {
            return;
        }

        this.finishButtonLoading = true;
        if(this.editingItemsAndPaymnts){
            const request = new UpdateItemsAndPaymentsRequest(
                this.itemsAndCategories,
                this.payments.filter(t => t.isEditable),
                this.totalToPay,
                this.serviceCost = this.getServicePrice(),
                this.paymentFee = this.getAdditionalPriceTotal(),)
            const response = await PackageDeliveryService.updateItemsAndPayments(this.id!,request)
            this.commonStore.processErrors(response)
            this.processError(response.error);
            if (response.success && response.data) {
                if (this.onSuccessCreate) {
                    this.onSuccessCreate();
                }
                this.id = response.data.id;
                this.orderNumber = response.data.orderNumber
                this.createdAt = response.data.createdAt
            }
        }else{
            const request = new PackageDeliveryOrderRequest(
                this.selectedContact!!.clientId,
                this.selectedContact!!.id,
                this.payments,
                this.itemsAndCategories,
                this.additionalPrices,
                this.id,
                this.orderNumber,
                this.selectedAgency?.id,
                this.selectedSeller?.id,
                this.totalToPay,
                this.serviceCost = this.getServicePrice(),
                this.paymentFee = this.getAdditionalPriceTotal(),
                this.observations
            )
            const response = await PackageDeliveryService.create(request);
            this.commonStore.processErrors(response)
            this.processError(response.error);
            if (response.success && response.data) {
                if (this.onSuccessCreate) {
                    this.onSuccessCreate();
                }
                this.id = response.data.id;
                this.orderNumber = response.data.orderNumber
                this.createdAt = response.data.createdAt
            }
        }
        
        this.finishButtonLoading = false;
    }
    public onSuccessCreate?: () => void;

    public getServicePrice(): number {
        return this.finalPackagePrice || 0;
    }

    public getTotalToPay(): number {
        return this.getServicePrice() + this.getAdditionalPriceTotal();
    }

    processError(error?: string) {
        this.invalidPaymentError = undefined
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error) //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
            if(ERROR_MAP.invalidPayment.includes(error)){
                this.invalidPaymentError = error
            }
        }
    }

    // ******** GET PACKAGE DELIVERY ORDER TO EDIT *******
    @action
    private async getPackageDeliveryOrder(packageDeliveryId:string){
        this.loading = true;
        const response = await PackageDeliveryService.find(packageDeliveryId)
        if (response.success) {
            const order = response.data
            if(order){
                this.orderNumber = order.orderNumber
                this.itemsAndCategories = order.itemsAndCategories
                this.updateCategoriesWithItems()

                await this.getClient(order.clientId)  
                await this.getContact(order.clientId,order.contact.id)

                this.updateGeneralPrice()  
                this.payments = order.payments
            }
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
    }

    @action
    private async getClient(clientId:string){
        this.loading = true;
        const response = await ClientService.find(clientId);
        if (response.success) {
            const client = response.data
            if(client){
                this.setClient(client)
            }
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
    }

    @action
    private async getContact(clientId:string, contactId:string){
        this.loading = true;
        const response = await ClientContactService.find(clientId, contactId);
        if (response.success) {
            const contact = response.data
            if(contact){
                this.setContact(contact)
            }
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
    }


    // ******** MANAGE CLIENT AND CONTACT ********
    @action
    public setClient(client?: Client) {
        this.selectedClient = client;
    }
    @action
    public setContact(contact?: ClientContact) {
        this.selectedContact = contact;
    }

    @action
    public setObservations(observations?: string) {
        this.observations = observations;
    }


    // ******** MANAGE CATEGORIES AND ITEMS ******** 
    @action
    public async searchCustomCategories(): Promise<void> {
        this.loading = true;
        const response = await CustomCategoryService.get(1, 20, '', true);
        if (response.success) {
            this.customCategories = response.data?.data || [];
            if(this.customCategories?.length>0){
                this.selectedCustomCategory = this.customCategories[0]
            }
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
    }

    public async setSelectedCustomCategory( customCategoryId?: String) {
        this.selectedCustomCategory = this.customCategories.find(a => a.id === customCategoryId);
    }

    @action
    public async save(packageItem:PackageItem){
        if(packageItem.id===""){
            this.loading = true
            const newInventoryItem = this.mapInventoryItem(packageItem)
            const response = await InventoryItemService.create(newInventoryItem)

            this.commonStore.processErrors(response)
            this.processError(response.error);
            if(response.success){
                packageItem.id = response.data.id
                await this.saveToList(packageItem)
            }
            this.loading = false;
        }else{
            await this.saveToList(packageItem)
        }
        
    }   
    
    //If item did not exist it creates it in the Inventory previously
    @action
    public cancelItemTemp(){
        this.tempPackageItem = undefined
    }   //Erase temp info 

    private mapInventoryItem(packageItem:PackageItem):InventoryItem{
        const categoryInfo = new CategoryInfo(this.selectedCustomCategory?.id,this.selectedCustomCategory?.name,this.selectedCustomCategory?.categoryType)
        const item = {} as InventoryItem
        item.name = packageItem.name
        item.categoryInfo = categoryInfo
       // item.customPrice = packageItem.finalCustomPrice
        //item.toConsiderPrice = packageItem.finalToConsiderPrice
        item.price = packageItem.finalItemPrice
        item.comments = packageItem.comments

        return item
    }   
    
    //Maps a packageItem into an InventoryItem to be saved in the inventory
    @action
    private async saveToList(packageItem:PackageItem){
        const categoryPosition = this.itemsAndCategories.findIndex(t => t.categoryInfo.name === this.selectedCustomCategory?.name);
        if (categoryPosition!==-1) {
            //Adding the packageItem to the existing category
            this.itemsAndCategories[categoryPosition].packageItem.unshift(packageItem)
            this.updateSelfCategory(categoryPosition)
        } else {
            // Create a new category and add the packageItem to it
            const newCatInfo = new CategoryInfo(this.selectedCustomCategory?.id,this.selectedCustomCategory?.name,this.selectedCustomCategory?.categoryType)
            let newCat = new ItemsAndCategories(newCatInfo, [packageItem])
            this.itemsAndCategories.unshift(newCat);
            this.updateSelfCategory(0)
        }

        this.updateCategoriesWithItems()
        this.tempPackageItem = undefined
    }   //If the category exists it adds the item to it, else it creates the category first then it adds the item to it
    
    
    @action
    public removePackageItem(packageItem?:PackageItem, categoryId?:string){
        const categoryPosition = this.itemsAndCategories.findIndex(e=> e.categoryInfo.id===categoryId)
        const itemPosition = this.itemsAndCategories[categoryPosition].packageItem.findIndex(e=>e.id===packageItem?.id)

        this.itemsAndCategories[categoryPosition].packageItem.splice(itemPosition,1)
        this.updateSelfCategory(categoryPosition)
        this.updateCategoriesWithItems()
    }    //Removes an item from a given category
    
    
    @action
    public editPackageItem(packageItem?:PackageItem, categoryId?:string){
        const categoryPosition = this.itemsAndCategories.findIndex(e=> e.categoryInfo.id===categoryId)
        const itemPosition = this.itemsAndCategories[categoryPosition].packageItem.findIndex(e=>e.id===packageItem?.id)

        this.itemsAndCategories[categoryPosition].packageItem.splice(itemPosition,1)
        this.updateSelfCategory(categoryPosition)
        this.updateCategoriesWithItems()
        this.tempPackageItem = packageItem
    }    //Removes an item from a given category and give it to be edited
    
    
    private updateSelfCategory(categoryWithItemPosition:number){
        if(this.itemsAndCategories[categoryWithItemPosition].packageItem?.length===0){
            //If hte category has no elements remove the current category from the list
            this.itemsAndCategories.splice(categoryWithItemPosition,1)
            this.updateGeneralPrice()
        }else{
            //1-Actualizar el subFinal y el final Individual
            let oldSubFinalIndividualPrice = this.itemsAndCategories[categoryWithItemPosition].subFinalIndividualPrice
            let newSubFinalIndividualPrice = 0
            this.itemsAndCategories[categoryWithItemPosition].packageItem.forEach(item=> newSubFinalIndividualPrice += item.finalItemPrice)
            if(oldSubFinalIndividualPrice !== newSubFinalIndividualPrice){
                this.updateSubFinalIndividualPrice(categoryWithItemPosition, newSubFinalIndividualPrice)
            }
        }
    }   //Goes through a category and updates the individual price if needed
    private updateSubFinalIndividualPrice(categoryWithItemPosition:number, subFinalIndividualPrice:number){
        this.itemsAndCategories[categoryWithItemPosition].subFinalIndividualPrice = subFinalIndividualPrice
        this.itemsAndCategories[categoryWithItemPosition].finalIndividualPrice = subFinalIndividualPrice
        this.getFinalCategoryPrice(categoryWithItemPosition)
    }   //Updates subFinalIndividualPrice and finalIndividualPrice and then the SubFinalPrice and the Final Price
    private getFinalCategoryPrice(categoryWithItemPosition:number){
        const subFinalPrice = (this.itemsAndCategories[categoryWithItemPosition].subFinalIndividualPrice || 0) + (this.itemsAndCategories[categoryWithItemPosition].weightInfo?.subWeightPrice || 0) 
        const finalPrice = (this.itemsAndCategories[categoryWithItemPosition].finalIndividualPrice || 0) + (this.itemsAndCategories[categoryWithItemPosition].weightInfo?.finalWeightPrice || 0) 
        this.itemsAndCategories[categoryWithItemPosition].subFinalPrice = subFinalPrice
        this.itemsAndCategories[categoryWithItemPosition].finalPrice = finalPrice
        this.updateGeneralPrice()
    }   //Recalculates te final price for the category
    @action
    public updateCategoryFinalIndividualPrice(finalIndividualPrice?:number, categoryId?:string){
        const categoryPosition = this.itemsAndCategories.findIndex(e=> e.categoryInfo.id===categoryId)
        this.itemsAndCategories[categoryPosition].finalIndividualPrice = finalIndividualPrice
        this.getFinalCategoryPrice(categoryPosition)
    }   //Updates final individual Price from user input
    @action
    public async setNewCategoryWeight(newWeight?: number, categoryId?: string) {
        await this.getWeightPrice(newWeight!!, categoryId!!)
    }   //Exposes the function to set the new Weight
    private async getWeightPrice(newWeight:number,categoryId:string):Promise<void>{
        const categoryPosition = this.itemsAndCategories.findIndex(e=> e.categoryInfo.id===categoryId)
        const response = await CustomCategoryWeightRangeService.getPriceFromWeight(newWeight,categoryId)
        if(response.success){
            runInAction(()=>{
                const range = response.data!!
                const computedPrice = parseFloat(parseFloat((range.price*newWeight).toFixed(3)).toFixed(2))
                this.itemsAndCategories[categoryPosition].weightInfo = new WeightInfo(newWeight,range,computedPrice,computedPrice)
            })
        }else{
            runInAction(()=>{
                const range = new CustomCategoryWeightRange("",0,0,0,0)
                const computedPrice = parseFloat(parseFloat((range.price*newWeight).toFixed(3)).toFixed(2))
                this.itemsAndCategories[categoryPosition].weightInfo = new WeightInfo(newWeight,range,computedPrice,computedPrice)
            })
        }
        this.commonStore.processErrors(response)
        this.processError(response.error);
        //Just update the weight and the final price
        this.updateWeightPrice(categoryPosition)
    }   //When weight changes it searches for the new weightRange and updates the new weightInfo
    @action
    private updateWeightPrice(categoryPosition:number){
        this.getFinalCategoryPrice(categoryPosition)
    }
     @action
    public updateFinalWeightPrice(finalPrice?:number, categoryId?:string){
        const categoryPosition = this.itemsAndCategories.findIndex(e=> e.categoryInfo.id===categoryId)
        if (this.itemsAndCategories[categoryPosition].weightInfo!=null){
            this.itemsAndCategories[categoryPosition].weightInfo!!.finalWeightPrice = finalPrice!!
        }
        this.getFinalCategoryPrice(categoryPosition)
    } 
    @action
    public updateCategoryFinalPrice(finalPrice?:number, categoryId?:string){
        const categoryPosition = this.itemsAndCategories.findIndex(e=> e.categoryInfo.id===categoryId)
        if(this.itemsAndCategories[categoryPosition].weightInfo!=null){
            this.itemsAndCategories[categoryPosition].weightInfo!!.finalWeightPrice = finalPrice || 0
        }
        this.itemsAndCategories[categoryPosition].finalPrice = finalPrice

        this.updateGeneralPrice()
    }   //Updates final categoryPrice from user input
    @action
    private updateGeneralPrice(){
        let newGeneralPrice = 0
        this.itemsAndCategories.forEach(category=> newGeneralPrice += category.finalPrice || 0 )
        this.finalPackagePrice = newGeneralPrice
        this.totalToPay = this.finalPackagePrice
    }   //Goes through all categories and updates generalPrice and price to Pay
    @action
    private updateCategoriesWithItems(){
        const newInfo = this.itemsAndCategories
        this.itemsAndCategories = []
        this.itemsAndCategories = newInfo
    }   //Makes UI react to an update in categories and items


    





/*     private updateFinalPrice(categoryWithItemPosition:number){
        const finalPrice = (this.itemsAndCategories[categoryWithItemPosition].weightInfo?.finalWeightPrice || 0) + (this.itemsAndCategories[categoryWithItemPosition].subFinalPrice || 0)
        this.itemsAndCategories[categoryWithItemPosition].subFinalPrice = finalPrice
        this.itemsAndCategories[categoryWithItemPosition].finalPrice = finalPrice
        this.updateGeneralPrice()
    }   //Gets the real finalPrice for the category */



    ////////////////////////////
    
    /////////////////////////////

/*     private updateFinalCategoryWeightPrice(categoryWithItemPosition:number){
        this.getFinalCategoryPrice(categoryWithItemPosition)
    } */

    /////////////////////////////
    //////////////////////////////

    // ******** MANAGE ADDITIONAL PRICES ********
    public getAdditionalPriceTotal(): number {
        return this.additionalPrices.reduce((sum, current) => sum + current.amount, 0);
    }
    @action
    public addAdditionalPrice(currency: string, amount: number, comment: string) {
        this.additionalPrices.push(new OrderAdditionalPrice(amount, comment, false));
    }
    @action
    public removeAdditionalPrice(additionalPrice: OrderAdditionalPrice) {
        this.additionalPrices = this.additionalPrices.filter(t => t.amount !== additionalPrice.amount && t.comment !== additionalPrice.comment);
    }


    // ******** MANAGE PAYMENTS ********
    // TODO: tipar payments y alinear con el componetne interno
    @action
    public setPayments(payments: any, paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
        if (paymentCompleted){
            //this.createPackageDelivery()
        }
    }
}

export default NewPackageDeliveryStore;
