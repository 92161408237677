import {action, makeAutoObservable} from "mobx";
import PaymentType from "../../../entities/PaymentType";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import Currency from "../../../entities/Currency";
import PaymentTypeResponse from "../../../model/payment/PaymentTypeResponse";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import OrderPaymentResponse from "../../../model/payment/OrderPaymentResponse";

class AddPaymentStore {

    public payments: OrderPaymentResponse[];
    public additionalPrices: OrderAdditionalPrice[];
    public selectedPrice: number;
    public error?: string;
    public total: number;
    public selectedPaymentType: PaymentTypeResponse;

    public userId?: string;


    constructor(
        total: number,
        currentAdditionalPrices: OrderAdditionalPrice[],
        userId?: string,
        currentPayments?: OrderPaymentResponse[],
    ) {
        this.total = total;
        this.userId = userId;
        this.payments = [];
        this.additionalPrices = [...currentAdditionalPrices];
        this.selectedPaymentType = new PaymentTypeResponse(0, PaymentType.CASH, false);
        this.selectedPrice = this.getRemaining;
        if(currentPayments){
            this.addAllPayments(currentPayments)
        }
        makeAutoObservable(this);
    }
    
    @action
    private addAllPayments(allPayments: OrderPaymentResponse[]){
        allPayments.forEach(element => {
            const payment = new OrderPaymentResponse(element.amount, element.currency, element.paymentTypeResponse, element.createdAt, element.userId, element.isEditable);
            this.payments.push(payment);
        })
        this.selectedPrice = this.getRemaining;
        if (this.getRemaining < 0) {
            this.error = "OLD_OVERPAYMENT";
            this.selectedPrice = this.getRemaining;
            return;
        }
    }

    @action
    public addPayment() {
        if (this.getRemaining < this.selectedPrice) {
            this.error = "OVERPAYMENT";
            this.selectedPrice = this.getRemaining;
            return;
        }
        const current = this.payments.find(t =>
            t.paymentTypeResponse.paymentType === this.selectedPaymentType.paymentType && t.isEditable && t.userId === this.userId
        );
        if (current) {
            current.amount += this.selectedPrice;
            this.payments = [...this.payments];
        } else {
            const payment = new OrderPaymentResponse(this.selectedPrice, Currency.DOLLAR, this.selectedPaymentType, 0, this.userId ?? '', true);
            this.payments.push(payment);
        }
        this.error = undefined;
        this.selectedPrice = this.getRemaining;
    }

    @action
    public removePayment(payment: AddPaymentRequest) {
        const payments = this.payments.filter(t => t.paymentTypeResponse !== payment.paymentTypeResponse);
        this.payments = [...payments];
        this.error = undefined;
        this.selectedPrice = this.getRemaining;
    }

    get isEmpty(): boolean {
        return this.payments.length === 0;
    }

    get getRemaining(): number {
        return this.total - this.getPaymentsSum;
    }

    get getPaymentsSum(): number {
        return this.payments.reduce((a, i) => a + i.amount, 0);
    }

    get getFeesSum(): number {
        return this.payments.reduce((a, i) => a + i.getExtraFee, 0);
    }

    get getTotalToPay(): number {
        return (this.total) + this.getFeesSum + this.getAdditionalPriceSum;
    }

    get getAdditionalPriceSum(): number {
        return this.additionalPrices.reduce((sum, item) => {
            if (item.isPercentage) {
                return sum + (item.amount / 100) * this.total;
            } else {
                return sum + item.amount;
            }
        }, 0);
    }

    get canAddMorePayments(): boolean {
        return this.getPaymentsSum < this.total;
    }

    // Additional prices

    @action
    public addAdditionalPrice(amount: number, comment: string, isPercentage: boolean) {
        const additionalPrice = new OrderAdditionalPrice(amount, comment, isPercentage);
        this.additionalPrices.push(additionalPrice);
    }

    @action
    public removeAdditionalPrice(amount: number, comment: string, isPercentage: boolean) {
        const additionalPrices = this.additionalPrices.filter(t => t.amount !== amount && t.comment !== comment && t.isPercentage !== isPercentage);
        this.additionalPrices = [...additionalPrices];
    }
}

export default AddPaymentStore;