import Phone from "../entities/Phone";

function formatCubanPhone(number: string | undefined, showCountryCode: boolean = false): string | undefined {
    if (!number) return undefined;
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (match) {
        const code = showCountryCode ? "+53 " : "";
        return `${code}${match[1]}${match[2]}${match[3]}${match[4]}`;
    }
    return number
}

function formatUSAPhone(number: string | undefined, showCountryCode: boolean = false): string | undefined {
    if (!number) return undefined;
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const code = showCountryCode ? "+1 " : "";
        return `${code}(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return number
}

export function format(phone?: Phone, showCountryCode: boolean = false): string | undefined {
    if (!phone) return undefined;
    switch (phone.countryCode.toString()) {
        case "CUBA":
            return formatCubanPhone(phone.number, showCountryCode);
        case "USA":
            return formatUSAPhone(phone.number, showCountryCode);
    }
    return phone.number;
}