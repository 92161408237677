import React from 'react';
import IconProperties from "./IconProperties";


const ZelleIcon: React.FC<IconProperties> = ({
                                                       className,
                                                       fontSize
                                                   }) => {
    return (
        <svg className={className} viewBox="0 0 493.3 490.2" x="0px" y="0px" width="1em" height="1em"
             style={{fontSize: fontSize}}>
            <g><path d="m245.4 20c-124.3 0-225.4 101.1-225.4 225.4s101.1 225.4 225.4 225.4 225.4-101.1 225.4-225.4-101.1-225.4-225.4-225.4zm0 413.6c-103.8 0-188.2-84.4-188.2-188.2s84.4-188.2 188.2-188.2 188.2 84.4 188.2 188.2-84.4 188.2-188.2 188.2z"/><path d="m325.8 175.1v-34.3h-61.5v-37.8h-37.8v37.8h-61.5v45.5h95.4l-95.4 129.4v34.3h61.5v37.6h37.8v-37.6h61.5v-45.5h-95.4z"/></g>

        </svg>
    )
}
export default ZelleIcon;