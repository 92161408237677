import React from 'react';
import ShowArticleAmountProperties from "./ShowArticleAmountProperties";
import {useStyles} from "./ShowArticleAmount.style";
import clsx from "clsx";

const ShowArticleAmount: React.FC<ShowArticleAmountProperties> = ({
                                                      value,
                                                      type,
                                                      valueBefore,
                                                      articleName,
                                                      className = '',
                                                  }) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div className={clsx(
                classes.number,
                (value >= 0 || type === 'positive') && classes.positive,
                (value < 0 || type === 'negative') && classes.negative,
            )}>{value &&
            <>
                <span>{value}</span>
            </>
            }</div>
            <div className={classes.article_name}>{articleName}</div>
            <div className={classes.value_before}>{!!valueBefore &&
            <>{'Cantidad anterior'} {valueBefore}</>
            }</div>
        </div>
    )
}


export default ShowArticleAmount;