import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import MoneyDeliveryFee from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryFee";
import {MoneyServiceFeeService} from "../../../service/moneyDelivery/MoneyServiceFeeService";
import Currency from "../../../entities/Currency";

const ERROR_MAP = {
    change: ["ERROR_00_400_35", "ERROR_00_400_35"],
    fromToValue: ["ERROR_00_500_57"],
}

class NewMoneyDeliveryFeeStore {

    public moneyServiceFeeId?: string;
    public moneyServiceFee: MoneyDeliveryFee;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public changeError?: string;
    public fromError?: string;
    public toError?: string;

    constructor(commonStore: CommonStore, moneyServiceFeeId?: string) {
        this.isEditing = !!moneyServiceFeeId;
        this.moneyServiceFeeId = moneyServiceFeeId;
        this.isModalOpen = false;
        this.moneyServiceFee = {
            currenciesValues: [{
                currency: Currency.DOLLAR,
                isPercentage: true,
                value: 0
            }, {
                currency: Currency.MLC,
                isPercentage: true,
                value: 0
            }, {
                currency: Currency.MN,
                isPercentage: true,
                value: 0
            }]
        } as MoneyDeliveryFee;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.moneyServiceFee?.fromValue) && !!(this.moneyServiceFee?.toValue);
    }

    @action
    public async loadMoneyServiceFee() {
        if (this.moneyServiceFeeId) {
            this.isEditing = true;
            const response = await MoneyServiceFeeService.find(this.moneyServiceFeeId!);
            if (response.success && response.data) {
                this.moneyServiceFee = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadMoneyServiceFee();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? MoneyServiceFeeService.update : MoneyServiceFeeService.create;
        const response = await serviceToCall(this.moneyServiceFee);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.changeError = undefined;
        this.fromError = undefined;
        this.toError = undefined;
        if (error) {
            if (ERROR_MAP.change.includes(error)) {
                this.changeError = error;
            }
            if (ERROR_MAP.fromToValue.includes(error)) {
                this.fromError = error;
            }
            if (ERROR_MAP.fromToValue.includes(error)) {
                this.toError = error;
            }
        }

    }
}

export default NewMoneyDeliveryFeeStore;