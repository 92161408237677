import React from 'react';
import {useStyles} from "./RoundedButton.style";
import RoundedButtonProperties from "./RoundedButtonProperties";
import clsx from "clsx";
import ButtonType from "../Button/ButtonType";
import {Tooltip} from "@material-ui/core";
import ButtonSize from "../Button/ButtonSize";


const RoundedButton: React.FC<RoundedButtonProperties> = ({
                                                              className = '',
                                                              icon = <></>,
                                                              endIcon = <></>,
                                                              text,
                                                              disabled = false,
                                                              type = ButtonType.DEFAULT,
                                                              tooltip,
                                                              onClick,
                                                              size = ButtonSize.DEFAULT,
                                                              loading = false
                                                          }) => {
    const classes = useStyles(!!text);

    const getSizeClass = () => {
        switch (size) {
            default:
            case ButtonSize.DEFAULT:
                return classes.medium;
            case ButtonSize.SMALL:
                return classes.small;
            case ButtonSize.LARGE:
                return classes.large;
        }
    }
    const getTypeClass = () => {
        switch (type) {
            default:
            case ButtonType.DEFAULT:
                return classes.default;
            case ButtonType.PRIMARY:
                return classes.primary;
            case ButtonType.SECONDARY:
                return classes.secondary;
        }
    }

    const containerClasses = clsx(
        classes.rounded_button_container,
        disabled && classes.disabled,
        getSizeClass(),
        getTypeClass(),
        className
    );
    if (text) {
        return (
            <div className={containerClasses}
                 onClick={onClick}>
                {icon}
                <div>{text}</div>
                {endIcon}
            </div>
        )
    }
    return (
        <Tooltip title={tooltip || ""}>
            <div className={containerClasses}
                 onClick={onClick}>
                {icon}
            </div>
        </Tooltip>
    )
}
export default RoundedButton;