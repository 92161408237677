import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import {ArticleDeliveryService} from "../../service/articleDelivery/ArticleDeliveryService";
import ArticleDeliveryOrderResponse from "../../model/articleDelivery/ArticleDeliveryOrderResponse";
import DeliveryPerson from "../../entities/DeliveryPerson";
import Geo from "../../entities/Geo";
import ClientContact from "../../entities/ClientContact";
import Agency from "../../entities/Agency";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";

class ArticleDeliveryStore {

    public articleRemittances: ArticleDeliveryOrderResponse[];
    public selectedArticleRemittanceIds: string[];
    public selectedArticleRemittanceId: string;
    public pagination: Pagination;

    public filter?: string;
    public statusFilter?: string[];
    public deliveryPersonFilter?: DeliveryPerson;
    public fromFilter: number | undefined;
    public toFilter: number | undefined;
    public agencyFilter?: Agency;
    public geoFilter?: Geo;

    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.articleRemittances = [];
        this.selectedArticleRemittanceIds = [];
        this.selectedArticleRemittanceId = '';
        this.commonStore = commonStore;
        makeObservable(this, {
            articleRemittances: observable,
            selectedArticleRemittanceIds: observable,
            pagination: observable,
            agencyFilter: observable,
            isEmpty: computed
        });

    }

    get isEmpty(): boolean {
        return this.articleRemittances.length === 0;
    }

    get haveNotSelected(): boolean {
        return this.selectedArticleRemittanceIds.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    public setStatusToFilter(selectedStatus?: string[]) {
        this.statusFilter = selectedStatus;
    }

    public setDeliveryPersonToFilter(deliveryPersonFilter?: DeliveryPerson) {
        this.deliveryPersonFilter = deliveryPersonFilter;
    }

    public setFromFilter(from?: number) {
        this.fromFilter = from;
    }

    public setToFilter(to?: number) {
        this.toFilter = to;
    }

    @action
    public async search() {
        await this.getElements(1);
    }

    @action
    public async clearFilter() {
        this.deliveryPersonFilter = undefined;
        this.statusFilter = undefined;
        this.geoFilter = undefined;
        this.fromFilter = undefined;
        this.toFilter = undefined;
        this.agencyFilter = undefined;
        this.filter = undefined;
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await ArticleDeliveryService.get(
            page, pageSize, this.fromFilter, this.toFilter, this.filter, this.deliveryPersonFilter?.id,
            this.statusFilter, this.geoFilter?.id, this.agencyFilter?.id);
        if (response.success) {
            runInAction(() => {
                this.articleRemittances = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async updateArticleRemittanceContact(contact: ClientContact) {
        this.commonStore.setLoading();
        // const response = await ArticleDeliveryService.updateClientPerson(this.selectedArticleRemittanceId, contact.id);
        // if (response.success) {
        //     runInAction(() => {
        //         const tempArticleRemittance = this.articleRemittances.find(t => t.id === this.selectedArticleRemittanceId);
        //         const tempArticleRemittanceIndex = this.articleRemittances.findIndex(t => t.id === this.selectedArticleRemittanceId);
        //         if (tempArticleRemittance != null) {
        //             this.articleRemittances[tempArticleRemittanceIndex] = {
        //                 ...tempArticleRemittance,
        //                 contactId: contact.id,
        //                 contactName: contact.name
        //             };
        //         }
        //     })
        // }
        // this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async setArticleRemittanceDeliveryPerson(articleDeliveryIds: string[], deliveryPerson: DeliveryPerson) {
        this.commonStore.setLoading();
        const response = await ArticleDeliveryService.setDeliveryPerson(articleDeliveryIds, deliveryPerson.id);
        if (response.success) {
            runInAction(() => {
                for (const articleDeliveryId of articleDeliveryIds) {
                    const tempArticleRemittance = this.articleRemittances.find(t => t.id === articleDeliveryId);
                    const tempArticleRemittanceIndex = this.articleRemittances.findIndex(t => t.id === articleDeliveryId);
                    if (tempArticleRemittance != null) {
                        this.articleRemittances[tempArticleRemittanceIndex] = {
                            ...tempArticleRemittance,
                            status: ProductOrderStatus.ASSIGNED,
                            deliveryPersonId: deliveryPerson.id,
                            deliveryPersonName: deliveryPerson.name
                        };
                    }
                }
                this.selectedArticleRemittanceIds = [];
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async unsetArticleRemittanceDeliveryPerson() {
        this.commonStore.setLoading();
        const response = await ArticleDeliveryService.unsetDeliveryPerson(this.selectedArticleRemittanceId);
        if (response.success) {
            runInAction(() => {
                const tempArticleRemittance = this.articleRemittances.find(t => t.id === this.selectedArticleRemittanceId);
                const tempArticleRemittanceIndex = this.articleRemittances.findIndex(t => t.id === this.selectedArticleRemittanceId);
                if (tempArticleRemittance != null) {
                    this.articleRemittances[tempArticleRemittanceIndex] = {
                        ...tempArticleRemittance,
                        status: ProductOrderStatus.PENDING,
                        deliveryPersonId: null,
                        deliveryPersonName: null
                    };
                }
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }
}

export default ArticleDeliveryStore;