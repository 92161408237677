import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.grey[300],
        height: 'calc( 100vh - 250px)'
    },
    selected_food_packages_container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gridColumnGap: '10px',
        gridRowGap:' 10px',
        backgroundColor: '#ffffff'
    },
    no_food_packages_title: {
        fontSize: "20px",
        textAlign: "center",
        margin: "10px 10px 25px"
    }
}));

export {useStyles}