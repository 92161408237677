import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    header_container: {
        backgroundColor: `${theme.palette.secondary.main}cf`,
        padding: '8px',
        boxSizing: 'border-box',
        width: '100%',
        height: '50px',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rigth_btn_container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        marginTop: '4px'
    }
}));

export {useStyles}