import Request from "../../model/Request";
import Response from "../../model/Response";
import { RestClient } from "../../util/RestClient";
import { Config } from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import ShopOrderResponse from "../../model/shop/ShopOrderResponse";
import Phone from "../../entities/Phone";
import ShopOrderRequest from "../../model/shop/ShopOrderRequest";

export module ShopOrderService {

  export async function get(
    page: number = 1,
    pageSize: number = 15,
    from: number = 0,
    to: number = 0,
    filter?: string,
    status?: string[],
    agencyId?: string
  ): Promise<Response<PagedResponse<ShopOrderResponse>>> {
    const url = Config.get("GET_SHOP_ORDER_URL", {
      page,
      pageSize,
      from,
      to,
      filter,
      status,
      agencyId,
    });
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const resultData = response.data?.data;
      const paginationData = response.data?.pagination;
      if (resultData && paginationData) {
        const data = resultData.map(
          (t: any) =>
            new ShopOrderResponse(
              t.id,
              t.orderNumber,
              t.agencyId,
              t.agencyName,
              t.clientId,
              t.clientName,
              t.clientPhone
                ? new Phone(
                    t.clientPhone.number,
                    t.clientPhone.countryCode,
                    t.clientPhone.phoneType
                  )
                : null,
              t.userId,
              t.userName,
              t.totalToPay,
              t.status,
              t.createdAt,
              t.products
            )
        );
        const pagination = new Pagination(
          paginationData.currentPage,
          paginationData.pageSize,
          paginationData.elementsCount,
          paginationData.pageCount
        );
        return new Response<PagedResponse<ShopOrderResponse>>(
          true,
          { data, pagination },
          response.error
        );
      }
    }
    return new Response<PagedResponse<ShopOrderResponse>>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function create(
    shopOrderOrder: ShopOrderRequest
  ): Promise<Response<any>> {
    const url = Config.get("CREATE_SHOP_ORDER_URL");
    const request = new Request(url, shopOrderOrder);
    return await RestClient.post(request);
  }

  export async function remove(shopOrderId: string): Promise<Response<any>> {
    const url = Config.get("REMOVE_SHOP_ORDER_URL", {id: shopOrderId});
    const request = new Request(url);
    return await RestClient.remove(request);
  }
}
