import React from "react";
import {useLocation} from "react-router";
import {useI18nContext} from "../../contexts/I18nContext";
import PasswordInput from "../../shared_components/Inputs/PasswordInput/PasswordInput";
import Button from "../../ui_components/Button/Button";
import ButtonType from "../../ui_components/Button/ButtonType";
import {useCommonStyles} from "../../ui_components/common/CommonStyles.style";
import {useStyles} from "./ChangePassword.style";
import Direction from "../../ui_components/common/enums/Direction";
import FlexContainer from "../../ui_components/FlexContainer/FlexContainer";
import TopupAgenciesText from "../../ui_components/Icon/icons/TopupAgenciesText";
import ChangePasswordStore from "./ChangePasswordStore";
import {useCommonContext} from "../../contexts/CommonContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from "clsx";
import {Observer} from "mobx-react-lite";
import LoginIcon from '@mui/icons-material/Login';
import Link from "../../ui_components/Link/Link";

const ChangePassword: React.FC = () => {
    const commonClasses = useCommonStyles();

    const i18n = useI18nContext();
    const location = useLocation();
    const commonStore = useCommonContext();
    const code = new URLSearchParams(location.search).get("code");
    const store = new ChangePasswordStore(commonStore, code || undefined)

    const classes = useStyles();

    const onSave = async () => {
        await store.changePassword()
    };

    const getClass = (key: string) => {
        return clsx(
            classes.check_item_container,
            store.validate(key) && classes.valid)
    }

    return (
        <Observer>
            {() =>
                (<FlexContainer
                    direction={Direction.COLUMN}
                    fullHeight
                    className={classes.page_container}
                >
                    <div className={classes.form_container}>
                        <div className={classes.header}>
                            <TopupAgenciesText fontSize={120}/>
                        </div>

                        <div className={classes.body}>
                            <PasswordInput
                                className={commonClasses.marginTop}
                                onChange={(p) => store.password = p}
                                value={store.password}
                            />
                            <PasswordInput
                                className={commonClasses.marginTop}
                                onChange={(p) => store.confirmPassword = p}
                                value={store.confirmPassword}
                            />

                            <Button
                                className={commonClasses.marginTop}
                                loading={store.loading}
                                fullWidth
                                type={ButtonType.SECONDARY}
                                onClick={() => onSave()}
                                text={i18n.translate("CHANGE")}
                                disabled={!store.isEnable}
                            />
                            <div className={classes.check_list_container}>
                                <div className={getClass("LENGTH")}>
                                    <CheckCircleIcon/>
                                    <div>{i18n.translate("PASSWORD_LENGTH_DESCRIPTION")}</div>
                                </div>
                                <div className={getClass("UPPERCASE")}>
                                    <CheckCircleIcon/>
                                    <div>{i18n.translate("PASSWORD_UPPERCASE_DESCRIPTION")}</div>
                                </div>
                                <div className={getClass("LOWERCASE")}>
                                    <CheckCircleIcon/>
                                    <div>{i18n.translate("PASSWORD_LOWERCASE_DESCRIPTION")}</div>
                                </div>
                                <div className={getClass("NUMBER")}>
                                    <CheckCircleIcon/>
                                    <div>{i18n.translate("PASSWORD_NUMBER_DESCRIPTION")}</div>
                                </div>
                                <div className={getClass("EQUALS")}>
                                    <CheckCircleIcon/>
                                    <div>{i18n.translate("PASSWORD_EQUALS_DESCRIPTION")}</div>
                                </div>
                            </div>
                            <Link startIcon={LoginIcon} className={commonClasses.marginTop}
                                  text={i18n.translate("GO_TO_LOGIN")} to={'/login'}/>
                        </div>
                    </div>
                </FlexContainer>)
            }
        </Observer>
    );
};

export default ChangePassword;
