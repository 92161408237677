import React from 'react';
import SellerSalesOverviewButtonProperties from "./SellerSalesOverviewButtonProperties";
import SellerSalesOverviewButtonStore from "./SellerSalesOverviewButtonStore";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./SellerSalesOverviewButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import AgencyTotalSales from "../../Widget/AgencyTotalSales/AgencyTotalSales";
import TabOption from "../../../ui_components/Tab/TabOption";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import MoneyDeliverySales from "../../Widget/MoneyDeliverySales/MoneyDeliverySales";
import Tabs from "../../../ui_components/Tab/Tabs";
import TopupSales from "../../Widget/TopupSales/TopupSales";
import ArticleSales from '../../Widget/ArticleSales/ArticleSales';
import MiscellanySales from '../../Widget/MiscellanySales/MiscellanySales';


const SellerSalesOverviewButton: React.FC<SellerSalesOverviewButtonProperties> = ({
                                                                                      onClick = () => {
                                                                                      },
                                                                                      onSave = () => {
                                                                                      },
                                                                                      showIcon = true,
                                                                                      showText = true,
                                                                                      disabled,
                                                                                      className = '',
                                                                                      sellerId,
                                                                                      from,
                                                                                      to,
                                                                                      rounded = false,
                                                                                      size = ButtonSize.DEFAULT,
                                                                                      type = ButtonType.DEFAULT
                                                                                  }) => {
    const classes = useStyles(showText);

    const commonContext = useCommonContext();
    const store = new SellerSalesOverviewButtonStore(commonContext, sellerId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const title = `Resumen de ventas`;
    const Icon = AttachMoneyIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? title : ""}
                text={showText ? title : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? title : ""}
            className={className}
            tooltip={!showText ? title : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => {
            const tabsContent = [
                new TabOption("Entrega de dinero", <MoneyDeliverySales from={from} to={to} sellerId={sellerId}/>, <NewspaperIcon/>),
                new TabOption("Artículos", <ArticleSales from={from} to={to} sellerId={sellerId}/>, <NewspaperIcon/>),
                new TabOption("Recargas", <TopupSales from={from} to={to} sellerId={sellerId}/>, <NewspaperIcon/>),
                new TabOption("Misceláneas", <MiscellanySales from={from} to={to} sellerId={sellerId}/>, <NewspaperIcon/>),
            ];
            return (
                <>
                    <SidePanel loading={store.loading} title={title} onClose={onCloseModal} open={store.isModalOpen}
                               className={classes.modal_container}>
                        <div className={classes.body_container}>
                           <div className={classes.header_container}>
                                <AgencyTotalSales
                                     from={from}
                                     to={to}
                                     title={"Total de ventas"}
                                     inline
                                     withBorder
                                     userId={sellerId}/>
                           </div>
                            <Tabs content={tabsContent}/>

                        </div>
                    </SidePanel>
                    <Btn/>
                </>
            );
        }}
    </Observer>
}

export default SellerSalesOverviewButton;
