import React from 'react';
import {useStyles} from "../TopupPrices.style";
import clsx from "clsx";
import TopupPriceRowProperties from "./TopupPricesRowProperties";
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import NewTopupPriceButton from '../../../shared_components/Buttons/NewTopupPriceButton/NewTopupPriceButton';
import RemoveTopupPriceButton from '../../../shared_components/Buttons/RemoveTopupPriceButton/RemoveTopupPriceButton';
import ShowProduct from '../../../shared_components/Info/ShowProduct/ShowProduct';

const TopupPriceRowSmall: React.FC<TopupPriceRowProperties> = ({topupPrice, products, onUpdate}) => {

    const classes = useStyles();


    return (
        <div className={clsx(classes.topup_price_info_container_small)}>
            <ShowProduct value={products.find((t) => t.id === topupPrice.topupProductId)}/>
            <ShowText text={topupPrice.amount ? '$' + topupPrice.amount.toString() : '-'} title={'Valor de venta'}/>          
            <div className={classes.menu_container_small}>
                    <NewTopupPriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       showText={false}
                       topupPriceId={topupPrice.id}
                       type={ButtonType.SECONDARY}
                   />
                   <RemoveTopupPriceButton
                       size={ButtonSize.SMALL}
                       onSave={onUpdate}
                       topupPrice={topupPrice}
                       showText={false}
                       type={ButtonType.SECONDARY}
                   />
            </div>
        </div>
    )
}

export default TopupPriceRowSmall;
