import InventoryItem from "../../../model/packageDelivery/inventoryItem/InventoryItem"
import CommonStore from "../../../stores/CommonStore"
import {action, makeAutoObservable} from "mobx"
import {InventoryItemService} from "../../../service/packageDelivery/InventoryItem/InventoryItemService"
import Response from "../../../model/Response"
import CustomCategory from "../../../model/packageDelivery/customCategory/CustomCategory";
import CategoryInfo from "../../../model/packageDelivery/inventoryItem/CategoryInfo";
import {CustomCategoryService} from "../../../service/CustomCategoryService";


const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"],
    name: ["ERROR_00_600_01"]
}

class NewInventoryItemButtonStore {

    public inventoryItemId?: string
    public inventoryItem: InventoryItem
    public isEditing: boolean
    public isModalOpen: boolean
    public loading: boolean
    public nameError?: string
    public commonStore: CommonStore
    public customCategories: CustomCategory[];
    public selectedCustomCategory: CustomCategory | undefined;

    // @ts-ignore
    constructor(commonStore: CommonStore, inventoryItemId?: string | undefined) {
        this.isEditing = !!inventoryItemId
        this.inventoryItemId = inventoryItemId
        this.isModalOpen = false
        this.inventoryItem = {} as InventoryItem
        this.loading = false
        this.customCategories=[]
        this.commonStore = commonStore
        makeAutoObservable(this)
    }

    get enableButton(): boolean {
        return !!(this.inventoryItem?.name)
    }


    @action
    public async loadInventoryItem() {
        if (this.inventoryItemId) {
            this.isEditing = true;
            const response = await InventoryItemService.find(this.inventoryItemId!)
            if (response.success && response.data) {
                this.inventoryItem = response.data;
                this.setSelectedCustomCategory(this.inventoryItem.categoryInfo.id)
            }
            this.commonStore.processErrors(response);
            this.processError(response.error);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.searchCustomCategories();
        if(this.isEditing){
            await this.loadInventoryItem();
        }
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        this.inventoryItem = {} as InventoryItem
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        let response: Response<any>;
        if (this.isEditing) {
            response = await InventoryItemService.update(this.inventoryItem);
        } else {

            response = await InventoryItemService.create(this.inventoryItem);
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    ///////Mange Category
    @action
    public async searchCustomCategories(): Promise<void> {
        this.loading = true;
        const response = await CustomCategoryService.get(1, 20, '', true);
        if (response.success) {
            this.customCategories = response.data?.data || [];
        }else{
            this.customCategories =  []
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        if (this.inventoryItem) {
           this.selectedCustomCategory = this.customCategories.find(t => t.id === this.inventoryItem.categoryInfo?.id)
        }
        this.loading = false;
    }
    public async setSelectedCustomCategory(customCategoryId?: String) {
        this.selectedCustomCategory = this.customCategories.find(a => a.id === customCategoryId)
        this.inventoryItem.categoryInfo = new CategoryInfo(this.selectedCustomCategory?.id,this.selectedCustomCategory?.name, this.selectedCustomCategory?.categoryType)
    }

    processError(error?: string) {
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error)
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }
    }
}
export default NewInventoryItemButtonStore
