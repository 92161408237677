import InventoryItemDescriptionInputProperties from "./InventoryItemDescriptionInputProperties";
import React, {useEffect, useState} from "react";
import {useI18nContext} from "../../../../contexts/I18nContext";
import Textarea from "../../../../ui_components/Textarea/Textarea";
import DescriptionIcon from "@mui/icons-material/Description";


const InventoryItemDescriptionInput:React.FC<InventoryItemDescriptionInputProperties> = ({
                                                                                 value,
                                                                                 placeholder,
                                                                                          lines = 3,
                                                                                 onChange,
                                                                                 disabled,
                                                                                 fullWidth = false,
                                                                                 className = '',
                                                                                 error
                                                                             }) => {

    const [description,setDescription] = useState(value)
    const i18n = useI18nContext()

    useEffect(() => {
        setDescription(value)
    }, [value]);

    const onNameChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newDescription = event.target.value
        setDescription(newDescription)
        onChange(newDescription)
    }

    return (
        <Textarea
            error={error}
            label={i18n.translate("INVENTORY_ITEM_DESCRIPTION")}
              value={description}
            placeholder={placeholder}
            disabled={disabled}
            lines={lines}
            onChange={onNameChange}
            startIcon={DescriptionIcon}
            className={className}
            fullWidth={fullWidth}/>
    )
}
export default InventoryItemDescriptionInput