class MoneyExchangeRate {
    public id: string;
    public agencyId: string;
    public currency: string;
    public value: number;

    constructor(id: string, agencyId: string, currency: string, value: number) {
        this.id = id;
        this.agencyId = agencyId;
        this.currency = currency;
        this.value = value;
    }
}

export default MoneyExchangeRate;