import React from 'react';
import {useStyles} from "./SidePanel.style";
import SidePanelProperties from "./SidePanelProperties";
import {Drawer} from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import PageLoading from "../PageLoading/PageLoading";
import {useI18nContext} from "../../contexts/I18nContext";
import {useCommonContext} from "../../contexts/CommonContext";


const SidePanel: React.FC<SidePanelProperties> = ({
                                                      open = false,
                                                      title,
                                                      onClose,
                                                      loading = false,
                                                      className = '',
                                                      children
                                                  }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonStore = useCommonContext();

    const onPanelClose = () => {
        commonStore.emitEvent("dialog_closed");
        onClose();
    }

    const handleBackdropClick = (event: any) => {
        event.stopPropagation();
      };

    return (
        <Drawer
            anchor="right"
            open={open}
            className={className}
            classes={{
                paper: classes.side_panel_container
            }}
            onBackdropClick={handleBackdropClick}>
            <div className={classes.panel_container}>
                <div className={classes.header_container}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.closeButton} onClick={onPanelClose}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={classes.body_container}>
                    {loading && <PageLoading isLoading={loading} text={i18n.translate("LOADING")}/>}
                    {children}
                </div>
            </div>
        </Drawer>
    )
}
export default SidePanel;