import { useToasts } from 'react-toast-notifications';

export function useSuccessToast(): (text: string) => Promise<boolean> {
    const { addToast } = useToasts()
    
    return async (text) => {
        try {
            addToast(text, {
                appearance: 'success',
                autoDismiss: true,
            });
            return true;
        } catch {
            return false;
        }
    }
}

export function useErrorToast(): (text: string) => Promise<boolean> {
    const { addToast } = useToasts()
    
    return async (text) => {
        try {
            addToast(text, {
                appearance: 'error',
                autoDismissTimeout: 10000,
                autoDismiss: true,
            });
            return true;
        } catch {
            return false;
        }
    }
}