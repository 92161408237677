import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from '../../../../../stores/CommonStore';
import Client from "../../../../../entities/Client";
import {ClientService} from "../../../../../service/ClientService";


class ClientSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public agencyId?: string;
    public clients: Client[];
    public selectedClient?: Client;
    public showSearchPanel: boolean;


    constructor(commonStore: CommonStore, selectedClient?: Client, agencyId?: string) {
        this.loading = false;
        this.commonStore = commonStore;
        this.clients = [];
        this.showSearchPanel = !selectedClient;
        this.selectedClient = selectedClient;
        this.agencyId = agencyId;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await ClientService.get(1, 20, this.filter, this.agencyId);
        if (response.success) {
            runInAction(() => {
                this.clients = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    get isEmpty(): boolean {
        return this.clients.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        runInAction(() => {
            this.filter = filter;
            this.search();
        })
    }

    @action
    public setSelectedClient(client?: Client) {
        runInAction(() => {
            this.selectedClient = client;
            if (client) {
                this.closeSearchPanel();
            }
        });
    }

    @action
    public async openSearchPanel() {
        this.showSearchPanel = true;
        this.selectedClient = undefined;
    }

    @action
    public closeSearchPanel() {
        this.filter = "";
        this.showSearchPanel = false;
    }
}

export default ClientSelectStore;