import React, {useMemo, useState} from 'react';
import ConfirmTopupButtonProperties from "./ConfirmTopupButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ConfirmTopupButtonStore from "./ConfirmTopupButtonStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import DoneAllIcon from '@mui/icons-material/DoneAll';

const ConfirmTopupButton: React.FC<ConfirmTopupButtonProperties> = ({
                                                                        onClick = () => {
                                                                        },
                                                                        onSuccess = () => {

                                                                        },
                                                                        showIcon = true,
                                                                        showText = true,
                                                                        disabled,
                                                                        className = '',
                                                                        orderId,
                                                                        rounded = false,
                                                                        size = ButtonSize.DEFAULT,
                                                                        type = ButtonType.DEFAULT
                                                                    }) => {

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const [loading, setLoading] = useState(false);
    const store = useMemo<ConfirmTopupButtonStore>(() =>
            new ConfirmTopupButtonStore(commonContext, orderId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);


    const handleOnclick = async () => {
        setLoading(true);
        await store.confirm();
        setLoading(false);
        if (onSuccess) {
            onSuccess();
        }
        onClick();
    }

    const text = i18n.translate("CONFIRM_TOPUP");

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                loading={loading}
                icon={showIcon ? <DoneAllIcon/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            loading={loading}
            startIcon={showIcon ? <DoneAllIcon/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default ConfirmTopupButton;
