import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import PhoneInputProperties from "./PhoneInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {PhoneValidation} from "../../../util/PhoneValidation";
import Phone from "../../../entities/Phone";
import CountryCode, {getPhoneCode} from "../../../entities/CountryCode";
import PhoneType from "../../../entities/PhoneType";
import CountryFlagIcon from "../../../ui_components/Icon/icons/CountryFlagIcon";

const PhoneInput: React.FC<PhoneInputProperties> = ({
                                                        value,
                                                        label = null,
                                                        required = false,
                                                        onChange,
                                                        disabled,
                                                        error,
                                                        fullWidth = false,
                                                        className = '',
                                                        countryCode = CountryCode.CUBA
                                                    }) => {

    const [phone, setPhone] = useState(value);
    const i18n = useI18nContext();
    const currentCountryCode = phone?.countryCode || countryCode;

    useEffect(() => {
        if (PhoneValidation.validate(value)) {
            setPhone(value);
            // setInputValue(value?.number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputText = event.target.value;
        const onlyNumbers = inputText.replace(/\D/g, '');
        const newPhone = new Phone(onlyNumbers, currentCountryCode, PhoneType.MOBILE)
        setPhone(newPhone);
        onChange(newPhone);
    }

    const Icon = () => {
        return <div>{`+${getPhoneCode(currentCountryCode)}`}</div>
    }

    const Flag = () => {
        return <CountryFlagIcon countryCode={currentCountryCode}/>
    }

    // const getMaxLength = (): number => {
    //     if (currentCountryCode === CountryCode.USA) return 10;
    //     if (currentCountryCode === CountryCode.BRAZIL) return 11;
    //     if (currentCountryCode === CountryCode.URUGUAY) return 8;
    //     if (currentCountryCode === CountryCode.MEXICO) return 11;
    //     if (currentCountryCode === CountryCode.BELGIUM) return 10;
    //     return 8;
    // }

    return (
        <TextInput
            label={label ? label : i18n.translate("PHONE") + `${required ? '*' : ''}`}
            error={error}
            // maxLength={getMaxLength()}
            // onFocus={onInputFocus}
            // onBlur={onInputBlur}
            value={phone?.number}
            removeSpaces
            disabled={disabled}
            onChange={onPhoneChange}
            startIcon={Icon}
            className={className}
            fullWidth={fullWidth}
            endIcon={Flag}
        />
    )
}


export default PhoneInput;