import React from "react";
import AllowAccessProperties from "./AllowAccessProperties";
import {useUserContext} from "../../../contexts/UserContext";

const AllowAccess: React.FC<AllowAccessProperties> = ({
                                                          any = [],
                                                          children,
                                                          isRootAgency = null,
                                                          agencyId = null
                                                      }) => {
    const userStore = useUserContext();

    const hasAccess = () => {
        const hasRolAccess = any?.length === 0 || any?.some((a) => userStore.hasAccess(a));
        let hasAgencyAccess = true;
        if (agencyId) {
            hasAgencyAccess = (userStore.currentUser()?.agencyId === agencyId);
        }
        if (isRootAgency !== null) {
            return hasRolAccess && isRootAgency === userStore.isRootAgency() && hasAgencyAccess;
        }
        return hasRolAccess && hasAgencyAccess;
    };

    if (!hasAccess()) return <></>;
    return <>{children}</>;
};

export default AllowAccess;
