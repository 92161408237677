import Select from "../../../ui_components/Select/Select";
import React, {useEffect, useState} from 'react';
import {useI18nContext} from '../../../contexts/I18nContext';
import SelectOption from "../../../ui_components/Select/SelectOption";
import PaymentTypeSelectProperties from './PaymentTypeSelectProperties';
import CreditCardSharpIcon from '@mui/icons-material/CreditCardSharp';
import PaymentsSharpIcon from '@mui/icons-material/PaymentsSharp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentType from "../../../entities/PaymentType";
import CheckPaymentIcon from "../../../ui_components/Icon/icons/CheckPaymentIcon";
import CashPaymentIcon from "../../../ui_components/Icon/icons/CashPaymentIcon";
import WireTransferIcon from "../../../ui_components/Icon/icons/WireTransferIcon";
import ZelleIcon from "../../../ui_components/Icon/icons/ZelleIcon";
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import {PaymentService} from "../../../service/PaymentFeeService";
import PaymentTypeResponse from "../../../model/payment/PaymentTypeResponse";
import { CurrencyModule } from "../../../util/Currency";

const PaymentTypeSelect: React.FC<PaymentTypeSelectProperties> = ({
                                                                      selected,
                                                                      onChange,
                                                                      disabled,
                                                                      fullWidth = false,
                                                                      className = '',
                                                                      agencyId = undefined
                                                                  }) => {

    const i18n = useI18nContext();

    const [paymentTypes, setPaymentTypes] = useState<PaymentTypeResponse[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<SelectOption>();

    const getIcon = (paymentType: PaymentType) => {
        switch (paymentType) {
            case PaymentType.CASH:
                return CashPaymentIcon;
            case PaymentType.CHECK:
                return CheckPaymentIcon;
            case PaymentType.CREDIT:
                return CreditCardIcon;
            case PaymentType.DEBIT:
                return CreditCardSharpIcon;
            case PaymentType.WIRE_TRANSFER:
                return WireTransferIcon;
            case PaymentType.ZELLE:
                return ZelleIcon;
            case PaymentType.ZELLE_LOWER:
                return ZelleIcon;
            case PaymentType.ZELLE_EXTRA:
                return ZelleIcon;
            case PaymentType.DISTANCE:
                return PermPhoneMsgIcon;
            case PaymentType.DISTANCE_LOWER:
                return PermPhoneMsgIcon;
        }
    }

    useEffect(() => {
        PaymentService.getPaymentFees(agencyId).then((response) => {
            if (response.success && response.data) {
                setPaymentTypes(response.data);
            }
        })
    }, [agencyId]);

    useEffect(() => {
        const mappedPaymentTypes = paymentTypes.map(p => {
            const feeText = p.value !== 0 ? `${i18n.translate("WITH_FEE")}: ${p.isPercentage ? `${p.value}%` : CurrencyModule.format(p.value)}` : i18n.translate('NO_FEE');
            return new SelectOption(i18n.translate(`PAYMENT_TYPE_${p.paymentType}`), p.paymentType, feeText, getIcon(p.paymentType));
        });
        setOptions(mappedPaymentTypes);
    }, [paymentTypes, i18n]);

    useEffect(() => {
        const selectedOption = options.find(t => t.value === selected);
        setSelectedOption(selectedOption);
    }, [selected, options])


    const onStatusChange = (option: SelectOption) => {
        const paymentType = option.value as PaymentType;
        const selected = paymentTypes.find(t=> t.paymentType === paymentType);
        onChange(selected)
    }

    return (
        <Select
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("PAYMENT_TYPE_FILTER")}
            placeholder={i18n.translate("PAYMENT_TYPE_FILTER_PLACEHOLDER")}
            selected={selectedOption}
            icon={PaymentsSharpIcon}
            onChange={onStatusChange}
            elements={options}
        />
    )
}

export default PaymentTypeSelect;