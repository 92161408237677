import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    display_field_container: {
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content'
    },
    value: {
        color: theme.palette.grey["600"],
        display: 'flex',
        alignItems: 'center',
        "& svg": {
            fontSize: '18px',
            color: theme.palette.grey["700"],
            marginRight: '4px',
        }
    },
    article_name_container: {
        display: 'flex',
        alignItems: 'center'
    },
    amount_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    contacts_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    body: {
        display: 'flex',
        padding: '8px 16px',
        flexDirection: 'column'
    },
    contacts_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    cost_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: '100px auto 100px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    label: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
}));

export {useStyles}