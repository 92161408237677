import CustomCategory from "../../../model/packageDelivery/customCategory/CustomCategory"
import CommonStore from "../../../stores/CommonStore"
import {action, makeAutoObservable} from "mobx"
import {CustomCategoryService} from "../../../service/packageDelivery/customCategory/CustomCategoryService"
import Response from "../../../model/Response"


const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"],
    name: ["ERROR_00_600_01","ERROR_00_600_02"]
}

class NewCustomCategoryButtonStore {

    public customCategoryId?: string
    public customCategory: CustomCategory
    public isEditing: boolean
    public isModalOpen: boolean
    public loading: boolean
    public nameError?: string
    public commonStore: CommonStore

    constructor(commonStore: CommonStore, customCategoryId?: string) {
        this.isEditing = !!customCategoryId
        this.customCategoryId = customCategoryId
        this.isModalOpen = false
        this.customCategory = {} as CustomCategory
        this.loading = false
        this.commonStore = commonStore
        makeAutoObservable(this)
    }

    get enableButton(): boolean {
        return !!(this.customCategory?.name) && !!(this.customCategory?.categoryType)
    }

    @action
    public async loadCustomCategory() {
        if (this.customCategoryId) {
            this.isEditing = true;
            const response = await CustomCategoryService.find(this.customCategoryId!)
            if (response.success && response.data) {
                this.customCategory = response.data;
            }
            this.commonStore.processErrors(response);
            this.processError(response.error);
        }
    }

    @action
    public async openModal() {
        this.processError()
        this.loading = true;
        await this.loadCustomCategory();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        this.customCategory = {} as CustomCategory
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        let response: Response<any>;
        if (this.isEditing) {
            response = await CustomCategoryService.update(this.customCategory);
        } else {
            response = await CustomCategoryService.create(this.customCategory);
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error)
            }
        }
    }
}
export default NewCustomCategoryButtonStore