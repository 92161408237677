import React from 'react';
import {useStyles} from "../DeliveryPerson.style";
import clsx from "clsx";
import DeliveryPersonRowProperties from "./DeliveryPersonRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import NewDeliveryPersonButton
    from "../../../shared_components/Buttons/NewDeliveryPersonButton/NewDeliveryPersonButton";
import DeliveryCostButton from "../../../shared_components/Buttons/DeliveryCostButton/DeliveryCostButton";
import RemoveDeliveryPersonButton
    from "../../../shared_components/Buttons/RemoveDeliveryPersonButton/RemoveDeliveryPersonButton";
import AddBalanceButton from "../../../shared_components/Buttons/AddBalanceButton/AddBalanceButton";
import ShowBalances from "../../../shared_components/Info/ShowBalances/ShowBalances";
import ViewTransactionsButton from "../../../shared_components/Buttons/ViewTransactionsButton/ViewTransactionsButton";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ShowDeliveryCosts from '../../../shared_components/Info/ShowDeliveryCosts/ShowDeliveryCosts';
import ShowGeo from '../../../shared_components/Info/ShowGeo/ShowGeo';
import AllowAccess from "../../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../../shared_components/Access/AllowAccess/Access";
import UpdateDeliveryPersonPasswordButton
    from "../../../shared_components/Buttons/UpdateDeliveryPersonPasswordButton/UpdateDeliveryPersonPasswordButton";
import ShowDeliveryPersonArticleStock from '../../../shared_components/Info/ShowDeliveryPersonArticleStock/ShowDeliveryPersonArticleStock';


const DeliveryPersonRowMedium: React.FC<DeliveryPersonRowProperties> = ({
                                                                            person,
                                                                            onUpdate = () => {
                                                                            },
                                                                        }) => {


    const classes = useStyles();

    return (
        <div
            className={clsx(classes.client_info_container)}>
            <ShowSellerBasicInfo autofill name={person.name} email={person.email} phone={person.phone}/>
            <ShowGeo geo={person.geo} showParentInfo={true}/>
            <AllowAccess any={[Access.GET_BALANCE_OF_DELIVERY_PERSON]}>
                <ShowBalances label={'Saldos'} balances={person.balances} activeMoneyDeliveryBalances={person.activeMoneyDeliveryBalances}/>
            </AllowAccess>
            <AllowAccess any={[Access.GET_ARTICLE_STOCK_OF_DELIVERY_PERSON]}>
                <ShowDeliveryPersonArticleStock deliveryPerson={person} onClose={onUpdate}/>
            </AllowAccess>
            <ShowDeliveryCosts label={'Comisión'} deliveryCosts={person.deliveryCosts}/>
            <ExpandoMenu>
                <>
                    <NewDeliveryPersonButton
                        type={ButtonType.SECONDARY}
                        onSave={onUpdate}
                        deliveryPersonId={person.id}
                        size={ButtonSize.SMALL}
                        showText
                    />
                    <DeliveryCostButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClose={onUpdate}
                        showText
                        deliveryPerson={person}
                    />
                    <AddBalanceButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        showText
                        entityName={person.name}
                        entityId={person.id}
                    />
                    <ViewTransactionsButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        showText
                        entityId={person.id}
                    />
                    <RemoveDeliveryPersonButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        showText
                        deliveryPerson={person}
                    />
                    <UpdateDeliveryPersonPasswordButton
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        deliveryPersonId={person.id}
                        deliveryPersonName={person.name}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default DeliveryPersonRowMedium;
