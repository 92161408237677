import React from 'react';
import RemoveTopupPriceButtonProperties from "./RemoveTopupPriceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import RemoveTopupPriceStore from "./RemoveTopupPriceStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useCommonContext} from "../../../contexts/CommonContext";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';

const RemoveTopupPriceButton: React.FC<RemoveTopupPriceButtonProperties> = ({
                                                                        onClick = () => {
                                                                        },
                                                                        onSave = () => {
                                                                        },
                                                                        showIcon = true,
                                                                        showText = true,
                                                                        disabled,
                                                                        className = '',
                                                                        topupPrice,
                                                                        rounded = false,
                                                                        size = ButtonSize.DEFAULT,
                                                                        type = ButtonType.DEFAULT
                                                                    }) => {

    const i18n = useI18nContext();
    const confirm = useConfirmation();
    const commonContext = useCommonContext();
    const store = new RemoveTopupPriceStore(commonContext, topupPrice);

    const handleOnclick = async () => {
        onClick();
        const success = await confirm(i18n.translate("REMOVE_TOPUP_PRICE_CONFIRMATION"), '');
        if (success) {
            await store.remove();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = i18n.translate("REMOVE_TOPUP_PRICE");

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon name={IconName.DELETE_ICON}/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default RemoveTopupPriceButton;
