import React from "react";
import IconProperties from "./IconProperties";

const DeliveryPersonIcon: React.FC<IconProperties> = ({
  className,
  fontSize,
}) => {
  return (
    <svg
      height="30px"
      width="30px"
      version="1.1"
      id="Layer_3"
      viewBox="0 0 512 512"
    >
      <g transform="translate(1 1)">
        <g>
          <path
            style={{fill:'#FFDD09'}}
            d="M161.133,429.933c0-23.893-18.773-42.667-42.667-42.667S75.8,406.04,75.8,429.933
			c0,23.893,18.773,42.667,42.667,42.667S161.133,453.827,161.133,429.933"
          />
          <path
            style={{fill:'#FFDD09'}}
            d="M493.933,429.933c0-23.893-18.773-42.667-42.667-42.667c-23.893,0-42.667,18.773-42.667,42.667
			c0,23.893,18.773,42.667,42.667,42.667C475.16,472.6,493.933,453.827,493.933,429.933"
          />
          <path
            style={{fill:'#FFDD09'}}
            d="M489.667,347.16c-11.093-12.8-29.013-19.627-46.933-19.627H434.2
			c-5.12-26.453-23.893-48.64-48.64-58.88L383,267.8v-93.867h-85.333v8.533c9.387,0,17.067,7.68,17.067,17.067
			s-7.68,17.067-17.067,17.067v8.533h51.2v102.4V395.8H212.333l11.947-26.453c3.413-11.093,5.12-22.187,5.12-33.28
			c0-14.507-11.093-25.6-25.6-25.6H41.667c-14.507,0-25.6,11.093-25.6,25.6v10.24c0,4.267,2.56,8.533,5.973,10.24
			c11.093,7.68,14.507,22.187,7.68,34.133l-11.093,18.773c-5.12,6.827-3.413,16.213,4.267,19.627c2.56,0,4.267,0.853,5.973,0.853
			H50.2c0-37.547,30.72-68.267,68.267-68.267s68.267,30.72,68.267,68.267H383c0-37.547,30.72-68.267,68.267-68.267
			c30.72,0,40.107,5.973,51.2,17.067C502.467,366.787,497.347,356.547,489.667,347.16"
          />
        </g>
        <path
          style={{fill:'#FFFFFF'}}
          d="M48.493,428.227c-7.68-3.413-9.387-13.653-4.267-19.627l11.093-18.773
		c6.827-11.093,3.413-25.6-7.68-33.28c-3.413-1.707-5.973-5.12-5.973-10.24v-10.24c0-14.507,11.093-25.6,25.6-25.6h-25.6
		c-14.507,0-25.6,11.093-25.6,25.6v10.24c0,4.267,2.56,8.533,5.973,10.24c11.093,7.68,14.507,22.187,7.68,34.133l-11.093,18.773
		c-5.12,6.827-3.413,16.213,4.267,19.627c2.56,0,4.267,0.853,5.973,0.853H50.2v-0.853C49.347,429.08,49.347,429.08,48.493,428.227"
        />
        <polygon
          style={{fill:'#54C9FD'}}
          points="417.133,208.067 400.067,208.067 383,199.533 383,165.4 400.067,156.867 417.133,156.867 	
		"
        />
        <g>
          <path
            style={{fill:'#FCC309'}}
            d="M237.933,80.067c0-23.893-18.773-42.667-42.667-42.667S152.6,56.173,152.6,80.067
			s18.773,42.667,42.667,42.667S237.933,103.96,237.933,80.067"
          />
          <path
            style={{fill:'#FCC309'}}
            d="M285.72,275.48c-3.413-9.387-11.947-16.213-22.187-16.213l0,0h-42.667V216.6h75.947
			c8.533,0,16.213-5.973,17.92-14.507c1.707-11.093-6.827-19.627-17.067-19.627h-59.733V152.6c0-16.213-13.653-29.867-29.867-29.867
			H191c-16.213,0-29.867,13.653-29.867,29.867v128c0,16.213,13.653,29.867,29.867,29.867h64l25.6,85.333h42.667L285.72,275.48z"
          />
        </g>
        <polygon
          style={{fill:'#FFDD09'}}
          points="7.533,310.467 127,310.467 127,191 7.533,191 	"
        />
        <polygon
          style={{fill:'#FFFFFF'}}
          points="7.533,310.467 24.6,310.467 24.6,191 7.533,191 	"
        />
        <polygon
          style={{fill:'#54C9FD'}}
          points="50.2,225.133 84.333,225.133 84.333,191 50.2,191 	"
        />
        <path
          d="M118.467,447c-9.387,0-17.067-7.68-17.067-17.067s7.68-17.067,17.067-17.067s17.067,7.68,17.067,17.067
		S127.853,447,118.467,447"
        />
        <path
          d="M118.467,481.133c-28.16,0-51.2-23.04-51.2-51.2s23.04-51.2,51.2-51.2s51.2,23.04,51.2,51.2
		S146.627,481.133,118.467,481.133z M118.467,395.8c-18.773,0-34.133,15.36-34.133,34.133s15.36,34.133,34.133,34.133
		s34.133-15.36,34.133-34.133S137.24,395.8,118.467,395.8z"
        />
        <path
          d="M451.267,447c-9.387,0-17.067-7.68-17.067-17.067s7.68-17.067,17.067-17.067s17.067,7.68,17.067,17.067
		S460.653,447,451.267,447"
        />
        <path
          d="M451.267,481.133c-28.16,0-51.2-23.04-51.2-51.2s23.04-51.2,51.2-51.2s51.2,23.04,51.2,51.2
		S479.427,481.133,451.267,481.133z M451.267,395.8c-18.773,0-34.133,15.36-34.133,34.133s15.36,34.133,34.133,34.133
		s34.133-15.36,34.133-34.133S470.04,395.8,451.267,395.8z"
        />
        <path
          d="M127,319H7.533C2.413,319-1,315.587-1,310.467V191c0-5.12,3.413-8.533,8.533-8.533H127c5.12,0,8.533,3.413,8.533,8.533
		v119.467C135.533,315.587,132.12,319,127,319z M16.067,301.933h102.4v-102.4h-102.4V301.933z"
        />
        <path
          d="M195.267,131.267c-28.16,0-51.2-23.04-51.2-51.2s23.04-51.2,51.2-51.2s51.2,23.04,51.2,51.2
		S223.427,131.267,195.267,131.267z M195.267,45.933c-18.773,0-34.133,15.36-34.133,34.133s15.36,34.133,34.133,34.133
		S229.4,98.84,229.4,80.067S214.04,45.933,195.267,45.933z"
        />
        <path
          d="M417.133,216.6h-17.067c-1.707,0-2.56,0-3.413-0.853l-17.067-8.533c-3.413-1.707-5.12-4.267-5.12-7.68V165.4
		c0-3.413,1.707-5.973,5.12-7.68l17.067-8.533c0.853-0.853,1.707-0.853,3.413-0.853h17.067c5.12,0,8.533,3.413,8.533,8.533v51.2
		C425.667,213.187,422.253,216.6,417.133,216.6z M401.773,199.533h6.827V165.4h-6.827l-10.24,5.12v23.893L401.773,199.533z"
        />
        <path
          d="M220.867,225.133c-14.507,0-25.6-11.093-25.6-25.6v-25.6c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v25.6c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533,3.413,8.533,8.533C229.4,221.72,225.987,225.133,220.867,225.133z"
        />
        <path
          d="M323.267,404.333H280.6c-3.413,0-6.827-2.56-8.533-5.973L249.027,319H191c-21.333,0-38.4-17.067-38.4-38.4v-128
		c0-21.333,17.067-38.4,38.4-38.4h17.067c21.333,0,38.4,17.067,38.4,38.4v21.333h51.2c7.68,0,14.507,3.413,19.627,9.387
		c5.12,5.973,6.827,13.653,5.973,20.48c-1.707,11.947-12.8,21.333-26.453,21.333H229.4v25.6h34.133
		c13.653,0,26.453,8.533,29.867,22.187l38.4,120.32c0.853,2.56,0,5.12-0.853,7.68C328.387,402.627,325.827,404.333,323.267,404.333z
		 M286.573,387.267h24.747L277.187,278.04l0,0c-1.707-5.973-6.827-10.24-13.653-10.24h-42.667c-5.12,0-8.533-3.413-8.533-8.533
		V216.6c0-2.56,0.853-4.267,2.56-5.973c1.707-1.707,3.413-2.56,5.973-2.56h75.947c4.267,0,8.533-3.413,9.387-6.827
		c0-2.56,0-5.12-1.707-6.827c-1.707-2.56-4.267-3.413-6.827-3.413h-59.733c-5.12,0-8.533-3.413-8.533-8.533V152.6
		c0-11.947-9.387-21.333-21.333-21.333H191c-11.947,0-21.333,9.387-21.333,21.333v128c0,11.947,9.387,21.333,21.333,21.333h64
		c3.413,0,6.827,2.56,8.533,5.973L286.573,387.267z"
        />
        <path
          d="M383,438.467H186.733c-5.12,0-8.533-3.413-8.533-8.533c0-33.28-26.453-59.733-59.733-59.733s-59.733,26.453-59.733,59.733
		c0,5.12-3.413,8.533-8.533,8.533H28.867c-3.413,0-6.827-0.853-9.387-2.56c-5.973-2.56-10.24-8.533-11.093-14.507
		c-1.707-5.973,0-12.8,3.413-17.067l10.24-17.92c4.267-7.68,2.56-17.067-5.12-21.333c-5.973-5.12-9.387-11.093-9.387-18.773v-10.24
		c0-18.773,15.36-34.133,34.133-34.133H203.8c9.387,0,17.92,3.413,23.893,10.24c6.827,6.827,10.24,15.36,10.24,23.893
		c0,11.947-1.707,23.893-5.973,35.84l-6.827,15.36h115.2v-153.6h-42.667c-5.12,0-8.533-3.413-8.533-8.533V216.6
		c0-5.12,3.413-8.533,8.533-8.533s8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533s-8.533-3.413-8.533-8.533v-8.533
		c0-5.12,3.413-8.533,8.533-8.533H383c5.12,0,8.533,3.413,8.533,8.533v87.893C415.427,272.92,433.347,293.4,441.027,319h1.707
		c21.333,0,40.96,8.533,53.76,23.04l0,0C505.88,353.133,511,365.08,511,378.733c0,3.413-1.707,6.827-5.12,7.68
		c-3.413,1.707-6.827,0.853-9.387-1.707c-9.387-9.387-17.067-14.507-45.227-14.507c-33.28,0-59.733,26.453-59.733,59.733
		C391.533,435.053,388.12,438.467,383,438.467z M194.413,421.4h180.053c4.267-38.4,36.693-68.267,75.947-68.267
		c16.213,0,27.307,1.707,35.84,5.12c-0.853-1.707-2.56-3.413-4.267-5.12l0,0c-9.387-11.093-24.747-17.067-40.96-17.067h-8.533
		c-4.267,0-7.68-2.56-8.533-6.827c-5.12-23.893-21.333-43.52-43.52-52.907l-2.56-0.853c-1.707-0.853-3.413-4.267-3.413-7.68v-85.333
		H316.44c4.267,4.267,6.827,10.24,6.827,17.067s-2.56,12.8-6.827,17.067h32.427c5.12,0,8.533,3.413,8.533,8.533V395.8
		c0,5.12-3.413,8.533-8.533,8.533H212.333c-2.56,0-5.973-1.707-6.827-4.267s-1.707-5.12-0.853-7.68l11.947-26.453
		c3.413-9.387,4.267-19.627,4.267-29.867c0-4.267-1.707-8.533-5.12-11.947c-3.413-3.413-7.68-5.12-11.947-5.12H41.667
		c-9.387,0-17.067,7.68-17.067,17.067v10.24c0,1.707,1.707,2.56,2.56,3.413c14.507,9.387,19.627,29.013,10.24,44.373l-11.093,18.773
		l-0.853,0.853c-0.853,1.707-0.853,3.413-0.853,4.267c0,0.853,0.853,2.56,2.56,3.413c0.853,0,0.853,0,1.707,0h12.8
		c4.267-38.4,36.693-68.267,75.947-68.267S190.147,383,194.413,421.4z"
        />
        <path
          d="M84.333,233.667H50.2c-5.12,0-8.533-3.413-8.533-8.533V191c0-5.12,3.413-8.533,8.533-8.533h34.133
		c5.12,0,8.533,3.413,8.533,8.533v34.133C92.867,230.253,89.453,233.667,84.333,233.667z M58.733,216.6H75.8v-17.067H58.733V216.6z"
        />
      </g>
    </svg>
  );
};
export default DeliveryPersonIcon;
