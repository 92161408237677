import React from 'react';
import ServiceFeeTypeSelectProperties from "./ServiceFeeTypeSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import Select from "../../../ui_components/Select/Select";
import SelectOption from '../../../ui_components/Select/SelectOption';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const ServiceFeeTypeSelect: React.FC<ServiceFeeTypeSelectProperties> = ({
                                                                        selected,
                                                                        onChange,
                                                                        fullWidth = false,
                                                                        className = '',

                                                                    }) => {

    const i18n = useI18nContext();

    const opt = [
        new SelectOption(i18n.translate("PERCENT"), "PERCENT", i18n.translate("PERCENT_DESCRIPTION"), PercentIcon),
        new SelectOption(i18n.translate("UNITARY"), "UNITARY", i18n.translate("UNITARY_DESCRIPTION"), AttachMoneyIcon),
    ]

    const selectedOption = opt.find(t => t.value === selected);

    const handleChange = (option: SelectOption) => {
        selected = option.value;
        onChange(option.value);
    }

    return (
        <Select
            className={className}
            fullWidth={fullWidth}
            label={i18n.translate("SERVICE_FEE_TYPE")}
            placeholder={i18n.translate("SERVICE_FEE_TYPE_PLACEHOLDER")}
            selected={selectedOption}
            onChange={handleChange}
            elements={opt}
        />
    )
}


export default ServiceFeeTypeSelect;