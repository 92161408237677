import CategoryPriceSectionProperties from "./CategoryPriceSectionProperties";
import React from "react";
import {Observer} from "mobx-react-lite";
import WeightPriceCalculator from "../WeightPriceCalculator/WeightPriceCalculator";

const CategoryPriceSection:React.FC<CategoryPriceSectionProperties> =({
                                                                          categoryId,
                                                                          weightInfo,
                                                                          onWeightChange,
                                                                          onFinalWeightPriceChange,

                                                                          disable,
                                                                          fullWidth=false,
                                                                          className='',
                                                                          })=>{

    const changeFinalPrice = (finalPrice?:number)=>{
        onFinalWeightPriceChange(finalPrice,categoryId)
    }
    const onChangeWeight=(newWeight?:number)=>{
        onWeightChange(newWeight,categoryId)
    }

    return (
        <Observer>
            {() => (
                <WeightPriceCalculator
                    weightInfo={weightInfo}
                    onWeightChange={onChangeWeight}
                    onFinalWeightPriceChange={changeFinalPrice}
            />
            )}
        </Observer>
    )
}
export default CategoryPriceSection