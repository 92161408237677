import errors from './error'
import basic from './basic'
import seller from './seller'
import client from './client'
import contact from './contact'
import agency from './agency'
import articles from './articles'
import articlePrices from './articlePrices'
import articleDelivery from './articleDelivery'
import moneyRemittances from './moneyDelivery'
import topup from './topup'
import topupPrices from './topupPrices'
import deliveryPerson from './deliveryPerson'
import remittancesAdditionalPrice from './remittancesAdditionalPrice'
import transaction from './transaction'
import moneyExchanges from './moneyExchanges'
import moneyServiceFees from './moneyServiceFees'
import invoice from './invoice'
import contactBankCard from './contactBankCard'
import packageCategoryCosts from './packageCategoryCosts'
import packageCategories from './packageCategories'
import packageDelivery from './packageDelivery'
import salePrices from './salePrices'
import dashboard from './dashboard'
import customCategory from './customCategory'
import inventoryItem from './inventoryItem'
import referenceItem from './referenceItem'
import promotion from './promotion'
import home from './home'
import miscellanyCategory from './miscellany'
import shopProducts from './shopProducts'
import shop from './shop'

export default Object({
    ...errors,
    ...basic,
    ...seller,
    ...client,
    ...contact,
    ...agency,
    ...articles,
    ...articlePrices,
    ...articleDelivery,
    ...moneyRemittances,
    ...topup,
    ...topupPrices,
    ...remittancesAdditionalPrice,
    ...deliveryPerson,
    ...transaction,
    ...moneyExchanges,
    ...moneyServiceFees,
    ...invoice,
    ...home,
    ...dashboard,
    ...contactBankCard,
    ...packageCategoryCosts,
    ...packageCategories,
    ...packageDelivery,
    ...salePrices,
    ...customCategory,
    ...inventoryItem,
    ...referenceItem,
    ...promotion,
    ...miscellanyCategory,
    ...shopProducts,
    ...shop,
})