import React from "react";
import { useStyles } from "../Agency.style";
import clsx from "clsx";
import AgencyRowProperties from "./AgencyRowProperties";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import RemoveAgencyButton from "../../../shared_components/Buttons/RemoveAgencyButton/RemoveAgencyButton";

const AgencyRowSmall: React.FC<AgencyRowProperties> = ({
  agency,
  onUpdate,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.client_info_container_small)}>
      <ShowSellerBasicInfo autofill name={agency.name} phone={agency.phone}/>
      <div className={classes.menu_container_small}>
        <RemoveAgencyButton
          size={ButtonSize.SMALL}
          onSave={onUpdate}
          agency={agency}
          showText={false}
          type={ButtonType.SECONDARY}
        />
      </div>
    </div>
  );
};

export default AgencyRowSmall;
