import React from 'react';
import SalePriceOptionsButtonProperties from "./SalePriceOptionsButtonProperties";
import SalePriceOptionsStore from "./SalePriceOptionsStore";
import {Observer} from "mobx-react-lite";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useCommonContext} from "../../../contexts/CommonContext";
import Menu from '@mui/material/Menu';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './SalePriceOptionsButton.style';
import ShowDetailsOption from './Options/ShowDetailsOption/ShowDetailsOption';
import RemoveOption from './Options/RemoveOption/RemoveOption';
import NewSalePriceButton from '../NewSalePriceButton/NewSalePriceButton';


const SalePriceOptionsButton: React.FC<SalePriceOptionsButtonProperties> = ({
                                                                                onUpdate = () => {
                                                                                },
                                                                                className = '',
                                                                                salePriceId,
                                                                                salePriceName,
                                                                                salePriceDescription,
                                                                              }) => {

    const classes: any = useStyles();

    const commonContext = useCommonContext();
    const store = new SalePriceOptionsStore(commonContext, salePriceId, salePriceName, salePriceDescription);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onUpdateClick = () => {
        onUpdate();
        setAnchorEl(null);
    };

    return <Observer>
        {() => (
            <>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.icon_button}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <ShowDetailsOption onClick={handleClose} salePriceName={salePriceName}  salePriceDescription={salePriceDescription}></ShowDetailsOption>
                    <NewSalePriceButton onSave={onUpdateClick} salePriceId={salePriceId}></NewSalePriceButton>
                    <RemoveOption store={store} onRemove={onUpdateClick} salePriceName={salePriceName}></RemoveOption>
                </Menu>
            </>
        )}
    </Observer>
}

export default SalePriceOptionsButton;
