import React, { useEffect } from 'react';
import ContactSelectListProperties from "./ContactSelectListProperties";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./ContactSelectList.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import ContactSelectListStore from "./ContactSelectListStore";
import {Observer} from "mobx-react-lite";
import SearchContactPanel from './searchContact/SearchContactPanel';
import ClientContact from '../../../entities/ClientContact';

const ContactSelectList: React.FC<ContactSelectListProperties> = ({
                                                                          clientId,
                                                                          onContactSelect,
                                                                          fullWidth = false,
                                                                          className = '',
                                                                          selectedContact,
                                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const commonContext = useCommonContext();
    const store = new ContactSelectListStore(commonContext, clientId, selectedContact);
    const containerClasses = clsx(
        classes.delivery_person_contact_container,
        className, fullWidth && commonClasses.fullWidth);

    useEffect(() => {
        // if (selectedContact) {
            store.setSelectedContact(selectedContact);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContact])

    const selectContact = (contact: ClientContact) => {
        onContactSelect(contact);
    }

    return (
        <Observer>
            {() => (
                <div className={containerClasses}>
                    <SearchContactPanel onContactSelect={selectContact} store={store}/>
                </div>
            )}
        </Observer>
    )
}

export default ContactSelectList;