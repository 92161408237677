const values = {
    "COST": "Costo",
    "PACKAGE_CATEGORY_COST": "Costo de categorías",
    "PACKAGE_CATEGORY_COSTS": "Costos de categorías",
    "NEW_PACKAGE_CATEGORY_COST": "Nuevo costo de categoría",
    "EDIT_PACKAGE_CATEGORY_COST": "Editar costo de categoría",
    "NO_PACKAGE_CATEGORY_COSTS_TITLE": "No hay costo de categorías!!!",
    "NO_PACKAGE_CATEGORY_COSTS_SUBTITLE": "Verifica si estás aplicando algún filtro",
}

export default values;