import {action, makeAutoObservable, observable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import {ProductOrderStatusHistoryService} from "../../../service/ProductOrderStatusHistoryService";
import ProductOrderStatusHistory from "../../../entities/ProductOrderStatusHistory";

class ShowProductOrderStatusHistoryStore {

    public orderId: string;
    public loading: boolean;
    public commonStore: CommonStore;
    public statusHistory: ProductOrderStatusHistory[];
    public isRootAgency: boolean;

    constructor(commonStore: CommonStore, orderId: string, isRootAgency: boolean) {
        this.orderId = orderId;
        this.loading = false;
        this.commonStore = commonStore;
        this.isRootAgency = isRootAgency;
        this.statusHistory = [];
        this.getElements();
        makeAutoObservable(this, {
            statusHistory: observable
        });
    }

    @action
    public async getElements(): Promise<void> {
        this.commonStore.setLoading();
        const response = await ProductOrderStatusHistoryService.getStatusHistory(this.isRootAgency, this.orderId);
        this.statusHistory = response;
        this.commonStore.removeLoading();
    }

    get isEmpty(): boolean {
        return this.statusHistory.length === 0;
    }
}

export default ShowProductOrderStatusHistoryStore;