import { action, makeAutoObservable } from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ArticleDelivery from "../../../entities/ArticleDelivery";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import Client from "../../../entities/Client";
import Agency from "../../../entities/Agency";
import Seller from "../../../entities/Seller";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import { AppStorage, AppStorageValues } from "../../../util/AppStorage";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyOrderService } from "../../../service/miscellany/MiscellanyOrderService";
import MiscellanyOrderRequest from "../../../model/miscellany/MiscellanyOrderRequest";

class NewMiscellanyOrderStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public commonStore: CommonStore;
    public response?: ArticleDelivery[]
    public errorKey?: string;

    public finishButtonLoading = false;

    public activeStep = 0;

    // Remittances data

    // Miscellany Category
    public description?: string;
    public selectedMiscellanyCategory?: MiscellanyCategory;

    // Contacts
    public selectedClient?: Client;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;
    
    public observations?: string;

    // Additional prices
    public additionalPrices: OrderAdditionalPrice[];

    // Payments
    public payments: AddPaymentRequest[];
    public paymentCompleted: boolean;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.validating = false;
        this.selectedMiscellanyCategory = undefined;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.commonStore = commonStore;
        this.selectedAgency = AppStorage.get(AppStorageValues.SELECTED_AGENCY, undefined);
        this.selectedSeller = AppStorage.get(AppStorageValues.SELECTED_SELLER, undefined);
        makeAutoObservable(this);
    }

    public reset() {
        this.selectedMiscellanyCategory = undefined;
        this.selectedClient = undefined;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.activeStep = 0;
        this.observations = undefined;
    }

    @action
    public async openModal() {
        this.reset();
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public restart() {
        this.response = undefined;
    }

    // Contact area

    @action
    public setClient(client?: Client) {
        this.selectedClient = client;
    }

    // Miscellany Category area



    // Additional prices area

    @action
    public addAdditionalPrice(currency: string, amount: number, comment: string) {
        this.additionalPrices.push(new OrderAdditionalPrice(amount, comment, false));
    }

    @action
    public removeAdditionalPrice(additionalPrice: OrderAdditionalPrice) {
        this.additionalPrices = this.additionalPrices.filter(t => t.amount !== additionalPrice.amount && t.comment !== additionalPrice.comment);
    }

    public getAdditionalPriceTotal(): number {
        return this.additionalPrices.reduce((sum, current) => sum + current.amount, 0);
    }

    // Payment area

    public getServicePrice(): number {
        return this.selectedMiscellanyCategory ? this.selectedMiscellanyCategory.price : 0;
    }

    public getTotalToPay(): number {
        return this.getServicePrice() + this.getAdditionalPriceTotal();
    }

    @action
    public setPayments(payments: AddPaymentRequest[], paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
    }

    @action
    public async createMiscellanyOrder() {
        if (!this.selectedClient) {
            await this.commonStore.showErrorToast('Debe seleccionar un cliente.');
            return;
        }
        if (!this.selectedMiscellanyCategory) {
            await this.commonStore.showErrorToast('Debe seleccionar alguna categoria.');
            return;
        }
        if (this.finishButtonLoading) {
            return;
        }
        this.finishButtonLoading = true;
        const response = await MiscellanyOrderService.create(
            new MiscellanyOrderRequest(
                this.selectedClient?.id,
                this.selectedMiscellanyCategory?.id,
                this.payments,
                this.selectedMiscellanyCategory.price + this.additionalPrices.reduce((total, obj) => total + obj.amount, 0),
                this.selectedAgency?.id,
                this.selectedSeller?.id,
                this.additionalPrices,
                this.description
        ));
        if (response.success && response.data) {
            this.closeModal();
            window.location.href = '/miscellany-orders';
        }
        this.finishButtonLoading = false;
    }

}

export default NewMiscellanyOrderStore;