import React from 'react';
import {useStyles} from "./CategorySelect.style";
import {Observer} from "mobx-react-lite";
import { Box } from '@material-ui/core';
import CategorySelectProperties from './CategorySelectProperties';
import ObservationsInput from '../../../Inputs/ObservationsInput/ObservationsInput';
import MiscellanyCategorySelectList from '../../../Selects/MiscellanyCategorySelectList/MiscellanyCategorySelectList';
import MiscellanyCategory from '../../../../entities/productOrder/miscellany/MiscellanyCategory';

const CategorySelect: React.FC<CategorySelectProperties> = ({
                                                                          store
                                                                      }) => {
    const classes = useStyles();

    const onMiscellanyCategorySelect = async (miscellanyCategory?: MiscellanyCategory) => {
        store.selectedMiscellanyCategory = miscellanyCategory;
    }

    const onDescriptionChange = (newDescription: string) => {
        store.description = newDescription;
    }

    return (
        <Observer>
            {() => (
                <>
                    <Box className={classes.container}>
                        <MiscellanyCategorySelectList selectedMiscellanyCategory={store.selectedMiscellanyCategory}
                            agencyId={store.selectedAgency?.id}
                            onMiscellanyCategorySelect={onMiscellanyCategorySelect}></MiscellanyCategorySelectList>
                        <ObservationsInput
                            className={classes.description_container}
                            lines={10}
                            label={'Descripción'}
                            value={store.description}
                            onChange={onDescriptionChange}/>
                    </Box>
                </>
            )}
        </Observer>
    )
}

export default CategorySelect;