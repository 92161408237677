import MoneyDeliveryFeeCurrencyValue from "./MoneyDeliveryFeeCurrencyValue";

class MoneyDeliveryFee {
    public id: string;
    public agencyId: string;
    public fromValue: number;
    public toValue: number;
    public currenciesValues: MoneyDeliveryFeeCurrencyValue[]

    constructor(
        id: string,
        agencyId: string,
        fromValue: number,
        toValue: number,
        currenciesValues: MoneyDeliveryFeeCurrencyValue[]
    ) {
        this.id = id;
        this.agencyId = agencyId;
        this.fromValue = fromValue;
        this.toValue = toValue;
        this.currenciesValues = currenciesValues;
    }
}

export default MoneyDeliveryFee;