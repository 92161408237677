import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import useConfirmation from "../../../hooks/ConfirmationDialog";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import EditPackageItemButtonProperties from './EditPackageItemButtonProperties';

const EditPackageItemButton: React.FC<EditPackageItemButtonProperties> = ({
                                                                                  onClick = () => {},
                                                                                  onSave = () => {},
                                                                                  showIcon = true,
                                                                                  showText = true,
                                                                                  disabled,
                                                                                  className = '',
                                                                                  packageItem,
                                                                                  categoryId,
                                                                                  onEditPackageItem,
                                                                                  rounded = false,
                                                                                  size = ButtonSize.DEFAULT,
                                                                                  type = ButtonType.DEFAULT
}) => {

    const i18n = useI18nContext();
    const confirm = useConfirmation();

    const handleOnclick = async () => {
        const success = await confirm(i18n.translate("EDIT_PACKAGE_ITEM_CONFIRMATION"), '');
        if(success){
            onEditPackageItem(packageItem,categoryId)
        }       
    }

    const text = i18n.translate("EDIT_PACKAGE_ITEM");

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                size={size}
                type={type}
                icon={showIcon ? <Icon name={IconName.EDIT_ICON}/> : <></>}
            />
        }
        return <Button
            onClick={handleOnclick}
            className={className}
            disabled={disabled}
            tooltip={!showText ? text : ""}
            text={showText ? text : ""}
            size={size}
            type={type}
            startIcon={showIcon ? <Icon name={IconName.EDIT_ICON}/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <Btn/>
        )}
    </Observer>
}

export default EditPackageItemButton;
