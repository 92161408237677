import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.grey[200],
        flexGrow: 1,
        height: 'calc(100vh - 250px)'
    },
    selected_food_packages_container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gridColumnGap: '10px',
        gridRowGap:' 10px',
        backgroundColor: '#ffffff'
    },
    no_food_packages_title: {
        fontSize: "20px",
        textAlign: "center",
        margin: "10px 10px 25px"
    },
    bank_card_container: {
        display: 'flex',
        gap: '20px',
        padding: '15px',
        margin: '10px',
        fontWeight: 900,
        backgroundColor: `${theme.palette.grey["200"]}`,
    },
    error: {
        color: theme.palette.error.dark,
        backgroundColor: `${theme.palette.grey["200"]}`,
    }
}));

export {useStyles}