import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        money_overview_container: {
            display: 'grid',
            minHeight: 50,
            columnGap: 8,
            rowGap: 8,
            marginTop: 8,
            position: "relative",
            padding: 8,
            borderRadius: 4,
            backgroundColor: theme.palette.grey[100]
        },
        money_overview_container_medium: {
            gridTemplateColumns: "20% auto auto",
        },
        prices: {
            display: 'grid',
            gridAutoFlow: "column",
            columnGap: 4,
            rowGap: 4
        },
        money_overview_container_small: {
            gridTemplateColumns: "auto",
        },
        column: {
            display: 'grid',
            gridAutoFlow: "column",
            columnGap: 4,
            rowGap: 4
        },
        column_small: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            columnGap: 4,
            rowGap: 4
        },
        loading_container: {
            minHeight: '50px',
        },
    }))
;

export {useStyles}