const values={
    "CUSTOM_CATEGORY":"Categorías Aduanales",
    "CUSTOM_CATEGORY_PLACEHOLDER":"Nombre, descripción",
    "CUSTOM_CATEGORIES":"Categorías Aduanales",
    "NO_CUSTOM_CATEGORIES_TITLE":"No hay categorías aduanales!!!",
    "NO_CUSTOM_CATEGORIES_SUBTITLE":"Verifica si estás aplicando algún filtro",
    "NEW_CUSTOM_CATEGORY":"Nueva Categoría Aduanal",
    "EDIT_CUSTOM_CATEGORY":"Editar categoría aduanal",
    "CUSTOM_CATEGORY_NAME":"Nombre de categoría",
    "CUSTOM_CATEGORY_DESCRIPTION":"Descripción",
    "REMOVE_CUSTOM_CATEGORY":"Eliminar categoría aduanal",
    "REMOVE_CUSTOM_CATEGORY_CONFIRMATION":"¿Está seguro que desea eliminar la categoría aduanal?",
    "SELECT_CUSTOM_CATEGORY":"Seleccione la categoría aduanal",

    //Custom Category Type
    "CUSTOM_CATEGORY_TYPE":"Tipo",
    "CUSTOM_CATEGORY_TYPE_PLACEHOLDER":"Seleccione el tipo de categoría",
    "GROUP":"Grupal",
    "INDIVIDUAL":"Individual",

    //Weight Ranges
    "POUNDS":"lbs",
    "WEIGHT_RANGES_RANGE":"Rango (lb)",
    "WEIGHT_RANGES_FROM":"De",
    "WEIGHT_RANGES_TO":"Hasta",
    "WEIGHT_RANGES_COST":"Cost",
    "WEIGHT_RANGES_PRICE":"Precio",
    "WEIGHT":"Peso",
    "WEIGHING":"Pesaje",
    "CONSIDERATION":"Consideracion",
    "CUSTOM":"Aduanal",
    "WEIGHT_RANGE":"Rango",
    "WEIGHT_RANGES":"Rangos de pesaje",
    "EDIT_WEIGHT_RANGE":"Ediar rango de pesaje",
    "CREATE_WEIGHT_RANGE":"Nuevo rango de pesaje",
    "REMOVE_WEIGHT_RANGE":"Eliminar rango de pesaje",
    "REMOVE_WEIGHT_RANGE_CONFIRMATION":"¿Está seguro que desea eliminar el rango de pesaje?",

}
export default values