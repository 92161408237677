import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import Pagination from "../../../entities/Pagination";
import ShippingAssignmentStatus from "../../../entities/ShippingAssignmentStatus";


class ShippingStatusSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public elements: DeliveryPerson[];
    public shippingAssignation: string[];
    public pagination: Pagination;
    public selectedShippingAssignment?: string;
    public isSearchDialogOpen: boolean;


    constructor(commonStore: CommonStore, selectedShippingAssignment?: string) {
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.elements = [];
        this.shippingAssignation = [];
        this.selectedShippingAssignment = selectedShippingAssignment;
        this.isSearchDialogOpen = false;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        // const response = await DeliveryPersonService.get(1, 20, this.filter);
        // if (response.success) {
        //     runInAction(() => {
        //         this.elements = response.data?.data || [];
        //         this.pagination = response.data?.pagination || this.pagination;
        //     })
        // }
        this.shippingAssignation = [ShippingAssignmentStatus.All, ShippingAssignmentStatus.ASSIGNED, ShippingAssignmentStatus.NONASSIGNED]
        this.pagination = new Pagination(1, 15, 0, 0);

        //this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        this.isSearchDialogOpen = value;
        if (this.isSearchDialogOpen) {
            this.search();
        }
    }

    get isEmpty(): boolean {
        return this.shippingAssignation.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }


    @action
    public setSelectedShippingAssignment(selectedShippingAssignment?: string) {
        this.selectedShippingAssignment = selectedShippingAssignment;
        this.isSearchDialogOpen = false;
    }

}

export default ShippingStatusSelectStore;
