import React from 'react';
import IconProperties from "./IconProperties";


const ProductIcon: React.FC<IconProperties> = ({
                                                   className,
                                                   fontSize
                                               }) => {
    return (
        <svg className={className} width="1em" height="1em" x="0px" y="0px" viewBox="0 0 1000 1000" style={{fontSize: fontSize}}>
            <g>
                <path fill="currentColor"
                      d="M177.5,592.2v161.5l323.9,127.8l325.1-131.7V587.3L990,517.9L825.5,349.4L990,190.9l-327-72.3L511.4,243.4L347.8,118.5L12.9,183.9l167.5,166.4h21.8l299.2-96.1l300.3,96.1L519.3,473.3L202.2,356.3v-15.8l-23.3,13.9L10,518.8L177.5,592.2z"/>
            </g>
        </svg>
    )
}
export default ProductIcon;