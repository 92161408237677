import React from 'react';
import {useStyles} from "../ShopProducts.style";
import clsx from "clsx";
import ShopProductRowProperties from "./ShopProductRowProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import { useI18nContext } from '../../../contexts/I18nContext';
import { CurrencyModule } from '../../../util/Currency';
import ShowImage from '../../../shared_components/Info/ShowImage/ShowImage';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowArticleDescription from '../../../shared_components/Info/ShowArticleDescription/ShowArticleDescription';
import Currency from '../../../entities/Currency';
import NewShopProductButton from '../../../shared_components/Buttons/NewShopProductButton/NewShopProductButton';
import RemoveShopProductButton from '../../../shared_components/Buttons/RemoveShopProductButton/RemoveShopProductButton';
import Access from '../../../shared_components/Access/AllowAccess/Access';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import ViewShopProductStockTransactionsButton from '../../../shared_components/Buttons/ViewShopProductStockTransactionsButton/ViewShopProductStockTransactionsButton';


const ShopProductRowMedium: React.FC<ShopProductRowProperties> = ({
                                                            shopProduct,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.shop_product_info_container)}>
            <ShowImage imageName={shopProduct.defaultImage} width={70} height={70}/>
            <ShowText text={shopProduct.name} className={classes.shop_product_name_container}/>
            <ShowArticleDescription description={shopProduct.description}/>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Precio:`}</div>
                    <div className={classes.value}>{shopProduct.price && CurrencyModule.format(shopProduct.price, Currency.USD)}</div>
                </div>
                <div className={classes.currency}>{i18n.translate(Currency.USD)}</div>
            </div>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Inventario:`}</div>
                    <div className={classes.value}>{shopProduct.stockAmount}</div>
                </div>
                <div>
                    <small>Notificar cuando inventario menor que: <strong>{shopProduct.minStockWarning}</strong></small>
                </div>
            </div>
            <ExpandoMenu>
                <>
                    <AllowAccess any={[Access.CREATE_SHOP_PRODUCT]}>
                        <NewShopProductButton
                            size={ButtonSize.SMALL}
                            onSave={onUpdate}
                            shopProductId={shopProduct.id}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    <AllowAccess any={[Access.LIST_SHOP_PRODUCT_STOCK_TRANSACTIONS]}>
                        <ViewShopProductStockTransactionsButton 
                            size={ButtonSize.SMALL}
                            shopProductId={shopProduct.id}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    {/* <UploadShopProductImageButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        articleId={shopProduct.id}
                        showText
                        type={ButtonType.SECONDARY}
                    /> */}
                    <AllowAccess any={[Access.REMOVE_SHOP_PRODUCT]}>
                        <RemoveShopProductButton
                            size={ButtonSize.SMALL}
                            onSave={onUpdate}
                            shopProduct={shopProduct}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                </>
            </ExpandoMenu>
        </div>
    )
}

export default ShopProductRowMedium;
