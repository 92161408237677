import CommonStore from "../../stores/CommonStore";
import {makeObservable, observable} from "mobx";
import CustomRangeRepository, {RangeName} from "../../ui_components/DateTimeRageSelect/CustomRangeRepository";
import I18nStore from "../../stores/I18nStore";

class DashboardStore {
    public commonStore: CommonStore;
    public from: number | undefined;
    public to: number | undefined;

    constructor(commonStore: CommonStore, i18n:I18nStore, selectedRange:RangeName) {
        const range = CustomRangeRepository.getRange(i18n, selectedRange);
        this.commonStore = commonStore;
        this.from = range?.from?.valueOf();
        this.to = range?.to?.valueOf();
        makeObservable(this, {
            from: observable,
            to: observable
        });
    }
}

export default DashboardStore;