import React from 'react';
import CustomCategoryTypeSelectProperties from "./CustomCategoryTypeSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import SelectOption from "../../../ui_components/Select/SelectOption";
import Select from "../../../ui_components/Select/Select";
import {ShoppingBasket} from "@material-ui/icons";
import CustomCategoryType from "../../../entities/CustomCategoryType";
import {ShoppingBag} from "@mui/icons-material";

const CustomCategoryTypeSelect: React.FC<CustomCategoryTypeSelectProperties> = ({
                                                                        selected,
                                                                        showSelectAllOption = false,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {

    const i18n = useI18nContext();

    let opt = showSelectAllOption ? [new SelectOption(i18n.translate("ALL_MALE"), "ALL", 'Todos los tipos', ShoppingBasket)] : [];

    opt.push(...[
        new SelectOption(
            i18n.translate(CustomCategoryType.GROUP.valueOf().toString()),
            CustomCategoryType.GROUP.toString(),
            i18n.translate(CustomCategoryType.GROUP.valueOf().toString()),
            ShoppingBasket),
        new SelectOption(
            i18n.translate(CustomCategoryType.INDIVIDUAL.valueOf().toString()),
            CustomCategoryType.INDIVIDUAL.toString(),
            i18n.translate(CustomCategoryType.INDIVIDUAL.valueOf().toString()),
            ShoppingBag),
    ]);

    const selectedOption = opt.find(t => t.value === selected);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value as CustomCategoryType)
    }

    return (
        <Select
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : i18n.translate("CUSTOM_CATEGORY_TYPE")}
            placeholder={i18n.translate("CUSTOM_CATEGORY_TYPE_PLACEHOLDER")}
            selected={selectedOption}
            icon={ShoppingBasket}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default CustomCategoryTypeSelect;