import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./ArticleDelivery.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import {useCommonContext} from "../../contexts/CommonContext";
import ArticleDeliveryStore from './ArticleDeliveryStore';
import GeoSelect from "../../shared_components/Selects/GeoSelect/GeoSelect";
import DeliveryPerson from '../../entities/DeliveryPerson';
import ButtonType from "../../ui_components/Button/ButtonType";
import NewArticleDeliveryButton from "../../shared_components/Buttons/NewArticleDeliveryButton/NewArticleDeliveryButton";
import ArticleDeliveryRow from './ArticleDeliveryRow/ArticleDeliveryRow';
import DeliveryPersonSelect from '../../shared_components/Selects/DeliveryPersonSelect/DeliveryPersonSelect';
import RemittanceStatusSelect from '../../shared_components/Selects/RemittanceStatusSelect/RemittanceStatusSelect';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import CustomRangeRepository, {RangeName} from '../../ui_components/DateTimeRageSelect/CustomRangeRepository';
import AllowAccess from "../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../shared_components/Access/AllowAccess/Access";
import ClientContact from '../../entities/ClientContact';
import ChooseDeliveryPersonButton
    from '../../shared_components/Buttons/ChooseDeliveryPersonButton/ChooseDeliveryPersonButton';
import AgencySelect from "../../shared_components/Selects/AgencySelect/AgencySelect";
import Agency from "../../entities/Agency";
import PrintToDeliverButton from '../../shared_components/Buttons/PrintToDeliverButton/PrintToDeliverButton';
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";

const ArticleDelivery: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = new ArticleDeliveryStore(commonStore);
    const classes = useStyles();

    const customRanges = CustomRangeRepository.getRanges(i18n);
    const range = customRanges.find(t => t.key === RangeName.LAST_FIFTEEN_DAYS);
    store.setFromFilter(range?.from?.valueOf() || undefined);
    store.setToFilter(range?.to?.valueOf() || undefined);

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onArticleRemittanceClick = (articleRemittanceId: string) => {
        store.selectedArticleRemittanceId = articleRemittanceId;
    }

    const onDeliveryPersonSelect = (deliveryPerson?: DeliveryPerson) => {
        if (deliveryPerson != null) {
            store.setArticleRemittanceDeliveryPerson([store.selectedArticleRemittanceId], deliveryPerson);
        } else {
            store.unsetArticleRemittanceDeliveryPerson();
        }
    }

    const onDeliveryPersonSelectForSeveralArticleDelivery = (deliveryPerson?: DeliveryPerson) => {
        if (deliveryPerson != null) {
            store.setArticleRemittanceDeliveryPerson(store.selectedArticleRemittanceIds, deliveryPerson);
            store.selectedArticleRemittanceIds = [];
        }
    }

    const onContactSelect = (clientContact: ClientContact) => {
        store.updateArticleRemittanceContact(clientContact);
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onAgencyChange = (newAgency?: Agency) =>
        (store.agencyFilter = newAgency);

    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.fromFilter = from;
        store.toFilter = to;
    }

    const onUpdate = async () => {
        await store.getElements();
    }

    const onArticleDeliveryOrderSelect = (articleRemittanceId: string) => {
        const index = store.selectedArticleRemittanceIds.findIndex(t => t === articleRemittanceId);
        store.selectedArticleRemittanceIds = index >= 0 ?
            store.selectedArticleRemittanceIds.filter(t => t !== articleRemittanceId) :
            [...store.selectedArticleRemittanceIds, articleRemittanceId];
    }

    const onArticleDeliveryOrderToggleSelectAll = (value: boolean) => {
        store.selectedArticleRemittanceIds = value ? store.articleRemittances.map(t => t.id) : [];
    }

    return (
        <Observer>
            {() => (
                <div className={classes.article_container}>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("MONEY_DELIVERY_ORDER_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <DateTimeRangeSelect selectedRange={RangeName.LAST_FIFTEEN_DAYS} label={i18n.translate("DATES")}
                                             onChange={onDateChange}/>
                        <AllowAccess any={[Access.SEND_ORDERS_TO_AGENCIES]}>
                            <AgencySelect selected={store.agencyFilter} onChange={onAgencyChange}/>
                        </AllowAccess>
                        <AllowAccess any={[Access.LIST_DELIVERY_PERSON]}>
                            <DeliveryPersonSelect
                                onChange={(c) => store.setDeliveryPersonToFilter(c)}
                                selected={store.deliveryPersonFilter}/>
                        </AllowAccess>
                        <RemittanceStatusSelect selectedStatus={store.statusFilter} onChange={(c) => store.setStatusToFilter(c)}/>
                        <GeoSelect selected={store.geoFilter} onChange={(value) => {
                                    store.geoFilter = value
                                }}/>
                        <div className={classes.filter_buttons_container}>
                            <Button
                                onClick={() => {
                                    store.search()
                                }}
                                type={ButtonType.PRIMARY}
                                className={classes.search_btn}
                                text={i18n.translate("SEARCH")}
                            />
                            <Button
                                onClick={() => {
                                    store.clearFilter()
                                }}
                                className={classes.search_btn}
                                text={i18n.translate("CLEAN")}
                            />
                        </div>
                    </Box>
                    <div className={classes.article_table}>
                        <Box className={classes.tools_container}
                             width={Width.FULL_WIDTH}
                             justifyContent={Alignment.END}>
                            <Checkbox text={''}
                                selected={store.selectedArticleRemittanceIds.length === store.articleRemittances.length}
                                onChange={onArticleDeliveryOrderToggleSelectAll} className={classes.select_all_checkbox}/>
                            <ChooseDeliveryPersonButton
                                onSelect={onDeliveryPersonSelectForSeveralArticleDelivery}
                                type={ButtonType.SECONDARY} disabled={store.haveNotSelected}
                                orderByGeosIdProximity={store.articleRemittances.filter(t => store.selectedArticleRemittanceIds.indexOf(t.id) >= 0).map(t => t.geo.id)}/>
                            <PrintToDeliverButton productOrderIds={store.selectedArticleRemittanceIds}
                                                  disabled={store.selectedArticleRemittanceIds.length === 0}
                                                  showText={true}
                                                  type={ButtonType.SECONDARY}/>
                            <NewArticleDeliveryButton type={ButtonType.SECONDARY}/>
                        </Box>
                        {!store.isEmpty &&
                            <>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={i18n.translate("ARTICLE_REMITTANCES")}
                                                pagination={store.pagination}/>
                                <div className={classes.elements_container}>
                                    {store.articleRemittances.map((articleRemittance, i) => (
                                        <ArticleDeliveryRow
                                            filter={store.filter}
                                            onArticleDeliveryOrderClick={onArticleRemittanceClick}
                                            onArticleDeliveryOrderSelect={onArticleDeliveryOrderSelect}
                                            onDeliveryPersonSelect={onDeliveryPersonSelect}
                                            onContactSelect={onContactSelect}
                                            articleDeliveryOrder={articleRemittance} key={i}
                                            selectedDeliveryOrderIds={store.selectedArticleRemittanceIds}
                                            onUpdate={onUpdate}
                                        />
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={i18n.translate("ARTICLE_REMITTANCES")}
                                    pagination={store.pagination}/>
                            </>
                        }
                    </div>
                    {store.isEmpty &&
                    <MessageCard icon={FastfoodIcon} title={i18n.translate("NO_ARTICLE_DELIVERY_TITLE")}
                                subtitle={i18n.translate("NO_ARTICLE_DELIVERY_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default ArticleDelivery;
