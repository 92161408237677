export module AppStorage {
    export function save<T>(name: string, value: T) {
        sessionStorage.setItem(name, JSON.stringify(value));
    }

    export function get<T>(name: string, defaultValue: T | undefined): T | undefined {
        try {
            const storedValue = sessionStorage.getItem(name);
            if (!storedValue) return defaultValue;
            const parsed = JSON.parse(storedValue);
            return hydrate<T>(parsed);
        } catch (e) {
            return defaultValue;
        }
    }

    function hydrate<T>(data: T): T {
       return data as T;
    }
}

export enum AppStorageValues {
    SELECTED_AGENCY = 'selected-agency',
    SELECTED_SELLER = 'selected-seller',
}