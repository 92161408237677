import React from 'react';
import AgencySellerSelectProperties from "./AgencySellerSelectProperties";
import {useStyles} from "./AgencySellerSelect.style";
import {Observer} from "mobx-react-lite";
import Select from "../../../ui_components/Select/Select";
import {useUserContext} from "../../../contexts/UserContext";
import AgencySellerSelectStore from "./AgencySellerSelectStore";
import {useCommonContext} from "../../../contexts/CommonContext";
import SelectOption from "../../../ui_components/Select/SelectOption";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import {useI18nContext} from "../../../contexts/I18nContext";
import PageLoading from "../../../ui_components/PageLoading/PageLoading";
import AgencySelect from '../AgencySelect/AgencySelect';
import Agency from '../../../entities/Agency';

const AgencySellerSelect: React.FC<AgencySellerSelectProperties> = ({
                                                                        onAgencyChange,
                                                                        onSellerChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        selectedSeller,
                                                                        selectedAgency
                                                                    }) => {
    const classes = useStyles();
    const userStore = useUserContext();
    const commonStore = useCommonContext();
    const store = new AgencySellerSelectStore(commonStore, userStore.currentUser()?.agencyId || "", userStore.currentUser()?.id || "", selectedAgency, selectedSeller);
    const i18n = useI18nContext();

    const mapSelectedSeller = () => {
        if (store.selectedSeller) {
            let name = store.selectedSeller.name;
            if (userStore.currentUser()?.id === store.selectedSeller.id) {
                name += ` (${i18n.translate("ME")})`;
            }
            return new SelectOption(name, store.selectedSeller.id, store.selectedSeller.email, PeopleAltIcon)
        }
    }
    const mapSellers = () => {
        return store.sellers.map(a => new SelectOption((userStore.currentUser()?.id === a.id) ? `${a.name} (${i18n.translate("ME")})` : a.name, a.id, a.email, PeopleAltIcon));
    }

    const onAgencySelectChange = async (selected?: Agency) => {
        await store.setSelectedAgency(selected?.id);
        onAgencyChange(store.selectedAgency);
        onSellerChange(store.selectedSeller);
    }

    const onSellerSelectChange = async (selected: SelectOption) => {
        await store.setSelectedSeller(selected.value);
        onSellerChange(store.selectedSeller);
    }

    return (
        <Observer>
            {() => (
                <div className={classes.agency_seller_container}>
                    <PageLoading isLoading={store.loading} text={i18n.translate("LOADING")}/>
                    <AgencySelect
                        selected={store.selectedAgency}
                        onChange={onAgencySelectChange}
                        showCleanButton={false}
                        initialLoadAmount={1000}/>
                    <Select label={i18n.translate("SELECT_SELLER")}
                            selected={mapSelectedSeller()}
                            onChange={onSellerSelectChange}
                            elements={mapSellers()}/>
                </div>
            )}
        </Observer>
    )
}

export default AgencySellerSelect;