import React from 'react';
import AgencySelectProperties from "./AgencySelectProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AgencySelect.style";
import AgencySellerSelect from "../../../Selects/AgencySellerSelect/AgencySellerSelect";
import Agency from "../../../../entities/Agency";
import Seller from "../../../../entities/Seller";
import { AppStorage, AppStorageValues } from '../../../../util/AppStorage';

const AgencySelect: React.FC<AgencySelectProperties> = ({
                                                            store
                                                        }) => {
    const classes = useStyles();

    const onAgencyChange = (agency?: Agency) => {
        AppStorage.save(AppStorageValues.SELECTED_AGENCY, agency);
        store.selectedAgency = agency;
        store.selectedContact = undefined;
        store.selectedClient = undefined;
    }

    const onSellerChange = (seller?: Seller) => {
        AppStorage.save(AppStorageValues.SELECTED_SELLER, seller);
        store.selectedSeller = seller;
    }

    return <Observer>
        {() => (
            <div className={classes.container}>
                <AgencySellerSelect
                    onAgencyChange={onAgencyChange}
                    onSellerChange={onSellerChange}
                    selectedAgency={store.selectedAgency}
                    selectedSeller={store.selectedSeller}
                />
            </div>
        )}
    </Observer>
}

export default AgencySelect;
