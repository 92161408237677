import React from 'react';
import ClientRowProperties from "./ClientRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ClientRowMedium from "./ClientRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ClientRowSmall from "./ClientRowSmall";


const ClientRow: React.FC<ClientRowProperties> = ({
                                                      client,
                                                      onUpdate
                                                  }) => {
    return (
        <>
            <ViewSmall>
                <ClientRowSmall onUpdate={onUpdate} client={client}/>
            </ViewSmall>
            <ViewMedium>
                <ClientRowMedium onUpdate={onUpdate} client={client} />
            </ViewMedium>
            <ViewLarge>
                <ClientRowMedium onUpdate={onUpdate} client={client} />
            </ViewLarge>
        </>
    )
}

export default ClientRow;
