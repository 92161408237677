
class CustomCategoryWeightRange {

    public id:string
    public from: number
    public to:number
    public cost:number
    public price:number

    constructor(id?:string,from?: number,to?:number, cost?:number,price?:number) {
        this.id = (id==null) ? "" : id
        this.from = (from==null) ? 0 : from
        this.to = (to==null) ? 0 : to
        this.cost = (cost==null)? 0 : cost
        this.price = (price==null)? 0 : price
    }
}
export default CustomCategoryWeightRange