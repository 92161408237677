import React from 'react';
import {useStyles} from "./ImageCardSkeleton.style";
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@material-ui/core';

const ImageCardSkeletonSkeleton: React.FC = () => {

    const classes = useStyles();

    return (
        <Box className={classes.container_classes}>
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
            <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="40%" />
            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
        </Box>
    )
}

export default ImageCardSkeletonSkeleton;