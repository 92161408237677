import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        parent_box: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.common.white,
            flexGrow: 1,
            justifyContent: 'flex-start'
        },
        step_completed_message: {
            marginTop: '2px',
            marginBottom: '1px'
        },
        steps_button_container: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '2px',
            marginTop: '10px',
            justifyContent: 'space-between',
            gap: '10px'
        },
        steps_button_directions_container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: '10px'
        },
        steps_header_container: {
            overflow: 'auto',
            borderBottom: `1px solid ${theme.palette.grey["400"]}`,
            backgroundColor: theme.palette.grey["100"],
        },
        disabled: {
            cursor: 'default',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[400],
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            },
            "& *": {
                color: theme.palette.grey[400],
                fill: theme.palette.grey[400],
            }
        },
        btn_container: {
            margin: '20px'
        },
        hide_small: {
            "@media (max-width: 800px)": {
                display: 'none'
            }
        }
    }))
;

export {useStyles}