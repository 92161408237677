import React, {createRef, useEffect, useState} from 'react';
import {useStyles} from "./MultiSelect.style";
import {useCommonStyles} from "../common/CommonStyles.style";
import MultiSelectProperties from "./MultiSelectProperties";
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MultiSelectOption from "./MultiSelectOption";
import Loading from "../PageLoading/Loading";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const MultiSelect: React.FC<MultiSelectProperties> = ({
                                                          elements,
                                                          error,
                                                          label,
                                                          placeholder = '',
                                                          helpText,
                                                          onChange,
                                                          disabled = false,
                                                          loading = false,
                                                          icon: DefaultIcon,
                                                          fullWidth = false,
                                                          className = '',
                                                      }: MultiSelectProperties) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const menuRef = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<MultiSelectOption[]>([]);
    const [selected, setSelected] = useState<MultiSelectOption[]>([]);

    useEffect(() => {
        setOptions(elements);
    }, [elements]);

    useEffect(() => {
        setSelected(options.filter(s => s.selected))
    }, [options]);

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                setOpen(false);
                onChange(selected);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuRef])

    const onElementClick = (element: MultiSelectOption) => {
        element.selected = !element.selected;
        setOptions([...options]);
    }

    const Icon = () => {
        if (DefaultIcon) {
            return (
                <div className={classes.icon_container}>
                    <DefaultIcon/>
                </div>)
        }
        return <></>
    }

    const containerClasses = clsx(classes.select_container, className, fullWidth && commonClasses.fullWidth);
    return (
        <div className={containerClasses} ref={menuRef}>
            <div className={classes.label}>{label}</div>
            <div className={classes.input_container} onClick={() => setOpen(loading ? open : !open)}>
                {!loading && <Icon/>}
                {loading && <Loading fontSize={22}/>}
                <div className={classes.info_container}>
                    {selected &&
                    <div className={classes.selected_container}>
                        {selected.map((s, i) => (
                            <div className={classes.selected} key={i}>{s.primaryText}</div>
                        ))}
                    </div>
                    }
                    {selected.length === 0 && placeholder}
                </div>
                <div className={classes.expand_icon}>
                    {open && <ExpandLessIcon/>}
                    {!open && <ExpandMoreIcon/>}
                </div>
            </div>
            {open && !loading &&
            <div className={classes.options_container}>
                {options.map((el, i) => (
                    <div key={i} className={clsx(classes.option, el.selected && classes.active)}
                         onClick={() => onElementClick(el)}>
                        {el.selected && <CheckBoxIcon/>}
                        {!el.selected && <CheckBoxOutlineBlankIcon/>}
                        <div className={classes.text_container}>
                            <div>{el.primaryText}</div>
                            <div> {el.secondaryText}</div>
                        </div>
                    </div>))}
            </div>
            }
        </div>
    )
}
export default MultiSelect;