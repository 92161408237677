import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import PublicIcon from '@mui/icons-material/Public';
import NationalityInputProperties from "./NationalityInputProperties";

const NationalityInput: React.FC<NationalityInputProperties> = ({
                                                                  value,
                                                                  placeholder,
                                                                  onChange,
                                                                  disabled,
                                                                  fullWidth = false,
                                                                  className = '',
                                                                  error
                                                              }) => {

    const [name, setName] = useState(value);

    useEffect(() => {
        setName(value);
    }, [value]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newName = event.target.value;
        setName(newName);
        onChange(newName);
    }

    return (
        <TextInput
            error={error}
            label={'Nacionalidad'}
            value={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onNameChange}
            startIcon={PublicIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default NationalityInput;