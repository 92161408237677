import React from 'react';
import ArticleRowProperties from "./ArticleRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ArticleRowMedium from "./ArticleRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ArticleRowSmall from "./ArticleRowSmall";


const ArticleRow: React.FC<ArticleRowProperties> = ({
                                                    article,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ArticleRowSmall onUpdate={onUpdate} article={article}/>
            </ViewSmall>
            <ViewMedium>
                <ArticleRowMedium onUpdate={onUpdate} article={article} />
            </ViewMedium>
            <ViewLarge>
                <ArticleRowMedium onUpdate={onUpdate} article={article} />
            </ViewLarge>
        </>
    )
}

export default ArticleRow;
