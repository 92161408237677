import React from 'react';
import {useStyles} from "../CustomCategoryWeightRange.style";
import clsx from "clsx";
import CustomCategoryWeightRangeRowProperties from "./CustomCategoryWeightRangeRowProperties";
import ShowInterval from '../../../../../shared_components/Info/ShowInterval/ShowInterval';
import ShowText from '../../../../../shared_components/Info/ShowText/ShowText';
import { useI18nContext } from '../../../../../contexts/I18nContext';


const CustomCategoryWeightRangeRowSmall: React.FC<CustomCategoryWeightRangeRowProperties> = ({customCategoryWeightRange}) => {

    const i18n = useI18nContext();
    const classes = useStyles();


    return (
        <div className={clsx(classes.foodPackage_info_container_small)}>
            <ShowInterval from={customCategoryWeightRange.from} to={customCategoryWeightRange.to}/>
            <ShowText text={customCategoryWeightRange.cost.toString()} title={i18n.translate("COST")}/>
            
            <div className={classes.menu_container_small}>
                {/*<NewMoneyDeliveryFeeButton*/}
                {/*        size={ButtonSize.SMALL}*/}
                {/*        onSave={onUpdate}*/}
                {/*        showText={false}*/}
                {/*        moneyServiceFeeId={customCategoryWeightRange.id}*/}
                {/*        type={ButtonType.SECONDARY}*/}
                {/*    />*/}
                {/*{<RemoveMoneyServiceFeeButton*/}
                {/*    size={ButtonSize.SMALL}*/}
                {/*    onSave={onUpdate}*/}
                {/*    moneyServiceFee={customCategoryWeightRange}*/}
                {/*    showText={false}*/}
                {/*    type={ButtonType.SECONDARY}*/}
                {/*/> }*/}
            </div>
        </div>
    )
}

export default CustomCategoryWeightRangeRowSmall;
