import React from 'react';
import {useI18nContext} from "../../../../contexts/I18nContext";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {Observer} from "mobx-react-lite";
import Button from "../../../../ui_components/Button/Button";
import Box from "../../../../ui_components/Box/Box";
import Width from "../../../../ui_components/common/enums/Width";
import Alignment from "../../../../ui_components/common/enums/Alignment";
import ShowPagination from "../../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../../ui_components/MessageCard/MessageCard";
import DateTimeRangeSelect from "../../../../ui_components/DateTimeRageSelect/DateTimeRangeSelect";
import ShowDate from "../../../Info/ShowDate/ShowDate";
import clsx from "clsx";
import TransactionStatus from "../../../../entities/TransactionStatus";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {useUserContext} from "../../../../contexts/UserContext";
import Access from "../../../Access/AllowAccess/Access";
import ShowGeneralComment from '../../../Info/ShowGeneralComment/ShowGeneralComment';
import ArticleTransactionsProperties from './ArticleTransactionsProperties';
import { useStyles } from './ArticleTransactions.style';
import ShowArticleAmount from '../../../Info/ShowArticleAmount/ShowArticleAmount';
import ArticleSelect from '../../../Selects/ArticleSelect/ArticleSelect';
import Article from '../../../../entities/Article';


const ArticleTransactions: React.FC<ArticleTransactionsProperties> = ({
                                                                                store
                                                                            }) => {
    const classes = useStyles();

    const i18n = useI18nContext();
    const userStore = useUserContext();

    const onArticleChange = (article?: Article) => store.articleFilter = article;

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToArticlePage(page, pageSize);
    }

    if (!userStore.hasAccess(Access.GET_TRANSACTIONS.valueOf())) {
        return <></>;
    }

    return <Observer>
        {() => (
            <>
            
                <div className={classes.contacts_container}>
                    <div className={classes.body}>
                        <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                                justifyContent={Alignment.SPACE_BETWEEN}>
                            <DateTimeRangeSelect
                                selectedFrom={store.articleFrom}
                                selectedTo={store.articleTo}
                                label={i18n.translate("DATE_RANGE")}
                                onChange={(from, to) => {
                                    store.articleFrom = from;
                                    store.articleTo = to;
                                }}/>
                            <ArticleSelect
                                label={'Combo'}
                                onChange={onArticleChange}
                                selected={store.articleFilter}
                                required={true}
                                fullWidth/>
                            <div className={classes.btn_container}>
                                <Button
                                    onClick={() => {
                                        store.searchArticleTransactions()
                                    }}
                                    className={classes.search_btn}
                                    text={i18n.translate("SEARCH")}
                                />
                                <Button
                                    onClick={() => {
                                        store.cleanArticleFilter()
                                    }}
                                    className={classes.search_btn}
                                    startIcon={<FilterAltOffIcon/>}
                                    tooltip={i18n.translate("CLEAN_FILTER")}
                                />
                            </div>
                        </Box>
                        {!store.isArticleEmpty &&
                        <div className={classes.contacts_table}>
                            <ShowPagination onChange={onPaginationChange}
                                            elementLabel={i18n.translate("TRANSACTIONS")}
                                            pagination={store.articlePagination}/>
                            <div className={classes.elements_container}>
                                {store.articleTransactions.map((transaction, i) => (
                                    <div key={i} className={clsx(
                                        classes.contact_info_container,
                                        transaction.status === TransactionStatus.FAILURE && classes.error_container
                                    )}>
                                        <ShowDate timestamp={transaction.createdAt}/>
                                        <ShowArticleAmount
                                            value={transaction.direction === "OUT" && transaction.amount > 0 ?
                                                transaction.amount * -1 : transaction.amount}
                                                articleName={transaction.articleName}
                                            valueBefore={transaction.balanceToBefore}
                                        />
                                        <ShowGeneralComment value={transaction.comment}/>
                                    </div>
                                ))}
                            </div>
                            <ShowPagination
                                onChange={onPaginationChange}
                                elementLabel={i18n.translate("TRANSACTIONS")}
                                pagination={store.articlePagination}/>
                        </div>
                        }
                        {store.isArticleEmpty &&
                        <MessageCard icon={AccountBalanceIcon} title={i18n.translate("NO_TRANSACTIONS_TITLE")}
                                        subtitle={i18n.translate("NO_TRANSACTIONS_SUBTITLE")}/>}
                    </div>
                </div>
            </>
        )}
    </Observer>
}

export default ArticleTransactions;
