import React from 'react';
import clsx from "clsx";
import Product from '../../../../entities/productOrder/topup/Product';
import ShowTopupCostsButtonRowProperties from './ShowTopupCostsButtonRowProperties';
import { useStyles } from '../ShowTopupCostsButton.style';
import ShowProduct from '../../../Info/ShowProduct/ShowProduct';
import ShowText from '../../../Info/ShowText/ShowText';

const ShowTopupCostsButtonRowSmall: React.FC<ShowTopupCostsButtonRowProperties> = ({topupPrice, products}) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_topup_price_info_container_small)}>
            <ShowProduct value={products.find((t: Product) => t.id === topupPrice.productId)}/>
            <ShowText text={topupPrice.salePrice ? '$' + topupPrice.salePrice.toString() : '-'} title={'Valor de costo'}/>          
        </div>
    )
}

export default ShowTopupCostsButtonRowSmall;
