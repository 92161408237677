import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import ClientContact from "../../../entities/ClientContact";
import { ClientContactService } from "../../../service/ClientContactService";


class ContactSelectListStore {

    public loadingContacts: boolean;
    public commonStore: CommonStore;
    public contactFilter?: string;
    public contacts: ClientContact[];
    public contactsPagination: Pagination;
    public selectedContact?: ClientContact;
    public clientId: string;

    constructor(commonStore: CommonStore, clientId: string, selectedContact?: ClientContact) {
        this.loadingContacts = false;
        this.commonStore = commonStore;
        this.contactsPagination = new Pagination(1, 15, 0, 0);
        this.contacts = [];
        this.selectedContact = selectedContact;
        this.clientId = clientId;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.selectedContact);
    }

    @action
    public async searchContacts(): Promise<void> {
        this.loadingContacts = true;
        const response = await ClientContactService.get(1, 1000, this.clientId, this.contactFilter);
        if (response.success) {
            runInAction(() => {
                this.contacts = response.data?.data || [];
                this.contactsPagination = response.data?.pagination || this.contactsPagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loadingContacts = false;
    }

    get isContactsEmpty(): boolean {
        return this.contacts.length === 0;
    }

    @action
    public async setContactFilter(filter: string) {
        runInAction(() => {
            this.contactFilter = filter;
            this.searchContacts();
        })
    }

    @action
    public setSelectedContact(contact?: ClientContact) {
        runInAction(() => {
            this.selectedContact = contact;
        });
    }

}

export default ContactSelectListStore;