import Currency from "./Currency";

class Balance {
    public id: string;
    public amount: number;
    public currency: Currency;


    constructor(id: string, amount: number, currency: Currency) {
        this.amount = amount;
        this.id = id;
        this.currency = currency;
    }

}

export default Balance;