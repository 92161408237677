import React from 'react';
import {useStyles} from "./GlobalButton.style";
import GlobalButtonProperties from "./GlobalButtonProperties";
import clsx from "clsx";
import {motion} from "framer-motion";
import Button from "../Button/Button";
import ButtonType from "../Button/ButtonType";
import ButtonSize from "../Button/ButtonSize";


const GlobalButton: React.FC<GlobalButtonProperties> = ({
                                                            className = '',
                                                            text,
                                                            onClick,
                                                            startIcon,
                                                            endIcon,
                                                            visible = false,
                                                            disabled = false,
                                                            loading = false,
                                                            type = ButtonType.DEFAULT,
                                                        }) => {
    const classes = useStyles();
    if (!text || !visible) {
        return <></>;
    }

    const containerClasses = clsx(
        classes.global_button_container,
        className
    );

    return (
        <motion.div
            className={containerClasses}
            transition={{repeatType: "loop", repeat: 1000, duration: 2}}
            animate={{
                scale: [1, 1.2, 1],
            }}
        >
            <Button
                onClick={onClick}
                startIcon={startIcon}
                endIcon={endIcon}
                text={text}
                type={type}
                disabled={disabled}
                loading={loading}
                size={ButtonSize.LARGE}
            />
        </motion.div>

    )
}
export default GlobalButton;