import { action, makeAutoObservable } from "mobx";
import Agency from "../../../entities/Agency";
import { AgencyService } from "../../../service/AgencyService";
import CommonStore from "../../../stores/CommonStore";

class RemoveAgencyStore {

    public agency: Agency;
    public loading: boolean;
    public commonStore: CommonStore;

    public constructor(commonStore: CommonStore, agency: Agency) {
        this.agency = agency;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await AgencyService.remove(this.agency);
        this.commonStore.processErrors(response);
        return response.success;
    }

}

export default RemoveAgencyStore
