import React from 'react';
import ShowMoneyDeliveryCostsButtonRowProperties from './ShowMoneyDeliveryCostsButtonRowProperties';
import ViewSmall from '../../../../ui_components/MediaView/ViewSmall';
import ShowMoneyDeliveryCostsButtonRowSmall from './ShowMoneyDeliveryCostsButtonRowSmall';
import ViewMedium from '../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../ui_components/MediaView/ViewLarge';
import ShowMoneyDeliveryCostsButtonRowMedium from './ShowMoneyDeliveryCostsButtonRowMedium';


const ShowMoneyDeliveryCostsButtonRow: React.FC<ShowMoneyDeliveryCostsButtonRowProperties> = ({
                                                    moneyPrices,
                                                    moneyDeliveryFee
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ShowMoneyDeliveryCostsButtonRowSmall moneyPrices={moneyPrices} moneyDeliveryFee={moneyDeliveryFee}/>
            </ViewSmall>
            <ViewMedium>
                <ShowMoneyDeliveryCostsButtonRowMedium moneyPrices={moneyPrices} moneyDeliveryFee={moneyDeliveryFee} />
            </ViewMedium>
            <ViewLarge>
                <ShowMoneyDeliveryCostsButtonRowMedium moneyPrices={moneyPrices} moneyDeliveryFee={moneyDeliveryFee} />
            </ViewLarge>
        </>
    )
}

export default ShowMoneyDeliveryCostsButtonRow;
