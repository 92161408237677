import Geo from "../../../entities/Geo";

interface GeoSelectProperties {
    label?: string,
    required?: boolean,
    disabled?: boolean,
    levels?: GeoLevel[],
    onChange: (geo?: Geo) => void,
    fullWidth?: boolean,
    selected?: Geo,
    parentIdFilter?: string,
    className?: string,
    error?: string
}

export enum GeoLevel {
    COUNTRY = 1,
    STATE = 2,
    CITY = 3,
    TOWN = 4
}

export default GeoSelectProperties;