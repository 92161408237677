import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    empty_list_container: {
        marginTop: '16px',
        padding: '32px',
        "& svg": {
            fontSize: '80px',
            color: `${theme.palette.secondary.light}1f`
        }
    },
    subtitle: {
        fontSize: '15px',
        color: `${theme.palette.secondary.light}8c`
    },
    title: {
        fontSize: '20px',
    },
    actions_container:{
        display: 'flex',
        marginTop: '16px',
        "& > *:not(:first-child)":{
            marginLeft: '8px'
        }
    }
}));

export {useStyles}