import React from "react";
import InventoryItemRowProperties from "./InventoryItemRowProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import ShowText from "../../../shared_components/Info/ShowText/ShowText";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import NewInventoryItemButton from "../../../shared_components/Buttons/NewInventoryItemButton/NewInventoryItemButton";
import RemoveInventoryItemButton
    from "../../../shared_components/Buttons/RemoveInventoryItemButton/RemoveInventoryItemButton";
import {useStyles} from "../InventoryItem.style";
import { useUserContext } from "../../../contexts/UserContext";
import Access from "../../../shared_components/Access/AllowAccess/Access";
import { CurrencyModule } from "../../../util/Currency";

const InventoryItemRowMedium:React.FC<InventoryItemRowProperties> = ({
                                                                                         inventoryItem,
                                                                                         onUpdate = ()=>{},
                                                                                         filter
                                                                                     }) => {
    const classes = useStyles()
    const i18n = useI18nContext()
    const userStore = useUserContext()

    return(
        <div
            className={clsx(classes.items_inventory_info_container)}>
            <ShowText title={i18n.translate("NAME")} text={inventoryItem.name} textToHighlight={filter}/>
            <ShowText title={i18n.translate("INVENTORY_ITEMS_CATEGORY")} text={inventoryItem.categoryInfo.name || "-"}/>
            <ShowText title={"Precio Individual"} text={CurrencyModule.format(inventoryItem.price)}/>
            {(userStore.hasAccess(Access.CREATE_EDIT_INVENTORY_ITEM) 
                || userStore.hasAccess(Access.REMOVE_INVENTORY_ITEM)) &&
                    <ExpandoMenu>
                        <>
                            {userStore.hasAccess(Access.CREATE_EDIT_INVENTORY_ITEM) &&
                                <NewInventoryItemButton
                                    type={ButtonType.SECONDARY}
                                    onSave={onUpdate}
                                    inventoryItemId={inventoryItem.id}
                                    size={ButtonSize.SMALL}
                                    showText/>
                            }
                            <RemoveInventoryItemButton
                                type={ButtonType.SECONDARY}
                                onSave={onUpdate}
                                inventoryItem={inventoryItem}
                                size={ButtonSize.SMALL}/>
                        </>
                    </ExpandoMenu>
            }
        </div>
    )
}
export default InventoryItemRowMedium