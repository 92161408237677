import React, {useEffect, useState} from 'react';
import ClientContactSelectProperties from "./ClientContactSelectProperties";
import {useStyles} from "./ClientContactSelect.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import {Observer} from "mobx-react-lite";
import SelectClientPanel from "./search/searchClient/SearchClientPanel";
import SelectContactPanel from "./search/searchContact/SearchContactPanel";
import Modal from "../../../ui_components/Modal/Modal";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Client from "../../../entities/Client";
import ClientContact from "../../../entities/ClientContact";
import ShowPhoneNumber from "../../Info/ShowPhoneNumber/ShowPhoneNumber";

const ClientContactSelect: React.FC<ClientContactSelectProperties> = ({
                                                                          onClientChange,
                                                                          onContactChange,
                                                                          onObservationsChange,
                                                                          disabled,
                                                                          fullWidth = false,
                                                                          modal = false,
                                                                          className = '',
                                                                          agencyId ,
                                                                          onlyClientSelection = false,
                                                                          selectedClient,
                                                                          selectedContact,
                                                                          selectedObservations,
                                                                          showObservationsInput = false
                                                                      }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentClient, setCurrentClient] = useState<Client|undefined>(selectedClient);
    const [currentContact, setCurrentContact] = useState<ClientContact|undefined>(selectedContact);
    const [observations, setObservations] = useState<string|undefined>(selectedObservations);
    const [client, setClient] = useState<Client>();
    const [contact, setContact] = useState<ClientContact>();

    useEffect(() => {
        setCurrentContact(selectedContact);
        setContact(selectedContact);
    }, [selectedContact]);

    const containerClasses = clsx(
        classes.client_contact_container,
        className,
        fullWidth && commonClasses.fullWidth,
    );

    const onContactSelected = () => {
        setContact(currentContact);
        setClient(currentClient);
        setIsModalOpen(false);
        onClientChange(currentClient);
        onContactChange(currentContact);
    }

    const onCurrentClientChange = (client?: Client) => {
        setCurrentClient(client);
        setCurrentContact(undefined);
        setObservations(undefined);
        if (!modal) {
            onClientChange(client);
            onContactChange(undefined);
            onObservationsChange && onObservationsChange(undefined);
        }
    }

    const onCurrentContactChange = (contact?: ClientContact) => {
        setCurrentContact(contact);
        onContactChange(contact);
    }

    const onCurrentObservationsChange = (newObservations?: string) => {
        setObservations(newObservations);
        onObservationsChange && onObservationsChange(newObservations);
    }

    const onModalClose = () => {
        setIsModalOpen(false);
        setCurrentContact(contact);
        setCurrentClient(client);
    }

    if (modal) {
        return (
            <Observer>
                {() => (
                    <>
                        <Modal title={"Seleccione Contacto"} className={classes.modal_container} fullScreen={false}
                               open={isModalOpen} onClose={onModalClose}>
                            <div className={containerClasses}>
                                <SelectClientPanel onClientChange={onCurrentClientChange}
                                                   agencyId={agencyId}
                                                   selectedClient={currentClient}/>
                                <SelectContactPanel onContactChange={setCurrentContact}
                                                    clientId={currentClient?.id}
                                                    showObservationsInput={false}
                                                    agencyId={agencyId}
                                                    selectedContact={currentContact}/>
                            </div>
                            <Button disabled={!currentContact} type={ButtonType.SECONDARY}
                                    className={classes.select_btn} text={'Seleccionar'}
                                    onClick={onContactSelected}/>
                        </Modal>

                        <div className={clsx((contact && client) && classes.data_container)}>
                            {contact && client &&
                                <div className={classes.info_container}>
                                    <div className={classes.client_info_container}>
                                        <div>{`${client.name} ${client.lastName}`}</div>
                                        <ShowPhoneNumber showFlag={false} value={client?.phone}/>
                                    </div>
                                    <DoubleArrowIcon/>
                                    <div className={classes.contact_info_container}>
                                        <div>{contact?.name}</div>
                                        <ShowPhoneNumber showFlag={false} value={contact?.mobilePhone}/>
                                    </div>
                                </div>
                            }
                            <Button
                                className={clsx(classes.search_btn)}
                                onClick={() => setIsModalOpen(true)}
                                text={(!contact || !client) ? 'Seleccione Contacto' : ''}
                                tooltip={'Seleccione Contacto'}
                                size={ButtonSize.SMALL}
                                type={ButtonType.SECONDARY}
                                startIcon={<PersonSearchIcon/>}
                            />
                        </div>
                    </>
                )}
            </Observer>
        )
    }

    return (
        <Observer>
            {() => (
                <div className={containerClasses}>
                    <SelectClientPanel onClientChange={onCurrentClientChange}
                        onlyClientSelection
                        agencyId={agencyId}
                        selectedClient={currentClient}/>
                    {!onlyClientSelection && <SelectContactPanel onContactChange={onCurrentContactChange}
                                        clientId={currentClient?.id}
                                        showObservationsInput={showObservationsInput}
                                        onObservationsChange={onCurrentObservationsChange}
                                        selectedObservations={observations}
                                        agencyId={agencyId}
                                        selectedContact={currentContact}/>}
                </div>
            )}
        </Observer>
    )
}

export default ClientContactSelect;