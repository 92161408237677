import CategoryWithItemsProperties from "./CategoryWithItemsProperties";
import {useStyles} from "../../CustomCategory.style";
import PackageItemsRow from "../PackageItemsRow/PackageItemsRow";
import CategoryPriceSection from "../../../../shared_components/Selects/CategoryPriceSection/CategoryPriceSection";
import React from "react";
import IndividualCategoryPriceSection
    from "../../../../shared_components/Selects/IndividualCategoryPriceSection/IndividualCategoryPriceSection";
import {Observer} from "mobx-react-lite";
import { Paper } from "@material-ui/core";
import FinalCategoryPriceSection from "../../../../shared_components/Selects/FinalCategoryPriceSection/FinalCategoryPriceSection";

const CategoryWithItems:React.FC<CategoryWithItemsProperties> = ({
                                                                     categoryWithItems,
                                                                     onRemovePackageItem,
                                                                     onEditPackageItem,
                                                                     onFinalIndividualPriceChange,
                                                                     onWeightChange,
                                                                     onFinalWeightPriceChange,
                                                                     onFinalPriceChange,
                                                                     onUpdate
}) => {
    const classes = useStyles()

    return(
        <Observer>{()=>(
            <div>
                <div className={classes.prices_container}>
                    <IndividualCategoryPriceSection
                            categoryId={categoryWithItems.categoryInfo.id!!}
                            subFinalPrice={categoryWithItems.subFinalIndividualPrice}
                            finalPrice={categoryWithItems.finalIndividualPrice}
                            onFinalPriceChange={onFinalIndividualPriceChange} />
                    <CategoryPriceSection
                            categoryId={categoryWithItems.categoryInfo.id!!}
                            weightInfo={categoryWithItems.weightInfo}
                            onWeightChange={onWeightChange}
                            onFinalWeightPriceChange={onFinalWeightPriceChange}/>
                    <FinalCategoryPriceSection
                        categoryId={categoryWithItems.categoryInfo.id!!}
                        subFinalPrice={categoryWithItems.subFinalPrice}
                        finalPrice={categoryWithItems.finalPrice}
                        onFinalPriceChange={onFinalPriceChange} />
                </div>
                <div className={classes.elements_container}>
                    <div className={classes.title}>{`${categoryWithItems.categoryInfo.name} (${categoryWithItems.packageItem.length}):`}</div>
                    <div>
                        {categoryWithItems.packageItem.map((packageItem,i)=>(
                            <Paper>
                                <PackageItemsRow
                                    onUpdate = {onUpdate}
                                    packageItem={packageItem}
                                    categoryId={categoryWithItems.categoryInfo.id!!}
                                    onRemovePackageItem={onRemovePackageItem}
                                    onEditPackageItem={onEditPackageItem}
                                    key={i}
                            />
                            </Paper>
                        ))}
                    </div>
                </div>
            </div>
        )}</Observer>
    )
}
export default CategoryWithItems