import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    payment_container: {
        display: 'flex',
        padding: '8px 16px',
        flexDirection: 'column',
        width: '100%',
        boxSizing: "border-box",
    },
    body: {
        display: 'flex',
        flexDirection: "column",
        width: '700px',
        boxSizing: "border-box",
        alignSelf: 'center',
        "@media (max-width: 800px)": {
            width: '100%',
        }
    },
    header: {
        display: 'grid',
        gridTemplateColumns: "150px 200px 300px auto",
        backgroundColor: theme.palette.common.white,
        padding: '16px',
        borderRadius: "4px",
        width: '100%',
        boxSizing: "border-box",
    },
    inputs_container: {
        display: 'grid',
        gridTemplateColumns: "50% 50%",
        gridColumnGap: '8px',
        gridRowGap: '8px',
        borderRadius: '4px',
        marginTop: '16px',
        padding: '16px',
        backgroundColor: theme.palette.common.white,
        "& > div:last-child": {
            gridColumn: 2,
            justifySelf: "end"
        }
    },
    additional_container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '8px 16px',
        width: '100%',
        boxSizing: "border-box",
        borderRadius: '4px',
        backgroundColor: theme.palette.common.white,
    },
    additional_inputs_container: {
        display: 'flex',
        gap: '10px'
    },
    addition_add_btn_container: {
        display: 'flex',
        justifyContent: 'end'
    },
    elements_container: {
        display: 'grid',
        gridTemplateColumns: "repeat( auto-fit, 195px )",
        justifyContent: 'start',
        gridRowGap: 16,
        gridColumnGap: 16,
        borderRadius: '4px',
        padding: '16px',
        backgroundColor: theme.palette.common.white,
        "@media (max-width: 800px)": {
            display: 'flex'
        }
    },
    margin_top_16px: {
        marginTop: '16px',
    },
    margin_top_2px: {
        marginTop: '2px',
    },
    element_row: {
        display: 'flex',
        position: 'relative',
        padding: "8px",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `3px solid ${theme.palette.success.light}`,
        maxWidth: '195px',
        boxSizing: 'border-box'
    },
    empty_card:{
        marginTop: '2px'
    },
    remove_button: {
        position: 'absolute',
        bottom: 4,
        right: 4
    },
    total_container: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export {useStyles}