import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    text: {
        textAlign:"center"
    },
    tag_container: {
        display: 'flex',
        borderRadius: '4px',
        padding: '4px',
        lineHeight: '12px',
        fontSize: '12px'
    },
    start_icon_container: {
        marginRight: '4px',
        "& svg":{
            fontSize: '12px'
        }
    },
    end_icon_container: {
        marginLeft: '4px',
        "& svg":{
            fontSize: '12px'
        }
    },
    default: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.grey[200],
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    danger: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.common.white,
    },
    fit_content:{
        width:"fit-content"
    }
}));

export {useStyles}