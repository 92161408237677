import Pagination from "../../entities/Pagination";
import CustomCategory from "../../model/packageDelivery/customCategory/CustomCategory";
import CommonStore from "../../stores/CommonStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {CustomCategoryService} from "../../service/packageDelivery/customCategory/CustomCategoryService";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"]
}
class CustomCategoryStore{

    public customCategory: CustomCategory[]
    public selectedCustomCategoryId:string
    public pagination: Pagination
    public filter?:string
    public commonStore:CommonStore

    constructor(commonStore:CommonStore) {
        this.pagination = new Pagination(1,15,0,0)
        this.customCategory = []
        this.selectedCustomCategoryId = ''
        this.commonStore = commonStore
        makeObservable(this,{
            customCategory:observable,
            pagination:observable,
            isEmpty:computed
        });
    }

    get isEmpty():boolean {
        return this.customCategory.length ===0;
    }

    public setFilter(filter:string){
        this.filter = filter
    }

    @action
    public async search(){
        await this.getElements(1)
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize)
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await CustomCategoryService.get(page, pageSize, this.filter);
        if (response.success) {
            runInAction(() => {
                this.customCategory = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.processError(response.error)
        this.commonStore.removeLoading();
    }

    processError(error?: string) {
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                //this.commonStore.showErrorToast(error) //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
        }
    }
}
export default CustomCategoryStore