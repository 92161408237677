import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import PaymentFee from "../../../entities/PaymentFee";
import {PaymentService} from "../../../service/PaymentFeeService";

class UpdatePaymentFeeButtonStore {

    public amount?: number;
    public agencyId?: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public success?: string;
    public fees: PaymentFee[];


    constructor(commonStore: CommonStore, agencyId?: string) {
        this.agencyId = agencyId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.fees = [];
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        await this.getPaymentFees();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async getPaymentFees() {
        this.loading = true;
        const response = await PaymentService.getPaymentFees(this.agencyId);
        if (response.success && response.data) {
            this.fees = response.data;
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const response = await PaymentService.updatePaymentFees(this.fees, this.agencyId);
        this.commonStore.processErrors(response);
        this.loading = false;
       return response.success && !!response.data;
    }
}

export default UpdatePaymentFeeButtonStore;