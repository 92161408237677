import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.grey[300],
            flexGrow: 1,
            justifyContent: 'center',
            height: 'calc( 100vh - 250px)'
        }, 
        article_container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '10px',
            gridAutoRows: 'max-content',
            backgroundColor: '#ffffff',
            flex: '60%',
            margin: '8px',
            borderRadius: '5px',
            overflowY: 'auto',
            height: 'calc(100% - 75px)'
        },
        select_container: {
            position: "relative",
        },
        filter_container: {
            display: 'flex',
            padding: '12px',
            borderRadius: '5px',
            flexDirection: 'column',
            backgroundColor: '#ffffff',
            margin: '8px',
            width: '30%',
            justifyContent: 'space-between'
        },
        products_container: {
            width: '70%'
        },
        shopping_cart_container: {
            height: '300px',
            overflowY: 'auto'
        },
        shopping_cart_icon_container: {
            textAlign: 'center',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            paddingTop: '5px',
            "& svg": {
                fontSize: '40px',
            }
        },
        with_cart_items_text: {
            textAlign: 'center',
            marginTop: '14px',
            fontSize: '18px'
        },
        search_btn: {
            justifySelf: 'flex-end',
            width: '100%',
            marginTop: '10px'
        },
        filler: {
            flexGrow: 1,
            textAlign: 'center'
        },
        label: {
            position: 'absolute',
            fontSize: '12px',
            color: theme.palette.grey[600],
            backgroundColor: theme.palette.common.white,
            top: -8,
            left: 10,
            padding: " 0 4px"
        },
        input_container: {
            display: 'flex',
            borderRadius: '4px',
            padding: '8px 14px',
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
            height: '40px',
            boxSizing: 'border-box',
            "&:hover": {
                border: `1px solid ${theme.palette.secondary.main}`,
                cursor: 'pointer',
                "& $icon_container": {
                    "& svg": {
                        color: theme.palette.secondary.main,
                        fill: theme.palette.secondary.main,
                    }
                },
                "& $expand_icon": {
                    color: theme.palette.secondary.main,
                }
            }
        },
        icon_container: {
            "& svg": {
                verticalAlign: 'middle',
                color: theme.palette.secondary.light,
                fill: theme.palette.secondary.light,
                fontSize: '1.5rem'
            }
        },
        info_container: {
            display: 'flex',
            flexGrow: 1,
            color: theme.palette.grey[500],
            paddingLeft: '8px'
        },
        expand_icon: {
            color: theme.palette.grey[500],
            "& svg": {
                verticalAlign: 'middle'
            }
        },
        options_container: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '500px',
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
            boxSizing: 'border-box',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '4px',
            top: -5,
        },
        selected_text_container: {
            display: 'flex',
            flexDirection: 'column',
            lineHeight: ' 12px',
            justifyContent: 'center',
            color: theme.palette.grey[400],
            fontSize: '12px',
            "& > div:first-child": {
                color: theme.palette.grey[600],
                fontSize: '14px',
            }
        },
        options_header: {
            height: '40px',
            display: 'flex',
            padding: '16px 8px',
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
            "& input": {
                border: 'none',
                width: '100%',
                fontSize: '24px',
                fontFamily: "Nunito, sans-serif",
                color: theme.palette.grey[400],
                "&:focus": {
                    outline: 'none'
                }
            }
        },
        options_body: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        loading: {
            color: theme.palette.primary.light
        },
        elements_container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            zIndex: 1,
            boxSizing: 'border-box',
            alignSelf: 'flex-start',
            overflow: 'auto',
            height: '335px'
        },
        header: {
            display: 'grid',
            padding: '16px',
            gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr));",
            gridColumnGap: "16px",
            rowGap: '16px',
            columnGap: '16px',
        },
        header_option: {
            backgroundColor: `${theme.palette.secondary.light}`,
            padding: '10px',
            color: theme.palette.common.white,
            borderRadius: 4
        },
        empty_container: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey[400],
            fontSize: "20px"
        },
        selected_container: {
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 1
        },
        remove_icon: {
            color: theme.palette.grey[800]
        },
        row: {
            borderRadius: '4px',
            display: 'flex',
            padding: '8px 16px',
            boxSizing: 'border-box',
            "& svg": {
                verticalAlign: 'middle',
                color: theme.palette.secondary.light,
                fill: theme.palette.secondary.light,
                fontSize: '30px',
                padding: '0 8px',
            },
            "&:hover": {
                cursor: 'pointer',
                backgroundColor: `${theme.palette.primary.light}38`,
                "& svg": {
                    color: theme.palette.primary.dark,
                    fill: theme.palette.primary.dark,
                },
                "& $text_container, $icon_info span": {
                    color: theme.palette.primary.dark,
                    "& > div:first-child": {
                        color: theme.palette.primary.dark,
                    }
                }
            }
        },
        contact_geo_select_link_container: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center'
        },
        filter_products_container: {
            padding: '10px'
        }
    }))
;

export {useStyles}