import React from 'react';
import ServiceNameSelectProperties from "./ServiceNameSelectProperties";
import SelectOption from "../../../ui_components/Select/SelectOption";
import Select from "../../../ui_components/Select/Select";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {Inventory} from "@mui/icons-material";
import DeliveryCostServiceName from '../../../entities/DeliveryCostServiceName';

const ServiceNameSelect: React.FC<ServiceNameSelectProperties> = ({
                                                                        selected,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {

    const opt = [
        new SelectOption("Todos", DeliveryCostServiceName.ALL, 'Todos los servicios', CheckBoxIcon),
        new SelectOption("Dinero", DeliveryCostServiceName.MONEY_DELIVERY, "Entregas de dinero", AttachMoneyIcon),
        new SelectOption("Artículo", DeliveryCostServiceName.ARTICLE_DELIVERY, "Entregas de artículos/combos", FastfoodIcon),
        new SelectOption("Paquete", DeliveryCostServiceName.PACKAGE_DELIVERY, "Entregas de paquetes", Inventory)
    ]

    const selectedOption = opt.find(t => t.value === selected);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value as DeliveryCostServiceName)
    }

    return (
        <Select
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : "Servicio"}
            placeholder={"Seleccione el tipo de servicio"}
            selected={selectedOption}
            icon={AttachMoneyIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default ServiceNameSelect;