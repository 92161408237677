import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Agency from "../../../entities/Agency";
import {AgencyService} from "../../../service/AgencyService";
import Seller from "../../../entities/Seller";
import Response from "../../../model/Response";

const ERROR_MAP = {
    userEmail: ["ERROR_00_400_07", "ERROR_00_400_08", "ERROR_00_400_09"],
    name: ["ERROR_00_400_02"],
    phone: ["ERROR_00_400_03"],
    userName: ["ERROR_00_400_06"],
}

class NewAgencyStore {

    public agencyId?: string;
    public agency: Agency;
    public seller?: Seller;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public userEmailError?: string;
    public userNameError?: string;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, agencyId?: string) {
        this.isEditing = !!agencyId;
        this.agencyId = agencyId;
        this.isModalOpen = false;
        this.agency = {} as Agency;
        this.loading = false;
        this.commonStore = commonStore;
        if (!this.isEditing) {
            this.seller = {} as Seller;
        }
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.agency?.name);
    }

    @action
    public async loadAgency() {
        if (this.agencyId) {
            this.isEditing = true;
            const response = await AgencyService.find(this.agencyId!)
            if (response.success && response.data) {
                this.agency = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadAgency();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        let response: Response<any>;
        if (this.isEditing) {
            response = await AgencyService.update(this.agency);
        } else {
            response = await AgencyService.create(this.agency, this.seller!!);
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.userEmailError = undefined;
        this.userNameError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.userEmail.includes(error)) {
                this.userEmailError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.userName.includes(error)) {
                this.userNameError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
        }

    }
}

export default NewAgencyStore;