import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import InvoiceStatus from "../entities/InvoiceStatus";
import Invoice from "../entities/Invoice";
import AddPaymentRequest from "../model/payment/AddPaymentRequest";
import InvoicePaymentResponse from "../model/payment/InvoicePaymentResponse";

export module InvoiceService {
    export async function get(
        page: number = 1,
        pageSize: number = 15,
        from?: number,
        to?: number,
        status?: InvoiceStatus,
        filter?: string,
        clientId?: string
    ): Promise<Response<PagedResponse<Invoice>>> {
        const url = Config.get("GET_INVOICE_URL", {page, pageSize, filter, from, to, status, clientId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const invoiceData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (invoiceData && paginationData) {
                const data = invoiceData.map((t: any) =>
                    new Invoice(
                        t.id,
                        t.number,
                        t.description,
                        t.userId,
                        t.productOrderId,
                        t.service,
                        t.createdAt,
                        t.clientId,
                        t.contactId,
                        t.serviceCost,
                        t.serviceFee,
                        t.paymentFee,
                        t.totalToPay,
                        t.currency,
                        t.status,
                        t.agencyName,
                        t.userName,
                        t.clientName,
                        t.contactName,
                        t.contactAddress,
                        t.contactPhone,
                        t.agencyAddress,
                        t.agencyEmail,
                        t.agencyPhone,
                        t.userEmail,
                        t.clientEmail,
                        t.clientPhone,
                        t.payments?.map((t: any) => new InvoicePaymentResponse(
                            t.amount,
                            t.currency,
                            t.paymentType,
                            t.fee
                        )),
                        t.additionalData
                    ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Invoice>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Invoice>>(false, undefined, response.error, response.status);
    }

    export async function findByProductOrder(productOrderId: string): Promise<Response<Invoice>> {
        const url = Config.get("FIND_INVOICE_BY_PRODUCT_ORDER_URL", {productOrderId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const invoiceData = response.data;
            if (invoiceData) {
                const invoice = new Invoice(
                    invoiceData.id,
                    invoiceData.number,
                    invoiceData.description,
                    invoiceData.userId,
                    invoiceData.productOrderId,
                    invoiceData.service,
                    invoiceData.createdAt,
                    invoiceData.clientId,
                    invoiceData.contactId,
                    invoiceData.serviceCost,
                    invoiceData.serviceFee,
                    invoiceData.paymentFee,
                    invoiceData.totalToPay,
                    invoiceData.currency,
                    invoiceData.status,
                    invoiceData.agencyName,
                    invoiceData.userName,
                    invoiceData.clientName,
                    invoiceData.contactName,
                    invoiceData.contactAddress,
                    invoiceData.contactPhone,
                    invoiceData.agencyAddress,
                    invoiceData.agencyEmail,
                    invoiceData.agencyPhone,
                    invoiceData.userEmail,
                    invoiceData.clientEmail,
                    invoiceData.clientPhone,
                    invoiceData.payments?.map((t: any) => new InvoicePaymentResponse(
                        t.amount,
                        t.currency,
                        t.paymentType,
                        t.fee
                    )),
                    invoiceData.additionalData
                );
                return new Response<Invoice>(true, invoice, response.error);
            }
        }
        return new Response<Invoice>(true, undefined, response.error, response.status);
    }

    export async function payProductOrder(productOrderId: string, payments: AddPaymentRequest[]): Promise<Response<any>> {
        const url = Config.get("PAY_INVOICE_URL", {productOrderId});
        const request = new Request(url, payments.map((p) => ({
            amount: p.amount,
            currency: p.currency,
            paymentType: p.paymentTypeResponse.paymentType
        })));
        return await RestClient.post(request);
    }

}