import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    anchor: {
        color: theme.palette.primary.dark,
        cursor: 'pointer'
    },
    link_container: {
        display: 'flex'
    },
    start_icon_container:{
        color: theme.palette.primary.dark,
        marginRight: '4px'
    },
    end_icon_container:{
        color: theme.palette.primary.dark,
        marginLeft: '4px'
    }
}));

export {useStyles}