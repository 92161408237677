import {useStyles} from './SearchDeliveryPersonPanel.style';
import React, {createRef, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {Observer} from "mobx-react-lite";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Loading from "../../../../ui_components/PageLoading/Loading";
import DeliveryPersonElementRow from "./row/DeliveryPersonElementRow";
import SearchDeliveryPersonPanelProperties from "./SearchDeliveryPersonPanelProperties";
import {useI18nContext} from "../../../../contexts/I18nContext";
import DeliveryPerson from '../../../../entities/DeliveryPerson';
import Button from '../../../../ui_components/Button/Button';
import ButtonType from '../../../../ui_components/Button/ButtonType';


const SearchDeliveryPersonPanel: React.FC<SearchDeliveryPersonPanelProperties> = ({
                                                                      store,
                                                                      onDeliveryPersonSelect
                                                                  }) => {
    const i18n = useI18nContext();
    const classes = useStyles();

    const inputRef = createRef<HTMLInputElement>();
    const [filter, setFilter] = useState("");

    const closeDeliveryPersonSearch = () => {
        setFilter("");
    }

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFilter(value);
    }

    const selectDeliveryPerson = (deliveryPerson: DeliveryPerson) => {
        store.setSelectedDeliveryPerson(deliveryPerson);
        // onDeliveryPersonSelect(deliveryPerson);
    }

    const onDeliveryPersonSelected = () => {
        onDeliveryPersonSelect(store.selectedDeliveryPerson);
        // onDeliveryPersonSelect(deliveryPerson);
    }

    let timeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        const changeFilter = () => {
            store.setDeliveryPersonFilter(filter);
        };
        timeout.current = setTimeout(changeFilter, 800);
    }, [filter, store])

    return <Observer>
                {() => (
                    <div className={classes.search_container}>
                        <div className={classes.search_input_container}>
                            <div className={classes.search_input}>
                                <input
                                    value={filter}
                                    placeholder="Nombre"
                                    ref={inputRef}
                                    onChange={onFilterChange}/>
                            </div>
                            <div onClick={closeDeliveryPersonSearch}
                                className={clsx(classes.btn, classes.remove_btn)}>
                                <HighlightOffIcon/>
                                <span>{i18n.translate("CLOSE")}</span>
                            </div>
                        </div>
                        {store.loadingDeliveryPersons &&
                        <div className={classes.options_body}>
                            <Loading className={classes.loading} fontSize={45}/>
                        </div>}

                        {!store.loadingDeliveryPersons && <div className={classes.elements_container}>
                            {store.deliveryPersons.map((e, i) => (
                                <DeliveryPersonElementRow
                                    key={i}
                                    element={e}
                                    selected={e.id === store.selectedDeliveryPerson?.id}
                                    onSelect={selectDeliveryPerson}
                                />
                            ))}
                        </div>}

                        {!store.loadingDeliveryPersons && store.isDeliveryPersonsEmpty &&
                        <div className={classes.empty_container}>
                            <div>No hay repartidores!!!</div>
                            <div>Verifica si estás aplicando algún filtro</div>
                        </div>}

                        <Button
                            disabled={!store.enableButton}
                            loading={false} className={classes.input} onClick={onDeliveryPersonSelected}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SELECT")}/>
                    </div>
                )}
            </Observer>
}

export default SearchDeliveryPersonPanel;