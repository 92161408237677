import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import MoneyExchangeRate from "../../../entities/productOrder/moneyDelivery/MoneyExchangeRate";
import { MoneyExchangeService } from "../../../service/moneyDelivery/MoneyExchangeRateService";


class RemoveMoneyExchangeStore {

    public moneyExchange: MoneyExchangeRate;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, moneyExchange: MoneyExchangeRate) {
        this.moneyExchange = moneyExchange;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await MoneyExchangeService.remove(this.moneyExchange);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveMoneyExchangeStore;