import {action, makeObservable, observable, runInAction} from "mobx";
import Response from "../model/Response";
import UserStore from "./UserStore";
import {RoleService} from "../service/RoleService";
import Role from "../entities/Role";
import {EventEmitter} from 'events'
import { AppStorage, AppStorageValues } from "../util/AppStorage";

class CommonStore extends EventEmitter {

    public loadingPage: boolean;
    public roles: Role[];
    private userStore: UserStore;
    private errorToast: (text: string) => Promise<boolean>;
    private successToast: (text: string) => Promise<boolean>;

    constructor(
        userStore: UserStore,
        errorToast: (text: string) => Promise<boolean>,
        successToast: (text: string) => Promise<boolean>
    ) {
        super();
        this.loadingPage = false;
        this.roles = [];
        this.userStore = userStore;
        this.errorToast = errorToast;
        this.successToast = successToast;
        makeObservable(this, {
            loadingPage: observable,
            roles: observable
        });
        RoleService.get().then(response => this.roles = response.data || []);
    }

    @action
    public setLoading() {
        runInAction(()=>{
            this.loadingPage = true;
        });
    }

    @action
    public removeLoading() {
        runInAction(()=>{
            this.loadingPage = false;
        });
    }

    @action
    public logout() {
        AppStorage.save(AppStorageValues.SELECTED_AGENCY, null);
        AppStorage.save(AppStorageValues.SELECTED_SELLER, null);
        this.userStore.logout();
    }
    
    public async showErrorToast(message: string) {
        await this.errorToast(message);
    }

    public async showSuccessToast(message: string) {
        await this.successToast(message);
    }

    public processErrors(response: Response<any>) {
        if (!response.success) {
            switch (response.status) {
                case 401:
                case 403:
                    this.userStore.logout();

            }
        }
    }

    public emitEvent(event: string) {
        this.emit(event);
    }

}

export default CommonStore;