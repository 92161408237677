import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";
import Pagination from "../../../entities/Pagination";


class DeliveryPersonSelectListStore {

    public loadingDeliveryPersons: boolean;
    public commonStore: CommonStore;
    public deliveryPersonFilter?: string;
    public deliveryPersons: DeliveryPerson[];
    public deliveryPersonsPagination: Pagination;
    public selectedDeliveryPerson?: DeliveryPerson;

    public orderByGeosIdProximity?: string[];

    constructor(commonStore: CommonStore, selectedDeliveryPerson?: DeliveryPerson, orderByGeosIdProximity?: string[]) {
        this.loadingDeliveryPersons = true;
        this.commonStore = commonStore;
        this.deliveryPersonsPagination = new Pagination(1, 15, 0, 0);
        this.deliveryPersons = [];
        this.selectedDeliveryPerson = selectedDeliveryPerson;
        this.orderByGeosIdProximity = orderByGeosIdProximity;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.selectedDeliveryPerson);
    }

    @action
    public async searchDeliveryPersons(): Promise<void> {
        this.loadingDeliveryPersons = true;
        const response = await DeliveryPersonService.get(1, 1000, this.deliveryPersonFilter, this.orderByGeosIdProximity);
        if (response.success) {
            runInAction(() => {
                this.deliveryPersons = response.data?.data || [];
                this.deliveryPersonsPagination = response.data?.pagination || this.deliveryPersonsPagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loadingDeliveryPersons = false;
    }

    get isDeliveryPersonsEmpty(): boolean {
        return this.deliveryPersons.length === 0;
    }

    @action
    public async setDeliveryPersonFilter(filter: string) {
        runInAction(() => {
            this.deliveryPersonFilter = filter;
            this.searchDeliveryPersons();
        })
    }

    @action
    public setSelectedDeliveryPerson(deliveryPerson?: DeliveryPerson) {
        runInAction(() => {
            this.selectedDeliveryPerson = deliveryPerson;
        });
    }

}

export default DeliveryPersonSelectListStore;