import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import ContactBankCard from "../entities/ContactBankCard";

export module ContactBankCardService {
    export async function get(page: number = 1, pageSize: number = 15, contactId?: string, filter?: string): Promise<Response<PagedResponse<ContactBankCard>>> {
        const url = Config.get("GET_CONTACT_BANK_CARD_URL", {page, pageSize, filter, contactId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const contactData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (contactData && paginationData) {
                const data = contactData.map((t: any) => new ContactBankCard(t.id, t.contactId, t.bank, t.cardNumber));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ContactBankCard>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ContactBankCard>>(false, undefined, response.error, response.status);
    }

    export async function find(contactId: string, contactBankCardId: string): Promise<Response<ContactBankCard>> {
        const url = Config.get("FIND_CONTACT_BANK_CARD_URL", {bankCardId: contactBankCardId, contactId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const contactBankCardData = response.data;
            if (contactBankCardData) {
                const contactBankCard = new ContactBankCard(contactBankCardData.id, contactBankCardData.contactId, contactBankCardData.bank, contactBankCardData.cardNumber);
                return new Response<ContactBankCard>(true, contactBankCard, response.error);
            }
        }
        return new Response<ContactBankCard>(true, undefined, response.error, response.status);
    }

    export async function create(contactBankCard: ContactBankCard, contactId: string): Promise<Response<any>> {
        const url = Config.get("CREATE_CONTACT_BANK_CARD_URL", {contactId});
        const request = new Request(url, contactBankCard);
        return await RestClient.post(request);
    }

    export async function update(data: ContactBankCard, contactId: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_CONTACT_BANK_CARD_URL", {contactId, id: data.id});
        const request = new Request(url, data);
        return await RestClient.put(request);
    }

    export async function remove(contactBankCard: ContactBankCard): Promise<Response<any>> {
        const url = Config.get("REMOVE_CONTACT_BANK_CARD_URL", {
            id: contactBankCard.id,
            contactId: contactBankCard.contactId
        });
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}