import React, {useEffect, useState} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AddressInputProperties from "./AddressInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import Textarea from "../../../ui_components/Textarea/Textarea";

const AddressInput: React.FC<AddressInputProperties> = ({
                                                        value,
                                                        lines = 3,
                                                        onChange,
                                                        required,
                                                        disabled,
                                                        error,
                                                        fullWidth = false,
                                                        className = '',
                                                    }) => {

    const [address, setAddress] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setAddress(value);
    }, [value]);

    const onAddressChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newAddress = event.target.value;
        setAddress(newAddress);
        onChange(newAddress);
    }

    return (
        <Textarea
            label={i18n.translate("ADDRESS") + `${required ? '*' : ''}`}
            error={error}
            value={address}
            lines={lines}
            disabled={disabled}
            onChange={onAddressChange}
            startIcon={HomeIcon}
            className={clsx(className)}
            fullWidth={fullWidth}
        />
    )
}


export default AddressInput;