import React from 'react';
import ShowGeneralCommentProperties from "./ShowGeneralCommentProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowGeneralComment.style";
import CommentIcon from '@material-ui/icons/Comment';
import clsx from "clsx";

const ShowGeneralComment: React.FC<ShowGeneralCommentProperties> = ({
                                                          value,
                                                          className = '',
                                                      }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_comment_container, className,!value && classes.empty)}>
            <CommentIcon className={classes.icon}/>
            <div className={classes.value_container}>
                {value || i18n.translate("NO_COMMENT")}
            </div>
        </div>
    )
}


export default ShowGeneralComment;