import React, {useEffect, useState} from 'react';
import {useStyles} from "./DifficultPersonCheckbox.style";
import DifficultPersonCheckboxProperties from "./DifficultPersonCheckboxProperties";
import clsx from "clsx";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';

const DifficultPersonCheckbox: React.FC<DifficultPersonCheckboxProperties> = ({
                                                    className = '',
                                                    onChange,
                                                    selected = false,
                                                    disabled = false,
                                                }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(selected);

    useEffect(() => {
        setChecked(selected);
    }, [selected])


    const onValueChange = () => {
        if (disabled) {
            return;
        }
        const newValue = !checked;
        setChecked(newValue);
        onChange(newValue);
    }

    return (
        <div className={clsx(classes.checkbox_container, className, disabled && classes.checkbox_disabled, !disabled && classes.checkbox_enabled)} onClick={onValueChange}>
            <div className={classes.icon_container}>
                {checked && <CheckBoxIcon/>}
                {!checked && <CheckBoxOutlineBlankIcon/>}
            </div>
            <div><WbTwilightIcon/></div>
        </div>
    )
}
export default DifficultPersonCheckbox;