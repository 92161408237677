export const latLgns = [
  { id: '00001', lat: 21.521757, lng: -77.781167 },     // Cuba
  { id: '00002', lat: 22.4175, lng: -83.6981 },         // Pinar del Río
  { id: '00003', lat: 22.8161, lng: -82.7586 },         // Artemisa
  { id: '00004', lat: 23.113592, lng: -82.366592 },     // La Habana
  { id: '00005', lat: 22.9615, lng: -82.1511 },         // Mayabeque
  { id: '00006', lat: 23.0415, lng: -81.5773 },         // Matanzas
  { id: '00007', lat: 22.149, lng: -80.441 },           // Cienfuegos
  { id: '00008', lat: 22.409, lng: -79.9667 },          // Villa Clara
  { id: '00009', lat: 21.929, lng: -79.4425 },          // Sancti Spíritus
  { id: '00010', lat: 21.84, lng: -78.7619 },           // Ciego de Ávila
  { id: '00011', lat: 21.3808, lng: -77.9169 },         // Camagüey
  { id: '00012', lat: 20.9617, lng: -76.9511 },         // Las Tunas
  { id: '00013', lat: 20.3875, lng: -76.6411 },         // Granma
  { id: '00014', lat: 20.8872, lng: -76.2631 },         // Holguín
  { id: '00015', lat: 20.0169, lng: -75.8302 },         // Santiago de Cuba
  { id: '00016', lat: 20.1444, lng: -75.2092 },         // Guantánamo
  { id: '00186', lat: 21.6208, lng: -82.6914 },         // Isla de la Juventud

  { id: '00017', lat: 22.505, lng: -83.5128 },          // Consolación del Sur
  { id: '00018', lat: 22.2042, lng: -84.0877 },         // Guane
  { id: '00019', lat: 22.7631, lng: -83.5336 },         // La Palma
  { id: '00020', lat: 22.5883, lng: -83.2486 },         // Los Palacios
  { id: '00021', lat: 22.5375, lng: -84.2806 },         // Mantua
  { id: '00022', lat: 22.575, lng: -83.9456 },          // Minas de Matahambre
  { id: '00023', lat: 22.4175, lng: -83.6981 },         // Pinar del Río
  { id: '00024', lat: 22.2467, lng: -83.8478 },         // San Juan y Martínez
  { id: '00025', lat: 22.2956, lng: -84.2203 },         // San Luis
  { id: '00026', lat: 22.0786, lng: -84.3472 },         // Sandino
  { id: '00027', lat: 22.6208, lng: -83.6981 },         // Viñales

  { id: '00028', lat: 22.9872, lng: -82.7533 },         // Mariel
  { id: '00029', lat: 22.9269, lng: -82.6861 },         // Guanajay
  { id: '00030', lat: 22.8722, lng: -82.5856 },         // Caimito
  { id: '00031', lat: 22.9886, lng: -82.5461 },         // Bauta
  { id: '00032', lat: 22.8917, lng: -82.5028 },         // San Antonio de los Baños
  { id: '00033', lat: 22.8042, lng: -82.4964 },         // Güira de Melena
  { id: '00034', lat: 22.8092, lng: -82.5339 },         // Alquízar
  { id: '00035', lat: 22.8161, lng: -82.7586 },         // Artemisa
  { id: '00036', lat: 22.9044, lng: -82.5042 },         // Bahía Honda
  { id: '00037', lat: 22.8861, lng: -82.6889 },         // Candelaria
  { id: '00038', lat: 22.6986, lng: -82.8817 },         // San Cristóbal

  { id: '00039', lat: 23.0167, lng: -82.3667 },         // Arroyo Naranjo
  { id: '00040', lat: 23.0097, lng: -82.4014 },         // Boyeros
  { id: '00041', lat: 23.1417, lng: -82.3647 },         // Centro Habana
  { id: '00042', lat: 23.1011, lng: -82.3733 },         // Cerro
  { id: '00043', lat: 23.0294, lng: -82.4097 },         // Cotorro
  { id: '00044', lat: 23.0811, lng: -82.3478 },         // Diez de Octubre
  { id: '00045', lat: 23.1231, lng: -82.3139 },         // Guanabacoa
  { id: '00046', lat: 23.1597, lng: -82.2997 },         // La Habana del Este
  { id: '00047', lat: 23.1333, lng: -82.3333 },         // La Habana Vieja
  { id: '00048', lat: 23.0822, lng: -82.3839 },         // La Lisa
  { id: '00049', lat: 23.0922, lng: -82.3861 },         // Marianao
  { id: '00050', lat: 23.1069, lng: -82.4044 },         // Playa
  { id: '00051', lat: 23.1322, lng: -82.3833 },         // Plaza de la Revolución
  { id: '00052', lat: 23.1369, lng: -82.3319 },         // Regla
  { id: '00053', lat: 23.0736, lng: -82.3472 },         // San Miguel del Padrón

  { id: '00054', lat: 22.9283, lng: -82.3925 },         // Bejucal
  { id: '00055', lat: 22.9606, lng: -82.1511 },         // San José de las Lajas
  { id: '00056', lat: 22.96, lng: -82.0103 },           // Jaruco
  { id: '00057', lat: 23.1597, lng: -81.9269 },         // Santa Cruz del Norte
  { id: '00058', lat: 22.9744, lng: -81.8256 },         // Madruga
  { id: '00059', lat: 22.8028, lng: -81.9464 },         // Nueva Paz
  { id: '00060', lat: 22.8081, lng: -81.9275 },         // San Nicolás de Bari
  { id: '00061', lat: 22.8369, lng: -82.0283 },         // Güines
  { id: '00062', lat: 22.7886, lng: -81.9758 },         // Melena del Sur
  { id: '00063', lat: 22.7217, lng: -82.2861 },         // Batabanó
  { id: '00064', lat: 22.8058, lng: -82.3811 },         // Quivicán

  { id: '00065', lat: 22.7572, lng: -81.5225 },  // Calimete
  { id: '00066', lat: 23.0364, lng: -81.2025 },  // Cárdenas
  { id: '00067', lat: 22.5969, lng: -81.0394 },  // Ciénaga de Zapata
  { id: '00068', lat: 22.5667, lng: -81.1486 },  // Colón
  { id: '00069', lat: 22.4456, lng: -81.5917 },  // Jagüey Grande
  { id: '00070', lat: 22.5711, lng: -81.1861 },  // Jovellanos
  { id: '00071', lat: 22.5878, lng: -81.6514 },  // Limonar
  { id: '00072', lat: 22.5597, lng: -81.5842 },  // Los Arabos
  { id: '00073', lat: 22.5636, lng: -81.7278 },  // Martí
  { id: '00074', lat: 23.0415, lng: -81.5773 },  // Matanzas
  { id: '00075', lat: 22.5836, lng: -81.3386 },  // Pedro Betancourt
  { id: '00076', lat: 22.5536, lng: -81.3336 },  // Perico
  { id: '00077', lat: 22.4606, lng: -81.4622 },  // Unión de Reyes
  { id: '00187', lat: 23.205, lng: -81.2606 },    // Varadero

  { id: '00078', lat: 22.6358, lng: -80.3181 },  // Abreus
  { id: '00079', lat: 22.4317, lng: -80.2581 },  // Aguada de Pasajeros
  { id: '00080', lat: 22.149, lng: -80.441 },    // Cienfuegos
  { id: '00081', lat: 22.1411, lng: -80.3778 },  // Cruces
  { id: '00082', lat: 22.3219, lng: -80.3694 },  // Cumanayagua
  { id: '00083', lat: 22.2497, lng: -80.3286 },  // Lajas
  { id: '00084', lat: 22.1047, lng: -80.4156 },  // Palmira
  { id: '00085', lat: 22.1869, lng: -80.3597 },  // Rodas
  { id: '00086', lat: 22.1628, lng: -80.3047 },  // Congojas

  { id: '00087', lat: 22.8594, lng: -80.0764 },  // Caibarién
  { id: '00088', lat: 22.7897, lng: -80.0433 },  // Camajuaní
  { id: '00089', lat: 22.7139, lng: -79.9917 },  // Cifuentes
  { id: '00090', lat: 22.7031, lng: -80.0608 },  // Corralillo
  { id: '00091', lat: 22.7208, lng: -79.9631 },  // Encrucijada
  { id: '00092', lat: 22.8836, lng: -80.0972 },  // Manicaragua
  { id: '00093', lat: 22.9544, lng: -79.9747 },  // Placetas
  { id: '00094', lat: 22.8256, lng: -79.8039 },  // Quemado de Güines
  { id: '00095', lat: 22.8711, lng: -80.0414 },  // Ranchuelo
  { id: '00096', lat: 22.7919, lng: -79.9672 },  // Remedios
  { id: '00097', lat: 22.4558, lng: -79.8786 },  // Sagua la Grande
  { id: '00098', lat: 22.4319, lng: -79.9606 },  // Santa Clara
  { id: '00099', lat: 22.4286, lng: -79.8328 },   // Santo Domingo

  { id: '00101', lat: 21.9333, lng: -79.4500 },  // Sancti Spíritus
  { id: '00102', lat: 21.7969, lng: -79.9833 },  // Trinidad
  { id: '00103', lat: 21.9792, lng: -79.5500 },  // Cabaiguán
  { id: '00104', lat: 22.0175, lng: -79.5931 },  // Yaguajay
  { id: '00105', lat: 21.8272, lng: -79.4733 },  // Jatibonico
  { id: '00106', lat: 21.9264, lng: -79.5333 },  // Taguasco
  { id: '00107', lat: 21.8458, lng: -79.4167 },  // Fomento
  { id: '00108', lat: 21.9000, lng: -79.9667 },  // La Sierpe

  { id: '00109', lat: 21.8344, lng: -78.7681 },  // Ciego de Ávila
  { id: '00110', lat: 21.6000, lng: -78.9650 },  // Morón
  { id: '00111', lat: 21.6375, lng: -78.9797 },  // Chambas
  { id: '00112', lat: 21.7333, lng: -78.9294 },  // Ciro Redondo
  { id: '00113', lat: 21.6167, lng: -78.9667 },  // Majagua
  { id: '00114', lat: 21.6681, lng: -78.8683 },  // Florencia
  { id: '00115', lat: 21.6225, lng: -78.9633 },  // Venezuela
  { id: '00116', lat: 21.6628, lng: -78.9856 },  // Baraguá
  { id: '00117', lat: 21.7125, lng: -78.9261 },  // Primero de Enero
  { id: '00118', lat: 21.7358, lng: -78.9306 },  // Bolivia

  { id: '00119', lat: 21.3833, lng: -77.9167 },  // Camagüey
  { id: '00120', lat: 21.3986, lng: -77.9950 },  // Guáimaro
  { id: '00121', lat: 21.5475, lng: -77.7400 },  // Nuevitas
  { id: '00122', lat: 21.4011, lng: -77.9033 },  // Céspedes
  { id: '00123', lat: 21.3400, lng: -77.8833 },  // Jimaguayú
  { id: '00124', lat: 21.2744, lng: -77.8556 },  // Sibanicú
  { id: '00125', lat: 21.3944, lng: -77.6708 },  // Esmeralda
  { id: '00126', lat: 21.4697, lng: -77.8667 },  // Minas
  { id: '00127', lat: 21.5078, lng: -77.7833 },  // Sierra de Cubitas
  { id: '00128', lat: 21.3833, lng: -77.7833 },  // Florida
  { id: '00129', lat: 21.3986, lng: -77.9125 },  // Najasa
  { id: '00130', lat: 21.4931, lng: -77.8411 },  // Vertientes
  { id: '00131', lat: 21.2700, lng: -77.7333 },  // Santa Cruz del Sur

  { id: '00132', lat: 21.6833, lng: -77.9500 },  // Manatí
  { id: '00133', lat: 21.7172, lng: -77.9050 },  // Puerto Padre
  { id: '00134', lat: 21.7100, lng: -77.9000 },  // Jesús Menéndez
  { id: '00135', lat: 21.6619, lng: -77.8675 },  // Majibacoa
  { id: '00136', lat: 21.7583, lng: -77.7750 },  // Las Tunas
  { id: '00137', lat: 21.7250, lng: -77.8500 },  // Jobabo
  { id: '00138', lat: 21.6872, lng: -77.8500 },  // Colombia
  { id: '00139', lat: 21.7156, lng: -77.7400 },  // Amancio

  { id: '00140', lat: 20.2281, lng: -76.4181 },  // Bartolomé Masó
  { id: '00141', lat: 20.3744, lng: -76.6364 },  // Bayamo
  { id: '00142', lat: 20.4464, lng: -76.6653 },  // Buey Arriba
  { id: '00143', lat: 20.3558, lng: -76.4825 },  // Campechuela
  { id: '00144', lat: 20.3736, lng: -76.4633 },  // Cauto Cristo
  { id: '00145', lat: 20.3889, lng: -76.6981 },  // Guisa
  { id: '00146', lat: 20.3142, lng: -76.4697 },  // Jiguaní
  { id: '00147', lat: 20.3650, lng: -76.6072 },  // Manzanillo
  { id: '00148', lat: 20.2639, lng: -76.3956 },  // Media Luna
  { id: '00149', lat: 20.4394, lng: -76.3211 },  // Niquero
  { id: '00150', lat: 20.3833, lng: -76.5500 },  // Pilón
  { id: '00151', lat: 20.3244, lng: -76.5886 },  // Río Cauto
  { id: '00152', lat: 20.3242, lng: -76.3478 },  // Yara

  { id: '00153', lat: 20.4808, lng: -75.7925 },  // Antilla
  { id: '00154', lat: 20.5658, lng: -75.7961 },  // Báguanos
  { id: '00155', lat: 20.6186, lng: -75.9272 },  // Banes
  { id: '00156', lat: 20.6078, lng: -75.9425 },  // Cacocum
  { id: '00157', lat: 20.6111, lng: -75.9981 },  // Calixto García
  { id: '00158', lat: 20.6642, lng: -75.9636 },  // Cueto
  { id: '00159', lat: 20.5719, lng: -75.9314 },  // Frank País
  { id: '00160', lat: 20.6172, lng: -75.8497 },  // Gibara
  { id: '00161', lat: 20.7847, lng: -75.9781 },  // Holguín
  { id: '00162', lat: 20.3725, lng: -75.7422 },  // Mayarí
  { id: '00163', lat: 20.4108, lng: -75.8603 },  // Moa
  { id: '00164', lat: 20.3531, lng: -75.9744 },  // Rafael Freyre
  { id: '00165', lat: 20.3450, lng: -75.9397 },  // Sagua de Tánamo
  { id: '00166', lat: 20.5961, lng: -75.9386 },  // Urbano Noris

  { id: '00167', lat: 20.2758, lng: -75.9717 },  // Contramaestre
  { id: '00168', lat: 20.2708, lng: -75.9464 },  // Guamá
  { id: '00169', lat: 20.2425, lng: -75.9700 },  // Mella
  { id: '00170', lat: 20.3142, lng: -75.8883 },  // Palma Soriano
  { id: '00171', lat: 20.3375, lng: -75.8897 },  // San Luis
  { id: '00172', lat: 20.0214, lng: -75.8214 },  // Santiago de Cuba
  { id: '00173', lat: 20.2333, lng: -75.8667 },  // Segundo Frente
  { id: '00174', lat: 20.2542, lng: -75.8833 },  // Songo-La Maya
  { id: '00175', lat: 20.3667, lng: -75.5833 },  // Tercer Frente

  { id: '00176', lat: 20.3447, lng: -74.5047 },  // Baracoa
  { id: '00177', lat: 20.3442, lng: -74.5261 },  // Caimanera
  { id: '00178', lat: 20.3653, lng: -74.5192 },  // El Salvador
  { id: '00179', lat: 20.1494, lng: -75.2108 },  // Guantánamo
  { id: '00180', lat: 20.2825, lng: -75.2386 },  // Imías
  { id: '00181', lat: 20.2614, lng: -74.5486 },  // Maisí
  { id: '00182', lat: 20.3128, lng: -74.4375 },  // Manuel Tames
  { id: '00183', lat: 20.2381, lng: -74.5083 },  // Niceto Pérez
  { id: '00184', lat: 20.3200, lng: -74.4272 },  // San Antonio del Sur
  { id: '00188', lat: 20.1747, lng: -74.3394 },  // Yateras

  { id: '00189', lat: 21.7569, lng: -82.8408 }   // Isla de la Juventud
]