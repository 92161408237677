import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    text: {
        fontSize: '16px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    }
}));

export {useStyles}