import React from 'react';
import ConfigurationButtonProperties from "./ConfigurationButtonProperties";
import SettingsIcon from '@mui/icons-material/Settings';
import {Observer} from "mobx-react-lite";
// import {useCommonContext} from "../../../contexts/CommonContext";
// import ConfigurationButtonStore from './ConfigurationButtonStore';
import {useStyles} from './ConfigurationButton.style';
import {Badge, Box, Popover} from '@material-ui/core';
import UpdatePaymentFeeButton from "../UpdatePaymentFeeButton/UpdatePaymentFeeButton";
import Access from "../../Access/AllowAccess/Access";
import AllowAccess from "../../Access/AllowAccess/AllowAccess";


const ConfigurationButton: React.FC<ConfigurationButtonProperties> = () => {

    const classes = useStyles();
    // const commonContext = useCommonContext();
    // const store = new ConfigurationButtonStore(commonContext);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <Observer>
        {() => (
            <AllowAccess any={[Access.UPDATE_PAYMENT_FEES]}>
                <Badge
                    classes={{ badge: classes.configuration_badge_back_color }}
                    max={99} className={classes.configuration_badge}>
                    <Box onClick={handleClick}>
                        <SettingsIcon />
                    </Box>
                </Badge>
                <Popover
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.configuration_area_header}>
                        <span className={classes.header_text}>Configuración</span>
                        <SettingsIcon color="action" />
                    </div>
                    <div className={classes.configuration_area_content}>
                        <ul className={classes.configuration_ul}>
                           <li className={classes.configuration_li}>
                               <UpdatePaymentFeeButton />
                           </li>
                        </ul>
                    </div>
                </Popover>
            </AllowAccess>
        )}
    </Observer>
}

export default ConfigurationButton;
