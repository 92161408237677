import React, {useEffect} from 'react';
import {Observer} from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import MoneyDeliveryOrderDetailsStore from './MoneyDeliveryOrderDetailsStore';
import { useCommonContext } from '../../../contexts/CommonContext';
import { useStyles } from './MoneyDeliveryOrderDetails.style';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import ShowDate from '../../../shared_components/Info/ShowDate/ShowDate';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowDeliveryPerson from '../../../shared_components/Info/ShowDeliveryPerson/ShowDeliveryPerson';
import ShowComments from '../../../shared_components/Info/ShowComments/ShowComments';
import { useUserContext } from '../../../contexts/UserContext';
import ShowMoneyDeliveryAmounts from '../../../shared_components/Info/ShowMoneyDeliveryAmounts/ShowMoneyDeliveryAmounts';
import Currency from '../../../entities/Currency';
import ShowProductOrderStatusHistory from '../../../shared_components/Info/ShowProductOrderStatusHistory/ShowProductOrderStatusHistory';
import ShowMapWithMarkers from '../../../shared_components/Info/ShowMapWithMarkers/ShowMapWithMarkers';
import ShowContactBankCard from '../../../shared_components/Info/ShowContactBankCard/ShowContactBankCard';
import ShowBankCardImg from '../../../shared_components/Info/ShowBankCardImg/ShowBankCardImg';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';


const MoneyDeliveryOrderDetails: React.FC = () => {

    const { id } = useParams();

    const commonStore = useCommonContext();
    const userContext = useUserContext();
    const store = new MoneyDeliveryOrderDetailsStore(commonStore, id);
    const classes = useStyles();

    useEffect(() => {
        store.getElement();
        store.loadMoneyDeliveryOrderComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewCommentOnclick = async (comment: string) => {
        await store.addComment(comment);
    }

    return (
        <Observer>
            {() => (
                <div className={classes.page_container}>
                    {store.moneyDeliveryOrder &&
                        <div className={classes.box_container}>
                            <div className={classes.order_details_container}>
                                <div>
                                    <small>Número de orden</small>
                                    <div><strong>{store.moneyDeliveryOrder.orderNumber}</strong></div>
                                    <div>
                                        <ShowDate timestamp={store.moneyDeliveryOrder.createdAt}
                                            showTime={false}
                                            className={classes.date_container}/>
                                    </div>
                                </div>
                                <div>
                                    <ShowOrderStatus status={store.moneyDeliveryOrder.status}/>
                                </div>
                            </div>
                            
                            <div className={classes.order_more_details_container}>

                                <div className={classes.order_more_details_container_col_1}>
                                    <div className={classes.order_more_details_item_container}>
                                        <ShowText text={store.moneyDeliveryOrder.clientName} title={'Remitente'}/>
                                        {store.moneyDeliveryOrder.clientPhone &&
                                            <ShowPhoneNumber value={store.moneyDeliveryOrder.clientPhone} showFlag={false}/>}
                                    </div>
                                    <div className={classes.order_more_details_item_container}>
                                        <ShowText text={store.moneyDeliveryOrder.contactName} title={'Destinatario'}/>
                                            {store.moneyDeliveryOrder.contactPhone &&
                                                <ShowPhoneNumber value={store.moneyDeliveryOrder.contactPhone} showFlag={false}/>}
                                    </div>
                                    <div className={classes.order_more_details_item_container}>
                                        <ShowMoneyDeliveryAmounts moneyDeliveryOrder={store.moneyDeliveryOrder} valueToPay={store.moneyDeliveryOrder.totalToPay} currencyToPay={Currency.DOLLAR}
                                            valueToReceive={store.moneyDeliveryOrder.totalToReceive}
                                            currencyReceive={store.moneyDeliveryOrder.currencyToReceive}/>
                                    </div>
                                </div>

                                <div className={classes.order_more_details_container_col_2}>
                                    <div>
                                        {store.moneyDeliveryOrder && store.moneyDeliveryOrder.isBankCard &&
                                            <div><small>Envío de tarjeta</small>
                                                <div><ShowContactBankCard bank={store.moneyDeliveryOrder.contactBankCardBank}
                                                                        cardNumber={store.moneyDeliveryOrder.contactBankCardNumber}></ShowContactBankCard>
                                                </div>
                                            </div>}

                                        {store.moneyDeliveryOrder && !store.moneyDeliveryOrder.isBankCard &&
                                            <div><small>Entrega</small>
                                                <div><ShowText text={store.moneyDeliveryOrder.address}
                                                            title={store.moneyDeliveryOrder.geo ? 
                                                                store.moneyDeliveryOrder.geo.name + '. ' + store.moneyDeliveryOrder.geo.parentInfo :
                                                                ''}/>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className={classes.delivery_person_name_container}>
                                        <ShowDeliveryPerson
                                            text={store.moneyDeliveryOrder.deliveryPersonName}
                                            showActionLinks={false}
                                        />
                                    </div>
                                    <div className={classes.delivery_person_map_container}>
                                        {store.moneyDeliveryOrder.isBankCard &&
                                            <ShowBankCardImg bankCardBank={store.moneyDeliveryOrder.contactBankCardBank} bankCardNumber={store.moneyDeliveryOrder.contactBankCardNumber}/>}
                                        {!store.moneyDeliveryOrder.isBankCard && <ShowMapWithMarkers deliveryPersonGeoId={store.deliveryPerson?.geo?.id} contactGeoId={store.moneyDeliveryOrder.geo.id}/>}
                                    </div>
                                </div>
                                
                            </div>

                            <div className={classes.content_area_container}>

                                <div className={classes.status_history_container}>
                                    <div className={classes.comments_label_header}>Timeline:</div>
                                    <ShowProductOrderStatusHistory productOrderId={store.moneyDeliveryOrderId}/>
                                </div>

                                <AllowAccess isRootAgency>
                                    <div className={classes.comments_container}>
                                        <div className={classes.comments_label_header}>Comentarios:</div>
                                        <ShowComments
                                            onNewComment={handleNewCommentOnclick}
                                            comments={store.moneyDeliveryOrderComments} loguedUserId={userContext.currentUser() ? userContext.currentUser()!!.id : ''}/>
                                    </div>
                                </AllowAccess>

                            </div>
                        </div>
                    }
                </div>
            )}
        </Observer>
    )
}

export default MoneyDeliveryOrderDetails;
