import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Geo from "../entities/Geo";
import DeliveryCost from "../entities/DeliveryCost";

export module DeliveryCostService {
    export async function get(page: number = 1, pageSize: number = 15, deliveryPersonId?: string, currency?: string, geoId?: string): Promise<Response<PagedResponse<DeliveryCost>>> {
        const url = Config.get("GET_DELIVERY_COST_URL", {page, pageSize, currency, personId: deliveryPersonId, geoId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const costData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (costData && paginationData) {
                const data = costData.map((t: any) => new DeliveryCost(
                    t.id,
                    t.geo && new Geo(t.geo.id, t.geo.name, t.geo.parentId, t.geo.level, t.geo.hasChildren),
                    t.currency,
                    t.cost,
                    t.deliveryPersonId,
                    t.service
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<DeliveryCost>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<DeliveryCost>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<DeliveryCost>> {
        const url = Config.get("FIND_DELIVERY_COST_URL", { id });
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const client = new DeliveryCost(
                    t.id,
                    t.geo && new Geo(t.geo.id, t.geo.name, t.geo.parentId, t.geo.level, t.geo.hasChildren),
                    t.currency,
                    t.cost,
                    t.deliveryPersonId,
                    t.service
                );
                return new Response<DeliveryCost>(true, client, response.error);
            }
        }
        return new Response<DeliveryCost>(true, undefined, response.error, response.status);
    }

    export async function create(deliveryCost: DeliveryCost, deliveryPersonId: string): Promise<Response<any>> {
        const url = Config.get("CREATE_DELIVERY_COST_URL", {personId: deliveryPersonId});
        const {geo, ...rest} = deliveryCost;
        const request = new Request(url, {geoId: geo?.id, ...rest});
        return await RestClient.post(request);
    }

    export async function update(deliveryCost: DeliveryCost): Promise<Response<any>> {
        const url = Config.get("UPDATE_DELIVERY_COST_URL", {id: deliveryCost.id});
        const {geo, ...rest} = deliveryCost;
        const request = new Request(url, {geoId: geo?.id, ...rest});
        return await RestClient.put(request);
    }

    export async function remove(deliveryCostId: String, deliveryPersonId: string): Promise<Response<any>> {
        const url = Config.get("REMOVE_DELIVERY_COST_URL", {personId: deliveryPersonId, id: deliveryCostId});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}