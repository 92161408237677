import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";


class RemoveMiscellanyCategoryStore {

    public miscellanyCategory: MiscellanyCategory;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, promotion: MiscellanyCategory) {
        this.miscellanyCategory = promotion;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await MiscellanyCategoryService.remove(this.miscellanyCategory);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveMiscellanyCategoryStore;