import {useI18nContext} from "../../../../contexts/I18nContext";
import {useStyles} from './ElementRow.style';
import React from "react";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ElementRowProperties from "./ElementRowProperties";
import clsx from "clsx";

const DeliveryPersonElementRow: React.FC<ElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    
    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <AccountBoxSharpIcon/>
            </div>

            <div className={classes.text_container}>
                <div><b>{element.name}</b></div>
                <div><b>{i18n.translate("EMAIL")}:</b>{`${element.email}`}</div>
            </div>

        </div>
    )
}


export default DeliveryPersonElementRow;