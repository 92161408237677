import ServiceName from "./ServiceName";
import Currency from "./Currency";
import InvoiceStatus from "./InvoiceStatus";
import InvoicePaymentResponse from "../model/payment/InvoicePaymentResponse";
import InvoiceAdditionalData from "./InvoiceAdditionalData";

class Invoice {
    public id: string;
    public number: string;
    public description: string;
    public userId: string;
    public productOrderId: string;
    public service: ServiceName;
    public createdAt: number;
    public clientId: string;
    public contactId: string;
    public serviceCost: number;
    public serviceFee: number;
    public totalService: number;
    public paymentFee: number;
    public totalToPay: number;
    public currency: Currency;
    public status: InvoiceStatus;

    public agencyName: string;
    public agencyAddress?: string;
    public agencyEmail?: string;
    public agencyPhone?: string;
    public userName: string;
    public userEmail?: string;
    public clientName: string;
    public clientEmail?: string;
    public clientPhone?: string;
    public contactName: string;
    public contactAddress: string;
    public contactPhone?: string;
    public payments?: InvoicePaymentResponse[];
    public additionalData?: InvoiceAdditionalData[];


    constructor(
        id: string,
        number: string,
        description: string,
        userId: string,
        productOrderId: string,
        service: ServiceName,
        createdAt: number,
        clientId: string,
        contactId: string,
        serviceCost: number,
        serviceFee: number,
        paymentFee: number,
        totalToPay: number,
        currency: Currency,
        status: InvoiceStatus,
        agencyName: string,
        userName: string,
        clientName: string,
        contactName: string,
        contactAddress: string,
        contactPhone?: string,
        agencyAddress?: string,
        agencyEmail?: string,
        agencyPhone?: string,
        userEmail?: string,
        clientEmail?: string,
        clientPhone?: string,
        payments?: InvoicePaymentResponse[],
        additionalData?: InvoiceAdditionalData[]
    ) {
        this.id = id;
        this.number = number;
        this.description = description;
        this.userId = userId;
        this.productOrderId = productOrderId;
        this.service = service;
        this.createdAt = createdAt;
        this.clientId = clientId;
        this.contactId = contactId;
        this.serviceCost = serviceCost;
        this.serviceFee = serviceFee;
        this.paymentFee = paymentFee;
        this.totalToPay = totalToPay;
        this.currency = currency;
        this.status = status;
        this.agencyName = agencyName;
        this.userName = userName;
        this.clientName = clientName;
        this.contactName = contactName;
        this.contactAddress = contactAddress;
        this.contactPhone = contactPhone;
        this.agencyAddress = agencyAddress;
        this.agencyEmail = agencyEmail;
        this.agencyPhone = agencyPhone;
        this.userEmail = userEmail;
        this.clientEmail = clientEmail;
        this.clientPhone = clientPhone;
        this.payments = payments;
        this.additionalData = additionalData;
        this.totalService = this.totalToPay - this.paymentFee
    }

}

export default Invoice;