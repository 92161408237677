import React, {createRef, useEffect, useState} from 'react';
import {useStyles} from "./ExpandoMenu.style";
import ExpandoMenuProperties from "./ExpandoMenuProperties";
import clsx from "clsx";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {motion} from "framer-motion";
import {useCommonContext} from "../../contexts/CommonContext";


const ExpandoMenu: React.FC<ExpandoMenuProperties> = ({
                                                          className = '',
                                                          children,
                                                          openToRight = false,
                                                          disabled = false,
                                                      }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const commonStore = useCommonContext();

    const menuRef = createRef<HTMLDivElement>();
    useEffect(() => {
        const clickOutside = (event: any) => {
            const dialog = document.querySelectorAll(".MuiDialog-container,.MuiPaper-root");
            let dialogOpen = false;
            for (let i = 0; i < dialog.length; i++) {
                if (menuRef?.current?.contains(dialog[i])) {
                    dialogOpen = true;
                }
            }
            if (menuRef && !menuRef.current?.contains(event.target) && !dialogOpen) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [menuRef]);

    commonStore.on('dialog_closed', () => {
        setIsMenuOpen(false);
    });

    const classes = useStyles();
    const variants = {
        open: {opacity: 1, x: 0},
        closed: openToRight ? {opacity: 0, x: -300, display: 'none'} : {opacity: 0, x: 300, display: 'none'},
    }

    return (
        <div className={clsx(classes.expando_menu_container, className)} ref={menuRef}>
            <div className={clsx(classes.button_container, disabled && classes.disabled)} onClick={() => { !disabled && setIsMenuOpen(!isMenuOpen)}}>
                {!isMenuOpen && <MenuIcon/>}
                {isMenuOpen && <MenuOpenIcon/>}
            </div>
            {
                <motion.nav animate={isMenuOpen ? "open" : "closed"} variants={variants}
                            className={clsx(classes.menu_items_container,openToRight && classes.menu_items_container_right)}>
                    {children}
                </motion.nav>}

        </div>
    )
}
export default ExpandoMenu;