import React from 'react';
import {useStyles} from "./Progress.style";
import ProgressProperties from "./ProgressProperties";
import clsx from "clsx";


const Progress: React.FC<ProgressProperties> = ({
                                                    className = '',
                                                    total,
                                                    progress,
                                                    theme = "info"
                                                }) => {
    const classes = useStyles();
    const getValueClass = () => {
        switch (theme) {
            case "info":
                return classes.info;
            case "success":
                return classes.success;
            case "danger":
                return classes.danger;
            case "primary":
                return classes.primary;
            case "secondary":
                return classes.secondary;
            case "warning":
                return classes.warning;
            case "purple":
                return classes.purple;
        }

    }


    return (
        <div className={clsx(classes.progress_container, className)}>
            <div className={classes.backdrop}/>
            <div className={clsx(classes.progress, getValueClass())}
                 style={{width: `${Math.round(progress * 100 / total)}%`}}/>
        </div>
    )
}
export default Progress;