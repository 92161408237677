import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        tab_container: {
            alignSelf: 'flex-start',
            width: '100%',
            backgroundColor: theme.palette.common.white,
            "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.primary.main,
            }
        },
        tab_content: {
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            backgroundColor: `${theme.palette.grey["200"]}83`
        },
        tab: {
            minHeight: '50px',
            '& .MuiTab-wrapper': {
                flexDirection: 'row',
                "& *:first-child": {
                    margin: '0 5px 0 0'
                }
            },
            "& svg": {
                margin: '0 5px 0 0',
                fontSize: '18px',
            },
            "&.Mui-selected": {
                color: theme.palette.primary.light
            }
        },
    }))
;

export {useStyles}