import React from 'react';
import {useStyles} from "../CustomCategoryWeightRange.style";
import clsx from "clsx";
import CustomCategoryWeightRangeRowProperties from "./CustomCategoryWeightRangeRowProperties";
import ShowText from '../../../../../shared_components/Info/ShowText/ShowText';
import { useI18nContext } from '../../../../../contexts/I18nContext';
import ShowIntervalWithTitle from "../../../../../shared_components/Info/ShowIntervalWithTitle/ShowIntervalWithTitle";
import ButtonSize from "../../../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../../../ui_components/Button/ButtonType";
import NewCustomCategoryWeightRangeButton
    from "../../../../../shared_components/Buttons/NewCustomCategoryWeightRangeButton/NewCustomCategoryWeightRangeButton";
import RemoveCustomCategoryWeightRangeButton from '../../../../../shared_components/Buttons/RemoveCustomCategoryWeightRangeButton/RemoveCustomCategoryWeightRangeButton';
import { useUserContext } from '../../../../../contexts/UserContext';
import Access from '../../../../../shared_components/Access/AllowAccess/Access';


const CustomCategoryWeightRangeRowMedium: React.FC<CustomCategoryWeightRangeRowProperties> = ({
                                                                                                  customCategoryWeightRange,
                                                                                                  customCategoryId,
                                                                                                  onUpdate = () => {},
                                                                                              }) => {

    const i18n = useI18nContext();
    const classes = useStyles();
    const userStore = useUserContext();

    return (
        <div
            className={clsx(classes.foodPackage_info_container)}>
            <ShowIntervalWithTitle title={i18n.translate("WEIGHT_RANGES_RANGE")} from={customCategoryWeightRange.from} to={customCategoryWeightRange.to}/>
            <ShowText text={"$"+customCategoryWeightRange.cost.toString()} title={i18n.translate("WEIGHT_RANGES_COST")}/>
            <ShowText text={"$"+customCategoryWeightRange.price.toString()} title={i18n.translate("WEIGHT_RANGES_PRICE")}/>
            {userStore.hasAccess(Access.CREATE_EDIT_WEIGHT_RANGE) &&
                <NewCustomCategoryWeightRangeButton
                    type={ButtonType.SECONDARY}
                    onSave={onUpdate}
                    customCategoryId={customCategoryId}
                    weightRangeId={customCategoryWeightRange.id}
                    size={ButtonSize.SMALL}
                    showText={false}/>
            }
            <RemoveCustomCategoryWeightRangeButton
                type={ButtonType.SECONDARY}
                onSave={onUpdate}
                customCategoryId={customCategoryId}
                weightRangeId={customCategoryWeightRange.id}
                size={ButtonSize.SMALL}
                showText={false}/>
        </div>
    )
}

export default CustomCategoryWeightRangeRowMedium;
