import React from "react";

class TabOption {
    public label: string;
    public content: React.ReactNode;
    public icon?: React.ReactElement;

    constructor(label: string, content: React.ReactNode, icon?: React.ReactElement) {
        this.label = label;
        this.content = content;
        this.icon = icon;
    }
}

export default TabOption;