import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import ContactBankCard from "../../../entities/ContactBankCard";
import {ContactBankCardService} from "../../../service/ContactBankCardService";
import { ClientContactService } from "../../../service/ClientContactService";
import ClientContact from "../../../entities/ClientContact";

class ContactBankCardButtonStore {

    public clientId: string;
    public contactId: string;
    public contact?: ClientContact;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public pagination: Pagination;
    public contactBankCardTypeFilter?: string;
    public contactBankCards: ContactBankCard[];

    public amountError?: string;


    constructor(commonStore: CommonStore, clientId: string, contactId: string) {
        this.isModalOpen = false;
        this.clientId = clientId;
        this.contactId = contactId;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.contactBankCards = [];
        makeAutoObservable(this);
    }

    @action
    public async loadContact() {
        const response = await ClientContactService.find(this.clientId, this.contactId!)
        if (response.success && response.data) {
            this.contact = response.data;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async getContactBankCards(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await ContactBankCardService.get(page, pageSize, this.contactId, this.contactBankCardTypeFilter);
        if (response.success) {
            runInAction(() => {
                this.contactBankCards = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.loadContact();
        await this.getContactBankCards();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async cleanFilter() {
        this.contactBankCardTypeFilter = undefined;
        await this.getContactBankCards();
    }

    get isEmpty(): boolean {
        return this.contactBankCards.length === 0;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getContactBankCards(page, pageSize);
    }

}

export default ContactBankCardButtonStore;