const values = {
    "APP_NAME": "Topup Agencias",
    "YES": "Si",
    "NO": "No",
    "EMAIL": "Correo",
    "PHONE": "Teléfono",
    "LINE_PHONE": "Teléfono fijo",
    "PASSWORD": "Clave",
    "NAME": "Nombre",
    "LASTNAME": "Apellidos",
    "DESCRIPTION": "Descripción",
    "OBSERVATIONS": "Observaciones",
    "PRICE": "Precio",
    "BALANCE": "Saldo",
    "CONFIRM_PASSWORD": "Confirmar Clave",
    "SIGN_IN": "Entrar",
    "SELLERS": "Vendedores",
    "DASHBOARD": "Resumen",
    "FILTER": "Filtro",
    "SHIPPING": "Envío",
    "LOADING": "Cargando",
    "MONTH_1": "Enero",
    "MONTH_2": "Febrero",
    "MONTH_3": "Marzo",
    "MONTH_4": "Abril",
    "MONTH_5": "Mayo",
    "MONTH_6": "Junio",
    "MONTH_7": "Julio",
    "MONTH_8": "Agosto",
    "MONTH_9": "Septiembre",
    "MONTH_10": "Octubre",
    "MONTH_11": "Noviembre",
    "MONTH_12": "Diciembre",
    "ITEMS_PER_PAGE": "Elementos por página",
    "SEARCH": "Buscar",
    "FIRST": "Primera",
    "LAST": "Última",
    "NEXT": "Siguiente",
    "PREVIOUS": "Anterior",
    "CLEAN": "Limpiar",
    "TOTAL": "Total",
    "SAVE": "Guardar",
    "ADD_TO_LIST": "Agregar a la lista",
    "CONTINUE": "Continuar",
    "AMOUNT": "Cantidad",
    "DATE": "Fecha",
    "DATES": "Fechas",
    "ADD": "Adicionar",
    "COUNTRY": "Pais",
    "STATE": "Provincia",
    "CITY": "Municipio",
    "TOWN": "Pueblo",
    "FORGOT_PASSWORD": "No recuerdo la clave",
    "RECOVER": "Recuperar",
    "PASSWORD_RECOVERY_MESSAGE": "Enviaremos un link a tu correo con instrucciones para cambiar tu clave.",
    "PASSWORD_RECOVERY_CONFIRMATION": "Hemos enviado un correo con las indicaciones de cómo cambiar tu clave.",
    "CHANGE": "Cambiar",
    "PASSWORD_LENGTH_DESCRIPTION": "Mínimo 8 caracteres",
    "PASSWORD_NUMBER_DESCRIPTION": "Al menos un número",
    "PASSWORD_EQUALS_DESCRIPTION": "Las claves deben ser iguales",
    "PASSWORD_UPPERCASE_DESCRIPTION": "Al menos una mayúscula",
    "PASSWORD_LOWERCASE_DESCRIPTION": "Al menos una minúscula",
    "GO_TO_LOGIN": "Regresar a autenticarme",
    "PASSWORD_RECOVERY_SUCCESS": "Su clave ha sido cambiada correctamente.",
    "PASSWORD_RECOVERY_CODE_ERROR": "El código de recuperación es incorrecto. Por favor intenta recuperar nuevamente o comunícate con un administrador.",
    "GEO": "Ubicación",
    "SEARCH_GEO_PLACEHOLDER": "Nombre",
    "SELECT_GEO_PLACEHOLDER": "Seleccione",
    "NO_GEO_SEARCH": "No hay ubicaciones",
    "CURRENCY": "Moneda",
    "CURRENCY_PLACEHOLDER": "Seleccione la moneda",
    "USD": "USD",
    "DOLLAR": "USD",
    "MN": "CUP",
    "EURO": "Euro",
    "MONEDA_NACIONAL": "Moneda Nacional",
    "TODAY_ONLY": "Solo hoy",
    "YESTERDAY_ONLY": "Solo ayer",
    "LAST_TREE_DAYS": "Últimos 3 días",
    "LAST_FIVE_DAYS": "Últimos 5 días",
    "LAST_FIFTEEN_DAYS": "Últimos 15 días",
    "THIS_WEEK": "Esta semana",
    "PREVIOUS_WEEK": "Semana anterior",
    "THIS_MONTH": "Este mes",
    "PREVIOUS_MONTH": "Mes anterior",
    "CUSTOM_RANGE": "Otro valor",
    "SELECT": "Seleccionar",
    "CANCEL": "Cancelar",
    "SELECT_DATE": "Seleccione fecha",
    "FROM": "Desde",
    "TO": "Hasta",
    "NO_GEO": "Sin ubicación",
    "CLEAN_FILTER": "Eliminar Filtro",
    "WITHOUT_COST": "Sin costo",
    "COMMENT": "Comentario",
    "SUBTOTAL": "Subtotal",
    "TAX": "Tarifas",
    "BACK": "Anterior",
    "FINISH": "Finalizar",
    "SEE_MORE": "Ver más",
    "SEE_LESS": "Ver menos",
    "DATE_RANGE": "Rango de fechas",
    "CREATED": "Creada",
    "REMOVED": "Eliminada",
    "PENDING": "Pendiente",
    "ASSIGNED": "Asignada",
    "PROCESSING": "Procesando",
    "PAYED": "Pagada",
    "DELIVERED": "Entregada",
    "NOT_DELIVERED": "No entregada",
    "CANCELED": "Cancelada",
    "COMPLETED": "Completada",
    "RETRYING": "Reintentando",
    "LOGOUT": "Salir",
    "REMITTANCE_STATUS": "Estado del envío",
    "NOT_AVAILABLE": "No disponible",
    "COMBOS": "Combo",
    "TOPUP": "Recarga",
    "MONEY_DELIVERY": "Envío de dinero",
    "ARTICLE_DELIVERY": "Envío de productos",
    "PRINT": "Imprimir",
    "NO_ADDRESS": "Sin dirección",
    "REMITTANCE_ORDER_NUMBER": "Número de order de envío",
    "ERROR_LOADING_CONTENT": "Se produjo un error al cargar el contenido. Click aquí para intentar nuevamente !!!",
    "NO_EMAIL": "Sin correo",
    "EDIT_VALUE": "Editar valor",
    "NEW_VALUE": "Nuevo valor",
    "OPTIONS": "Opciones",
    "DETAILS": "Detalles",
    "STATUS": "Estado",
    "STATUS_PLACEHOLDER": "Seleccione los estados",
    "ALL_FEMALE": "Todas",
    "ALL_MALE": "Todos",
    "CLOSE": "Cerrar",
    "NEW": "Nuevo",
    "BREAKDOWN": "Desglose",
    "VALUE": "Valor",
    "MOBILE_PHONE": "Teléfono celular",
    "SECOND_MOBILE_PHONE": "Teléfono celular 2",
    "HOME_PHONE": "Teléfono fijo",
    "LOCALIZATION": "Localidad",
    "NO_SALES": "No hay ventas",
    "SALES_DONE": "Ventas realizadas",
    "SERVICES": "Servicios",
    "ME": "Yo",
    "ADMIN_ACCESS": "Administracion de acceso",
    "PRODUCT_ORDER_STATUS_HISTORY": "Historial de estados",
    "USD_SHORT": "USD",
    "DOLLAR_SHORT": "USD",
    "MN_SHORT": "MN",
    "MLC_SHORT": "MLC",
    "EURO_SHORT": "Euro",
    "MONEDA_NACIONAL_SHORT": "MN",
    "EXIT_WITHOUT_SAVING_CONFIRMATION": "¿Está seguro que desea salir sin guardar los cambios?",
    "UPDATE_PAYMENT_FEES": "Actualizar tarifas de pago",
    "NEW_DELIVERY": "Nueva entrega"
}

export default values;
