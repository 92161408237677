import React from 'react';
import NewSellerButtonProperties from "./NewSellerButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewSellerStore from "./NewSellerStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import SellerNameInput from "../../Inputs/SellerNameInput/SellerNameInput";
import EmailInput from "../../Inputs/EmailInput/EmailInput";
import {useStyles} from "./NewSellerButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import SellerRoleSelect from "../../Selects/SellerRoleSelect/SellerRoleSelect";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Role from "../../../entities/Role";
import useOnEnterPress from '../../../hooks/EnterPress';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';


const NewSellerButton: React.FC<NewSellerButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  sellerId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewSellerStore(commonContext, sellerId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onNameChange = (newName: string) => store.seller.name = newName;
    const onEmailChange = (newEmail: string) => store.seller.email = newEmail;
    const onRoleChange = (newRoles: Role[]) => {
        store.seller.roles = newRoles.map(r=> r.id);
    };
    const onSellerSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }
    useOnEnterPress(onSellerSave,[store]);

    const text = i18n.translate((store.isEditing ? "EDIT_SELLER" : "NEW_SELLER"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <SellerNameInput error={i18n.translate(store.nameError)} fullWidth className={classes.input}
                                         value={store.seller?.name}
                                         onChange={onNameChange}/>
                        <EmailInput error={i18n.translate(store.emailError)} fullWidth className={classes.input}
                                    value={store.seller?.email}
                                    onChange={onEmailChange}/>
                        <SellerRoleSelect fullWidth className={classes.input} onChange={onRoleChange}
                                          selectedRolesId={store.seller?.roles}/>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onSellerSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewSellerButton;
