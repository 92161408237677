import React from "react";
import IconProperties from "./IconProperties";

const HouseIcon: React.FC<IconProperties> = ({ className, fontSize }) => {
  return (
    <svg
      version="1.0"
      id="Layer_2"
      width="25px"
      height="25px"
      viewBox="0 0 64 64"
      enable-background="new 0 0 64 64"
    >
      <g>
        <path
          fill="#F9EBB2"
          d="M56,60c0,1.104-0.896,2-2,2H38V47c0-0.553-0.447-1-1-1H27c-0.553,0-1,0.447-1,1v15H10c-1.104,0-2-0.896-2-2
		V31.411L32.009,7.403L56,31.394V60z"
        />
        <polygon fill="#F76D57" points="14,6 18,6 18,12.601 14,16.593 	" />
        <rect x="28" y="48" fill="#F9EBB2" width="8" height="14" />
        <path
          fill="#F76D57"
          d="M61,33c-0.276,0-0.602-0.036-0.782-0.217L32.716,5.281c-0.195-0.195-0.451-0.293-0.707-0.293
		s-0.512,0.098-0.707,0.293L3.791,32.793C3.61,32.974,3.276,33,3,33c-0.553,0-1-0.447-1-1c0-0.276,0.016-0.622,0.197-0.803
		L31.035,2.41c0,0,0.373-0.41,0.974-0.41s0.982,0.398,0.982,0.398l28.806,28.805C61.978,31.384,62,31.724,62,32
		C62,32.552,61.553,33,61,33z"
        />
        <g>
          <path
            fill="#394240"
            d="M63.211,29.789L34.438,1.015c0,0-0.937-1.015-2.43-1.015s-2.376,0.991-2.376,0.991L20,10.604V5
			c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v13.589L0.783,29.783C0.24,30.326,0,31.172,0,32c0,1.656,1.343,3,3,3
			c0.828,0,1.662-0.251,2.205-0.794L6,33.411V60c0,2.211,1.789,4,4,4h44c2.211,0,4-1.789,4-4V33.394l0.804,0.804
			C59.347,34.739,60.172,35,61,35c1.657,0,3-1.343,3-3C64,31.171,63.754,30.332,63.211,29.789z M14,6h4v6.601l-4,3.992V6z M36,62h-8
			V48h8V62z M56,60c0,1.104-0.896,2-2,2H38V47c0-0.553-0.447-1-1-1H27c-0.553,0-1,0.447-1,1v15H10c-1.104,0-2-0.896-2-2V31.411
			L32.009,7.403L56,31.394V60z M61,33c-0.276,0-0.602-0.036-0.782-0.217L32.716,5.281c-0.195-0.195-0.451-0.293-0.707-0.293
			s-0.512,0.098-0.707,0.293L3.791,32.793C3.61,32.974,3.276,33,3,33c-0.553,0-1-0.447-1-1c0-0.276,0.016-0.622,0.197-0.803
			L31.035,2.41c0,0,0.373-0.41,0.974-0.41s0.982,0.398,0.982,0.398l28.806,28.805C61.978,31.384,62,31.724,62,32
			C62,32.552,61.553,33,61,33z"
          />
          <path
            fill="#394240"
            d="M23,32h-8c-0.553,0-1,0.447-1,1v8c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1v-8
			C24,32.447,23.553,32,23,32z M22,40h-6v-6h6V40z"
          />
          <path
            fill="#394240"
            d="M41,42h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1h-8c-0.553,0-1,0.447-1,1v8
			C40,41.553,40.447,42,41,42z M42,34h6v6h-6V34z"
          />
        </g>
        <rect x="28" y="48" fill="#506C7F" width="8" height="14" />
        <g>
          <rect x="16" y="34" fill="#45AAB8" width="6" height="6" />
          <rect x="42" y="34" fill="#45AAB8" width="6" height="6" />
        </g>
      </g>
    </svg>
  );
};
export default HouseIcon;
