import axios, {AxiosRequestConfig} from 'axios';
import Request from "../model/Request";
import Response from "../model/Response";
import Token from "../model/Token";
import {Random} from "./Random";

export module RestClient {

    export async function get(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.get(request.url, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function download(url: string): Promise<Response<{ data: any, filename: string }>> {
        try {
            const {data, headers} = await axios.get(url, {...getConfig(), ...{responseType: 'blob'}});
            const filename = headers["x-suggested-filename"]
            return Promise.resolve(new Response(true, {
                data,
                filename
            }));
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function postDownload(request: Request): Promise<Response<{ data: any, filename: string }>> {
        try {
            const {data, headers} = await axios.post(request.url, request.data, {...getConfig(), ...{responseType: 'blob'}});
            const filename = headers["x-suggested-filename"]
            return Promise.resolve(new Response(true, {
                data,
                filename
            }));
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function post(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.post(request.url, request.data, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function postFile(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.post(request.url, request.data, getConfig(true));
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function put(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.put(request.url, request.data, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }

    export async function remove(request: Request): Promise<Response<any>> {
        try {
            const axiosResponse = await axios.delete(request.url, getConfig());
            const response = new Response<any>(true, axiosResponse.data);
            return Promise.resolve(response);
        } catch (e) {
            return getErrorResponse(e);
        }
    }
}

const getConfig = (multipartFormData: boolean = false): AxiosRequestConfig => {
    const token = new Token();
    return {
        headers: {
            'Content-Type': multipartFormData ? 'multipart/form-data' : 'application/json',
            'Authorization': `Bearer ${token.value}`,
            'Hash': `1234`,
            'RequestId': Random.id()
        },
        withCredentials : true
    };
}

const getErrorResponse = (e: any): Promise<Response<any>> => {
    const message = e.response?.data?.message;
    const status = e.response?.status;
    const response = new Response<any>(false, undefined, message, status);
    return Promise.resolve(response);
}