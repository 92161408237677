import React from 'react';
import ViewSmall from '../../../../ui_components/MediaView/ViewSmall';
import ViewMedium from '../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../ui_components/MediaView/ViewLarge';
import ShowMiscellanyCostsButtonRowProperties from './ShowMiscellanyCostsButtonRowProperties';
import ShowMiscellanyCostsButtonRowMedium from './ShowMiscellanyCostsButtonRowMedium';
import ShowMiscellanyCostsButtonRowSmall from './ShowMiscellanyCostsButtonRowSmall';


const ShowMiscellanyCostsButtonRow: React.FC<ShowMiscellanyCostsButtonRowProperties> = ({
                                                    miscellanyPrice,
                                                    miscellanyCategories
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ShowMiscellanyCostsButtonRowSmall miscellanyPrice={miscellanyPrice} miscellanyCategories={miscellanyCategories}/>
            </ViewSmall>
            <ViewMedium>
                <ShowMiscellanyCostsButtonRowMedium miscellanyPrice={miscellanyPrice} miscellanyCategories={miscellanyCategories} />
            </ViewMedium>
            <ViewLarge>
                <ShowMiscellanyCostsButtonRowMedium miscellanyPrice={miscellanyPrice} miscellanyCategories={miscellanyCategories} />
            </ViewLarge>
        </>
    )
}

export default ShowMiscellanyCostsButtonRow;
