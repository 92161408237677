import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./Agency.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import StorefrontIcon from '@mui/icons-material/Storefront';
import AgencyRow from "./AgencyRow/AgencyRow";
import AgencyStore from "./AgencyStore";
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import NewAgencyButton from "../../shared_components/Buttons/NewAgencyButton/NewAgencyButton";
import SalePrice from '../../entities/SalePrice';
import useOnEnterPress from '../../hooks/EnterPress';


const Agency: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const agencyStore = new AgencyStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        agencyStore.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAgencyClick = (agencyId: string) => {
        agencyStore.selectedAgencyId = agencyId;
    }

    const onSalePriceSelect = (salePrice?: SalePrice) => {
        if (salePrice != null) {
            agencyStore.setAgencySalePrice(salePrice);
        }
    }

    const onUpdate = async () => {
        await agencyStore.getElements();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await agencyStore.goToPage(page, pageSize);
    }

    const onAgencySearch = () => {
        agencyStore.search()
    }
    useOnEnterPress(onAgencySearch,[agencyStore]);

    return (
        <Observer>
            {() => (
                <div className={classes.clients_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <NewAgencyButton type={ButtonType.SECONDARY} onSave={onUpdate} rounded/>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={agencyStore.filter}
                                     placeholder={i18n.translate("AGENCY_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         agencyStore.setFilter(value)
                                     }} onEnter={() => agencyStore.search()}/>
                        <Button
                            onClick={onAgencySearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    {!agencyStore.isEmpty &&
                    <div className={classes.clients_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("AGENCIES")}
                                        pagination={agencyStore.pagination}/>
                        <div className={classes.elements_container}>
                            {agencyStore.agencies.map((agency, i) => (
                                <AgencyRow
                                    onUpdate={onUpdate}
                                    onAgencyClick={onAgencyClick}
                                    onSalePriceSelect={onSalePriceSelect}
                                    agency={agency} key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("AGENCIES")}
                            pagination={agencyStore.pagination}/>
                    </div>}
                    {agencyStore.isEmpty &&
                    <MessageCard icon={StorefrontIcon} title={i18n.translate("NO_AGENCIES_TITLE")}
                                 subtitle={i18n.translate("NO_AGENCIES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default Agency;
