const values = {
    "CATEGORIES": "Categorías",
    "PACKAGE_CATEGORY": "Categoría de paquete",
    "PACKAGE_CATEGORIES": "Categoría de paquetes",
    "NEW_PACKAGE_CATEGORY": "Nuevo categoría",
    "EDIT_PACKAGE_CATEGORY": "Editar categoría",
    "NO_PACKAGE_CATEGORY_TITLE": "No hay categorías!!!",
    "NO_PACKAGE_CATEGORY_SUBTITLE": "Verifica si estás aplicando algún filtro",
}

export default values;