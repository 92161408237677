import React from 'react';
import MiscellanyOrderRowProperties from "./MiscellanyOrderRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import MiscellanyOrderRowMedium from "./MiscellanyOrderRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MiscellanyOrderRowSmall from "./MiscellanyOrderRowSmall";


const MiscellanyOrderRow: React.FC<MiscellanyOrderRowProperties> = ({
                                                                    miscellanyOrder,
                                                                    selectedDeliveryOrderIds,
                                                                    onMiscellanyOrderSelect,
                                                                    onUpdate,
                                                                    filter
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <MiscellanyOrderRowSmall onUpdate={onUpdate} onMiscellanyOrderSelect={onMiscellanyOrderSelect}
                                       miscellanyOrder={miscellanyOrder}
                                       selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                       filter={filter}/>
            </ViewSmall>
            <ViewMedium>
                <MiscellanyOrderRowMedium onUpdate={onUpdate} onMiscellanyOrderSelect={onMiscellanyOrderSelect}
                                        miscellanyOrder={miscellanyOrder}
                                        selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                        filter={filter}/>
            </ViewMedium>
            <ViewLarge>
                <MiscellanyOrderRowMedium onUpdate={onUpdate} onMiscellanyOrderSelect={onMiscellanyOrderSelect}
                                        miscellanyOrder={miscellanyOrder}
                                        selectedDeliveryOrderIds={selectedDeliveryOrderIds}
                                        filter={filter}/>
            </ViewLarge>
        </>
    )
}

export default MiscellanyOrderRow;
