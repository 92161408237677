import PagedResponse from "../../../entities/PagedResponse";
import { Config } from "../../../util/Config";
import { RestClient } from "../../../util/RestClient";
import Response from "../../../model/Response";
import Pagination from "../../../entities/Pagination";
import Request from "../../../model/Request";
import ReferenceItemPriceResponse from "../../../model/packageDelivery/referenceItemPrice/ReferenceItemPriceResponse";

export module ReferenceItemPriceService {

  export async function get(page: number = 1, pageSize: number = 15, filter?: string): Promise<Response<PagedResponse<ReferenceItemPriceResponse>>> {
    const url = Config.get("GET_REFERENCE_ITEM_PRICE_URL", {page, pageSize, filter,});
    const request = new Request(url);
    const response = await RestClient.get(request);

    if (response.success) {
      const resultData = response.data?.data;
      const paginationData = response.data?.pagination;

      if (resultData && paginationData) {
        const data = resultData.map(
          (t: any) =>
            new ReferenceItemPriceResponse(
              t.agencyId,
              t.referenceItemPriceId,
              t.referenceItemId,
              t.referenceItemName,
              t.referenceItemDescription,
              t.amount,
              t.cost
            )
        );
        const pagination = new Pagination(
          paginationData.currentPage,
          paginationData.pageSize,
          paginationData.elementsCount,
          paginationData.pageCount
        );
        return new Response<PagedResponse<ReferenceItemPriceResponse>>(true,{ data, pagination }, response.error);
      }
    }
    return new Response<PagedResponse<ReferenceItemPriceResponse>>(false, undefined, response.error, response.status);
  }

  export async function create(referenceItemPrice: ReferenceItemPriceResponse): Promise<Response<any>> {
    const url = Config.get("CREATE_REFERENCE_ITEM_PRICE_URL");
    const request = new Request(url, referenceItemPrice);
    return await RestClient.post(request);
  }

  export async function update(referenceItemPrice: ReferenceItemPriceResponse): Promise<Response<any>> {
    const url = Config.get("UPDATE_REFERENCE_ITEM_PRICE_URL", {
      referenceItemId: referenceItemPrice.referenceItemId,
      amount: referenceItemPrice.amount,
    });
    const request = new Request(url, {});
    return await RestClient.put(request);
  }

  export async function find(id: string): Promise<Response<ReferenceItemPriceResponse>> {
    const url = Config.get("FIND_REFERENCE_ITEM_PRICE_URL", { id });
    const request = new Request(url);
    const response = await RestClient.get(request);

    if (response.success) {
      const data = response.data;
      if (data) {
        const referenceItemPrice = new ReferenceItemPriceResponse(
          data.agencyId,
          data.referenceItemPriceId,
          data.referenceItemId,
          data.referenceItemName,
          data.referenceItemDescription,
          data.amount,
          data.cost
        );
        return new Response<ReferenceItemPriceResponse>(true, referenceItemPrice, response.error);
      }
    }
    return new Response<ReferenceItemPriceResponse>(false, undefined, response.error, response.status);
  }

  export async function remove(referenceItemPrice: ReferenceItemPriceResponse): Promise<Response<any>> {
    const url = Config.get("REMOVE_REFERENCE_ITEM_PRICE_URL", {id: referenceItemPrice.referenceItemId,});
    const request = new Request(url);
    return await RestClient.remove(request);
  }

  export async function getAllFromBaseAgency(): Promise<Response<ReferenceItemPriceResponse[]>> {
    const url = Config.get("GET_REFERENCE_ITEM_PRICE_FROM_BASE_AGENCY_URL");
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const referenceItemPrices = response.data;
      if (referenceItemPrices) {
        const data = referenceItemPrices.map(
          (t: any) =>
            new ReferenceItemPriceResponse(
              t.agencyId,
              t.referenceItemPriceId,
              t.referenceItemId,
              t.referenceItemName,
              t.referenceItemDescription,
              t.amount,
              t.cost
            )
        );
        return new Response<ReferenceItemPriceResponse[]>(true, data, response.error);
      }
    }
    return new Response<ReferenceItemPriceResponse[]>(false, undefined, response.error, response.status);
  }
}
