import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    title: {
        display: 'flex',
        fontSize: '18px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "4px 8px"
    },
    prices_container: {
        display: 'grid',
        rowGap: '8px'
    },
    inline: {
        columnGap: 4,
        padding: 6,
        gridTemplateColumns: "repeat( auto-fit, minmax(200px, 1fr) )",
    },
    no_content_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        height: '100%',
        color: theme.palette.grey[400],
        textAlign: 'center',
        cursor: 'pointer',
        "& svg": {
            fontSize: '50px'
        }
    },
    loading_container: {
        minHeight: '80px',
    },
    download_btn_container: {
        position: 'absolute',
        right: '6px',
        marginTop: '-60px'
    }
}));

export {useStyles}