import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./Articles.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import NewArticleButton from "../../shared_components/Buttons/NewArticleButton/NewArticleButton";
import ArticleStore from './ArticleStore';
import ArticleRow from './ArticleRow/ArticleRow';
import GeoSelect from "../../shared_components/Selects/GeoSelect/GeoSelect";
import ShowArticleCostsButton from '../../shared_components/Buttons/ShowArticleCostsButton/ShowArticleCostsButton';


const Articles: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const articleStore = new ArticleStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        articleStore.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await articleStore.getElements();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await articleStore.goToPage(page, pageSize);
    }


    return (
        <Observer>
            {() => (
                <div className={classes.articles_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <ShowArticleCostsButton type={ButtonType.SECONDARY}/>
                        <div></div>
                        <NewArticleButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={articleStore.filter}
                                     placeholder={i18n.translate("ARTICLE_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                        articleStore.setFilter(value)
                                     }} onEnter={() => articleStore.search()}/>
                        <GeoSelect onChange={(l)=> {console.log(l)}}/>
                        <Button
                            onClick={() => {
                                articleStore.search()
                            }}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    {!articleStore.isEmpty &&
                    <div className={classes.articles_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("ARTICLES")}
                                        pagination={articleStore.pagination}/>
                        <div className={classes.elements_container}>
                            {articleStore.articles.map((article, i) => (
                                <ArticleRow
                                    onUpdate={onUpdate}
                                    article={article} key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("ARTICLES")}
                            pagination={articleStore.pagination}/>
                    </div>}
                    {articleStore.isEmpty &&
                    <MessageCard icon={FastfoodIcon} title={i18n.translate("NO_ARTICLES_TITLE")}
                                 subtitle={i18n.translate("NO_ARTICLES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default Articles;
