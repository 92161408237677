import React from 'react';
import ChooseContactLinkProperties from "./ChooseContactLinkProperties";
import ChooseContactStore from "./ChooseContactStore";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ChooseContactLink.style";
import {useCommonContext} from "../../../contexts/CommonContext";
import Link from '../../../ui_components/Link/Link';
import ContactSelectList from '../../Selects/ContactSelectList/ContactSelectList';
import ClientContact from '../../../entities/ClientContact';

const ChooseContactLink: React.FC<ChooseContactLinkProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSelect = () => {
                                                                                },
                                                                                clientId
                                                                            }) => {
    const classes = useStyles();
    const commonContext = useCommonContext();
    const store = new ChooseContactStore(commonContext);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }
    
    const onContactChange = async (contact: ClientContact) => {
        onSelect(contact);
        onCloseModal();
    }

    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={'Seleccionar destinatario'} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <ContactSelectList clientId={clientId} onContactSelect={onContactChange}/>
                    </div>
                </Modal>
                <div className={classes.btn_container}>
                    <div><Link text={'Cambiar destinatario'} onClick={handleOnclick}></Link></div>
                </div>
            </>
        )}
    </Observer>
}

export default ChooseContactLink;
