import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    print_button: {
        "@media print": {
            display: 'none'
        }
    }
}));

export {useStyles}