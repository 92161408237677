import {action, makeObservable, observable, runInAction} from "mobx";
import {SalesService} from "../../../service/SalesService";
import CommonStore from "../../../stores/CommonStore";
import MoneyDeliveryOrderOverviewResponse from "../../../model/sales/productOrder/MoneyDeliveryOrderOverviewResponse";
import Currency from "../../../entities/Currency";

class MoneyDeliveryOverviewStore {

    public overview?: MoneyDeliveryOrderOverviewResponse;
    public from?: number;
    public to?: number;
    public filter?: string;
    public deliveryPersonId?: string;
    public status?: string[];
    public currency?: Currency;
    public type?: string;
    public geoId?: string;
    public agencyId?: string;
    public userId?: string;
    public includeChildren?: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(
        commonStore: CommonStore,
        from?: number,
        to?: number,
        filter?: string,
        deliveryPersonId?: string,
        status?: string[],
        currency?: Currency,
        type?: string,
        geoId?: string,
        agencyId?: string,
        userId?: string,
        includeChildren?: boolean
    ) {
        this.commonStore = commonStore;
        this.from = from;
        this.to = to;
        this.filter = filter;
        this.deliveryPersonId = deliveryPersonId;
        this.status = status;
        this.currency = currency;
        this.type = type;
        this.geoId = geoId;
        this.agencyId = agencyId;
        this.userId = userId;
        this.loading = false;
        this.includeChildren = includeChildren;
        makeObservable(this, {
            overview: observable,
            loading: observable,
        });
    }


    @action
    public async getElements(): Promise<void> {
        this.loading = true;
        const response = await SalesService.getMoneyDeliveryOrderOverview(
            this.from, this.to, this.filter, this.deliveryPersonId,
            this.status, this.currency, this.type, this.geoId, this.agencyId, this.includeChildren);
        if (response.success) {
            runInAction(() => {
                this.overview = response.data;
            })
        }
        this.loading = false;
        this.commonStore.processErrors(response);
    }
}

export default MoneyDeliveryOverviewStore;