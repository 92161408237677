import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import EmailIcon from '@material-ui/icons/Email';
import EmailInputProperties from "./EmailInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";

const EmailInput: React.FC<EmailInputProperties> = ({
                                                        value,
                                                        onChange,
                                                        disabled,
                                                        error,
                                                        fullWidth = false,
                                                        className = '',
                                                        required = false,
                                                    }) => {

    const [email, setEmail] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setEmail(value);
    }, [value]);

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        onChange(newEmail);
    }

    return (
        <TextInput
            label={i18n.translate("EMAIL") + `${required ? '*' : ''}`}
            error={error}
            value={email}
            disabled={disabled}
            onChange={onEmailChange}
            startIcon={EmailIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default EmailInput;