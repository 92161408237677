import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_comment_container: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    icon: {
        color: theme.palette.grey[700],
        fontSize: '18px'
    },
    value_container: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        marginLeft: '4px'
    },
    empty: {
        "& $value_container,$icon": {
            fontStyle: 'italic',
            color: theme.palette.grey[400],
        }
    }
}));

export {useStyles}