import React from 'react';
import { useStyles } from "./ShowServicesShortcuts.style";
import ShowServicesShortcutsProperties from "./ShowServicesShortcutsProperties";
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import {Inventory} from "@mui/icons-material";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";

const ShowServicesShortcuts: React.FC<ShowServicesShortcutsProperties> = ({
  className = '',
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Card>
        <CardContent>
          <Typography variant="h6" component="div" mb={2}>
            Accesos Directos
          </Typography>
          <div className={classes.buttons_container}>
            <Button
              component={Link}
              to="/money-remittances"
              startIcon={<AttachMoneyIcon style={{ fontSize: 40 }} />}
              variant="contained"
              color="primary"
              fullWidth
            >
              Remesas de Dinero
            </Button>
            <Button
              component={Link}
              to="/article-remittances"
              startIcon={<FastfoodIcon style={{ fontSize: 40 }} />}
              variant="contained"
              color="primary"
              fullWidth
            >
              Envío de Artículos
            </Button>
            <Button
              component={Link}
              to="/package-delivery"
              startIcon={<Inventory style={{ fontSize: 40 }} />}
              variant="contained"
              color="primary"
              fullWidth
            >
              Envío de Paquetes
            </Button>
            <Button
              component={Link}
              to="/topups"
              startIcon={<SendToMobileIcon style={{ fontSize: 40 }} />}
              variant="contained"
              color="primary"
              fullWidth
            >
              Recargas de Celular
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
export default ShowServicesShortcuts;