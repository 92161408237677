import React from 'react';
import {Observer} from "mobx-react-lite";
import MenuItem from '@mui/material/MenuItem';
import useConfirmation from '../../../../../hooks/ConfirmationDialog';
import { useI18nContext } from '../../../../../contexts/I18nContext';
import RemoveOptionProperties from './RemoveOptionProperties';
import Icon from '../../../../../ui_components/Icon/Icon';
import IconName from '../../../../../ui_components/Icon/enums/IconName';

const RemoveOption: React.FC<RemoveOptionProperties> = ({
                                                                        onRemove = () => {
                                                                        },
                                                                        salePriceName,
                                                                        store,
                                                                    }) => {

    const i18n = useI18nContext();
    const confirm = useConfirmation();

    const handleClick = async () => {
        const ok = await confirm(`${salePriceName}`, i18n.translate("REMOVE_SALE_PRICE_CONFIRMATION"));
        if (ok) {
            await store.remove();
            onRemove();
        }
    };

    return <Observer>
        {() => (
            <MenuItem onClick={handleClick}><Icon name={IconName.DELETE_ICON}/>{i18n.translate("REMOVE_SALE_PRICE")}</MenuItem>
        )}
    </Observer>
}

export default RemoveOption;
