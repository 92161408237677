import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import { TopupPriceService } from "../../service/topup/TopupPriceService";
import TopupPrice from "../../entities/productOrder/topup/TopupPrice";
import Product from "../../entities/productOrder/topup/Product";
import { ProductService } from "../../service/topup/ProductService";

class TopupPriceStore {

    public topupPrices: TopupPrice[];
    public products: Product[];
    public filter?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore) {
        this.topupPrices = [];
        this.commonStore = commonStore;
        this.products = [];
        makeObservable(this, {
            topupPrices: observable,
            isEmpty: computed,
            products: observable
        });
    }

    get isEmpty(): boolean {
        return this.topupPrices.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements();
    }

    @action
    public async getProducts() {
        const response = await ProductService.get();
        if (response.success && response.data) {
            this.products = response.data
        }
    }

    @action
    public async getElements(): Promise<void> {
        this.commonStore.setLoading();
        const response = await TopupPriceService.get();
        if (response.success) {
            runInAction(() => {
                this.topupPrices = response.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default TopupPriceStore;