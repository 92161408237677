import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import SalesByPaymentTypeResponse from "../model/sales/SalesByPaymentTypeResponse";
import SalesByServiceResponse from "../model/sales/SalesByServiceResponse";
import SalesByUserResponse from "../model/sales/SalesByUserResponse";
import SalesByAgencyResponse from "../model/sales/SalesByAgencyResponse";
import MoneyDeliveryOrderOverviewResponse from "../model/sales/productOrder/MoneyDeliveryOrderOverviewResponse";
import PackageDeliveryOrderOverviewResponse from "../model/sales/productOrder/PackageDeliveryOrderOverviewResponse";
import MoneyDeliverySalesResponse from "../model/sales/productOrder/MoneyDeliverySalesResponse";
import TopupSalesResponse from "../model/sales/productOrder/TopupSalesResponse";
import ArticleSalesResponse from "../model/sales/productOrder/ArticleSalesResponse";
import MiscellanySalesResponse from "../model/sales/productOrder/MiscellanySalesResponse";

export module SalesService {
    export async function getMoneyDeliveryOrderOverview(from: number = 0,
                                                        to: number = 0,
                                                        filter?: string,
                                                        deliveryPersonId?: string,
                                                        status?: string[],
                                                        currency?: string,
                                                        type?: string,
                                                        geoId?: string,
                                                        agencyId?: string,
                                                        includeChildren?: boolean): Promise<Response<MoneyDeliveryOrderOverviewResponse>> {
        const url = Config.get("GET_MONEY_DELIVERY_ORDER_OVERVIEW_URL", {
            from,
            to,
            filter,
            deliveryPersonId,
            status,
            currency,
            type,
            geoId,
            agencyId,
            includeChildren
        });
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getPackageDeliveryOrderOverview(
      from: number = 0,
      to: number = 0,
      filter?: string,
      deliveryPersonId?: string,
      status?: string[],
      geoId?: string,
      agencyId?: string,
      shippingId?:string,
      includeChildren?: boolean
    ): Promise<Response<PackageDeliveryOrderOverviewResponse>> {
      const url = Config.get("GET_PACKAGE_DELIVERY_ORDER_OVERVIEW_URL", {
        from,
        to,
        filter,
        deliveryPersonId,
        status,
        geoId,
        agencyId,
        shippingId,
        includeChildren,
      });
      const request = new Request(url);
      return await RestClient.get(request);
    }

    export async function getSalesByPaymentType(from?: number, to?: number, agencyId?: String, userId?: String): Promise<Response<SalesByPaymentTypeResponse>> {
        const url = Config.get("GET_SALES_BY_PAYMENT_TYPE_URL", {from, to, agencyId, userId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getSalesByService(from?: number, to?: number, agencyId?: String, userId?: String, includeChildren?: boolean): Promise<Response<SalesByServiceResponse>> {
        const url = Config.get("GET_SALES_BY_SERVICE_URL", {from, to, agencyId, userId, includeChildren});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getSalesByUser(from?: number, to?: number, agencyId?: String): Promise<Response<SalesByUserResponse>> {
        const url = Config.get("GET_SALES_BY_USER_URL", {from, to, agencyId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getSalesByAgency(from?: number, to?: number, agencyId?: String): Promise<Response<SalesByAgencyResponse>> {
        const url = Config.get("GET_SALES_BY_AGENCY_URL", {from, to, agencyId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getMoneyDeliverySales(from: number, to: number, agencyId?: String, userId?: String): Promise<Response<MoneyDeliverySalesResponse>> {
        const url = Config.get("GET_MONEY_DELIVERY_SALES_URL", {from, to, agencyId, userId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getTopupSales(from: number, to: number, agencyId?: String, userId?: String): Promise<Response<TopupSalesResponse>> {
        const url = Config.get("GET_TOPUP_SALES_URL", {from, to, agencyId, userId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getArticleSales(from: number, to: number, agencyId?: String, userId?: String): Promise<Response<ArticleSalesResponse>> {
        const url = Config.get("GET_ARTICLE_SALES_URL", {from, to, agencyId, userId});
        const request = new Request(url)
        return await RestClient.get(request)
    }

    export async function getMiscellanySales(from: number, to: number, agencyId?: String, userId?: String): Promise<Response<MiscellanySalesResponse>> {
        const url = Config.get("GET_MISCELLANY_SALES_URL", {from, to, agencyId, userId});
        const request = new Request(url)
        return await RestClient.get(request)
    }
}
