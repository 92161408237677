import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPerson from "../../../entities/DeliveryPerson";

class ChooseDeliveryPersonStore {

    public selectedDeliveryPerson?: DeliveryPerson;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public setDeliveryPerson(deliveryPerson?: DeliveryPerson) {
        this.selectedDeliveryPerson = deliveryPerson;
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
    
}

export default ChooseDeliveryPersonStore;