import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    password_recover_page_container: {
        backgroundImage: "url(/webb.png)",
        backgroundPosition: 'bottom',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form_container: {
        boxSizing: 'border-box',
        position: 'relative',
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '6px 6px 0 0',
        padding: '8px 8px',
        textAlign: 'center',
    },
    body: {
        border: `1px solid ${theme.palette.grey["200"]}`,
        borderRadius: '0 0 6px 6px',
        padding: '32px 32px 32px 32px',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        "& button": {
            marginTop: '32px'
        }
    },
    '@keyframes bp': {
        "from": {
            backgroundPosition: '150px 0'
        },
        "to": {
            backgroundPosition: '0 173px'
        }
    },
    recover_btn:{
        marginTop: '16px'
    },
    description_container:{
        maxWidth: '400px',
        marginTop: '0'
    }
}));

export {useStyles}
export default useStyles