import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import {TopupService} from "../../../service/topup/TopupService";


class ConfirmTopupButtonStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public orderId: string;


    constructor(commonStore: CommonStore, orderId: string) {
        this.orderId = orderId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async confirm() {
        this.loading = true;
        const response = await TopupService.confirm(this.orderId);
        this.commonStore.processErrors(response);
        const success = response.data?.success || false;
        this.loading = false;
        return success;
    }
}

export default ConfirmTopupButtonStore;