import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ServiceName from '../../../entities/ServiceName';
import ShowService from '../../../shared_components/Info/ShowService/ShowService';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import SalePriceTableMoneyRowProperties from './SalePriceTableMoneyRowProperties';
import ShowEditMoneySalePrice from '../../../shared_components/InfoEditables/ShowEditMoneySalePrice/ShowEditMoneySalePrice';
import Currency from '../../../entities/Currency';
import { useStyles } from '../SalePrices.style';


const SalePriceTableMoneyRow: React.FC<SalePriceTableMoneyRowProperties> = ({
    moneyServiceFees, salePrices, onUpdate
                                                  }) => {

    const classes = useStyles();

    const onUpdateValue = () => {
        onUpdate();
    }

    const currencies = [Currency.DOLLAR, Currency.MLC, Currency.MN];

    return (
        <>
        <TableRow hover role="checkbox" tabIndex={-1} key={'row.id'}>
            <TableCell key={`column.id`} align={'right'}>
                <ShowService service={ServiceName.MONEY_DELIVERY}/>
            </TableCell>
        </TableRow>
        {moneyServiceFees.map((moneyServiceFee: any) => {
            return (
                <TableRow>
                    <TableCell key={moneyServiceFee.id} align={'left'}>
                        <ShowText text={`${moneyServiceFee.fromValue} - ${moneyServiceFee.toValue}`}/>
                    </TableCell>
                    {salePrices.map((price) => {
                        const moneySalePrice = price.moneySalePrices ? price.moneySalePrices.find(t => t.serviceFeeId === moneyServiceFee.id) : null;
                        return (
                        <TableCell key={price.id} align={'left'}>
                            <div className={classes.values_container}>
                                {currencies.map((currency: Currency, i) => {
                                    const salePriceCurrency = moneySalePrice?.salePriceByCurrency.find(b => b.currency === currency);
                                    return <ShowEditMoneySalePrice
                                        value={salePriceCurrency ? salePriceCurrency.salePrice : 0}
                                        isPercentage={salePriceCurrency ? salePriceCurrency.isPercentage : true}
                                        currency={currency}
                                        salePriceId={price.id}
                                        serviceFeeId={moneyServiceFee.id}
                                        onSave={onUpdateValue}/>
                                })}
                            </div>
                        </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
        </>
    )
}

export default SalePriceTableMoneyRow;
