import {action, makeAutoObservable} from "mobx";
import { MoneyDeliveryService } from "../../../service/moneyDelivery/MoneyDeliveryService";
import CommonStore from "../../../stores/CommonStore";


class RemoveMoneyDeliveryStore {

    public moneyDeliveryOrderId: string;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, moneyDeliveryOrderId: string) {
        this.moneyDeliveryOrderId = moneyDeliveryOrderId;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await MoneyDeliveryService.remove(this.moneyDeliveryOrderId);
        if (!response.success && response.status === 400 && response.error === 'ERROR_00_400_44') {
            await this.commonStore.showErrorToast('No se pudo eliminar el envío porque está en un estado que lo imposibilita');
        } else {
            this.commonStore.processErrors(response);
        }        
        return response.success;
    }
}

export default RemoveMoneyDeliveryStore;