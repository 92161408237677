import React from 'react';
import AdditionalPricesProperties from "./AdditionalPricesProperties";
import {Observer} from "mobx-react-lite";
import AddAdditionalPrice from '../AddAdditionalPrice/AddAdditionalPrice';
import AdditionalPriceRow from '../AdditionalPriceRow/AdditionalPriceRow';
import OrderAdditionalPrice from '../../../../../entities/OrderAdditionalPriceRequest';
import MessageCard from '../../../../../ui_components/MessageCard/MessageCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useI18nContext } from '../../../../../contexts/I18nContext';
import { useStyles } from './AdditionalPrices.style';
import { Typography } from '@material-ui/core';
import CategorySelect from '../../CategorySelect/CategorySelect';

const AdditionalPrices: React.FC<AdditionalPricesProperties> = ({
                                                                    store
                                                                }) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    const removeAdditionalPrice = async (additionalPrice: OrderAdditionalPrice) => {
        await store.removeAdditionalPrice(additionalPrice);
    }

    const getAdditionalPrices = () => {
        return store.additionalPrices.reduce((total, obj) => total + obj.amount, 0);
    }

    const getCategoryPrice = () => {
        return store.selectedMiscellanyCategory ? store.selectedMiscellanyCategory.price : 0;
    }

    const getTotalPrices = () => {
        return getCategoryPrice() + getAdditionalPrices();
    }

    const noData = <MessageCard icon={AttachMoneyIcon} className={classes.no_data_container}
        title={i18n.translate("NO_ADDITIONAL_PRICE_TITLE")}
        subtitle={i18n.translate("NO_ADDITIONAL_PRICE_SUBTITLE")}/>

    return <Observer>
        {() => (
            <div className={classes.container}>
                <div className={classes.articles_resume_container}>
                    <CategorySelect store={store}/>
                </div>
                <div className={classes.resume_container}>
                    <div className={classes.additional_price_resume_container}>
                        <Typography variant="subtitle1" className={classes.title} component="div">
                            Tarifas adicionales
                        </Typography>
                        <div>
                            <AddAdditionalPrice store={store}></AddAdditionalPrice>
                        </div>
                        <div className={classes.list_container}>
                        {store.additionalPrices.length > 0 ? store.additionalPrices.map((t, i) => (
                                <AdditionalPriceRow onDelete={removeAdditionalPrice} additionalPrice={t} key={i}/>
                            )) : noData}
                        </div>
                    </div>
                    <div className={classes.resume_all_container}>
                        <Typography variant="subtitle1" className={classes.title} component="div">
                            Pre-Resumen
                        </Typography>
                        <div className={classes.pre_resume_item_container}>
                            <div>Servicio</div>
                            <div>{getCategoryPrice()} USD</div>
                        </div>
                        <div className={classes.pre_resume_item_container}>
                            <div>Tarifas adicionales</div>
                            <div>{getAdditionalPrices()} USD</div>
                        </div>
                        <div className={classes.pre_resume_item_container}>
                            <div className={classes.text_bold}>Total</div>
                            <div className={classes.text_bold}>{getTotalPrices()} USD</div>
                        </div>
                    </div>
                </div>
                
            </div>
        )}
    </Observer>
}

export default AdditionalPrices;
