import React, { useState } from 'react';
import ShowImageProperties from "./ShowImageProperties";
import {useStyles} from "./ShowImage.style";
import clsx from "clsx";
import { Config } from '../../../util/Config';
import Modal from '../../../ui_components/Modal/Modal';
// import { Carousel } from "react-responsive-carousel";

const ShowImage: React.FC<ShowImageProperties> = ({
                                                        imageName,
                                                        width = 0,
                                                        bigImageWidth = 0,
                                                        height = 200,
                                                        bigImageHeight = 400,
                                                        className = '',
                                                      }) => {

    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const imageLink = Config.get('IMAGE_CDN_URL', {
        imageName: imageName ?? 'article-thumbnail.jpg',
        width,
        height
    });

    const bigImageLink = Config.get('IMAGE_CDN_URL', {
        imageName: imageName ?? 'article-thumbnail.jpg',
        width: bigImageWidth,
        height: bigImageHeight
    });

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div className={clsx(className)}>
            <Modal fullScreen={false} title={'Preview'} onClose={closeModal} open={isModalOpen}>
                {/* <Carousel>
                    <div>
                        <img src={bigImageLink} />
                    </div>
                    <div>
                        <img src={bigImageLink} />
                    </div>
                </Carousel> */}
                <img src={bigImageLink} alt={''}/>
            </Modal>
            <img src={imageLink} onClick={openModal} className={classes.cursor_pointer} alt={''}/>
        </div>
    )
}


export default ShowImage;