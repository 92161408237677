import { useEffect } from "react";
import { useStyles } from "./ReferenceItemPriceSelect.style";
import { useCommonContext } from "../../../contexts/CommonContext";
import { useI18nContext } from "../../../contexts/I18nContext";
import useOnEnterPress from "../../../hooks/EnterPress";
import Width from "../../../ui_components/common/enums/Width";
import Box from "../../../ui_components/Box/Box";
import Alignment from "../../../ui_components/common/enums/Alignment";
import { Observer } from "mobx-react-lite";
import FilterInput from "../../Inputs/FilterInput/FilterInput";
import Button from "../../../ui_components/Button/Button";
import ReferenceItemPriceSelectRow from "./ReferenceItemPriceSelectRow/ReferenceItemPriceSelectRow";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ReferenceItemPriceSelectStore from "./ReferenceItemPriceSelectStore";
import ShowPaginationSmall from "../../../ui_components/ShowPagination/ShowPaginationSmall/ShowPaginationSmall";
import ReferenceItemPriceSelectProperties from "./ReferenceItemPriceSelectProperties";



const ReferenceItemPriceSelect: React.FC<ReferenceItemPriceSelectProperties> = ({ 
  className,
}) => {
  const commonStore = useCommonContext();
  const i18n = useI18nContext();
  const store = new ReferenceItemPriceSelectStore(commonStore);
  const classes = useStyles();

  useEffect(() => {
    store.getElements();
  });

  const onPaginationChange = async (page: number, pageSize: number) => {
    await store.goToPage(page, pageSize);
  };
  const onSearch = () => {
    store.search();
  };
  const onCleanSearch = () => {
    store.cleanSearch();
  };
  const onValueChange = (value: string) => {
    store.setFilter(value);
  };

  useOnEnterPress(onSearch, [store]);

  return (
      <Observer>
          {() => (
              <div className={classes.foodPackages_container}>
                <Box
                  className={classes.filter_container}
                  width={Width.FULL_WIDTH}
                  justifyContent={Alignment.SPACE_BETWEEN}
                >
                  <FilterInput
                    value={store.filter}
                    placeholder={i18n.translate("INVENTORY_ITEMS_PLACEHOLDER")}
                    onChange={onValueChange}
                    onEnter={onSearch}
                    fullWidth={true}
                  />
                  <Button
                      onClick={onCleanSearch}
                      className={classes.search_btn}
                      text={i18n.translate("CLEAN")}
                    />
                </Box>
                {!store.isEmpty && (
                  <div className={classes.items_inventory_table}>
                    <ShowPaginationSmall
                      onChange={onPaginationChange}
                      elementLabel={i18n.translate("ITEMS")}
                      pagination={store.pagination}
                    />
                    <div className={classes.elements_container}>
                      {store.referenceItemsPrice.map((referenceItemPriceResponse, i) => (
                        <ReferenceItemPriceSelectRow
                          referenceItemPriceResponse={referenceItemPriceResponse}
                          key={i}
                          filter={store.filter}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {store.isEmpty && (
                  <MessageCard
                    title={i18n.translate("NO_REFERENCE_ITEMS_TITLE")}
                    icon={StorefrontIcon}
                    subtitle={i18n.translate("NO_REFERENCE_ITEMS_SUBTITLE")}
                  />
                )}
              </div>
          )}
      </Observer>
  );
};
export default ReferenceItemPriceSelect;
