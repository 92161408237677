import React from 'react';
import IconProperties from "./IconProperties";


const CheckPaymentIcon: React.FC<IconProperties> = ({
                                                     className,
                                                     fontSize
                                                 }) => {
    return (
        <svg className={className}  viewBox="0 0 508.007 508.007" x="0px" y="0px" width="1em" height="1em" style={{fontSize: fontSize}}>
            <g>
                <g>
                    <path d="M181.1,254.571H70.3c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.4,14.1,14.1,14.1h110.8c7.8,0,14.1-6.3,14.1-14.1
			C195.2,260.871,188.9,254.571,181.1,254.571z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M436.2,310.871H70.3c-7.7,0.1-14,6.4-14,14.1c0,7.8,6.3,14.1,14.1,14.1h365.8c7.8,0,14.1-6.3,14.1-14.1
			S444,310.871,436.2,310.871z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M436.2,367.271H70.3c-7.8,0-14.1,6.3-14.1,14.1s6.3,14.1,14.1,14.1h365.8c7.8,0,14.1-6.3,14.1-14.1
			C450.2,373.571,443.9,367.271,436.2,367.271z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M147.4,423.571H70.3c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h77.1c7.8,0,14.1-6.3,14.1-14.1
			C161.5,429.871,155.2,423.571,147.4,423.571z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M436.2,423.571h-77.1c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1h77.1c7.8,0,14.1-6.3,14.1-14.1
			C450.2,429.871,443.9,423.571,436.2,423.571z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M492.4,198.371H380.2l111.7-111.7c16.3-16.3,26.1-44.6,0-70.7c-25.6-25.6-54.3-16.4-70.7,0l-170.5,170.5
			c-1.5,1.5-2.7,3.4-3.4,5.5l-2.1,6.3H14.1c-7.8,0-14.1,6.3-14.1,14.1v281.5c0,7.8,6.3,14.1,14.1,14.1h478.4
			c7.8,0,14.1-6.3,14.1-14.1v-281.5C506.5,204.671,500.2,198.371,492.4,198.371z M441.1,35.871c8.3-8.3,22.6-8.3,30.9,0
			c8.2,8.2,8.2,22.7,0,30.9l-8.2,8.2l-30.9-30.9L441.1,35.871z M413,63.971l30.9,30.9l-124.2,124.2l-30.9-30.9L413,63.971z
			 M270.9,210.071l26.9,27l-40.6,13.7L270.9,210.071z M28.1,479.871v-253.4h207.5l-14.2,42.1c-1.7,5.1-0.4,10.7,3.4,14.4
			c5,5,13,3.9,14.4,3.4l76.5-25.8c2.1-0.7,3.9-1.8,5.5-3.4l30.7-30.7h126.3v253.4H28.1z"/>
                </g>
            </g>
        </svg>
    )
}
export default CheckPaymentIcon;