import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_comment_container: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        paddingTop: '8px',
        borderBottom: '1px solid #efefef'
    },
    comment_left: {
        justifyContent: 'start'
    },
    comment_right: {
        justifyContent: 'end'
    },
    icon: {
        color: theme.palette.grey[700],
        fontSize: '40px',
        opacity: '0.6'
    },
    date_container: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        marginLeft: '4px'
    },
    value_container: {
        color: theme.palette.grey[900],
        marginLeft: '4px'
    },
    empty: {
        "& $value_container,$icon": {
            fontStyle: 'italic',
            color: theme.palette.grey[400],
        }
    }
}));

export {useStyles}