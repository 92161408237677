import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    card_container: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    box_container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    card_content: {
        flex: '1 0 auto',
    },
    image: {
        height: '100px',
        width: '100px'
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px'
    },
    title: {
        fontWeight: 800
    },
    price: {
        marginTop: '5px'
    },
    description_container: {
        height: '90px',
        paddingBottom: '20px',
        overflow: 'hidden'
    },
    expand_button_container: {
        textAlign: 'right',
        paddingBottom: '10px'
    },
    price_container: {
    },
    amount_input: {
        marginLeft: '5px',
        width: '60px',
        textAlign: 'center',
    },
    amount_input_contaier: {
        width: '100px'
    },
    description: {
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: '14px',
        color: '#666',
    },
    stock_info: {
        fontSize: '12px',
        color: '#888',
        marginBottom: '15px',
    },
    amount_input_container: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
}));

export {useStyles}