import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    global_button_container: {
        position: 'sticky',
        right: '32px',
        bottom: '32px'
    },
}));

export {useStyles}