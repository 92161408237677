import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import Currency from "../../entities/Currency";
import MoneyDeliveryBudgetResponse from "../../model/moneyDelivery/MoneyDeliveryBudgetResponse";
import PagedResponse from "../../entities/PagedResponse";
import MoneyDeliveryOrderResponse from "../../model/moneyDelivery/MoneyDeliveryOrderResponse";
import Pagination from "../../entities/Pagination";
import MoneyDeliveryOrderRequest from "../../model/moneyDelivery/MoneyDeliveryOrderRequest";
import DeliveryOrderCommentResponse from "../../model/moneyDelivery/DeliveryOrderCommentResponse";
import Phone from "../../entities/Phone";
import MoneyDeliveryValuesRequest from "../../model/moneyDelivery/MoneyDeliveryValuesRequest";

export module MoneyDeliveryService {
    export async function get(page: number = 1, pageSize: number = 15, from: number = 0, to: number = 0, filter?: string, deliveryPersonId?: string,
                              status?: string[], currency?: string, type?: string, geoIds?: string[], agencyId?: string): Promise<Response<PagedResponse<MoneyDeliveryOrderResponse>>> {
        const url = Config.get("GET_MONEY_REMITTANCE_URL", {
            page,
            pageSize,
            from,
            to,
            filter,
            deliveryPersonId,
            status,
            currency,
            type,
            geoIds,
            agencyId
        });
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const resultData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (resultData && paginationData) {
                const data = resultData.map((t: any) => new MoneyDeliveryOrderResponse(
                    t.id,
                    t.orderNumber,
                    t.agencyId,
                    t.agencyName,
                    t.clientId,
                    t.clientName,
                    t.clientPhone ? new Phone(t.clientPhone.number, t.clientPhone.countryCode, t.clientPhone.phoneType) : null,
                    t.contactId,
                    t.contactName,
                    t.contactPhone ? new Phone(t.contactPhone.number, t.contactPhone.countryCode, t.contactPhone.phoneType) : null,
                    t.userId,
                    t.userName,
                    t.deliveryPersonId,
                    t.deliveryPersonName,
                    t.serviceFee,
                    t.totalToPay,
                    t.serviceCost,
                    t.totalToReceive,
                    t.conversionRate,
                    t.currencyToReceive,
                    t.status,
                    t.createdAt,
                    t.isBankCard,
                    t.address,
                    t.geo,
                    t.contactBankCardNumber,
                    t.contactBankCardBank,
                    t.observations,
                    t.payed
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<MoneyDeliveryOrderResponse>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<MoneyDeliveryOrderResponse>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<MoneyDeliveryOrderResponse | undefined> {
        const url = Config.get("FIND_MONEY_REMITTANCE_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const moneyRemittance = new MoneyDeliveryOrderResponse(
                    t.id,
                    t.orderNumber,
                    t.agencyId,
                    t.agencyName,
                    t.clientId,
                    t.clientName,
                    t.clientPhone ? new Phone(t.clientPhone.number, t.clientPhone.countryCode, t.clientPhone.phoneType) : null,
                    t.contactId,
                    t.contactName,
                    t.contactPhone ? new Phone(t.contactPhone.number, t.contactPhone.countryCode, t.contactPhone.phoneType) : null,
                    t.userId,
                    t.userName,
                    t.deliveryPersonId,
                    t.deliveryPersonName,
                    t.serviceFee,
                    t.totalToPay,
                    t.serviceCost,
                    t.totalToReceive,
                    t.conversionRate,
                    t.currencyToReceive,
                    t.status,
                    t.createdAt,
                    t.isBankCard,
                    t.address,
                    t.geo,
                    t.contactBankCardNumber,
                    t.contactBankCardBank,
                    t.observations,
                    t.payed
                );
                return moneyRemittance;
            }
        }
        return undefined;
    }

    export async function create(moneyRemittance: MoneyDeliveryOrderRequest): Promise<Response<any>> {
        const url = Config.get("CREATE_MONEY_REMITTANCE_URL");
        const request = new Request(url, moneyRemittance);
        return await RestClient.post(request);
    }

    export async function getMoneyDeliveryBudget(currency: Currency, totalToSend?: number, totalToReceive?: number, totalToPay?: number, agencyId?:string): Promise<Response<MoneyDeliveryBudgetResponse>> {
        const url = Config.get("GET_MONEY_DELIVERY_BUDGET", {totalToSend, currency, totalToReceive, totalToPay, agencyId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const data = response.data;
            if (data) {
                const moneyRemittanceBudget = new MoneyDeliveryBudgetResponse(
                    data.currencyToReceive,
                    data.amountToSend,
                    data.totalToPay,
                    data.amountToReceive,
                    data.totalFees,
                    data.conversionRate);
                return new Response<MoneyDeliveryBudgetResponse>(true, moneyRemittanceBudget, response.error);
            }
        }
        return new Response<MoneyDeliveryBudgetResponse>(true, undefined, response.error, response.status);
    }

    export async function getMoneyDeliveryComments(id: string): Promise<Response<DeliveryOrderCommentResponse[]>> {
        const url = Config.get("GET_PRODUCT_ORDER_COMMENTS_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const comments = response.data;
            if (comments) {
                const commentsREsult = comments.map((t: DeliveryOrderCommentResponse) => new DeliveryOrderCommentResponse(
                    t.id,
                    t.userId,
                    t.userName,
                    t.comment,
                    t.createdAt
                ))
                return new Response<DeliveryOrderCommentResponse[]>(true, commentsREsult, response.error);
            }
        }
        return new Response<DeliveryOrderCommentResponse[]>(true, undefined, response.error, response.status);
    }

    export async function getPossiblyRepeated(
        clientId: string,
        contactId: string,
        currency: string,
        amountToReceive: string,
        contactBankCardId?: string,
    ): Promise<Response<MoneyDeliveryOrderResponse[]>> {
        const url = Config.get("GET_POSSIBLY_REPEATED_MONEY_REMITTANCE_URL", {
            clientId,
            contactId,
            currency,
            amountToReceive,
            contactBankCardId
        });
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const resultData = response.data;
            if (resultData) {
                const resData = resultData.map((t: any) => new MoneyDeliveryOrderResponse(
                    t.id,
                    t.orderNumber,
                    t.agencyId,
                    t.agencyName,
                    t.clientId,
                    t.clientName,
                    t.clientPhone ? new Phone(t.clientPhone.number, t.clientPhone.countryCode, t.clientPhone.phoneType) : null,
                    t.contactId,
                    t.contactName,
                    t.contactPhone ? new Phone(t.contactPhone.number, t.contactPhone.countryCode, t.contactPhone.phoneType) : null,
                    t.userId,
                    t.userName,
                    t.deliveryPersonId,
                    t.deliveryPersonName,
                    t.serviceFee,
                    t.totalToPay,
                    t.serviceCost,
                    t.totalToReceive,
                    t.conversionRate,
                    t.currencyToReceive,
                    t.status,
                    t.createdAt,
                    t.isBankCard,
                    t.address,
                    t.geo,
                    t.contactBankCardNumber,
                    t.contactBankCardBank,
                    t.observations,
                    t.payed
                ));
                return new Response<MoneyDeliveryOrderResponse[]>(true, resData, response.error);
            }
        }
        return new Response<MoneyDeliveryOrderResponse[]>(false, undefined, response.error, response.status);
    }

    export async function updateClientPerson(moneyRemittanceId: string, contactId: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_REMITTANCE_CONTACT_URL", {
            moneyRemitanteId: moneyRemittanceId,
            contactId: contactId
        });
        const request = new Request(url, {});
        return await RestClient.put(request);
    }

    export async function setDeliveryPerson(moneyDeliveryIds: string[], deliveryPersonId: string): Promise<Response<any>> {
        const url = Config.get("SET_MONEY_REMITTANCE_DELIVERY_PERSON_URL", {
            deliveryPersonId: deliveryPersonId
        });
        const request = new Request(url, moneyDeliveryIds);
        return await RestClient.put(request);
    }

    export async function unsetDeliveryPerson(moneyRemittanceId: string): Promise<Response<any>> {
        const url = Config.get("UNSET_MONEY_REMITTANCE_DELIVERY_PERSON_URL", {
            moneyRemitanteId: moneyRemittanceId
        });
        const request = new Request(url, {});
        return await RestClient.put(request);
    }

    export async function updateStatus(moneyDeliveryId: string, status: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_DELIVERY_ORDER_STATUS_URL", {
            moneyDeliveryId: moneyDeliveryId,
            status: status
        });
        const request = new Request(url, { comment: comment ? comment : '' });
        return await RestClient.put(request);
    }

    export async function updateBulkStatus(ordersIds: string[], status: string, comment: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_DELIVERY_ORDERS_STATUS_URL", {
            status: status
        });
        const request = new Request(url, { ordersIds, comment: comment ? comment : '' });
        return await RestClient.put(request);
    }

    export async function updateValues(moneyDeliveryId: string, values: MoneyDeliveryValuesRequest): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_DELIVERY_ORDER_VALUES_URL", {
            moneyDeliveryId: moneyDeliveryId
        });
        const request = new Request(url, values);
        return await RestClient.put(request);
    }

    export async function remove(moneyDeliveryId: string): Promise<Response<any>> {
        const url = Config.get("REMOVE_MONEY_DELIVERY_ORDER_URL", {id: moneyDeliveryId});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}