import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ReferenceItemPriceRowMedium from "./ReferenceItemPriceRowMedium";
import ReferenceItemPriceRowProperties from "./ReferenceItemPriceRowProperties";
import ReferenceItemPriceRowSmall from "./ReferenceItemPriceRowSmall";

const ReferenceItemPriceRow: React.FC<ReferenceItemPriceRowProperties> = ({
                                                                            referenceItemPriceResponse,
                                                                            className,
                                                                            filter,
                                                                            onUpdate,
}) => {
  return (
    <>
      <ViewSmall>
        <ReferenceItemPriceRowSmall
          referenceItemPriceResponse={referenceItemPriceResponse}
          className={className}
          filter={filter}
          onUpdate={onUpdate}
        />
      </ViewSmall>
      <ViewMedium>
        <ReferenceItemPriceRowMedium
          referenceItemPriceResponse={referenceItemPriceResponse}
          className={className}
          filter={filter}
          onUpdate={onUpdate}
        />
      </ViewMedium>
      <ViewLarge>
        <ReferenceItemPriceRowMedium
          referenceItemPriceResponse={referenceItemPriceResponse}
          className={className}
          filter={filter}
          onUpdate={onUpdate}
        />
      </ViewLarge>
    </>
  );
};
export default ReferenceItemPriceRow;
