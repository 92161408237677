import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    main_container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    content_container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    outlet: {
        padding: '16px',
        position: 'relative',
        flexGrow: 1
    }
}));

export {useStyles}