import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        minWidth: '500px',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    input: {
        marginTop: '16px'
    },
    custom_button_container:{
        display: 'flex'
    },
    btn_container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        width: '100%'
    },
    cancel_btn: {
        marginTop: '16px',
        marginLeft: '10px'
    },
    selector:{
        marginTop: '16px',
        marginLeft: '10px'
    }
}));

export {useStyles}