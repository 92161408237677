import React from 'react';
import TabPanelProperties from "./TabPanelProperties";
import {useStyles} from "./Tab.style";


const TabPanel: React.FC<TabPanelProperties> = ({
                                                    children,
                                                    index,
                                                    value
                                                }: TabPanelProperties) => {
    const classes = useStyles();

    if (value !== index) return <></>

    return (
        <div className={classes.tab_content}>
            {children}
        </div>
    )
}
export default TabPanel;