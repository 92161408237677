import Currency from "../../entities/Currency";
import Geo from "../../entities/Geo";
import Phone from "../../entities/Phone";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";

class MoneyDeliveryOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public agencyName: string;
    public clientName: string;
    public clientPhone: Phone | null;
    public contactId: string;
    public contactName: string;
    public contactPhone: Phone | null;
    public userId: string;
    public userName: string;
    public deliveryPersonId: string | null;
    public deliveryPersonName: string | null;
    public serviceFee: number;
    public totalToPay: number;
    public serviceCost: number;
    public totalToReceive: number;
    public currencyToReceive: Currency;
    public conversionRate: number;
    public createdAt: number;
    public status: ProductOrderStatus;
    public isBankCard: boolean;
    public address: string;
    public geo: Geo;
    public contactBankCardNumber: string;
    public contactBankCardBank: string;
    public observations: string;
    public payed?: boolean;

    constructor(id: string, orderNumber: string, agencyId: string, agencyName: string, clientId: string,
            clientName: string, clientPhone: Phone | null, contactId: string, contactName: string, contactPhone: Phone | null,
            userId: string, userName: string, deliveryPersonId: string, deliveryPersonName: string,
            serviceFee: number, totalToPay: number, serviceCost: number, totalToReceive: number,
            conversionRate: number, currencyToReceive: Currency, status: ProductOrderStatus, createdAt: number, isBankCard: boolean, 
            address: string, geo: Geo,
            contactBankCardNumber: string, contactBankCardBank: string,
            observations: string,
            payed?: boolean) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.agencyName = agencyName;
        this.clientId = clientId;
        this.clientName = clientName;
        this.clientPhone = clientPhone;
        this.contactId = contactId;
        this.contactName = contactName;
        this.contactPhone = contactPhone;
        this.userId = userId;
        this.userName = userName;
        this.deliveryPersonId = deliveryPersonId;
        this.contactName = contactName;
        this.deliveryPersonId = deliveryPersonId;
        this.deliveryPersonName = deliveryPersonName;
        this.serviceFee = serviceFee;
        this.totalToPay = totalToPay;
        this.serviceFee = serviceFee;
        this.serviceCost = serviceCost;
        this.totalToReceive = totalToReceive;
        this.conversionRate = conversionRate;
        this.currencyToReceive = currencyToReceive;
        this.status = status;
        this.createdAt = createdAt;
        this.isBankCard = isBankCard;
        this.address = address;
        this.geo = geo;
        this.contactBankCardNumber = contactBankCardNumber;
        this.contactBankCardBank = contactBankCardBank;
        this.observations = observations;
        this.payed = payed;
    }

}

export default MoneyDeliveryOrderResponse;