import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";

class UpdateDeliveryPersonPasswordButtonStore {

    public password?: string;
    public error?: string;
    public agencyId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public isValid: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, agencyId: string) {
        this.agencyId = agencyId;
        this.isModalOpen = false;
        this.isValid = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async cleanModal() {
        this.password = undefined;
        this.error = undefined;
    }

    @action
    public async save(): Promise<boolean> {
        if (this.password) {
            this.loading = true;
            const response = await DeliveryPersonService.updatePassword(this.agencyId, this.password);
            this.commonStore.processErrors(response);
            this.error = response.success ? undefined : "No fue posible actualizar la contraseña";
            this.loading = false;
            return response.success;
        }
        return false;
    }
}

export default UpdateDeliveryPersonPasswordButtonStore;