import React, {useEffect, useState} from 'react';
import {useStyles} from "./Textarea.style";
import {useCommonStyles} from "../common/CommonStyles.style";
import TextareaProperties from "./TextareaProperties";
import clsx from "clsx";


const Textarea: React.FC<TextareaProperties> = ({
                                                    error,
                                                    label,
                                                    value = '',
                                                    placeholder = '',
                                                    helpText,
                                                    onChange,
                                                    onBlur,
                                                    disabled = false,
                                                    startIcon: StartIcon,
                                                    endIcon: EndIcon,
                                                    fullWidth = false,
                                                    className = '',
                                                    lines = 1,
                                                    onKeyPress = () => {
                                                    }
                                                }: TextareaProperties) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [currentValue, setCurrentValue] = useState(value);

    const containerClasses = clsx(
        classes.text_input_container,
        className,
        fullWidth && commonClasses.fullWidth,
        error && classes.input_container_error);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCurrentValue(e.target.value);
        onChange && onChange(e);
    }

    const onBlurEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onBlur && onBlur(e);
    }

    return (
        <div className={containerClasses}>
            <div className={classes.label}>{label}</div>
            <div className={classes.input_container}>
                {StartIcon &&
                <div className={classes.start_icon_container}>
                    <StartIcon/>
                </div>}
                <textarea
                    rows={lines}
                    value={currentValue}
                    placeholder={placeholder}
                    className={classes.input}
                    onKeyPress={(event => onKeyPress(event.key))}
                    onChange={onValueChange}
                    onBlur={onBlurEvent}
                    disabled={disabled}
                />
                {EndIcon &&
                <div className={classes.end_icon_container}>
                    <EndIcon/>
                </div>}
            </div>
            <div className={clsx(classes.text_hint, error && classes.error)}>{error || helpText}</div>
        </div>
    )
}
export default Textarea;