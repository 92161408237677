import InventoryItemPriceSectionProperties from "./InventoryItemPriceSectionProperties";
import React from "react";
import {useStyles} from "./InventoryItemPriceSection.style";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import {Observer} from "mobx-react-lite";
import clsx from "clsx";
import PriceInput from "../../Inputs/PriceInput/PricetInput";

const InventoryItemPriceSection:React.FC<InventoryItemPriceSectionProperties> =({
                                                                                    price,
                                                                                    onPriceChange,
                                                                                    disable,
                                                                                    fullWidth=false,
                                                                                    className='',
                                                                                  })=>{

    const classes = useStyles()
    const commonClasses = useCommonStyles()
    const i18n = useI18nContext()

    return (
        <Observer>
            {() => (
                <div className={clsx(fullWidth && commonClasses.fullWidth)}>
                    <div className={clsx(classes.elements_container)}>
                        <PriceInput
                            label={i18n.translate("INVENTORY_ITEM_DEFAULT_PRICE")}
                            value={price}
                            onChange={onPriceChange}/>
                    </div>
                </div>
            )}
        </Observer>
    )
}
export default InventoryItemPriceSection