import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        btn_container: {
            backgroundColor: theme.palette.grey[50],
            flexGrow: 1,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            boxSizing: 'border-box',
            alignItems: 'center',
            color: theme.palette.grey[500],
            "&:hover": {
                backgroundColor: theme.palette.grey[300],
            },
            "& > div:first-child": {
                fontSize: 20
            },
            "& > div svg": {
                fontSize: 100
            }
        },
        select_client_btn: {
            borderRadius: '8px 0 0 8px',
            borderLeft: `20px solid ${theme.palette.secondary.light}`,
            borderRight: `3px solid ${theme.palette.secondary.light}`
        },
        search_container: {
            backgroundColor: theme.palette.grey[50],
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            boxSizing: 'border-box',
            "@media (max-width: 800px)": {
                maxWidth: '100%',
                maxHeight: '50%'
            }
        },
        maxWidth50Percent: {
            maxWidth: '50%',
        },
        search_client_container: {
            border: `1px solid ${theme.palette.secondary.light}30`,
            borderRight: `3px solid ${theme.palette.secondary.light}`,
            borderRadius: '8px 0 0 8px',
        },
        search_input_container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '-webkit-fill-available',
            boxSizing: 'border-box',
            height: '40px',
            background: theme.palette.common.white,
            borderRadius: '8px 0 0 0',
            borderBottom: `1px solid ${theme.palette.secondary.light}30`,
        },
        btn: {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[100],
            width: '40px',
            lineHeight: '5px',
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            },
            "& > span": {
                fontSize: '9px',
                fontWeight: 800
            }
        },
        add_btn: {
            borderRadius: '8px 0 0 0',
            "& > svg": {
                color: theme.palette.primary.dark
            }
        },
        remove_btn: {
            "& > svg": {
                color: theme.palette.error.light
            }
        },
        search_input: {
            padding: "8px",
            flexGrow: 1,
            "& > input": {
                width: '100%',
                border: 'none',
                color: theme.palette.grey[400],
                fontSize: '16px',
                "&:focus": {
                    outline: 'none'
                }
            }
        },
        elements_container: {
            display: 'flex',
            flexDirection: "column",
            width: '100%',
            overflowY: 'scroll'
        },
        options_body: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        loading: {
            color: theme.palette.primary.light
        },
        empty_container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey[400],
            fontSize: "20px",
            height: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            textAlign: 'center',
            "& > div:last-child": {
                fontSize: "12px",
            }
        },
        header_container: {
            textAlign: 'center',
            width: '100%'
        },
        name_icon_container: {
            margin: '0 auto',
            paddingBottom: '10px'
        },
        row_container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '97%',
            marginTop: '15px',
            paddingBottom: '10px',
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            color: theme.palette.grey[800],
            "& > span": {
                marginTop: '10px',
                fontSize: 18,
                color: theme.palette.secondary.light
            },
            "& > div": {
                width: '100%'
            },
        },
        row_container_left: {
            marginTop: '7px',
        },
        row_container_right: {
            display: 'flex',
            "& > div": {
                marginTop: '7px',
                marginLeft: '5px',
            },
        },
        value_container: {
            display: 'flex',
            marginTop: '4px',
            flexDirection: 'row',
            alignItems: 'center',
            "& > svg": {
                fontSize: 18,
                color: theme.palette.primary.dark,
                marginRight: '4px'
            },
            "& > span": {
                fontSize: 14,
                color: theme.palette.secondary.light
            }
        },
        btn_selected_container: {
            backgroundColor: `${theme.palette.grey[50]}`,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            alignItems: 'center',
            color: theme.palette.grey[900],
            lineHeight: '18px',
            justifyContent: 'space-between',
            "@media (max-width: 800px)": {
                maxWidth: '100%',
                maxHeight: '50%'
            }
        },
        info_container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'flex-start',
            width: '100%',
            marginLeft: '25px',
            position: 'relative'
        },
        selected_client_btn: {
            borderRadius: '8px 0 0 8px',
            borderRight: `3px solid ${theme.palette.secondary.light}`,
            "@media (max-width: 800px)": {
                borderRight: 'none',
                borderBottom: `5px solid ${theme.palette.secondary.light}`,
                borderRadius: '0'
            }
        },
        buttons_container: {
            display: 'flex',
            width: '100%',
            padding: '16px 24px',
            boxSizing: 'border-box',
            justifyContent: 'space-between'
        },
        tool_btn: {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            color: theme.palette.secondary.light,
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '5px',
            "&:hover": {
                color: theme.palette.primary.main,
            },
            "& > span": {
                fontSize: '12px',
                fontWeight: 800
            },
            "& > svg": {
                fontSize: '14px',
                fontWeight: 800
            }
        },
        difficult_person_indicator_container: {
            padding: '18px 32px',
            position: 'absolute',
            right: '14px'
        },
        hide_small: {
            "@media (max-width: 800px)": {
                display: 'none'
            }
        }
    }))
;

export {useStyles}