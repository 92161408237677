import React from 'react';
import ShowErrorProperties from "./ShowErrorProperties";
import {useStyles} from "./ShowError.style";
import ErrorIcon from '@material-ui/icons/Error';
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";

const ShowError: React.FC<ShowErrorProperties> = ({
                                                      error,
                                                      className = '',
                                                  }) => {

    const classes = useStyles();
    const i18n = useI18nContext();
    
    if (!error) {
        return <></>;
    }
    return (
        <div className={clsx(classes.show_error_container, className)}>
            <div className={classes.label_container}>
                <ErrorIcon className={classes.icon}/>
                <div className={classes.label}>{i18n.translate("ERROR")}</div>
            </div>

            <div className={classes.content}>
                <div className={classes.value_container}>
                    {error !== 'null' ? error : ''}
                </div>
            </div>

        </div>
    )
}


export default ShowError;