import ViewLarge from "../../../../ui_components/MediaView/ViewLarge";
import ViewMedium from "../../../../ui_components/MediaView/ViewMedium";
import ViewSmall from "../../../../ui_components/MediaView/ViewSmall";
import DeliveryPersonInfoRowMedium from "./DeliveryPersonInfoRowMedium";
import DeliveryPersonInfoRowProperties from "./DeliveryPersonInfoRowProperties";
import DeliveryPersonInfoRowSmall from "./DeliveryPersonInfoRowSmall";


const DeliveryPersonInfoRow: React.FC<DeliveryPersonInfoRowProperties> = ({ 
                                                                              deliveryPersonsInfo, 
                                                                              onReceivedDelivery, 
                                                                              onDeliveredDelivery,
                                                                              onRemoveDeliveryPersonInfo, 
                                                                              onCommissionSave,
                                                                              onUpdate,
                                                                              filter,
}) => {
  return (
    <>
      <ViewSmall>
        <DeliveryPersonInfoRowSmall
          deliveryPersonsInfo={deliveryPersonsInfo} 
          onReceivedDelivery={onReceivedDelivery}
          onDeliveredDelivery={onDeliveredDelivery}
          onRemoveDeliveryPersonInfo={onRemoveDeliveryPersonInfo}
          onCommissionSave={onCommissionSave}
          onUpdate={onUpdate}
          filter={filter}
        />
      </ViewSmall>
      <ViewMedium>
        <DeliveryPersonInfoRowMedium
          deliveryPersonsInfo={deliveryPersonsInfo}
          onReceivedDelivery={onReceivedDelivery}
          onDeliveredDelivery={onDeliveredDelivery}
          onRemoveDeliveryPersonInfo={onRemoveDeliveryPersonInfo}
          onCommissionSave={onCommissionSave}
          onUpdate={onUpdate}
          filter={filter}
        />
      </ViewMedium>
      <ViewLarge>
        <DeliveryPersonInfoRowMedium
          deliveryPersonsInfo={deliveryPersonsInfo}
          onReceivedDelivery={onReceivedDelivery}
          onDeliveredDelivery={onDeliveredDelivery}
          onRemoveDeliveryPersonInfo={onRemoveDeliveryPersonInfo}
          onCommissionSave={onCommissionSave}
          onUpdate={onUpdate}
          filter={filter}
        />
      </ViewLarge>
    </>
  );
};
export default DeliveryPersonInfoRow;
