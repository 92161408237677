import React from 'react';
import IconProperties from "./IconProperties";


const WireTransferIcon: React.FC<IconProperties> = ({
                                                       className,
                                                       fontSize
                                                   }) => {
    return (
        <svg className={className} viewBox="0 0 496 496" x="0px" y="0px" width="1em" height="1em"
             style={{fontSize: fontSize}}>
            <g>
                <g>
                    <g>
                        <path d="M208,120c0-13.232,10.768-24,24-24h40V80h-40c-22.056,0-40,17.944-40,40h-9.888L0,211.056V256h16v32h16v128H16v32H0v48
				h400v-48h-16v-32h-16V288h16v-32h16v-44.944L217.888,120H208z M32,432h48v16H32V432z M128,288v128h-16v32H96v-32H80V288h16v-32
				h16v32H128z M224,288v128h-16v32h-16v-32h-16V288h16v-32h16v32H224z M320,288v128h-16v32h-16v-32h-16V288h16v-32h16v32H320z
				 M368,432v16h-48v-16H368z M336,416V288h16v128H336z M272,272h-48v-16h48V272z M256,288v128h-16V288H256z M272,432v16h-48v-16
				H272z M176,272h-48v-16h48V272z M160,288v128h-16V288H160z M176,432v16h-48v-16H176z M80,272H32v-16h48V272z M64,288v128H48V288
				H64z M384,464v16H16v-16H384z M368,272h-48v-16h48V272z M384,240h-80h-16h-80h-16h-80H96H16v-19.056L185.888,136H192v56h16v-56
				h6.112L384,220.944V240z"/>
                        <rect x="64" y="208" width="272" height="16"/>
                        <path d="M408,0c-48.52,0-88,39.48-88,88s39.48,88,88,88c48.52,0,88-39.48,88-88S456.52,0,408,0z M408,160
				c-39.704,0-72-32.296-72-72s32.296-72,72-72c39.704,0,72,32.296,72,72S447.704,160,408,160z"/>
                        <path d="M400,64h16c4.416,0,8,3.584,8,8h16c0-13.232-10.768-24-24-24V32h-16v16c-13.232,0-24,10.768-24,24s10.768,24,24,24h16
				c4.416,0,8,3.584,8,8s-3.584,8-8,8h-16c-4.416,0-8-3.584-8-8h-16c0,13.232,10.768,24,24,24v16h16v-16c13.232,0,24-10.768,24-24
				s-10.768-24-24-24h-16c-4.416,0-8-3.584-8-8S395.584,64,400,64z"/>
                        <rect x="288" y="80" width="16" height="16"/>
                        <path d="M448,312c0,13.232-10.768,24-24,24h-8v16h8c22.056,0,40-17.944,40-40V184h-16V312z"/>
                        <rect x="384" y="336" width="16" height="16"/>
                    </g>
                </g>
            </g>

        </svg>
    )
}
export default WireTransferIcon;