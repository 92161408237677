import React from "react";
import ShowClientToContactInfoProperties from "./ShowClientToContactInfoProperties";
import {useStyles} from "./ShowClientToContactInfo.style";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import clsx from "clsx";
import ProfileImagePlaceholder from "../../../ui_components/Icon/ProfileImagePlaceholder";
import EmailIcon from "@mui/icons-material/Email";
import {format} from "../../../util/PhoneFormatter";

const ShowClientToContactInfo: React.FC<ShowClientToContactInfoProperties> = ({
                                                                                  client,
                                                                                  contact
                                                                              }) => {
    const classes = useStyles();


    return (
        <div className={classes.show_client_contact_container}>
            {client &&
            <div className={clsx(classes.card, classes.contact_container)}>
                <span className={classes.hide_small}>
                    <ProfileImagePlaceholder
                        fontSize={40}
                        name={client?.name}/>
                </span>
                <div className={classes.data_container}>
                    <div className={classes.name}>
                        {client.name}
                    </div>
                    {client.email && <div className={classes.value}>
                        <EmailIcon/>
                        {client.email}
                    </div>}
                    {client.phone && <div className={classes.value}>
                        <ContactPhoneIcon/>
                        {format(client.phone)}
                    </div>
                    }
                </div>
                <div>

                </div>
            </div>}
            {contact && <div className={clsx(classes.card, classes.contact_container)}>
                <span className={classes.hide_small}>
                    <ProfileImagePlaceholder
                        fontSize={40}
                        name={contact?.name}/>
                </span>
                <div className={classes.data_container}>
                    <div className={classes.name}>
                        {contact.name}
                    </div>
                    {contact.mobilePhone && <div className={classes.value}>
                        <ContactPhoneIcon/>
                        {format(contact.mobilePhone)}
                    </div>}
                    {contact.geo && <div className={classes.value}>
                        <LocationOnIcon/>
                        {contact.geo.name}
                    </div>}
                    {contact.address && <div className={classes.value}>
                        <HomeIcon/>
                        {contact.address}
                    </div>}
                </div>
            </div>}
        </div>
    );
};

export default ShowClientToContactInfo;
