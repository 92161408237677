import React from 'react';
import DeliveryPersonRowProperties from "./DeliveryPersonRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import DeliveryPersonRowMedium from "./DeliveryPersonRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import DeliveryPersonRowSmall from "./DeliveryPersonRowSmall";


const DeliveryPersonRow: React.FC<DeliveryPersonRowProperties> = ({
                                                                      person,
                                                      onUpdate
                                                  }) => {
    return (
        <>
            <ViewSmall>
                <DeliveryPersonRowSmall onUpdate={onUpdate} person={person}/>
            </ViewSmall>
            <ViewMedium>
                <DeliveryPersonRowMedium onUpdate={onUpdate} person={person} />
            </ViewMedium>
            <ViewLarge>
                <DeliveryPersonRowMedium onUpdate={onUpdate} person={person} />
            </ViewLarge>
        </>
    )
}

export default DeliveryPersonRow;
