import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ServiceName from '../../../entities/ServiceName';
import ShowService from '../../../shared_components/Info/ShowService/ShowService';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import SalePriceTableMiscellanyRowProperties from './SalePriceTableMiscellanyRowProperties';
import ShowEditMiscellanySalePrice from '../../../shared_components/InfoEditables/ShowEditMiscellanySalePrice/ShowEditMiscellanySalePrice';


const SalePriceTableMiscellanyRow: React.FC<SalePriceTableMiscellanyRowProperties> = ({
    miscellanyCategories, salePrices, onUpdate
                                                  }) => {

    const onUpdateValue = () => {
        onUpdate();
    }

    return (
        <>
        <TableRow hover role="checkbox" tabIndex={-1} key={'row.id'}>
            <TableCell key={`column.id`} align={'right'}>
                <ShowService service={ServiceName.MISCELLANY}/>
            </TableCell>
        </TableRow>
        {miscellanyCategories.map((miscellanyCategory: any) => {
            return (
                <TableRow>
                    <TableCell key={miscellanyCategory.id} align={'left'}>
                        <ShowText text={miscellanyCategory.name}/>
                    </TableCell>
                    {salePrices.map((price) => {
                        const miscellanySalePrice = price.miscellanySalePrices ? price.miscellanySalePrices.find(t => t.miscellanyCategoryId === miscellanyCategory.id) : null;
                        return (
                        <TableCell key={price.id} align={'left'}>
                            <ShowEditMiscellanySalePrice
                                value={miscellanySalePrice ? miscellanySalePrice.salePrice : 0}
                                salePriceId={price.id}
                                miscellanyCategoryId={miscellanyCategory.id}
                                onSave={onUpdateValue}/>
                        </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
        </>
    )
}

export default SalePriceTableMiscellanyRow;
