import React from 'react';
import {useStyles} from "./HighlightText.style";
import HighlightTextProperties from "./HighlightTextProperties";
import Highlighter from "react-highlight-words";


const HighlightText: React.FC<HighlightTextProperties> = ({
                                                              className = '',
                                                              text,
                                                              textToHighlight
                                                          }) => {
    const classes = useStyles();
    return (
        <Highlighter
            highlightClassName={classes.highlight}
            className={className}
            searchWords={textToHighlight}
            autoEscape={true}
            textToHighlight={text}
        />
    )
}
export default HighlightText;