import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
        delivery_person_contact_container: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
            minHeight: '300px'
        },
        btn_container: {
            backgroundColor: theme.palette.grey[200],
            width: '300px',
            height: '200px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            boxSizing: 'border-box',
            alignItems: 'center',
            color: theme.palette.grey[500],
            "&:hover": {
                backgroundColor: theme.palette.grey[300],
            },
            "& > div:first-child": {
                fontSize: 20
            },
            "& > div svg": {
                fontSize: 100
            }
        },
        select_deliveryPerson_btn: {
            borderRadius: '8px 0 0 8px',
            borderLeft: `20px solid ${theme.palette.secondary.light}`,
            borderRight: `3px solid ${theme.palette.secondary.light}`
        },
        select_contact_btn: {
            borderRadius: '0 8px 8px 0',
            borderRight: `20px solid ${theme.palette.secondary.light}`
        },
        search_container: {
            backgroundColor: theme.palette.grey[50],
            width: '300px',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            boxSizing: 'border-box',
        },
        search_deliveryPerson_container: {
            border: `1px solid ${theme.palette.secondary.light}30`,
            borderRadius: '8px 0 0 8px',
        },
        search_input_container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '-webkit-fill-available',
            boxSizing: 'border-box',
            height: '40px',
            background: theme.palette.common.white,
            borderRadius: '8px 0 0 0',
            borderBottom: `1px solid ${theme.palette.secondary.light}30`,
        },
        btn: {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[100],
            width: '40px',
            lineHeight: '5px',
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            },
            "& > span": {
                fontSize: '9px',
                fontWeight: 800
            }
        },
        add_btn: {
            borderRadius: '8px 0 0 0',
            "& > svg": {
                color: theme.palette.primary.dark
            }
        },
        remove_btn: {
            "& > svg": {
                color: theme.palette.error.light
            }
        },
        search_input: {
            padding: "8px",
            flexGrow: 1,
            "& > input": {
                width: '100%',
                border: 'none',
                color: theme.palette.grey[400],
                fontSize: '16px',
                "&:focus": {
                    outline: 'none'
                }
            }
        },
        elements_container: {
            display: 'flex',
            flexDirection: "column",
            width: '100%',
            overflowY: 'scroll'
        },
        options_body: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        loading: {
            color: theme.palette.primary.light
        },
        empty_container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey[400],
            fontSize: "20px",
            height: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            textAlign: 'center',
            "& > div:last-child": {
                fontSize: "12px",
            }
        },
        name_container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            "& > svg": {
                fontSize: 50,
                color: theme.palette.primary.dark
            },
            "& > span": {
                fontSize: 14,
                color: theme.palette.secondary.light
            }
        },
        value_container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            "& > svg": {
                fontSize: 18,
                color: theme.palette.primary.dark,
                marginRight: '4px'
            },
            "& > span": {
                fontSize: 14,
                color: theme.palette.secondary.light
            }
        },
        btn_selected_container: {
            backgroundColor: theme.palette.grey[50],
            width: '300px',
            height: '200px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            boxSizing: 'border-box',
            alignItems: 'center',
            color: theme.palette.grey[500],
            lineHeight: '10px',
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            }
        },
        selected_deliveryPerson_btn: {
            borderRadius: '8px 0 0 8px',
            borderLeft: `20px solid ${theme.palette.primary.dark}`,
            borderRight: `3px solid ${theme.palette.secondary.light}`
        },
    }))
;

export {useStyles}