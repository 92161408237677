import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import EditMiscellanyCategoryPriceStore from "./EditMiscellanyCategoryPriceStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./EditMiscellanyCategoryPriceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import EditMiscellanyCategoryPriceButtonProperties from './EditMiscellanyCategoryPriceButtonProperties';
import PriceInput from '../../Inputs/PriceInput/PricetInput';
import { useUserContext } from '../../../contexts/UserContext';


const EditMiscellanyCategoryPriceButton: React.FC<EditMiscellanyCategoryPriceButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  miscellanyCategoryId,
                                                                  miscellanyCategoryName,
                                                                  price,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new EditMiscellanyCategoryPriceStore(commonContext, miscellanyCategoryId, miscellanyCategoryName, price);

    if (userStore.isRootAgency()) {
        return <></>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onPriceChange = (newPrice: number) => store.price = newPrice;
    const onMiscellanyCategorySave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? 'Editar precio' : ""}
                text={showText ? 'Editar precio' : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? 'Editar precio' : ""}
            className={className}
            tooltip={!showText ? 'Editar precio' : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={`Editar precio de ${miscellanyCategoryName}`} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <div className={classes.price_container}>
                            <PriceInput label={'Precio'} fullWidth className={classes.input}
                                value={store.price}
                                onChange={onPriceChange}/>
                        </div>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onMiscellanyCategorySave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default EditMiscellanyCategoryPriceButton;
