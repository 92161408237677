import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Article from "../../../entities/Article";
import {ArticleService} from "../../../service/articleDelivery/ArticleService";
import {ArticlePriceService} from "../../../service/articleDelivery/ArticlePriceService";
import Pagination from "../../../entities/Pagination";
import ArticlePrice from "../../../entities/ArticlePrice";

const ERROR_MAP = {
    phone: ["CLIENT_PHONE_ALREADY_EXIST", "INVALID_PHONE_NUMBER"],
    name: ["CLIENT_NAME_REQUIRED", "CLIENT_NAME_ALREADY_EXIST"],
}

class ArticlePriceStore {

    public articleId: string;
    public article: Article;
    public isModalOpen: boolean;
    public loading: boolean;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;
    public pagination: Pagination;
    public filter?: string;
    public articlePrices: ArticlePrice[];

    public articlePrice: ArticlePrice;
    public amountError?: string;


    constructor(commonStore: CommonStore, articleId?: string) {
        this.articleId = articleId ?? '';
        this.isModalOpen = false;
        this.article = {} as Article;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 15, 0, 0);
        this.articlePrices = [];
        this.articlePrice = {} as ArticlePrice;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.article?.name);
    }

    @action
    public async loadArticle() {
        const response = await ArticleService.find(this.articleId!)
        if (response.success && response.data) {
            this.article = response.data;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async getPrices(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await ArticlePriceService.get(page, pageSize, this.articleId, this.filter);
        if (response.success) {
            runInAction(() => {
                this.articlePrices = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        this.loading = true;
        await this.loadArticle();
        await this.search();
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.articlePrices.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getPrices(page, pageSize);
    }

    @action
    public async search() {
        await this.getPrices(1);
    }

    processError(error?: string) {
        this.phoneError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }
    }
}

export default ArticlePriceStore;