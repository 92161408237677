import Pagination from "../../entities/Pagination";
import InventoryItem from "../../model/packageDelivery/inventoryItem/InventoryItem";
import CommonStore from "../../stores/CommonStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {InventoryItemService} from "../../service/packageDelivery/InventoryItem/InventoryItemService";
import { CustomCategoryService } from "../../service/CustomCategoryService";
import CustomCategory from "../../model/packageDelivery/customCategory/CustomCategory";


class InventoryItemStore {

    public inventoryItems: InventoryItem[]
    public customCategories: CustomCategory[]
    public selectedCustomCategory?:CustomCategory
    public pagination: Pagination
    public filter?:string
    public commonStore:CommonStore

    constructor(commonStore:CommonStore) {
        this.pagination = new Pagination(1,15,0,0)
        this.inventoryItems = []
        this.customCategories = []
        this.commonStore = commonStore
        makeObservable(this,{
            inventoryItems:observable,
            customCategories:observable,
            selectedCustomCategory:observable,
            pagination:observable,
            filter:observable,
            isEmpty:computed
        });
    }

    get isEmpty():boolean {
        return this.inventoryItems.length ===0;
    }


    public async setFilter(filter:string){
        runInAction(()=>{
            this.filter = filter.toUpperCase()
        })
        await this.getElements(1)
    }
    public async setCategoryFilter(categoryId?:string){
        runInAction(()=>{
            this.selectedCustomCategory = this.customCategories.find(t => t.id === categoryId)
        })
        await this.getElements(1)
    }
    @action
    public async search(){
        await this.getElements(1)
    }

    @action
    public async cleanSearch(){
        runInAction(()=>{
            this.filter = ""
            this.selectedCustomCategory = undefined
        })
        await this.getElements(1)
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize)
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await InventoryItemService.get(page, pageSize, this.filter, this.selectedCustomCategory?.id);
        if (response.success) {
            runInAction(() => {
                this.inventoryItems = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async getCategories(): Promise<void> {
        this.commonStore.setLoading();
        const response = await CustomCategoryService.get();
        if (response.success) {
            runInAction(() => {
                this.customCategories = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }
}
export default InventoryItemStore