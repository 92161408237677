import {action, makeAutoObservable} from "mobx";
import Promotion from "../../../entities/Promotion";
import CommonStore from "../../../stores/CommonStore";
import {PromotionService} from "../../../service/PromotionService";


class RemovePromotionStore {

    public promotion: Promotion;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, promotion: Promotion) {
        this.promotion = promotion;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await PromotionService.remove(this.promotion);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemovePromotionStore;