import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Article from "../../../entities/Article";
import {ArticleService} from "../../../service/articleDelivery/ArticleService";

const ERROR_MAP = {
    name: ["ERROR_00_400_33"],
}

class NewArticleStore {

    public articleId?: string;
    public article: Article;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public nameError?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, articleId?: string) {
        this.isEditing = !!articleId;
        this.articleId = articleId;
        this.isModalOpen = false;
        this.article = {} as Article;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.article?.name);
    }

    @action
    public async loadFoodPackage() {
        if (this.articleId) {
            this.isEditing = true;
            const response = await ArticleService.find(this.articleId!)
            if (response.success && response.data) {
                this.article = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadFoodPackage();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? ArticleService.update : ArticleService.create;
        const response = await serviceToCall(this.article);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default NewArticleStore;