import Currency from "../../entities/Currency";

class MoneyDeliveryBudgetResponse {

    public currencyToReceive: Currency;
    public amountToSend: number;
    public totalToPay: number;
    public amountToReceive: number;
    public totalFees: number;
    public conversionRate: number;

    constructor(currencyToReceive: Currency, amountToSend: number, totalToPay: number, amountToReceive: number,
                totalFees: number, conversionRate: number) {
        this.currencyToReceive = currencyToReceive;
        this.amountToSend = amountToSend;
        this.totalToPay = totalToPay;
        this.amountToReceive = amountToReceive;
        this.totalFees = totalFees;
        this.conversionRate = conversionRate;
    }

}

export default MoneyDeliveryBudgetResponse;