import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Agency from "../entities/Agency";
import Seller from "../entities/Seller";
import AgencyResponse from "../model/agency/AgencyResponse";

export module AgencyService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string, includeChildren: boolean = false, includeCurrent: boolean = false): Promise<Response<PagedResponse<Agency>>> {
        const url = Config.get("GET_AGENCY_URL", {page, pageSize, filter, includeChildren, includeCurrent});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const clientsData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (clientsData && paginationData) {
                const data = clientsData.map((t: any) => new AgencyResponse(t.id, t.name, t.createdAt, t.email, t.phone, t.address, t.salePriceId, t.salePriceName));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<AgencyResponse>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<AgencyResponse>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<Agency>> {
        const url = Config.get("FIND_AGENCY_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const agencyData = response.data;
            if (agencyData) {
                const client = new AgencyResponse(agencyData.id, agencyData.name, agencyData.createdAt, agencyData.email, agencyData.phone, agencyData.address, agencyData.salePriceId, agencyData.salePriceName);
                return new Response<AgencyResponse>(true, client, response.error);
            }
        }
        return new Response<AgencyResponse>(true, undefined, response.error, response.status);
    }

    export async function create(agency: Agency, seller: Seller): Promise<Response<any>> {
        const url = Config.get("CREATE_AGENCY_URL");
        const request = new Request(url, {
            name: agency.name,
            user: seller,
            email: agency.email,
            phone: agency.phone,
            address: agency.address,
            salePriceId: agency.salePriceId
        });
        return await RestClient.post(request);
    }

    export async function update(agency: Agency): Promise<Response<any>> {
        const url = Config.get("UPDATE_AGENCY_URL", {id: agency.id});
        const request = new Request(url, {
            name: agency.name,
            email: agency.email,
            phone: agency.phone,
            address: agency.address,
            salePriceId: agency.salePriceId
        });
        return await RestClient.put(request);
    }

    export async function setSalePrice(agencyId: string, salePriceId: string): Promise<Response<any>> {
        const url = Config.get("SET_AGENCY_SALE_PRICE_URL", {agencyId: agencyId, salePriceId: salePriceId});
        const request = new Request(url, {});
        return await RestClient.put(request);
    }

    export async function remove(agency: Agency): Promise<Response<any>> {
        const url = Config.get("REMOVE_AGENCY_URL", {id: agency.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function updateMainUserPassword(agencyId: string, password: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_AGENCY_MAIN_USER_PASSWORD_URL", {id: agencyId});
        const request = new Request(url, {password});
        return await RestClient.put(request);
    }
}