import React from 'react';
import ShowDetailsOptionProperties from "./ShowDetailsOptionProperties";
import {useI18nContext} from "../../../../../contexts/I18nContext";
import MenuItem from '@mui/material/MenuItem';
import { Observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useStyles } from './ShowDetailsOption.style';

const ShowDetailsOption: React.FC<ShowDetailsOptionProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                salePriceName,
                                                                                salePriceDescription = '',
                                                                              }) => {


    const i18n = useI18nContext();
    const classes: any = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        onClick();
    }

    const handleClick = () => {
        setOpen(true);
    };

    return <Observer>
        {() => (
            <>
                <MenuItem onClick={handleClick}><TextSnippetIcon/> {i18n.translate("DETAILS")}</MenuItem>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.box_text_container}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {salePriceName}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {salePriceDescription}
                        </Typography>
                    </Box>
                </Modal>
            </>
        )}
    </Observer>
}

export default ShowDetailsOption;
