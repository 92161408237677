import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    history_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        alignItems: 'center'
    },
    history_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container: {},
    margin_left_4: {
        marginLeft: '4px'
    },
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start',
        height: '150px',
        justifyContent: 'space-between'
    },
    btn_container: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    box: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: 4,
        padding: 2,
        height: 400,
        width: 500,
        marginTop: 8
    },
    with_scroll: {
        overflowY: "auto"
    }
}));

export {useStyles}