import {action, makeAutoObservable, observable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import {AgencyService} from "../../../service/AgencyService";
import Agency from "../../../entities/Agency";
import {ProductOrderToPrintService} from "../../../service/ProductOrderToPrintService";
import OrderToPrint from "../../../model/orderToPrint/OrderToPrint";

class InvoiceToPrintStore {

    public productOrderIds: string[];

    public deliveryOrdersToPrint: OrderToPrint[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    @observable agency: Agency;

    @observable agencies : Agency[] = [];


    constructor(commonStore: CommonStore, productOrderIds: string[]) {
        this.productOrderIds = [...productOrderIds];
        this.deliveryOrdersToPrint = [];
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
        this.agency = {} as Agency;
    }

    @action
    public async loadProductOrderToPrintInvoice() {
        this.loading = true;
        this.deliveryOrdersToPrint = [];
        for (const productOrderId of this.productOrderIds) {
            const response = await ProductOrderToPrintService.find(productOrderId!);
            this.commonStore.processErrors(response);
            if (response.success && response.data) {
                runInAction(() => {
                    if (response.data instanceof OrderToPrint) {
                        this.deliveryOrdersToPrint.push(response.data);
                    }
                })

            }
        }
        this.loading = false;
    }

    @action
    public async loadAgencyData() {
        this.loading = true;
        const response = await AgencyService.get(1, 1000, '', true, true)
        if (response.success && response.data) {
            this.setAgencies(response.data.data)
        }
        this.commonStore.processErrors(response);
        this.loading = false;

    }

    @action setAgencies(newAgencies: Agency[]) {
        this.agencies = newAgencies;
    }

    @action getAgency(agencyId: string) {
        for (const item of this.agencies) {
            if (item.id === agencyId){
                this.agency = item
            }
        }
    }
    @action
    public async openModal() {
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default InvoiceToPrintStore;

