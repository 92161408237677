import React from 'react';
import {useStyles} from "./ShowSellerBasicInfo.style";
import ShowSellerBasicInfoProperties from "./ShowSellerBasicInfoProperties";
import clsx from "clsx";
import ShowPhoneNumber from "../ShowPhoneNumber/ShowPhoneNumber";


const ShowSellerBasicInfo: React.FC<ShowSellerBasicInfoProperties> = ({
                                                                          autofill = false,
                                                                          className = '',
                                                                          name,
                                                                          lastName,
                                                                          email,
                                                                          phone,
                                                                          light = false
                                                                      }) => {
    const classes = useStyles(light);
    const firstLetter = name ? name[0].toUpperCase() : '';
    const colors = ["#5ac18e", "#008080", "#daa520", "#f6546a", "#cbcba9",
        "#0e2f44", "#0a75ad", "#fa8072", "#c39797", "#468499"];

    const getBackgroundColor = () => {
        const index = firstLetter.charCodeAt(0);
        if (autofill && index) return colors[index % colors.length];
        return '';
    }
    return (
        <div className={clsx(classes.seller_info_container, className)}>
            <div className={classes.seller_logo} style={{
                backgroundColor: getBackgroundColor()
            }}>{firstLetter}</div>
            <div className={classes.info_container}>
                <div className={classes.name}>{name} {lastName}</div>
                <div>{email}</div>
                <ShowPhoneNumber value={phone}/>
                {/*<div>{format(phone)}</div>*/}
            </div>
        </div>
    )
}
export default ShowSellerBasicInfo;