
class Role {
    public readonly id: string;
    public readonly name: string;
    public readonly value: BigInt;
    public readonly description?: string;

    constructor(id: string, name: string, value: BigInt,description?: string) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.description = description;
    }
}

export default Role;