import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import SalePrice from "../entities/SalePrice";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Currency from "../entities/Currency";

export module SalePriceService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string): Promise<Response<PagedResponse<SalePrice>>> {
        const url = Config.get("GET_SALE_PRICE_URL", {page, pageSize, filter});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const salePricesData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (salePricesData && paginationData) {
                const data = salePricesData.map((t: any) => 
                    new SalePrice(
                        t.id,
                        t.name,
                        t.description,
                        t.articleSalePrices,
                        t.moneySalePrices,
                        t.topupSalePrices,
                        t.packageReferenceItemSalePrices,
                        t.miscellanySalePrices,
                        t.status
                    ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<SalePrice>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<SalePrice>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<SalePrice>> {
        const url = Config.get("FIND_SALE_PRICE_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const salePriceData = response.data;
            if (salePriceData) {
                const salePrice = new SalePrice(
                    salePriceData.id,
                    salePriceData.name,
                    salePriceData.description,
                    salePriceData.articleSalePrices,
                    salePriceData.moneySalePrices,
                    salePriceData.topupSalePrices,
                    salePriceData.referenceItemSalePrice,
                    salePriceData.miscellanySalePrices,
                    salePriceData.status
                );
                return new Response<SalePrice>(true, salePrice, response.error);
            }
        }
        return new Response<SalePrice>(true, undefined, response.error, response.status);
    }

    export async function getSalePriceFromAgency(): Promise<Response<SalePrice>> {
        const url = Config.get("GET_SALE_PRICE_FROM_AGENCY__URL");
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const salePriceData = response.data;
            if (salePriceData) {
                const salePrice =
                    new SalePrice(
                        salePriceData.id,
                        salePriceData.name,
                        salePriceData.description,
                        salePriceData.articleSalePrices,
                        salePriceData.moneySalePrices,
                        salePriceData.topupSalePrices,
                        salePriceData.packageReferenceItemSalePrices,
                        salePriceData.miscellanySalePrices,
                        salePriceData.status
                    );
                return new Response<SalePrice>(true, salePrice, response.error);
            }
        }
        return new Response<SalePrice>(true, undefined, response.error, response.status);
    }

    export async function create(salePrice: SalePrice): Promise<Response<any>> {
        const url = Config.get("CREATE_SALE_PRICE_URL");
        const request = new Request(url, salePrice);
        return await RestClient.post(request);
    }

    export async function update(salePrice: SalePrice): Promise<Response<any>> {
        const url = Config.get("UPDATE_SALE_PRICE_URL", {id: salePrice.id});
        const request = new Request(url, salePrice);
        return await RestClient.put(request);
    }

    export async function setActive(salePrice: SalePrice): Promise<Response<any>> {
        const url = Config.get("SET_SALE_PRICE_ACTIVE_URL", {id: salePrice.id});
        const request = new Request(url);
        return await RestClient.get(request);
    }

    export async function setInactive(salePrice: SalePrice): Promise<Response<any>> {
        const url = Config.get("SET_SALE_PRICE_INACTIVE_URL", {id: salePrice.id});
        const request = new Request(url);
        return await RestClient.get(request);
    }

    export async function remove(salePriceId: string): Promise<Response<any>> {
        const url = Config.get("REMOVE_SALE_PRICE_URL", {id: salePriceId});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function updateArticleSalePrice(salePriceId: string, articleId: string, salePrice: number): Promise<Response<any>> {
        const url = Config.get("UPDATE_ARTICLE_SALE_PRICE_URL", { id: salePriceId, articleId });
        const request = new Request(url, {salePrice});
        return await RestClient.put(request);
    }

    export async function updateMoneySalePrice(salePriceId: string, serviceFeeId: string, salePrice: number, isPercentage: boolean, currency: Currency): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_SALE_PRICE_URL", {id: salePriceId, serviceFeeId: serviceFeeId});
        const request = new Request(url, {salePrice, isPercentage, currency});
        return await RestClient.put(request);
    }

    export async function updateTopupSalePrice(salePriceId: string, productId: string, salePrice: number): Promise<Response<any>> {
        const url = Config.get("UPDATE_TOPUP_SALE_PRICE_URL", {id: salePriceId, productId: productId});
        const request = new Request(url, {salePrice});
        return await RestClient.put(request);
    }

    export async function updateReferenceItemSalePrice(salePriceId: string, referenceItemId: string, salePrice: number): Promise<Response<any>> {
        const url = Config.get("UPDATE_REFERENCE_ITEM_SALE_PRICE_URL", {id: salePriceId, referenceItemId: referenceItemId});
        const request = new Request(url, {salePrice});
        return await RestClient.put(request);
    }

    export async function updateMiscellanySalePrice(salePriceId: string, miscellanyCategoryId: string, salePrice: number): Promise<Response<any>> {
        const url = Config.get("UPDATE_MISCELLANY_SALE_PRICE_URL", { id: salePriceId, miscellanyCategoryId });
        const request = new Request(url, {salePrice});
        return await RestClient.put(request);
    }

}