import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        minWidth: '300px',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '16px',
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    input: {
        marginTop: '16px'
    }
}));

export {useStyles}