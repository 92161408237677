import React from 'react';
import {useStyles} from "./ShowPaginationSmall.style";
import ShowPaginationProperties from "../ShowPaginationProperties";
import clsx from "clsx";
import {useI18nContext} from "../../../contexts/I18nContext";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';


const PageComponentsMedium: React.FC<ShowPaginationProperties> = ({
                                                                      pagination,
                                                                      onChange
                                                                  }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    const goToPage = (page: number) => {
        if (page > pagination.pageCount || page <= 0) return;
        if (page === pagination.currentPage) return;
        onChange(page, pagination.pageSize);
    }

    return (
        <div className={classes.page_elements}>
            <div onClick={() => goToPage(1)}
                 className={clsx(classes.page_button, pagination.currentPage === 1 && classes.disabled)}>
                <FirstPageIcon/>
                <div>{i18n.translate("FIRST")}</div>
            </div>
            <div onClick={() => goToPage(pagination.currentPage - 1)}
                 className={clsx(classes.page_button, pagination.currentPage === 1 && classes.disabled)}>
                <ChevronLeftIcon/>
                <div>{i18n.translate("PREVIOUS")}</div>
            </div>
            <div className={classes.current_page}>{`${pagination.currentPage} / ${pagination.pageCount}`}</div>
            <div
                onClick={() => goToPage(pagination.currentPage + 1)}
                className={clsx(classes.page_button, pagination.currentPage === pagination.pageCount && classes.disabled)}>
                <div>{i18n.translate("NEXT")}</div>
                <ChevronRightIcon/>
            </div>
            <div
                onClick={() => goToPage(pagination.pageCount)}
                className={clsx(classes.page_button, pagination.currentPage === pagination.pageCount && classes.disabled)}>
                <div>{i18n.translate("LAST")}</div>
                <LastPageIcon/>
            </div>
        </div>
    )
}
export default PageComponentsMedium;