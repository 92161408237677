import CategoryInfo from "./CategoryInfo";

class InventoryItem {

    public id: string ;
    public agencyId: string;
    public name: string;
    public categoryInfo: CategoryInfo;
    public price:number
    public comments: string;
    public status: string;
    public createdAt: number;
    constructor(id:string,agencyId:string,  name:string, categoryInfo:CategoryInfo, price:number, comments:string, status:string, createdAt:number) {
        this.id = id;
        this.agencyId = agencyId;
        this.name = name;
        this.categoryInfo = categoryInfo;
        this.price = price;
        this.comments = comments;
        this.status = status;
        this.createdAt = createdAt
    }
}
export default InventoryItem
