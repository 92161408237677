import React from 'react';
import ShowIntervalWithTitleProperties from "./ShowIntervalWithTitleProperties";
import {useStyles} from "../ShowText/ShowText.style";


const ShowIntervalWithTitle: React.FC<ShowIntervalWithTitleProperties> = ({
                                                                              title,
                                                                              from,
                                                                              to
}) => {
    const classes = useStyles();

    return (
        <div>
            <>
                {title && <div className={classes.title}>{`${title}:`}</div>}
                <div>{from} - {to}</div>
            </>
        </div>
    )
}
export default ShowIntervalWithTitle;