import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryCost from "../../../entities/DeliveryCost";
import {DeliveryCostService} from "../../../service/DeliveryCostService";


class RemoveDeliveryCostStore {

    public deliveryCost: DeliveryCost;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, deliveryCost: DeliveryCost) {
        this.deliveryCost = deliveryCost;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await DeliveryCostService.remove(this.deliveryCost.id, this.deliveryCost.deliveryPersonId);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveDeliveryCostStore;