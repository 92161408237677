import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    notifications_badge: {
        color: '#ffffff',
        cursor: 'pointer',
        marginRight: '10px'
    },
    notifications_badge_back_color: {
        backgroundColor: '#DE350B'
    },
    notification_area_header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 24px'
    },
    notification_area_content: {
        
    },
    notifications_ul: {
        listStyle: 'none',
        margin: '0px',
        padding: '0px',
        position: 'relative'
    },
    notifications_li: {
        display: 'grid',
        gridTemplateColumns: '38px auto 34px',
        position: 'relative',
        textDecoration: 'none',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'left',
        padding: '8px 25px 8px 16px',
        borderBottom: '1px solid rgb(242, 244, 247)',
        backgroundClip: 'padding-box',
        alignItems: 'flex-start',
        "&:hover": {
            backgroundColor: 'rgba(17, 25, 39, 0.04)'
        }
    },
    new_notifications_li: {
        backgroundColor: '#019d8c33'
    },
    li_icon_content: {
        minWidth: '56px',
        flexShrink: 0,
        marginTop: '4px'
    },
    li_text_content: {
        minWidth: '270px'
    },
    li_text_content_action: {
        cursor: 'pointer'
    },
    li_text_type: {
        fontSize: '15px'
    },
    date_label: {

    },
    header_text: {
        fontSize: '19px',
        fontWeight: 700
    },
    no_notifications_container: {
        padding: '20px',
        minWidth: '300px',
        textAlign: 'center'
    },
    secondary_text_container: {
        padding: '3px',
        borderRadius: '2px',
        margin: '3px 0',
        border: '1px solid rgb(242, 244, 247)'
    },
}));

export {useStyles}
