import {action, makeAutoObservable} from "mobx";
import Client from "../../../entities/Client";
import CommonStore from "../../../stores/CommonStore";
import {ClientService} from "../../../service/ClientService";


class RemoveClientStore {

    public client: Client;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, client: Client) {
        this.client = client;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }


    @action
    public async remove() {
        const response = await ClientService.remove(this.client);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveClientStore;