export module Filter {
    export function save<T>(name: string, value: T) {
        // eslint-disable-next-line no-restricted-globals
        const filterName = `${location.pathname}-${name}`;
        sessionStorage.setItem(filterName, JSON.stringify(value));
    }

    export function get<T>(name: string, defaultValue: T | undefined): T | undefined {
        // eslint-disable-next-line no-restricted-globals
        const filterName = `${location.pathname}-${name}`;
        try {
            const storedValue = sessionStorage.getItem(filterName);
            if (!storedValue) return defaultValue;
            const parsed = JSON.parse(storedValue);
            return hydrate<T>(parsed);
        } catch (e) {
            return defaultValue;
        }
    }

    function hydrate<T>(data: T): T {
       return data as T;
    }
}