import React from "react";

class SelectOption {
    public primaryText: string;
    public secondaryText?: string;
    public value: string;
    public icon?: React.ComponentType;

    constructor(primaryText: string, value: string, secondaryText?: string, icon?: React.ComponentType) {
        this.primaryText = primaryText;
        this.value = value;
        this.icon = icon;
        this.secondaryText = secondaryText;
    }
}

export default SelectOption;