import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import {CustomCategoryWeightRangeService} from "../../../service/packageDelivery/customCategory/CustomCategoryWeightRangeService";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"]
}
class RemoveCustomCategoryWeightRangeStore {

    public weightRangeId: string;
    public customCategoryId:string
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, weightRangeId: string,customCategoryId:string) {
        this.weightRangeId = weightRangeId;
        this.customCategoryId = customCategoryId
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await CustomCategoryWeightRangeService.remove(this.weightRangeId,this.customCategoryId);
        this.commonStore.processErrors(response);
        this.processError(response.error)
        return response.success;
    }

    processError(error?: string) {
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error) //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
        }
    }
}

export default RemoveCustomCategoryWeightRangeStore;