import {useStyles} from './SearchContactPanel.style';
import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";
import {format} from "../../../../../util/PhoneFormatter";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {Observer} from "mobx-react-lite";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Loading from "../../../../../ui_components/PageLoading/Loading";
import SearchContactPanelProperties from "./SearchContactPanelProperties";
import {useI18nContext} from "../../../../../contexts/I18nContext";
import ClientContact from "../../../../../entities/ClientContact";
import ContactElementRow from "./row/ContactElementRow";
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from "@mui/icons-material/Edit";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import NewClientContactButton from "../../../../Buttons/NewClientContactButton/NewClientContactButton";
import ProfileImagePlaceholder from "../../../../../ui_components/Icon/ProfileImagePlaceholder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {useCommonContext} from "../../../../../contexts/CommonContext";
import ContactSelectStore from "./ContactSelectStore";
import ShowDifficultPersonIndicator from '../../../../Info/ShowDifficultPersonIndicator/ShowDifficultPersonIndicator';
import ObservationsInput from '../../../../Inputs/ObservationsInput/ObservationsInput';


const SelectContactPanel: React.FC<SearchContactPanelProperties> = ({
                                                                        clientId,
                                                                        selectedContact,
                                                                        selectedObservations,
                                                                        onContactChange,
                                                                        onObservationsChange,
                                                                        agencyId,
                                                                        showObservationsInput
                                                                    }) => {
    const i18n = useI18nContext();
    const classes = useStyles();
    const commonContext = useCommonContext();
    const store = useMemo<ContactSelectStore>(() => new ContactSelectStore(commonContext, clientId, selectedContact, selectedObservations, agencyId), [commonContext, clientId, selectedContact, selectedObservations, agencyId]);

    const inputRef = createRef<HTMLInputElement>();
    const [filter, setFilter] = useState("");

    const openContactSearch = () => {
        store.openSearchPanel();
    }

    const closeClientSearch = () => {
        store.closeSearchPanel();
        setFilter("");
    }

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFilter(value);
    }

    const selectContact = (contact: ClientContact) => {
        store.setSelectedContact(contact);
        onContactChange(contact);
    }

    const onNewContact = async (contact: ClientContact) => {
        store.setSelectedContact(contact);
        onContactChange(contact);
    }

    const onUpdateContact = async (contact: ClientContact) => {
        store.setSelectedContact(contact);
    }

    const onCurrentObservationsChange = (newObservations: string) => {
        onObservationsChange && onObservationsChange(newObservations);
    }

    useEffect(() => {
        store.search()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    useEffect(() => {
        if (store.showSearchPanel) {
            inputRef.current?.focus();
        }
    }, [store.showSearchPanel, inputRef])

    let timeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        const changeFilter = () => {
            store.setFilter(filter);
        };
        timeout.current = setTimeout(changeFilter, 800);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const ContactInfo = () => {
        if (!store.clientId) {
            return (
                <div className={clsx(classes.btn_container, classes.no_client_container)}>
                    <div>{i18n.translate("NO_CLIENT_SELECT_PLACEHOLDER")}</div>
                    <div>{i18n.translate("NO_CLIENT_SELECT_HINT")}</div>
                </div>
            )
        }

        if (store.selectedContact) {
            return <Observer>
                {() => (
                    <div className={clsx(classes.btn_selected_container, classes.selected_contact_btn)}>
                        <div className={classes.buttons_container}>
                            <NewClientContactButton clientId={store.clientId || ""}
                                                    contactId={store.selectedContact?.id}
                                                    agencyId={agencyId}
                                                    customButton={<div className={clsx(classes.tool_btn)}>
                                                        <EditIcon/>
                                                        <span>{i18n.translate("EDIT_CONTACT")}</span>
                                                    </div>}
                                                    onSave={onUpdateContact}
                            />
                            <div className={clsx(classes.tool_btn)} onClick={openContactSearch}>
                                <ManageSearchIcon/>
                                <span>Buscar otro destinatario</span>
                            </div>
                        </div>
                        <div className={classes.info_container}>
                            <div className={classes.header_container}>
                                <h2>Destinatario</h2>
                            </div>
                            <div className={clsx(classes.name_icon_container,classes.hide_small)}>
                                <ProfileImagePlaceholder
                                    fontSize={40}
                                    name={store.selectedContact?.name}/>
                            </div>
                            <div className={classes.row_container}>
                                <div>
                                    <strong>{i18n.translate("NAME")}:</strong>
                                </div>
                                <div>
                                    <strong><span>{store.selectedContact?.name + ' ' + store.selectedContact?.lastName}</span></strong>
                                </div>
                            </div>

                            {store.selectedContact?.documentId &&
                                <div className={classes.row_container}>
                                    <div className={classes.row_container_left}>
                                        <strong>Carnet de identidad:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <div><strong><span>{store.selectedContact?.documentId}</span></strong></div>
                                    </div>
                                </div>}

                            {store.selectedContact?.mobilePhone &&
                                <div className={classes.row_container}>
                                    <div className={classes.row_container_left}>
                                        <strong>{i18n.translate("MOBILE_PHONE")}:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <ContactPhoneIcon/>
                                        <div><strong><span>{format(store.selectedContact.mobilePhone)}</span></strong>
                                        </div>
                                    </div>
                                </div>}

                            {store.selectedContact?.secondMobilePhone &&
                                <div className={classes.row_container}>
                                    <div className={classes.row_container_left}>
                                        <strong>{i18n.translate("SECOND_MOBILE_PHONE")}:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <ContactPhoneIcon/>
                                        <div>
                                            <strong><span>{format(store.selectedContact.secondMobilePhone)}</span></strong>
                                        </div>
                                    </div>
                                </div>}

                            {store.selectedContact?.homePhone &&
                                <div className={classes.row_container}>
                                    <div className={classes.row_container_left}>
                                        <strong>{i18n.translate("HOME_PHONE")}:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <ContactPhoneIcon/>
                                        <div><strong><span>{format(store.selectedContact?.homePhone)}</span></strong>
                                        </div>
                                    </div>
                                </div>}

                            {store.selectedContact?.address &&
                                <div className={classes.row_container}>
                                    <div className={classes.row_container_left}>
                                        <strong>{i18n.translate("ADDRESS")}:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <HomeIcon/>
                                        <div>
                                            <strong><span>{store.selectedContact?.address}</span><span>{store.selectedContact?.neighborhood ? '. ' + store.selectedContact?.neighborhood : ''}</span></strong>
                                        </div>
                                    </div>
                                </div>}

                            {store.selectedContact?.geo &&
                                <div className={classes.row_container}>
                                    <div className={classes.row_container_left}>
                                        <strong>{i18n.translate("LOCALIZATION")}:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <LocationOnIcon/>
                                        <div>
                                            <strong><span>{store.selectedContact?.geo.name}. {store.selectedContact?.geo.parentInfo}</span></strong>
                                        </div>
                                    </div>
                                </div>}

                            {store.selectedContact?.observation &&
                                <div className={classes.row_container}>
                                    <div>
                                        <strong>Observación:</strong>
                                    </div>
                                    <div className={classes.row_container_right}>
                                        <div><strong><span>{store.selectedContact?.observation}</span></strong></div>
                                    </div>
                                </div>}

                            {store.selectedContact?.difficultPerson &&
                                <div className={classes.difficult_person_indicator_container}>
                                    <ShowDifficultPersonIndicator
                                        difficultPerson={store.selectedContact?.difficultPerson}/>
                                </div>}

                            {store.selectedContact && showObservationsInput &&
                                <ObservationsInput
                                    className={classes.observations_container}
                                    value={store.observations}
                                    onChange={onCurrentObservationsChange}/>}
                        </div>
                    </div>
                )}
            </Observer>
        }

        return (
            <div onClick={openContactSearch}
                 className={clsx(classes.btn_container, classes.select_contact_btn)}>
                <div>{i18n.translate("SELECT_CONTACT_PLACEHOLDER")}</div>
                <div><ContactPageIcon/></div>
            </div>
        )
    }

    const SearchContact = () => (
        <Observer>
            {() => (
                <div className={clsx(classes.search_container, classes.search_contact_container)}>
                    <div className={classes.search_input_container}>
                        <div className={clsx(classes.btn, classes.add_btn)}>
                            <NewClientContactButton
                                clientId={store.clientId || ""}
                                agencyId={agencyId}
                                customButton={<div className={clsx(classes.btn, classes.add_btn)}>
                                    <AddBoxIcon/>
                                    <span>{i18n.translate("NEW")}</span>
                                </div>}
                                onSave={onNewContact}
                            />
                        </div>
                        <div className={classes.search_input}>
                            <input
                                value={filter}
                                placeholder={i18n.translate("CLIENT_CONTACT_FILTER_PLACEHOLDER")}
                                ref={inputRef}
                                onChange={onFilterChange}/>
                        </div>
                        <div onClick={closeClientSearch}
                             className={clsx(classes.btn, classes.remove_btn)}>
                            <HighlightOffIcon/>
                            <span>{i18n.translate("CLOSE")}</span>
                        </div>
                    </div>
                    {store.loading &&
                        <div className={classes.options_body}>
                            <Loading className={classes.loading} fontSize={45}/>
                        </div>}

                    {!store.loading && <div className={classes.elements_container}>
                        {store.contacts.map((e, i) => (
                            <ContactElementRow
                                key={i}
                                element={e}
                                selected={e.id === store.selectedContact?.id}
                                onSelect={selectContact}
                            />
                        ))}
                    </div>}

                    {!store.loading && store.isEmpty &&
                        <div className={classes.empty_container}>
                            <div>{i18n.translate("NO_CONTACTS_TITLE")}</div>
                            <div>{i18n.translate("NO_CONTACTS_SUBTITLE")}</div>
                        </div>}
                </div>
            )}
        </Observer>
    )


    return <Observer>
        {() => (
            <>
                {!store.showSearchPanel && <ContactInfo/>}
                {store.showSearchPanel && <SearchContact/>}
            </>
        )}
    </Observer>
}


export default SelectContactPanel;