import React from 'react';
import ClientContactButtonProperties from "./ClientContactButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ClientContactStore from "./ClientContactStore";
import ContactsIcon from '@material-ui/icons/Contacts';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ClientContactButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import FilterInput from "../../Inputs/FilterInput/FilterInput";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import NewClientContactButton from "../NewClientContactButton/NewClientContactButton";
import ShowPagination from "../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import ShowPhoneNumber from "../../Info/ShowPhoneNumber/ShowPhoneNumber";
import ShowGeo from "../../Info/ShowGeo/ShowGeo";
import ContactBankCardButton from '../ContactBankCardButton/ContactBankCardButton';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import useOnEnterPress from "../../../hooks/EnterPress";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ShowDifficultPersonIndicator from '../../Info/ShowDifficultPersonIndicator/ShowDifficultPersonIndicator';
import RemoveContactButton from '../RemoveContactButton/RemoveContactButton';


const ClientContactButton: React.FC<ClientContactButtonProperties> = ({
                                                                          onClick = () => {
                                                                          },
                                                                          onSave = () => {
                                                                          },
                                                                          showIcon = true,
                                                                          showText = true,
                                                                          disabled,
                                                                          className = '',
                                                                          clientId,
                                                                          agencyId,
                                                                          rounded = false,
                                                                          size = ButtonSize.DEFAULT,
                                                                          type = ButtonType.DEFAULT
                                                                      }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ClientContactStore(commonContext, clientId, agencyId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }
    const onContactSave = async () => {
        await store.search();
    }

    const text = i18n.translate("CLIENT_CONTACTS")
    const Icon = ContactsIcon;
    const onContactSearch = () => {
        store.search();
    }
    useOnEnterPress(onContactSearch, []);
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <Box
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.SPACE_BETWEEN}>
                            <ShowSellerBasicInfo name={store.client.name}
                                                 email={store.client.email}
                                                 phone={store.client.phone} autofill/>
                            <NewClientContactButton
                                type={ButtonType.SECONDARY}
                                rounded
                                onSave={onContactSave}
                                clientId={store.client.id}/>
                        </Box>
                        <div className={classes.body}>
                            <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                                 justifyContent={Alignment.SPACE_BETWEEN}>
                                <FilterInput value={store.filter}
                                             placeholder={i18n.translate("CLIENT_CONTACT_FILTER_PLACEHOLDER")}
                                             onChange={(value) => {
                                                 store.setFilter(value)
                                             }} onEnter={() => store.search()}/>
                                <Button
                                    onClick={() => {
                                        store.search()
                                    }}
                                    className={classes.search_btn}
                                    text={i18n.translate("SEARCH")}
                                />
                            </Box>
                            {!store.isEmpty &&
                                <div className={classes.contacts_table}>
                                    <ShowPagination onChange={onPaginationChange}
                                                    elementLabel={i18n.translate("CONTACTS")}
                                                    pagination={store.pagination}/>
                                    <div className={classes.elements_container}>
                                        {store.contacts.map((contact, i) => (
                                            <div className={classes.contact_info_container}>
                                                <ShowSellerBasicInfo autofill name={contact.name} lastName={contact.lastName}/>
                                                <ShowPhoneNumber label={i18n.translate("MOBILE_PHONE")}
                                                                 value={contact.mobilePhone}/>
                                                <ShowPhoneNumber label={i18n.translate("HOME_PHONE")}
                                                                 value={contact.homePhone}/>
                                                <ShowGeo geo={contact.geo} showParentInfo={true}/>
                                                <ShowDifficultPersonIndicator difficultPerson={contact.difficultPerson}/>
                                                <ExpandoMenu>
                                                    <>
                                                        <NewClientContactButton type={ButtonType.SECONDARY}
                                                            size={ButtonSize.SMALL} showText
                                                            contactId={contact.id}
                                                            onSave={onContactSave}
                                                            clientId={store.client.id}/>
                                                        <ContactBankCardButton type={ButtonType.SECONDARY}
                                                            size={ButtonSize.SMALL} showText
                                                            clientId={store.client.id}
                                                            contactId={contact.id}
                                                            onSave={onContactSave}/>
                                                        <RemoveContactButton size={ButtonSize.SMALL} onSave={onContactSave}
                                                            clientId={clientId}
                                                            contact={contact} showText
                                                            type={ButtonType.SECONDARY}/>
                                                    </>
                                                </ExpandoMenu>
                                            </div>
                                        ))}
                                    </div>
                                    <ShowPagination
                                        onChange={onPaginationChange}
                                        elementLabel={i18n.translate("CONTACTS")}
                                        pagination={store.pagination}/>
                                </div>
                            }
                            {store.isEmpty &&
                                <MessageCard icon={ContactsIcon} title={i18n.translate("NO_CONTACTS_TITLE")}
                                             subtitle={i18n.translate("NO_CONTACTS_SUBTITLE")}/>}
                        </div>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ClientContactButton;
