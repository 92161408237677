import CommonStore from "../../../stores/CommonStore";
import {action, makeObservable, observable} from "mobx";
import {SalesService} from "../../../service/SalesService";
import ArticleSalesResponse from "../../../model/sales/productOrder/ArticleSalesResponse";

class ArticleSalesStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public from: number;
    public to: number;
    public sellerId?: string;
    public agencyId?: string;
    public sales?: ArticleSalesResponse

    constructor(
        commonStore: CommonStore,
        from: number,
        to: number,
        sellerId?: string,
        agencyId?: string,
    ) {
        this.commonStore = commonStore;
        this.loading = true;
        this.from = from;
        this.sellerId = sellerId;
        this.to = to;
        this.agencyId = agencyId;
        makeObservable(this, {
            loading: observable,
            from: observable,
            to: observable,
            sales: observable,
        });
    }

    @action
    public async getElements(): Promise<void> {
        this.loading = true;
        const response = await SalesService.getArticleSales(this.from, this.to, this.agencyId, this.sellerId);
        if (response.success) {
            this.sales = response.data;
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public isEmpty(): boolean {
        return !this.sales || this.sales.orders.length === 0;
    }
}

export default ArticleSalesStore;