import {makeStyles} from "@material-ui/core/styles";

const useCommonStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    fitContent: {
        width: 'fit-content'
    },
    flex: {
        display: 'flex'
    },
    flexColumn: {
        flexDirection: 'column'
    },
    flexRow: {
        flexDirection: 'row'
    },
    selfAlignCenter: {
        AlignSelf: 'center'
    },
    selfAlignStart: {
        AlignSelf: 'flex-start'
    },
    selfAlignEnd: {
        AlignSelf: 'flex-end'
    },
    justifyContentCenter: {
        justifyContent: 'center'
    },
    justifyContentStart: {
        justifyContent: 'flex-start'
    },
    justifyContentEnd: {
        justifyContent: 'flex-end'
    },
    justifyContentSpaceBetween: {
        justifyContent: 'space-between'
    },
    justifyContentSpaceAround: {
        justifyContent: 'space-around'
    },
    alignItemsCenter: {
        alignItems: 'center'
    },
    alignItemsStart: {
        alignItems: 'flex-start'
    },
    alignItemsEnd: {
        alignItems: 'flex-end'
    },
    marginTop: {
        marginTop: '8px'
    }
}));

export {useCommonStyles}