import clsx from "clsx";

import ReferenceItemPriceRowProperties from "./ReferenceItemPriceSelectRowProperties";
import { useStyles } from "../ReferenceItemPriceSelect.style";
import { useI18nContext } from "../../../../contexts/I18nContext";
import { useUserContext } from "../../../../contexts/UserContext";
import Access from "../../../Access/AllowAccess/Access";
import ExpandoMenu from "../../../../ui_components/ExpandoMenu/ExpandoMenu";
import ShowText from "../../../Info/ShowText/ShowText";

const ReferenceItemPriceSelectRowSmall: React.FC<ReferenceItemPriceRowProperties> = ({
  referenceItemPriceResponse,
  className,
  filter,
}) => {
  const classes = useStyles();
  const i18n = useI18nContext();
  const userStore = useUserContext();

  return (
    <div className={clsx(classes.reference_items_info_container)}>
      <ShowText
        title={i18n.translate("NAME")}
        text={referenceItemPriceResponse.referenceItemName}
        textToHighlight={filter}
      />
      {(userStore.hasAccess(Access.CREATE_EDIT_REFERENCE_ITEM_PRICE) ||
        userStore.hasAccess(Access.REMOVE_REFERENCE_ITEM_PRICE)) && (
        <ExpandoMenu>
          <>
            {/*  {userStore.hasAccess(Access.CREATE_EDIT_REFERENCE_ITEM) &&
                                <NewInventoryItemButton
                                    type={ButtonType.SECONDARY}
                                    onSave={onUpdate}
                                    inventoryItemId={inventoryItem.id}
                                    size={ButtonSize.SMALL}
                                    showText/>
                            }
                            <RemoveInventoryItemButton
                                type={ButtonType.SECONDARY}
                                onSave={onUpdate}
                                inventoryItem={inventoryItem}
                                size={ButtonSize.SMALL}/> */}
          </>
        </ExpandoMenu>
      )}
    </div>
  );
};
export default ReferenceItemPriceSelectRowSmall;
