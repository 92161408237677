import React from 'react';
import {useStyles} from "./Box.style";
import BoxProperties from "./BoxProperties";
import Direction from "../common/enums/Direction";
import Width from "../common/enums/Width";
import FlexContainer from "../FlexContainer/FlexContainer";
import Alignment from "../common/enums/Alignment";
import clsx from "clsx";


const Box: React.FC<BoxProperties> = ({
                                          className = '',
                                          direction = Direction.ROW,
                                          width = Width.FIT_CONTENT,
                                          alignItems = Alignment.CENTER,
                                          justifyContent = Alignment.CENTER,
                                          children
                                      }) => {
    const classes = useStyles();
    return (
        <FlexContainer
            className={clsx(classes.box_container, className)}
            width={width}
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}>
            {children}
        </FlexContainer>
    )
}
export default Box;