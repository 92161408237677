import React from 'react';
import NotificationsButtonProperties from "./NotificationsButtonProperties";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MessageIcon from '@mui/icons-material/Message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {Observer} from "mobx-react-lite";
import CloseIcon from '@mui/icons-material/Close';
import {useCommonContext} from "../../../contexts/CommonContext";
import NotificationsStore from './NotificationsStore';
import { useStyles } from './NotificationsButton.style';
import { Badge, Box, IconButton, Popover, Tooltip } from '@material-ui/core';
import NotificationType from '../../../entities/NotificationType';
import ShowDate from '../../Info/ShowDate/ShowDate';
import clsx from 'clsx';
import UserNotification from '../../../entities/Notification';
import { useNavigate } from 'react-router-dom';


const NotificationsButton: React.FC<NotificationsButtonProperties> = () => {

    const classes = useStyles();
    const commonContext = useCommonContext();
    const navigate = useNavigate();
    const store = new NotificationsStore(commonContext);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        store.markAsVisualized();
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const getIcon = (notificationType: NotificationType) => {
        switch (notificationType) {
            case NotificationType.NEW_COMMENT:
                return <MessageIcon/>;
            case NotificationType.ALERT:
                return <ReportProblemIcon/>;
            case NotificationType.GENERAL:
                return <NotificationsIcon/>;
        }
    }

    const getText = (notificationType: NotificationType) => {
        switch (notificationType) {
            case NotificationType.NEW_COMMENT:
                return "Nuevo comentario!";
            case NotificationType.ALERT:
                return "Alerta!";
            case NotificationType.GENERAL:
                return "General!";
        }
    }

    const handleLinkClick = (notification: UserNotification) => {
        if (notification.url) {
            navigate(notification.url, { replace: true });
            window.location.reload();
        }
    };

    return <Observer>
        {() => (
            <div>
                <Badge
                    badgeContent={store.newNotifications} 
                    classes={{ badge: classes.notifications_badge_back_color }}
                    max={99} className={classes.notifications_badge}>
                        <Box onClick={handleClick}>
                            <NotificationsIcon />
                        </Box>
                </Badge>
                <Popover
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.notification_area_header}>
                        <span className={classes.header_text}>Notificaciones</span>
                        <NotificationsIcon color="action" />
                    </div>
                    <div className={classes.notification_area_content}>
                        <ul className={classes.notifications_ul}>
                            {store.notifications.map((notification, i) => (
                                <li className={clsx(classes.notifications_li, (store.newNotificationsIds.findIndex(t => t === notification.id)) >= 0 && classes.new_notifications_li)}>
                                    <div className={classes.li_icon_content}>
                                        {getIcon(notification.type)}
                                    </div>
                                    <div
                                        className={clsx(classes.li_text_content, notification.url && classes.li_text_content_action)}
                                        onClick={() => handleLinkClick(notification)}>
                                        <div className={classes.li_text_type}>{getText(notification.type)}</div>
                                        <div><b>{notification.text}</b></div>
                                        {notification.secondaryText && <div className={classes.secondary_text_container}>
                                            <small>{notification.secondaryText}</small>
                                        </div>}
                                        <div>
                                            <ShowDate timestamp={notification.createdAt}
                                                className={classes.date_label}/></div>
                                    </div>
                                    <div>
                                        <Tooltip title="Eliminar">
                                            <IconButton onClick={() => { store.remove(notification) }}>
                                                <CloseIcon color="action" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </li>
                            ))}
                            {store.notifications.length === 0 && 
                                <div className={classes.no_notifications_container}>No hay notificaciones!!!</div>
                            }
                        </ul>
                    </div>
                </Popover>
            </div>
        )}
    </Observer>
}

export default NotificationsButton;
