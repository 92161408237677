import {makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";

class UploadImageStore {

    public commonStore: CommonStore;

    public selectedImage: string;

    constructor(commonStore: CommonStore) {
        this.selectedImage = '';
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

}

export default UploadImageStore;
