import React, {useEffect, useMemo} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./MoneyDelivery.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useCommonContext} from "../../contexts/CommonContext";
import MoneyDeliveryStore from './MoneyDeliveryStore';
import DeliveryPerson from '../../entities/DeliveryPerson';
import ButtonType from "../../ui_components/Button/ButtonType";
import NewMoneyDeliveryButton from "../../shared_components/Buttons/NewMoneyDeliveryButton/NewMoneyDeliveryButton";
import MoneyDeliveryRow from './MoneyDeliveryRow/MoneyDeliveryRow';
import DeliveryPersonSelect from '../../shared_components/Selects/DeliveryPersonSelect/DeliveryPersonSelect';
import RemittanceStatusSelect from '../../shared_components/Selects/RemittanceStatusSelect/RemittanceStatusSelect';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import CustomRangeRepository, {RangeName} from '../../ui_components/DateTimeRageSelect/CustomRangeRepository';
import CurrencySelect from '../../shared_components/Selects/CurrencySelect/CurrencySelect';
import Currency from '../../entities/Currency';
import RemittanceTypeSelect from '../../shared_components/Selects/RemittanceTypeSelect/RemittanceTypeSelect';
import AllowAccess from "../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../shared_components/Access/AllowAccess/Access";
import ClientContact from '../../entities/ClientContact';
import ChooseDeliveryPersonButton
    from '../../shared_components/Buttons/ChooseDeliveryPersonButton/ChooseDeliveryPersonButton';
import AgencySelect from "../../shared_components/Selects/AgencySelect/AgencySelect";
import Agency from "../../entities/Agency";
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import {GeoLevel} from "../../shared_components/Selects/GeoSelect/GeoSelectProperties";
import MoneyDeliveryOverview from "../../shared_components/Widget/MoneyDeliveryOverview/MoneyDeliveryOverview";
import Geo from "../../entities/Geo";
import DownloadMoneyDeliverySCVButton
    from "../../shared_components/Buttons/DownloadMoneyDeliveryCSVButton/DownloadMoneyDeliverySCVButton";
import DownloadOrdersToDeliverReportButton
    from '../../shared_components/Buttons/DownloadOrdersToDeliverReportButton/DownloadOrdersToDeliverReportButton';
import GeoMultiSelect from '../../shared_components/Selects/GeoMultiSelect/GeoMultiSelect';
import UpdateDeliveryOrdersStatusButton from '../../shared_components/Buttons/UpdateDeliveryOrdersStatusButton/UpdateDeliveryOrdersStatusButton';
import ButtonSize from '../../ui_components/Button/ButtonSize';
import ExpandoMenu from '../../ui_components/ExpandoMenu/ExpandoMenu';
import clsx from 'clsx';
import RemoveProductOrdersButton from '../../shared_components/Buttons/RemoveProductOrdersButton/RemoveProductOrdersButton';
// import SellerSelect from '../../shared_components/Selects/SellerSelect/SellerSelect';
// import Seller from '../../entities/Seller';
// import Token from '../../model/Token';

const MoneyDelivery: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const customRanges = CustomRangeRepository.getRanges(i18n);

    const range = customRanges.find(t => t.key === RangeName.LAST_FIFTEEN_DAYS);
    const store = useMemo<MoneyDeliveryStore>(() =>
            new MoneyDeliveryStore(commonStore, range || customRanges[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMoneyRemittanceClick = (moneyRemittanceId: string) => {
        store.selectedMoneyRemittanceId = moneyRemittanceId;
    }

    const onDeliveryPersonSelect = (deliveryPerson?: DeliveryPerson) => {
        if (deliveryPerson != null) {
            store.setMoneyRemittanceDeliveryPerson([store.selectedMoneyRemittanceId], deliveryPerson);
        } else {
            store.unsetMoneyRemittanceDeliveryPerson();
        }
    }

    const onDeliveryPersonSelectForSeveralMoneyDelivery = (deliveryPerson?: DeliveryPerson) => {
        if (deliveryPerson != null) {
            store.setMoneyRemittanceDeliveryPerson(store.selectedMoneyRemittanceIds, deliveryPerson);
            store.selectedMoneyRemittanceIds = [];
        }
    }

    // const getAgencyId = () => {
    //     if (store.agencyFilter) {
    //         return store.agencyFilter.id;
    //     }
    //     // pasar esto pa una propiedad del componente.
    //     const token = new Token();
    //     const user = token.parse();
    //     console.log(111111, user?.agencyId)
    //     return user?.agencyId;
    // }

    const onContactSelect = (clientContact: ClientContact) => {
        store.updateMoneyRemittanceContact(clientContact);
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onCurrencyChange = (newCurrency: Currency) =>
        (store.setCurrencyFilter(newCurrency));

    const onAgencyChange = (newAgency?: Agency) =>
        (store.setAgencyFilter(newAgency));

    // const onSellerChange = (newSeller?: Seller) =>
    //     (store.setSellerFilter(newSeller));

    const onTypeChange = (newType: string) =>
        (store.setTypeFilter(newType));

    const onGeoChange = (newGeos: Geo[]) =>
        (store.setGeoFilter(newGeos));

    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.setFromFilter(from);
        store.setToFilter(to);
    }

    const onUpdate = async () => {
        await store.getElements();
    }

    const onUpdateAndUnselectAll = async () => {
        store.selectedMoneyRemittanceIds = [];
        onMoneyDeliveryOrderToggleSelectAll(false);
        await store.getElements();
    }

    const onMoneyDeliveryOrderSelect = (moneyRemittanceId: string) => {
        const index = store.selectedMoneyRemittanceIds.findIndex(t => t === moneyRemittanceId);
        store.selectedMoneyRemittanceIds = index >= 0 ?
            store.selectedMoneyRemittanceIds.filter(t => t !== moneyRemittanceId) :
            [...store.selectedMoneyRemittanceIds, moneyRemittanceId];
    }

    const onMoneyDeliveryOrderToggleSelectAll = (value: boolean) => {
        store.selectedMoneyRemittanceIds = value ? store.moneyRemittances.map(t => t.id) : [];
    }

    const onUnselectAll = () => {
        store.selectedMoneyRemittanceIds = [];
    }

    const clearFilter = async () => {
        await store.clearFilter()
    }

    return (
        <Observer>
            {() => (
                <div className={classes.money_container}>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("MONEY_DELIVERY_ORDER_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <DateTimeRangeSelect selectedFrom={store.fromFilter} selectedTo={store.toFilter}
                                             label={i18n.translate("DATES")}
                                             onChange={onDateChange}/>
                        <AllowAccess any={[Access.SEND_ORDERS_TO_AGENCIES]}>
                            <AgencySelect selected={store.agencyFilter} onChange={onAgencyChange}/>
                        </AllowAccess>
                        {/* <SellerSelect
                                selected={store.sellerFilter}
                                agencyId={store.agencyFilter?.id}
                                onChange={onSellerChange}/> */}
                        <AllowAccess any={[Access.LIST_DELIVERY_PERSON]}>
                            <DeliveryPersonSelect
                                onChange={(c) => store.setDeliveryPersonToFilter(c)}
                                selected={store.deliveryPersonFilter}/>
                        </AllowAccess>
                        <RemittanceStatusSelect selectedStatus={store.statusFilter}
                                                onChange={(c) => store.setStatusToFilter(c)}/>
                        <CurrencySelect
                            showSelectAllOption={true}
                            fullWidth
                            selected={store.currencyFilter}
                            onChange={onCurrencyChange}
                        />
                        <RemittanceTypeSelect selected={store.typeFilter} onChange={onTypeChange}/>
                        <GeoMultiSelect selected={store.geoFilter} levels={[GeoLevel.CITY, GeoLevel.STATE]}
                                   onChange={onGeoChange}/>
                        <div className={classes.filter_buttons_container}>
                            <Button
                                onClick={() => {
                                    store.search()
                                }}
                                type={ButtonType.PRIMARY}
                                className={classes.search_btn}
                                text={i18n.translate("SEARCH")}
                            />
                            <Button
                                onClick={clearFilter}
                                className={classes.search_btn}
                                text={i18n.translate("CLEAN")}
                            />
                        </div>
                    </Box>
                    <div className={classes.money_table}>
                        <Box className={classes.tools_container}
                             width={Width.FULL_WIDTH}
                             justifyContent={Alignment.END}>
                            <div className={classes.options_container}>
                                <Checkbox 
                                    text={''}
                                    selected={store.selectedMoneyRemittanceIds.length === store.moneyRemittances.length}
                                    onChange={onMoneyDeliveryOrderToggleSelectAll}
                                    className={classes.select_all_checkbox}/>
                                <ExpandoMenu openToRight={true} disabled={store.haveNotSelected}>
                                    <>
                                        <ChooseDeliveryPersonButton
                                            onSelect={onDeliveryPersonSelectForSeveralMoneyDelivery}
                                            type={ButtonType.SECONDARY}
                                            disabled={store.haveNotSelected}
                                            orderByGeosIdProximity={store.moneyRemittances.filter(t => store.selectedMoneyRemittanceIds.indexOf(t.id) >= 0).map(t => t.geo.id)}
                                        />
                                        <UpdateDeliveryOrdersStatusButton
                                            disabled={store.disableUpdateBulkStatus}
                                            onSave={onUpdateAndUnselectAll}
                                            type={ButtonType.SECONDARY}
                                            productOrderIds={store.selectedMoneyRemittanceIds}
                                        />
                                        <DownloadOrdersToDeliverReportButton productOrderIds={store.selectedMoneyRemittanceIds}
                                            disabled={store.selectedMoneyRemittanceIds.length === 0}
                                            showText={true}
                                            type={ButtonType.SECONDARY}
                                            onSave={onUnselectAll}
                                        />
                                        <RemoveProductOrdersButton
                                            size={ButtonSize.SMALL}
                                            onSave={onUpdate}
                                            productOrdersIds={store.selectedMoneyRemittanceIds}
                                            showText
                                            type={ButtonType.SECONDARY}
                                        />
                                    </>
                                </ExpandoMenu>
                                <DownloadMoneyDeliverySCVButton
                                    from={store.fromFilter}
                                    to={store.toFilter}
                                    currency={store.currencyFilter}
                                    deliveryPersonId={store.deliveryPersonFilter?.id}
                                    agencyId={store.agencyFilter?.id}
                                    status={store.statusFilter}
                                    orderType={store.typeFilter}
                                    filter={store.filter}
                                    geoId={store.geoFilter && store.geoFilter.length > 0 ? store.geoFilter[0].id : undefined}
                                    disabled={store.isEmpty || !store.geoFilter || store.geoFilter?.length > 1}
                                    type={ButtonType.SECONDARY}
                                    className={clsx(classes.hide_small,classes.margin_left_download_btn)}
                                />
                            </div>
                            {/* <div>
                                <Checkbox 
                                    text={''}
                                    selected={store.selectedMoneyRemittanceIds.length === store.moneyRemittances.length}
                                    onChange={onMoneyDeliveryOrderToggleSelectAll}
                                    className={classes.select_all_checkbox}/>
                            </div>
                            <div className={classes.hide_small}>
                                <DownloadMoneyDeliverySCVButton
                                    from={store.fromFilter}
                                    to={store.toFilter}
                                    currency={store.currencyFilter}
                                    deliveryPersonId={store.deliveryPersonFilter?.id}
                                    agencyId={store.agencyFilter?.id}
                                    status={store.statusFilter}
                                    orderType={store.typeFilter}
                                    filter={store.filter}
                                    geoId={store.geoFilter && store.geoFilter.length > 0 ? store.geoFilter[0].id : undefined}
                                    disabled={store.isEmpty || !store.geoFilter || store.geoFilter?.length > 1}
                                    type={ButtonType.SECONDARY}
                                />
                            </div>
                            <div>
                                <ChooseDeliveryPersonButton
                                    onSelect={onDeliveryPersonSelectForSeveralMoneyDelivery}
                                    type={ButtonType.SECONDARY} disabled={store.haveNotSelected}
                                    orderByGeosIdProximity={store.moneyRemittances.filter(t => store.selectedMoneyRemittanceIds.indexOf(t.id) >= 0).map(t => t.geo.id)}/>
                            </div>
                            <div className={classes.hide_small}>
                                <UpdateDeliveryOrdersStatusButton
                                    disabled={store.disableUpdateBulkStatus}
                                    onSave={onUpdateAndUnselectAll}
                                    type={ButtonType.SECONDARY}
                                    productOrderIds={store.selectedMoneyRemittanceIds}/>
                            </div>
                            <div className={classes.hide_small}>
                                <DownloadOrdersToDeliverReportButton productOrderIds={store.selectedMoneyRemittanceIds}
                                    disabled={store.selectedMoneyRemittanceIds.length === 0}
                                    showText={true}
                                    type={ButtonType.SECONDARY}
                                    onSave={onUnselectAll}/>
                            </div>
                            <div>
                                <RemoveMoneyDeliveryButton
                                    size={ButtonSize.SMALL}
                                    onSave={onUpdate}
                                    moneyDeliveryOrderId={'moneyDeliveryOrder.id'}
                                    showText
                                    type={ButtonType.SECONDARY}
                                />
                            </div> */}
                            <NewMoneyDeliveryButton type={ButtonType.SECONDARY} onSuccessCreate={onUpdate}/>
                        </Box>
                        {(!store.geoFilter || store.geoFilter?.length === 1 || store.geoFilter?.length === 0) && <MoneyDeliveryOverview
                            from={store.fromFilter}
                            to={store.toFilter}
                            currency={store.currencyFilter}
                            deliveryPersonId={store.deliveryPersonFilter?.id}
                            agencyId={store.agencyFilter?.id}
                            status={store.statusFilter}
                            type={store.typeFilter}
                            filter={store.filter}
                            geoId={store.geoFilter && store.geoFilter!.length > 0 ? store.geoFilter![0].id : ''}
                            includeChildren
                            forceRefresh={store.ref}
                            className={classes.hide_small}
                        />}
                        {!store.isEmpty &&
                            <>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={i18n.translate("MONEY_REMITTANCES")}
                                                pagination={store.pagination}/>
                                <div className={classes.elements_container}>
                                    {store.moneyRemittances.map((moneyRemittance, i) => (
                                        <MoneyDeliveryRow
                                            filter={store.filter}
                                            onMoneyDeliveryOrderClick={onMoneyRemittanceClick}
                                            onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect}
                                            onDeliveryPersonSelect={onDeliveryPersonSelect}
                                            onContactSelect={onContactSelect}
                                            moneyDeliveryOrder={moneyRemittance}
                                            key={i}
                                            selected={store.selectedMoneyRemittanceIds.some(t => t === moneyRemittance.id)}
                                            onUpdate={onUpdate}
                                        />
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={i18n.translate("MONEY_REMITTANCES")}
                                    pagination={store.pagination}/>
                            </>
                        }
                    </div>
                    {store.isEmpty &&
                        <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_MONEY_REMITTANCES_TITLE")}
                                     subtitle={i18n.translate("NO_MONEY_REMITTANCES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default MoneyDelivery;
