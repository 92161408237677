import React from 'react';
import {useStyles} from "./Alert.style";
import AlertProperties from "./AlertProperties";
import clsx from "clsx";
import AlertType from "./AlertType";
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import AddAlertSharpIcon from '@mui/icons-material/AddAlertSharp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';


const Alert: React.FC<AlertProperties> = ({
                                              className = '',
                                              text,
                                              type = AlertType.INFO,
                                          }) => {
    const classes = useStyles();
    const getIcon = () => {
        switch (type) {
            case AlertType.DANGER:
                return <ErrorIcon/>;
            default:
            case AlertType.INFO:
                return <InfoIcon/>;
            case AlertType.PRIMARY:
                return <AddAlertSharpIcon/>;
            case AlertType.SUCCESS:
                return <CheckCircleIcon/>;
            case AlertType.WARNING:
                return <WarningIcon/>;
            case AlertType.SECONDARY:
                return <AddAlertSharpIcon/>;
        }
    }
    const customClass = (): string => {
        switch (type) {
            case AlertType.DANGER:
                return classes.danger;
            default:
            case AlertType.INFO:
                return classes.info;
            case AlertType.PRIMARY:
                return classes.primary;
            case AlertType.SUCCESS:
                return classes.success;
            case AlertType.WARNING:
                return classes.warning;
            case AlertType.SECONDARY:
                return classes.secondary;
        }
    }
    if (!text) {
        return <></>;
    }

    return (
        <div className={clsx(classes.alert_container, customClass(), className)}>
            {getIcon()}
            <div>{text}</div>
        </div>
    )
}
export default Alert;