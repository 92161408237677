import React from 'react';
import MoneyDeliveryRowProperties from "./MoneyDeliveryRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import MoneyDeliveryRowMedium from "./MoneyDeliveryRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import MoneyDeliveryRowSmall from "./MoneyDeliveryRowSmall";


const MoneyDeliveryRow: React.FC<MoneyDeliveryRowProperties> = ({
                                                                    moneyDeliveryOrder,
                                                                    selected,
                                                                    onMoneyDeliveryOrderClick,
                                                                    onMoneyDeliveryOrderSelect,
                                                                    onDeliveryPersonSelect,
                                                                    onContactSelect,
                                                                    onUpdate,
                                                                    filter
                                                                }) => {
    return (
        <>
            <ViewSmall>
                <MoneyDeliveryRowSmall onUpdate={onUpdate} onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect}
                                       onMoneyDeliveryOrderClick={onMoneyDeliveryOrderClick}
                                       onDeliveryPersonSelect={onDeliveryPersonSelect} onContactSelect={onContactSelect}
                                       moneyDeliveryOrder={moneyDeliveryOrder}
                                       selected={selected}
                                       filter={filter}/>
            </ViewSmall>
            <ViewMedium>
                <MoneyDeliveryRowMedium onUpdate={onUpdate} onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect}
                                        onMoneyDeliveryOrderClick={onMoneyDeliveryOrderClick}
                                        onDeliveryPersonSelect={onDeliveryPersonSelect}
                                        onContactSelect={onContactSelect} moneyDeliveryOrder={moneyDeliveryOrder}
                                        selected={selected}
                                        filter={filter}/>
            </ViewMedium>
            <ViewLarge>
                <MoneyDeliveryRowMedium onUpdate={onUpdate} onMoneyDeliveryOrderSelect={onMoneyDeliveryOrderSelect}
                                        onMoneyDeliveryOrderClick={onMoneyDeliveryOrderClick}
                                        onDeliveryPersonSelect={onDeliveryPersonSelect}
                                        onContactSelect={onContactSelect} moneyDeliveryOrder={moneyDeliveryOrder}
                                        selected={selected}
                                        filter={filter}/>
            </ViewLarge>
        </>
    )
}

export default MoneyDeliveryRow;
