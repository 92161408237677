import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Pagination from "../../../entities/Pagination";
import { ShopProductStockTransactionService } from "../../../service/ShopProductStockTransactionService";
import ShopProductStockTransaction from "../../../entities/productOrder/shop/ShopProductStockTransaction";

class ViewShopProductStockTransactionsStore {

    public shopProductId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public pagination: Pagination;
    public shopProductStockTransactions: ShopProductStockTransaction[];
    public from?: number;
    public to?: number;


    constructor(commonStore: CommonStore, shopProductId: string) {
        this.shopProductId = shopProductId;
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        this.pagination = new Pagination(1, 10, 0, 0);
        this.shopProductStockTransactions = [];
        makeAutoObservable(this);
    }

    @action
    public async search(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.loading = true;
        const response = await ShopProductStockTransactionService.get(
            this.shopProductId,
            page,
            pageSize,
            this.from,
            this.to,
        );
        if (response.success) {
            runInAction(() => {
                this.shopProductStockTransactions = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public async openModal() {
        this.isModalOpen = true;
        await this.search();
    }

    @action
    public async cleanFilter() {
        this.from = undefined;
        this.to = undefined;
        await this.search();
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    get isEmpty(): boolean {
        return this.shopProductStockTransactions.length === 0;
    }

    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.search(page, pageSize);
    }

}

export default ViewShopProductStockTransactionsStore;