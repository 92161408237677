import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    title: {
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800,
        textDecoration: 'underline'
    }
}));

export {useStyles}