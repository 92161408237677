import React from 'react';
import MySalesTabProperties from "./MySalesTabProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./MySalesTab.style";
import clsx from "clsx";
import AgencyTotalSales from "../../../../shared_components/Widget/AgencyTotalSales/AgencyTotalSales";
import AgencyTotalSalesGraph from "../../../../shared_components/Widget/AgencyTotalSalesGraph/AgencyTotalSalesGraph";
import {useUserContext} from "../../../../contexts/UserContext";
import MoneyDeliverySales
    from "../../../../shared_components/Widget/MoneyDeliverySales/MoneyDeliverySales";


const MySalesTab: React.FC<MySalesTabProperties> = ({
                                                        from,
                                                        to
                                                    }) => {

    // const i18n = useI18nContext();
    // const commonContext = useCommonContext();
    const userStore = useUserContext();
    const classes = useStyles();

    return <Observer>
        {() => (<div className={classes.tab_container}>
            <div className={classes.row}>
                <div className={clsx(classes.box, classes.with_height)}>
                    <AgencyTotalSalesGraph from={from}
                                           to={to}
                                           title={"Total de ventas"}
                                           userId={userStore.currentUser()?.id}/>
                </div>
                <div className={clsx(classes.box, classes.with_height, classes.with_scroll)}>
                    <AgencyTotalSales from={from}
                                      to={to}
                                      title={"Total de ventas"}
                                      userId={userStore.currentUser()?.id}/>
                </div>
                <div className={clsx(classes.box, classes.span2, classes.with_scroll)}>
                    <MoneyDeliverySales from={from || 0}
                                        to={to || 0}
                                        sellerId={userStore.currentUser()?.id}
                                        title={"Envíos de dinero"}/>
                </div>
            </div>
        </div>)}
    </Observer>
}

export default MySalesTab;
