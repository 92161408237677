import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import StorefrontIcon from '@mui/icons-material/Storefront';
import AgencyNameInputProperties from "./AgencyNameInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";

const AgencyNameInput: React.FC<AgencyNameInputProperties> = ({
                                                                  value,
                                                                  placeholder,
                                                                  onChange,
                                                                  disabled,
                                                                  fullWidth = false,
                                                                  className = '',
                                                                  error
                                                              }) => {

    const [name, setName] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setName(value);
    }, [value]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newName = event.target.value;
        setName(newName);
        onChange(newName);
    }

    return (
        <TextInput
            error={error}
            label={i18n.translate("AGENCY_NAME")}
            value={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onNameChange}
            startIcon={StorefrontIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default AgencyNameInput;