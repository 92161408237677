import ShopProductStatus from "./ShopProductStatus";

class ShopProduct {
    public id: string;
    public name: string;
    public description: string;
    public defaultImage: string;
    public price: number;
    public stockAmount: number;
    public minStockWarning: number;
    public status: ShopProductStatus;
    public amount?: number;
    public createdAt: number;

    constructor(
        id: string,
        name: string,
        description: string,
        defaultImage: string,
        price: number,
        stockAmount: number,
        minStockWarning: number,
        status: ShopProductStatus,
        createdAt: number
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.defaultImage = defaultImage;
        this.price = price;
        this.stockAmount = stockAmount;
        this.minStockWarning = minStockWarning;
        this.status = status;
        this.createdAt = createdAt;
    }
}

export default ShopProduct;