import React from 'react';
import {Observer} from "mobx-react-lite";
import {useCommonContext} from "../../../contexts/CommonContext";
import ShowOrderStatus from "../ShowOrderStatus/ShowOrderStatus";
import ShowDate from "../ShowDate/ShowDate";
import PersonIcon from '@mui/icons-material/Person';
import { useUserContext } from '../../../contexts/UserContext';
import ShowProductOrderStatusHistoryProperties from './ShowProductOrderStatusHistoryProperties';
import { useStyles } from './ShowProductOrderStatusHistory.style';
import ShowProductOrderStatusHistoryStore from './ShowProductOrderStatusHistoryStore';
import AllowAccess from '../../Access/AllowAccess/AllowAccess';
import Access from '../../Access/AllowAccess/Access';

const ShowProductOrderStatusHistory: React.FC<ShowProductOrderStatusHistoryProperties> = ({
                                                                                                  className = '',
                                                                                                  productOrderId,
                                                                                              }) => {
    const classes = useStyles();

    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new ShowProductOrderStatusHistoryStore(commonContext, productOrderId, userStore.isRootAgency());
    
    return <Observer>
        {() => (
            <>
                <div className={classes.history_container}>
                    <div className={classes.body}>
                        {!store.isEmpty &&
                            <div className={classes.history_table}>
                                <div className={classes.line}/>
                                {store.statusHistory.map((s, i) => (
                                    <div className={classes.element_row}>
                                        <div key={i} className={classes.date_container}>
                                            <ShowDate timestamp={s.date} showSeconds/>
                                        </div>
                                        <div className={classes.dot}/>
                                        <div key={i} className={classes.element_container}>
                                            <ShowOrderStatus status={s.status}/>
                                            <div className={classes.comment}>{s.comment}</div>
                                            <AllowAccess any={[Access.SHOW_USER_IN_STATUS_HISTORY]}>
                                                {s.userName &&
                                                    <div className={classes.user_container}><PersonIcon fontSize="small"/> {s.userName}</div>}
                                            </AllowAccess>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </>
        )}
    </Observer>
}

export default ShowProductOrderStatusHistory;
