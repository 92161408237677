import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    group_container: {
        border: `1px solid ${theme.palette.grey[400]}`,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        position: 'relative'
    },
    title: {
        position: 'absolute',
        top: '-8px',
        left: '10px',
        zIndex: 2,
        color: theme.palette.grey[700],
        padding: '0 4px',
        fontSize: '12px',
        backgroundImage: `linear-gradient(0deg, transparent 50%, ${theme.palette.common.white} 50%)`
    },
    disabled: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: `${theme.palette.grey[200]}`,
        zIndex: 1,
        opacity: 0.5
    }
}));

export {useStyles}