import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ShopProductService } from "../../../service/shop/ShopProductService";
import ShopProduct from "../../../entities/productOrder/shop/ShopProduct";

const ERROR_MAP = {
    name: ["ERROR_00_400_80", "ERROR_00_400_0001", "ERROR_00_400_0002"],
    description: ["ERROR_00_400_80", "ERROR_00_400_0001", "ERROR_00_400_0002"],
}

class NewShopProductStore {

    public shopProductId?: string;
    public shopProduct: ShopProduct;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public nameError?: string;
    public descriptionError?: string;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, shopProductId?: string) {
        this.isEditing = !!shopProductId;
        this.shopProductId = shopProductId;
        this.isModalOpen = false;
        this.shopProduct = {} as ShopProduct;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.shopProduct?.name);
    }

    @action
    public async loadFoodPackage() {
        if (this.shopProductId) {
            this.isEditing = true;
            const response = await ShopProductService.find(this.shopProductId!)
            if (response.success && response.data) {
                this.shopProduct = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadFoodPackage();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? ShopProductService.update : ShopProductService.create;
        const response = await serviceToCall(this.shopProduct);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.nameError = undefined;
        this.descriptionError = undefined;
        if (error) {
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.description.includes(error)) {
                this.descriptionError = error;
            }
        }

    }
}

export default NewShopProductStore;