import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    sign_in_page_container: {
        backgroundImage: "url(/webb.png)",
        backgroundPosition: 'bottom',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form_container: {
        boxSizing: 'border-box',
        position: 'relative',
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '6px 6px 0 0',
        padding: '8px 8px',
        textAlign: 'center',
    },
    body: {
        border: `1px solid ${theme.palette.grey["200"]}`,
        borderRadius: '0 0 6px 6px',
        padding: '32px 32px 32px 32px',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        "& button": {
            marginTop: '32px'
        }
    },
    '@keyframes bp': {
        "from": {
            backgroundPosition: '150px 0'
        },
        "to": {
            backgroundPosition: '0 173px'
        }
    },
    notification_container: {
        height: '30px',
        position: 'absolute',
        // bottom: 0,
        width: '100%'
    },
    error: {
        color: theme.palette.error.light,
        fontSize: '16px',
        padding: '8px',
        background: `${theme.palette.error.light}38`,
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    label: {
        color: theme.palette.info.light,
        fontSize: '16px',
        padding: '8px',
        background: `${theme.palette.info.light}38`,
        borderRadius: '4px',
        boxSizing: 'border-box',
    },
    sign_in_btn:{
        marginTop: '8px'
    }
}));

export {useStyles}
export default useStyles