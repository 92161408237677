import { action, makeAutoObservable, runInAction } from "mobx";
import CommonStore from "../../../stores/CommonStore";
import DeliveryPersonResponse from "../../../model/packageDelivery/DeliveryPersonInfoResponse";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import ProductOrderDeliveryPersonStatus from "../../../entities/productOrder/ProductOrderDeliveryPersonStatus";
import { PackageDeliveryService } from "../../../service/packageDelivery/PackageDeliveryService";
import DeliveryPersonInfoRequest from "../../../model/packageDelivery/DeliveryPersonInfoRequest";
import DeliveryPersonType from "../../../entities/DeliveryPersonType";
import DeliveryPersonInfoResponse from "../../../model/packageDelivery/DeliveryPersonInfoResponse";
import AddDeliveryPersonInfoRequestMultiOrders from "../../../model/packageDelivery/AddDeliveryPersonInfoRequestMultiOrders";
import NewCommissionDeliveryPersonInfoRequestMultiOrders from "../../../model/packageDelivery/NewCommissionDeliveryPersonInfoRequestMultiOrders";
import I18nStore from "../../../stores/I18nStore";

const ERROR_MAP = {
  unauthorized: ["ERROR_00_401_00"],
  deliveryPersonInfoDiffer: ["ERROR_00_400_54"],
};
class ChooseDeliveryPersonMultiStore {
  public deliveryOrderIds?: string[];
  public deliveryPersonsInfo: DeliveryPersonResponse[];
  public isModalOpen: boolean;
  public loading: boolean;
  public commonStore: CommonStore;
  public i18n:I18nStore

  constructor(commonStore: CommonStore,i18n:I18nStore, deliveryOrderIds?: string[]) {
    this.deliveryOrderIds = deliveryOrderIds;
    this.deliveryPersonsInfo = [];
    this.isModalOpen = false;
    this.loading = false;
    this.commonStore = commonStore;
    this.i18n = i18n
    makeAutoObservable(this);
  }

  @action
  public async openModal() {
    this.loading = true;
    this.isModalOpen = true;
    this.loading = false;
    await this.getDeliveryPersonInfo();
  }

  @action
  public closeModal() {
    this.isModalOpen = false;
  }
  
  //GET 
  @action
  public async getDeliveryPersonInfo() {
    if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
      const response =
        await PackageDeliveryService.getPackageDeliveryDeliveryPersonsInfo(
          this.deliveryOrderIds
        );
      if (response.success) {
        runInAction(() => {
          this.deliveryPersonsInfo = response.data!;
        });
      }
      this.commonStore.processErrors(response);
      this.processError(response.error);
      this.commonStore.removeLoading();
    }
  }
  //ADD
  @action
  public async selectDeliveryPerson(deliveryPerson?: DeliveryPerson) {
    if (deliveryPerson) {
      if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
        //1-We add to the order the deliveryPerson
        const deliveryPersonInfoRequest = new DeliveryPersonInfoRequest(
          deliveryPerson.id,
          this.getDefaultCommission(DeliveryPersonType.DELIVERY_PERSON),
          ProductOrderDeliveryPersonStatus.ASSIGNED,
          0,
          0
        );
        const addDeliveryPersonInfoRequestMultiOrders =
          new AddDeliveryPersonInfoRequestMultiOrders(
            this.deliveryOrderIds,
            deliveryPersonInfoRequest
          );
        const response = await PackageDeliveryService.addDeliveryPersonInfo(
          addDeliveryPersonInfoRequestMultiOrders
        );
        if (response.success) {
          runInAction(() => {
            //2-If response is success we update the list with the response
            this.deliveryPersonsInfo = response.data;
          });
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.commonStore.removeLoading();
      }
    }
  }
  @action
  public async selectOrganizer(deliveryPerson?: DeliveryPerson) {
    if (deliveryPerson) {
      if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
        //1-We add to the order the deliveryPerson
        const deliveryPersonInfoRequest = new DeliveryPersonInfoRequest(
          deliveryPerson.id,
          this.getDefaultCommission(DeliveryPersonType.ORGANIZER),
          ProductOrderDeliveryPersonStatus.ASSIGNED,
          0,
          0
        );
        const addDeliveryPersonInfoRequestMultiOrders =
          new AddDeliveryPersonInfoRequestMultiOrders(
            this.deliveryOrderIds,
            deliveryPersonInfoRequest
          );
        const response = await PackageDeliveryService.addDeliveryPersonInfo(
          addDeliveryPersonInfoRequestMultiOrders
        );
        if (response.success) {
          runInAction(() => {
            //2-If response is success we update the list with the response
            this.deliveryPersonsInfo = response.data;
          });
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.commonStore.removeLoading();
      }
    }
  }
  //REMOVE
  @action
  public async removeDeliveryPersonInfo(
    deliveryPersonInfo?: DeliveryPersonInfoResponse
  ) {
    if (deliveryPersonInfo) {
      if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
        const response = await PackageDeliveryService.removeDeliveryPersonInfo(this.deliveryOrderIds, deliveryPersonInfo.deliveryPersonId);
        if (response.success) {
          runInAction(() => {
            this.deliveryPersonsInfo = response.data;
          });
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.commonStore.removeLoading();
      }
    }
  }
  //NEW COMMISSION
  @action
  public async saveNewCommission(
    newCommission?: number,
    deliveryPersonInfo?: DeliveryPersonInfoResponse
  ) {
    if (deliveryPersonInfo && newCommission) {
      if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
        const newCommissionRequest= new NewCommissionDeliveryPersonInfoRequestMultiOrders(this.deliveryOrderIds,newCommission)
        const response = await PackageDeliveryService.setCommissionDeliveryPersonInfo(newCommissionRequest,deliveryPersonInfo.deliveryPersonId);
        if (response.success) {
          runInAction(() => {
            this.deliveryPersonsInfo = response.data;
          });
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.commonStore.removeLoading();
      }
    }
  }

  //RECEIVE
  @action
  public async receivedDelivery(
    deliveryPersonInfo?: DeliveryPersonInfoResponse
  ) {
    if (deliveryPersonInfo) {
      if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
        const response = await PackageDeliveryService.receivedDelivery(
          this.deliveryOrderIds,
          deliveryPersonInfo.deliveryPersonId
        );
        if (response.success) {
          runInAction(() => {
            this.deliveryPersonsInfo = response.data;
          });
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.commonStore.removeLoading();
      }
    }
  }
  //DELIVER
  @action
  public async deliveredDelivery(
    deliveryPersonInfo?: DeliveryPersonInfoResponse
  ) {
    if (deliveryPersonInfo) {
      if (this.deliveryOrderIds != null && this.deliveryOrderIds.length > 0) {
        const response = await PackageDeliveryService.deliveredDelivery(
          this.deliveryOrderIds,
          deliveryPersonInfo.deliveryPersonId
        );
        if (response.success) {
          runInAction(() => {
            this.deliveryPersonsInfo = response.data;
          });
        }
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.commonStore.removeLoading();
      }
    }
  }


  private getDefaultCommission(deliveryPersonType: DeliveryPersonType) {
    switch (deliveryPersonType) {
      case DeliveryPersonType.DELIVERY_PERSON:
        return 5.0;
      case DeliveryPersonType.ORGANIZER:
        return 1.0;
    }
  }

  processError(error?: string) {
    if (error) {
      if (ERROR_MAP.unauthorized.includes(error)) {
        this.commonStore.showErrorToast(error); //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
      }
      if (ERROR_MAP.deliveryPersonInfoDiffer.includes(error)) {
        this.commonStore.showErrorToast(this.i18n.translate(error)); //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
      }
    }
  }
}

export default ChooseDeliveryPersonMultiStore;
