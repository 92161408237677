import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ArticleService } from "../../../service/articleDelivery/ArticleService";

class UploadArticleImageStore {

    public articleId: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public selectedImageFile?: File;

    constructor(commonStore: CommonStore, articleId: string) {
        this.isModalOpen = false;
        this.loading = false;
        this.articleId = articleId;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        if (!this.selectedImageFile) {
            return false;
        }
        this.loading = true;
        await ArticleService.uploadImage(this.articleId, this.selectedImageFile);
        this.loading = false;
        return true;
    }
}

export default UploadArticleImageStore;