import {action, makeAutoObservable} from "mobx";
import Seller from "../../../entities/Seller";
import {SellerService} from "../../../service/SellerService";
import CommonStore from "../../../stores/CommonStore";

const ERROR_MAP = {
    email: ["ERROR_00_400_07", "ERROR_00_400_08", "ERROR_00_400_09"],
    phone: ["ERROR_00_400_10"],
    name: ["ERROR_00_400_06"],
}

class NewSellerStore {

    public sellerId?: string;
    public seller: Seller;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public emailError?: string;
    public phoneError?: string;
    public nameError?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, sellerId?: string) {
        this.isEditing = !!sellerId;
        this.sellerId = sellerId;
        this.isModalOpen = false;
        this.seller = {roles: new Array<string>()} as Seller;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.seller?.name && this.seller?.email);
    }

    @action
    public async loadSeller() {
        if (this.sellerId) {
            this.isEditing = true;
            const response = await SellerService.find(this.sellerId!)
            if (response.success && response.data) {
                this.seller = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadSeller();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? SellerService.update : SellerService.create;
        const response = await serviceToCall(this.seller);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default NewSellerStore;