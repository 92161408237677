import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    geo_info_container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
    },
    geo_icon: {
        backgroundColor: theme.palette.secondary.light,
        fontSize: '20px',
        border: `2px solid ${theme.palette.common.white}`,
        color: theme.palette.common.white,
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    info_container: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        lineHeight: '14px',
        marginLeft: '8px',
        color: (light) => light ? theme.palette.common.white : theme.palette.secondary.light,
        "& div:first-child": {
            fontWeight: '800'
        }
    },
    inactive: {
        backgroundColor: theme.palette.grey[500]
    },
    no_geo: {
        color: theme.palette.grey[500]
    }
}));

export {useStyles}