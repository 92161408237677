import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Geo from "../entities/Geo";

export module GeoService {

    export async function search(page: number, pageSize: number, filter?: string, level?: number[], parentIdFilter?: string): Promise<Response<PagedResponse<Geo>>> {
        const url = Config.get("SEARCH_GEO_URL", {filter, level: level?.join(), page, pageSize, parentIdFilter});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const geoData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (geoData && paginationData) {
                const data = geoData.map((t: any) => new Geo(t.id, t.name, t.parentId, t.level, t.hasChildren, t.parentInfo));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Geo>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Geo>>(false, undefined, response.error, response.status);
    }
}