import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import AddBalanceRequest from "../../../model/transaction/AddBalanceRequest";
import Currency from "../../../entities/Currency";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";
import DeliveryPerson from "../../../entities/DeliveryPerson";

class TransferBalanceStore {

    public amount?: number;
    public currency?: Currency;
    public comment?: string;
    public from?: DeliveryPerson;
    public to?: DeliveryPerson;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;
    public amountError?: string;
    public currencyError?: string;
    public fromError?: string;
    public toError?: string;
    public error?: string;
    public success?: string;


    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.amount && this.currency && this.from && this.to);
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async cleanModal() {
        this.amount = undefined;
        this.currency = undefined;
        this.comment = undefined;
        // this.from = undefined;
        this.to = undefined;
    }


    @action
    public async save(): Promise<boolean> {
        if (this.amount && this.currency && this.from && this.to) {
            this.loading = true;
            const request = new AddBalanceRequest(this.to.id, this.amount, this.currency, this.comment);
            const response = await DeliveryPersonService.transferBalance(this.from.id, request);
            this.commonStore.processErrors(response);
            this.loading = false;
            return response.success;
        } else {
            this.amountError = undefined;
            this.currencyError = undefined;
            if (!this.amount) {
                this.amountError = "AMOUNT_REQUIRED";
            }
            if (!this.currency) {
                this.currencyError = "CURRENCY_REQUIRED";
            }
            if (!this.from) {
                this.fromError = "FROM_REQUIRED";
            }
            if (!this.to) {
                this.toError = "TO_REQUIRED";
            }
        }
        return false;
    }
}

export default TransferBalanceStore;