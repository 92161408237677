import { action, makeAutoObservable, runInAction } from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ArticleDelivery from "../../../entities/ArticleDelivery";
import OrderAdditionalPrice from "../../../entities/OrderAdditionalPriceRequest";
import { ArticleService } from "../../../service/articleDelivery/ArticleService";
import ArticleWithPriceResponse from "../../../model/articleDelivery/ArticleWithPriceResponse";
import ClientContact from "../../../entities/ClientContact";
import { ArticleDeliveryService } from "../../../service/articleDelivery/ArticleDeliveryService";
import ArticleDeliveryOrderRequest from "../../../model/articleDelivery/ArticleDeliveryOrderRequest";
import Client from "../../../entities/Client";
import Agency from "../../../entities/Agency";
import Seller from "../../../entities/Seller";
import Geo from "../../../entities/Geo";
import ArticlePrice from "../../../entities/ArticlePrice";
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import { AppStorage, AppStorageValues } from "../../../util/AppStorage";

class NewArticleDeliveryStore {

    public isModalOpen: boolean;
    public validating: boolean;
    public commonStore: CommonStore;
    public response?: ArticleDelivery[]
    public errorKey?: string;

    public finishButtonLoading = false;

    public activeStep = 0;

    // Remittances data

    // Articles
    public loadingArticles: boolean;
    public articles?: ArticleWithPriceResponse[];
    public selectedArticles: ArticleWithPriceResponse[];
    public selectedArticlesPrices: ArticlePrice[];
    public geoFilter?: Geo;

    // Contacts
    public selectedClient?: Client;
    public selectedContact?: ClientContact;
    public selectedAgency?: Agency;
    public selectedSeller?: Seller;
    
    public observations?: string;

    // Additional prices
    public additionalPrices: OrderAdditionalPrice[];

    // Payments
    public payments: AddPaymentRequest[];
    public paymentCompleted: boolean;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.validating = false;
        this.loadingArticles = false;
        this.selectedArticles = [];
        this.selectedArticlesPrices = [];
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.commonStore = commonStore;
        this.selectedAgency = AppStorage.get(AppStorageValues.SELECTED_AGENCY, undefined);
        this.selectedSeller = AppStorage.get(AppStorageValues.SELECTED_SELLER, undefined);
        makeAutoObservable(this);
    }

    public reset() {
        this.loadingArticles = false;
        this.selectedArticles = [];
        this.selectedArticlesPrices = [];
        this.selectedClient = undefined;
        this.selectedContact = undefined;
        this.additionalPrices = [];
        this.payments = [];
        this.paymentCompleted = false;
        this.activeStep = 0;
        this.observations = undefined;
    }

    @action
    public async openModal() {
        this.reset();
        this.isModalOpen = true;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public restart() {
        this.response = undefined;
    }

    // Contact area

    @action
    public setClient(client?: Client) {
        this.selectedClient = client;
    }

    @action
    public setContact(contact?: ClientContact) {
        this.selectedContact = contact;
    }

    @action
    public setObservations(observations?: string) {
        this.observations = observations;
    }

    // Articles area

    @action
    public async filterByGeo(): Promise<void> {
        this.loadingArticles = true;
        const response = await ArticleService.filter(this.geoFilter ? this.geoFilter.id : '00001');
        if (response) {
            runInAction(() => {
                this.articles = response || [];
            })
        }
        this.loadingArticles = false;
    }

    get isEmpty(): boolean {
        return !this.articles || this.articles.length === 0;
    }

    @action
    public async selectArticle(foodPackageId: string) {
        const foodPackage = this.articles!.find(t => t.id === foodPackageId);
        if (foodPackage && this.selectedArticles && this.selectedArticles.findIndex(t => t.id === foodPackageId) < 0) {
            this.selectedArticles?.push(foodPackage);
        }
    }

    @action
    public async deselectArticle(articleId: string) {
        this.selectedArticles = this.selectedArticles?.filter(t => t.id !== articleId);
        this.selectedArticlesPrices = this.selectedArticlesPrices?.filter(t => t.articleId !== articleId);
    }

    // Additional prices area

    @action
    public addAdditionalPrice(currency: string, amount: number, comment: string) {
        this.additionalPrices.push(new OrderAdditionalPrice(amount, comment, false));
    }

    @action
    public removeAdditionalPrice(additionalPrice: OrderAdditionalPrice) {
        this.additionalPrices = this.additionalPrices.filter(t => t.amount !== additionalPrice.amount && t.comment !== additionalPrice.comment);
    }

    public getAdditionalPriceTotal(): number {
        return this.additionalPrices.reduce((sum, current) => sum + current.amount, 0);
    }

    // Payment area

    public getServicePrice(): number {
        return this.selectedArticlesPrices?.reduce((total, obj) =>
            total + (obj.price * (this.selectedArticles.find(article => article.id === obj.articleId)!!.amount)), 0) || 0;
    }

    public getTotalToPay(): number {
        return this.getServicePrice() + this.getAdditionalPriceTotal();
    }

    @action
    public setPayments(payments: AddPaymentRequest[], paymentCompleted: boolean) {
        this.payments = payments;
        this.paymentCompleted = paymentCompleted;
    }

    @action
    public async createArticleDelivery() {
        if (!this.selectedContact) {
            await this.commonStore.showErrorToast('Debe seleccionar un contacto.');
            return;
        }
        if (!this.selectedArticles || this.selectedArticles.length === 0) {
            await this.commonStore.showErrorToast('Debe seleccionar algún artículo.');
            return;
        }
        if (this.finishButtonLoading) {
            return;
        }
        this.finishButtonLoading = true;
        const response = await ArticleDeliveryService.create(
            new ArticleDeliveryOrderRequest(
                this.selectedContact.clientId,
                this.selectedContact?.id,
                this.getTotalToPay(),
                this.selectedArticles.map(article => {
                    const tempPrice = this.selectedArticlesPrices.find(price => price.articleId === article.id)?.price;
                    return {
                        articleId: article.id,
                        amount: article.amount,
                        price: tempPrice ?? 0
                    }
                }),
                this.payments,
                this.additionalPrices,
                this.selectedAgency?.id,
                this.selectedSeller?.id,
                this.observations));
        if (response.success && response.data) {
            this.closeModal();
            window.location.href = '/article-remittances';
        }
        this.finishButtonLoading = false;
    }

}

export default NewArticleDeliveryStore;