import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Promotion from "../../../entities/Promotion";
import {PromotionService} from "../../../service/PromotionService";
import Agency from "../../../entities/Agency";
import { AgencyService } from "../../../service/AgencyService";

const ERROR_MAP = {
    email: ["ERROR_00_400_20", "ERROR_00_400_21", "ERROR_00_400_213"],
    phone: ["ERROR_00_400_24", "ERROR_00_400_212"],
    name: ["ERROR_00_400_22", "ERROR_00_400_23"],
    lastName: ["ERROR_00_400_25"],
    address: ["ERROR_00_400_27"],
    city: ["ERROR_00_400_28"],
    state: ["ERROR_00_400_29"],
    zipCode: ["ERROR_00_400_211"],
}

class NewPromotionStore {

    public promotionId?: string;
    public promotion: Promotion;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;

    public agencies: Agency[];

    // Errors validators
    public emailError?: string;
    public phoneError?: string;
    public nameError?: string;
    public lastNameError?: string;
    public addressError?: string;
    public cityError?: string;
    public stateError?: string;
    public zipCodeError?: string;

    public commonStore: CommonStore;
    public agencyId?: string;


    constructor(commonStore: CommonStore, promotionId?: string, agencyId?: string) {
        this.isEditing = !!promotionId;
        this.promotionId = promotionId;
        this.isModalOpen = false;
        this.promotion = {} as Promotion;
        this.loading = false;
        this.commonStore = commonStore;
        this.agencyId = agencyId;
        this.agencies = [];
        makeAutoObservable(this);
    }

    @action
    public async loadPromotion() {
        if (this.promotionId) {
            this.isEditing = true;
            const response = await PromotionService.find(this.promotionId!)
            if (response.success && response.data) {
                this.promotion = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async loadAgencies() {
        const response = await AgencyService.get(1, 1000);
        if (response.success && response.data) {
            this.agencies = response.data.data;
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadPromotion();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
        this.promotion = {} as Promotion;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        this.promotion.agencyId = this.agencyId;
        const serviceToCall = this.isEditing ? PromotionService.update : PromotionService.create;
        const response = await serviceToCall(this.promotion);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.emailError = undefined;
        this.phoneError = undefined;
        this.nameError = undefined;
        this.lastNameError = undefined;
        this.addressError = undefined;
        this.cityError = undefined;
        this.stateError = undefined;
        this.zipCodeError = undefined;
        if (error) {
            if (ERROR_MAP.email.includes(error)) {
                this.emailError = error;
            }
            if (ERROR_MAP.phone.includes(error)) {
                this.phoneError = error;
            }
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
            if (ERROR_MAP.lastName.includes(error)) {
                this.lastNameError = error;
            }
            if (ERROR_MAP.address.includes(error)) {
                this.addressError = error;
            }
            if (ERROR_MAP.city.includes(error)) {
                this.cityError = error;
            }
            if (ERROR_MAP.state.includes(error)) {
                this.stateError = error;
            }
            if (ERROR_MAP.zipCode.includes(error)) {
                this.zipCodeError = error;
            }
        }

    }
}

export default NewPromotionStore;