import Request from "../../model/Request";
import Response from "../../model/Response";
import { RestClient } from "../../util/RestClient";
import { Config } from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import ArticleDelivery from "../../entities/ArticleDelivery";
import ArticleDeliveryOrderRequest from "../../model/articleDelivery/ArticleDeliveryOrderRequest";
import ArticleDeliveryOrderResponse from "../../model/articleDelivery/ArticleDeliveryOrderResponse";
import Phone from "../../entities/Phone";
import ArticleDeliveryOrderWithDetailsResponse from "../../model/articleDelivery/ArticleDeliveryOrderWithDetailsResponse";

export module ArticleDeliveryService {
  export async function get(
    page: number = 1,
    pageSize: number = 15,
    from: number = 0,
    to: number = 0,
    filter?: string,
    deliveryPersonId?: string,
    status?: string[],
    geoId?: string,
    agencyId?: string
  ): Promise<Response<PagedResponse<ArticleDeliveryOrderResponse>>> {
    const url = Config.get("GET_ARTICLE_REMITTANCE_URL", {
      page,
      pageSize,
      from,
      to,
      filter,
      deliveryPersonId,
      status,
      geoId,
      agencyId,
    });
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const resultData = response.data?.data;
      const paginationData = response.data?.pagination;
      if (resultData && paginationData) {
        const data = resultData.map(
          (t: any) =>
            new ArticleDeliveryOrderResponse(
              t.id,
              t.orderNumber,
              t.agencyId,
              t.agencyName,
              t.clientId,
              t.clientName,
              t.clientPhone
                ? new Phone(
                    t.clientPhone.number,
                    t.clientPhone.countryCode,
                    t.clientPhone.phoneType
                  )
                : null,
              t.contactId,
              t.contactName,
              t.contactPhone
                ? new Phone(
                    t.contactPhone.number,
                    t.contactPhone.countryCode,
                    t.contactPhone.phoneType
                  )
                : null,
              t.userId,
              t.userName,
              t.deliveryPersonId,
              t.deliveryPersonName,
              t.totalToPay,
              t.status,
              t.createdAt,
              t.address,
              t.geo,
              t.articles,
              t.observations
            )
        );
        const pagination = new Pagination(
          paginationData.currentPage,
          paginationData.pageSize,
          paginationData.elementsCount,
          paginationData.pageCount
        );
        return new Response<PagedResponse<ArticleDeliveryOrderResponse>>(
          true,
          { data, pagination },
          response.error
        );
      }
    }
    return new Response<PagedResponse<ArticleDeliveryOrderResponse>>(
      false,
      undefined,
      response.error,
      response.status
    );
  }

  export async function find(
    id: string
  ): Promise<Response<ArticleDeliveryOrderWithDetailsResponse>> {
    const url = Config.get("FIND_ARTICLE_REMITTANCE_URL", { id });
    const request = new Request(url);
    const response = await RestClient.get(request);
    if (response.success) {
      const t = response.data;
      if (t) {
        const articleRemittance = new ArticleDeliveryOrderWithDetailsResponse(
          t.id,
          t.orderNumber,
          t.agencyId,
          t.clientId,
          t.clientName,
          t.contact,
          t.articles,
          t.createdAt,
          t.currentStatus,
          t.comments,
          t.difficultDelivery
        );
        return new Response<ArticleDeliveryOrderWithDetailsResponse>(
          true,
          articleRemittance,
          response.error
        );
      }
    }
    return new Response<ArticleDeliveryOrderWithDetailsResponse>(
      true,
      undefined,
      response.error,
      response.status
    );
  }

  export async function create(
    articleDeliveryOrder: ArticleDeliveryOrderRequest
  ): Promise<Response<any>> {
    const url = Config.get("CREATE_ARTICLE_REMITTANCE_URL");
    const request = new Request(url, articleDeliveryOrder);
    return await RestClient.post(request);
  }

  export async function update(
    client: ArticleDelivery
  ): Promise<Response<any>> {
    const url = Config.get("UPDATE_ARTICLE_REMITTANCE_URL", { id: client.id });
    const request = new Request(url, client);
    return await RestClient.put(request);
  }

  export async function setDeliveryPerson(articleDeliveryIds: string[], deliveryPersonId: string): Promise<Response<any>> {
    const url = Config.get("SET_ARTICLE_REMITTANCE_DELIVERY_PERSON_URL", {
        deliveryPersonId: deliveryPersonId
    });
    const request = new Request(url, articleDeliveryIds);
    return await RestClient.put(request);
}

export async function unsetDeliveryPerson(articleRemittanceId: string): Promise<Response<any>> {
    const url = Config.get("UNSET_ARTICLE_REMITTANCE_DELIVERY_PERSON_URL", {
        articleRemitanteId: articleRemittanceId
    });
    const request = new Request(url, {});
    return await RestClient.put(request);
}

export async function updateStatus(articleDeliveryId: string, status: string, comment: string): Promise<Response<any>> {
  const url = Config.get("UPDATE_ARTICLE_DELIVERY_ORDER_STATUS_URL", {
      articleDeliveryId: articleDeliveryId,
      status: status
  });
  const request = new Request(url, { comment: comment ? comment : '' });
  return await RestClient.put(request);
}

export async function updateBulkStatus(ordersIds: string[], status: string, comment: string): Promise<Response<any>> {
  const url = Config.get("UPDATE_ARTICLE_DELIVERY_ORDERS_STATUS_URL", {
      status: status
  });
  const request = new Request(url, { ordersIds, comment: comment ? comment : '' });
  return await RestClient.put(request);
}

export async function remove(articleDeliveryId: string): Promise<Response<any>> {
  const url = Config.get("REMOVE_ARTICLE_DELIVERY_ORDER_URL", {id: articleDeliveryId});
  const request = new Request(url);
  return await RestClient.remove(request);
}
}
