import TransactionStatus from "../../TransactionStatus";

class ShopProductStockTransaction {
    public id: string;
    public agencyId: string;
    public shopProductId: string;
    public shopProductName: string;
    public amount: number;
    public error?: string;
    public status: TransactionStatus;
    public createdAt: number;
    public amountBefore: number;
    public comment?: string;

    constructor(
        id: string,
        agencyId: string,
        shopProductId: string,
        shopProductName: string,
        amount: number,
        status: TransactionStatus,
        createdAt: number,
        amountBefore: number,
        comment?: string,
        error?: string
    ) {
        this.id = id;
        this.agencyId = agencyId;
        this.createdAt = createdAt;
        this.amount = amount;
        this.shopProductId = shopProductId;
        this.shopProductName = shopProductName;
        this.error = error;
        this.status = status;
        this.amountBefore = amountBefore;
        this.comment = comment;
    }

}

export default ShopProductStockTransaction;