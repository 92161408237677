import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import ClientContact from "../entities/ClientContact";
import SearchContactClientElementResponse from "../model/search/SearchContactClientElementResponse";
import SearchContactClientResponse from "../model/search/SearchContactClientResponse";
import Geo from "../entities/Geo";
import ContactBankCard from "../entities/ContactBankCard";

export module ClientContactService {
    export async function get(page: number = 1, pageSize: number = 15, clientId?: string, filter?: string, agencyId?: string): Promise<Response<PagedResponse<ClientContact>>> {
        const url = Config.get("GET_CLIENT_CONTACT_URL", {page, pageSize, filter, id: clientId, agencyId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const contactData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (contactData && paginationData) {
                const data = contactData.map((t: any) => new ClientContact(
                    t.id,
                    t.name,
                    t.lastName ?? '',
                    t.documentId,
                    t.clientId,
                    t.createdAt,
                    t.address,
                    t.difficultPerson,
                    t.geo && new Geo(t.geo.id, t.geo.name, t.geo.parentId, t.geo.level, t.geo.hasChildren, t.geo.parentInfo),
                    t.neighborhood,
                    t.homePhone,
                    t.mobilePhone,
                    t.secondMobilePhone,
                    t.email,
                    t.observation,
                    t.agencyId
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ClientContact>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ClientContact>>(false, undefined, response.error, response.status);
    }

    export async function search(filter?: string, clientId?: string): Promise<Response<SearchContactClientResponse>> {
        const url = Config.get("SEARCH_CLIENT_CONTACT_URL", {filter, clientId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const contactData = response.data;
            if (contactData) {
                const elements = contactData.elements.map((t: any) => new SearchContactClientElementResponse(t.id, t.name, t.email, t.clientId, t.clientName, '', t.clientEmail, t.clientPhone, t.homePhone, t.mobilePhone, t.agencyId));
                const data = new SearchContactClientResponse(elements, contactData.totalElements, contactData.allElementsIncluded);
                return new Response<SearchContactClientResponse>(true, data, response.error)
            }
        }
        return new Response<SearchContactClientResponse>(false, undefined, response.error, response.status);
    }

    export async function find(clientId: string, contactId: string): Promise<Response<ClientContact>> {
        const url = Config.get("FIND_CLIENT_CONTACT_URL", {id: clientId, contactId});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const client = new ClientContact(
                    t.id,
                    t.name,
                    t.lastName ?? '',
                    t.documentId,
                    t.clientId,
                    t.createdAt,
                    t.address,
                    t.difficultPerson,
                    t.geo && new Geo(t.geo.id, t.geo.name, t.geo.parentId, t.geo.level, t.geo.hasChildren, t.geo.parentInfo),
                    t.neighborhood,
                    t.homePhone,
                    t.mobilePhone,
                    t.secondMobilePhone,
                    t.email,
                    t.observation,
                    t.agencyId
                );
                return new Response<ClientContact>(true, client, response.error);
            }
        }
        return new Response<ClientContact>(true, undefined, response.error, response.status);
    }

    export async function create(contact: ClientContact, clientId: string): Promise<Response<any>> {
        const url = Config.get("CREATE_CLIENT_CONTACT_URL", {id: clientId});
        const {geo, ...rest} = contact;
        const request = new Request(url, {geoId: geo?.id, ...rest});
        return await RestClient.post(request);
    }

    export async function update(contact: ClientContact, clientId: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_CLIENT_CONTACT_URL", {id: clientId, contactId: contact.id});
        const {geo, ...rest} = contact;
        const request = new Request(url, {geoId: geo?.id, ...rest});
        return await RestClient.put(request);
    }

    export async function remove(contact: ClientContact, clientId: string): Promise<Response<any>> {
        const url = Config.get("REMOVE_CLIENT_CONTACT_URL", {id: clientId, contactId: contact.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function getBankCards(page: number = 1, pageSize: number = 15, contactId?: string, filter?: string): Promise<Response<PagedResponse<ContactBankCard>>> {
        const url = Config.get("GET_CONTACT_BANK_CARD_URL", {page, pageSize, filter, contactId: contactId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const contactData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (contactData && paginationData) {
                const data = contactData.map((t: any) => new ContactBankCard(
                    t.id,
                    t.contactId,
                    t.bank,
                    t.cardNumber
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<ContactBankCard>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<ContactBankCard>>(false, undefined, response.error, response.status);
    }

}