import Route from "./Route";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
// import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Dashboard from "../pages/Dashboard/Dashboard";
import Sellers from "../pages/Sellers/Sellers";
import Clients from "../pages/Clients/Clients";
import Agencies from "../pages/Agencies/Agency";
import DeliveryPerson from "../pages/DeliveryPerson/DeliveryPerson";
import Access from "../shared_components/Access/AllowAccess/Access";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import WidgetsIcon from '@mui/icons-material/Widgets';
import MoneyExchanges from "../pages/MoneyExchanges/MoneyExchanges";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LockIcon from '@mui/icons-material/Lock';
import MoneyServiceFees from "../pages/MoneyServiceFees/MoneyServiceFees";
import MoneyDelivery from "../pages/MoneyDelivery/MoneyDelivery";
// import Invoice from "../pages/Invoice/Invoice";
import SalePrices from "../pages/SalePrices/SalePrices";
import CustomCategory from "../pages/CustomCategory/CustomCategory";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryItem from "../pages/InventoryItem/InventoryItem";
import {Inventory} from "@mui/icons-material";
import PackageDelivery from "../pages/PackageDelivery/PackageDelivery";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import Topups from "../pages/Topups/Topups";
import AccessPage from "../pages/Access/Access";
import TopupPrices from "../pages/TopupPrices/TopupPrices";
import Articles from "../pages/Articles/Articles";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SellIcon from '@mui/icons-material/Sell';
import ArticleDelivery from "../pages/ArticleDelivery/ArticleDelivery";
import ReferenceItemPrice from "../pages/ReferenceItemPrice/ReferenceItemPrice";
import Promotions from "../pages/Promotions/Promotions";
import CampaignIcon from '@mui/icons-material/Campaign';
import HomeIcon from '@mui/icons-material/Home';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import Home from "../pages/Home/Home";
import MiscellanyCategories from "../pages/MiscellanyCategory/MiscellanyCategories";
import MiscellanyOrder from "../pages/MiscellanyOrder/MiscellanyOrder";
import MoneyDeliveryOrderDetails from "../pages/ProductOrdersDetails/MoneyDeliveryOrderDetails/MoneyDeliveryOrderDetails";
import ShopProducts from "../pages/ShopProducts/ShopProducts";
import ShopOrder from "../pages/ShopOrder/ShopOrder";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

class RouteRepository {
    static getRoutes(): Route[] {
        return [
            new Route("/home", HomeIcon, "HOME", Home),
            new Route("/dashboard", DashboardIcon, "DASHBOARD", Dashboard),

            // new Route("/invoice", ReceiptIcon, "INVOICE", Invoice, [Access.LIST_ALL_INVOICES]),
            new Route("", ShoppingCartIcon, "SERVICES", MoneyDelivery, [], [
                new Route("/money-remittances", AttachMoneyIcon, "MONEY_REMITTANCES", MoneyDelivery, [Access.NONE]),
                new Route("/article-remittances", AttachMoneyIcon, "ARTICLE_REMITTANCES", ArticleDelivery, [Access.NONE]),
                new Route("/package-delivery", LocalShippingIcon, "PACKAGES_DELIVERY", PackageDelivery, [Access.LIST_PACKAGE_DELIVERY_ORDER]),
                new Route("/topups", SendToMobileIcon, "TOPUPS", Topups, [Access.NONE]),
                new Route("/miscellany-orders", DashboardCustomizeIcon, "MISCELLANY", MiscellanyOrder, [Access.NONE]),
                new Route("/shop-orders", ShoppingBagIcon, "SHOP", ShopOrder, [Access.NONE]),
            ]),

            new Route("", StorefrontIcon, "AGENCY", Agencies, [], [
                new Route("/agency", StorefrontIcon, "AGENCIES", Agencies, [Access.LIST_AGENCY]),
                new Route("/promotions", CampaignIcon, "PROMOTIONS", Promotions, [Access.LIST_PROMOTION]),
                new Route("/clients", AccountBoxSharpIcon, "CLIENTS", Clients),
                new Route("/sellers", PeopleAltIcon, "SELLERS", Sellers, [Access.LIST_USER]),
                new Route("/delivery-person", DeliveryDiningIcon, "DELIVERY_PERSON", DeliveryPerson, [Access.LIST_DELIVERY_PERSON]),
            ]),

            new Route("", AttachMoneyIcon, "MONEY_REMITTANCES_CONFIGURATIONS", MoneyDelivery, [], [
                new Route("/money-exchange", CurrencyExchangeIcon, "MONEY_EXCHANGES", MoneyExchanges, [Access.LIST_MONEY_DELIVERY_EXCHANGE_RATE]),
                new Route("/money-service-fees", PaidOutlinedIcon, "MONEY_SERVICE_FEES", MoneyServiceFees, [Access.LIST_MONEY_DELIVERY_FEE]),
            ]),

            new Route("", FastfoodIcon, "ARTICLES_CONFIGURATIONS", MoneyDelivery, [], [
                new Route("/articles", FastfoodIcon, "ARTICLES", Articles, [Access.LIST_TOPUP_PRICES])
            ]),

            new Route("", ShoppingCartIcon, "SHOP_PRODUCTS_CONFIGURATIONS", MoneyDelivery, [], [
                new Route("/shop-products", FastfoodIcon, "SHOP_PRODUCTS", ShopProducts)
            ]),

            new Route("", Inventory, "PACKAGES_CONFIGURATIONS", MoneyDelivery, [], [
                new Route("/custom-category", CategoryIcon, "CUSTOM_CATEGORY", CustomCategory, [Access.LIST_CUSTOM_CATEGORY]),
                new Route("/inventory-item", Inventory, "INVENTORY_ITEMS", InventoryItem, [Access.LIST_INVENTORY_ITEM]),
                new Route("/reference-item-price", Inventory, "REFERENCE_ITEMS", ReferenceItemPrice, [Access.LIST_REFERENCE_ITEM_PRICE]),
            ]),

            new Route("", SendToMobileIcon, "TOPUPS_CONFIGURATIONS", MoneyDelivery,  [], [
                new Route("/topup-prices", PaidOutlinedIcon, "TOPUP_PRICES", TopupPrices, [Access.LIST_TOPUP_PRICES]),
            ]),

            new Route("", DashboardCustomizeIcon, "MISCELLANY_CONFIGURATIONS", MoneyDelivery,  [], [
                new Route("/miscellany-categories", DashboardCustomizeIcon, "CATEGORIES", MiscellanyCategories, [Access.LIST_TOPUP_PRICES]),
            ]),

            new Route("", SellIcon, "SALE_PRICES", MoneyDelivery, [], [
                new Route("/sale-prices", WidgetsIcon, "SALE_PRICES", SalePrices, [Access.LIST_SALE_PRICE]),
            ]),

            new Route("/money-delivery-order-details/:id", AttachMoneyIcon, "MONEY_DELIVERY_ORDER_DETAILS", MoneyDeliveryOrderDetails, [Access.NONE], [], false),

        ];
    }

    static getAdminRoutes(): Route[] {
        return [
            new Route("", LockIcon, "ACCESS", AccessPage, [], [
                new Route("/access", LockIcon, "ADMIN_ACCESS", AccessPage, [Access.MANAGE_ACCESS])
            ])
        ];
    }

    static getAllRoutes(): Route[] {
        return [...this.getRoutes(), ...this.getAdminRoutes()];
    }

    static getRoute(path: string): Route | undefined {
        const routes = this.getAllRoutes();
        const allRoutes = routes.flatMap(t => [t, ...t.children || []]);
        return allRoutes.find(r => {
            const routeRegex = new RegExp(`^${r.path.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
            return routeRegex.test(path);
        });
    }
}

export default RouteRepository;