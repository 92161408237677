import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        backgroundColor: theme.palette.grey[300],
        flexGrow: 1,
        justifyContent: 'center',
        height: 'calc( 100vh - 250px)'
    }
}));

export {useStyles}