import React from "react";
import NewInventoryItemButtonProperties from "./NewInventoryItemButtonProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useStyles} from "./NewInventoryItemButton.style";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import NewInventoryItemButtonStore from "./NewInventoryItemButtonStore";
import useOnEnterPress from "../../../hooks/EnterPress";
import Icon from "../../../ui_components/Icon/Icon";
import IconName from "../../../ui_components/Icon/enums/IconName";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Button from "../../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import Modal from "../../../ui_components/Modal/Modal";
import CustomCategorySelect from "../../Selects/CustomCategorySelect/CustomCategorySelect";
import InventoryItemNameInput from "../../Inputs/InventoryItem/InventoryItemNameInput/InventoryItemNameInput";
import InventoryItemPriceSection from "../../Selects/InventoryItemPriceSection/InventoryItemPriceSection";

const NewInventoryItemButton:React.FC<NewInventoryItemButtonProperties> = ({
                                                                                               onClick = () => {},
                                                                                               onSave = () => {},
                                                                                               showIcon = true,
                                                                                               showText = true,
                                                                                               disabled,
                                                                                               className = '',
                                                                                               rounded = false,
                                                                                               size = ButtonSize.DEFAULT,
                                                                                               type = ButtonType.DEFAULT,
                                                                                               customButton = null,
                                                                                               inventoryItemId
}) => {

    const classes = useStyles(showText);
    const i18n = useI18nContext();
    const commonContext = useCommonContext();

    const store = new NewInventoryItemButtonStore(commonContext,inventoryItemId)

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }
    const onCloseModal = () => {
        store.closeModal();
    }
    const onInventoryItemSave = async () => {
        const saved = await store.save()
        if(saved) {
            store.closeModal()
            if (onSave){
                onSave(store.inventoryItem)
            }
        }
    }


    const onCustomCategorySelectChange = async (categoryId?: string) => { await store.setSelectedCustomCategory(categoryId) }
    const onNameChange = (newName: string) => { store.inventoryItem.name = newName }
    const onPriceChange = (price:number)=>{ store.inventoryItem.price = price }


    useOnEnterPress(onInventoryItemSave,[store])

    const text = i18n.translate((store.isEditing ? "EDIT_INVENTORY_ITEM" : "NEW_INVENTORY_ITEM"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;

    const Btn = () => {
        if (customButton !=null){
            return <div className={classes.custom_button_container} onClick={handleOnclick}>{customButton}</div>;
        }
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }

    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal}
                       open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <CustomCategorySelect
                            fullWidth
                            className={classes.input}
                            selectedCustomCategory={store.selectedCustomCategory}
                            categories={store.customCategories}
                            onCustomCategoryChange={onCustomCategorySelectChange}/>
                        <InventoryItemNameInput
                            error={i18n.translate(store.nameError)}
                            fullWidth
                            className={classes.input}
                            required = {true}
                            value={store.inventoryItem?.name}
                            onChange={onNameChange}/>
                        <InventoryItemPriceSection
                                price={store.inventoryItem.price}
                                onPriceChange={onPriceChange}
                                fullWidth
                                className={classes.selector}/>
                        <div className={classes.btn_container}>
                            <Button
                                disabled={!store.enableButton}
                                loading={store.loading} className={classes.input} onClick={onInventoryItemSave}
                                type={ButtonType.SECONDARY}
                                text={i18n.translate("SAVE")}/>
                            <Button
                                loading={store.loading} className={classes.cancel_btn} onClick={onCloseModal}
                                type={ButtonType.DEFAULT}
                                text={i18n.translate("CANCEL")}/>
                        </div>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}
export default NewInventoryItemButton