class ContactBankCard {
    public id: string;
    public contactId: string;
    public bank: string;
    public cardNumber: string;


    constructor(id: string, contactId: string, bank: string, cardNumber: string) {
        this.id = id;
        this.bank = bank;
        this.cardNumber = cardNumber;
        this.contactId = contactId;
    }
}

export default ContactBankCard;