import React, {useEffect} from 'react';
import {useStyles} from "./Access.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import {Observer} from "mobx-react-lite";
import AccessStore from "./AccessStore";
import {useCommonContext} from "../../contexts/CommonContext";
import Select from "../../ui_components/Select/Select";
import SelectOption from "../../ui_components/Select/SelectOption";
import LockIcon from '@mui/icons-material/Lock';
import {Tooltip} from '@material-ui/core';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GetAccessResponse from "../../model/access/GetAccessResponse";
import ShowField from "../../shared_components/Info/ShowField/ShowField";


const AccessPage: React.FC = () => {
    const commonStore = useCommonContext();
    const store = new AccessStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        store.getAllAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRoleChange = (option: SelectOption) => {
        store.setSelectedRoleId(option.value);
    }
    const toggleAccess = (access: GetAccessResponse) => {
        store.toggleAccess(access.name);
    }


    return (
        <Observer>
            {() => (
                <div className={classes.access_page_container}>
                    <Box className={classes.toolbar_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <ShowField label={"Nuevo Acceso"} value={store?.newAccessValue}/>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        {/*<Button*/}
                        {/*    onClick={onAgencySearch}*/}
                        {/*    className={classes.search_btn}*/}
                        {/*    text={i18n.translate("SEARCH")}*/}
                        {/*/>*/}
                    </Box>
                    <Box width={Width.FULL_WIDTH} className={classes.body_container}>
                        <div className={classes.roles_container}>
                            <Select onChange={onRoleChange}
                                    elements={commonStore.roles.map(r => new SelectOption(r.name, r.id, r.description, LockIcon))}
                                    label={"Seleccione rol"}/>
                        </div>
                        <div className={classes.access_container}>
                            {store.roleAccess.map((a, i) => (
                                <Tooltip title={a.description}>
                                    <div className={classes.access} key={i}>
                                        <div> {a.name}</div>
                                        <div className={classes.tools} onClick={() => toggleAccess(a)}>
                                            <DeleteForeverIcon/>
                                        </div>
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                        <div className={classes.access_container}>
                            {store.accessNotInRole.map((a, i) => (
                                <Tooltip title={a.description}>
                                    <div className={classes.access_neg} key={i}>
                                        <div> {a.name}</div>
                                        <div className={classes.tools} onClick={() => toggleAccess(a)}>
                                            <AddBoxIcon/>
                                        </div>
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </Box>
                </div>
            )}
        </Observer>
    )
}

export default AccessPage;
