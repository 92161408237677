import {useStyles} from './SearchContactPanel.style';
import React, {createRef, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {Observer} from "mobx-react-lite";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Loading from "../../../../ui_components/PageLoading/Loading";
import ContactElementRow from "./row/ContactElementRow";
import SearchContactPanelProperties from "./SearchContactPanelProperties";
import {useI18nContext} from "../../../../contexts/I18nContext";
import ClientContact from '../../../../entities/ClientContact';
import Button from '../../../../ui_components/Button/Button';
import ButtonType from '../../../../ui_components/Button/ButtonType';


const SearchContactPanel: React.FC<SearchContactPanelProperties> = ({
                                                                      store,
                                                                      onContactSelect
                                                                  }) => {
    const i18n = useI18nContext();
    const classes = useStyles();

    const inputRef = createRef<HTMLInputElement>();
    const [filter, setFilter] = useState("");

    const closeContactSearch = () => {
        setFilter("");
    }

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setFilter(value);
    }

    const selectContact = (contact: ClientContact) => {
        store.setSelectedContact(contact);
    }

    const onContactSelected = () => {
        onContactSelect(store.selectedContact!!);
        // onContactSelect(contact);
    }

    let timeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
        const changeFilter = () => {
            store.setContactFilter(filter);
        };
        timeout.current = setTimeout(changeFilter, 800);
    }, [filter, store])

    return <Observer>
                {() => (
                    <div className={classes.search_container}>
                        <div className={classes.search_input_container}>
                            <div className={classes.search_input}>
                                <input
                                    value={filter}
                                    placeholder="Nombre"
                                    ref={inputRef}
                                    onChange={onFilterChange}/>
                            </div>
                            <div onClick={closeContactSearch}
                                className={clsx(classes.btn, classes.remove_btn)}>
                                <HighlightOffIcon/>
                                <span>{i18n.translate("CLOSE")}</span>
                            </div>
                        </div>
                        {store.loadingContacts &&
                        <div className={classes.options_body}>
                            <Loading className={classes.loading} fontSize={45}/>
                        </div>}

                        {!store.loadingContacts && <div className={classes.elements_container}>
                            {store.contacts.map((e, i) => (
                                <ContactElementRow
                                    key={i}
                                    element={e}
                                    selected={e.id === store.selectedContact?.id}
                                    onSelect={selectContact}
                                />
                            ))}
                        </div>}

                        {!store.loadingContacts && store.isContactsEmpty &&
                        <div className={classes.empty_container}>
                            <div>No hay contactos para el cliente!!!</div>
                            <div>Verifica si estás aplicando algún filtro</div>
                        </div>}

                        <Button
                            disabled={!store.enableButton}
                            loading={false} className={classes.input} onClick={onContactSelected}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SELECT")}/>
                    </div>
                )}
            </Observer>
}

export default SearchContactPanel;