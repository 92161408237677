import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    budget_container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    box_container: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridColumnGap: '10px',
        gridTemplateRows: '100px'
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        "& > div": {
            fontSize: '30px'
        }
    },
    overview_container: {
        display: 'flex',
        marginTop: '20px',
        background: theme.palette.common.white,
        padding: '10px',
        borderRadius: '10px'
    },
    no_content: {
        display: 'flex',
        flexGrow: 1
    }
}));

export {useStyles};
