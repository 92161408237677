import React from 'react';
import PayInvoiceButtonProperties from "./PayInvoiceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import PayInvoiceButtonStore from "./PayInvoiceButtonStore";
import PaidIcon from '@mui/icons-material/Paid';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./PayInvoiceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ShowDate from "../../Info/ShowDate/ShowDate";
import ShowClientContact from "../../Info/ShowCientContact/ShowClientContact";
import ShowPrice from "../../Info/ShowPrice/ShowPrice";
import ShowServiceName from "../../Info/ShowServiceName/ShowServiceName";
import PriceInput from "../../Inputs/PriceInput/PricetInput";
import PaymentTypeSelect from "../../Selects/PaymentTypeSelect/PaymentTypeSelect";
import ShowPayment from "../../Info/ShowPayment/ShowPayment";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import Alert from "../../../ui_components/Alert/Alert";
import AlertType from "../../../ui_components/Alert/AlertType";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import AddPaymentRequest from "../../../model/payment/AddPaymentRequest";
import GlobalButton from "../../../ui_components/GlobalButton/GlobalButton";
import PlayCircleFilledSharpIcon from '@mui/icons-material/PlayCircleFilledSharp';
import PaymentTypeResponse from "../../../model/payment/PaymentTypeResponse";
import PaymentType from "../../../entities/PaymentType";
import ShowField from "../../Info/ShowField/ShowField";
import { CurrencyModule } from '../../../util/Currency';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";


const PayInvoiceButton: React.FC<PayInvoiceButtonProperties> = ({
                                                                    onClick = () => {
                                                                    },
                                                                    onSave = () => {
                                                                    },
                                                                    showIcon = true,
                                                                    showText = true,
                                                                    disabled,
                                                                    className = '',
                                                                    productOrderId,
                                                                    rounded = false,
                                                                    size = ButtonSize.DEFAULT,
                                                                    type = ButtonType.DEFAULT
                                                                }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new PayInvoiceButtonStore(commonContext, productOrderId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const addPayment = () => {
        store.addPayment();
    }

    const removePayment = (payment: AddPaymentRequest) => {
        store.removePayment(payment);
    }

    const onPaymentTypeChange = (paymentTypeResponse?: PaymentTypeResponse) => {
        store.selectedPaymentType = paymentTypeResponse || new PaymentTypeResponse(0, PaymentType.CASH, false);
    }

    const save = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const Icon = PaidIcon;
    const text = `${i18n.translate("PAY_INVOICE")}`;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading}  title={`${i18n.translate("PAY_INVOICE")}: ${store.invoice?.number}`}
                       onClose={onCloseModal} open={store.isModalOpen}
                       className={classes.modal_container}>
                    <div className={classes.payment_container}>
                        {store.invoice &&
                        <div className={classes.header}>
                            <div>
                                <ShowPrice value={store.invoice.totalToPay}/>
                                <ShowServiceName service={store.invoice?.service}/>
                            </div>
                            <ShowDate timestamp={store.invoice.createdAt}/>
                            <ShowClientContact clientName={store.invoice?.clientName}
                                               contactName={store.invoice?.contactName}/>
                        </div>}
                        <div className={classes.body}>
                            <div className={classes.inputs_container}>
                                <PriceInput error={i18n.translate(store.error)} value={store.selectedPrice}
                                            onChange={(price) => {
                                                store.selectedPrice = price;
                                            }}/>
                                <PaymentTypeSelect selected={store.selectedPaymentType.paymentType} onChange={onPaymentTypeChange}/>
                                <Button disabled={!store.canAddMorePayments} onClick={addPayment}
                                        type={ButtonType.SECONDARY}
                                        text={i18n.translate("ADD_PAYMENT")}/>
                            </div>
                            <div className={classes.elements_container}>
                                <ShowField label={i18n.translate("INVOICE_AMOUNT")} value={CurrencyModule.format(store.invoice?.totalToPay || 0)}/>
                                <ShowField label={i18n.translate("TOTAL_TAXES")} value={CurrencyModule.format(store.getFeesSum)}/>
                                <ShowField label={i18n.translate("TOTAL_TO_PAY")} value={CurrencyModule.format(store.getTotalToPay)}/>
                            </div>
                            {!store.isEmpty && <div className={classes.elements_container}>
                                {store.payments.map((payment, i) => (
                                    <div key={i} className={classes.element_row}>
                                        <div className={classes.remove_button}>
                                            <Button type={ButtonType.SECONDARY} size={ButtonSize.SMALL}
                                                    startIcon={<DeleteForeverSharpIcon/>} onClick={() => {
                                                removePayment(payment)
                                            }}/>
                                        </div>
                                        <ShowPayment amount={payment.amount} paymentType={payment.paymentTypeResponse.paymentType} extraFee={payment.getExtraFee}/>
                                    </div>
                                ))}
                            </div>}
                            {store.isEmpty &&
                            <MessageCard icon={PaidIcon} title={i18n.translate("NO_PAYMENTS_TITLE")}
                                         subtitle={i18n.translate("NO_PAYMENT_SUBTITLE")}/>}
                            {store.canAddMorePayments && !store.error && <Alert
                                text={`${i18n.translate("REMAINING_PAYMENT")}: ${CurrencyModule.format(store.getRemaining)}`}
                                type={AlertType.INFO}/>}
                            {!store.canAddMorePayments && !store.error && <Alert
                                text={`${i18n.translate("ALL_PAYMENTS_ADDED")}`}
                                type={AlertType.SUCCESS}/>}
                        </div>
                        <GlobalButton
                            loading={store.loading}
                            type={ButtonType.PRIMARY}
                            startIcon={<PlayCircleFilledSharpIcon/>}
                            visible={!store.canAddMorePayments}
                            text={i18n.translate("SAVE")}
                            onClick={save}/>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default PayInvoiceButton;
