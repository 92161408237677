import React from 'react';
import clsx from "clsx";
import ShowArticleCostsButtonRowProperties from './ShowArticleCostsButtonRowProperties';
import { useStyles } from '../ShowArticleCostsButton.style';
import ShowText from '../../../Info/ShowText/ShowText';
import Article from '../../../../entities/Article';

const ShowArticleCostsButtonRowSmall: React.FC<ShowArticleCostsButtonRowProperties> = ({articlePrice, articles}) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.show_article_price_info_container_small)}>
            <ShowText text={articles.find((t: Article) => t.id === articlePrice.articleId)?.name ?? ''}/>
            <ShowText text={articlePrice.salePrice ? '$' + articlePrice.salePrice.toString() : '-'} title={'Valor de costo'}/>          
        </div>
    )
}

export default ShowArticleCostsButtonRowSmall;
