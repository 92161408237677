import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.grey[200],
        flexGrow: 1,
        height: 'calc(100vh - 250px)'
    },
    topup_container:{
        display: 'grid',
        rowGap: '16px',
        padding: '16px',
    },
    topup_row:{
        display: 'grid',
        gridTemplateColumns: '150px 280px 250px auto',
        columnGap: '16px',
        padding: '16px',
        backgroundColor: theme.palette.common.white,
        "@media (max-width: 800px)": {
            display: 'flex'
        }
    }
}));

export {useStyles}