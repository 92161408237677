const values = {
    "SALE_PRICES": "Precios de venta",
    "SALE_PRICE": "Precio de venta",
    "NO_SALE_PRICES_TITLE": "No hay precios de venta!!!",
    "NO_SALE_PRICES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "NEW_SALE_PRICE_LIST": "Nueva lista de precios",
    "EDIT_SALE_PRICE_LIST": "Editar lista de precio",
    "SELECT_SALE_PRICE": "Seleccione la lista de precios de venta",
    "SELECT_SALE_PRICE_PLACEHOLDER": "Seleccione precios de venta",
    "REMOVE_SALE_PRICE": "Eliminar",
    "REMOVE_SALE_PRICE_CONFIRMATION": "¿Está seguro que desea eliminar los precios de venta?",
    "COST_VALUE": "Valor de costo",
}

export default values;