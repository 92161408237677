import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button_container: {
        display: "flex",
        alignItems: 'center',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '4px',
        cursor: 'pointer',
        boxSizing: 'border-box',
    },
    full_width: {
        width: '100%',
    },
    text: {
        display: 'flex',
        userSelect: 'none',
        flexGrow: 1,
        textTransform: 'uppercase',
        justifyContent: 'center',
        "& + $end_icon": {
            marginRight: '4px'
        }
    },
    small: {
        padding: '4px',
        fontSize: '14px',
        "& svg": {
            fontSize: '15px',
        }
    },
    medium: {
        padding: '6px 8px',
        fontSize: '15px',
        "& svg": {
            fontSize: '19px',
        }
    },
    large: {
        padding: '8px 16px',
        fontSize: '16px',
        "& svg": {
            fontSize: '22px',
        }
    },
    default: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[800],
        "&:hover": {
            backgroundColor: theme.palette.grey[300],
        },
        "& *": {
            color: theme.palette.grey[800],
            fill: theme.palette.grey[800],
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
        "& *": {
            color: theme.palette.common.white,
            fill: theme.palette.common.white,
        }
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
        "& *": {
            color: theme.palette.common.white,
            fill: theme.palette.common.white,
        }
    },
    start_icon: {
        display: 'flex',
        "& + $text": {
            marginLeft: '4px'
        }
    },
    end_icon: {
        display: 'flex'
    },
    disabled: {
        cursor: 'default',
        backgroundColor: `${theme.palette.grey[200]} !important`,
        color: theme.palette.grey[400],
        "&:hover": {
            backgroundColor: theme.palette.grey[200],
        },
        "& *": {
            color: theme.palette.grey[400],
            fill: theme.palette.grey[400],
        }
    },

}));

export {useStyles}