import React from 'react';
import SellerRowProperties from "./SellerRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import SellerRowMedium from "./SellerRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import SellerRowSmall from "./SellerRowSmall";


const SellerRow: React.FC<SellerRowProperties> = ({
                                                      seller,
                                                      onUpdate
                                                  }) => {
    return (
        <>
            <ViewSmall>
                <SellerRowSmall onUpdate={onUpdate} seller={seller}/>
            </ViewSmall>
            <ViewMedium>
                <SellerRowMedium onUpdate={onUpdate}  seller={seller} />
            </ViewMedium>
            <ViewLarge>
                <SellerRowMedium onUpdate={onUpdate} seller={seller} />
            </ViewLarge>
        </>
    )
}

export default SellerRow;
