import {action, makeAutoObservable} from "mobx";
import ContactBankCard from "../../../entities/ContactBankCard";
import { ContactBankCardService } from "../../../service/ContactBankCardService";
import CommonStore from "../../../stores/CommonStore";


class RemoveContactBankCardStore {

    public contactBankCard: ContactBankCard;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, contactBankCard: ContactBankCard) {
        this.contactBankCard = contactBankCard;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await ContactBankCardService.remove(this.contactBankCard);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default RemoveContactBankCardStore;