import React from 'react';
import ShowMapWithMarkersProperties from './ShowMapWithMarkersProperties';

import GoogleMapReact from 'google-map-react';
import ShowDeliveryPersonMarker from './ShowDeliveryPersonMarker/ShowDeliveryPersonMarker';
import { latLgns } from '../../../util/GeoLatLgn';
import ShowHouseMarker from './ShowHouseMarker/ShowHouseMarker';
import { useStyles } from './ShowMapWithMarkers.style';

const ShowMapWithMarkers: React.FC<ShowMapWithMarkersProperties> = ({ deliveryPersonGeoId, contactGeoId }) => {
   
  const classes = useStyles();
  
  const defaultProps = {
    center: {
      lat: 21.2525927,
      lng: -81.5903987
    },
    zoom: 6
  };

  const latLgnsList = latLgns;

  const deliveryPersonLatLgn = latLgnsList.find(t => t.id.toString() === deliveryPersonGeoId );
  const contactLatLgn = latLgnsList.find(t => t.id.toString() === contactGeoId );

  return (
    <div>
        <div style={{ height: '195px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyApg9-qEYnXFkk5YhVHUhgU_uDHVDtMq7M" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <ShowHouseMarker
              lat={contactLatLgn?.lat}
              lng={contactLatLgn?.lng}
              alt=''
              className={deliveryPersonGeoId === contactGeoId ? classes.same_geo : ''}/>
            {deliveryPersonGeoId && <ShowDeliveryPersonMarker
              lat={deliveryPersonLatLgn?.lat}
              lng={deliveryPersonLatLgn?.lng}
              alt=''/>}
          </GoogleMapReact>
        </div>
    </div>
  );
};

export default ShowMapWithMarkers;