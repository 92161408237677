import React from 'react';
import ShowTopupCostsButtonRowProperties from './ShowTopupCostsButtonRowProperties';
import ViewSmall from '../../../../ui_components/MediaView/ViewSmall';
import ShowTopupCostsButtonRowSmall from './ShowTopupCostsButtonRowSmall';
import ViewMedium from '../../../../ui_components/MediaView/ViewMedium';
import ViewLarge from '../../../../ui_components/MediaView/ViewLarge';
import ShowTopupCostsButtonRowMedium from './ShowTopupCostsButtonRowMedium';


const ShowTopupCostsButtonRow: React.FC<ShowTopupCostsButtonRowProperties> = ({
                                                    topupPrice,
                                                    products
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ShowTopupCostsButtonRowSmall topupPrice={topupPrice} products={products}/>
            </ViewSmall>
            <ViewMedium>
                <ShowTopupCostsButtonRowMedium topupPrice={topupPrice} products={products} />
            </ViewMedium>
            <ViewLarge>
                <ShowTopupCostsButtonRowMedium topupPrice={topupPrice} products={products} />
            </ViewLarge>
        </>
    )
}

export default ShowTopupCostsButtonRow;
