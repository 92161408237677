import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    payment_type_info_container: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box'
    },
    icon: {
        fontSize: '30px',
        color: theme.palette.grey[800],
        fill: theme.palette.grey[800],
        height: '40px',
        width: '40px',
    },
    info:{
        lineHeight: '15px',
        "& > :first-child":{
            fontWeight: '800'
        }
    },
    fee:{
        fontSize: '14px',
        color: theme.palette.error.light
    }
}));

export {useStyles}