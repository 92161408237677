import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { ProductOrderService } from "../../../service/ProductOrderService";

class ObservationsStore {

    public deliveryOrderId?: string;
    public deliveryOrderObservations: string;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore, observations: string, deliveryOrderId?: string) {
        this.deliveryOrderId = deliveryOrderId;
        this.isModalOpen = false;
        this.deliveryOrderObservations = observations;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async updateObservations() {
        this.loading = true;
        const response = await ProductOrderService.updateObservations(this.deliveryOrderId!, this.deliveryOrderObservations);
        if (response.success && response.data) {
            // TODO: Revisar porque cuando llamo el showSuccessToast se queda la lista de envios vacia.
            // this.commonStore.showSuccessToast('Observaciones actualizadas satisfactoriamente!!!')
            this.closeModal();
            return true;
        }
        this.loading = false;
        return false;
    }

    @action
    public async openModal() {
        this.loading = true;
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }
}

export default ObservationsStore;