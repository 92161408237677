import React from 'react';
import {useStyles} from "./ShowClientLocationInfo.style";
import ShowClientLocationInfoProperties from "./ShowClientLocationInfoProperties";
import clsx from "clsx";
import PlaceIcon from '@mui/icons-material/Place';

const ShowClientLocationInfo: React.FC<ShowClientLocationInfoProperties> = ({
                                                                          className = '',
                                                                          address,
                                                                          city,
                                                                          state,
                                                                          zipCode
                                                                      }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.seller_info_container, className)}>
            <div className={classes.location_icon}>
                <PlaceIcon/>
            </div>
            <div className={classes.info_container}>
                <div className={classes.name}>{address}</div>
                <div>{city}, {state}. {zipCode}</div>
            </div>
        </div>
    )
}
export default ShowClientLocationInfo;