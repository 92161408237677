import React from 'react';
import {Observer} from "mobx-react-lite";
import ShowInvoiceAdditionalPrices from '../../../Info/ShowInvoiceAdditionalPrices/ShowInvoiceAdditionalPrices';
import ShowClientToContactInfo from '../../../Info/ShowClientToContactInfo/ShowClientToContactInfo';
import PaymentsTable from '../../../Info/PaymentsTable/PaymentsTable';
import OrderAdditionalPrice from '../../../../entities/OrderAdditionalPriceRequest';
import ShopOrderResumeProperties from './ShopOrderResumeProperties';
import { useStyles } from './ShopOrderResume.style';

const ShopOrderResume: React.FC<ShopOrderResumeProperties> = ({
                                                                    store
                                                                }) => {

    const classes = useStyles();

    return <Observer>
        {() => (
            <div className={classes.container}>
                {store.selectedClient && <ShowClientToContactInfo
                    client={store.selectedClient}/>}
                
                {/* {store.selectedProducts.length > 0 &&
                    <ShowShopOrderDetails products={store.selectedArticles} selectedArticlesPrices={store.selectedArticlesPrices}/>    
                } */}

                {store.additionalPrices.length > 0 &&
                    <ShowInvoiceAdditionalPrices additionalPrices={store.additionalPrices.map((p) => {
                        return new OrderAdditionalPrice(p.amount, p.comment, p.isPercentage)
                    })}></ShowInvoiceAdditionalPrices>}

                {store.payments.length > 0 &&
                    <PaymentsTable payments={store.payments}/>}
                
            </div>
        )}
    </Observer>
}

export default ShopOrderResume;
