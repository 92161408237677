import React from "react";
import Access from "../shared_components/Access/AllowAccess/Access";

class Route {
    public path: string;
    public icon: React.ComponentType;
    public label: string;
    public component: React.ComponentType;
    public access?: Access[];
    public children?: Route[];
    public showInMenu?: boolean;

    constructor(
        path: string,
        icon: React.ComponentType,
        label: string,
        component: React.ComponentType,
        access?: Access[],
        children?: Route[],
        showInMenu: boolean = true
    ) {
        this.path = path;
        this.icon = icon;
        this.label = label;
        this.component = component;
        this.access = access;
        this.children = children;
        this.showInMenu = showInMenu;
    }

    public isActive(path: string): boolean {
        return this.path === path;
    }

}

export default Route;