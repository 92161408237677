import React from 'react';
import {useStyles} from "./Dashboard.style";
import {useI18nContext} from "../../contexts/I18nContext";
import {Observer} from "mobx-react-lite";
import DateTimeRangeSelect from "../../ui_components/DateTimeRageSelect/DateTimeRangeSelect";
import {RangeName} from "../../ui_components/DateTimeRageSelect/CustomRangeRepository";
import DashboardStore from "./DashboardStore";
import {useCommonContext} from "../../contexts/CommonContext";
import TabOption from "../../ui_components/Tab/TabOption";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Tabs from "../../ui_components/Tab/Tabs";
import GeneralTab from "./tabs/GeneralTab/GeneralTab";
import MySalesTab from "./tabs/MySalesTab/MySalesTab";
import AgencySalesTab from "./tabs/AgencySalesTab/AgencySalesTab";
import {useUserContext} from "../../contexts/UserContext";
import Access from "../../shared_components/Access/AllowAccess/Access";

const Dashboard: React.FC = () => {
    const selectedRange = RangeName.THIS_WEEK;
    const commonStore = useCommonContext();
    const userStore = useUserContext();
    const i18n = useI18nContext();
    const store = new DashboardStore(commonStore, i18n, selectedRange);
    const classes = useStyles();
    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.from = from;
        store.to = to;
    }

    return (
        <Observer>{() => {
            const tabsContent = [
                new TabOption("Mis Ventas", <MySalesTab from={store.from} to={store.to}/>, <NewspaperIcon/>),

                // new TabOption("Recargas", <TopupTab/>, <SendToMobileIcon/>),
                // new TabOption("Entregas de articulos", <ArticleDeliveryTab/>, <FastfoodIcon/>),
                // new TabOption("Envios de paquetes", <PackageDeliveryTab/>, <Inventory/>)
            ];
            if (userStore.hasAccess(Access.GET_AGENCY_SALES)) {
                tabsContent.push(new TabOption("Ventas de mi agencia", <AgencySalesTab from={store.from}
                                                                                       to={store.to}/>,
                    <NewspaperIcon/>))
            }
            if (userStore.hasAccess(Access.GET_GENERAL_SALES)) {
                tabsContent.push(new TabOption("Ventas generales", <GeneralTab from={store.from} to={store.to}/>,
                    <NewspaperIcon/>))
            }
            return (
                <div className={classes.dashboard_container}>
                    <div className={classes.tools_container}>
                        <DateTimeRangeSelect selectedRange={selectedRange} label={i18n.translate("DATE")}
                                             onChange={onDateChange}/>
                    </div>
                    <div className={classes.box_container}>
                        <Tabs content={tabsContent}/>
                    </div>
                </div>
            )
        }}
        </Observer>
    )
}

export default Dashboard;
