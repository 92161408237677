import React from 'react';
import ViewShopProductStockTransactionsStore from "./ViewShopProductStockTransactionsStore";
import ListAltIcon from '@mui/icons-material/ListAlt';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ViewShopProductStockTransactionsButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import ShopProductTransactions from './ShopProductTransactions/ShopProductTransactions';
import ViewShopProductStockTransactionsButtonProperties from './ViewShopProductStockTransactionsProperties';


const ViewShopProductStockTransactionsButton: React.FC<ViewShopProductStockTransactionsButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSave = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                shopProductId,
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT
                                                                            }) => {
    const classes = useStyles(showText);

    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new ViewShopProductStockTransactionsStore(commonContext, shopProductId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const text = 'Inventario';
    const Icon = ListAltIcon;

    if (!userStore.hasAccess(Access.LIST_SHOP_PRODUCT_STOCK_TRANSACTIONS.valueOf())) {
        return <></>;
    }

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <ShopProductTransactions store={store}/>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ViewShopProductStockTransactionsButton;
