import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";

const ERROR_MAP = {
    name: ["ERROR_00_400_33"],
}

class NewMiscellanyCategoryStore {

    public miscellanyCategoryId?: string;
    public miscellanyCategory: MiscellanyCategory;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public nameError?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, miscellanyCategoryId?: string) {
        this.isEditing = !!miscellanyCategoryId;
        this.miscellanyCategoryId = miscellanyCategoryId;
        this.isModalOpen = false;
        this.miscellanyCategory = {} as MiscellanyCategory;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.miscellanyCategory?.name);
    }

    @action
    public async loadMiscellanyCategory() {
        if (this.miscellanyCategoryId) {
            this.isEditing = true;
            const response = await MiscellanyCategoryService.find(this.miscellanyCategoryId!)
            if (response.success && response.data) {
                this.miscellanyCategory = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadMiscellanyCategory();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? MiscellanyCategoryService.update : MiscellanyCategoryService.create;
        const response = await serviceToCall(this.miscellanyCategory);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.nameError = undefined;
        if (error) {
            if (ERROR_MAP.name.includes(error)) {
                this.nameError = error;
            }
        }

    }
}

export default NewMiscellanyCategoryStore;