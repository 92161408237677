import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";
import MoneySalePrice from "../../../entities/productOrder/moneyDelivery/MoneySalePrice";
import MoneyDeliveryFee from "../../../entities/productOrder/moneyDelivery/MoneyDeliveryFee";
import { MoneyServiceFeeService } from "../../../service/moneyDelivery/MoneyServiceFeeService";

class ShowMoneyDeliveryCostsStore {
    public moneyDeliveryPrices: MoneySalePrice[];
    public moneyDeliveryFees: MoneyDeliveryFee[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.moneyDeliveryPrices = [];
        this.moneyDeliveryFees = [];
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get isEmpty(): boolean {
        return this.moneyDeliveryFees.length === 0;
    }

    @action
    public async getMoneyServiceFees() {
        const response = await MoneyServiceFeeService.getAllFromBaseAgency();
        if (response.success && response.data) {
            this.moneyDeliveryFees = response.data;
        }
    }

    @action
    public async loadMoneyDeliverySalePrices() {
        const response = await SalePriceService.getSalePriceFromAgency();
        if (response.success && response.data) {
            this.moneyDeliveryPrices = response.data.moneySalePrices;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadMoneyDeliverySalePrices();
        await this.getMoneyServiceFees();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

}

export default ShowMoneyDeliveryCostsStore;