import React, {useEffect, useState} from 'react';
import CommentIcon from '@material-ui/icons/Comment';
import CommentInputProperties from "./CommentInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import Textarea from "../../../ui_components/Textarea/Textarea";

const CommentInput: React.FC<CommentInputProperties> = ({
                                                            value,
                                                            onChange,
                                                            lines = 2,
                                                            disabled,
                                                            fullWidth = false,
                                                            className = '',
                                                        }) => {

    const [comment, setComment] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setComment(value);
    }, [value]);

    const onCommentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newComment = event.target.value;
        setComment(newComment);
        onChange(newComment);
    }

    return (
        <Textarea
            label={i18n.translate("COMMENT")}
            value={comment}
            lines={lines}
            disabled={disabled}
            onChange={onCommentChange}
            startIcon={CommentIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default CommentInput;