import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    clients_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    clients_table: {
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container: {},
    info_container_header: {
        position: 'relative',
        marginTop: '4px',
        paddingLeft: '15px',
        display: 'grid',
        gridTemplateColumns: '250px 200px auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8
    },
    client_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: '250px 200px auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    client_info_container_small: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        placeItems: 'stretch',
        rowGap: 16,
        columnGap: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
    },
    inactive: {
        borderLeft: `8px solid ${theme.palette.grey["300"]}`,
        backgroundColor: `${theme.palette.grey["300"]}8c`,
        opacity: '0.8'
    },
    menu_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    menu_container_small: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        "& > *": {
            marginLeft: '4px'
        }
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
        rowGap: 16,
        "& button": {
            justifySelf: "end"
        }
    },
    tools_container: {},
    search_btn: {
        justifySelf: 'flex-end'
    },
    service_name: {
        fontWeight: 'bold'
    },
    product_container: {
        padding: '6px 0',
        borderBottom: '1px solid #c5c5c5',
        borderTop: '1px solid #c5c5c5',
        margin: '15px'
    },
    product_value_container: {
        padding: '6px 0',
        borderBottom: '1px solid #c5c5c5',
        borderTop: '1px solid #c5c5c5',
        margin: '15px 0'
    },
    values_container: {
        display: 'grid',
        gap: '10px'
    }
}));

export {useStyles}