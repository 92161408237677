import Select from "../../../ui_components/Select/Select";
import React from 'react';
import {useI18nContext} from '../../../contexts/I18nContext';
import SelectOption from "../../../ui_components/Select/SelectOption";
import ContactBankCardTypeSelectProperties from './ContactBankCardTypeSelectProperties';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactBankCardType from "../../../entities/ContactBankCardType";

const ContactBankCardTypeSelect: React.FC<ContactBankCardTypeSelectProperties> = ({
                                                                      selected,
                                                                      onChange,
                                                                      disabled,
                                                                      fullWidth = false,
                                                                      className = ''
                                                                  }) => {

    const i18n = useI18nContext();

    const opt = [
        new SelectOption(i18n.translate("BPA"), "BPA", i18n.translate("BPA"), CreditCardIcon),
        new SelectOption(i18n.translate("METROPOLITANO"), "METROPOLITANO", i18n.translate("METROPOLITANO"), CreditCardIcon),
        new SelectOption(i18n.translate("BANDEC"), "BANDEC", i18n.translate("BANDEC"), CreditCardIcon)
    ]

    const selectedOption = opt.find(t => t.value === selected);

    const onStatusChange = (option: SelectOption) => {
        onChange(option.value as ContactBankCardType)
    }

    return (
        <Select
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={i18n.translate("CONTACT_BANK_CARD_FILTER")}
            placeholder={i18n.translate("CONTACT_BANK_CARD_FILTER_PLACEHOLDER")}
            selected={selectedOption}
            icon={CreditCardIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}

export default ContactBankCardTypeSelect;