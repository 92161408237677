import React from 'react';
import NewContactBankCardButtonProperties from "./NewContactBankCardButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewContactBankCardStore from "./NewContactBankCardStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewContactBankCardButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import CreditCardNumber from '../../Inputs/CreditCardNumberInput/CreditCardNumber';
import ContactBankCardTypeSelect from '../../Selects/ContactBankCardTypeSelect/ContactBankCardTypeSelect';
import ContactBankCardType from '../../../entities/ContactBankCardType';
import { ContactBankCardUtils } from '../../../util/ContactBankCardUtils';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';


const NewContactBankCardButton: React.FC<NewContactBankCardButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  contactBankCard,
                                                                  contactId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewContactBankCardStore(commonContext, contactId, contactBankCard?.id);

    const handleOnclick = async () => {
        if (!disabled) {
            await store.openModal();
            onClick();
        }
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onCardNumberChange = (newCardNumber: string) => {
        store.contactBankCard.cardNumber = newCardNumber
        store.contactBankCard.bank = ContactBankCardUtils.getBankFromCardNumber(store.contactBankCard.cardNumber) ?? store.contactBankCard.bank;
    }

    const onBankChange = (newBank: ContactBankCardType) => store.contactBankCard.bank = newBank;

    const onContactBankCardSave = async () => {
        const savedContactBanckCard = await store.save();
        if (savedContactBanckCard) {
            store.closeModal();
            if (onSave) {
                onSave(savedContactBanckCard);
            }
        }
    }

    const text = i18n.translate((store.isEditing ? "EDIT_CONTACT_BANK_CARD" : "NEW_CONTACT_BANK_CARD"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                            <CreditCardNumber error={i18n.translate(store.changeError)} fullWidth className={classes.input}
                                value={store.contactBankCard?.cardNumber}
                                onChange={onCardNumberChange}/>
                            <ContactBankCardTypeSelect selected={store.contactBankCard?.bank} fullWidth className={classes.input}
                                onChange={onBankChange}/>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onContactBankCardSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewContactBankCardButton;
