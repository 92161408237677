import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    alert_container: {
        borderRadius: '6px',
        padding: '8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        "& :last-child":{
            marginLeft: '8px'
        },
        "& svg":{
           fontSize: '30px'
        }
    },
    danger: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.common.white,
    },
    info: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
    },
    success: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.common.white,
    },
    warning: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.common.white,
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
    }
}));

export {useStyles}