import React from 'react';
import DeliveryCostButtonProperties from "./DeliveryCostButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import DeliveryCostButtonStore from "./DeliveryCostButtonStore";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./DeliveryCostButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ShowPagination from "../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import GeoSelect from '../../Selects/GeoSelect/GeoSelect';
import CurrencySelect from '../../Selects/CurrencySelect/CurrencySelect';
import ShowGeo from '../../Info/ShowGeo/ShowGeo';
import ShowPrice from '../../Info/ShowPrice/ShowPrice';
import RemoveDeliveryCostButton from "../RemoveDeliveryCostButton/RemoveDeliveryCostButton";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import NewDeliveryCostButton from "../NewDeliveryCostButton/NewDeliveryCostButton";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import ShowService from '../../Info/ShowService/ShowService';


const DeliveryCostButton: React.FC<DeliveryCostButtonProperties> = ({
                                                                        onClick = () => {
                                                                        },
                                                                        onClose = () => {
                                                                        },
                                                                        showIcon = true,
                                                                        showText = true,
                                                                        disabled,
                                                                        className = '',
                                                                        deliveryPerson,
                                                                        rounded = false,
                                                                        size = ButtonSize.DEFAULT,
                                                                        type = ButtonType.DEFAULT
                                                                    }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new DeliveryCostButtonStore(commonContext, deliveryPerson);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
        onClose();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onCostSaved = async () => {
        await store.cleanFilter()
    }


    const text = 'Comisiones';
    const title = `${text}: ${deliveryPerson.name}`;
    const Icon = AttachMoneyIcon;
    if (!userStore.hasAccess(Access.ADD_DELIVERY_COST.valueOf())) {
        return <></>;
    }
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={title} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <div className={classes.body}>
                            <Box
                                width={Width.FULL_WIDTH}
                                justifyContent={Alignment.SPACE_BETWEEN}>
                                <ShowSellerBasicInfo name={store.deliveryPerson.name}
                                                     email={store.deliveryPerson.email}
                                                     phone={store.deliveryPerson?.phone} autofill/>
                                <NewDeliveryCostButton
                                    type={ButtonType.SECONDARY}
                                    rounded
                                    onSave={onCostSaved}
                                    deliveryPersonId={store.deliveryPerson.id}/>
                            </Box>

                            <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                                 justifyContent={Alignment.SPACE_BETWEEN}>
                                <GeoSelect selected={store.geoFilter} onChange={(value) => {
                                    store.geoFilter = value
                                }}/>
                                <CurrencySelect showSelectAllOption={true} onChange={(value) => {
                                    store.currencyFilter = value
                                }}/>
                                <div className={classes.btn_container}>
                                    <Button
                                        onClick={() => {
                                            store.getPrices()
                                        }}
                                        className={classes.search_btn}
                                        text={i18n.translate("SEARCH")}
                                    />
                                    <Button
                                        onClick={() => {
                                            store.cleanFilter()
                                        }}
                                        className={classes.search_btn}
                                        startIcon={<FilterAltOffIcon/>}
                                        tooltip={i18n.translate("CLEAN_FILTER")}
                                    />
                                </div>
                            </Box>
                            {!store.isEmpty &&
                            <div className={classes.contacts_table}>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={'Comisiones'}
                                                pagination={store.pagination}/>
                                <div>
                                    {store.costs.map((cost, i) => (
                                        <div className={classes.cost_info_container}>
                                            <ShowGeo geo={cost.geo}/>
                                            {cost.cost > 0 && <ShowPrice value={cost.cost} currency={cost.currency}/>}
                                            {cost.cost === 0 &&
                                                <div className={classes.no_cost}>{i18n.translate("WITHOUT_COST")}</div>}
                                            <div>
                                                <div className={classes.label}>Servicio</div>
                                                <ShowService service={cost.service}/>
                                            </div>
                                            <ExpandoMenu>
                                                <>
                                                    <NewDeliveryCostButton
                                                        type={ButtonType.SECONDARY}
                                                        deliveryCostId={cost.id}
                                                        onSave={onCostSaved}
                                                        deliveryPersonId={store.deliveryPerson.id}/>
                                                    <RemoveDeliveryCostButton
                                                        deliveryCost={cost}
                                                        type={ButtonType.SECONDARY}
                                                        size={ButtonSize.SMALL}
                                                        onSave={() => {
                                                            store.getPrices()
                                                        }}
                                                    />
                                                </>
                                            </ExpandoMenu>
                                            {/* <div className={classes.menu_container}>
                                                
                                            </div> */}
                                        </div>
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={'Comisiones'}
                                    pagination={store.pagination}/>
                            </div>
                            }
                            {store.isEmpty &&
                            <MessageCard icon={AttachMoneyIcon} title={i18n.translate("NO_DELIVERY_COST_TITLE")}
                                         subtitle={i18n.translate("NO_DELIVERY_COST_SUBTITLE")}/>}
                        </div>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default DeliveryCostButton;
