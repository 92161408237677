import ArticlePrice from "../../entities/ArticlePrice";

class ArticleWithPriceResponse {
    public id: string;
    public name: string;
    public description: string;
    public defaultImage: string;
    public prices: ArticlePrice[];
    public amount: number;
    public createdAt: number;

    constructor(id: string, name: string, description: string, defaultImage: string, prices: ArticlePrice[], amount: number, createdAt: number) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.defaultImage = defaultImage;
        this.prices = prices;
        this.amount = amount;
        this.createdAt = createdAt;
    }

}

export default ArticleWithPriceResponse;