import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    contacts_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    body: {
        display: 'flex',
        padding: '8px 16px',
        flexDirection: 'column'
    },
    input: {
        marginTop: '16px'
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
        rowGap: 16,
        "& button": {
            justifySelf: "end"
        }
    },
    search_btn: {
        justifySelf: 'flex-end'
    },
    contacts_table:{
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container:{

    },
    contact_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: '250px 200px auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.secondary.light}5c`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    menu_container:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    create_price_container:{
        display: 'flex',
        flexDirection: 'column'
    },
    create_price_inputs_container:{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    create_price_button_container:{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    margin_right_5:{
        marginRight: '5px'
    },
    width_100p:{
        width: '100%'
    }

}));

export {useStyles}