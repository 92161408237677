import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";

class SalePriceOptionsStore {

    public salePriceId: string;
    public salePriceName: string;
    public salePriceDescription?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, salePriceId: string, salePriceName: string, salePriceDescription?: string) {
        this.salePriceId = salePriceId;
        this.salePriceName = salePriceName;
        this.salePriceDescription = salePriceDescription;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await SalePriceService.remove(this.salePriceId);
        this.commonStore.processErrors(response);
        return response.success;
    }

}

export default SalePriceOptionsStore;