import React from 'react';
import {useStyles} from "./Link.style";
import {useCommonStyles} from "../common/CommonStyles.style";
import LinkProperties from "./LinkProperties";
import clsx from "clsx";


const Link: React.FC<LinkProperties> = ({
                                            text,
                                            to,
                                            onClick,
                                            startIcon: StartIcon,
                                            endIcon: EndIcon,
                                            fullWidth = false,
                                            className = '',
                                        }: LinkProperties) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const containerClasses = clsx(
        className,
        classes.link_container,
        fullWidth && commonClasses.fullWidth);

    return (
        <div className={containerClasses}>
            {StartIcon &&
            <div className={classes.start_icon_container}>
                <StartIcon/>
            </div>}
            <a className={classes.anchor} href={to} onClick={onClick}>{text}</a>
            {EndIcon &&
            <div className={classes.end_icon_container}>
                <EndIcon/>
            </div>}

        </div>
    )
}
export default Link;