import React from 'react';
import ShowArticleCostsButtonProperties from "./ShowArticleCostsButtonProperties";
import ShowArticleCostsStore from "./ShowArticleCostsStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ShowArticleCostsButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShowArticleCostsButtonRow from './ShowArticleCostsButtonRow/ShowArticleCostsButtonRow';
import { useUserContext } from '../../../contexts/UserContext';


const ShowArticleCostsButton: React.FC<ShowArticleCostsButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);
    const commonContext = useCommonContext();
    const store = new ShowArticleCostsStore(commonContext);
    const userStore = useUserContext();

    if (userStore.isRootAgency()) {
        return <div></div>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const text = "Precios de costo";
    const btnIcon = <AttachMoneyIcon />;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        {!store.isEmpty &&
                        <div className={classes.show_article_price_table}>
                            {store.articlePrices.map((articlePrice, i) => (
                                <ShowArticleCostsButtonRow
                                    articlePrice={articlePrice} key={i}
                                    articles={store.articles}
                                />
                            ))}
                        </div>}
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ShowArticleCostsButton;
