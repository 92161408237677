import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import ContactBankCard from "../../../entities/ContactBankCard";
import {ContactBankCardService} from "../../../service/ContactBankCardService";
import {CreditCardNumberValidation} from "../../../util/CreditCardNumberValidation";

const ERROR_MAP = {
    change: ["ERROR_00_400_35", "ERROR_00_400_35"],
    currency: ["ERROR_00_400_37"]
}

class NewContactBankCardStore {

    public contactId: string;
    public contactBankCardId?: string;
    public contactBankCard: ContactBankCard;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public changeError?: string;
    public currencyError?: string;

    constructor(commonStore: CommonStore, contactId: string, contactBankCardId?: string) {
        this.isEditing = !!contactBankCardId;
        this.contactBankCardId = contactBankCardId;
        this.contactId = contactId;
        this.isModalOpen = false;
        this.contactBankCard = {} as ContactBankCard;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        const requireds = !!(this.contactBankCard?.cardNumber && this.contactBankCard?.bank);
        const cardNumberValid = CreditCardNumberValidation.validate(this.contactBankCard?.cardNumber);
        return requireds && cardNumberValid;
    }

    @action
    public async loadContactBankCard() {
        if (this.contactBankCardId) {
            this.isEditing = true;
            const response = await ContactBankCardService.find(this.contactId, this.contactBankCardId!)
            if (response.success && response.data) {
                this.contactBankCard = response.data;
                // this.currency = getEnum(response.data.currency);
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadContactBankCard();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<ContactBankCard | null> {
        this.loading = true;
        const serviceToCall = this.isEditing ? ContactBankCardService.update : ContactBankCardService.create;
        const response = await serviceToCall(this.contactBankCard, this.contactId);
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success ? response.data : null;
    }

    processError(error?: string) {
        this.changeError = undefined;
        this.currencyError = undefined;
        if (error) {
            if (ERROR_MAP.change.includes(error)) {
                this.changeError = error;
            }
            if (ERROR_MAP.currency.includes(error)) {
                this.currencyError = error;
            }
        }

    }
}

export default NewContactBankCardStore;