import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    side_menu_container: {
        backgroundColor: `${theme.palette.secondary.light}`,
        boxSizing: 'border-box',
        display: 'flex',
        zIndex: 2,
        justifyContent: "space-between",
        position: 'relative',
        padding: '8px',
    },
    menu_items_container_top: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.light,
        position: 'absolute',
        boxSizing: 'border-box',
        top: '55px',
        width: "242px",
        left: "-8px"
    },
    menu_items_container: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.light,
        position: 'absolute',
        flexDirection: "column",
        boxSizing: 'border-box',
        top: '51px',
        width: "250px",
        left: "-8px"
    },
    menu_item_title: {
        display: 'flex',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        alignItems: 'center',
        color: theme.palette.grey[300],
        fontSize: '16px',
        padding: '0 14px',
        height: '30px',
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.light,
            cursor: 'pointer',
        },
        "& svg": {
            fontSize: '20px',
            marginRight: '16px'
        },
    },
    title_with_children: {
        width: '100%',
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: 0,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.secondary.light,
            cursor: 'pointer'
        },
    },
    title_label_with_children: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textWrap: 'nowrap'
    },
    label_container: {
        paddingLeft: '26px'
    },
    menu_item_children: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px'
    },
    title_with_children_active: {
        fontWeight: 800,
        color: theme.palette.primary.main
    },
    menu_button_container: {
        position: 'relative',
        boxSizing: 'border-box',
    },
    menu_button: {
        cursor: 'pointer',
        borderRadius: '8px',
        boxSizing: 'border-box',
        border: `1px solid transparent`,
        "& svg": {
            fontSize: '40px',
            color: theme.palette.common.white,
        },
        "&:hover": {
            border: `1px solid ${theme.palette.common.white}`
        }
    },
    menu_item_container: {
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        padding: '0',
        color: theme.palette.secondary.light,
        fontSize: '20px',
        "&:hover": {
            backgroundColor: `${theme.palette.secondary.main}d1`,
            cursor: 'pointer',
            color: theme.palette.common.white
        },
        "& svg": {
            fontSize: '30px',
        },
        "& div": {
            flexGrow: 1
        }
    },
    active: {
        backgroundColor: `${theme.palette.primary.light}5c`,
        color: theme.palette.primary.main,
        fontWeight: 800
    },
    main_action_container: {
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        boxSizing: 'border-box'
    },
    user_info_container: {
        color: theme.palette.common.white,
        width: 'fit-content',
        flexWrap: 'nowrap',
        marginRight: '8px'
    }
}));

export {useStyles}