import React from 'react';
import {useStyles} from "../MoneyExchanges.style";
import clsx from "clsx";
import MoneyExchangeRowProperties from "./MoneyExchangeRowProperties";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewMoneyExchangeRateButton
    from '../../../shared_components/Buttons/NewMoneyExchangeRateButton/NewMoneyExchangeRateButton';
import ShowPrice from '../../../shared_components/Info/ShowPrice/ShowPrice';
import RemoveMoneyExchangeButton
    from '../../../shared_components/Buttons/RemoveMoneyExchangeButton/RemoveMoneyExchangeButton';
import AllowAccess from "../../../shared_components/Access/AllowAccess/AllowAccess";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";


const MoneyExchangeRowMedium: React.FC<MoneyExchangeRowProperties> = ({
                                                                          moneyExchange,
                                                                          onUpdate = () => {
                                                                          },
                                                                      }) => {

    const classes = useStyles();

    return (
        <div
            className={clsx(classes.money_exchange_info_container)}>
            <ShowPrice value={moneyExchange.value} currency={moneyExchange.currency}/>
            <AllowAccess isRootAgency>
                <ExpandoMenu>
                    <>
                        <NewMoneyExchangeRateButton
                            size={ButtonSize.SMALL}
                            onSave={onUpdate}
                            showText
                            moneyExchangeId={moneyExchange.id}
                            type={ButtonType.SECONDARY}
                        />
                        <RemoveMoneyExchangeButton
                            size={ButtonSize.SMALL}
                            onSave={onUpdate}
                            moneyExchange={moneyExchange}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </>
                </ExpandoMenu>
            </AllowAccess>
            
        </div>
    )
}

export default MoneyExchangeRowMedium;
