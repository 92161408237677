import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    show_confirmation_container: {
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        display: 'flex',
    },
    label:{
        fontSize: '12px',
        color: theme.palette.grey["600"],
        fontWeight: 800
    },
    icon: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.common.white,
        fontSize: '18px',
        padding: '2px 4px',
        borderRadius: "4px 0 0 4px",
    },
    value_container: {
        color: theme.palette.success.light,
        border: `1px solid ${theme.palette.success.light}`,
        padding: '0 8px',
        borderRadius: "0 4px 4px 0",
        fontSize: '14px',
    },
}));

export {useStyles}