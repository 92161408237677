import React from 'react';
import {useStyles} from "../MoneyServiceFees.style";
import clsx from "clsx";
import MoneyServiceFeeRowProperties from "./MoneyServiceFeeRowProperties";
import NewMoneyDeliveryFeeButton from '../../../shared_components/Buttons/NewMoneyDeliveryFeeButton/NewMoneyDeliveryFeeButton';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ShowInterval from '../../../shared_components/Info/ShowInterval/ShowInterval';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import { useI18nContext } from '../../../contexts/I18nContext';
import RemoveMoneyServiceFeeButton from '../../../shared_components/Buttons/RemoveMoneyServiceFeeButton/RemoveMoneyServiceFeeButton';


const MoneyServiceFeeRowSmall: React.FC<MoneyServiceFeeRowProperties> = ({moneyServiceFee, onUpdate}) => {

    const i18n = useI18nContext();
    const classes = useStyles();


    return (
        <div className={clsx(classes.foodPackage_info_container_small)}>
            <ShowInterval from={moneyServiceFee.fromValue} to={moneyServiceFee.toValue}/>
            <ShowText text={moneyServiceFee.amount.toString()} title={i18n.translate("MONEY_SERVICE_FEE")}/>
            
            <div className={classes.menu_container_small}>
                <NewMoneyDeliveryFeeButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        showText={false}
                        moneyServiceFeeId={moneyServiceFee.id}
                        type={ButtonType.SECONDARY}
                    />
                {<RemoveMoneyServiceFeeButton
                    size={ButtonSize.SMALL}
                    onSave={onUpdate}
                    moneyServiceFee={moneyServiceFee}
                    showText={false}
                    type={ButtonType.SECONDARY}
                /> }
            </div>
        </div>
    )
}

export default MoneyServiceFeeRowSmall;
