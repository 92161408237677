import React from 'react';
import {Observer} from "mobx-react-lite";
import {useStyles} from './Payment.style';
import AddPayment from "../../../Content/AddPayment/AddPayment";
import AddPaymentRequest from "../../../../model/payment/AddPaymentRequest";
import PaymentProperties from './PaymentProperties';

const Payment: React.FC<PaymentProperties> = ({
                                                  store
                                              }) => {

    const classes = useStyles();

    const onPaymentChange = (payments: AddPaymentRequest[], paymentCompleted: boolean) => {
        store.setPayments(payments, paymentCompleted);
    }

    return <Observer>
        {() => (
            <div className={classes.container}>
                <AddPayment currentPayments={store.payments} total={store.getTotalToPay()}
                            onPaymentsChange={onPaymentChange}/>
            </div>
        )}
    </Observer>
}

export default Payment;
