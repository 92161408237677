import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../Promotions.style";
import clsx from "clsx";
import PromotionRowProperties from "./PromotionRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import RemovePromotionButton from '../../../shared_components/Buttons/RemovePromotionButton/RemovePromotionButton';
import NewPromotionButton from '../../../shared_components/Buttons/NewPromotionButton/NewPromotionButton';
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ShowArticleDescription from '../../../shared_components/Info/ShowArticleDescription/ShowArticleDescription';
import ShowImage from '../../../shared_components/Info/ShowImage/ShowImage';
import UploadPromotionImageButton from '../../../shared_components/Buttons/UploadPromotionImageButton/UploadPromotionImageButton';


const PromotionRowMedium: React.FC<PromotionRowProperties> = ({
                                                            promotion,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const classes = useStyles();
    const i18n = useI18nContext();

    return (
        <div
            className={clsx(classes.promotion_info_container)}>
            <ShowImage imageName={promotion.defaultImage} width={70} height={70}/>
            <ShowText text={promotion.name} className={classes.article_name_container}/>
            <ShowArticleDescription description={promotion.description}/>
            <ShowDate label={'Inicio'} timestamp={promotion.startDate}/>
            <ShowDate label={'Fin'} timestamp={promotion.endDate}/>
            <ShowDate label={i18n.translate("CREATED")} timestamp={promotion.createdAt}/>
            <ExpandoMenu>
                <>
                    <NewPromotionButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        promotionId={promotion.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <UploadPromotionImageButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        promotionId={promotion.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <RemovePromotionButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        promotion={promotion}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default PromotionRowMedium;
