import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import Seller from "../entities/Seller";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";

export module SellerService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string, includeCurrent: boolean = false, agencyId?:string): Promise<Response<PagedResponse<Seller>>> {
        const url = Config.get("GET_SELLERS_URL", {page, pageSize, filter, includeCurrent, agencyId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const sellersData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (sellersData && paginationData) {
                const data = sellersData.map((t: any) => new Seller(t.id, t.name, t.email, t.createdAt, t.status, t.roles));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Seller>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Seller>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<Seller>> {
        const url = Config.get("FIND_SELLER_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const sellerData = response.data;
            if (sellerData) {
                const seller = new Seller(sellerData.id, sellerData.name, sellerData.email, sellerData.createdAt, sellerData.status, sellerData.roleIds);
                return new Response<Seller>(true, seller, response.error);
            }
        }
        return new Response<Seller>(true, undefined, response.error, response.status);
    }

    export async function create(seller: Seller): Promise<Response<any>> {
        const url = Config.get("CREATE_SELLER_URL");
        const request = new Request(url, seller);
        return await RestClient.post(request);
    }

    export async function update(seller: Seller): Promise<Response<any>> {
        const url = Config.get("UPDATE_SELLER_URL", {id: seller.id});
        const request = new Request(url, seller);
        return await RestClient.put(request);
    }

    export async function setActive(seller: Seller): Promise<Response<any>> {
        const url = Config.get("SET_SELLER_ACTIVE_URL", {id: seller.id});
        const request = new Request(url);
        return await RestClient.get(request);
    }

    export async function setInactive(seller: Seller): Promise<Response<any>> {
        const url = Config.get("SET_SELLER_INACTIVE_URL", {id: seller.id});
        const request = new Request(url);
        return await RestClient.get(request);
    }

    export async function remove(seller: Seller): Promise<Response<any>> {
        const url = Config.get("REMOVE_SELLER_URL", {id: seller.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function updatePassword(sellerId: string, password: string): Promise<Response<any>> {
        const url = Config.get("UPDATE_SELLER_PASSWORD_URL", {id: sellerId});
        const request = new Request(url, {password});
        return await RestClient.put(request);
    }

}