import CommonStore from "../../../stores/CommonStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {SalesService} from "../../../service/SalesService";
import SalesByServiceResponse from "../../../model/sales/SalesByServiceResponse";

class AgencyTotalSalesStore {

    public commonStore: CommonStore;
    public loading: boolean;
    public from?: number;
    public to?: number;
    public agencyId?: string;
    public userId?: string;
    public includeChildren?: boolean;
    public sales?: SalesByServiceResponse
    public totalSales?: number;

    constructor(
        commonStore: CommonStore,
        from?: number,
        to?: number,
        agencyId?: string,
        userId?: string,
        includeChildren?: boolean
    ) {
        this.commonStore = commonStore;
        this.loading = false;
        this.from = from;
        this.agencyId = agencyId;
        this.userId = userId;
        this.to = to;
        this.includeChildren = includeChildren;
        makeObservable(this, {
            loading: observable,
            from: observable,
            to: observable,
            sales: observable,
            totalSales: observable,
            includeChildren: observable,
        });
    }

    @action
    public async getElements(): Promise<void> {
        this.loading = true;
        const response = await SalesService.getSalesByService(this.from, this.to, this.agencyId, this.userId, this.includeChildren);
        if (response.success) {
            runInAction(() => {
                this.sales = response.data;
                this.totalSales = response.data?.breakdown.reduce((t, c) => t + c.total, 0) || 0
            })
        }
        this.loading = false;
        this.commonStore.processErrors(response);
    }
}

export default AgencyTotalSalesStore;