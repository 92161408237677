import React from 'react';
import ChooseDeliveryPersonLinks from '../../Buttons/ChooseDeliveryPersonLinks/ChooseDeliveryPersonLinks';
import {useStyles} from "./ShowDeliveryPerson.style";
import ShowDeliveryPersonProperties from "./ShowDeliveryPersonProperties";
import AllowAccess from "../../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../../shared_components/Access/AllowAccess/Access";
import { useUserContext } from '../../../contexts/UserContext';

const ShowDeliveryPerson: React.FC<ShowDeliveryPersonProperties> = ({
                                                          text,
                                                          showActionLinks = true,
                                                          orderByGeosIdProximity = [],
                                                          className = '',
                                                          onClick = () => {
                                                          },
                                                          onSelect = () => {
                                                          }
                                                      }) => {
    const classes = useStyles();
    const userStore = useUserContext();

    if (!userStore.isRootAgency()) {
        return <></>;
    }

    return (
        <div className={className}>
            <>
                {<div className={classes.title}>{`Repartidor:`}</div>}
                <div>{text ? text : '-'}</div>
                {showActionLinks && <AllowAccess any={[Access.SET_MONEY_DELIVERY_DELIVERY_PERSON]}>
                    <ChooseDeliveryPersonLinks
                        haveDeliveryPersonSelected={text != null}
                        orderByGeosIdProximity={orderByGeosIdProximity}
                        onClick={onClick}
                        onSelect={onSelect}>
                    </ChooseDeliveryPersonLinks>
                </AllowAccess>}
            </>
        </div>
    )
}
export default ShowDeliveryPerson;