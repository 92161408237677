import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    history_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    body: {
        position: 'relative',
        display: 'flex',
        padding: '20px 16px',
        marginTop: '10px',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        alignItems: 'center',
        overflow: 'auto',
        height: 'calc(100vh - 120px)'
    },
    line: {
        position: 'absolute',
        height: '100%',
        width: 1,
        borderLeft: `2px dotted ${theme.palette.grey[300]}`,
        left: '40%'
    },
    history_table: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative'
    },
    element_container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        padding: 4,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[200]}`,
        width: '200px',
        marginLeft: '10px'
    },
    date_container: {
        display: 'flex',
        backgroundColor: theme.palette.common.white,
        padding: 4,
        borderRadius: 4,
        width: 'calc(40% - 20px)',
        justifyContent: 'flex-end'
    },
    element_row: {
        display: "flex",
        padding: '15px 10px'
    },
    dot: {
        backgroundColor: theme.palette.common.white,
        width: 14,
        height: 14,
        borderRadius: '50%',
        zIndex: 1,
        border: `4px solid ${theme.palette.primary.dark}`
    },
    comment: {
        padding: 4,
        fontSize: 14,
        textAlign: 'justify',
        color: theme.palette.grey[500]
    },
    user_container: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        color: theme.palette.grey[500]
    }
}));

export {useStyles}