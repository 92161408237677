const values = {
    "MONEY_EXCHANGES": "Tasas de cambio",
    "NEW_MONEY_EXCHANGES": "Nueva tasa de cambio",
    "EDIT_MONEY_EXCHANGES": "Editar tasa de cambio",
    "NO_MONEY_EXCHANGES_TITLE": "No hay tasas de cambio!!!",
    "NO_MONEY_EXCHANGES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "MONEY_EXCHANGES_FILTER_PLACEHOLDER": "Moneda",
    "REMOVE_MONEY_EXCHANGE_CONFIRMATION": "¿Está seguro que desea eliminar la tasa de cambio?",
    "REMOVE_MONEY_EXCHANGE": "Eliminar tasa de cambio",
    "MONEY_EXCHANGE_CREATION_EXPLANATION": "Por cada dólar, el contacto recibe:",
    "MONEY_EXCHANGE_HINT": "Por cada USD enviado, se entregarán"
}

export default values;