import React from 'react';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconName from './enums/IconName';
import IconProperties from './IconProperties';

const Icon: React.FC<IconProperties> = ({
    className = '',
    fontSize,
    name,
    color,
    label,
}) => {

    const renderIcon = (name: IconName) => {
        switch(name){
            case IconName.CREATE_ICON:
                return <CreateIcon className={className} color={color} fontSize={fontSize}>{label}</CreateIcon>;
            case IconName.DELETE_ICON:
                return <DeleteIcon className={className} color={color} fontSize={fontSize}>{label}</DeleteIcon>;
            case IconName.EDIT_ICON:
                return <EditIcon className={className} color={color} fontSize={fontSize}>{label}</EditIcon>;
            default:
                return <></>;
        }
    };

    return (
        <>
          {renderIcon(name)}
        </>
    )
}
export default Icon;