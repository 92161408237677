import React from 'react';
import ResumeProperties from "./ResumeProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from './Resume.style';
import PaymentsTable from "../../../Info/PaymentsTable/PaymentsTable";
import ShowPhoneNumber from "../../../Info/ShowPhoneNumber/ShowPhoneNumber";
import ShowClientContact from "../../../Info/ShowCientContact/ShowClientContact";
import ShowProduct from "../../../Info/ShowProduct/ShowProduct";
import ShowGeneralComment from '../../../Info/ShowGeneralComment/ShowGeneralComment';

const Resume: React.FC<ResumeProperties> = ({
                                                  store
                                              }) => {

    const classes = useStyles();

    return <Observer>
        {() => (
            <div className={classes.container}>
               <div className={classes.topup_container}>
                   {store.topups.map((t,i)=>(
                       <div key={i} className={classes.topup_row}>
                           <ShowPhoneNumber value={t.number}/>
                           <ShowClientContact clientName={`${t.client.name} ${t.client.lastName}`} contactName={t.contact.name}/>
                           <ShowProduct value={t.product}/>
                           <ShowGeneralComment value={t.comment}/>
                       </div>
                   ))}
               </div>
                {store.payments.length > 0 &&
                    <PaymentsTable payments={store.payments}/>}
            </div>
        )}
    </Observer>
}

export default Resume;
