import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    history_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    body: {
        display: 'flex',
        padding: '20px 16px',
        marginTop: '10px',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        alignItems: 'center'
    },
    history_table:{
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container:{

    },
    margin_left_4: {
        marginLeft: '4px'
    }
}));

export {useStyles}