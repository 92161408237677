import {useStyles} from './DeliveryPersonElementRow.style';
import React from "react";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import DeliveryPersonElementRowProperties from "./DeliveryPersonElementRowProperties";
import clsx from "clsx";
import { CurrencyModule } from '../../../../../util/Currency';
import { useI18nContext } from '../../../../../contexts/I18nContext';
import {format} from "../../../../../util/PhoneFormatter";
import ShowGeo from '../../../../Info/ShowGeo/ShowGeo';

const DeliveryPersonElementRow: React.FC<DeliveryPersonElementRowProperties> = ({
                                                               element,
                                                               onSelect,
                                                               className,
                                                               selected
                                                           }) => {
    const classes = useStyles();
    const i18n = useI18nContext();

    return (
        <div className={clsx(classes.element_row_container, className, selected && classes.active)}
             onClick={() => onSelect(element)}>
            <div className={classes.icon_info}>
                <AccountBoxSharpIcon/>
            </div>
            <div className={classes.name_geo_container}>
                <div className={classes.text_container}>
                    <div>
                        <b>{element.name}</b> 
                        {element.phone && <span className={classes.phone_number_container}><b>{` (${format(element.phone)})`}</b></span>}
                    </div>
                    <div className={classes.balances_container}>
                        {element.balances.map((balance, i) => (
                            <div key={i} className={classes.balance}>
                                <div className={clsx(
                                    balance.amount >= 0 && classes.positive,
                                    balance.amount < 0 && classes.negative
                                )}>{CurrencyModule.format(balance.amount, balance.currency)}</div>
                                <div className={classes.hint}>{i18n.translate(balance.currency)}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.geo_container}>
                    <ShowGeo geo={element.geo} showParentInfo={true} showIcon={false} />
                </div>
            </div>
        </div>
    )
}


export default DeliveryPersonElementRow;