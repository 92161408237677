import React from 'react';
import ShowArticleDeliveryArticlesProperties from "./ShowArticleDeliveryArticlesProperties";
import {useStyles} from "./ShowArticleDeliveryArticles.style";
import {CurrencyModule} from "../../../util/Currency";
import {useI18nContext} from "../../../contexts/I18nContext";
import clsx from "clsx";
import Currency from '../../../entities/Currency';

const ShowArticleDeliveryArticles: React.FC<ShowArticleDeliveryArticlesProperties> = ({
                                                        valueToPay,
                                                        articles,
                                                        className = '',
                                                  }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_price_container, className)}>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`${i18n.translate("TOTAL_TO_PAY")}:`}</div>
                    <div className={classes.value}>{valueToPay && CurrencyModule.format(valueToPay, Currency.DOLLAR)}</div>
                </div>
                <div className={classes.currency}>USD</div>
            </div>
            <div>
                <div className={classes.number}>
                    <div className={classes.title}>{`Artículos (${articles.length}):`}</div>
                    <div className={classes.value}>
                        {articles.map((article, index) => (
                            <span>{article.articleName} ({article.amount}){index < articles.length - 1 && <span>,</span>} </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ShowArticleDeliveryArticles;