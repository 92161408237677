const values = {
    "AGENCIES": "Agencias",
    "AGENCY": "Agencia",
    "NO_AGENCIES_TITLE": "No hay agencias!!!",
    "NO_AGENCIES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "AGENCY_FILTER_PLACEHOLDER": "Nombre",
    "EDIT_AGENCY": "Editar Agencia",
    "NEW_AGENCY": "Nueva Agencia",
    "AGENCY_NAME": "Nombre de la Agencia",
    "USER_DATA": "Datos del usuario",
    "REMOVE_AGENCY": "Eliminar agencia",
    "REMOVE_AGENCY_CONFIRMATION": "¿Está seguro que desea eliminar la agencia?",
    "SELECT_AGENCY": "Seleccione Agencia",
    "CURRENT_AGENCY": "Agencia actual",
    "SELECT_AGENCY_PLACEHOLDER": "Seleccione agencia",
    "AGENCY_SALES_OVERVIEW": "Resumen de ventas",
    "UPDATE_MAIN_USER_PASSWORD": "Actualizar contraseña de usuario principal",
}

export default values;