import React from 'react';
import NewTopupPriceButtonProperties from "./NewTopupPriceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewTopupPriceStore from "./NewTopupPriceStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewTopupPriceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import TextInput from '../../../ui_components/TextInput/TextInput';
import TextInputType from '../../../ui_components/TextInput/TextInputType';
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import ProductSelect from '../../Selects/ProductSelect/ProductSelect';
import Access from "../../Access/AllowAccess/Access";
import {useUserContext} from "../../../contexts/UserContext";


const NewTopupPriceButton: React.FC<NewTopupPriceButtonProperties> = ({
                                                                  onClick = () => {
                                                                  },
                                                                  onSave = () => {
                                                                  },
                                                                  showIcon = true,
                                                                  showText = true,
                                                                  disabled,
                                                                  className = '',
                                                                  topupPriceId,
                                                                  rounded = false,
                                                                  size = ButtonSize.DEFAULT,
                                                                  type = ButtonType.DEFAULT
                                                              }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewTopupPriceStore(commonContext, topupPriceId);

    const userStore = useUserContext();
    if (!userStore.hasAccess(Access.CREATE_TOPUP_PRICE.valueOf())) {
        return <></>;
    }

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onFeeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFeeValue = event.target.value;
        store.topupPrice.amount = +newFeeValue;
    };

    const onServiceFeeSave = async () => {
        const saved = await store.save();
        if (saved) {
            store.closeModal();
            if (onSave) {
                onSave();
            }
        }
    }

    const text = store.isEditing ? "Editar precio de recarga" : "Nuevo precio de recarga";
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <ProductSelect onChange={(p) => store.selectedProduct = p} error={i18n.translate(store.productError)} className={classes.full_width}/> 
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={store.topupPrice?.amount}
                            onChange={onFeeChange}
                            error={i18n.translate(store.priceError)}
                            label={'Valor'}
                            className={classes.input}
                            fullWidth
                        />
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onServiceFeeSave}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewTopupPriceButton;
