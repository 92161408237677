import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../Sellers.style";
import clsx from "clsx";
import NewSellerButton from "../../../shared_components/Buttons/NewSellerButton/NewSellerButton";
import SellerRowProperties from "./SellerRowProperties";
import ToggleSellerStatusButton
    from "../../../shared_components/Buttons/ToggleSellerStatusButton/ToggleSellerStatusButton";
import RemoveSellerButton from "../../../shared_components/Buttons/RemoveSellerButton/RemoveSellerButton";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowField from "../../../shared_components/Info/ShowField/ShowField";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ShowRoles from "../../../shared_components/Info/ShowRoles/ShowRoles";
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import UpdateSellerPasswordButton
    from "../../../shared_components/Buttons/UpdateSellerPasswordButton/UpdateSellerPasswordButton";


const SellerRowMedium: React.FC<SellerRowProperties> = ({
                                                            seller,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const classes = useStyles();
    const i18n = useI18nContext();

    return (
        <div
            className={clsx(classes.seller_info_container, !seller.isActive && classes.inactive)}>
            <ShowSellerBasicInfo autofill name={seller.name} email={seller.email}/>
            <ShowDate label={i18n.translate("CREATED")} timestamp={seller.createdAt}/>
            <ShowField
                value={i18n.translate(seller.status)}
                label={i18n.translate("STATUS")}/>
            <ShowRoles rolesId={seller.roles}/>
            <ExpandoMenu>
                <>
                    <NewSellerButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        sellerId={seller.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ToggleSellerStatusButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        seller={seller}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <RemoveSellerButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        seller={seller}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <UpdateSellerPasswordButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        type={ButtonType.SECONDARY}
                        showText
                        sellerId={seller.id}
                        sellerName={seller.name}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default SellerRowMedium;
