import React from 'react';
import ShowAddressProperties from "./ShowAddressProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "./ShowAddress.style";
import HomeIcon from '@mui/icons-material/Home';
import clsx from "clsx";

const ShowAddress: React.FC<ShowAddressProperties> = ({
                                                          value,
                                                          className = '',
                                                      }) => {

    const i18n = useI18nContext();
    const classes = useStyles();

    return (
        <div className={clsx(classes.show_comment_container, className,!value && classes.empty)}>
            <HomeIcon className={classes.icon}/>
            <div className={classes.value_container}>
                {value || i18n.translate("NO_ADDRESS")}
            </div>
        </div>
    )
}


export default ShowAddress;