import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../Clients.style";
import clsx from "clsx";
import ClientRowProperties from "./ClientRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowSellerBasicInfo from "../../../shared_components/Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import NewClientButton from "../../../shared_components/Buttons/NewClientButton/NewClientButton";
import RemoveClientButton from "../../../shared_components/Buttons/RemoveClientButton/RemoveClientButton";
import ClientContactButton from "../../../shared_components/Buttons/ClientContactButton/ClientContactButton";
import ExpandoMenu from "../../../ui_components/ExpandoMenu/ExpandoMenu";
import ShowClientLocationInfo from '../../../shared_components/Info/ShowClientLocationInfo/ShowClientLocationInfo';
import ShowDifficultPersonIndicator from '../../../shared_components/Info/ShowDifficultPersonIndicator/ShowDifficultPersonIndicator';


const ClientRowMedium: React.FC<ClientRowProperties> = ({
                                                            client,
                                                            onUpdate = () => {
                                                            },
                                                        }) => {


    const classes = useStyles();
    const i18n = useI18nContext();

    return (
        <div
            className={clsx(classes.client_info_container)}>
            <ShowSellerBasicInfo autofill name={client.name} lastName={client.lastName} email={client.email}
                                 phone={client.phone}/>
            <ShowClientLocationInfo address={client.address} city={client.city} state={client.state} zipCode={client.zipCode}/>
            <ShowDate label={i18n.translate("CREATED")} timestamp={client.createdAt}/>
            <ShowDifficultPersonIndicator difficultPerson={client.difficultPerson}/>
            <ExpandoMenu>
                <>
                    <NewClientButton size={ButtonSize.SMALL} onSave={onUpdate} clientId={client.id} showText
                                     type={ButtonType.SECONDARY}/>
                    <ClientContactButton size={ButtonSize.SMALL} onSave={onUpdate} clientId={client.id} agencyId={client.agencyId} showText
                                         type={ButtonType.SECONDARY}/>
                    <RemoveClientButton size={ButtonSize.SMALL} onSave={onUpdate} client={client} showText
                                        type={ButtonType.SECONDARY}/>
                </>
            </ExpandoMenu>
        </div>
    )
}

export default ClientRowMedium;
