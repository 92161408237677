const values = {
    "ARTICLES_CONFIGURATIONS": "Config. de artículos",
    "ARTICLES": "Artículos",
    "NEW_ARTICLE": "Nuevo Artículo",
    "EDIT_ARTICLE": "Editar Artículo",
    "NO_ARTICLES_TITLE": "No hay artículos!!!",
    "NO_ARTICLES_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "ARTICLE_FILTER_PLACEHOLDER": "Nombre, descripción",
    "REMOVE_ARTICLE": "Eliminar artículo",
    "REMOVE_ARTICLE_CONFIRMATION": "¿Está seguro que desea eliminar el artículo?",
}

export default values;