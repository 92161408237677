import Currency from "../../entities/Currency";
import PaymentTypeResponse from "./PaymentTypeResponse";
import PaymentType from "../../entities/PaymentType";

class AddPaymentRequest {
    public amount: number;
    public currency: Currency;
    public paymentTypeResponse: PaymentTypeResponse;
    public paymentType: PaymentType;

    constructor(amount: number, currency: Currency, paymentType: PaymentTypeResponse) {
        this.paymentTypeResponse = paymentType;
        this.amount = amount;
        this.currency = currency;
        this.paymentType = this.paymentTypeResponse.paymentType;
    }

    get getExtraFee(): number {
        if (this.paymentTypeResponse.value !== 0) {
            if (this.paymentTypeResponse.isPercentage) {
                return this.amount * this.paymentTypeResponse.value / 100.00;
            }
            return this.paymentTypeResponse.value
        }
        return 0;
    }

}

export default AddPaymentRequest;