import React, { useEffect } from 'react';
import PackageDeliveryResumeProperties from "./PackageDeliveryResumeProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from './PackageDeliveryResume.style';
import InvoiceToPrint from "../../../Content/InvoiceToPrint/InvoiceToPrint";
import MessageCard from '../../../../ui_components/MessageCard/MessageCard';
import { useI18nContext } from '../../../../contexts/I18nContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


const PackageDeliveryResume: React.FC<PackageDeliveryResumeProperties> = ({
                                                                              store,
                                                                          }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    useEffect(() => {
        store.createPackageDelivery();
    })

    return <Observer>
        {() => (
            <div className={classes.container}>
                {store.invalidPaymentError && 
                    <MessageCard 
                    className={classes.error}
                    icon={AttachMoneyIcon} 
                    title={i18n.translate("INVALID_PAYMENTS_TITLE")}
                    subtitle={i18n.translate("INVALID_PAYMENTS_SUBTITLE")}/>
                }
                <div>
                    {store.createdAt &&
                        <InvoiceToPrint productOrderIds={[store.id||'']} isInvoice={true}/>
                    }
                </div>
            </div>
        )}
    </Observer>
}

export default PackageDeliveryResume;
