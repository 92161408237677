const values = {
    "SELLER_FILTER_PLACEHOLDER": "Name, Email",
    "NO_SELLERS_TITLE": "There are no sellers!!!",
    "NO_SELLERS_SUBTITLE": "Check if you are applying any filter",
    "PENDING": "Pending",
    "INACTIVE": "Inactive",
    "ACTIVE": "Active",
    "CREATED": "Created",
    "SHIPPING_NUMBER": "Shipment: ",
    "NO_SHIPPING_ASSIGNED": "No shipment assigned",
    "ROLE": "Role",
    "NEW_SELLER": "New Seller",
    "EDIT_SELLER": "Edit Seller",
    "PENDING_DESCRIPTION": "Pending validation, cannot sign in",
    "INACTIVE_DESCRIPTION": "Inactive, cannot sign in",
    "ACTIVE_DESCRIPTION": "Active, can sign in",
    "STATUS_PLACEHOLDER": "MultiSelect status",
    "ROLE_PLACEHOLDER": "MultiSelect role",
    "DEACTIVATE_SELLER": "Deactivate seller",
    "ACTIVATE_SELLER": "Activate seller",
    "REMOVE_SELLER": "Delete seller",
    "REMOVE_SELLER_CONFIRMATION": "Are you sure you want to remove the seller?",
}

export default values;
