import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import MoneyExchangeRate from "../../entities/productOrder/moneyDelivery/MoneyExchangeRate";

export module MoneyExchangeService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string, agencyId?: string): Promise<Response<PagedResponse<MoneyExchangeRate>>> {
        const url = Config.get("GET_MONEY_EXCHANGE_URL", {page, pageSize, filter, agencyId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const moneyExchangeData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (moneyExchangeData && paginationData) {
                const data = moneyExchangeData.map((t: any) => new MoneyExchangeRate(t.id, t.agencyId, t.currency, t.value));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<MoneyExchangeRate>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<MoneyExchangeRate>>(false, undefined, response.error, response.status);
    }


    export async function find(id: string): Promise<Response<MoneyExchangeRate>> {
        const url = Config.get("FIND_MONEY_EXCHANGE_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const data = response.data;
            if (data) {
                const moneyExchange = new MoneyExchangeRate(data.id, data.agencyId, data.currency, data.value);
                return new Response<MoneyExchangeRate>(true, moneyExchange, response.error);
            }
        }
        return new Response<MoneyExchangeRate>(true, undefined, response.error, response.status);
    }

    export async function create(data: MoneyExchangeRate): Promise<Response<any>> {
        const url = Config.get("CREATE_MONEY_EXCHANGE_URL");
        const request = new Request(url, data);
        return await RestClient.post(request);
    }

    export async function update(data: MoneyExchangeRate): Promise<Response<any>> {
        const url = Config.get("UPDATE_MONEY_EXCHANGE_URL", {id: data.id});
        const request = new Request(url, data);
        return await RestClient.put(request);
    }

    export async function remove(data: MoneyExchangeRate): Promise<Response<any>> {
        const url = Config.get("REMOVE_MONEY_EXCHANGE_URL", {id: data.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}