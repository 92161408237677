import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import { SalePriceService } from "../../../service/SalePriceService";
import MiscellanySalePrice from "../../../entities/MiscellanySalePrice";
import MiscellanyCategory from "../../../entities/productOrder/miscellany/MiscellanyCategory";
import { MiscellanyCategoryService } from "../../../service/miscellany/MiscellanyService";

class ShowMiscellanyCostsStore {
    public miscellanyPrices: MiscellanySalePrice[];
    public miscellanyCategories: MiscellanyCategory[];
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    constructor(commonStore: CommonStore) {
        this.isModalOpen = false;
        this.miscellanyPrices = [];
        this.miscellanyCategories = [];
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get isEmpty(): boolean {
        return this.miscellanyPrices.length === 0;
    }

    @action
    public async getMiscellanyCategories() {
        const response = await MiscellanyCategoryService.getAll();
        if (response.success && response.data) {
            this.miscellanyCategories = response.data;
        }
    }

    @action
    public async loadMiscellanySalePrices() {
        const response = await SalePriceService.getSalePriceFromAgency();
        if (response.success && response.data) {
            this.miscellanyPrices = response.data.miscellanySalePrices;
        }
        this.commonStore.processErrors(response);
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadMiscellanySalePrices();
        await this.getMiscellanyCategories();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

}

export default ShowMiscellanyCostsStore;