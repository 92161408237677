import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.grey[200],
        flexGrow: 1,
        height: 'calc(100vh - 250px)'
    }
}));

export {useStyles}