import React from 'react';
import {motion} from "framer-motion"
import {Navigate} from "react-router-dom";
import ProtectedRouteWithLayoutProperties from "./ProtectedRouteProperties";
import {useUserContext} from "../../../contexts/UserContext";

const ProtectedRoute: React.FC<ProtectedRouteWithLayoutProperties> = (
    {
        route,
        ...rest
    }) => {
    const userStore = useUserContext();
    if (!userStore.isAuthenticated() ) {
        return <Navigate to="/login"/>;
    }
    return (
        <motion.div exit={{opacity: 0, x: -50}} initial={{opacity: 0, x: -50}} animate={{opacity: 1, x: 0}}>
            <route.component {...rest}/>
        </motion.div>
    );

}

export default ProtectedRoute;