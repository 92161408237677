const values = {
    "SHOP_PRODUCTS_CONFIGURATIONS": "Config. de tienda",
    "SHOP_PRODUCT": "Producto",
    "SHOP_PRODUCTS": "Productos",
    "NEW_SHOP_PRODUCT": "Nuevo Producto",
    "EDIT_SHOP_PRODUCT": "Editar Producto",
    "NO_SHOP_PRODUCTS_TITLE": "No hay productos!!!",
    "NO_SHOP_PRODUCTS_SUBTITLE": "Verifica si estás aplicando algún filtro",
    "SHOP_PRODUCT_FILTER_PLACEHOLDER": "Nombre, descripción",
    "REMOVE_SHOP_PRODUCT": "Eliminar producto",
    "REMOVE_SHOP_PRODUCT_CONFIRMATION": "¿Está seguro que desea eliminar el producto?",
}

export default values;