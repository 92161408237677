import React, {useState} from 'react';
import {Observer} from "mobx-react-lite";
import {useStyles} from "./DoTopupRow.style";
import {useI18nContext} from "../../../../contexts/I18nContext";
import DoTopupRowProperties from "./DoTopupRowProperties";
import ShowPhoneNumber from "../../../Info/ShowPhoneNumber/ShowPhoneNumber";
import ShowProduct from "../../../Info/ShowProduct/ShowProduct";
import ShowDate from "../../../Info/ShowDate/ShowDate";
import Button from "../../../../ui_components/Button/Button";
import ButtonSize from "../../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../../ui_components/Button/ButtonType";
import HistoryIcon from '@material-ui/icons/History';
import ShowField from "../../../Info/ShowField/ShowField";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Icon from '../../../../ui_components/Icon/Icon';
import IconName from '../../../../ui_components/Icon/enums/IconName';
import ShowClientContact from "../../../Info/ShowCientContact/ShowClientContact";
import ShowGeneralComment from '../../../Info/ShowGeneralComment/ShowGeneralComment';


const DoTopupRow: React.FC<DoTopupRowProperties> = ({
                                                        doTopup,
                                                        onDelete,
                                                        index
                                                    }) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    const [checked, setChecked] = useState(false);

    return <Observer>
        {() => (
            <div className={classes.do_topup_row_container}>
                <div className={classes.index_container}>
                    <div className={classes.index}>{index}</div>
                    <div className={classes.check_btn} onClick={() => setChecked(!checked)}>
                        {checked && <CheckBoxIcon className={classes.checked}/>}
                        {!checked && <CheckBoxOutlineBlankIcon/>}
                    </div>
                </div>
                <div>
                    <ShowPhoneNumber value={doTopup.number}/>
                    <ShowField tooltip={i18n.translate("PROMO_NUMBER_COUNT_DESCRIPTION")}
                               value={doTopup.promoCount.toString()} icon={HistoryIcon}/>
                </div>
                <ShowClientContact
                    clientName={`${doTopup.client.name}
                    ${doTopup.client.lastName}`}
                    contactName={doTopup.contact.name}
                    className={classes.hide_small}    
                />
                <ShowProduct value={doTopup.product}/>
                <ShowDate label={i18n.translate("LAST_RECHARGE")} timestamp={doTopup.lastRecharge}/>
                <ShowGeneralComment value={doTopup.comment}/>
                <div className={classes.tools_container}>
                    <Button
                        onClick={() => onDelete(doTopup)}
                        size={ButtonSize.SMALL}
                        type={ButtonType.SECONDARY}
                        tooltip={i18n.translate("DELETE")}
                        startIcon={<Icon name={IconName.DELETE_ICON}/>}
                    />
                </div>
            </div>
        )}
    </Observer>
}

export default DoTopupRow;
