import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    seller_info_container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
    },
    info_container: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        lineHeight: '14px',
        marginLeft: '8px',
        color: theme.palette.secondary.light,
        "& div:last-child": {
            marginTop: '2px'
        }
    },
    link: {
        fontWeight: 800
    }
}));

export {useStyles}