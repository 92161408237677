import Phone from "../../entities/Phone";
import ProductOrderStatus from "../../entities/productOrder/ProductOrderStatus";
import ShopOrderProductResponse from "./ShopOrderProductResponse";

class ShopOrderResponse {
    public id: string;
    public orderNumber: string;
    public agencyId: string;
    public clientId: string;
    public agencyName: string;
    public clientName: string;
    public clientPhone: Phone | null;
    public userId: string;
    public userName: string;
    public totalToPay: number;
    public createdAt: number;
    public status: ProductOrderStatus;
    public products: ShopOrderProductResponse[];

    constructor(
        id: string,
        orderNumber: string,
        agencyId: string,
        agencyName: string,
        clientId: string,
        clientName: string,
        clientPhone: Phone | null,
        userId: string,
        userName: string,
        totalToPay: number,
        status: ProductOrderStatus,
        createdAt: number,
        products: ShopOrderProductResponse[]
    ) {
        this.id = id;
        this.orderNumber = orderNumber;
        this.agencyId = agencyId;
        this.agencyName = agencyName;
        this.clientId = clientId;
        this.clientName = clientName;
        this.clientPhone = clientPhone;
        this.userId = userId;
        this.userName = userName;
        this.totalToPay = totalToPay;
        this.status = status;
        this.createdAt = createdAt;
        this.products = products;
    }

}

export default ShopOrderResponse;