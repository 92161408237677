import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import CommonStore from "../../stores/CommonStore";
import {AgencyService} from "../../service/AgencyService";
import SalePrice from "../../entities/SalePrice";
import AgencyResponse from "../../model/agency/AgencyResponse";

class AgencyStore {

    public agencies: AgencyResponse[];
    public selectedAgencyId: string;
    public pagination: Pagination;
    public filter?: string;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.agencies = [];
        this.selectedAgencyId = '';
        this.commonStore = commonStore;
        makeObservable(this, {
            agencies: observable,
            pagination: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.agencies.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await AgencyService.get(page, pageSize, this.filter);
        if (response.success) {
            runInAction(() => {
                this.agencies = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

    @action
    public async setAgencySalePrice(salePrice: SalePrice) {
        this.commonStore.setLoading();
        const response = await AgencyService.setSalePrice(this.selectedAgencyId, salePrice.id);
        if (response.success) {
            runInAction(() => {
                const tempAgency = this.agencies.find(t => t.id === this.selectedAgencyId);
                const tempAgencyIndex = this.agencies.findIndex(t => t.id === this.selectedAgencyId);
                if (tempAgency != null) {
                    this.agencies[tempAgencyIndex] = {
                        ...tempAgency,
                        salePriceId: salePrice.id,
                        salePriceName: salePrice.name
                    };
                }
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default AgencyStore;