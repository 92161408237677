import React from 'react';
import ShopProductRowProperties from "./ShopProductRowProperties";
import ViewMedium from "../../../ui_components/MediaView/ViewMedium";
import ShopProductRowMedium from "./ShopProductRowMedium";
import ViewLarge from "../../../ui_components/MediaView/ViewLarge";
import ViewSmall from "../../../ui_components/MediaView/ViewSmall";
import ShopProductRowSmall from "./ShopProductRowSmall";


const ShopProductRow: React.FC<ShopProductRowProperties> = ({
                                                    shopProduct,
                                                    onUpdate
                                                }) => {
    return (
        <>
            <ViewSmall>
                <ShopProductRowSmall onUpdate={onUpdate} shopProduct={shopProduct}/>
            </ViewSmall>
            <ViewMedium>
                <ShopProductRowMedium onUpdate={onUpdate} shopProduct={shopProduct} />
            </ViewMedium>
            <ViewLarge>
                <ShopProductRowMedium onUpdate={onUpdate} shopProduct={shopProduct} />
            </ViewLarge>
        </>
    )
}

export default ShopProductRow;
