import React from 'react';
import {Inventory} from "@mui/icons-material";
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {useCommonContext} from "../../../contexts/CommonContext";
import NewPackageDeliveryStore from "./NewPackageDeliveryStore";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewPackageDeliveryButton.style";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import NewPackageDeliveryButtonProperties from './NewPackageDeliveryButtonProperties';
import Steppers from '../../../ui_components/Stepper/Steppers';
import StepperOption from '../../../ui_components/Stepper/StepperOption';
import ContactSelect from './ContactSelect/ContactSelect';
import ItemInventory from './ItemInventory/ItemInventory';
import PackageDeliveryResume from './Resume/PackageDeliveryResume';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import Payment from "./Payment/Payment";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import AgencySelect from "./AgencySelect/AgencySelect";
import Link from '../../../ui_components/Link/Link';

const NewPackageDeliveryButton: React.FC<NewPackageDeliveryButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSuccessCreate = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT, 
                                                                                minimal = false, 
                                                                                packageDeliveryId
                                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewPackageDeliveryStore(commonContext, onSuccessCreate, packageDeliveryId);
    const userStore = useUserContext();

    const handleOnclick = async () => {
        onClick();
        await store.openModal();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const isContactStepCompleted = (): boolean => {
        return store.selectedContact?.id != null;
    }

    const isBudgetStepCompleted = (): boolean => {
        return  true
    }

    const isAgencySelected = (): boolean => {
        if (!store.selectedAgency) return true;
        return !!store.selectedSeller?.id;
    }

    const getIcon = (): React.ReactNode => {
        return showText ? <Icon name={IconName.CREATE_ICON}/> : <Inventory/>;
    }

    const getTitle = ():string =>{
        if(packageDeliveryId==null){
            return i18n.translate("NEW_PACKAGE_DELIVERY")
        }else{
            return i18n.translate("EDIT_PACKAGE_DELIVERY")
        }
    }

    const text = showText ? i18n.translate("NEW_PACKAGE_DELIVERY") : "";
    const tooltip = !showText ? i18n.translate("NEW_PACKAGE_DELIVERY") : "";
    const btnIcon = showIcon ? getIcon() : React.Fragment;

    let index = 0;
    let steps: StepperOption[] = [];
    if(packageDeliveryId==null){
        if (userStore.hasAccess(Access.SEND_ORDERS_TO_AGENCIES) && userStore.hasAccess(Access.LIST_AGENCY)) {
        steps.push(new StepperOption(index++, i18n.translate("SELECT_AGENCY"), isAgencySelected, <AgencySelect
            store={store}/>))
        }
        steps.push(new StepperOption(index++, i18n.translate("SELECT_CONTACT_PLACEHOLDER"), isContactStepCompleted,
            <ContactSelect
                store={store}/>));
    }    
    steps.push(new StepperOption(index++, i18n.translate("ITEMS_PLACEHOLDER"), isBudgetStepCompleted, <ItemInventory
        store={store}/>));
    steps.push(new StepperOption(index++, i18n.translate("PAYMENT"), () => {
        return true;
    }, <Payment
        store={store}/>));
    steps.push(new StepperOption(index++, i18n.translate("RESUME"), () => {
        return false;
    }, <PackageDeliveryResume store={store}/>));

    const handleFinish = () => {
        //store.createPackageDelivery();
        window.location.href = '/package-delivery';
        onSuccessCreate();
    };

    const Btn = () => {
        if(minimal){
            return <div className={classes.btn_container}>
                        <div><Link text={'Detalles'} onClick={handleOnclick}></Link></div>
                        <div className={classes.btn_container}></div>
                    </div>
        }else{
            if (rounded) {
                return <RoundedButton
                    className={className}
                    onClick={handleOnclick}
                    tooltip={tooltip}
                    disabled={disabled}
                    size={size}
                    type={type}
                    text={text}
                    icon={btnIcon}
                />
            }
            return <Button
                className={className}
                onClick={handleOnclick}
                tooltip={tooltip}
                disabled={disabled}
                size={size}
                type={type}
                text={text}
                startIcon={btnIcon}
            />
        }
    }

    return <Observer>
        {() => (
            <>
                <SidePanel title={getTitle()} onClose={onCloseModal}
                           open={store.isModalOpen} className={classes.modal_container}>
                    {!store.response &&
                        <Steppers 
                            activeStep={store.activeStep}
                            onActiveStepChange={(value) => {
                                store.activeStep = value;
                            }}
                            steps={steps} containerClassName={classes.stepper_container}
                            handleFinish={handleFinish}
                            finishButtonLoading={store.finishButtonLoading}/>}
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewPackageDeliveryButton;
