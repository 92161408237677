class OrderAdditionalPrice {
    public isPercentage: boolean;
    public amount: number;
    public comment: string;

    constructor(amount: number, comment: string, isPercentage: boolean) {
        this.isPercentage = isPercentage;
        this.amount = amount;
        this.comment = comment;
    }

}

export default OrderAdditionalPrice;