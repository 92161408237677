import clsx from "clsx";
import {useCommonStyles} from "../CommonStyles.style";
import Width from "../enums/Width";

export const useWidthClasses = (width: Width) => {
    const commonClasses = useCommonStyles();
    return clsx(
        commonClasses.flex,
        width === Width.FIT_CONTENT && commonClasses.fitContent,
        width === Width.FULL_WIDTH && commonClasses.fullWidth);
}