import Balance from "./Balance";
import DeliveryCost from "./DeliveryCost";
import Geo from "./Geo";
import Phone from "./Phone";

class DeliveryPerson {
    public id: string;
    public name: string;
    public homePhone?: Phone;
    public phone: Phone;
    public createdAt: number;
    public email: string;
    public geo?: Geo;
    public status: string;
    public balances: Balance[];
    public activeMoneyDeliveryBalances: Balance[];
    public articleStockTotalAmount: number;
    public deliveryCosts: DeliveryCost[];

    constructor(
        id: string,
        name: string,
        homePhone: Phone,
        phone: Phone,
        email: string,
        status: string,
        createdAt: number,
        articleStockTotalAmount: number,
        geo?: Geo,
        balances: Balance[] = [],
        activeMoneyDeliveryBalances: Balance[] = [],
        deliveryCosts: DeliveryCost[] = []
    ) {
        this.name = name;
        this.homePhone = homePhone;
        this.phone = phone;
        this.id = id;
        this.createdAt = createdAt;
        this.email = email;
        this.geo = geo;
        this.status = status;
        this.balances = balances;
        this.activeMoneyDeliveryBalances = activeMoneyDeliveryBalances;
        this.articleStockTotalAmount = articleStockTotalAmount;
        this.deliveryCosts = deliveryCosts;
    }

}

export default DeliveryPerson;