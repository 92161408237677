import React, {memo, PropsWithChildren, useMemo} from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {useCommonContext} from "../../../contexts/CommonContext";
import NewMoneyDeliveryStore from "./NewMoneyDeliveryStore";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewMoneyDeliveryButton.style";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import ButtonType from "../../../ui_components/Button/ButtonType";
import Button from "../../../ui_components/Button/Button";
import NewMoneyDeliveryButtonProperties from './NewMoneyDeliveryButtonProperties';
import Steppers from '../../../ui_components/Stepper/Steppers';
import StepperOption from '../../../ui_components/Stepper/StepperOption';
import ContactSelect from './ContactSelect/ContactSelect';
import MoneySelect from './MoneySelect/MoneySelect';
import MoneyRemittanceResume from './Resume/MoneyRemittanceResume';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import Payment from "./Payment/Payment";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import AgencySelect from "./AgencySelect/AgencySelect";

const NewMoneyDeliveryButton: React.FC<NewMoneyDeliveryButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSuccessCreate = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT
                                                                            }) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();

    const store = useMemo<NewMoneyDeliveryStore>(() =>
            new NewMoneyDeliveryStore(commonContext, userStore, onSuccessCreate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);


    const handleOnclick = async () => {
        onClick();
        await store.openModal();
    }

    const onCloseModal = () => {
        store.createAnother = false;
        store.closeModal();
    }

    const isContactStepCompleted = (): boolean => {
        return store.selectedContact?.id != null;
    }

    const isBudgetStepCompleted = (): boolean => {
        if (store.isCardRemittance && !store.contactBankCard) {
            return false;
        }
        return store.budgetDetails != null;
    }

    const isAgencySelected = (): boolean => {
        if (!store.selectedAgency) return true;
        return !!store.selectedSeller?.id
    }

    const getIcon = (): React.ReactNode => {
        return showText ? <Icon name={IconName.CREATE_ICON}/> : <AttachMoneyIcon/>;
    }

    const text = showText ? i18n.translate("NEW_DELIVERY") : '';
    const tooltip = !showText ? i18n.translate("NEW_MONEY_REMITTANCE") : '';
    const btnIcon = showIcon ? getIcon() : React.Fragment;

    let index = 0;
    let steps: StepperOption[] = [];
    if (userStore.hasAccess(Access.SEND_ORDERS_TO_AGENCIES) && userStore.hasAccess(Access.LIST_AGENCY)) {
        steps.push(new StepperOption(index++, i18n.translate("SELECT_AGENCY"), isAgencySelected, <AgencySelect
            store={store}/>));
    }
    steps.push(new StepperOption(index++, i18n.translate("SELECT_CONTACT_PLACEHOLDER"), isContactStepCompleted,
        <ContactSelect
            store={store}/>));
    steps.push(new StepperOption(index++, i18n.translate("QUANTITY_PLACEHOLDER"), isBudgetStepCompleted, <MoneySelect
        store={store}/>));
    steps.push(new StepperOption(index++, i18n.translate("PAYMENT"), () => {
        return true;
    }, <Payment
        store={store}/>));
    steps.push(new StepperOption(index++, i18n.translate("RESUME"), () => {
        return false;
    }, <MoneyRemittanceResume store={store}/>));

    const handleFinish = () => {
        store.createMoneyDelivery();
    };

    const onCreateAnotherChange = (value: boolean) => {
        store.createAnother = value;
    };

    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                className={className}
                onClick={handleOnclick}
                tooltip={tooltip}
                disabled={disabled}
                size={size}
                type={type}
                text={text}
                icon={btnIcon}
            />
        }
        return <Button
            className={className}
            onClick={handleOnclick}
            tooltip={tooltip}
            disabled={disabled}
            size={size}
            type={type}
            text={text}
            startIcon={btnIcon}
        />
    }

    return <Observer>
        {() => (
            <>
                <SidePanel title={i18n.translate("NEW_MONEY_REMITTANCE")} onClose={onCloseModal}
                           open={store.isModalOpen} className={classes.modal_container}>
                    {!store.response &&
                        <Steppers
                            activeStep={store.activeStep}
                            onActiveStepChange={(value) => {
                                store.activeStep = value;
                            }}
                            steps={steps} containerClassName={classes.stepper_container}
                            handleFinish={handleFinish}
                            finishButtonLoading={store.finishButtonLoading}
                            bottomLeftCheckboxText={'Crear otro envío para el mismo cliente'}
                            onBottomLeftCheckboxChange={onCreateAnotherChange}/>}
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

const arePropsEqual = (prevProps: Readonly<PropsWithChildren<NewMoneyDeliveryButtonProperties>>,
                       nextProps: Readonly<PropsWithChildren<NewMoneyDeliveryButtonProperties>>) => {
    return (
        prevProps.showIcon === nextProps.showIcon
        && prevProps.showText === nextProps.showText
        && prevProps.disabled === nextProps.disabled
        && prevProps.type === nextProps.type
        && prevProps.size === nextProps.size
        && prevProps.rounded === nextProps.rounded

    );
}
export default memo(NewMoneyDeliveryButton, arePropsEqual);
