import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    date_time_range_select_container: {
        display: 'flex',
        position: 'relative',
        width: '100%'
    },
    input_container: {
        display: 'flex',
        position: 'relative',
        flexGrow: 1,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px',
        padding: '8px 14px',
        height: '40px',
        boxSizing: 'border-box',
        "&:hover": {
            border: `1px solid ${theme.palette.secondary.main}`,
        }
    },
    display_container: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white
    },
    icon_container: {
        color: theme.palette.grey[800],
    },
    input: {
        fontSize: '14px',
        color: theme.palette.grey[700],
        flexGrow: 1,
        paddingLeft: '8px',
        lineHeight: '25px'
    },
    label: {
        position: 'absolute',
        top: '-8px',
        left: '10px',
        color: theme.palette.grey[700],
        padding: '0 4px',
        fontSize: '12px',
        backgroundColor: theme.palette.common.white
    },
    popup_container: {
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        top: '40px',
        display: 'flex',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '4px',
        zIndex: 1
    },
    row: {
        display: 'flex',
    },
    optionsContainer: {
        width: '180px',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: "border-box",
        padding: "8px",
        "&>*": {
            margin: '4px'
        }
    },
    buttons_container: {
        display: 'flex',
        padding: '10px',
        justifyContent: 'flex-end',
        "&>*": {
            marginLeft: '8px'
        }
    },
    calendar: {
        "& button.Mui-selected": {
            backgroundColor: theme.palette.secondary.main
        },
        "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
            backgroundColor: `${theme.palette.secondary.light}80`
        },
        "& .MuiPickersDay-today": {
            fontSize: '20px'
        }
    },
    selected_range_container: {
        display: 'flex',
        fontSize: '18px',
        color: theme.palette.grey[800]
    },
    selected_values_container:{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '12px',
        fontSize: '14px',
        color: theme.palette.grey[800]
    }
}));

export {useStyles}