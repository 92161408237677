import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    full_width: {
        width: '100%'
    },
    full_height: {
        height: '100%'
    }
}));

export {useStyles}