import {action, makeAutoObservable, runInAction} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Article from "../../../entities/Article";
import { ArticleService } from "../../../service/articleDelivery/ArticleService";


class ArticleSelectStore {

    public loading: boolean;
    public commonStore: CommonStore;
    public filter?: string;
    public elements: Article[];
    public selectedElement?: Article;
    public isSearchDialogOpen: boolean;

    constructor(commonStore: CommonStore, selectedElement?: Article) {
        this.loading = false;
        this.commonStore = commonStore;
        this.elements = [];
        this.selectedElement = selectedElement ?
            new Article(
                selectedElement.id,
                selectedElement.name,
                selectedElement.description,
                selectedElement.defaultImage,
                selectedElement.confectionPrice,
                selectedElement.confectionPriceCurrency,
                selectedElement.createdAt) : 
            undefined;
        this.isSearchDialogOpen = false;
        makeAutoObservable(this);
    }

    @action
    public async search(): Promise<void> {
        this.loading = true;
        const response = await ArticleService.get(1, 30, this.filter);
        if (response.success) {
            runInAction(() => {
                this.elements = response.data?.data || [];
            })
        }
        this.commonStore.processErrors(response);
        this.loading = false;
    }

    @action
    public toggleSearchDialog(value?: boolean) {
        value = (value === undefined) ? !this.isSearchDialogOpen : value;
        this.isSearchDialogOpen = value;
        if (this.isSearchDialogOpen) {
            this.search();
        }
    }

    get isEmpty(): boolean {
        return this.elements.length === 0;
    }

    @action
    public async setFilter(filter: string) {
        this.filter = filter;
        await this.search();
    }

    @action
    public async setSelectedElement(element?: Article) {
        this.selectedElement = element ?
            new Article(
                element.id,
                element.name,
                element.description,
                element.defaultImage,
                element.confectionPrice,
                element.confectionPriceCurrency,
                element.createdAt) :
            undefined;
        this.isSearchDialogOpen = false;
    }

}

export default ArticleSelectStore;