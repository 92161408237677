import React from 'react';
import NewDeliveryCostButtonProperties from "./NewDeliveryCostButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import NewDeliveryCostStore from "./NewDeliveryCostStore";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./NewDeliveryCostButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Geo from "../../../entities/Geo";
import Currency from "../../../entities/Currency";
import GeoSelect from "../../Selects/GeoSelect/GeoSelect";
import PriceInput from "../../Inputs/PriceInput/PricetInput";
import CurrencySelect from "../../Selects/CurrencySelect/CurrencySelect";
import Group from "../../../ui_components/Group/Group";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import Checkbox from "../../../ui_components/Checkbox/Checkbox";
import Icon from '../../../ui_components/Icon/Icon';
import IconName from '../../../ui_components/Icon/enums/IconName';
import { getEnum } from '../../../util/Currency';
import ServiceNameSelect from '../../Selects/ServiceNameSelect/ServiceNameSelect';
import DeliveryCostServiceName from '../../../entities/DeliveryCostServiceName';


const NewDeliveryCostButton: React.FC<NewDeliveryCostButtonProperties> = ({
                                                                              onClick = () => {
                                                                              },
                                                                              onSave = () => {
                                                                              },
                                                                              showIcon = true,
                                                                              showText = true,
                                                                              disabled,
                                                                              className = '',
                                                                              deliveryCostId,
                                                                              deliveryPersonId,
                                                                              rounded = false,
                                                                              size = ButtonSize.DEFAULT,
                                                                              type = ButtonType.DEFAULT
                                                                          }) => {
    const classes = useStyles(showText);
    const commonClasses = useCommonStyles();

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new NewDeliveryCostStore(commonContext, deliveryPersonId, deliveryCostId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onGeoChange = (newGeo: Geo | undefined) => store.cost.geo = newGeo;
    const onServiceChange = (newService: DeliveryCostServiceName) =>
        (store.cost.service = newService);
    const onAmountChange = (newAmount: number) => store.cost.cost = newAmount;
    const onCurrencyChange = (newCurrency: Currency) => store.cost.currency = newCurrency;
    const onPriceCreate = async () => {
        await store.save();
        store.closeModal();
        onSave();
    }

    const text = i18n.translate((store.isEditing ? "Editar comisión" : "Nueva comisión"));
    const btnIcon = store.isEditing ? <Icon name={IconName.EDIT_ICON}/> : <Icon name={IconName.CREATE_ICON}/>;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? btnIcon : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? btnIcon : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <Modal fullScreen={false} loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}>
                    <div className={classes.modal_container}>
                        <GeoSelect
                            fullWidth
                            onChange={onGeoChange}
                            selected={store.cost.geo}
                            className={classes.input}
                        />
                        <ServiceNameSelect
                            fullWidth className={classes.input}
                            selected={store.cost.service}
                            onChange={onServiceChange}/>
                        <Checkbox selected={store.withoutCost} text={'Sin costo'} className={classes.checkbox} onChange={(value) => {
                            store.withoutCost = value
                        }}/>
                        <Group disabled={store.withoutCost} title={i18n.translate("COST_OF_THE_DELIVERY")} className={commonClasses.marginTop}>
                            <PriceInput fullWidth
                                value={store.cost.cost}
                                className={classes.input}
                                onChange={onAmountChange}/>
                            <CurrencySelect
                                selected={getEnum(store.cost.currency)}
                                fullWidth className={classes.input}
                                onChange={onCurrencyChange}/>
                        </Group>
                        <Button
                            disabled={!store.enableButton}
                            loading={store.loading} className={classes.input} onClick={onPriceCreate}
                            type={ButtonType.SECONDARY}
                            text={i18n.translate("SAVE")}/>
                    </div>
                </Modal>
                <Btn/>
            </>
        )}
    </Observer>
}

export default NewDeliveryCostButton;
