import React, {createRef, useEffect} from 'react';
import ArticleSelectProperties from "./ArticleSelectProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import {useCommonContext} from "../../../contexts/CommonContext";
import {useStyles} from "./ArticleSelect.style";
import clsx from "clsx";
import {useCommonStyles} from "../../../ui_components/common/CommonStyles.style";
import Loading from "../../../ui_components/PageLoading/Loading";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArticleSelectStore from "./ArticleSelectStore";
import {Observer} from "mobx-react-lite";
import CancelIcon from '@mui/icons-material/Cancel';
import {Tooltip} from "@material-ui/core";
import ArticleRow from "./row/ArticleRow";
import Article from '../../../entities/Article';
import ShowImage from '../../Info/ShowImage/ShowImage';

const ArticleSelect: React.FC<ArticleSelectProperties> = ({
                                                      onChange,
                                                      disabled,
                                                      required,
                                                      fullWidth = false,
                                                      className = '',
                                                      selected,
                                                      label = 'Ubicación',
                                                      error
                                                  }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const menuRef = createRef<HTMLDivElement>();
    const inputRef = createRef<HTMLInputElement>();
    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new ArticleSelectStore(commonContext, selected);
    const containerClasses = clsx(classes.select_container, className, fullWidth && commonClasses.fullWidth);

    useEffect(() => {
        if (selected) {
            store.setSelectedElement(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    useEffect(() => {
        const clickOutside = (event: any) => {
            if (menuRef && !menuRef.current?.contains(event.target)) {
                store.toggleSearchDialog(false);
            }
        }
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuRef])

    const toggleDialog = () => {
        store.toggleSearchDialog();
        setTimeout(() => inputRef.current?.focus(), 20);
    }

    let timeout: NodeJS.Timeout;
    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        clearTimeout(timeout);
        const value = event.target.value;
        const changeFilter = () => {
            store.setFilter(value);
        };
        timeout = setTimeout(changeFilter, 800);
    }

    const selectArticle = (article: Article) => {
        store.setSelectedElement(article);
        onChange(article);
    }

    const removeSelected = (e: React.MouseEvent) => {
        store.setSelectedElement(undefined);
        e.stopPropagation();
        onChange(undefined);
    }
    return (
        <Observer>
            {() => (
                <div className={containerClasses} ref={menuRef}>
                    <div className={classes.label}>{label + `${required ? '*' : ''}`}</div>
                    <div className={classes.input_container} onClick={toggleDialog}>
                        {store.selectedElement &&
                            <ShowImage
                                className={classes.selected_image}
                                imageName={store.selectedElement.defaultImage} width={70} height={70}/>}
                        {/*{store.loading && <Loading fontSize={22}/>}*/}
                        <div className={classes.info_container}>
                            {store.selectedElement &&
                                <div className={classes.selected_container}>
                                    <div className={classes.selected_text_container}>
                                        <div><b>{store.selectedElement.name}</b></div>
                                    </div>
                                    <Tooltip title={i18n.translate("CLEAN")}>
                                        <div onClick={removeSelected} className={classes.remove_icon}><CancelIcon/>
                                        </div>
                                    </Tooltip>

                                </div>
                            }
                            {!store.selectedElement && <div className={classes.placeholder}>{i18n.translate("SELECT_GEO_PLACEHOLDER")}</div>}
                        </div>
                        <div className={classes.expand_icon}>
                            {store.isSearchDialogOpen && <ExpandLessIcon/>}
                            {!store.isSearchDialogOpen && <ExpandMoreIcon/>}
                        </div>
                    </div>
                    {store.isSearchDialogOpen &&
                        <div className={classes.options_container}>
                            <div className={classes.options_header}>
                                <input placeholder={i18n.translate("SEARCH_GEO_PLACEHOLDER")} ref={inputRef}
                                       onChange={onFilterChange}/>
                            </div>
                            <div className={classes.options_body}>
                                {store.loading && <Loading className={classes.loading} fontSize={55}/>}
                                {!store.loading && !store.isEmpty && <div className={classes.elements_container}>
                                    {store.elements.map((el, i) => (
                                        <ArticleRow
                                            key={i}
                                            element={el}
                                            selected={el.id === store.selectedElement?.id}
                                            onSelect={selectArticle}
                                        />
                                    ))}
                                </div>}
                                {!store.loading && store.isEmpty &&
                                    <div className={classes.empty_container}>
                                        <div>{i18n.translate("NO_GEO_SEARCH")}</div>
                                    </div>}
                            </div>
                        </div>
                    }
                    {error &&
                        <div className={clsx(classes.text_hint, error && classes.error)}>{error}</div>}
                </div>
            )}
        </Observer>
    )
}


export default ArticleSelect;