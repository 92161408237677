
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import Request from "../model/Request";
import OrderToPrint from "../model/orderToPrint/OrderToPrint";

export module ProductOrderToPrintService {

    export async function find(id: string): Promise<Response<OrderToPrint>> {
        const url = Config.get("FIND_PRODUCT_ORDER_TO_PRINT_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const productOrderToPrint = new OrderToPrint(
                    t.id,
                    t.agencyId,
                    t.agencyName,
                    t.agencyAddress,
                    t.agencyEmail,
                    t.agencyPhone,
                    t.clientId,
                    t.contactId,
                    t.userId,
                    t.createdAt,
                    t.totalToPay,
                    t.status,
                    t.orderNumber,
                    t.geoId,
                    t.deliveryPersonId,
                    t.deliveredAt,
                    t.comments,
                    t.itemsAndCategories,
                    t.serviceFee,
                    t.serviceCost,
                    t.totalToReceive,
                    t.conversionRate,
                    t.currencyToReceive,
                    t.isBankCard,
                    t.contactBankCardId,
                    t.articles,
                    t.products,
                    t.orderType,
                    t.difficultDelivery,
                    t.bankCardType,
                    t.bankCardNumber,
                    t.clientName,
                    t.contact,
                    t.amountToReceive,
                    t.shippingId
                );
                return new Response<OrderToPrint>(true, productOrderToPrint, response.error);
            }
        }
        return new Response<OrderToPrint>(true, undefined, response.error, response.status);
    }



}
