import Phone from "./Phone";

class Client {
    public id: string;
    public name: string;
    public lastName: string;
    public documentId: string;
    public address: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public email?: string;
    public phone?: Phone;
    public nacionality?: string;
    public birthday?: number;
    public observation?: string;
    public difficultPerson: boolean;
    public createdAt: number;
    public agencyId?: string;
    public sendDeliveryNotificationsEmail?: boolean;
    public sendDeliveryNotificationsSms?: boolean;
    public sendPromotionNotificationsEmail?: boolean;
    public sendPromotionNotificationsSms?: boolean;

    constructor(id: string, name: string, lastName: string, documentId: string, address: string,
        city: string, state: string, zipCode: string, createdAt: number, difficultPerson: boolean,
        email?: string, nacionality?: string, birthday?: number, observation?: string, phone?: Phone,
        agencyId?: string,
        sendDeliveryNotificationsEmail?: boolean, sendDeliveryNotificationsSms?: boolean,
        sendPromotionNotificationsEmail?: boolean, sendPromotionNotificationsSms?: boolean) {
        this.id = id;
        this.name = name;
        this.lastName = lastName;
        this.documentId = documentId;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.email = email;
        this.phone = phone;
        this.nacionality = nacionality;
        this.birthday = birthday;
        this.observation = observation;
        this.difficultPerson = difficultPerson;
        this.createdAt = createdAt;
        this.agencyId = agencyId;
        this.sendDeliveryNotificationsEmail = sendDeliveryNotificationsEmail;
        this.sendDeliveryNotificationsSms = sendDeliveryNotificationsSms;
        this.sendPromotionNotificationsEmail = sendPromotionNotificationsEmail;
        this.sendPromotionNotificationsSms = sendPromotionNotificationsSms;
    }

    fullName(): string {
        return `${this.name} ${this.lastName}`;
    }

}

export default Client;