import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import PagedResponse from "../../entities/PagedResponse";
import Pagination from "../../entities/Pagination";
import Article from "../../entities/Article";
import ArticleWithPriceResponse from "../../model/articleDelivery/ArticleWithPriceResponse";

export module ArticleService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string): Promise<Response<PagedResponse<Article>>> {
        const url = Config.get("GET_ARTICLE_URL", {page, pageSize, filter});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const articleData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (articleData && paginationData) {
                const data = articleData.map((t: any) => new Article(t.id, t.name, t.description, t.defaultImage, t.confectionPrice, t.confectionPriceCurrency, t.createdAt));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Article>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Article>>(false, undefined, response.error, response.status);
    }

    export async function getAll(): Promise<Response<Article[]>> {
        const url = Config.get("GET_ARTICLE_URL", {page: 1, pageSize: 1000, filter: ''});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const moneyServiceFeeData = response.data?.data;
            if (moneyServiceFeeData) {
                const data = moneyServiceFeeData.map((t: any) => new Article(t.id, t.name, t.description, t.defaultImage, t.confectionPrice, t.confectionPriceCurrency, t.createdAt));
                return new Response<Article[]>(true, data, response.error)
            }
        }
        return new Response<Article[]>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<Article>> {
        const url = Config.get("FIND_ARTICLE_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const articleData = response.data;
            if (articleData) {
                const client = new Article(articleData.id, articleData.name, articleData.description, articleData.defaultImage, articleData.confectionPrice, articleData.confectionPriceCurrency, articleData.createdAt);
                return new Response<Article>(true, client, response.error);
            }
        }
        return new Response<Article>(true, undefined, response.error, response.status);
    }

    export async function filter(geoId: string): Promise<ArticleWithPriceResponse[]> {
        const url = Config.get("FILTER_ARTICLE_URL", {id: geoId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response && response.data) {
            const data = response.data.map((t: any) => new ArticleWithPriceResponse(t.id, t.name, t.description, t.defaultImage, t.prices, 1, t.createdAt));
            return data;
        }
        return [];
    }

    export async function create(article: Article): Promise<Response<any>> {
        const url = Config.get("CREATE_ARTICLE_URL");
        const request = new Request(url, article);
        return await RestClient.post(request);
    }

    export async function update(article: Article): Promise<Response<any>> {
        const url = Config.get("UPDATE_ARTICLE_URL", {id: article.id});
        const request = new Request(url, article);
        return await RestClient.put(request);
    }

    export async function remove(article: Article): Promise<Response<any>> {
        const url = Config.get("REMOVE_ARTICLE_URL", {id: article.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function uploadImage(articleId: string, file: File): Promise<void> {
        const reader = new FileReader();
        reader.onloadend = async () => {
            const url = Config.get("UPLOAD_ARTICLE_IMAGE_URL", {id: articleId});
            const request = new Request(url, { base64File: reader.result });
            await RestClient.post(request);
        };
        reader.readAsDataURL(file);
    }

}