import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./MiscellanyOrder.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import {useCommonContext} from "../../contexts/CommonContext";
import MiscellanyOrderStore from './MiscellanyOrderStore';
import ButtonType from "../../ui_components/Button/ButtonType";
import NewMiscellanyOrderButton from "../../shared_components/Buttons/NewMiscellanyOrderButton/NewMiscellanyOrderButton";
import MiscellanyOrderRow from './MiscellanyOrderRow/MiscellanyOrderRow';
import RemittanceStatusSelect from '../../shared_components/Selects/RemittanceStatusSelect/RemittanceStatusSelect';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import CustomRangeRepository, {RangeName} from '../../ui_components/DateTimeRageSelect/CustomRangeRepository';
import AllowAccess from "../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../shared_components/Access/AllowAccess/Access";
import AgencySelect from "../../shared_components/Selects/AgencySelect/AgencySelect";
import Agency from "../../entities/Agency";
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const MiscellanyOrder: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = new MiscellanyOrderStore(commonStore);
    const classes = useStyles();

    const customRanges = CustomRangeRepository.getRanges(i18n);
    const range = customRanges.find(t => t.key === RangeName.LAST_FIFTEEN_DAYS);
    store.setFromFilter(range?.from?.valueOf() || undefined);
    store.setToFilter(range?.to?.valueOf() || undefined);

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onAgencyChange = (newAgency?: Agency) =>
        (store.agencyFilter = newAgency);

    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.fromFilter = from;
        store.toFilter = to;
    }

    const onUpdate = async () => {
        await store.getElements();
    }

    const onMiscellanyOrderSelect = (articleRemittanceId: string) => {
        const index = store.selectedMiscellanyOrdersIds.findIndex(t => t === articleRemittanceId);
        store.selectedMiscellanyOrdersIds = index >= 0 ?
            store.selectedMiscellanyOrdersIds.filter(t => t !== articleRemittanceId) :
            [...store.selectedMiscellanyOrdersIds, articleRemittanceId];
    }

    const onMiscellanyOrderOrderToggleSelectAll = (value: boolean) => {
        store.selectedMiscellanyOrdersIds = value ? store.miscellanyOrders.map(t => t.id) : [];
    }

    return (
        <Observer>
            {() => (
                <div className={classes.article_container}>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("MISCELLANY_ORDER_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <DateTimeRangeSelect selectedRange={RangeName.LAST_FIFTEEN_DAYS} label={i18n.translate("DATES")}
                                             onChange={onDateChange}/>
                        <AllowAccess any={[Access.SEND_ORDERS_TO_AGENCIES]}>
                            <AgencySelect selected={store.agencyFilter} onChange={onAgencyChange}/>
                        </AllowAccess>
                        <RemittanceStatusSelect selectedStatus={store.statusFilter} onChange={(c) => store.setStatusToFilter(c)}/>
                        <div className={classes.filter_buttons_container}>
                            <Button
                                onClick={() => {
                                    store.search()
                                }}
                                type={ButtonType.PRIMARY}
                                className={classes.search_btn}
                                text={i18n.translate("SEARCH")}
                            />
                            <Button
                                onClick={() => {
                                    store.clearFilter()
                                }}
                                className={classes.search_btn}
                                text={i18n.translate("CLEAN")}
                            />
                        </div>
                    </Box>
                    <div className={classes.article_table}>
                        <Box className={classes.tools_container}
                             width={Width.FULL_WIDTH}
                             justifyContent={Alignment.END}>
                            <Checkbox text={''}
                                selected={store.selectedMiscellanyOrdersIds.length === store.miscellanyOrders.length}
                                onChange={onMiscellanyOrderOrderToggleSelectAll} className={classes.select_all_checkbox}/>
                            <NewMiscellanyOrderButton type={ButtonType.SECONDARY}/>
                        </Box>
                        {!store.isEmpty &&
                            <>
                                <ShowPagination onChange={onPaginationChange}
                                                elementLabel={i18n.translate("MISCELLANY")}
                                                pagination={store.pagination}/>
                                <div className={classes.elements_container}>
                                    {store.miscellanyOrders.map((articleRemittance, i) => (
                                        <MiscellanyOrderRow
                                            filter={store.filter}
                                            onMiscellanyOrderSelect={onMiscellanyOrderSelect}
                                            miscellanyOrder={articleRemittance} key={i}
                                            selectedDeliveryOrderIds={store.selectedMiscellanyOrdersIds}
                                            onUpdate={onUpdate}
                                        />
                                    ))}
                                </div>
                                <ShowPagination
                                    onChange={onPaginationChange}
                                    elementLabel={i18n.translate("MISCELLANY")}
                                    pagination={store.pagination}/>
                            </>
                        }
                    </div>
                    {store.isEmpty &&
                    <MessageCard icon={DashboardCustomizeIcon} title={i18n.translate("NO_MISCELLANY_ORDER_TITLE")}
                                subtitle={i18n.translate("NO_MISCELLANY_ORDER_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default MiscellanyOrder;
