import InventoryItem from "../../../model/packageDelivery/inventoryItem/InventoryItem";
import CommonStore from "../../../stores/CommonStore";
import {action, makeAutoObservable} from "mobx";
import {InventoryItemService} from "../../../service/packageDelivery/InventoryItem/InventoryItemService";

const ERROR_MAP = {
    unauthorized: ["ERROR_00_401_00"]
}
class RemoveInventoryItemButtonStore {

    public inventoryItem:InventoryItem
    public loading: boolean
    public commonStore: CommonStore

    constructor(commonStore: CommonStore, inventoryItem: InventoryItem) {
        this.inventoryItem = inventoryItem;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    @action
    public async remove() {
        const response = await InventoryItemService.remove(this.inventoryItem);
        this.commonStore.processErrors(response);
        this.processError(response.error)
        return response.success;
    }

    processError(error?: string) {
        if (error) {
            if (ERROR_MAP.unauthorized.includes(error)) {
                this.commonStore.showErrorToast(error) //DONT SHOW THE TOAST BECAUSE IT TRGGERS THE SEACH AGAIN OR DO THE LOGOUT
            }
        }
    }
}
export default RemoveInventoryItemButtonStore