import CustomRange from "./CustomRange";
import I18nStore from "../../stores/I18nStore";
import esLocale from "date-fns/locale/es";
import {
    startOfDay,
    endOfDay,
    subDays,
    startOfWeek,
    endOfWeek,
    subWeeks,
    endOfMonth,
    startOfMonth,
    subMonths
} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';

export enum RangeName {
    TODAY_ONLY = "TODAY_ONLY",
    YESTERDAY_ONLY = "YESTERDAY_ONLY",
    LAST_TREE_DAYS = "LAST_TREE_DAYS",
    LAST_FIVE_DAYS = "LAST_FIVE_DAYS",
    LAST_FIFTEEN_DAYS = "LAST_FIFTEEN_DAYS",
    THIS_WEEK = "THIS_WEEK",
    PREVIOUS_WEEK = "PREVIOUS_WEEK",
    THIS_MONTH = "THIS_MONTH",
    PREVIOUS_MONTH = "PREVIOUS_MONTH"
}

class CustomRangeRepository {
    static getRanges(i18n: I18nStore): CustomRange[] {
        const timeZone = "America/Havana";
        const weekOptions: any = {weekStartsOn: 1, locale: esLocale};

        const now = zonedTimeToUtc(Date.now(), timeZone);
        const yesterday = zonedTimeToUtc(subDays(now, 1), timeZone);
        const twoDaysAgo = zonedTimeToUtc(subDays(now, 2), timeZone);
        const fourDaysAgo = zonedTimeToUtc(subDays(now, 4), timeZone);
        const fourteenDaysAgo = zonedTimeToUtc(subDays(now, 14), timeZone);
        const previousWeek = zonedTimeToUtc(subWeeks(now, 1), timeZone);
        const previousMonth = zonedTimeToUtc(subMonths(now, 1), timeZone);
        return [
            new CustomRange(RangeName.TODAY_ONLY, i18n.translate(RangeName.TODAY_ONLY), startOfDay(now), endOfDay(now)),
            new CustomRange(RangeName.YESTERDAY_ONLY, i18n.translate(RangeName.YESTERDAY_ONLY), startOfDay(yesterday), endOfDay(yesterday)),
            new CustomRange(RangeName.LAST_TREE_DAYS, i18n.translate(RangeName.LAST_TREE_DAYS), startOfDay(twoDaysAgo), endOfDay(now)),
            new CustomRange(RangeName.LAST_FIVE_DAYS, i18n.translate(RangeName.LAST_FIVE_DAYS), startOfDay(fourDaysAgo), endOfDay(now)),
            new CustomRange(RangeName.LAST_FIFTEEN_DAYS, i18n.translate(RangeName.LAST_FIFTEEN_DAYS), startOfDay(fourteenDaysAgo), endOfDay(now)),
            new CustomRange(RangeName.THIS_WEEK, i18n.translate(RangeName.THIS_WEEK), startOfWeek(now, weekOptions), endOfWeek(now, weekOptions)),
            new CustomRange(RangeName.PREVIOUS_WEEK, i18n.translate(RangeName.PREVIOUS_WEEK), startOfWeek(previousWeek, weekOptions), endOfWeek(previousWeek, weekOptions)),
            new CustomRange(RangeName.THIS_MONTH, i18n.translate(RangeName.THIS_MONTH), startOfMonth(now), endOfMonth(now)),
            new CustomRange(RangeName.PREVIOUS_MONTH, i18n.translate(RangeName.PREVIOUS_MONTH), startOfMonth(previousMonth), endOfMonth(previousMonth)),
        ];
    }

    static getRange(i18n: I18nStore, rangeName: RangeName): CustomRange | undefined {
        const ranges = CustomRangeRepository.getRanges(i18n);
        return ranges.find(r => r.key === rangeName)
    }
}

export default CustomRangeRepository;