import React, {useMemo, useState} from "react";
import UpdatePaymentFeeButtonProperties from "./UpdatePaymentFeeButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import UpdatePaymentFeeButtonStore from "./UpdatePaymentFeeButtonStore";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import Modal from "../../../ui_components/Modal/Modal";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./UpdatePaymentFeeButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import {useUserContext} from "../../../contexts/UserContext";
import PaymentFee from "../../../entities/PaymentFee";
import PriceInput from "../../Inputs/PriceInput/PricetInput";
import Checkbox from "../../../ui_components/Checkbox/Checkbox";
import {Percent} from "@mui/icons-material";
import Access from "../../Access/AllowAccess/Access";
import PaymentType from "../../../entities/PaymentType";
import CashPaymentIcon from "../../../ui_components/Icon/icons/CashPaymentIcon";
import CheckPaymentIcon from "../../../ui_components/Icon/icons/CheckPaymentIcon";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditCardSharpIcon from "@mui/icons-material/CreditCardSharp";
import WireTransferIcon from "../../../ui_components/Icon/icons/WireTransferIcon";
import ZelleIcon from "../../../ui_components/Icon/icons/ZelleIcon";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";

const UpdatePaymentFeeButton: React.FC<UpdatePaymentFeeButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSave = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = "",
                                                                                agencyId,
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT,
                                                                            }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const userStore = useUserContext();
    const commonContext = useCommonContext();
    const store = useMemo(() => new UpdatePaymentFeeButtonStore(commonContext, agencyId), [agencyId, commonContext]);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    };

    const onCloseModal = () => {
        store.closeModal();
    };

    if (!userStore.hasAccess(Access.UPDATE_PAYMENT_FEES.valueOf())) {
        return <></>;
    }

    const basicText = i18n.translate("UPDATE_PAYMENT_FEES");
    const Btn = () => {
        if (rounded) {
            return (
                <RoundedButton
                    onClick={handleOnclick}
                    className={className}
                    disabled={disabled}
                    tooltip={!showText ? basicText : ""}
                    text={showText ? basicText : ""}
                    type={type}
                    icon={showIcon ? <RequestQuoteIcon/> : <></>}
                />
            );
        }
        return (
            <Button
                text={showText ? basicText : ""}
                className={className}
                tooltip={!showText ? basicText : ""}
                disabled={disabled}
                size={size}
                onClick={handleOnclick}
                type={type}
                startIcon={showIcon ? <RequestQuoteIcon/> : <></>}
            />
        );
    };

    const PaymentFeeRow: React.FC<{ paymentFee: PaymentFee }> = ({paymentFee}) => {
        const [isPercent, setIsPercent] = useState<boolean>(paymentFee.isPercentage)
        const onValueChange = (newValue: number) => {
            paymentFee.value = newValue;
        }

        const onIsPercentChange = (newValue: boolean) => {
            paymentFee.isPercentage = newValue;
            setIsPercent(newValue);
        }

        const getIcon = (paymentType: PaymentType) => {
            switch (paymentType) {
                case PaymentType.CASH:
                    return <CashPaymentIcon/>;
                case PaymentType.CHECK:
                    return <CheckPaymentIcon/>;
                case PaymentType.CREDIT:
                    return <CreditCardIcon/>;
                case PaymentType.DEBIT:
                    return <CreditCardSharpIcon/>;
                case PaymentType.WIRE_TRANSFER:
                    return <WireTransferIcon/>;
                case PaymentType.ZELLE:
                    return <ZelleIcon/>;
                case PaymentType.DISTANCE:
                    return <PermPhoneMsgIcon/>;
            }
        }

        return (
            <div className={classes.payment_fee_row_container}>
                <div className={classes.payment_type}>
                    {getIcon(paymentFee.paymentType)}
                    {i18n.translate(`PAYMENT_TYPE_${paymentFee.paymentType}`)}
                </div>
                <PriceInput onChange={onValueChange} value={paymentFee.value} icon={isPercent ? Percent : undefined}
                            label={isPercent ? "Porciento" : "Valor fijo"}/>
                <Checkbox text={"Valor en %"} onChange={onIsPercentChange} selected={isPercent}/>
            </div>
        )
    }

    const save = async () => {
        const success = await store.save();
        if (onSave) {
            onSave();
        }
        if (success) {
            store.closeModal();
        }
    }

    return (
        <Observer>
            {() => (
                <>
                    <Modal
                        fullScreen={false}
                        loading={store.loading}
                        title={basicText}
                        onClose={onCloseModal}
                        open={store.isModalOpen}
                    >
                        <div className={classes.modal_container}>
                            <div className={classes.fees_container}>
                                {store.fees.map((paymentFee, i) =>
                                    <PaymentFeeRow paymentFee={paymentFee} key={i}/>
                                )}
                            </div>
                            <div className={classes.btn_container}>
                                <Button onClick={save} text={i18n.translate("SAVE")} type={ButtonType.PRIMARY}
                                        size={ButtonSize.LARGE}/>
                            </div>
                        </div>
                    </Modal>
                    <Btn/>
                </>
            )}
        </Observer>
    );
};

export default UpdatePaymentFeeButton;
