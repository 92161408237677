import Pagination from "../../entities/Pagination";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {ClientService} from "../../service/ClientService";
import CommonStore from "../../stores/CommonStore";
import Client from "../../entities/Client";
import Agency from "../../entities/Agency";

class ClientStore {

    public clients: Client[];
    public pagination: Pagination;
    public filter?: string;
    public agencyFilter?: Agency;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore) {
        this.pagination = new Pagination(1, 15, 0, 0);
        this.clients = [];
        this.commonStore = commonStore;
        makeObservable(this, {
            clients: observable,
            pagination: observable,
            isEmpty: computed
        });
    }

    get isEmpty(): boolean {
        return this.clients.length === 0;
    }

    public setFilter(filer: string) {
        this.filter = filer;
    }

    @action
    public async search() {
        await this.getElements(1);
    }


    @action
    public async goToPage(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        await this.getElements(page, pageSize);
    }

    @action
    public async getElements(page: number = this.pagination.currentPage, pageSize: number = this.pagination.pageSize): Promise<void> {
        this.commonStore.setLoading();
        const response = await ClientService.get(page, pageSize, this.filter, this.agencyFilter?.id);
        if (response.success) {
            runInAction(() => {
                this.clients = response.data?.data || [];
                this.pagination = response.data?.pagination || this.pagination;
            })
        }
        this.commonStore.processErrors(response);
        this.commonStore.removeLoading();
    }

}

export default ClientStore;