import React from 'react';
import ClientSelectProperties from "./ClientSelectProperties";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ClientSelect.style";
import ClientContactSelect from "../../../Selects/ClientContactSelect/ClientContactSelect";
import ClientContact from "../../../../entities/ClientContact";
import Client from "../../../../entities/Client";

const ClientSelect: React.FC<ClientSelectProperties> = ({
                                                              store
                                                          }) => {
    const classes = useStyles();

    const onContactChange = (contact?: ClientContact) => {
    }

    const onClientChange = (client?: Client) => {
        store.setClient(client);
    }
    
    return <Observer>
        {() => (
            <div className={classes.container}>
                <ClientContactSelect
                    fullWidth
                    agencyId={store.selectedAgency?.id}
                    showObservationsInput
                    onlyClientSelection={true}
                    selectedClient={store.selectedClient}
                    selectedObservations={store.observations}
                    onClientChange={onClientChange}
                    onContactChange={onContactChange}/>
            </div>
        )}
    </Observer>
}

export default ClientSelect;
