import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import TopupPrice from "../../../entities/productOrder/topup/TopupPrice";
import { TopupPriceService } from "../../../service/topup/TopupPriceService";
import Product from "../../../entities/productOrder/topup/Product";

const ERROR_MAP = {
    change: ["ERROR_00_400_35", "ERROR_00_400_35"],
    product: ["ERROR_00_400_77"],
}

class NewTopupPriceStore {

    public topupPriceId?: string;
    public topupPrice: TopupPrice;
    public selectedProduct?: Product;
    public isEditing: boolean;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public priceError?: string;
    public productError?: string;

    constructor(commonStore: CommonStore, topupPriceId?: string) {
        this.isEditing = !!topupPriceId;
        this.topupPriceId = topupPriceId;
        this.isModalOpen = false;
        this.topupPrice = {} as TopupPrice;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.topupPrice?.amount) && !!(this.selectedProduct);
    }

    @action
    public async loadTopupPrice() {
        if (this.topupPriceId) {
            this.isEditing = true;
            const response = await TopupPriceService.get();
            if (response.success && response.data) {
                this.topupPrice = response.data.find(t => t.id === this.topupPriceId!)!;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadTopupPrice();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async save(): Promise<boolean> {
        this.loading = true;
        const serviceToCall = this.isEditing ? TopupPriceService.update : TopupPriceService.create;
        const response = await serviceToCall({
            id: this.topupPrice.id,
            topupProductId: this.selectedProduct!!.id,
            amount: this.topupPrice.amount
        });
        this.commonStore.processErrors(response);
        this.processError(response.error);
        this.loading = false;
        return response.success;
    }

    processError(error?: string) {
        this.priceError = undefined;
        this.productError = undefined;
        if (error) {
            if (ERROR_MAP.change.includes(error)) {
                this.priceError = error;
            }
            if (ERROR_MAP.product.includes(error)) {
                this.productError = error;
            }
        }

    }
}

export default NewTopupPriceStore;