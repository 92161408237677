import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Client from "../entities/Client";

export module ClientService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string, agencyId?: string): Promise<Response<PagedResponse<Client>>> {
        const url = Config.get("GET_CLIENTS_URL", {page, pageSize, filter, agencyId});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const clientsData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (clientsData && paginationData) {
                const data = clientsData.map((t: any) => new Client(
                    t.id,
                    t.name,
                    t.lastName,
                    t.documentId,
                    t.address,
                    t.city,
                    t.state,
                    t.zipCode,
                    t.createdAt,
                    t.difficultPerson,
                    t.email,
                    t.nacionality,
                    t.birthday,
                    t.observation,
                    t.phone,
                    t.agencyId,
                    t.sendDeliveryNotificationsEmail,
                    t.sendDeliveryNotificationsSms,
                    t.sendPromotionNotificationsEmail,
                    t.sendPromotionNotificationsSms,
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Client>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Client>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<Client>> {
        const url = Config.get("FIND_CLIENT_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const client = new Client(
                    t.id,
                    t.name,
                    t.lastName,
                    t.documentId,
                    t.address,
                    t.city,
                    t.state,
                    t.zipCode,
                    t.createdAt,
                    t.difficultPerson,
                    t.email,
                    t.nacionality,
                    t.birthday,
                    t.observation,
                    t.phone,
                    t.agencyId,
                    t.sendDeliveryNotificationsEmail,
                    t.sendDeliveryNotificationsSms,
                    t.sendPromotionNotificationsEmail,
                    t.sendPromotionNotificationsSms,
                );
                return new Response<Client>(true, client, response.error);
            }
        }
        return new Response<Client>(true, undefined, response.error, response.status);
    }

    export async function create(client: Client): Promise<Response<any>> {
        const url = Config.get("CREATE_CLIENT_URL");
        const request = new Request(url, client);
        return await RestClient.post(request);
    }

    export async function update(client: Client): Promise<Response<any>> {
        const url = Config.get("UPDATE_CLIENT_URL", {id: client.id});
        const request = new Request(url, client);
        return await RestClient.put(request);
    }

    export async function remove(client: Client): Promise<Response<any>> {
        const url = Config.get("REMOVE_CLIENT_URL", {id: client.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}