import Request from "../model/Request";
import Response from "../model/Response";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Promotion from "../entities/Promotion";
import { RestClient } from "../util/RestClient";

export module PromotionService {
    export async function get(page: number = 1, pageSize: number = 15, filter?: string): Promise<Response<PagedResponse<Promotion>>> {
        const url = Config.get("GET_PROMOTIONS_URL", {page, pageSize, filter});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const promotionsData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (promotionsData && paginationData) {
                const data = promotionsData.map((t: any) => new Promotion(
                    t.id,
                    t.name,
                    t.description,
                    t.defaultImage,
                    t.agenciesId,
                    t.startDate,
                    t.endDate,
                    t.status,
                    t.createdAt,
                    t.agencyId
                ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Promotion>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Promotion>>(false, undefined, response.error, response.status);
    }

    export async function find(id: string): Promise<Response<Promotion>> {
        const url = Config.get("FIND_PROMOTION_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const t = response.data;
            if (t) {
                const promotion = new Promotion(
                    t.id,
                    t.name,
                    t.description,
                    t.defaultImage,
                    t.agenciesId,
                    t.startDate,
                    t.endDate,
                    t.status,
                    t.createdAt,
                    t.agencyId
                );
                return new Response<Promotion>(true, promotion, response.error);
            }
        }
        return new Response<Promotion>(true, undefined, response.error, response.status);
    }

    export async function create(promotion: Promotion): Promise<Response<any>> {
        const url = Config.get("CREATE_PROMOTION_URL");
        const request = new Request(url, promotion);
        return await RestClient.post(request);
    }

    export async function update(promotion: Promotion): Promise<Response<any>> {
        const url = Config.get("UPDATE_PROMOTION_URL", {id: promotion.id});
        const request = new Request(url, promotion);
        return await RestClient.put(request);
    }

    export async function remove(promotion: Promotion): Promise<Response<any>> {
        const url = Config.get("REMOVE_PROMOTION_URL", {id: promotion.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

    export async function uploadImage(promotionId: string, file: File): Promise<void> {
        const reader = new FileReader();
        reader.onloadend = async () => {
            const url = Config.get("UPLOAD_PROMOTION_IMAGE_URL", {id: promotionId});
            const request = new Request(url, { base64File: reader.result });
            await RestClient.post(request);
        };
        reader.readAsDataURL(file);
    }

    export async function getActivePromotions(): Promise<Response<Promotion[]>> {
        const url = Config.get("GET_ACTIVE_PROMOTIONS_URL");
        const request = new Request(url);
        const response = await RestClient.get(request);
        if (response.success) {
            const promotionsData = response.data;
            if (promotionsData) {
                const data = promotionsData.map((t: any) => new Promotion(
                    t.id,
                    t.name,
                    t.description,
                    t.defaultImage,
                    t.agenciesId,
                    t.startDate,
                    t.endDate,
                    t.status,
                    t.createdAt,
                    t.agencyId
                ));
                return new Response<Promotion[]>(true, data, response.error)
            }
        }
        return new Response<Promotion[]>(false, undefined, response.error, response.status);
    }
}