import React from 'react';
import AgencyMoneyExchangeButtonProperties from "./AgencyMoneyExchangeButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import AgencyMoneyExchangeStore from "./AgencyMoneyExchangeStore";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AgencyMoneyExchangeButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import ShowPagination from "../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../ui_components/MessageCard/MessageCard";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import ShowPrice from '../../Info/ShowPrice/ShowPrice';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import NewMoneyExchangeRateButton from '../NewMoneyExchangeRateButton/NewMoneyExchangeRateButton';


const AgencyMoneyExchangeButton: React.FC<AgencyMoneyExchangeButtonProperties> = ({
                                                                          onClick = () => {
                                                                          },
                                                                          showIcon = true,
                                                                          showText = true,
                                                                          disabled,
                                                                          className = '',
                                                                          agencyId,
                                                                          rounded = false,
                                                                          size = ButtonSize.DEFAULT,
                                                                          type = ButtonType.DEFAULT
                                                                      }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const store = new AgencyMoneyExchangeStore(commonContext, agencyId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onSave = async () => {
        await store.search();
    }

    const text = 'Tasas de cambio';
    const Icon = CurrencyExchangeIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <div className={classes.contacts_container}>
                        <Box
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.SPACE_BETWEEN}>
                            <ShowSellerBasicInfo name={store.agency.name}
                                                 email={store.agency.email}
                                                 phone={store.agency.phone} autofill/>
                        </Box>
                        <div className={classes.body}>
                            {!store.isEmpty &&
                                <div className={classes.contacts_table}>
                                    <ShowPagination onChange={onPaginationChange}
                                                    elementLabel={'Tasas de cambio'}
                                                    pagination={store.pagination}/>
                                    <div className={classes.elements_container}>
                                        {store.moneyExchanges.map((moneyExchange, i) => (
                                            <div className={classes.contact_info_container}>
                                                <ShowPrice value={moneyExchange.value} currency={moneyExchange.currency}/>
                                                <ExpandoMenu>
                                                    <>
                                                        <NewMoneyExchangeRateButton
                                                            size={ButtonSize.SMALL}
                                                            onSave={onSave}
                                                            showText
                                                            moneyExchangeId={moneyExchange.id}
                                                            type={ButtonType.SECONDARY}
                                                        />
                                                    </>
                                                </ExpandoMenu>
                                            </div>
                                        ))}
                                    </div>
                                    <ShowPagination
                                        onChange={onPaginationChange}
                                        elementLabel={'Tasas de cambio'}
                                        pagination={store.pagination}/>
                                </div>
                            }
                            {store.isEmpty &&
                                <MessageCard icon={CurrencyExchangeIcon} title={i18n.translate("NO_MONEY_EXCHANGES_TITLE")}
                                             subtitle={i18n.translate("NO_MONEY_EXCHANGES_SUBTITLE")}/>}
                        </div>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default AgencyMoneyExchangeButton;
