import CommonStore from "../../stores/CommonStore";
import {action, makeObservable, observable} from "mobx";
import {RoleService} from "../../service/RoleService";
import GetAccessResponse from "../../model/access/GetAccessResponse";

class AccessStore {
    public commonStore: CommonStore;
    selectedRoleId?: string;
    roleAccess: GetAccessResponse[];
    allAccess: GetAccessResponse[];
    accessNotInRole: GetAccessResponse[];
    newAccessValue?: string

    constructor(commonStore: CommonStore) {
        this.commonStore = commonStore;
        this.roleAccess = [];
        this.allAccess = [];
        this.accessNotInRole = [];
        makeObservable(this, {
            roleAccess: observable,
            allAccess: observable,
            accessNotInRole: observable,
            selectedRoleId: observable,
            newAccessValue: observable,
        });
    }

    @action
    public async getAllAccess(): Promise<void> {
        this.commonStore.setLoading();
        const response = await RoleService.getAllAccess();
        const newAccessValueResponse = await RoleService.getNewAccessValue();
        this.allAccess = response || [];
        this.newAccessValue = newAccessValueResponse.value;
        this.commonStore.removeLoading();
    }

    @action
    public async toggleAccess(accessName: string): Promise<void> {
        if (!this.selectedRoleId) return;
        this.commonStore.setLoading();
        await RoleService.toggleAccess(this.selectedRoleId, accessName);
        await this.getRoleAccess();
        this.commonStore.removeLoading();
    }

    @action
    public async setSelectedRoleId(roleId: string): Promise<void> {
        this.selectedRoleId = roleId;
        await this.getRoleAccess();
    }

    @action
    public async getRoleAccess(): Promise<void> {
        if (!this.selectedRoleId) return;
        this.commonStore.setLoading();
        const response = await RoleService.getRoleAccess(this.selectedRoleId);
        this.roleAccess = response || [];
        this.getRoleAccessDiff()
        this.commonStore.removeLoading();
    }

    private getRoleAccessDiff() {
        this.accessNotInRole = this.allAccess.filter(a => !this.roleAccess.find(r => r.name === a.name))
    }
}

export default AccessStore;