import Geo from "./Geo";
import Phone from "./Phone";

class ClientContact {
    public id: string;
    public name: string;
    public lastName: string;
    public documentId: string;
    public geo?: Geo;
    public address: string;
    public neighborhood?: string;
    public homePhone?: Phone;
    public mobilePhone?: Phone;
    public secondMobilePhone?: Phone;
    public email?: string;
    public observation?: string;
    public difficultPerson: boolean;
    public agencyId?: string;
    public clientId: string;
    public createdAt: number;

    constructor(id: string, name: string, lastName: string, documentId: string, clientId: string, createdAt: number, address: string, difficultPerson: boolean, geo?: Geo, neighborhood?: string, homePhone?: Phone, mobilePhone?: Phone, secondMobilePhone?: Phone, email?: string, observation?: string, agencyId?: string) {
        this.name = name;
        this.lastName = lastName;
        this.documentId = documentId;
        this.homePhone = homePhone;
        this.mobilePhone = mobilePhone;
        this.secondMobilePhone = secondMobilePhone;
        this.id = id;
        this.createdAt = createdAt;
        this.geo = geo;
        this.address = address;
        this.neighborhood = neighborhood;
        this.email = email;
        this.observation = observation;
        this.difficultPerson = difficultPerson;
        this.agencyId = agencyId;
        this.clientId = clientId;
    }

}

export default ClientContact;