import React from 'react';
import AdditionalPricesProperties from "./AdditionalPricesProperties";
import {Observer} from "mobx-react-lite";
import AddAdditionalPrice from '../AddAdditionalPrice/AddAdditionalPrice';
import AdditionalPriceRow from '../AdditionalPriceRow/AdditionalPriceRow';
import OrderAdditionalPrice from '../../../../../entities/OrderAdditionalPriceRequest';
import MessageCard from '../../../../../ui_components/MessageCard/MessageCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useI18nContext } from '../../../../../contexts/I18nContext';
import { useStyles } from './AdditionalPrices.style';
import { Typography } from '@material-ui/core';
import ShowShopProductDeliveryItem from '../../../../Info/ShowShopProductDeliveryItem/ShowShopProductDeliveryItem';

const AdditionalPrices: React.FC<AdditionalPricesProperties> = ({
                                                                    store
                                                                }) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    // const [observations, setObservations] = useState<string|undefined>(selectedObservations);

    const removeAdditionalPrice = async (additionalPrice: OrderAdditionalPrice) => {
        await store.removeAdditionalPrice(additionalPrice);
    }

    const onAmountSelect = (id: string, amount: number) => {
        const tempArticlePriceIndex = store.selectedProducts.find(t => t.id === id);
        if (tempArticlePriceIndex) {
            tempArticlePriceIndex.amount = amount;
        }
    }

    const getShopProductsPrices = () => {
        return store.selectedProducts.reduce((total, obj) =>
            total + (obj.price * (obj.amount ?? 1)), 0);
    }

    const getAdditionalPrices = () => {
        return store.additionalPrices.reduce((total, obj) => total + obj.amount, 0);
    }

    const getTotalPrices = () => {
        return getShopProductsPrices() + getAdditionalPrices();
    }

    const noData = <MessageCard icon={AttachMoneyIcon} className={classes.no_data_container}
        title={i18n.translate("NO_ADDITIONAL_PRICE_TITLE")}
        subtitle={i18n.translate("NO_ADDITIONAL_PRICE_SUBTITLE")}/>

    return <Observer>
        {() => (
            <div className={classes.container}>
                <div className={classes.articles_resume_container}>
                    {!store.loadingShopProducts && store.selectedProducts &&
                    store.selectedProducts.map((el, i) => (
                        <ShowShopProductDeliveryItem
                            key={i}
                            id={el.id}
                            title={el.name}
                            text={el.description}
                            imageName={el.defaultImage}
                            price={el.price}
                            stockAmount={el.stockAmount}
                            selectedAmount={store.selectedProducts ? store.selectedProducts.find(t => t.id === el.id)?.amount : 0}
                            isSelectable={false}
                            onAmountSelect={(amount) => onAmountSelect(el.id, amount)}
                        />
                    ))}
                </div>
                <div className={classes.resume_container}>
                    <div className={classes.additional_price_resume_container}>
                        <Typography variant="subtitle1" className={classes.title} component="div">
                            Tarifas adicionales
                        </Typography>
                        <div>
                            <AddAdditionalPrice store={store}></AddAdditionalPrice>
                        </div>
                        <div className={classes.list_container}>
                        {store.additionalPrices.length > 0 ? store.additionalPrices.map((t, i) => (
                                <AdditionalPriceRow onDelete={removeAdditionalPrice} additionalPrice={t} key={i}/>
                            )) : noData}
                        </div>
                    </div>
                    <div className={classes.resume_all_container}>
                        <Typography variant="subtitle1" className={classes.title} component="div">
                            Pre-Resumen
                        </Typography>
                        <div className={classes.pre_resume_item_container}>
                            <div>Productos</div>
                            <div>{getShopProductsPrices()} USD</div>
                        </div>
                        <div className={classes.pre_resume_item_container}>
                            <div>Tarifas adicionales</div>
                            <div>{getAdditionalPrices()} USD</div>
                        </div>
                        <div className={classes.pre_resume_item_container}>
                            <div className={classes.text_bold}>Total</div>
                            <div className={classes.text_bold}>{getTotalPrices()} USD</div>
                        </div>
                    </div>
                </div>
                
            </div>
        )}
    </Observer>
}

export default AdditionalPrices;
