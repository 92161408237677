import OrderAdditionalPrice from "../../entities/OrderAdditionalPriceRequest";
import AddPaymentRequest from "../payment/AddPaymentRequest";
import ShopOrderProductReguest from "./ShopOrderProductReguest";

class ShopOrderRequest {
    public clientId: string;
    public total: number;
    public products: ShopOrderProductReguest[];
    public payments: AddPaymentRequest[];
    public additionalPrices?: OrderAdditionalPrice[];
    public observations?: string;

    constructor(
        clientId: string,
        total: number,
        products: ShopOrderProductReguest[],
        payments: AddPaymentRequest[],
        additionalPrices?: OrderAdditionalPrice[],
        observations?: string
    ) {
        this.clientId = clientId;
        this.total = total;
        this.products = products;
        this.payments = payments;
        this.additionalPrices = additionalPrices;
        this.observations = observations;
    }
}

export default ShopOrderRequest;