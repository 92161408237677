import {action, makeAutoObservable} from "mobx";
import Seller from "../../../entities/Seller";
import {SellerService} from "../../../service/SellerService";
import CommonStore from "../../../stores/CommonStore";
import SellerStatus from "../../../entities/SellerStatus";


class ToggleSellerStatusStore {

    public seller: Seller;
    public loading: boolean;
    public commonStore: CommonStore;


    constructor(commonStore: CommonStore, seller: Seller) {
        this.seller = seller;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get isSellerActive(): boolean {
        return this.seller.status === SellerStatus.ACTIVE;
    }

    @action
    public async toggle() {
        const serviceToCall = this.isSellerActive
            ? SellerService.setInactive
            : SellerService.setActive;
        const response = await serviceToCall(this.seller);
        this.commonStore.processErrors(response);
        return response.success;
    }
}

export default ToggleSellerStatusStore;