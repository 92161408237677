import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import Product from "../../entities/productOrder/topup/Product";

export module ProductService {
    export async function get(): Promise<Response<Product[]>> {
        const url = Config.get("GET_PRODUCTS_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const productsData = response.data;
            if (productsData) {
                const data = productsData.map((t: any) => new Product(t.id, t.name, t.amount));
                return new Response<Product[]>(true, data, response.error)
            }
        }
        return new Response<Product[]>(false, undefined, response.error, response.status);
    }
}